import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AttachmentCard } from "../../../../components/AttachmentCard";
import { ReactComponent as BinIcon } from "../../../../assets/svg/bin-1.svg";
import { ReactComponent as ExternalIcon } from "../../../../assets/svg/external-link.svg";
import { isUrlAllowed } from "../../../../utilities/IsUrlAllowed";

interface Props {
	link?: string;
	fileDescription?: string;
	identifier: string;
	onDelete: () => void;
	readonly: boolean;
}

export const ActionAttachmentLinkItem = ({
	link,
	fileDescription,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	identifier,
	onDelete,
	readonly,
}: Props) => {
	const { t } = useTranslation();

	const menuItems = useMemo(() => {
		const menuItems = [
			{
				label: t("display:labelOpenLink"),
				onClick: () => {
					if (link) {
						window.open(
							(isUrlAllowed(link) ? "" : "https://") + link,
							"_blank",
							"noopener",
						);
					}
				},
				icon: <ExternalIcon />,
			},
		];
		if (!readonly) {
			menuItems.push({
				label: t("display:buttonDelete"),
				onClick: onDelete,
				icon: <BinIcon />,
			});
		}
		return menuItems;
	}, [link, onDelete, readonly, t]);

	return <AttachmentCard description={fileDescription} menuItems={menuItems} name={link ?? ""} />;
};
