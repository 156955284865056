import { v4 } from "uuid";

const APPLICATION_ID_KEY = "applicationId";

export const createApplicationId = (): void => {
	if (!getApplicationId()) {
		const raygunId = window.localStorage.getItem("raygun4js-userid");
		if (raygunId) {
			setApplicationId(raygunId);
		} else {
			setApplicationId(v4());
		}
	}
};

export const setApplicationId = (applicationId: string): void => {
	window.localStorage.setItem(APPLICATION_ID_KEY, applicationId);
};

export const getApplicationId = (): string => {
	const applicationId = window.localStorage.getItem(APPLICATION_ID_KEY);
	return applicationId || "";
};

createApplicationId();
