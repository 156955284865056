import { useMemo } from "react";
import { NavLink } from "react-router-dom";
import { usePortalPath } from "../../../helpers/UsePortalPath";
import { ModuleListItemThumbnail } from "../../pages/RecordsPage/components/ModuleListItemThumbnail";
import "./ModuleListItem.styles.scss";

interface ModuleListItemProps {
	title?: string;
	moduleKey: string;
}

export const ModuleListItem = ({ title, moduleKey }: ModuleListItemProps) => {
	const portalPath = usePortalPath();
	const to = useMemo(
		() =>
			moduleKey === "Policy"
				? `${portalPath}documents/Policy`
				: `${portalPath}records/${moduleKey}`,
		[moduleKey, portalPath],
	);

	return (
		<li className="she-portal-questionnaire">
			<NavLink className="she-module-card she-module-card--as-button" to={to}>
				<ModuleListItemThumbnail moduleKey={moduleKey} />
				<div className="she-module-card__body">
					{title && <h1 className="she-module-card__title">{title}</h1>}
				</div>
			</NavLink>
		</li>
	);
};
