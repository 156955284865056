import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { DefaultActionsService } from "../../../../../services/actions";
import { DefaultAttachmentService } from "../../../../../services/attachment";
import { EditAction } from "../EditAction";
import { ErrorViewer } from "../../../../components/ErrorViewer/ErrorViewer.component";
import { ConfirmationModal, FormModal } from "../../../../components/modals";
import { fileNameToPost } from "../../../../../helpers/FileHelper";
import { getDateFormat } from "../../../../../helpers/DateTimeInputHelper";
import { IllustratedThemedModal } from "../../../../components/modals/IllustratedThemedModal";
import { Loading } from "../../../../components/Loading";
import { ReactComponent as ErrorIllustration } from "../../../../assets/svg/illustrations/falling.svg";
import { switchMatchingLanguageCode } from "../../../../../helpers/LanguageCodeHelper";
import { useManageActionAttachments } from "../../../../utilities/UseManageActionAttachments";
import { usePortalPath } from "../../../../../helpers/UsePortalPath";
import { useGetActionQuery } from "../../hooks/useGetActionQuery";
import { queryClient } from "../../../../../QueryClient";
import type { ActionFull } from "../../../../../models/action";
import type { HistoryLocationState } from "../../../../../types/History";

interface Match {
	params: { action: string };
}

interface Props {
	refetch: () => void;
}

export const EditActionModal = ({ refetch }: Props) => {
	const { actionId } = useParams<{ actionId: string }>();

	const { t, i18n } = useTranslation();
	const language = switchMatchingLanguageCode(i18n.language);
	const history = useHistory<HistoryLocationState>();
	const customerKey = window.location.pathname.split("/")[1];
	const portalKey = window.location.pathname.split("/")[3];
	const match: Match | null = useRouteMatch(
		"/:customerKey/p/:portalKey/actions/:action(edit)/:actionId",
	);
	const portalPath = usePortalPath();

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isSubmitError, setIsSubmitError] = useState(false);
	const [isConfirmationModalActive, setIsConfirmationModalActive] = useState(false);
	const [tempData, setTempData] = useState<any | null>(null);

	const service = useMemo(
		() =>
			new DefaultActionsService({
				subdomain: "Action",
			}),
		[],
	);

	const { isLoading, isError, data, error } = useGetActionQuery(actionId, !!match, language);

	const { attachmentsToAdd, attachmentsToRemove, ...attachmentsProps } =
		useManageActionAttachments((data && data.Attachments) || []);

	const isCreator = useMemo(
		() => (data && data.ForUser.Id === data.RaisingUser.Id) || false,
		[data],
	);

	const handleRedirect = (isSubmit = false) => {
		setTempData(null);

		if (isSubmit) {
			history.push(`${portalPath}actions`);
			return;
		}

		if (history.location.state) {
			history.push(
				history.location.state.previousPath
					? history.location.state.previousPath
					: `${portalPath}actions`,
			);
		} else {
			history.push(`${portalPath}actions`);
		}
	};

	const onCancel = () => {
		if (attachmentsToAdd.length || attachmentsToRemove.length) {
			setIsConfirmationModalActive(true);
			return;
		}

		if (tempData) {
			for (let index = 0; index < Object.keys(tempData).length; index++) {
				const key: string = Object.keys(tempData)[index];

				if (key in (data as ActionFull) && (data as any)[key] !== tempData[key]) {
					setIsConfirmationModalActive(true);
					return;
				}
			}
		}

		handleRedirect();
	};

	const onChange = <T,>(values: T) => setTempData(values);

	const onSubmit = (data: any) => {
		const attachmentsService = new DefaultAttachmentService({
			subdomain: "ActionAttachments",
		});

		setIsSubmitting(true);
		const action = { ...data };

		if (action.ForUserIds) {
			action.ForUserId = action.ForUserIds.id;
			delete action.ForUserIds;
		}

		if (action.ActionCategory === "-1") {
			action.ActionCategory = null;
		}

		if (action.ActionSubCategory === "-1") {
			action.ActionSubCategory = null;
		}

		if (attachmentsToRemove.length) {
			action.RemoveAttachments = attachmentsToRemove;
		}
		if (attachmentsToAdd.length) {
			action.AddAttachments = attachmentsToAdd.map((attachment) => ({
				FileName: fileNameToPost(attachment.Identifier, attachment.FileName),
				FileDescription: "",
			}));
		}
		if (action.DueDate) {
			const dueDate = moment(data.DueDate, getDateFormat(), true).utc(true);
			action.DueDate = dueDate.toISOString();
		}

		attachmentsService
			.postActionAttachments(attachmentsToAdd)
			.then(() => {
				const language = switchMatchingLanguageCode(i18n.language);
				return service.putAction(actionId, customerKey, portalKey, action, language);
			})
			.then(({ IsSaved }) => {
				if (IsSaved) {
					queryClient.clear();
					setIsSubmitting(false);
					refetch();
					handleRedirect(true);
				}
			})
			.catch(() => {
				setIsSubmitting(false);
				setIsSubmitError(true);
			});
	};

	return (
		<>
			<FormModal
				cancelText={t("global:cancel")}
				formId="edit-action-form"
				large
				okText={t("display:buttonSave")}
				onCancel={onCancel}
				onOk={() => {}}
				show={match !== null && match.params.action === "edit"}
				title={t("display:myTasks.labelEditTask")}
				withHistory={false}
			>
				{isLoading && <div>{t("display:labelLoading")}</div>}

				{isError && (
					<>
						<div>{t("display:labelError")}</div>
						<ErrorViewer error={error} />
					</>
				)}

				{data && !isLoading && !isError ? (
					<EditAction
						action={data}
						isCreator={isCreator}
						onChange={onChange}
						onSubmit={onSubmit}
						{...attachmentsProps}
					/>
				) : null}
			</FormModal>

			{isSubmitError && (
				<IllustratedThemedModal
					cancelText={t("global:ok")}
					onCancel={() => setIsSubmitError(false)}
					show={isSubmitError}
					text={t("error:failedToUpdateTheTask")}
					withHistory={false}
				>
					<ErrorIllustration />
				</IllustratedThemedModal>
			)}

			{isSubmitting && <Loading withHistory={false} />}

			<ConfirmationModal
				onCancel={() => setIsConfirmationModalActive(false)}
				onConfirm={() => handleRedirect()}
				show={isConfirmationModalActive}
				text={t("display:labelAreYouSure")}
			/>
		</>
	);
};
