import { FieldType, SelectFieldType } from "../../../../models/questionnaire";
import { AuditFieldValidator } from "../../../../validators/auditFieldValidator";
import { BehaviouralFieldValidator } from "../../../../validators/behaviouralFieldValidator";
import { BooleanFieldValidator } from "../../../../validators/booleanFieldValidator";
import { CommentBoxValidator } from "../../../../validators/commentBoxValidator";
import { DateTimeFieldValidator } from "../../../../validators/dateTimeFieldValidator";
import { EmailFieldValidator } from "../../../../validators/emailFieldValidator";
import { EntitySelectFieldValidator } from "../../../../validators/entitySelectFieldValidator";
import { EnumSelectFieldValidator } from "../../../../validators/enumSelectFieldValidator";
import { OptionalRegisterSelectFieldValidator } from "../../../../validators/optionalRegisterSelectFieldValidator";
import { IQSignatureFieldValidator } from "../../../../validators/signatureFieldValidator/iQSignatureFieldValidator";
import { MandatoryAdditionsValidator } from "../../../../validators/mandatoryAdditionsValidator";
import { MatrixFieldValidator } from "../../../../validators/matrixFieldValidator";
import { MultiSelectFieldValidator } from "../../../../validators/multiSelectFieldValidator/multiSelectFieldValidator";
import { NumberFieldValidator } from "../../../../validators/numberFieldValidator";
import { OrgUnitFieldValidator } from "../../../../validators/orgUnitFieldValidator";
import { ProjectSelectFieldValidator } from "../../../../validators/projectSelectFieldValidator";
import { SingleSelectFieldValidator } from "../../../../validators/singleSelectFieldValidator";
import { TextFieldValidator } from "../../../../validators/textFieldValidator";
import { GeoPositionFieldValidator } from "../../../../validators/geoPositionValidator/geoPositionFieldValidator";
import type { SingleSelectField } from "../../../../models/fields/SingleSelectField";
import type { TextField } from "../../../../models/fields/TextField";
import type { TimeField } from "../../../../models/fields/TimeField";
import type { ValidationService } from "../../interface";
import type { OrgUnitField } from "../../../../models/fields/OrgUnitField";
import type { ProjectSelectField } from "../../../../models/fields/ProjectSelctField";
import type { Portal } from "../../../../models/portal";
import type { MultiSelectField } from "../../../../models/fields/MultiSelectField";
import type { MatrixField } from "../../../../models/fields/MatrixField";
import type { IndirectField } from "../../../../models/fields/IndirectField";
import type { IQSignatureField } from "../../../../models/fields/IQSignatureField";
import type { IQDateTimeField } from "../../../../models/fields/IQDateTimeField";
import type { IQDateField } from "../../../../models/fields/IQDateField";
import type { GeoPositionField } from "../../../../models/fields/GeoPositionField";
import type { FieldValidationResult } from "../../../../models/validation";
import type { Field } from "../../../../models/fields/Field";
import type { EnumSelectField } from "../../../../models/fields/EnumSelectField";
import type { EntitySelectField } from "../../../../models/fields/EntitySelectField";
import type { EmailField } from "../../../../models/fields/EmailField";
import type { DateTimeField } from "../../../../models/fields/DateTimeField";
import type { DateField } from "../../../../models/fields/DateField";
import type { BooleanField } from "../../../../models/fields/BooleanField";
import type { BehaviouralField } from "../../../../models/fields/BehaviouralField";
import type { OptionalSelectField } from "../../../../models/fields/OptionalSelectField";
import type { BaseNumberField, NumberField } from "../../../../models/fields/NumberField";
import type { AuditField } from "../../../../models/fields/AuditField";
import type { Attachment } from "../../../../models/attachments/Attachment";
import type { NotApplicableComments, SubModuleRecord } from "../../../../models/questionnaire";

export class DefaultValidationService implements ValidationService {
	validateAllFields(
		fields: Field[],
		portal: Portal,
		notApplicableComments?: NotApplicableComments,
	): FieldValidationResult[] {
		const result: FieldValidationResult[] = [];

		fields.forEach((field) => {
			result.push(...this.validateField(field, portal, notApplicableComments, fields));
		});

		return result;
	}

	validateField(
		field: Field,
		portal: Portal,
		notApplicableComments?: NotApplicableComments,
		fields?: Field[],
	): FieldValidationResult[] {
		const result: FieldValidationResult[] = [];
		const fieldType =
			field.type === FieldType.Indirect
				? field.originalField.type === FieldType.OptionalSelect
					? field.originalField.type
					: field.type
				: field.type;

		if (!field.isNotApplicable) {
			switch (fieldType) {
				case FieldType.IQSignature: {
					const signatureValidator = new IQSignatureFieldValidator(
						field as IQSignatureField,
					);
					if (!signatureValidator.isFieldValid() && field.name !== "") {
						result.push({
							fieldId: field.id,
							isValid: false,
							validationMessages: signatureValidator.messages,
							sectionId: field.sectionId,
						});
					}
					break;
				}
				case FieldType.GeoPosition: {
					const geoPositionvalidator = new GeoPositionFieldValidator(
						field as GeoPositionField,
					);
					if (!geoPositionvalidator.isFieldValid()) {
						result.push({
							fieldId: field.id,
							isValid: false,
							validationMessages: geoPositionvalidator.messages,
							sectionId: field.sectionId,
						});
					}

					break;
				}
				case FieldType.Indirect: {
					const indirectValidator = new TextFieldValidator(field as IndirectField);
					if (!indirectValidator.isFieldValid((field as IndirectField).value)) {
						result.push({
							fieldId: field.id,
							isValid: false,
							validationMessages: indirectValidator.messages,
							sectionId: field.sectionId,
						});
					}
					break;
				}
				case FieldType.Text: {
					const textValidator = new TextFieldValidator(field as TextField);
					if (!textValidator.isFieldValid((field as TextField).value)) {
						result.push({
							fieldId: field.id,
							isValid: false,
							validationMessages: textValidator.messages,
							sectionId: field.sectionId,
						});
					}
					break;
				}
				case FieldType.Boolean: {
					const boolValidator = new BooleanFieldValidator(field as BooleanField);
					if (!boolValidator.isFieldValid((field as BooleanField).value)) {
						result.push({
							fieldId: field.id,
							isValid: false,
							validationMessages: boolValidator.messages,
							sectionId: field.sectionId,
						});
					}
					break;
				}
				case FieldType.Number: {
					const numberValidator = new NumberFieldValidator(field as BaseNumberField);
					if (
						!numberValidator.isFieldValid(
							field.value !== null && field.value !== undefined
								? field.value.toString()
								: "",
							(field as NumberField).pattern,
							false,
							false,
						)
					) {
						result.push({
							fieldId: field.id,
							isValid: false,
							validationMessages: numberValidator.messages,
							sectionId: field.sectionId,
						});
					}
					break;
				}
				case FieldType.Select: {
					if ((field as MultiSelectField).selectType === SelectFieldType.MultiSelect) {
						const multiSelectValidator = new MultiSelectFieldValidator(
							field as MultiSelectField,
						);
						if (!multiSelectValidator.isFieldValid((field as MultiSelectField).value)) {
							result.push({
								fieldId: field.id,
								isValid: false,
								validationMessages: multiSelectValidator.messages,
								sectionId: field.sectionId,
							});
						}
					} else if (
						(field as SingleSelectField).selectType === SelectFieldType.SingleSelect
					) {
						const singleSelectValidator = new SingleSelectFieldValidator(
							field as SingleSelectField,
						);
						if (
							!singleSelectValidator.isFieldValid((field as SingleSelectField).value)
						) {
							result.push({
								fieldId: field.id,
								isValid: false,
								validationMessages: singleSelectValidator.messages,
								sectionId: field.sectionId,
							});
						}
					} else if (
						(field as EnumSelectField).selectType === SelectFieldType.EnumSingleSelect
					) {
						const singleSelectValidator = new EnumSelectFieldValidator(
							field as EnumSelectField,
						);
						if (!singleSelectValidator.isFieldValid((field as EnumSelectField).value)) {
							result.push({
								fieldId: field.id,
								isValid: false,
								validationMessages: singleSelectValidator.messages,
								sectionId: field.sectionId,
							});
						}
					}
					break;
				}
				case FieldType.Audit: {
					const auditValidator = new AuditFieldValidator(field as AuditField);
					if (
						!auditValidator.isFieldValid(
							(field as AuditField).scoreField.value !== null &&
								(field as AuditField).scoreField.value !== undefined
								? (field as AuditField).scoreField.value!.toString()
								: "",
						)
					) {
						result.push({
							fieldId: field.id,
							isValid: false,
							validationMessages: auditValidator.messages,
							sectionId: field.sectionId,
						});
					}
					break;
				}
				case FieldType.Date: {
					const dateValidator = new DateTimeFieldValidator(field as DateField);
					if (!dateValidator.isFieldValid((field as DateField).value, fields)) {
						result.push({
							fieldId: field.id,
							isValid: false,
							validationMessages: dateValidator.messages,
							sectionId: field.sectionId,
						});
					}
					break;
				}
				case FieldType.Time: {
					const timeValidator = new DateTimeFieldValidator(field as TimeField);
					if (!timeValidator.isFieldValid((field as TimeField).value)) {
						result.push({
							fieldId: field.id,
							isValid: false,
							validationMessages: timeValidator.messages,
							sectionId: field.sectionId,
						});
					}
					break;
				}
				case FieldType.DateTime: {
					const dateTimeValidator = new DateTimeFieldValidator(field as DateTimeField);
					if (!dateTimeValidator.isFieldValid((field as DateTimeField).value)) {
						result.push({
							fieldId: field.id,
							isValid: false,
							validationMessages: dateTimeValidator.messages,
							sectionId: field.sectionId,
						});
					}
					break;
				}
				case FieldType.IQDateTime: {
					const iqdateTimeValidator = new DateTimeFieldValidator(
						field as IQDateTimeField,
					);
					if (!iqdateTimeValidator.isFieldValid((field as IQDateTimeField).value)) {
						result.push({
							fieldId: field.id,
							isValid: false,
							validationMessages: iqdateTimeValidator.messages,
							sectionId: field.sectionId,
						});
					}
					break;
				}
				case FieldType.IQDate: {
					const iqDateValidator = new DateTimeFieldValidator(field as IQDateField);
					if (!iqDateValidator.isFieldValid((field as IQDateField).value)) {
						result.push({
							fieldId: field.id,
							isValid: false,
							validationMessages: iqDateValidator.messages,
							sectionId: field.sectionId,
						});
					}
					break;
				}
				case FieldType.Matrix: {
					const matrixValidator = new MatrixFieldValidator(field as MatrixField);
					if (!matrixValidator.isFieldValid((field as MatrixField).value)) {
						result.push({
							fieldId: field.id,
							isValid: false,
							validationMessages: matrixValidator.messages,
							sectionId: field.sectionId,
						});
					}
					break;
				}
				case FieldType.ProjectSelect: {
					const projectValidator = new ProjectSelectFieldValidator(
						field as ProjectSelectField,
					);
					if (!projectValidator.isFieldValid(field.value)) {
						result.push({
							fieldId: field.id,
							isValid: false,
							validationMessages: projectValidator.messages,
							sectionId: field.sectionId,
						});
					}
					break;
				}
				case FieldType.OrgUnit: {
					const orgUnitValidator = new OrgUnitFieldValidator(field as OrgUnitField);
					if (!orgUnitValidator.isFieldValid(field.value)) {
						result.push({
							fieldId: field.id,
							isValid: false,
							validationMessages: orgUnitValidator.messages,
							sectionId: field.sectionId,
						});
					}
					break;
				}
				case FieldType.Email: {
					const emailValidator = new EmailFieldValidator(field as EmailField);
					if (!emailValidator.isFieldValid((field as EmailField).value)) {
						result.push({
							fieldId: field.id,
							isValid: false,
							validationMessages: emailValidator.messages,
							sectionId: field.sectionId,
						});
					}
					break;
				}
				case FieldType.Behavioural: {
					const behaviouralValidator = new BehaviouralFieldValidator(
						field as BehaviouralField,
					);
					if (
						!behaviouralValidator.isFieldValid(
							(field as BehaviouralField).safeField.value !== null &&
								(field as BehaviouralField).safeField.value !== undefined
								? (field as BehaviouralField).safeField.value!.toString()
								: "",
							(field as BehaviouralField).unSafeField.value !== null &&
								(field as BehaviouralField).unSafeField.value !== undefined
								? (field as BehaviouralField).unSafeField.value!.toString()
								: "",
						)
					) {
						result.push({
							fieldId: field.id,
							isValid: false,
							validationMessages: behaviouralValidator.messages,
							sectionId: field.sectionId,
						});
					}
					break;
				}
				case FieldType.EntitySelect: {
					const entityValidator = new EntitySelectFieldValidator(
						field as EntitySelectField,
					);
					if (!entityValidator.isFieldValid(field.value)) {
						result.push({
							fieldId: field.id,
							isValid: false,
							validationMessages: entityValidator.messages,
							sectionId: field.sectionId,
						});
					}
					break;
				}
				case FieldType.OptionalSelect: {
					const optionalSelectValidator = new OptionalRegisterSelectFieldValidator(
						field as OptionalSelectField,
					);
					if (optionalSelectValidator.shouldValidateRelatedFields()) {
						if (
							field.relatedFields &&
							field.relatedFields.length > 0 &&
							!((field as IndirectField).originalField as OptionalSelectField)
								.selectedFromRegister
						) {
							field.relatedFields.forEach((field) => {
								const res = this.validateField(
									field,
									portal,
									notApplicableComments,
									fields,
								);
								result.push(...res.map((r) => ({ ...r, relatedField: true })));
							});
						}
					} else if (!optionalSelectValidator.isFieldValid(field.value)) {
						result.push({
							fieldId: field.id,
							isValid: false,
							validationMessages: optionalSelectValidator.messages,
							sectionId: field.sectionId,
						});
					}
					break;
				}
				default:
					break;
			}
		}

		const commentsValidator = new CommentBoxValidator(field, notApplicableComments);
		if (!commentsValidator.isFieldValid(field.comments.value)) {
			result.push({
				fieldId: field.id,
				isValid: false,
				validationMessages: commentsValidator.messages,
				sectionId: field.sectionId,
			});
		}
		return result;
	}

	validateMandatoryActions(
		fields: Field[],
		actions: SubModuleRecord[],
		actionsEnabled: boolean,
	): FieldValidationResult[] {
		const result: FieldValidationResult[] = [];

		if (actionsEnabled) {
			fields.forEach((field) => {
				const validator = new MandatoryAdditionsValidator(field);
				const fieldActionsNumber = actions.filter((x) => x.parentId === field.id).length;

				if (!validator.hasMandatoryActions(fieldActionsNumber)) {
					result.push({
						fieldId: field.id,
						isValid: false,
						validationMessages: validator.messages,
						sectionId: field.sectionId,
					});
				}
			});
		}

		return result;
	}

	validateMandatoryAttachments(
		fields: Field[],
		attachments: Attachment[],
		attachmentsEnabled: boolean,
	): FieldValidationResult[] {
		const result: FieldValidationResult[] = [];

		if (attachmentsEnabled) {
			fields.forEach((field) => {
				const validator = new MandatoryAdditionsValidator(field);
				const fieldAttachmentsNumber = attachments.filter(
					(x) => x.parentGuid === field.guid,
				).length;

				if (!validator.hasMandatoryAttachments(fieldAttachmentsNumber)) {
					result.push({
						fieldId: field.id,
						isValid: false,
						validationMessages: validator.messages,
						sectionId: field.sectionId,
					});
				}
			});
		}

		return result;
	}
}
