import { useContext, useState } from "react";
import { useAuth } from "oidc-react";
import { useTranslation } from "react-i18next";
import { APIHealthContext } from "../../../services/health/implementations/healthContext/healthContext";
import { Button } from "../input/Button";
import { IllustratedThemedModal } from "../modals/IllustratedThemedModal";
import { OnlineStatus } from "../../../services/health/implementations/healthMonitor";
import { PortalGuard } from "../PortalGuard";
import { ReactComponent as ErrorIllustration } from "../../assets/svg/illustrations/caution.svg";
import { SignOutModal } from "../modals/SignOutModal";
import { ServiceWorkerUpdateNotification } from "../ServiceWorkerUpdateNotification";
import "./LoginButton.styles.scss";
import { clearPreSignInURL, storePreSignInURL } from "../../../helpers/AuthenticationHelpers";

export const LoginButton = () => {
	const { signIn, userData } = useAuth();
	const [isOpen, setIsOpen] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [showErrorModal, setShowErrorModal] = useState(false);
	const [showCheckUpdate, setShowCheckUpdate] = useState(false);
	const { t } = useTranslation();
	const health = useContext(APIHealthContext);

	const isOffline = health !== OnlineStatus.Available;

	const handleCloseModal = () => setIsOpen(false);

	const handleClickLogin = () => {
		if (isOffline) {
			setShowCheckUpdate(false);
			setErrorMessage(t("error:noLoginAvailableOffline"));
			setShowErrorModal(true);
		} else {
			storePreSignInURL();
			signIn({ prompt: "login" }).catch(() => {
				setShowCheckUpdate(true);
				setErrorMessage(t("error:loginErrorOccurred"));
				setShowErrorModal(true);
				clearPreSignInURL();
			});
		}
	};

	const handleCloseErrorModal = () => {
		setShowErrorModal(false);
	};

	return (
		<PortalGuard>
			<SignOutModal onCancel={handleCloseModal} show={isOpen} />

			<IllustratedThemedModal
				cancelText={t("global:ok")}
				onCancel={handleCloseErrorModal}
				show={showErrorModal}
				text={`${errorMessage}`}
			>
				{showCheckUpdate && (
					<div className="she-login-button__new-version-button">
						<ServiceWorkerUpdateNotification />
					</div>
				)}
				<ErrorIllustration />
			</IllustratedThemedModal>

			<div className="she-login-button__container ">
				<Button
					onClick={() => (userData ? setIsOpen(true) : handleClickLogin())}
					variant="nav"
				>
					{t(`display:authentication.${userData ? "logout" : "login"}`)}
				</Button>
			</div>
		</PortalGuard>
	);
};
