import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { authReducer } from "./components/auth";
import { commonReducer } from "./components/common";
import { portalReducer } from "./components/portal";
import { orgunitReducer } from "./components/orgUnit";
import { questionnaireReducer } from "./components/questionnaire";
import { persistStateMiddleware } from "./middleware";
import { attachmentsReducer } from "./components/attachment";
import { forceLoginGuard } from "./middleware/forceLogingGuard/forceLoginGuard";
import { validationMiddleware } from "./middleware/validationMiddleware";
import type { State } from "./model";

const reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION__
	? window.__REDUX_DEVTOOLS_EXTENSION__()
	: (noop: () => void) => noop;

export const store = createStore(
	combineReducers<State>({
		auth: authReducer,
		common: commonReducer,
		questionnaire: questionnaireReducer,
		portal: portalReducer,
		orgUnit: orgunitReducer,
		recordAttachments: attachmentsReducer,
	}),
	compose(
		applyMiddleware(thunk, forceLoginGuard, persistStateMiddleware, validationMiddleware),
		reduxDevTools,
	),
);
