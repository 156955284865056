import { useMemo } from "react";
import { BooleanFieldValidator } from "../../../../validators/booleanFieldValidator";
import { BooleanInputPresentation } from "./BooleanInput.presentation";
import type { BooleanField } from "../../../../models/fields/BooleanField";

interface Props {
	booleanField: BooleanField;
	updateField: (fieldId: number, value: any) => void;
	updateValidationStatus: (fieldId: number, value: string[]) => void;
}

export const BooleanInputContainer = ({
	booleanField,
	updateField,
	updateValidationStatus,
}: Props) => {
	const validator = useMemo(() => new BooleanFieldValidator(booleanField), [booleanField]);

	const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue: boolean = event.target.value === "true";
		updateField(booleanField.id, newValue);
		validate(newValue);
	};

	const click = (value: boolean) => {
		if (value === booleanField.value) {
			updateField(booleanField.id, null);
			validate(undefined);
		}
	};

	const validate = (value: boolean | undefined) => {
		const validationResult = validator.isFieldValid(value);
		if (validationResult !== (booleanField.validationMessage.length === 0)) {
			updateValidationStatus(booleanField.id, validator.messages);
		}
	};

	return (
		<BooleanInputPresentation
			fieldGroupName={`group_${booleanField.guid}`}
			fieldGuid={booleanField.guid}
			isNotApplicable={booleanField.isNotApplicable}
			onChange={onChange}
			onClick={click}
			required={booleanField.isMandatory}
			value={booleanField.value}
		/>
	);
};
