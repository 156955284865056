export enum SectionType {
	Main,
	Additional,
	iQ,
	Guidance,
	Attachments,
	Actions,
	Submission,
}

export enum FieldType {
	Text,
	Number,
	Boolean,
	Date,
	Time,
	DateTime,
	Select,
	Matrix,
	File,
	OrgUnit,
	Email,
	Audit,
	Behavioural,
	Signature,
	Indirect,
	ProjectSelect,
	SelectableText,
	IQSignature,
	IQDate,
	IQDateTime,
	IQTime,
	GeoPosition,
	PPE,
	EntitySelect,
	EntitySelectWithDefault,
	OptionalSelect,
}

export enum SubmoduleType {
	Main = "Main",
	Actions = "Actions",
	PPE = "PPE",
}

export enum SelectFieldType {
	SingleSelect,
	EnumSingleSelect,
	MultiSelect,
}

export enum LocalFieldIds {
	Name = -1,
	Phone = -2,
	Email = -3,
	EmailCopy = -4,
	Attachments = -5,
	OrgUnit = -6,
	Confidentiality = -7,
	AdditionalEmails = -5,
	ActionTitle = -1001,
	ActionPriority = -1002,
	ActionCategory = -1003,
	ActionDetail = -1004,
	ActionDueDate = -1005,
	ActionForUser = -1006,
	ActionSubCategory = -1007,
	SubmissionAssigned = -2001,
	SubmissionDueDate = -2002,
	SubmissionComments = -2003,
}

export enum LocalGroupIds {
	OrgUnit = "GROUPID_ORGUNIT",
	Details = "GROUPID_DETAILS",
	Attachments = "GROUPID_ATTACHMENTS",
	Confidentiality = "GROUPID_CONFIDENTIALITY",
	Submission = "GROUPID_SUBMISSION",
}

export enum NotApplicableComments {
	Hidden = "Hidden",
	Visible = "Visible",
	Required = "Required",
}

export enum LocalSectionOrderIndex {
	Main = 0,
	Submission = 900,
	Actions = 1000,
	Attachments = 2000,
}

export enum LocalSectionId {
	Main = 0,
	Actions = -1000,
	Attachments = -2000,
	Submission = -3000,
}

export enum QuestionnaireProgressState {
	InProgress = "InProgress",
	Completed = "Completed",
	UploadFailed = "UploadFailed",
	Uploading = "Uploading",
}

export enum DateRestrictionType {
	Day = 1,
	Week = 2,
}

export enum EntitySelectProperty {
	Project = "Project",
	ProjectRisk = "ProjectRisk",
	ActivityRisk = "ActivityRisk",
	User = "User",
	Assigned = "Assigned",
}

export enum EntitySelectPropertyWithDefault {
	ForUser = "ForUser",
}

export enum EntitySelectDefaultSort {
	User = "FullName",
	ForUser = "FullName",
	Project = "EntityReference",
	ProjectRisk = "EntityReference",
	ActivityRisk = "EntityReference",
	Assigned = "FullName",
}

export enum EntitySelectServiceEndpointMap {
	ForUser = "User",
	Submission = "Assigned",
	Assigned = "User",
}

export enum EntityApiUrl {
	Assigned = "Assignees",
	Default = "Links",
}

export enum RegisterSelectDefaultSort {
	EquipmentPrimaryReference = "EquipmentName",
	EquipmentSecondaryReference = "EquipmentName",
	PersonReference = "FullName",
}

export enum QuestionnaireEncryptionVersion {
	V1 = "goplus_key",
	V2 = "goplus_cryptokey",
}
