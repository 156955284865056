import { t } from "i18next";
import { isAttachmentSizeCorrect } from "../../helpers/FileHelper";
import type { Attachment } from "../../models/attachments/Attachment";
import type { Questionnaire } from "../../models/questionnaire/Questionnaire";

export class FileValidator {
	questionnaire: Questionnaire;
	attachmentSizeLimit: number;
	excludedFileTypes: string[];
	messages: string[];
	validateAttachmentLimit: boolean;
	fileTooBig: boolean;
	fileEmpty: boolean;

	constructor(
		questionnaire: Questionnaire,
		attachmentSizeLimit: number,
		excludedFileTypes: string[],
		validateAttachmentLimit = true,
	) {
		this.attachmentSizeLimit = attachmentSizeLimit;
		this.questionnaire = questionnaire;
		this.excludedFileTypes = excludedFileTypes;
		this.messages = [];
		this.validateAttachmentLimit = validateAttachmentLimit;
		this.fileTooBig = false;
		this.fileEmpty = false;
		this.excludedFileTypes.shift();
	}

	async isAttachmentValid(attachment: Attachment) {
		this.messages = [];
		return (
			this.isAttachmentsEnabled() &&
			this.isAcceptableFileType(attachment.file.name) &&
			this.isBelowSizeLimit(attachment) &&
			(await this.isSizeCorrect(attachment))
		);
	}

	isBelowSizeLimit(attachment: Attachment): boolean {
		let result = true;
		const attachmentSize = attachment.file.size / 1024;
		this.fileTooBig = attachmentSize > this.attachmentSizeLimit;
		if (attachmentSize > this.attachmentSizeLimit) {
			this.messages.push(
				t("validation:maxAttachmentSizeMessage", {
					maxSize: this.attachmentSizeLimit,
				}),
			);
			result = false;
		}

		return result;
	}

	async isSizeCorrect(attachment: Attachment) {
		if (!(await isAttachmentSizeCorrect(attachment))) {
			this.messages.push(t("validation:attachmentSizeIncorrect"));
			this.fileEmpty = true;
			return false;
		}
		return true;
	}

	isBelowAttachmentLimit(noOfAttachments: number): boolean {
		let result = true;

		if (this.questionnaire.maxAttachments && this.validateAttachmentLimit) {
			if (noOfAttachments > this.questionnaire.maxAttachments) {
				this.messages.push(t("validation:maxAttachmentsMessage"));
				result = false;
			}
		}

		return result;
	}

	isAcceptableFileType(type: string): boolean {
		let result = true;

		// Coverts the type format. For examples "image/png" to ".png".
		const formattedType = type.substring(type.lastIndexOf(".") + 1).toLocaleLowerCase();
		if (this.excludedFileTypes.includes(formattedType)) {
			this.messages.push(
				t("validation:unacceptableFileTypeMessage", {
					excludedFileTypes: this.excludedFileTypes.join(", "),
				}),
			);
			result = false;
		}

		return result;
	}

	isAttachmentsEnabled(): boolean {
		let result = true;

		if (
			!this.questionnaire.maxAttachments ||
			(this.questionnaire.maxAttachments && this.questionnaire.maxAttachments <= 0)
		) {
			this.messages.push(t("validation:attachmentsDisabledMessage"));
			result = false;
		}

		return result;
	}
}
