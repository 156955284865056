import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { LocalFieldIds } from "../../../../models/questionnaire";
import { TextInputPresentation } from "./TextInput.presentation";
import type { TextField, SimplifiedTextField } from "../../../../models/fields/TextField";
import type { TextFieldValidator } from "../../../../validators/textFieldValidator";
import type { SelectableTextField } from "../../../../models/fields/SelectableTextField";
import type { IndirectField } from "../../../../models/fields/IndirectField";

interface Props {
	isExternalValidationRequired?: boolean;
	onValidation?: () => void;
	textField: TextField | IndirectField | SelectableTextField | SimplifiedTextField;
	updateField: (value: any) => void;
	updateValidationStatus: (value: string[]) => void;
	validator: TextFieldValidator;
}

export const TextInputContainer = ({
	isExternalValidationRequired,
	onValidation,
	textField,
	updateField,
	updateValidationStatus,
	validator,
}: Props) => {
	const { t } = useTranslation();
	const [isInvalid, setIsInvalid] = useState(false);
	const [fieldValue, setFieldValue] = useState(textField.value);
	let hint = "";

	let autoComplete = "off";

	if ((textField as TextField).id === LocalFieldIds.Phone) {
		autoComplete = "tel";
	}

	if ((textField as TextField).id === LocalFieldIds.Name) {
		autoComplete = "name";
	}

	const onChange = (output?: string) => {
		setFieldValue(output);

		if (autoComplete !== "off") {
			updateField(output);
		}
	};

	const onBlur = () => {
		const validationResult = validator.isFieldValid(fieldValue);
		updateField(fieldValue);
		updateValidationStatus(validator.messages);
		setIsInvalid(!validationResult);
	};

	if (textField.maxLength || textField.minLength) {
		hint = t("display:hintTextFieldLength", {
			minLength: textField.minLength || 0,
			maxLength: textField.maxLength || t("display:labelUnlimited"),
		});
	}

	// method to trigger request for validation if field value has been updated outside of this component
	useEffect(() => {
		if (isExternalValidationRequired) {
			const validationResult = validator.isFieldValid(textField.value);
			updateValidationStatus(validator.messages);
			setIsInvalid(!validationResult);

			if (onValidation) {
				onValidation();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isExternalValidationRequired]);

	useEffect(() => {
		setFieldValue(textField.value);
	}, [textField.value]);

	return (
		<TextInputPresentation
			autoCompleteValue={autoComplete}
			guid={textField.guid}
			hint={hint}
			isInvalid={isInvalid}
			isMandatory={textField.isMandatory}
			isNotApplicable={textField.isNotApplicable}
			isScannable={textField.isScannable}
			maxLength={textField.maxLength}
			name={textField.name}
			onBlur={onBlur}
			onChange={(e) => onChange(e)}
			rows={textField.rows || 1}
			value={fieldValue || ""}
		/>
	);
};
