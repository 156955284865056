import { useCallback, useState, useContext, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "oidc-react";
import { PortalViewType, PortalAuthenticationMode } from "../../../../../models/portal";
import {
	sortQuestionnaireStubs,
	removeQuestionnaireFromDevice,
	markQuestionnaireAsDownloadToDevice,
} from "../../../../../state";
import { StartQuestionnaireModal } from "../StartQuestionnaireModal";
import { ToastContext } from "../../../../components/toast/context/ToastContext";
import { APIHealthContext } from "../../../../../services/health/implementations/healthContext/healthContext";
import { OnlineStatus } from "../../../../../services/health/implementations/healthMonitor";
import { ConfirmationModal, NotificationModal } from "../../../../components/modals";
import { isBrowserIE } from "../../../../../helpers/ieHelper";
import { usePortalPath } from "../../../../../helpers/UsePortalPath";
import { useResetHighlight } from "../../../../../helpers/UseHighlight";
import { Toast } from "../../../../components/toast/component/Toast";
import { PortalQuestionnaireListPresentation } from "./PortalQuestionnaireList.presentation";
import type { PortalTranslationLanguage } from "../../../../../types/i18next";
import type { HistoryLocationState } from "../../../../../types/History";
import type { State } from "../../../../../state";
import type { Portal, QuestionnaireStub } from "../../../../../models/portal";
import type { StaticContext } from "react-router";
import type { RouteComponentProps } from "react-router-dom";

interface Props extends RouteComponentProps<any, StaticContext, HistoryLocationState> {
	portal: Portal;
}

type sortFn = <T>(a: T, b: T) => number;
const sortFunctions: Record<string, sortFn> = {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	default: (a: any, b: any) => 0,
	az: (a: any, b: any) => a.title.localeCompare(b.title),
	za: (a: any, b: any) => b.title.localeCompare(a.title),
};

export const PortalQuestionnaireListContainer = withRouter((props: Props) => {
	const { t } = useTranslation();
	const sortFunctionKey = useSelector<State, string>((s) => s.portal.sortingKey);
	const dispatch = useDispatch();
	const toastContext = useContext(ToastContext);
	const health = useContext(APIHealthContext);

	const [questionnaireSelected, setQuestionnaireSelected] = useState<
		QuestionnaireStub | undefined
	>(undefined);
	const [showModal, setShowModal] = useState(false);
	const [showRemoveModal, setShowRemoveModal] = useState(false);
	const [contextMenu, setContextMenu] = useState<HTMLButtonElement | null>(null);
	const [templateToDelete, setTemplateToDelete] = useState("");
	const offlineEnabled = isBrowserIE() === false;
	const portalPath = usePortalPath();
	const [searchTerm, setSearchTerm] = useState("");
	const { userData } = useAuth();
	const [showAuthorizedOnlyModal, setShowAuthorizedOnlyModal] = useState(false);

	useResetHighlight(props.history, props.location);

	const onQuestionnaireSelected = useCallback(
		(questionnaireKey: string) => {
			const questionnaireSelected = props.portal.questionnaireStubs.find(
				(q) => q.key === questionnaireKey,
			);
			if (questionnaireSelected?.authenticationRequired && !userData) {
				setShowAuthorizedOnlyModal(true);
			} else {
				setQuestionnaireSelected(questionnaireSelected);
				setShowModal(true);
			}
		},
		[props.portal.questionnaireStubs, userData],
	);

	const onQuestionnaireSelectKeyboard = useCallback(
		(questionnaireKey: string, keyPressed: string) => {
			if (keyPressed === "Enter" || keyPressed === " ") {
				onQuestionnaireSelected(questionnaireKey);
			}
		},
		[onQuestionnaireSelected],
	);

	const onStartQuestionnaire = useCallback(() => {
		if (questionnaireSelected) {
			if (
				health === OnlineStatus.Available ||
				(health.toString() !== OnlineStatus.Available &&
					questionnaireSelected.makeAvailableOffline === true) ||
				offlineEnabled === false
			) {
				props.history.replace(props.location.pathname, {
					...props.location.state,
					highlightRecord: questionnaireSelected.key,
				} as any);
				props.history.push(
					`${portalPath}${PortalViewType.Forms}/${questionnaireSelected.key}`,
				);
			} else {
				let uuid = "";
				const onCloseToast = () => toastContext.removeToast(uuid);
				uuid = toastContext.putToast({
					content: (
						<Toast onClick={onCloseToast} variant="error">
							{t("display:labelUnavailableOffline")}
						</Toast>
					),
					timeout: 3000,
				});
			}

			setShowModal(false);
		}
	}, [
		props.history,
		questionnaireSelected,
		toastContext,
		health,
		t,
		offlineEnabled,
		portalPath,
		props.location.pathname,
		props.location.state,
	]);

	const onCancelModal = useCallback(() => {
		setShowModal(false);
	}, []);

	const onRemoveQuestionnaire = useCallback(
		(questionnaireKey: string, contextMenuButton: HTMLButtonElement | null) => {
			setTemplateToDelete(questionnaireKey);
			setContextMenu(contextMenuButton);
			setShowRemoveModal(true);
		},
		[],
	);

	const onRemoveQuestionnaireCancel = useCallback(() => {
		if (contextMenu) {
			contextMenu.focus();
		}
		setShowRemoveModal(false);
	}, [contextMenu]);

	const onRemoveQuestionnaireConform = useCallback(() => {
		dispatch(removeQuestionnaireFromDevice(templateToDelete, props.portal.key));
		setShowRemoveModal(false);
	}, [dispatch, props.portal.key, templateToDelete]);

	const onDownloadQuestionnaire = useCallback(
		(questionnaireKey: string) => {
			dispatch(markQuestionnaireAsDownloadToDevice(questionnaireKey, props.portal.key));
		},
		[dispatch, props.portal.key],
	);

	const onSearchTermChange = (s: string) => {
		setSearchTerm(s);
	};

	const questionnairesToShow = useMemo(() => {
		const searchTermLowerCase = searchTerm.toLocaleLowerCase().trim();
		return props.portal.questionnaireStubs
			.filter(
				(q) =>
					q.title.toLocaleLowerCase().includes(searchTermLowerCase) ||
					q.description
						.toLocaleLowerCase()
						.replace(/(<([^>]+)>)/gi, "")
						.includes(searchTermLowerCase) ||
					t(`display:language${q.language.replace("-", "") as PortalTranslationLanguage}`)
						.toLocaleLowerCase()
						.includes(searchTermLowerCase),
			)
			.filter(
				(q) =>
					!(
						props.portal.authenticationMode === PortalAuthenticationMode.Public &&
						q.authenticationRequired
					),
			)
			.sort(sortFunctions[sortFunctionKey]);
	}, [
		props.portal.questionnaireStubs,
		props.portal.authenticationMode,
		searchTerm,
		sortFunctionKey,
		t,
	]);

	const emptyListMessage = useMemo(() => {
		if (questionnairesToShow.length > 0) {
			return null;
		}

		return searchTerm ? t("display:labelFormsEmptySearch") : t("display:labelFormsEmptyList");
	}, [questionnairesToShow, searchTerm, t]);

	return (
		<>
			<PortalQuestionnaireListPresentation
				displayLanguage={props.portal.userLanguages.length > 1}
				emptyListMessage={emptyListMessage}
				highlightRecord={props.location.state?.highlightRecord ?? ""}
				onDownloadQuestionnaire={onDownloadQuestionnaire}
				onQuestionnaireSelect={onQuestionnaireSelected}
				onQuestionnaireSelectKeyboard={onQuestionnaireSelectKeyboard}
				onRemoveQuestionnaire={onRemoveQuestionnaire}
				onSearchTermChange={onSearchTermChange}
				onSortChange={(k) => dispatch(sortQuestionnaireStubs(k))}
				questionnaireStubs={questionnairesToShow}
				searchTerm={searchTerm}
				sortValue={sortFunctionKey}
			/>
			{questionnaireSelected && (
				<StartQuestionnaireModal
					displayLanguage={props.portal.userLanguages.length > 1}
					onCancel={onCancelModal}
					onOk={onStartQuestionnaire}
					questionnaireStub={questionnaireSelected}
					show={showModal}
				/>
			)}
			<ConfirmationModal
				cancelText={t("display:buttonNoCancel")}
				confirmText={t("display:buttonYesRemove")}
				onCancel={onRemoveQuestionnaireCancel}
				onConfirm={onRemoveQuestionnaireConform}
				show={showRemoveModal}
				text={t("display:labelAreYouSureRemoveForm")}
			/>
			<NotificationModal
				content={t("display:labelFormOnlyAvailableToAuthorizedUsers")}
				onClose={() => setShowAuthorizedOnlyModal(false)}
				show={showAuthorizedOnlyModal}
				withHistory={false}
			/>
		</>
	);
});
