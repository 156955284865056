import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "../Modal";
import { ModalFooter } from "../ModalFooter";
import { ModalHeader } from "../ModalHeader";

interface Props {
	show: boolean;
	title?: string;
	padded?: boolean;
	large?: boolean;
	okText?: string;
	cancelText?: string;
	onOk: () => void;
	onCancel: () => void;
	showOkBtn?: boolean;
	showCancelBtn?: boolean;
	primaryCancelBtn?: boolean;
	withHistory?: boolean;
	formId?: string | null;
	children?: React.ReactNode;
}

export const FormModal = ({
	onCancel,
	onOk,
	show,
	cancelText,
	children,
	formId,
	large,
	okText,
	padded,
	primaryCancelBtn,
	showCancelBtn,
	showOkBtn,
	title,
	withHistory,
}: Props) => {
	const { t } = useTranslation();

	const okTextMemo = useMemo(() => okText ?? t("global:ok"), [okText, t]);
	const cancelTextMemo = useMemo(() => cancelText ?? t("global:cancel"), [cancelText, t]);

	return (
		<Modal
			fullHeight={large}
			fullScreenMobile
			onClose={onCancel}
			show={show}
			withHistory={withHistory}
		>
			{title && <ModalHeader text={title} />}
			<div
				className={`${
					padded !== false ? "she-components-modal-body-padded" : ""
				} she-components-modal-body-form`}
			>
				{children}
			</div>
			<ModalFooter
				buttons={[
					{
						text: cancelTextMemo,
						primary: primaryCancelBtn === true,
						isShown: showCancelBtn,
						onClick: onCancel,
						testId: "form-modal-cancel",
					},
					{
						text: okTextMemo,
						primary: true,
						isShown: showOkBtn,
						onClick: onOk,
						formId: formId ? formId : "",
						testId: "form-modal-ok",
						type: "submit",
					},
				]}
			/>
		</Modal>
	);
};
