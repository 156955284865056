import { FormModal } from "../../../components/modals";
import { Checkbox } from "../../../components/Checkbox";
import type { QuestionnaireStub } from "../../../../models/portal";

interface Props {
	save: () => void;
	onChange: (key: number) => void;
	onCancel: () => void;
	selectedValues: number[];
	stubs?: QuestionnaireStub[];
	show: boolean;
	showPerfWarning: boolean;
	modalTitle: string;
	setupQuestion: string;
	changeLater: string;
	perfWarning: string;
	cancelText: string;
	confirmText: string;
}

export const InitialSetupPresentation = ({
	save,
	onChange,
	onCancel,
	selectedValues,
	stubs,
	show,
	showPerfWarning,
	modalTitle,
	setupQuestion,
	changeLater,
	perfWarning,
	cancelText,
	confirmText,
}: Props) => {
	return (
		<FormModal
			cancelText={cancelText}
			okText={confirmText}
			onCancel={onCancel}
			onOk={save}
			show={show}
			showCancelBtn
			title={modalTitle}
		>
			<div className="she-components-control">
				<p className="she-components-control-question ">{setupQuestion}</p>
				<p className="she-components-control-subtitle ">
					{changeLater} {showPerfWarning ? perfWarning : null}
				</p>
			</div>
			<div data-testid="offline-setup-group" role="group">
				<ul className="she-components-checkbox-group">
					{stubs &&
						stubs.map((stub) => (
							<li className="she-components-checkbox" key={stub.key}>
								<Checkbox
									checked={selectedValues.includes(Number.parseInt(stub.key))}
									id={stub.key}
									name={stub.title}
									onChange={() => {
										onChange(Number.parseInt(stub.key));
									}}
									value={Number.parseInt(stub.key)}
								/>
							</li>
						))}
				</ul>
			</div>
		</FormModal>
	);
};
