import { t } from "i18next";
import type { ProjectSelectField } from "../../models/fields/ProjectSelctField";
import type { Project } from "../../models/project/project";

export class ProjectSelectFieldValidator {
	messages: string[] = [];

	constructor(public projectField: ProjectSelectField) {}

	isFieldValid = (value?: Project): boolean => {
		let result = true;

		if (this.projectField.isMandatory && value === undefined) {
			this.messages.push(t("validation:mandatoryFieldMessage"));
			result = false;
		}

		return result;
	};
}
