import { t } from "i18next";
import type { GeoPositionField } from "../../models/fields/GeoPositionField";

export class GeoPositionFieldValidator {
	geoPositionField: GeoPositionField;
	messages: string[];

	constructor(geoPositionField: GeoPositionField) {
		this.geoPositionField = geoPositionField;
		this.messages = [];
	}

	isFieldValid = (): boolean => {
		this.messages = [];

		if (this.geoPositionField.isMandatory) {
			if (
				this.geoPositionField.validation &&
				this.geoPositionField.validation === "LocationTextField" &&
				!this.geoPositionField.description.value
			) {
				// description is mandatory - don't have to check map position
				this.messages.push(t("validation:invalidGeoPositionDescriptionMessage"));
				return false;
			} else if (
				(this.geoPositionField.lat.value === undefined ||
					this.geoPositionField.lat.value === null ||
					this.geoPositionField.long.value === undefined ||
					this.geoPositionField.long.value === null) &&
				!this.geoPositionField.description.value
			) {
				// description or map position has to be provided
				this.messages.push(t("validation:invalidGeoPositionDescriptionOrPosition"));
				return false;
			}
		}

		return true;
	};
}
