import { useCachedUpdate } from "../../../utilities/use-cached-update";
import { TextBox } from "./TextBox.component";
import type { TextBoxProps } from "./TextBox.component";

export const CachedTextBox = ({
	onChange,
	value: propValue,
	disabled,
	extraClassName,
	id,
	label,
	large,
	maxLength,
	onBlur,
	onFocus,
	placeholder,
	reference,
}: TextBoxProps) => {
	const { value, debouncedChange, immediateChange } = useCachedUpdate(propValue, onChange);

	return (
		<TextBox
			disabled={disabled}
			extraClassName={extraClassName}
			id={id}
			label={label}
			large={large}
			maxLength={maxLength}
			onBlur={(value) => {
				immediateChange(value);
				onBlur?.(value);
			}}
			onChange={debouncedChange}
			onFocus={onFocus}
			placeholder={placeholder}
			reference={reference}
			value={value}
		/>
	);
};
