import { useTranslation } from "react-i18next";
import { ReactComponent as IconBack } from "../../../../assets/svg/arrow-left.svg";
import "./QuestionnaireHeader.styles.scss";

interface Props {
	title: string;
	backButtonPressed: () => void;
}

export const QuestionnaireHeader = ({ backButtonPressed, title }: Props) => {
	const { t } = useTranslation();

	return (
		<header>
			<div className="she-questionnaire-header-container">
				<button
					aria-label={t("global:cancel")}
					className="she-icon she-questionnaire-header-icon"
					onClick={backButtonPressed}
					type="button"
				>
					<IconBack />
				</button>

				<h3>{title}</h3>
			</div>
		</header>
	);
};
