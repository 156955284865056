import { useTranslation } from "react-i18next";
import { saveAs } from "file-saver";
import { useDispatch } from "react-redux";
import { getBlobUrl } from "../../../helpers/BlobHelper";
import { Modal, ConfirmationModal } from "../modals";
import { LargeImage } from "../LargeImage";
import { ReactComponent as Spots } from "../../assets/svg/navigation-menu-vertical.svg";
import { ReactComponent as DefaultIcon } from "../../assets/svg/non-image-expandable.svg";
import { ReactComponent as Bin } from "../../assets/svg/bin-1.svg";
import { ReactComponent as Pencil } from "../../assets/svg/streamline-icon-pencil-write.svg";
import { ReactComponent as Download } from "../../assets/svg/download.svg";
import { ReactComponent as Edit } from "../../assets/svg/image-file-edit.svg";
import { setListOfEditedAttachments } from "../../../state/components/attachment";
import { LocalSectionId } from "../../../models/questionnaire";
import type { Attachment } from "../../../models/attachments/Attachment";
import "./Thumbnail.styles.scss";

interface Props {
	attachment: Attachment;
	isOpen: boolean;
	areYouSure: boolean;
	displayOptions: boolean;
	onClick: () => void;
	onKeyPress: (key: string) => void;
	onCancel: () => void;
	onDelete: () => void;
	onDoNotDelete: () => void;
	onOpenMenu: (showMenu: boolean) => void;
	onDeleteAttachment: (attachmentId: string) => void;
	onDisplayMenu: (showMenu: boolean) => void;
	isEditing: boolean;
	hideControls: boolean;
	onEdit: () => void;
	onEditSave: (dataURI: string) => void;
	onEditClose: () => void;
	displayEditButton: boolean;
	subModuleId?: LocalSectionId;
}

export const ThumbnailPresentation = ({
	attachment,
	isOpen,
	areYouSure,
	displayOptions,
	onClick,
	onKeyPress,
	onCancel,
	onDelete,
	onDoNotDelete,
	onOpenMenu,
	onDeleteAttachment,
	onDisplayMenu,
	isEditing,
	hideControls,
	onEdit,
	onEditSave,
	onEditClose,
	displayEditButton,
	subModuleId,
}: Props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const blobUrl = getBlobUrl(attachment.file, attachment.isSVG);
	return (
		<>
			<div>
				<div
					className={`she-thumbnail-list-image ${
						attachment.isImage || attachment.isSVG ? "she-thumbnail-clickable" : ""
					}`}
					onClick={() => {
						if (attachment.isImage || attachment.isSVG) {
							onClick();
						}
					}}
					onKeyPress={(event) => onKeyPress(event.key)}
					tabIndex={0}
				>
					<div>
						{attachment.isImage || attachment.isSVG ? (
							<div className="she-thumbnail-image">
								<img
									alt={
										attachment.originalFileName ||
										attachment.file.name ||
										attachment.fileName
									}
									draggable={false}
									src={blobUrl}
								/>
							</div>
						) : (
							<div
								className="she-thumbnail-expanding-icon"
								id={`expander-${attachment.id}`}
							>
								<DefaultIcon />
							</div>
						)}
					</div>
					<div className="she-thumbnail-text-wrapper">
						<div className="she-thumbnail-filename" id={`filename-${attachment.id}`}>
							{attachment.originalFileName ||
								attachment.file.name ||
								attachment.fileName}
						</div>
						{attachment.description && (
							<div className="she-thumbnail-description">
								{attachment.description}
							</div>
						)}
					</div>

					<div className="she-thumbnail-more-options">
						<button
							aria-label={t("display:buttonOptions")}
							className="she-icon"
							onClick={(e) => {
								e.stopPropagation();
								onOpenMenu(!displayOptions);
							}}
							type="button"
						>
							<Spots />
						</button>
					</div>
				</div>
				<div
					className={`she-thumbnail-options-overlay ${
						displayOptions ? "showOptions " : ""
					}`}
					onClick={() => {
						onDisplayMenu(false);
					}}
				/>
				<div
					className={`she-thumbnail-options-wrapper ${
						displayOptions ? "showOptions " : ""
					}`}
				>
					<div className="she-thumbnail-options">
						{(subModuleId === undefined || subModuleId !== LocalSectionId.Actions) && (
							<div className="she-thumbnail-option">
								<button
									// eslint-disable-next-line jsx-a11y/no-autofocus
									autoFocus
									onClick={() => {
										onDisplayMenu(false);
										dispatch(setListOfEditedAttachments([attachment.id]));
									}}
									tabIndex={0}
									type="button"
								>
									<div>
										<Pencil />
										<span>{t("display:labelDescription")}</span>
									</div>
								</button>
							</div>
						)}
						{displayEditButton && (
							<div className="she-thumbnail-option">
								<button
									onClick={() => {
										onDisplayMenu(false);
										onEdit();
									}}
									type="button"
								>
									<div>
										<Edit />
										<span>{t("display:buttonEditPicture")}</span>
									</div>
								</button>
							</div>
						)}
						<div className="she-thumbnail-option">
							<button
								onClick={() => {
									onDisplayMenu(false);
									saveAs(
										attachment.file,
										attachment.originalFileName ||
											attachment.file.name ||
											attachment.fileName,
									);
								}}
								type="button"
							>
								<div>
									<Download />
									<span>{t("display:buttonDownload")}</span>
								</div>
							</button>
						</div>
						<div className="she-thumbnail-option">
							<button
								// eslint-disable-next-line jsx-a11y/no-autofocus
								autoFocus
								onClick={onDelete}
								tabIndex={0}
								type="button"
							>
								<div>
									<Bin />
									<span>{t("display:buttonDelete")}</span>
								</div>
							</button>
						</div>
					</div>
				</div>
			</div>
			<Modal
				blockUi={isEditing}
				customOverlayClassName="she-components-modal-lightbox"
				onClose={onCancel}
				show={isOpen}
			>
				<LargeImage
					alt={attachment.originalFileName || attachment.file.name || attachment.fileName}
					backButtonText={t("global:close")}
					deleteButtonText={t("display:buttonDeletePicture")}
					displayEditButton={displayEditButton}
					editButtonText={t("display:buttonEditPicture")}
					hideControls={hideControls}
					isEditing={isEditing}
					onCancel={onCancel}
					onDelete={onDelete}
					onEdit={onEdit}
					onEditClose={onEditClose}
					onEditSave={onEditSave}
					src={blobUrl}
					type={attachment.file.type}
				/>
			</Modal>
			<ConfirmationModal
				cancelText={t("global:no")}
				confirmText={t("global:yes")}
				onCancel={onDoNotDelete}
				onConfirm={() => onDeleteAttachment(attachment.id)}
				show={areYouSure}
				text={t("display:labelDeleteAttachmentMessage")}
			/>
		</>
	);
};
