import { PPEIconSvg } from "../../../../../components/PPEIcon/PPEIconSvg.component";
import type { PPECategoryValue } from "../../../../../../models/fields/PPEField";

export interface Props {
	value: PPECategoryValue;
}

export const PPESubmoduleCardItem = ({ value }: Props) => {
	return (
		<>
			<PPEIconSvg iconId={value.iconId} />
			<div>
				<div className="she-ppe-submodule-item__category">{value.name}</div>
				{value.option && (
					<div className="she-ppe-submodule-item__option">{value.option.name}</div>
				)}
			</div>
		</>
	);
};
