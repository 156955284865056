import { useMemo } from "react";

interface Props {
	text: string;

	foregroundColour?: string;
	backgroundColour?: string;
}

export const ModalHeaderPresentation = ({ text, backgroundColour, foregroundColour }: Props) => {
	const headerStyles = useMemo<React.CSSProperties | undefined>(() => {
		return backgroundColour ? { background: backgroundColour } : undefined;
	}, [backgroundColour]);

	const h3Styles = useMemo<React.CSSProperties | undefined>(() => {
		return foregroundColour ? { color: foregroundColour } : undefined;
	}, [foregroundColour]);

	return (
		<header className="she-components-modal-header" style={headerStyles}>
			<h3 style={h3Styles}>{text}</h3>
		</header>
	);
};
