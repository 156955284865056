import { useState, useCallback, useEffect } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useDetailedSubmitError } from "../../../../utilities/UseDetailedSubmitError";
import { IllustratedThemedModal } from "../../../../components/modals/IllustratedThemedModal";
import { DefaultLocalDataService } from "../../../../../services/localData";
import { getDateFormat } from "../../../../../helpers/DateTimeInputHelper";
import { getAuthUser } from "../../../../../helpers/AuthenticationHelpers";
import type { Portal, RecordStub } from "../../../../../models/portal";
import "./QueuedUploadError.styles.scss";

interface Props {
	cancelButtonClicked: () => void;
	displayErrors: boolean;
	portal: Portal;
}

export const QueuedUploadError = ({ cancelButtonClicked, displayErrors, portal }: Props) => {
	const { t } = useTranslation();
	const portalStubs = portal.questionnaireStubs;
	const [questionnaireRecords, setQuestionnaireRecords] = useState<RecordStub[]>([]);
	const [dateFormat, setDateFormat] = useState("");

	const sortFunc = (a: RecordStub, b: RecordStub) => {
		return a.dateCompleted < b.dateCompleted ? 1 : -1;
	};

	const getFailedUploads = useCallback(async () => {
		const localDataService = new DefaultLocalDataService();
		const user = await getAuthUser();
		const records = await localDataService.getFailedUploads(portal.key, user);

		const recordStubs: RecordStub[] = [];
		for (const record of records) {
			const templateStub = portalStubs.find(
				(s) => s.key === record.questionnaire.templateId.toString(),
			);
			if (templateStub !== undefined && templateStub !== null) {
				const recordStub: RecordStub = {
					id: record.questionnaire.id,
					name: record.questionnaire.name,
					dateCreated: record.status.dateCreated ? record.status.dateCreated : new Date(),
					templateStub,
					state: record.status.questionnaireState,
					dateCompleted: record.status.dateCompleted
						? record.status.dateCompleted
						: new Date(),
					errors: record.status.errors,
				};

				recordStubs.push(recordStub);
			}
		}

		recordStubs.sort(sortFunc);
		setQuestionnaireRecords(recordStubs);
	}, [portalStubs, portal.key]);

	if (dateFormat.length === 0) {
		setDateFormat(getDateFormat());
	}

	useEffect(() => {
		getFailedUploads();
	}, [getFailedUploads]);

	const detailedError = useDetailedSubmitError();

	return (
		<IllustratedThemedModal
			cancelText={t("global:ok")}
			onCancel={cancelButtonClicked}
			show={displayErrors}
			text={t("display:labelUploadError")}
		>
			<div>
				<div className="she-upload-error-wrapper">
					{questionnaireRecords.map((q: RecordStub, i: number) => (
						<div className="she-upload-error-form" key={i}>
							<p className="she-error-questionnaire-title">{q.name}</p>
							<p className="she-error-questionnaire-data">
								{`${t("display:labelDateCompleted")}: ${moment(
									q.dateCompleted,
								).format(dateFormat)}`}
							</p>
							<p className="she-record-submission-failure">
								{q.errors
									? detailedError(q.errors)
									: t("display:labelSubmissionFailedError")}
							</p>
						</div>
					))}
				</div>
				<p className="she-record-submission-failure-subtitle">
					{t("display:labelUploadErrorsStillInQueue")}
				</p>
			</div>
		</IllustratedThemedModal>
	);
};
