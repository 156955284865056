import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory, useRouteMatch } from "react-router-dom";
import { ConfirmationModal, FormModal } from "../../../../components/modals";
import { CreateAction } from "../CreateAction/CreateAction.component";
import { DefaultActionsService } from "../../../../../services/actions";
import { DefaultAttachmentService } from "../../../../../services/attachment";
import { fileNameToPost } from "../../../../../helpers/FileHelper";
import { getDateFormat } from "../../../../../helpers/DateTimeInputHelper";
import { IllustratedThemedModal } from "../../../../components/modals/IllustratedThemedModal";
import { Loading } from "../../../../components/Loading";
import { ReactComponent as ErrorIllustration } from "../../../../assets/svg/illustrations/falling.svg";
import { useManageActionAttachments } from "../../../../utilities/UseManageActionAttachments";
import { usePortalPath } from "../../../../../helpers/UsePortalPath";
import { switchMatchingLanguageCode } from "../../../../../helpers/LanguageCodeHelper";

interface Match {
	params: { action: string };
}

interface Props {
	closeCallback: () => void;
	refetch: () => void;
}

export const CreateActionModal = ({ closeCallback, refetch }: Props) => {
	const { t, i18n } = useTranslation();
	const history = useHistory();
	const customerKey = window.location.pathname.split("/")[1];
	const portalKey = window.location.pathname.split("/")[3];
	const match: Match | null = useRouteMatch("/:customerKey/p/:portalKey/actions/:action(create)");

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isSubmitError, setIsSubmitError] = useState(false);
	const [isConfirmationModalActive, setIsConfirmationModalActive] = useState(false);

	const portalPath = usePortalPath();

	const service = useMemo(
		() =>
			new DefaultActionsService({
				subdomain: "Action",
			}),
		[],
	);

	const { handleSubmit, formState, ...methods } = useForm({
		mode: "onBlur",
		criteriaMode: "all",
	});

	const { attachmentsToAdd, attachmentsToRemove, ...attachmentsProps } =
		useManageActionAttachments([]);

	const redirectToList = useCallback(() => {
		history.push(`${portalPath}actions`);
	}, [history, portalPath]);

	const onCancel = () => {
		if (attachmentsToAdd.length || attachmentsToRemove.length || formState.isDirty) {
			setIsConfirmationModalActive(true);
			return;
		}
		redirectToList();
		closeCallback();
	};

	const onOk = useCallback(() => {
		handleSubmit((data) => {
			const attachmentsService = new DefaultAttachmentService({
				subdomain: "ActionAttachments",
			});

			setIsSubmitting(true);
			const action = { ...data };

			if (action.ActionCategory === "-1") {
				action.ActionCategory = null;
			}

			if (action.ActionSubCategory === "-1") {
				action.ActionSubCategory = null;
			}

			if (attachmentsToAdd.length) {
				action.AddAttachments = attachmentsToAdd.map((attachment) => ({
					FileName: fileNameToPost(attachment.Identifier, attachment.FileName),
					FileDescription: "",
				}));
			}

			if (action.DueDate) {
				const dueDate = moment(data.DueDate, getDateFormat(), true).utc(true);
				action.DueDate = dueDate.toISOString();
			}

			if (action.ForUserIds) {
				action.ForUserIds = [action.ForUserIds.id];
			}

			attachmentsService
				.postActionAttachments(attachmentsToAdd)
				.then(() => {
					const language = switchMatchingLanguageCode(i18n.language);
					return service.createAction(customerKey, portalKey, action, language);
				})
				.then(() => {
					setIsSubmitting(false);
					refetch();
					redirectToList();
				})
				.catch(() => {
					setIsSubmitting(false);
					setIsSubmitError(true);
				});
		})();
	}, [handleSubmit, customerKey, portalKey, refetch, attachmentsToAdd, redirectToList, service]);

	return (
		<>
			<FormModal
				cancelText={t("global:cancel")}
				large
				okText={t("display:buttonSave")}
				onCancel={onCancel}
				onOk={onOk}
				show={match !== null && match.params.action === "create"}
				title={t("display:myTasks.labelCreateTask")}
				withHistory={false}
			>
				<FormProvider formState={formState} handleSubmit={handleSubmit} {...methods}>
					<CreateAction {...attachmentsProps} />
				</FormProvider>
			</FormModal>

			{isSubmitError && (
				<IllustratedThemedModal
					cancelText={t("global:ok")}
					onCancel={() => setIsSubmitError(false)}
					show={isSubmitError}
					text={t("error:failedToCreateTheTask")}
					withHistory={false}
				>
					<ErrorIllustration />
				</IllustratedThemedModal>
			)}

			{isSubmitting && <Loading withHistory={false} />}

			<ConfirmationModal
				onCancel={() => setIsConfirmationModalActive(false)}
				onConfirm={redirectToList}
				show={isConfirmationModalActive}
				text={t("display:labelAreYouSure")}
			/>
		</>
	);
};
