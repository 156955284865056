import { BaseService } from "../../../baseService";
import { withSignInGet } from "../../../utilities/http";
import type { ServiceConfiguration } from "../../../baseService";
import type { ReportService } from "../../interface";

export enum DefaultReportServiceError {
	BlobNotSupported,
}

export class DefaultReportService extends BaseService implements ReportService {
	portalKey: string;
	language: string;
	moduleName: string;

	constructor(
		config: ServiceConfiguration,
		portalKey: string,
		language: string,
		moduleName: string,
	) {
		super(config);
		this.portalKey = portalKey;
		this.language = language;
		this.moduleName = moduleName;
	}

	async getReport(recordId: string): Promise<Blob> {
		let query = "/Report?";
		query += `portalDashKey=${this.portalKey}`;
		query += `&moduleRecordId=${recordId}`;
		query += `&userCurrentLanguage=${this.language}`;

		const serviceUrl = `${await this.getEndpointUrl()}${query}`;
		const headers = await this.getAuthHeaders();
		return withSignInGet<any>(serviceUrl, {
			responseType: "blob",
			...headers,
		}).then((response) => response.data);
	}
}
