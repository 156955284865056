import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ReactComponent as Image } from "../../assets/svg/illustrations/caution.svg";
import "./ErrorBoundary.styles.scss";

export interface AlternateRoute {
	routeUrl?: string;
	label?: string;
}

interface Props {
	message?: any[];
	pageNotFound?: boolean;
	error?: Error;
	alternateRoutes?: AlternateRoute[];
	buttonText?: string;
}

const ErrorBoundaryPresentation = ({
	error,
	message,
	pageNotFound,
	alternateRoutes,
	buttonText,
}: Props) => {
	const { t } = useTranslation();
	const portal = useSelector((state: any) => state.portal);

	const portalPath = useMemo(() => {
		if (
			portal &&
			portal.portals &&
			portal.portals.length &&
			portal.portals[0].key.length &&
			portal.portals[0].customerKey.length
		) {
			return `/${portal.portals[0].customerKey}/p/${portal.portals[0].key}`;
		}
		return null;
	}, [portal]);

	return (
		<div className="errorBoundary">
			<div className="errorBoundary__content">
				<h1 className="errorBoundary__heading">{t("display:errorBoundary.heading")}</h1>

				<div className="errorBoundary__content-inner">
					<figure className="errorBoundary__figure">
						<Image />
					</figure>

					{(message && <p>{t(message[0], message[1])}</p>) ||
						(pageNotFound && <p>{t("error:pageNotFound")}</p>) || (
							<p>{t("error:generic")}</p>
						)}

					{alternateRoutes &&
						alternateRoutes.length > 0 &&
						alternateRoutes.map((route: AlternateRoute) => {
							return (
								<a
									key={route.label}
									className="she-btn she-btn-primary she-error__button-ok"
									href={route.routeUrl}
								>
									{route.label}
								</a>
							);
						})}

					{portalPath && (
						<a
							className="she-btn she-btn-primary she-error__button-ok"
							href={portalPath}
						>
							{buttonText ?? t("display:errorBoundary.button")}
						</a>
					)}
				</div>
				{error && (
					<div className="collapse">
						<a href="#errorDetails">{t("display:buttonViewError")}</a>
						<div className="errorDetails" id="errorDetails">
							{error.stack}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export { ErrorBoundaryPresentation };
