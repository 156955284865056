import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { switchMatchingLanguageCode } from "../../../../../helpers/LanguageCodeHelper";
import { usePortalPath } from "../../../../../helpers/UsePortalPath";
import { queryClient } from "../../../../../QueryClient";
import { NotificationModal } from "../../../../components/modals";
import { useGetActionQuery } from "../../hooks/useGetActionQuery";

interface Props {
	refetch: () => void;
}

export const WrongUserErrorModal = ({ refetch }: Props) => {
	const match = useRouteMatch(
		"/:customerKey/p/:portalKey/actions/(edit|detail|complete)/:actionId",
	);
	const history = useHistory();
	const portalPath = usePortalPath();
	const [showModal, setShowModal] = useState(false);
	const { actionId } = useParams<{ actionId: string }>();
	const { t, i18n } = useTranslation();
	const language = switchMatchingLanguageCode(i18n.language);

	const { isError, error } = useGetActionQuery(actionId, !!match, language);

	useEffect(() => {
		if (isError) {
			queryClient.clear();
			if (
				error instanceof Error &&
				error.message &&
				/.*Action with identifier .* is not assigned to the user with identifier.*/.test(
					error.message,
				)
			) {
				history.push(`${portalPath}actions`);
				refetch();
				setShowModal(true);
			}
		}
	}, [error, history, isError, portalPath, refetch]);

	const onClose = () => {
		setShowModal(false);
	};

	return (
		<NotificationModal
			content={t("error:actionNoLongerAssignedToYou")}
			onClose={onClose}
			show={showModal}
		/>
	);
};
