import { useMemo } from "react";
import moment from "moment";
import { Link, useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ActionAttachmentsList } from "../ActionAttachmentsList";
import { ActionReadOnlyField } from "../ActionReadOnlyField/ActionReadOnlyField.component";
import { Chip } from "../../../../components/generic/Chip";
import { getDateFormat } from "../../../../../helpers/DateTimeInputHelper";
import { ProgressBar } from "../../../../components/generic/ProgressBar";
import { ReactComponent as Calendar } from "../../../../assets/svg/calendar.svg";
import { ReactComponent as UserCircle } from "../../../../assets/svg/userCircle.svg";
import { useManageActionAttachments } from "../../../../utilities/UseManageActionAttachments";
import { usePortalPath } from "../../../../../helpers/UsePortalPath";
import { ActionLastComment } from "../ActionComments/ActionLastComment";
import { useActionComments } from "../ActionComments/hooks/useActionComments";
import { ActionCommentsHistory } from "../ActionComments/ActionCommentsHistory";
import type { ActionFull } from "../../../../../models/action";
import "./ActionDetail.styles.scss";

interface Props {
	action: ActionFull;
}

export const ActionDetail = ({ action }: Props) => {
	const { t } = useTranslation();
	const portalPath = usePortalPath();
	const { actionId } = useParams<{ actionId: string }>();
	const history = useHistory();
	const { comments } = useActionComments(Number(actionId));

	const formattedDueDate = useMemo(
		() => moment(action.DueDate).format(getDateFormat()),
		[action.DueDate],
	);

	const formattedDateCreated = useMemo(
		() => moment(action.DateCreated).format(getDateFormat()),
		[action.DateCreated],
	);

	const formattedOriginalDueDate = useMemo(
		() => moment(action.OriginalDueDate).format(getDateFormat()),
		[action.OriginalDueDate],
	);

	const { attachmentsToDisplay } = useManageActionAttachments(action.Attachments);

	return (
		<div className="she-action-detail">
			<div className="she-action-detail__header-chips">
				<Chip backgroundColor={action.PriorityColourCode}>{action.PriorityText}</Chip>

				{action.IsOverdue && (
					<Chip backgroundColor={action.DueDateColourCode}>{action.DueDateStatus}</Chip>
				)}
			</div>

			<h1 className="she-action-detail__title">{action.ActionHeader}</h1>

			{action.RecordReference &&
				t("display:myTasks.labelRef", { reference: action.RecordReference })}

			<dl>
				<ActionReadOnlyField info={action.Info} propertyName="OrgUnitName">
					{action.OrgUnitName}
				</ActionReadOnlyField>

				<ActionReadOnlyField info={action.Info} propertyName="ModuleDescrip">
					{action.ModuleDescrip}
				</ActionReadOnlyField>

				<ActionReadOnlyField info={action.Info} propertyName="ActionCategory">
					{action.ActionCategoryText}
				</ActionReadOnlyField>

				<ActionReadOnlyField info={action.Info} propertyName="ActionSubCategory">
					{action.ActionSubCategoryText}
				</ActionReadOnlyField>

				<ActionReadOnlyField info={action.Info} propertyName="ActionDetail">
					{action.ActionDetail}
				</ActionReadOnlyField>

				<ActionReadOnlyField info={action.Info} propertyName="RaisingUser">
					<div className="she-icon she-action-detail__icon">
						<UserCircle />
					</div>
					{action.RaisingUser.FullName}
				</ActionReadOnlyField>

				<ActionReadOnlyField info={action.Info} propertyName="ForUser">
					<div className="she-icon she-action-detail__icon">
						<UserCircle />
					</div>
					{action.ForUser.FullName}
				</ActionReadOnlyField>

				<ActionReadOnlyField info={action.Info} propertyName="DateCreated">
					<div className="she-icon she-action-detail__icon">
						<Calendar />
					</div>
					{formattedDateCreated}
				</ActionReadOnlyField>

				<ActionReadOnlyField info={action.Info} propertyName="DueDate">
					<div className="she-icon she-action-detail__icon">
						<Calendar />
					</div>
					{formattedDueDate}
				</ActionReadOnlyField>

				<ActionReadOnlyField info={action.Info} propertyName="OriginalDueDate">
					<div className="she-icon she-action-detail__icon">
						<Calendar />
					</div>
					{formattedOriginalDueDate}
				</ActionReadOnlyField>

				<ActionReadOnlyField info={action.Info} propertyName="Priority">
					{action.PriorityText}
				</ActionReadOnlyField>

				<ActionReadOnlyField info={action.Info} propertyName="CurrentStatus">
					{action.CurrentStatus}
				</ActionReadOnlyField>

				<ActionReadOnlyField info={action.Info} propertyName="CompletePercent">
					<div className="she-action-detail__progress-wrapper">
						<ProgressBar className="she-action-detail__progress">
							{action.CompletePercent}
						</ProgressBar>

						<Link
							className="she-btn she-btn-tertiary"
							to={{
								pathname: `${portalPath}actions/complete/${actionId}`,
								state: { previousPath: history.location.pathname },
							}}
						>
							{t("display:myTasks.buttonMarkComplete")}
						</Link>
					</div>
				</ActionReadOnlyField>

				{action.TimeTaken !== null && (
					<ActionReadOnlyField info={action.Info} propertyName="TimeTaken">
						{action.TimeTaken}
					</ActionReadOnlyField>
				)}

				{comments.length ? (
					<ActionReadOnlyField
						direction="column"
						info={action.Info}
						propertyName="LastComment"
					>
						{action.LatestComment ? (
							<ActionLastComment {...action.LatestComment} />
						) : null}

						<ActionCommentsHistory comments={comments} />
					</ActionReadOnlyField>
				) : null}
			</dl>

			<ActionAttachmentsList attachments={attachmentsToDisplay} readonly />
		</div>
	);
};
