import { useSelector } from "react-redux";
import {
	extractCurrentPortalAuthenticationMode,
	isHiddenOnPortal,
} from "../../../helpers/PortalHelpers";
import { PortalAuthenticationMode } from "../../../models/portal";
import type { State } from "../../../state";

interface Props {
	hideOnPortals?: PortalAuthenticationMode | PortalAuthenticationMode[];
	children?: React.ReactNode;
}

export const PortalGuard = ({
	children,
	hideOnPortals = PortalAuthenticationMode.Public,
}: Props) => {
	const authenticationMode = useSelector<State, PortalAuthenticationMode | null>((state) =>
		extractCurrentPortalAuthenticationMode(state),
	);

	if (isHiddenOnPortal(authenticationMode, hideOnPortals)) {
		return null;
	}

	return <>{children}</>;
};
