import { useEffect, useRef, useCallback } from "react";
import { Html5Qrcode } from "html5-qrcode";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconBack } from "../../assets/svg/arrow-left.svg";
import "./QRScanner.styles.scss";

interface Props {
	onScanComplete: (decodedText: string) => void;
	closeAction: () => void;
}

export const ModalBody = ({ onScanComplete, closeAction }: Props) => {
	const qrCodeRegionId = "html5qr-code-full-region";
	const html5QrCode = useRef<Html5Qrcode | null>(null);
	const scannerRef = useRef<HTMLDivElement>(null);
	const { t } = useTranslation();

	const qrCodeSuccessCallback = useCallback(
		(decodedText: string) => {
			onScanComplete(decodedText);
			closeAction();
		},
		[onScanComplete, closeAction],
	);

	useEffect(() => {
		if (!scannerRef.current) {
			return;
		}
		if (!html5QrCode.current || scannerRef.current) {
			html5QrCode.current = new Html5Qrcode(qrCodeRegionId);

			let aspect = 1.8;
			const scannerBoundingRect = scannerRef.current.getBoundingClientRect();
			aspect = scannerBoundingRect.height / scannerBoundingRect.width;

			html5QrCode.current.start(
				{ facingMode: "environment" },
				{
					fps: 10,
					qrbox: 250,
					aspectRatio: aspect,
				},
				qrCodeSuccessCallback,
				undefined,
			);
		}

		return () => {
			const scanner = html5QrCode.current;
			if (!scanner) {
				return;
			}

			try {
				scanner
					.stop()
					.then(() => {
						scanner.clear();
					})
					.catch((err) => {
						console.log(err.message);
					});
			} catch (err) {
				console.log(err);
			}
		};
	}, [scannerRef, qrCodeSuccessCallback]);

	const onClose = () => {
		closeAction();
	};

	return (
		<div className="qr-scanner-modal__container">
			<div className="qr-scanner-modal__header">
				<button
					className="she-icon qr-scanner-modal__header--btn"
					onClick={onClose}
					type="button"
				>
					<IconBack />
				</button>
				<span className="qr-scanner-modal__header--text">{t("display:scanCode")}</span>
			</div>
			<div className="qr-scanner-modal__body" id={qrCodeRegionId} ref={scannerRef} />
			<div className="qr-scanner-modal__footer">
				<button className="qr-scanner-modal__footer--btn" onClick={onClose} type="button">
					{t("global:cancel")}
				</button>
			</div>
		</div>
	);
};
