import { t } from "i18next";
import type { OrgUnitField } from "../../models/fields/OrgUnitField";

export class OrgUnitFieldValidator {
	messages: string[] = [];

	constructor(public orgUnitField: OrgUnitField) {}

	isFieldValid = (value?: number): boolean => {
		let result = true;

		if (this.orgUnitField.isMandatory && value === undefined) {
			this.messages.push(t("validation:mandatoryFieldMessage"));
			result = false;
		}

		return result;
	};
}
