import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { PageLayout } from "../../layouts/PageLayout";
import { ActionList } from "./components/ActionList";
import type { State } from "../../../state";
import type { Portal } from "../../../models/portal";

const ActionsPage = () => {
	const { customerKey, portalKey } = useParams<{
		customerKey: string;
		portalKey: string;
	}>();

	const portal = useSelector<State, Portal | undefined>((s) =>
		s.portal.portals.find(
			(p) => p.key === portalKey.toLowerCase() && p.customerKey === customerKey.toLowerCase(),
		),
	);

	return (
		<PageLayout portal={portal} title={portal ? portal.title : ""}>
			<ActionList portal={portal} />
		</PageLayout>
	);
};

export { ActionsPage };
