import { PortalActionTypes } from "./actions/types";
import type { Reducer } from "redux";
import type { PortalState } from "./model";
import type { PortalAction } from "./actions/definitions";

const initialState: PortalState = {
	portals: [],
	sortingKey: "default",
};

export const portalReducer: Reducer<PortalState, PortalAction> = (state = initialState, action) => {
	switch (action.type) {
		case PortalActionTypes.Loaded:
			return {
				...state,
				portals: [action.portal],
			};
		case PortalActionTypes.QuestionnaireStubsSorted:
			return {
				...state,
				sortingKey: action.sortingKey,
			};
		case PortalActionTypes.SaveInitialSetup:
			return {
				...state,
				portals: state.portals.map((p) =>
					p.key !== action.portalKey
						? p
						: {
								...p,
								questionnaireStubs: action.stubs,
								initialSetupCompleted: true,
						  },
				),
			};
		case PortalActionTypes.UpdateInitialSetupFlag:
			return {
				...state,
				portals: state.portals.map((p) =>
					p.key !== action.portalKey
						? p
						: {
								...p,
								initialSetupCompleted: action.initialSetupCompleted,
						  },
				),
			};
		case PortalActionTypes.RemoveQuestionnaire:
			return {
				...state,
				portals: state.portals.map((p) =>
					p.key !== action.portalKey
						? p
						: {
								...p,
								questionnaireStubs: p.questionnaireStubs.map((s) =>
									s.key !== action.questionnaireKey
										? s
										: { ...s, makeAvailableOffline: false },
								),
						  },
				),
			};
		case PortalActionTypes.DownloadQuestionnaireToDevice:
			return {
				...state,
				portals: state.portals.map((p) =>
					p.key !== action.portalKey
						? p
						: {
								...p,
								questionnaireStubs: p.questionnaireStubs.map((s) =>
									s.key !== action.questionnaireKey
										? s
										: { ...s, makeAvailableOffline: true },
								),
								questionnaireTemplateSyncRequired: true,
						  },
				),
			};
		case PortalActionTypes.UpdateQuestionnaireTemplateStatus:
			return {
				...state,
				portals: state.portals.map((p) =>
					p.key !== action.portalKey
						? p
						: {
								...p,
								questionnaireStubs: p.questionnaireStubs.map((s) =>
									s.key !== action.questionnaireKey
										? s
										: {
												...s,
												questionnaireStatus: action.status,
												syncError: action.errorMsg,
										  },
								),
						  },
				),
			};
		case PortalActionTypes.UpdateTemplateSyncFlag:
			return {
				...state,
				portals: state.portals.map((p) =>
					p.key !== action.portalKey
						? p
						: {
								...p,
								questionnaireTemplateSyncRequired: action.syncFlag,
						  },
				),
			};
		default:
			return state;
	}
};
