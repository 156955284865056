interface Props {
	id: string;
	name: string;
	value: number;
	checked: boolean;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const CheckboxPresentation = ({ checked, id, name, onChange, value }: Props) => {
	return (
		<div className="she-components-checkbox">
			<input
				checked={checked}
				id={id}
				name={name}
				onChange={onChange}
				type="checkbox"
				value={value}
			/>
			<label htmlFor={id}>{name}</label>
		</div>
	);
};
