import { PortalAuthenticationMode } from "../models/portal";
import type { State } from "../state";

export const extractCurrentPortalAuthenticationMode = (
	state: State,
): PortalAuthenticationMode | null => {
	if (
		!state.portal ||
		!state.portal.portals ||
		!state.portal.portals[0] ||
		state.portal.portals[0].authenticationMode === undefined ||
		state.portal.portals[0].authenticationMode === null
	) {
		return null;
	}

	return state.portal.portals[0].authenticationMode;
};

export const isHiddenOnPortal = (
	currentMode: PortalAuthenticationMode | null,
	portals:
		| PortalAuthenticationMode
		| PortalAuthenticationMode[] = PortalAuthenticationMode.Public,
): boolean => {
	if (!currentMode && currentMode !== 0) {
		return false;
	}

	if (Array.isArray(portals)) {
		return portals.some((portal) => portal === currentMode);
	} else if (currentMode in PortalAuthenticationMode) {
		return currentMode === portals;
	}
	return false;
};

export const getPortalKeyFromPathname = (pathname: string) => {
	const portalKey = pathname.split("/")[3]?.toLowerCase();
	return portalKey;
};

export const getTenantIdFromPathname = (pathname: string) => {
	const tenantId = pathname.split("/")[1]?.toLowerCase();
	return tenantId;
};
