import { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Media from "react-media";
import { OrgUnitFieldValidator } from "../../../../validators/orgUnitFieldValidator";
import { OrgUnitSelectorPresentation } from "./OrgUnitSelector.presentation";
import { buildBreadcrumb } from "./buildBreadcrumb";
import styles from "./OrgUnitSelector.module.scss";
import type { State } from "../../../../state";
import type { OrgUnitField } from "../../../../models/fields/OrgUnitField";
import type { OrgUnit } from "../../../../models/orgUnit";

interface Props {
	field: OrgUnitField;
	selectedId?: number;
	onSelect: (id?: number) => void;
	updateValidationStatus: (fieldId: number, value: string[]) => void;
}

export const OrgUnitSelectorContainer = ({
	field,
	onSelect,
	updateValidationStatus,
	selectedId,
}: Props) => {
	const { t } = useTranslation();
	const orgUnits = useSelector<State, OrgUnit[]>((s) => s.orgUnit.orgUnits);
	const [currentId, setCurrentId] = useState<number | undefined>(selectedId);
	const [checkedId, setCheckedId] = useState<number | undefined>(selectedId);
	const [isShown, setIsShown] = useState(false);
	const [isValid, setIsValid] = useState(true);
	const fieldValidator = useMemo(() => new OrgUnitFieldValidator(field), [field]);

	const breadcrumbData = useMemo(() => {
		const data: OrgUnit[] = buildBreadcrumb(
			checkedId || -1,
			orgUnits,
			orgUnits.filter((ou) => ou.parentId === checkedId).length > 0,
		);

		if (!data.length) {
			const top = orgUnits.find((ou) => ou.parentId === undefined);
			if (top) {
				data.push(top);
			}
		}

		return data;
	}, [checkedId, orgUnits]);

	const fullBreadcrumbData = useMemo(
		() => buildBreadcrumb(currentId || -1, orgUnits, false),
		[currentId, orgUnits],
	);

	const checkedOrgUnit = useMemo(
		() => orgUnits.find((ou) => ou.id === checkedId),
		[orgUnits, checkedId],
	);

	const currentOrgUnit = useMemo(
		() => orgUnits.find((ou) => ou.id === currentId),
		[orgUnits, currentId],
	);

	const buttonText = useMemo(() => {
		if (!field.isFixed && !field.hidden && !field.isForced) {
			return t("display:buttonChangeOrgUnit", { orgCaption: field.name });
		}
		if (field.isForced) {
			return t("display:labelSelectOrgUnit", { orgCaption: field.name });
		}

		return undefined;
	}, [field, t]);

	const [searchTerm, setSearchTerm] = useState("");
	const [isSearchMode, setIsSearchMode] = useState(false);

	const onModalSelect = () => {
		setIsSearchMode(false);
		setSearchTerm("");
		setIsValid(fieldValidator.isFieldValid(checkedId));
		updateValidationStatus(field.id, fieldValidator.messages);
		setIsShown(false);
		setCurrentId(checkedId);
		onSelect(checkedId);
	};

	const onModalCancel = () => {
		setIsSearchMode(false);
		setSearchTerm("");
		setIsValid(fieldValidator.isFieldValid(checkedId));
		updateValidationStatus(field.id, fieldValidator.messages);
		setIsShown(false);
		setCheckedId(selectedId);
	};

	return (
		<Media query={`(min-width: ${styles.breakpointS})`}>
			{() => (
				<OrgUnitSelectorPresentation
					breadcrumbData={breadcrumbData}
					buttonText={buttonText}
					checkedOrgUnit={checkedOrgUnit}
					currentOrgUnit={currentOrgUnit}
					fieldId={field.guid}
					fullBreadcrumbData={fullBreadcrumbData}
					isFixed={field.isFixed}
					isSearchMode={isSearchMode}
					isValid={isValid}
					onCancel={() => onModalCancel()}
					onChange={(id) => setCheckedId(id)}
					onOpen={() => setIsShown(true)}
					onSelect={() => onModalSelect()}
					orgUnitCaption={field.name}
					orgUnits={orgUnits}
					searchTerm={searchTerm}
					setIsSearchMode={setIsSearchMode}
					setSearchTerm={setSearchTerm}
					show={isShown}
				/>
			)}
		</Media>
	);
};
