import { useContext, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ToastContext } from "../../../../components/toast";
import type { Section } from "../../../../../models/questionnaire";
import type { State } from "../../../../../state";

const everySectionAccounted = (left?: Section[], right?: Section[]) => {
	return (
		left?.every((sectionLeft) =>
			right?.some(
				(sectionRight) =>
					sectionRight.guid === sectionLeft.guid &&
					sectionRight.isVisible === sectionLeft.isVisible,
			),
		) ?? true
	);
};

const areSectionsEqual = (left?: Section[], right?: Section[]) => {
	return everySectionAccounted(left, right) && everySectionAccounted(right, left);
};

export const useBranchingAnnouncements = (currentSectionIndex: number) => {
	const sections = useSelector<State, Section[] | undefined>((state) => {
		return state.questionnaire.sections;
	}, areSectionsEqual);
	const prevSections = useRef<Section[]>();
	const { t, i18n } = useTranslation();
	const { announce } = useContext(ToastContext);

	useEffect(() => {
		if (sections?.length && prevSections.current?.length) {
			const sectionDiff = sections.filter((section) => {
				return prevSections.current?.some((prevSection) => {
					return (
						prevSection.guid === section.guid &&
						prevSection.isVisible !== section.isVisible
					);
				});
			});

			if (sectionDiff.length) {
				const formatter = new Intl.ListFormat(i18n.language, {
					style: "long",
					type: "conjunction",
				});

				const displayedSectionToAnnounce = sectionDiff.filter(
					(section) => section.isVisible && section.orderIndex < currentSectionIndex,
				);
				if (displayedSectionToAnnounce.length) {
					const message =
						displayedSectionToAnnounce.length === 1
							? t("branching.messageSectionDisplayed", {
									section: displayedSectionToAnnounce[0].name,
							  })
							: t("branching.messageSectionsDisplayed", {
									sections: formatter.format(
										displayedSectionToAnnounce.map((section) => section.name),
									),
							  });
					announce(message);
				}

				const hiddenSectionToAnnounce = sectionDiff.filter(
					(section) => !section.isVisible && section.orderIndex < currentSectionIndex,
				);
				if (hiddenSectionToAnnounce.length) {
					const message =
						hiddenSectionToAnnounce.length === 1
							? t("branching.messageSectionHidden", {
									section: hiddenSectionToAnnounce[0].name,
							  })
							: t("branching.messageSectionsHidden", {
									sections: formatter.format(
										hiddenSectionToAnnounce.map((section) => section.name),
									),
							  });
					announce(message);
				}
			}
		}
		prevSections.current = sections;
	}, [currentSectionIndex, i18n.language, announce, sections, t]);
};
