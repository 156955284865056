import { useTranslation } from "react-i18next";
import { Link, useHistory, useParams } from "react-router-dom";
import { usePortalPath } from "../../../../../helpers/UsePortalPath";
import type { ActionComment } from "../../../../../models/action";

interface ActionCommentsHistoryProps {
	comments: ActionComment[];
}

export const ActionCommentsHistory = ({ comments }: ActionCommentsHistoryProps) => {
	const portalPath = usePortalPath();
	const history = useHistory();
	const { action, actionId } = useParams<{ action: string; actionId: string }>();
	const { t } = useTranslation();

	return (
		<Link
			className="she-btn she-btn-tertiary action-last-comment__view-all"
			to={{
				pathname: `${portalPath}actions/${action}/${actionId}/comments`,
				state: { previousPath: history.location.pathname },
			}}
		>
			{t("display:myTasks.labelCommentViewAll", { count: comments.length })}
		</Link>
	);
};

ActionCommentsHistory.displayName = "ActionCommentsHistory";
