import i18next, { changeLanguage as i18nextChangeLanguage, use } from "i18next";
import { initReactI18next } from "react-i18next";
import { locale } from "moment";
import { getPortalKeyFromPathname, getTenantIdFromPathname } from "../helpers/PortalHelpers";
import { getUserLanguageForPortal } from "./helpers/i18nHelpers";
import resources from "./locales";
import "moment/locale/es";
import "moment/locale/it";
import "moment/locale/de";
import "moment/locale/nl";
import "moment/locale/fr";
import "moment/locale/es-us";
import "moment/locale/pa-in";
import "moment/locale/lt";
import "moment/locale/ro";
import "moment/locale/pl";
import "moment/locale/pt";
import "moment/locale/pt-br";
import "moment/locale/hr";
import "moment/locale/cs";
import "moment/locale/sl";
import "moment/locale/hu";
import "moment/locale/zh-cn";
import "moment/locale/da";
import "moment/locale/sv";
import "moment/locale/nb";
import "moment/locale/fi";
import "moment/locale/hi";
import "moment/locale/ar";
import "moment/locale/ja";
import "moment/locale/ms";
import "moment/locale/ko";
import "moment/locale/ta";
import "moment/locale/vi";
import "moment/locale/ru";
import "moment/locale/th";
/**
 * Make sure that moment/locale/en-gb is the last import.
 * moment.js uses last import if it cannot recognize language code and we want it to be English.
 * See #Bug 81518: AGO+ [FE]: Chinese language uses Hindi moment.js.
 */
import "moment/locale/en-gb";

export const defaultNS = "display";

use(initReactI18next).init({
	lng: "en-gb",
	load: "all",
	resources,
	ns: ["display", "error", "validation", "global"],
	defaultNS,
	fallbackLng: "en-gb",
	lowerCaseLng: true,
	returnNull: false,
});

locale("en-gb");

getUserLanguageForPortal(
	getTenantIdFromPathname(window.location.pathname),
	getPortalKeyFromPathname(window.location.pathname),
).then((userLanguage) => {
	if (userLanguage) {
		i18nextChangeLanguage(userLanguage);
		locale(userLanguage);
	}
});

/**
 * Changes language.
 */
export const changeLanguage = function (key: string) {
	i18nextChangeLanguage(key).then(() => {
		document.documentElement.dir = i18next.dir(i18next.language);
		document.documentElement.lang = i18next.language.split("-")[0];
		locale(key);
	});
};
