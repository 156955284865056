import { useEffect } from "react";
import * as markerjs2 from "markerjs2";
import { logException } from "../../../services/log";
import transitionUtils from "./AnnotateImage.module.scss";
import "./AnnotateImage.styles.scss";

export interface Props {
	imgRef: React.RefObject<HTMLImageElement>;
	onSave: (dataUrl: string) => void;
	onClose: () => void;
	type: string;
	delayEntry?: boolean;
}

export const AnnotateImage = ({ imgRef, onSave, onClose, type, delayEntry }: Props) => {
	useEffect(() => {
		if (imgRef && imgRef.current) {
			markerjs2.Activator.addKey(process.env.REACT_APP_MARKERJS2_LICENSE || "");
			const markerArea = new markerjs2.MarkerArea(imgRef.current);
			markerArea.uiStyleSettings.toolbarHeight = 52;
			markerArea.renderAtNaturalSize = true;
			markerArea.settings.defaultColorSet = [
				"#EF4444", // red
				"#10B981", // green
				"#2563EB", // blue
				"#FFFF00", // yellow
				"#7C3AED", // purple
				"#000000", // black
				"#FFFFFF", // white
			];
			markerArea.availableMarkerTypes = [
				"FreehandMarker",
				"TextMarker",
				"ArrowMarker",
				"LineMarker",
				"FrameMarker",
				"EllipseFrameMarker",
				"CoverMarker",
				"EllipseMarker",
			];
			markerArea.renderImageType = type;
			markerArea.renderImageQuality = 0.8;
			markerArea.addCloseEventListener(onClose);
			markerArea.addRenderEventListener((dataURI) => {
				// replace image in tag with new one
				if (imgRef.current) {
					imgRef.current.src = dataURI;
				}
				onSave(dataURI);
			});
			if (!delayEntry) {
				markerArea.show();
			} else {
				// allow modal animation on entry
				window.setTimeout(() => {
					markerArea.show();
				}, parseInt(transitionUtils.transitionModalDuration));
			}

			return () => {
				if (markerArea.isOpen) {
					try {
						markerArea.close();
					} catch (e) {
						if (!(e instanceof DOMException)) {
							// Ignore DOMException - marker tries to remove DOM nodes that are already removed. Log all other errors.
							logException(e);
						}
					}
				}
			};
		}
		// Ignore lint warning. We want to run this hook only once, when component is mounted.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return null;
};
