import { useState, useMemo, useRef, useContext, useCallback, Fragment } from "react";
import Axios from "axios";
import { useAuth } from "oidc-react";
import { useHistory } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { ActionModal } from "../ActionModal";
import { Button } from "../../../../components/input/Button";
import { CancelablePromise } from "../../../../../services/utilities/CancelablePromise";
import { CreateActionModal } from "../CreateActionModal";
import { DefaultActionsService } from "../../../../../services/actions";
import { EditActionModal } from "../EditActionModal";
import { ErrorViewer } from "../../../../components/ErrorViewer/ErrorViewer.component";
import { OnlineStatus } from "../../../../../services/health/implementations/healthMonitor";
import { Pagination } from "../../../../components/generic/Pagination";
import { ReactComponent as Show } from "../../../../assets/svg/add.svg";
import { ScrollRootToTop } from "../../../../components/ScrollRootToTop";
import { SearchBox } from "../../../../components/input/SearchBox";
import { switchMatchingLanguageCode } from "../../../../../helpers/LanguageCodeHelper";
import { useGetActionsNumber } from "../../../../utilities/UseGetActionsNumber";
import { usePortalPath } from "../../../../../helpers/UsePortalPath";
import { ActionCompleteModal } from "../ActionCompleteModal";
import { ActionCard } from "../ActionCard";
import { APIHealthContext } from "../../../../../services/health/implementations/healthContext/healthContext";
import { WrongUserErrorModal } from "../WrongUserErrorModal";
import { ActionListSort } from "./ActionListSort";
import type { Portal } from "../../../../../models/portal";
import type { Action, Actions, ActionSortPropertyInfo } from "../../../../../models/action";
import "./ActionList.styles.scss";
import { ActionCommentsModal } from "../ActionComments/ActionCommentsModal";

interface ActionListProps {
	portal?: Portal | undefined;
}

const ActionList = ({ portal }: ActionListProps) => {
	const { t, i18n } = useTranslation();
	const [totalPages, setTotalPages] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const maxItemsPerPage = 20;
	const portalKey = (portal && portal.key) || "";
	const [searchTerm, setSearchTerm] = useState("");
	const [actionsDetails, setActionsDetails] = useState("");
	const onlineStatus = useContext(APIHealthContext);
	const [sort, setSort] = useState<ActionSortPropertyInfo | undefined>();
	const history = useHistory();
	const portalPath = usePortalPath();

	const createActionButtonRef = useRef<HTMLButtonElement>(null);
	const searchBoxRef = useRef<HTMLInputElement>(null);

	const service = useMemo(
		() =>
			new DefaultActionsService({
				subdomain: "Action",
			}),
		[],
	);

	const { refetch: refetchActionNumber } = useGetActionsNumber();

	const {
		isLoading,
		isError,
		data,
		error,
		refetch: refetchActions,
		remove,
	} = useQuery(
		[
			portalKey,
			currentPage - 1,
			switchMatchingLanguageCode(i18n.language),
			searchTerm,
			sort && sort.propertyName,
			sort && sort.order,
		],
		() => {
			const cancelTokenSource = Axios.CancelToken.source();

			const getActions = new CancelablePromise<Actions>((resolve, reject) =>
				service
					.getActions(
						portalKey,
						sort && sort.propertyName,
						sort && sort.order,
						maxItemsPerPage,
						(currentPage - 1) * maxItemsPerPage,
						searchTerm,
						portal && portal.dateFormat,
						switchMatchingLanguageCode(i18n.language),
						cancelTokenSource,
					)
					.then((r) => resolve(r))
					.catch((e) => reject(e)),
			);

			getActions.cancel = () => {
				cancelTokenSource.cancel();
			};

			return getActions;
		},
		{
			onSuccess: (data) => {
				const { totalDataCount } = data;
				const totalPagesSum = Math.ceil(totalDataCount / maxItemsPerPage);

				setTotalPages(totalPagesSum);

				if (currentPage > totalPagesSum) {
					remove();
					setCurrentPage(totalPagesSum);
				}

				setActionsDetails(
					t("display:labelViewingXYofZ", {
						min: maxItemsPerPage * (currentPage - 1) + 1,
						max: Math.min(maxItemsPerPage * currentPage, data.totalDataCount),
						total: data.totalDataCount,
					}),
				);
			},
		},
	);

	const refetch = () => {
		refetchActions();
		refetchActionNumber();
	};

	const openActionDetail = useCallback(
		(action: Action) => {
			history.push(`${portalPath}actions/detail/${action.ActionId}`);
		},
		[history, portalPath],
	);

	const openCreateAction = useCallback(() => {
		history.push(`${portalPath}actions/create`);
	}, [history, portalPath]);

	const auth = useAuth();

	const errorContent = (
		<>
			<p className="she-actions__status">{t("display:labelError")}</p>

			<ErrorViewer error={error} />
		</>
	);

	const listContent = (
		<>
			<div className="she-actions__wrapper">
				{actionsDetails ? (
					<div className="she-actions__details">{actionsDetails}</div>
				) : null}

				<ul className="she-actions__list">
					{data?.list.map((record, i) => (
						<Fragment key={i}>
							<ActionCard action={record} openActionDetail={openActionDetail} />
						</Fragment>
					))}
				</ul>

				{actionsDetails ? (
					<div className="she-actions__details">{actionsDetails}</div>
				) : null}
			</div>

			<Pagination
				currentPage={currentPage}
				maxPages={totalPages}
				onChangePage={(newPageNumber) => setCurrentPage(newPageNumber)}
			/>
		</>
	);

	const resolveConditionalRendering = (): JSX.Element => {
		if (onlineStatus === OnlineStatus.Unavailable) {
			return <p className="she-actions__status">{t("error:actionsDeviceOffline")}</p>;
		} else if (data && sort) {
			if (data.list && data.list.length) {
				return listContent;
			} else if (searchTerm) {
				return (
					<p className="she-actions__status">
						{t("display:labelRecordsNoResultsWereFound")}
					</p>
				);
			}
			return <p className="she-actions__status">{t("display:labelActionsEmptyList")}</p>;
		} else if (isError) {
			return errorContent;
		} else if (isLoading || !sort) {
			return (
				<p className="she-actions__status">
					{t(
						onlineStatus === OnlineStatus.Available
							? "display:labelLoading"
							: "error:actionsDeviceOffline",
					)}
				</p>
			);
		}

		return <p className="she-actions__status">{t("display:labelActionsEmptyList")}</p>;
	};

	const handleCreateActionClose = useCallback(() => {
		if (createActionButtonRef.current) {
			createActionButtonRef.current.focus();
		}
	}, []);

	return (
		<>
			<ScrollRootToTop observe={currentPage} />

			<header className="she-actions__header">
				<h2>{t("display:labelNavigationActions")}</h2>
				{auth.userData?.profile.canCreateFreestandingActions && (
					<Button
						className="she-actions__create"
						icon={<Show />}
						onClick={() => openCreateAction()}
						ref={createActionButtonRef}
						variant="primary"
					>
						{t("display:myTasks.labelCreateTask")}
					</Button>
				)}
			</header>

			<div className="she-actions">
				<CreateActionModal closeCallback={handleCreateActionClose} refetch={refetch} />

				<ActionModal />

				<EditActionModal refetch={refetch} />

				<ActionCompleteModal refetch={refetch} />

				<ActionCommentsModal />

				<WrongUserErrorModal refetch={refetch} />

				<SearchBox
					onSearchBegan={() => {}}
					onSearchEnd={() => {}}
					onSearchTermChange={setSearchTerm}
					reference={searchBoxRef}
					searchTerm={searchTerm || ""}
				/>

				<ActionListSort
					onSelect={(fieldInfo) => setSort(fieldInfo)}
					propertyInfos={data ? data.sortProperties : undefined}
				/>

				{resolveConditionalRendering()}
			</div>
		</>
	);
};

export { ActionList };
