import { forwardRef } from "react";
import classNames from "classnames";
import type { ButtonHTMLAttributes } from "react";
import "./TreeRadioButton.styles.scss";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
	selected: boolean;
}

export const TreeRadioButton = forwardRef<HTMLButtonElement, Props>(
	({ selected, children, ...rest }, ref) => {
		const classes = classNames("tree-radio-button", {
			"tree-radio-button--selected": selected,
		});

		return (
			<button aria-pressed={selected} className={classes} ref={ref} type="button" {...rest}>
				<div className="tree-radio-button__pseudo-radio" />
				<div className="tree-radio-button__label">{children}</div>
			</button>
		);
	},
);

TreeRadioButton.displayName = "TreeRadioButton";
