import { createRef, useCallback, useEffect, useState } from "react";
import { Map, TileLayer, useLeaflet } from "react-leaflet";
import { Sync } from "leaflet";
import Control from "react-leaflet-control";
import "leaflet.sync";
import "./GeoMiniMap.styles.scss";

interface Props {
	url: string;
	onClick?: () => void;
	label?: string;
	width?: number;
	height?: number;
	bottom?: number;
	left?: number;
	borderWidth?: number;
	lat?: number;
	long?: number;
}

export const GeoMiniMap = ({
	url,
	onClick,
	label,
	width = 104,
	height = 64,
	bottom = 10,
	left = 6,
	borderWidth = 2,
	lat,
	long,
}: Props) => {
	const refMap = createRef<Map>();
	const { map } = useLeaflet();
	const [needsSyncing, setNeedsSyncing] = useState<boolean | undefined>();

	// offset for leaflet control margin
	const controlMaring = 10;

	const resync = useCallback(() => {
		if (map && refMap.current && refMap.current.leafletElement) {
			map.unsync(refMap.current.leafletElement);
			map.sync(refMap.current.leafletElement, {
				offsetFn: Sync.offsetHelper(
					[0, 1],
					[
						(-left - borderWidth - controlMaring) / width,
						1 + (bottom + borderWidth + controlMaring) / height,
					],
				),
			});
			return true;
		}
		return false;
	}, [borderWidth, bottom, height, left, map, refMap, width]);

	useEffect(() => {
		if (!resync()) {
			setNeedsSyncing(true);
		}
	}, [resync, needsSyncing]);

	// Force resync after marker is moved
	useEffect(() => {
		if (map) {
			map.once("moveend", () => {
				resync();
			});
		}
	}, [lat, long, map, resync]);

	return (
		<Control position="bottomleft">
			<div
				className="she-components-minimap"
				onClick={onClick}
				role="button"
				style={{
					width: `${width}px`,
					height: `${height}px`,
					position: "absolute",
					bottom: `${bottom}px`,
					left: `${left}px`,
				}}
				tabIndex={0}
			>
				<Map
					boxZoom={false}
					className="mapContainer"
					doubleClickZoom={false}
					key="miniMap"
					keyboard={false}
					ref={refMap}
					style={{
						width: "100%",
						height: "100%",
						pointerEvents: "none",
					}}
					tap={false}
					zoomControl={false}
				>
					<TileLayer url={url} />
				</Map>
				{label ? <div className="she-components-minimap__label">{label}</div> : null}
			</div>
		</Control>
	);
};
