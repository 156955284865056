export const GetCurrentUnixTime = (): number => {
	return Math.round(new Date().getTime() / 1000);
};

export const GetUnixTimeFromStorage = (storeKey: string): number | undefined => {
	const storedValue = localStorage.getItem(storeKey);
	if (storedValue && storedValue.length > 9) {
		try {
			return parseInt(storedValue);
		} catch {
			return undefined;
		}
	} else {
		return undefined;
	}
};

export const SetCurrentUnixTimeToStorage = (storageKey: string) => {
	localStorage.setItem(storageKey, GetCurrentUnixTime().toString());
};

export const GetCurrentUnitTimeDifference = (
	compareToTime: number | undefined,
): number | undefined => {
	return compareToTime !== undefined ? GetCurrentUnixTime() - compareToTime : undefined;
};
