import { t } from "i18next";
import { NotApplicableComments } from "../../models/questionnaire";
import type { Field } from "../../models/fields/Field";

export class CommentBoxValidator {
	field: Field;
	maxLength: number;
	notApplicableComments: NotApplicableComments;
	messages: string[];

	constructor(
		field: Field,
		notApplicableComments: NotApplicableComments = NotApplicableComments.Hidden,
	) {
		this.field = field;
		this.notApplicableComments = notApplicableComments;
		this.maxLength = 100000;
		this.messages = [];
	}

	isFieldValid(value?: string): boolean {
		this.messages = [];

		if (
			this.field.isIq &&
			(this.field.comments.isEnabled || this.field.comments.isNotApplicableCommentsEnabled)
		) {
			return this.isMandatoryAndHasValue(value) && this.isBelowMaxLength(value);
		}
		return true;
	}

	isMandatoryAndHasValue(value?: string): boolean {
		let result = true;

		if (
			this.field.isNotApplicable &&
			this.notApplicableComments === NotApplicableComments.Required
		) {
			if (!value) {
				result = false;
				this.messages.push(t("validation:mandatoryCommentMessage"));
			}
		}

		return result;
	}

	isBelowMaxLength(value?: string): boolean {
		let result = true;

		if (value && value.length > this.maxLength) {
			result = false;
			this.messages.push(
				t("validation:maxCommentLengthMessage", { maxLength: this.maxLength }),
			);
		}

		return result;
	}
}
