import { NavLink, useRouteMatch } from "react-router-dom";

export interface PageNavItemProps {
	to: string;
	icon: React.ReactNode;
	iconActive: React.ReactNode;
	label: string;
	exact?: boolean;
	show?: boolean;
	pill?: string;
}

export const PageMainNavItem = ({
	to,
	icon,
	iconActive,
	label,
	exact = false,
	pill,
}: PageNavItemProps) => {
	const match = useRouteMatch({
		path: to,
		exact,
	});

	return (
		<li className="she-main-nav__item">
			<NavLink
				activeClassName="she-main-nav__item-link--selected"
				className="she-main-nav__item-link"
				exact={exact}
				to={to}
			>
				<span className="she-main-nav__item-link-icon she-icon">
					{match ? iconActive : icon}
				</span>
				<span className="she-main-nav__item-link-label">{label}</span>
				{pill ? <span className="she-main-nav__item-link-pill">{pill}</span> : null}
			</NavLink>
		</li>
	);
};
