import { useMemo } from "react";
import { TextBox } from "./TextBox.component";
import type { TextBoxProps } from "./TextBox.component";

interface Props extends TextBoxProps {
	component?: React.FunctionComponent<TextBoxProps>;

	icon: React.FunctionComponent;
	onIconClick?: () => void;
	iconLabel: string;
	iconDisabled?: boolean;
	label?: string;
}

export const IconifiedTextBox = ({
	component,
	extraClassName,
	iconLabel,
	iconDisabled,
	onIconClick,
	icon: Icon,
	...rest
}: Props) => {
	const Component = useMemo(() => component || TextBox, [component]);

	return (
		<div className="she-layout-relative she-layout-flex">
			<Component
				{...rest}
				extraClassName={`she-components-text-input-pad-right ${extraClassName}`}
			/>
			<button
				aria-label={iconLabel}
				className="she-components-control-icon she-components-control-icon-interactive"
				disabled={iconDisabled}
				onClick={onIconClick}
				type="button"
			>
				<Icon />
			</button>
		</div>
	);
};
