import { License } from "../../../../components/License";
import { Version } from "../../../../components/Version";
import { ServiceWorkerUpdateNotification } from "../../../../components/ServiceWorkerUpdateNotification";
import { AppId } from "../../../../components/input/AppId";
import { DownloadForOfflineUse } from "../../../../components/DownloadForOfflineUse";
import type { Portal } from "../../../../../models/portal";

interface Props {
	portal?: Portal;
}

// TODO: why are we accepting props if were not using them
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const PageFooter = (props: Props) => {
	return (
		<>
			<DownloadForOfflineUse />
			<License />
			<ServiceWorkerUpdateNotification />
			<Version />
			<AppId />
		</>
	);
};
