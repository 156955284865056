import { useTranslation } from "react-i18next";
import { IllustratedThemedModal } from "../IllustratedThemedModal";
import { ReactComponent as ErrorIllustration } from "../../../assets/svg/illustrations/caution.svg";

interface Props {
	forceLogin: () => void;
	isForcingLogin: boolean;
}

export const ForceLoginModal = ({ forceLogin, isForcingLogin }: Props) => {
	const { t } = useTranslation();

	return (
		<IllustratedThemedModal
			cancelText={t("global:ok")}
			onCancel={() => forceLogin()}
			show={isForcingLogin}
			text={t("display:authentication.labelForceSignIn")}
		>
			<ErrorIllustration />
		</IllustratedThemedModal>
	);
};
