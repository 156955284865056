interface Props {
	id: string | number;
	name?: string;
	selected?: boolean;
	onClick?: () => void;
	children?: React.ReactNode;
}

export const RadioButton = ({ id, children, name, onClick, selected }: Props) => {
	return (
		<div className="she-components-radio-group-button" onClick={onClick}>
			<input
				checked={selected === true}
				id={id.toString()}
				name={name}
				onChange={onClick}
				type="radio"
			/>
			<label htmlFor={id.toString()}>{children}</label>
		</div>
	);
};
