import { useState } from "react";
import { FormModal } from "..";
import { ConfirmationModal } from "../ConfirmationModal";

interface Props {
	modalTitle?: string;
	buttonLabel: string;
	onOk: () => void;
	onCancel?: () => void;
	modalType?: ModalType;
	okText?: string;
	cancelText?: string;
	children?: React.ReactNode;
}

export enum ModalType {
	Form,
	Confirmation,
}

export const ButtonTriggeredModal = ({
	buttonLabel,
	onOk,
	onCancel,
	modalTitle,
	children,
	modalType = ModalType.Form,
	okText,
	cancelText,
}: Props) => {
	const [showModal, setShowModal] = useState(false);

	const onCancelModal = () => {
		setShowModal(false);
		if (onCancel) {
			onCancel();
		}
	};

	const onOkModal = () => {
		setShowModal(false);
		onOk();
	};

	return (
		<div>
			<button
				className="she-btn she-btn-tertiary"
				onClick={() => setShowModal(true)}
				type="button"
			>
				{buttonLabel}
			</button>
			{modalType === ModalType.Form ? (
				<FormModal
					cancelText={cancelText}
					okText={okText}
					onCancel={onCancelModal}
					onOk={onOkModal}
					show={showModal}
					title={modalTitle || buttonLabel}
				>
					{children}
				</FormModal>
			) : (
				<ConfirmationModal
					cancelText={cancelText}
					confirmText={okText}
					onCancel={onCancelModal}
					onConfirm={onOkModal}
					show={showModal}
				>
					{children}
				</ConfirmationModal>
			)}
		</div>
	);
};
