import { useAuth } from "oidc-react";
import { Redirect } from "react-router-dom";
import { getCustomerKey, getPortalKey } from "../helpers/AuthenticationHelpers";
import { TrackedRoute } from "./TrackedRoute";
import type { RouteProps, RouteComponentProps } from "react-router-dom";

interface PrivateRouteProps extends RouteProps {
	component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

export const PrivateRoute = ({ component: Component, ...rest }: PrivateRouteProps) => {
	const { userData } = useAuth();

	const customerKey = getCustomerKey();
	const portalKey = getPortalKey();

	return (
		<TrackedRoute
			{...rest}
			render={(routeProps) =>
				userData ? (
					<Component {...routeProps} />
				) : (
					<Redirect
						to={{
							pathname: `/${customerKey}/p/${portalKey}`,
							state: { from: routeProps.location },
						}}
					/>
				)
			}
		/>
	);
};
