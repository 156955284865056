import { BaseService } from "../../../baseService";
import { withSignInGet } from "../../../utilities/http";
import { DefaultLocalDataService } from "../../../localData";
import type { OrgUnit } from "../../../../models/orgUnit";
import type { OrgUnitService } from "../../interface";

export class DefaultOrgUnitService extends BaseService implements OrgUnitService {
	async getManyByRootIdAndCustomerKey(rootId: number, customerKey: string): Promise<OrgUnit[]> {
		const serviceUrl = `${await this.getEndpointUrl()}?rootOrgId=${rootId}`;
		const localDataService = new DefaultLocalDataService();

		const response = await withSignInGet<any>(serviceUrl, await this.getAuthHeaders());
		const orgUnits = response.parsedBody.OrgUnits;

		let orgs = orgUnits.map((ou: any) => ({
			id: ou.Id,
			parentId: ou.ParentId === 0 ? undefined : ou.ParentId,
			name: ou.Name,
		}));

		orgs = (orgs as OrgUnit[]).sort((a, b) => a.name.localeCompare(b.name));

		localDataService.saveOrgUnits(customerKey, orgs);

		return orgs;
	}

	async hasStoredOrgUnits(customerKey: string): Promise<boolean> {
		const localDataService = new DefaultLocalDataService();
		const orgUnits = await localDataService.getOrgUnitsByCustomerKey(customerKey);

		return orgUnits && orgUnits.length > 0;
	}
}
