import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../input/Button";
import { ReactComponent as Discard } from "../../assets/svg/remove.svg";
import { ReactComponent as Retry } from "../../assets/svg/synchronize-arrow.svg";
import { DownloadManagerContext } from "./DownloadManagerContext";
import { DownloadState } from "./Download";
import type { Download } from "./Download";
import "./DownloadManager.styles.scss";

export interface Props {
	download: Download;
}

export const DownloadListItem = ({ download }: Props) => {
	const downloadContext = useContext(DownloadManagerContext);
	const { t } = useTranslation();

	return (
		<div className="she-download-list__item">
			<h3>{download.fileName}</h3>
			{download.description && download.description.map((d, i) => <div key={i}>{d}</div>)}
			{download.status === DownloadState.Error && (
				<div className="she-download-list__buttons">
					<Button
						icon={<Retry />}
						onClick={() => downloadContext.retryDownload(download)}
						variant="secondary"
					>
						{t("display:labelRetry")}
					</Button>
					<Button
						icon={<Discard />}
						onClick={() => downloadContext.deleteDownload(download)}
						variant="secondary"
					>
						{t("display:labelDiscard")}
					</Button>
				</div>
			)}
		</div>
	);
};
