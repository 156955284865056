import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Bin } from "../../../../../assets/svg/bin-1.svg";
import { FieldType } from "../../../../../../models/questionnaire";
import { Base64Image } from "../../../../../components/generic/Base64Image";
import { ReactComponent as Star } from "../../../../../assets/svg/star.svg";
import { ReactComponent as PaperClip } from "../../../../../assets/svg/streamline-icon-attachment.svg";

interface Props {
	fieldsToDisplay: {
		header: string;
		value: string;
		colour: string;
		type: FieldType;
		orderIndex: number;
	}[];
	removeRecord: () => void;
	editRecord: () => void;
	onKeyPress: (key: string) => void;
	actions: number | null;
	attachments: number | null;
	isAuthenticated: boolean;
}

export const SubModuleRecordPresentation = ({
	actions,
	attachments,
	editRecord,
	fieldsToDisplay,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	isAuthenticated,
	onKeyPress,
	removeRecord,
}: Props) => {
	const { t } = useTranslation();

	return (
		<div
			className="she-components-submodule-tile"
			onKeyPress={(event) => {
				onKeyPress(event.key);
			}}
			tabIndex={0}
		>
			<dl onClick={editRecord}>
				{fieldsToDisplay.map(
					(f: { header: string; value: string; colour: string; type: FieldType }) => {
						return (
							<Fragment key={f.header}>
								<dt>{f.header}</dt>
								{f.type !== FieldType.Matrix &&
									f.type !== FieldType.Signature &&
									f.type !== FieldType.PPE &&
									f.value && <dd>{f.value.toString().replace(/\n/g, ", ")}</dd>}
								{f.type === FieldType.Matrix && (
									<dd>
										<div className="she-components-submodule-matrix">
											<div
												className="she-matrix-input-color"
												style={{ backgroundColor: f.colour }}
											/>
											<div className="she-submodule-matrix-text">
												{f.value}
											</div>
										</div>
									</dd>
								)}
								{f.type === FieldType.Signature && (
									<dd>
										<Base64Image
											altString={t("display:labelSignature")}
											className="she-components-signature-display"
											value={f.value}
										/>
									</dd>
								)}
								{f.type === FieldType.PPE && <dd />}
							</Fragment>
						);
					},
				)}
				{actions !== null ? (
					<>
						<dt>{t("display:labelActions")}</dt>
						<dd className="she-components-submodule-stats">
							<div className="she-icon">
								<Star />
							</div>
							{actions}
						</dd>
					</>
				) : null}
				{attachments !== null ? (
					<>
						<dt>{t("display:labelAttachments")}</dt>
						<dd className="she-components-submodule-stats">
							<div className="she-icon">
								<PaperClip />
							</div>
							{attachments}
						</dd>
					</>
				) : null}
			</dl>
			<div className="she-components-submodule-remove">
				<button
					aria-label={t("display:ariaLabelRemoveSubmoduleRecord")}
					className="she-icon"
					onClick={() => {
						removeRecord();
					}}
					onKeyDown={(e) => {
						e.stopPropagation();
						if (e.key === "Enter" || e.key === " ") {
							removeRecord();
						}
					}}
					type="button"
				>
					<Bin />
				</button>
			</div>
		</div>
	);
};
