import { useState } from "react";
import { useAuth } from "oidc-react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { SignOutModal } from "../../../../components/modals/SignOutModal";
import { SettingsModuleListItem } from "../SettingsModuleListItem";
import type { Portal } from "../../../../../models/portal";
import type { SettingsModuleListItemOptions } from "../../../../../models/settings";
import "./PageUserSettingsWrapper.styles.scss";

interface Props {
	portal: Portal;
}

// TODO: Why are we passing props where when there not used??
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const PageUserSettingsWrapper = ({ portal }: Props) => {
	const { t } = useTranslation();
	const history = useHistory();
	const { userData } = useAuth();
	const [isOpenSignOut, setIsOpenSignOut] = useState(false);

	const handleCloseModal = () => {
		setIsOpenSignOut(false);
	};

	const options: SettingsModuleListItemOptions = [
		{
			label: t("display:authentication.logout"),
			action: () => setIsOpenSignOut(true),
		},
		{
			label: t("display:labelChangePassword"),
			action: () => history.push("password"),
		},
	];

	return (
		<>
			<SignOutModal onCancel={handleCloseModal} show={isOpenSignOut} />
			<TransitionGroup component={null}>
				<CSSTransition appear classNames="fade" component={null} timeout={500}>
					<SettingsModuleListItem
						image="user"
						options={options}
						title={
							userData && userData.profile
								? (userData.profile.fullname as string)
								: ""
						}
					/>
				</CSSTransition>
			</TransitionGroup>
		</>
	);
};
