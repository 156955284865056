import { forwardRef } from "react";
import classnames from "classnames";
import "./Button.styles.scss";

interface Props {
	children: React.ReactNode;
	onClick?: () => void;
	variant?: "primary" | "secondary" | "tertiary" | "tertiary-inverted" | "nav";
	icon?: React.ReactNode;
	awaiting?: boolean;
	className?: string;
	id?: string;
}

export const Button = forwardRef<HTMLButtonElement, Props>(
	({ onClick, variant = "tertiary", icon, children, awaiting, className, ...rest }, ref) => {
		const classes = classnames(
			"she-button",
			{ "she-button-primary": variant === "primary" },
			{ "she-button-secondary": variant === "secondary" },
			{ "she-button-tertiary": variant === "tertiary" },
			{ "she-button-nav": variant === "nav" },
			{ "she-button-tertiary-inverted": variant === "tertiary-inverted" },
			{ "she-button-awaiting": awaiting },
			className,
		);

		return (
			<button className={classes} onClick={onClick} ref={ref} type="button" {...rest}>
				{children}
				{icon ? <span className="she-button-icon">{icon}</span> : null}
			</button>
		);
	},
);

Button.displayName = "Button";
