import { Children } from "react";
import "./List.styles.scss";

interface Props {
	extraClassName?: string;
	children?: React.ReactNode;
}

export const List = ({ children, extraClassName }: Props) => {
	return (
		<ul className={`she-list ${extraClassName}`}>
			{Children.map(children, (c) => (
				<li>{c}</li>
			))}
		</ul>
	);
};
