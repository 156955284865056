import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ValidationHeaderPresentation } from "./ValidationHeader.presentation";
import type { Field } from "../../../../../models/fields/Field";
import type { Group } from "../../../../../models/questionnaire";
import type { State } from "../../../../../state";

interface Props {
	groups: Group[] | undefined;
	subModuleErrors?: string[];
}

const fieldHasValidationMessage = (field: Field) => field.validationMessage.length > 0;

const fieldHasActionValidationMessage = (field: Field) =>
	field.actionValidationMessage && field.actionValidationMessage.length > 0;

const fieldHasAttachmentValidationMessage = (field: Field) =>
	field.attachmentValidationMessage && field.attachmentValidationMessage.length > 0;

const relatedFieldHasValidationMessage = (field: Field) =>
	field.relatedFields &&
	field.relatedFields.some((rf) => rf.validationMessage && rf.validationMessage.length > 0);

const fieldHasValidationMessages = (field: Field) => {
	return (
		field.groupId &&
		field.id &&
		(fieldHasValidationMessage(field) ||
			fieldHasActionValidationMessage(field) ||
			fieldHasAttachmentValidationMessage(field) ||
			relatedFieldHasValidationMessage(field))
	);
};

const fieldIsPartOfGroup = (groupIds: string[], field: Field): boolean =>
	groupIds.includes(field.groupId as string);

export const ValidationHeaderContainer = ({ groups, subModuleErrors }: Props) => {
	const { t } = useTranslation();
	const fields = useSelector<State, Field[] | undefined>((state) => state.questionnaire.fields);

	const messages = useMemo(() => {
		if (subModuleErrors) {
			return subModuleErrors;
		}
		return fields
			? fields
					.filter(fieldHasValidationMessages)
					.filter((field) =>
						fieldIsPartOfGroup(groups ? groups.map((g) => g.id) : [], field),
					)
					.reduce<string[]>((acc, field) => {
						if (fieldHasValidationMessage(field)) {
							acc.push(`${field.name}: ${field.validationMessage[0]}`);
						} else if (relatedFieldHasValidationMessage(field)) {
							acc.push(
								...(field.relatedFields
									? field.relatedFields.reduce<string[]>(
											(subAcc, relatedField) => {
												if (relatedField.validationMessage.length > 0) {
													subAcc.push(
														`${relatedField.name} : ${relatedField.validationMessage[0]}`,
													);
												}

												return subAcc;
											},
											[],
									  )
									: []),
							);
						} else if (field.attachmentValidationMessage.length > 0) {
							acc.push(`${field.name}: ${field.attachmentValidationMessage[0]}`);
						} else if (field.actionValidationMessage.length > 0) {
							acc.push(`${field.name}: ${field.actionValidationMessage[0]}`);
						}

						return acc;
					}, [])
			: [];
	}, [fields, groups, subModuleErrors]);

	if (messages.length < 1) {
		return null;
	}

	return (
		<ValidationHeaderPresentation
			headerText={t("display:labelValidationHeaderText")}
			messages={messages}
		/>
	);
};
