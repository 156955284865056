import { v4 as uuid } from "uuid";
import { t } from "i18next";
import { AttachmentActionTypes } from "../../attachment";
import { DefaultBranchingService, DefaultEvaluationService } from "../../../../services/branching";
import { DefaultDictionaryService } from "../../../../services/dictionary";
import { DefaultMatrixService } from "../../../../services/matrix";
import { DefaultQuestionnaireService } from "../../../../services/questionnaire";
import { DefaultValidationService } from "../../../../services/validation";
import {
	FieldType,
	LocalFieldIds,
	LocalSectionId,
	NotApplicableComments,
	QuestionnaireProgressState,
	SectionType,
	SelectFieldType,
	SubmoduleType,
} from "../../../../models/questionnaire";
import {
	HealthMonitorSingleton,
	OnlineStatus,
} from "../../../../services/health/implementations/healthMonitor";
import { CommonActionTypes } from "../../common/actions/types";
import { DefaultLocalDataService } from "../../../../services/localData";
import { DictionarySort } from "../../../../helpers/SortingHelper";
import { ENTITY_SELECT_PROPERTY_USING_SELECTED_ORG_UNIT } from "../../../../models/questionnaire/Consts";
import { EntitySelectFieldValidator } from "../../../../validators/entitySelectFieldValidator";
import { SingleSelectFieldValidator } from "../../../../validators/singleSelectFieldValidator";
import { clearOrphanedAttachments } from "../../../../services/utilities/AttachmentHelpers";
import { getAuthUser } from "../../../../helpers/AuthenticationHelpers";
import { isAttachmentSizeCorrect } from "../../../../helpers/FileHelper";
import { isTopNAQuestion } from "../../../../helpers/IsTopNAHelper";
import { logBreadcrumb, logException } from "../../../../services/log/log";
import { QuestionnaireActionTypes } from "./types";
import { UpdatableFieldProperty } from "./enums";
import type { Matrix } from "../../../../models/matrix/matrix";
import type { AttachmentAction } from "../../attachment";
import type { BranchingService, EvaluationService } from "../../../../services/branching";
import type { DictionaryService } from "../../../../services/dictionary";
import type { MatrixService } from "../../../../services/matrix";
import type { QuestionnaireService } from "../../../../services/questionnaire";
import type { ValidationService } from "../../../../services/validation";
import type {
	Note,
	QuestionnaireTemplate,
	SubModuleRecord,
} from "../../../../models/questionnaire";
import type { BranchInfo } from "../../../../models/branching/branchInfo";
import type { BranchTriggeringInfo } from "../../../../models/branching/branchTriggeringInfo";
import type { CommonAction } from "../../common/actions/definitions";
import type { EntitySelectField } from "../../../../models/fields/EntitySelectField";
import type { Field } from "../../../../models/fields/Field";
import type { History } from "history";
import type { IndirectField } from "../../../../models/fields/IndirectField";
import type { OrgUnitField } from "../../../../models/fields/OrgUnitField";
import type { PPECategoryValue } from "../../../../models/fields/PPEField";
import type { Portal, QuestionnaireStub } from "../../../../models/portal";
import type { SelectableTextField } from "../../../../models/fields/SelectableTextField";
import type { QuestionnaireAction } from "./definitions";
import type { SimpleThunkAction } from "../../../utils/thunk";
import type { SingleSelectField } from "../../../../models/fields/SingleSelectField";
import type { State } from "../../../model";

export const reportQuestionnaireError = (
	key: string,
	exceptionMessage?: Error | false,
): CommonAction => {
	logException({
		error: exceptionMessage,
		customData: { key },
	});
	return {
		type: CommonActionTypes.ErrorHappened,
		key,
		hasError: true,
	};
};

export const reportQuestionnaireErrorWithRedirect = (
	key: string,
	portal: Portal,
	templateId: number,
	exceptionMessage?: Error | false,
): CommonAction => {
	logException({
		error: exceptionMessage,
		customData: { key },
	});
	return {
		type: CommonActionTypes.ErrorHappened,
		key,
		parameters: {
			portal: portal.key,
		},
		buttonText: t("global:cancel"),
		alternateRoutes: [
			{
				routeUrl: `/${portal.customerKey}/p/${portal.key}/forms/${templateId}`,
				label: t("display:tryAgain"),
			},
		],
		hasError: true,
		redirectUrl: `/${portal.customerKey}/p/${portal.key}`,
	};
};

const getPortalStub = (
	portal: Portal,
	template: QuestionnaireTemplate,
): QuestionnaireStub | undefined =>
	portal.questionnaireStubs.find(
		(questionnaire: QuestionnaireStub) =>
			questionnaire.key === template.questionnaire.templateId.toString(),
	);

const updateLocalTemplates = async (template: QuestionnaireTemplate): Promise<void> => {
	const localDataService = new DefaultLocalDataService();
	await localDataService.saveTemplate(template);
};

const injectPortalOrgUnit = (template: QuestionnaireTemplate, portal: Portal): void => {
	//Injects the correct OrgUnit into the orgunit field if it exists.
	const orgUnitField = template.fields.find((f) => f.id === LocalFieldIds.OrgUnit);
	if (!orgUnitField || orgUnitField.type !== FieldType.OrgUnit) {
		return;
	}

	if (orgUnitField.isForced) {
		orgUnitField.value = undefined;
		return;
	}

	orgUnitField.value =
		portal.defaultOrgUnitId || portal.maskedOrgUnitId || orgUnitField.defaultValue;
};

export const createScaffoldOnline = (
	templateId: number,
	portal: Portal,
	history: History,
): SimpleThunkAction<QuestionnaireAction | CommonAction, State> => {
	return async (dispatch) => {
		try {
			//Any errors which should stop form creation or form offline saving should
			//run sync through here, so that the process is stopped.
			//The code should either return false and dispatch an error. Or directly throw an error.
			//Any code ran within dispatches will be async and if they fail do not stop form creation

			const questionnaireService: QuestionnaireService = new DefaultQuestionnaireService({
				subdomain: "Questionnaire",
			});

			const template = await questionnaireService.getTemplate(templateId, portal.key);

			if (!portal) {
				dispatch(
					reportQuestionnaireErrorWithRedirect(
						"error:portalQuestionnaireNoPortal",
						portal,
						templateId,
					),
				);
				history.push("/error");
				return false;
			}

			if (!template) {
				dispatch(
					reportQuestionnaireErrorWithRedirect(
						"error:portalQuestionnaireMismatch",
						portal,
						templateId,
					),
				);
				history.push("/error");
				return false;
			}

			const stub = getPortalStub(portal, template);

			if (!stub) {
				dispatch(
					reportQuestionnaireErrorWithRedirect(
						"error:portalQuestionnaireMismatch",
						portal,
						templateId,
					),
				);
				history.push("/error");
				return false;
			}

			template.status.dateCreated = new Date();

			injectPortalOrgUnit(template, portal);

			template.dictionaries = await getDictionaries(templateId);

			template.matrixes = await getMatrices(template.fields);

			//By the point the template is all setup with its raw data
			//If anything were to have failed the exception would have stopped execution before any further than this

			//Any exceptions from within the update will also surface to the creator
			if (stub.makeAvailableOffline) {
				await updateLocalTemplates(template);
			}

			dispatch({ type: QuestionnaireActionTypes.Create, ...template });

			dispatch(evaluatePicklists(false));
			dispatch(updateIndirectPicklistDefaultValues());
			dispatch(evaluateBranchingInitialState());

			dispatch({
				type: QuestionnaireActionTypes.UpdateProgress,
				questionnaireState: QuestionnaireProgressState.InProgress,
			});

			return template;
		} catch (e) {
			const error = e as any;
			if (isQuotaError(error)) {
				dispatch(reportQuestionnaireError("error:createQuestionnaireFailedQuotaExceeded"));
			} else {
				if (await isTemplateAvailableOffline(templateId)) {
					//Ask user if they wish to use offline form
					dispatch({
						type: CommonActionTypes.ErrorHappened,
						key: "display:questionnaireErrors.questionnaireFailedOfflineAvailable",
						parameters: {
							portal: portal.key,
						},
						buttonText: t("global:cancel"),
						alternateRoutes: [
							{
								routeUrl: `/${portal.customerKey}/p/${portal.key}/forms/${templateId}`,
								label: t("display:tryAgain"),
							},
							{
								routeUrl: `/${portal.customerKey}/p/${portal.key}/forms/${templateId}/offline`,
								label: t(
									"display:questionnaireErrors.questionnaireOfflineFormAvailable",
								),
							},
						],
						hasError: true,
						redirectUrl: `/${portal.customerKey}/p/${portal.key}`,
					});
					return;
				}
				dispatch(
					reportQuestionnaireErrorWithRedirect(
						"error:createQuestionnaireFailed",
						portal,
						templateId,
						error,
					),
				);
			}
			history.push("/error");
		}
	};
};

export const isTemplateAvailableOffline = async (templateId: number): Promise<boolean> => {
	const localDataService = new DefaultLocalDataService();
	const template = await localDataService.getTemplateById(templateId);
	return !!template;
};

const createScaffoldOffline = (
	templateId: number,
	portal: Portal,
	history: History,
): SimpleThunkAction<QuestionnaireAction | CommonAction, State> => {
	return async (dispatch) => {
		try {
			const localDataService = new DefaultLocalDataService();
			const template = await localDataService.getTemplateById(templateId);
			if (!template) {
				dispatch(
					reportQuestionnaireErrorWithRedirect(
						"error:portalQuestionnaireMismatch",
						portal,
						templateId,
					),
				);
				history.push("/error");
				return;
			}

			template.status.dateCreated = new Date();

			// update the questionnaire id so we don't have multiple records with the same guid
			const newQuestionnaireId = uuid();
			template.questionnaire.id = newQuestionnaireId;
			template.sections.forEach((s) => (s.questionnaireId = newQuestionnaireId));
			template.groups.forEach((g) => (g.questionnaireId = newQuestionnaireId));
			template.fields.forEach((f) => (f.questionnaireId = newQuestionnaireId));
			template.subModules.forEach((sm) => (sm.questionnaireId = newQuestionnaireId));
			template.branchConditions.forEach((b) => (b.questionnaireId = newQuestionnaireId));
			/* eslint-disable @typescript-eslint/no-unnecessary-condition -- we need this for backward compatibility */
			template.dictionaries = template.dictionaries || [];
			template.matrixes = template.matrixes || [];
			template.notes = template.notes || [];
			/* eslint-enable */

			dispatch({ type: QuestionnaireActionTypes.Create, ...template });

			dispatch(evaluatePicklists(false));
			dispatch(updateIndirectPicklistDefaultValues());
			dispatch(evaluateBranchingInitialState());

			dispatch({
				type: QuestionnaireActionTypes.UpdateProgress,
				questionnaireState: QuestionnaireProgressState.InProgress,
			});
		} catch (e) {
			const error = e as any;
			if (
				error.message &&
				typeof error.message === "string" &&
				error.message.includes("QuotaExceededError")
			) {
				dispatch(
					reportQuestionnaireErrorWithRedirect(
						"error:createQuestionnaireFailedQuotaExceeded",
						portal,
						templateId,
						error,
					),
				);
			} else {
				dispatch(
					reportQuestionnaireErrorWithRedirect(
						"error:createQuestionnaireFailed",
						portal,
						templateId,
						error,
					),
				);
			}
			history.push("/error");
		}
	};
};

export const createQuestionnaire = (
	templateId: number,
	portal: Portal,
	history: History,
	useOfflineForm?: boolean,
): SimpleThunkAction<QuestionnaireAction | CommonAction, State> => {
	return async (dispatch) => {
		const healthApi = await HealthMonitorSingleton.getInstance().checkIfOnline();
		if (useOfflineForm || healthApi !== OnlineStatus.Available) {
			//Using offline form, or user is offline
			return dispatch(createScaffoldOffline(templateId, portal, history));
		}
		return dispatch(createScaffoldOnline(templateId, portal, history));
	};
};

const isQuotaError = (error: any): boolean => {
	if (
		error?.message &&
		typeof error.message === "string" &&
		error.message.includes("QuotaExceededError")
	) {
		return true;
	}
	return false;
};

export const loadQuestionnaire = (
	recordId: string,
): SimpleThunkAction<QuestionnaireAction | CommonAction, State> => {
	return async (dispatch) => {
		const localDataService = new DefaultLocalDataService();
		let user = null;
		try {
			user = await getAuthUser();
		} catch {
			return;
		}
		const record: QuestionnaireTemplate | undefined =
			await localDataService.getQuestionnaireById(recordId, user);
		if (record) {
			record.status.isSubmittingInProgress = false;
			record.status.displayValidationMessage = false;
		}

		if (record) {
			dispatch({
				type: QuestionnaireActionTypes.Load,
				questionnaireRecord: record,
			});
		}
	};
};

export const submitQuestionnaire = (
	questionnaireId: string,
	portal: Portal,
): SimpleThunkAction<QuestionnaireAction | CommonAction, State> => {
	return async (dispatch, getState) => {
		try {
			const status = getState().questionnaire.status;
			if (status) {
				dispatch({
					type: QuestionnaireActionTypes.UpdateStatus,
					isValidated: status.isValidated,
					isSubmittingInProgress: true,
					errors: [],
					displayValidationMessage: false,
				});
			}
			const hiddenSections = getState()
				.questionnaire.sections.filter((s) => !s.isVisible)
				.map((x) => {
					return x.id;
				});
			const hiddenGroups = getState()
				.questionnaire.groups.filter((g) => hiddenSections.includes(g.sectionId))
				.map((x) => {
					return x.id;
				});
			const fields = getState().questionnaire.fields.filter(
				(f) =>
					!f.hidden &&
					f.subModuleId === undefined &&
					f.groupId &&
					!hiddenGroups.includes(f.groupId),
			);

			const notApplicableComments =
				getState().questionnaire.questionnaire?.notApplicableComments;

			// error cleanup
			fields.forEach(({ id }) => {
				if (id > 0) {
					dispatch({
						type: QuestionnaireActionTypes.UpdateValidationStatus,
						fieldId: id,
						value: [],
					});
				}
			});

			const validationService = new DefaultValidationService();
			const fieldValidationResults = validationService.validateAllFields(
				fields,
				portal,
				notApplicableComments,
			);

			if (fieldValidationResults.length > 0) {
				fieldValidationResults.forEach((result) => {
					if (!result.isValid) {
						dispatch({
							type: QuestionnaireActionTypes.UpdateValidationStatus,
							fieldId: result.fieldId,
							value: result.validationMessages,
							relatedField: result.relatedField,
						});
					}
				});
			}

			const questionnaire = getState().questionnaire.questionnaire;

			const actions = getState().questionnaire.subModuleRecords.filter(
				(x) => x.subModuleId === LocalSectionId.Actions,
			);
			const mandatoryActionsResults = validationService.validateMandatoryActions(
				fields,
				actions,
				!!(questionnaire && questionnaire.allowActions),
			);

			if (mandatoryActionsResults.length > 0) {
				mandatoryActionsResults.forEach((result) => {
					if (!result.isValid) {
						dispatch({
							type: QuestionnaireActionTypes.UpdateFieldProperty,
							fieldId: result.fieldId,
							propertyName: UpdatableFieldProperty.actionValidationMessage,
							value: result.validationMessages,
						});
					}
				});
			}

			const attachments = getState().recordAttachments.attachments;
			const mandatoryAttachments = validationService.validateMandatoryAttachments(
				fields,
				attachments,
				!!(
					questionnaire &&
					questionnaire.maxAttachments &&
					questionnaire.maxAttachments > 0
				),
			);

			if (mandatoryAttachments.length > 0) {
				mandatoryAttachments.forEach((result) => {
					if (!result.isValid) {
						dispatch({
							type: QuestionnaireActionTypes.UpdateFieldProperty,
							fieldId: result.fieldId,
							propertyName: UpdatableFieldProperty.attachmentValidationMessage,
							value: result.validationMessages,
						});
					}
				});
			}

			if (
				fieldValidationResults.length > 0 ||
				mandatoryActionsResults.length > 0 ||
				mandatoryAttachments.length > 0
			) {
				const sectionsWithErrors: number[] = [];
				fieldValidationResults.forEach(
					(x) => x.sectionId !== undefined && sectionsWithErrors.push(x.sectionId),
				);
				mandatoryActionsResults.forEach(
					(x) => x.sectionId !== undefined && sectionsWithErrors.push(x.sectionId),
				);
				mandatoryAttachments.forEach(
					(x) => x.sectionId !== undefined && sectionsWithErrors.push(x.sectionId),
				);

				// // Set validation flag
				dispatch({
					type: QuestionnaireActionTypes.Submit,
					isValidated: true,
					isSubmittingInProgress: false,
					errors: [],
					displayValidationMessage: true,
					firstSectionIdWithErrors: Math.min(...sectionsWithErrors),
				});

				return;
			}

			const healthApi = await HealthMonitorSingleton.getInstance().checkIfOnline();
			if (healthApi === OnlineStatus.Available) {
				// if we have a token then start building and submitting questionnaire
				const questionnaireService: QuestionnaireService = new DefaultQuestionnaireService({
					subdomain: "Questionnaire",
				});
				const attachmentsToSubmit = clearOrphanedAttachments(
					getState().recordAttachments.attachments,
					getState().questionnaire.subModuleRecords,
				);

				for (let i = attachmentsToSubmit.length - 1; i >= 0; i--) {
					const attachment = attachmentsToSubmit[i];
					if (!isAttachmentSizeCorrect(attachment)) {
						logException({
							error: new Error("Found corrupted attachment, loading from storage"),
							customData: { fileSize: attachment.file.size },
						});
						let user = null;
						try {
							user = await getAuthUser();
						} catch {
							return;
						}
						const localDataService = new DefaultLocalDataService();
						const newAttachment = await localDataService.loadAttachment(
							attachment.id,
							user,
						);
						attachmentsToSubmit.filter((value) => value.id !== attachment.id);
						attachmentsToSubmit.push(newAttachment);
					}
				}

				const result = await questionnaireService.submitQuestionnaire(
					getState().questionnaire,
					attachmentsToSubmit,
				);
				if (!result.isSaved) {
					if (result.errors instanceof Error) {
						throw result.errors;
					}
					throw new Error(
						result.errors
							? result.errors.toString()
							: "Error happened when trying to submit form: form not saved",
					);
				}
				logBreadcrumb("Form Submitted Normally", {
					questionnaireId,
				});
				dispatch({
					type: QuestionnaireActionTypes.Submit,
					isValidated: !result.isSaved,
					isSubmitSuccess: result.isSaved,
					isSubmittingInProgress: false,
					errors: result.errors,
					displayValidationMessage: false,
				});
			} else {
				logBreadcrumb("Form added to Queue", {
					questionnaireId,
				});
				dispatch({
					type: QuestionnaireActionTypes.UpdateProgress,
					questionnaireState: QuestionnaireProgressState.Completed,
				});
			}
		} catch (e) {
			logException(e);
			dispatch({
				type: QuestionnaireActionTypes.Submit,
				isValidated: true,
				isSubmitSuccess: false,
				isSubmittingInProgress: false,
				errors: [e],
				displayValidationMessage: false,
			} as any);
		}
	};
};

export const updateQuestionnaireStatus = (
	isValidated: boolean,
	isSubmitSuccess: boolean | undefined,
	errors: string[],
): SimpleThunkAction<QuestionnaireAction | CommonAction> => {
	return (dispatch) => {
		dispatch({
			type: QuestionnaireActionTypes.UpdateStatus,
			isValidated,
			isSubmitSuccess,
			isSubmittingInProgress: false,
			errors,
			displayValidationMessage: false,
		});
	};
};

export const cancelQuestionnaire = (
	saveQuestionnaire: boolean,
	questionnaireId?: string,
): SimpleThunkAction<QuestionnaireAction | CommonAction, State> => {
	return async (dispatch, getState) => {
		if (saveQuestionnaire && questionnaireId) {
			const questionnaire = getState().questionnaire;
			const localDataService = new DefaultLocalDataService();
			let user = null;
			try {
				user = await getAuthUser();
			} catch {
				return;
			}
			if (questionnaire.questionnaire) {
				await localDataService.saveQuestionnaire(
					questionnaire as QuestionnaireTemplate,
					user,
				);
			}
		}

		dispatch({
			type: QuestionnaireActionTypes.Cancel,
			saveQuestionnaire,
			questionnaireId,
		});
	};
};

export const updateField = (
	fieldId: number,
	value: any,
	cleanUpdate?: boolean,
	additionalParams?: Record<string, any>,
): SimpleThunkAction<QuestionnaireAction | CommonAction, State> => {
	return (dispatch, getState) => {
		try {
			dispatch({
				type: QuestionnaireActionTypes.UpdateField,
				fieldId,
				value,
				cleanUpdate,
				additionalParams,
			});

			const state = getState();

			const fieldUpdated = state.questionnaire.fields.find((f) => f.id === fieldId);

			if (fieldUpdated != null) {
				const section = state.questionnaire.sections.find(
					(s) => s.id === fieldUpdated.sectionId,
				);
				const isIqField = section ? section.type === SectionType.iQ : false;

				if (isIqField) {
					dispatch(evaluateBranchingConditionForField(fieldUpdated));
				}

				if (fieldUpdated.type === FieldType.OrgUnit) {
					dispatch(evaluatePicklists(true));
					dispatch(evaluatePPEPicklists());

					// Clear entity select fields that depend on selected OrgUnit
					const entitySelectFields = state.questionnaire.fields.filter(
						(field) =>
							(field.type === FieldType.EntitySelect ||
								field.type === FieldType.EntitySelectWithDefault) &&
							(ENTITY_SELECT_PROPERTY_USING_SELECTED_ORG_UNIT as string[]).includes(
								field.propertyName,
							) &&
							field.value,
					);
					entitySelectFields.forEach((field) => {
						dispatch(updateField(field.id, undefined));
						const validator = new EntitySelectFieldValidator(
							field as EntitySelectField,
						);
						if (!validator.isFieldValid(undefined)) {
							dispatch(updateValidationStatus(field.id, validator.messages));
						}
					});
				}

				// If the field is a select field then check if any child picklists need to be updated.
				if (
					fieldUpdated.type === FieldType.Select &&
					fieldUpdated.selectType === SelectFieldType.SingleSelect
				) {
					const selectField = fieldUpdated as SingleSelectField;
					if (
						selectField.childDictionaryKeys &&
						selectField.childDictionaryKeys.length > 0
					) {
						selectField.childDictionaryKeys.forEach((child) => {
							dispatch(evaluateChildPicklists(child, value));
						});
					}
				}
			}
		} catch (e) {
			logException(e);
			dispatch({
				type: CommonActionTypes.ErrorHappened,
				key: "error:updateFieldFailed",
				hasError: true,
			});
		}
	};
};

export const updateFieldProperty = (
	fieldId: number,
	value: any,
	propertyName: UpdatableFieldProperty,
	cleanUpdate?: boolean,
): SimpleThunkAction<QuestionnaireAction | CommonAction> => {
	return (dispatch) => {
		try {
			dispatch({
				type: QuestionnaireActionTypes.UpdateFieldProperty,
				fieldId,
				value,
				propertyName,
				cleanUpdate,
			});
		} catch (e) {
			logException(e);
			dispatch({
				type: CommonActionTypes.ErrorHappened,
				key: "error:updateFieldFailed",
				hasError: true,
			});
		}
	};
};

export const updateValidationStatus = (
	fieldId: number,
	value: string[],
): SimpleThunkAction<QuestionnaireAction | CommonAction> => {
	return (dispatch) => {
		// TODO why try catch on dispatch? what can go wrong?
		try {
			dispatch({
				type: QuestionnaireActionTypes.UpdateValidationStatus,
				fieldId,
				value,
			});
		} catch (e) {
			logException(e);
			dispatch({
				type: CommonActionTypes.ErrorHappened,
				key: "error:updateValidationStatusFailed",
				hasError: true,
			});
		}
	};
};

export const createSubModuleRecord = (
	subModuleId: number,
	subModuleRecord: SubModuleRecord,
): SimpleThunkAction<QuestionnaireAction | CommonAction> => {
	return (dispatch) => {
		try {
			dispatch({
				type: QuestionnaireActionTypes.CreateSubModuleRecord,
				subModuleId,
				subModuleRecord,
			});
		} catch (e) {
			logException(e);
			dispatch({
				type: CommonActionTypes.ErrorHappened,
				key: "error:createSubModuleRecordFailed",
				hasError: true,
			});
		}
	};
};

export const editSubModuleRecord = (
	subModuleRecordId: string,
	subModuleRecord: SubModuleRecord,
): SimpleThunkAction<QuestionnaireAction | CommonAction> => {
	return (dispatch) => {
		try {
			dispatch({
				type: QuestionnaireActionTypes.EditSubModuleRecord,
				subModuleRecordId,
				subModuleRecord,
			});
		} catch (e) {
			logException(e);
			dispatch({
				type: CommonActionTypes.ErrorHappened,
				key: "error:editSubModuleRecordFailed",
				hasError: true,
			});
		}
	};
};

export const removeSubModuleRecord = (
	recordId: string,
	subModuleId: number,
): SimpleThunkAction<QuestionnaireAction | AttachmentAction | CommonAction> => {
	return (dispatch, getState) => {
		try {
			const state = getState() as State;
			const attachmentsToRemove = state.recordAttachments.attachments.filter(
				(a) => a.parentGuid === recordId,
			);
			attachmentsToRemove.forEach((a) => {
				dispatch({ type: AttachmentActionTypes.Remove, attachmentId: a.id });
			});
			dispatch({
				type: QuestionnaireActionTypes.DeleteSubModuleRecord,
				recordId,
				subModuleId,
			});
		} catch (e) {
			logException(e);
			dispatch({
				type: CommonActionTypes.ErrorHappened,
				key: "error:removeSubModuleRecordFailed",
				hasError: true,
			});
		}
	};
};

export const getDictionaries = async (questionnaireId: number) => {
	const dictionaryService: DictionaryService = new DefaultDictionaryService({
		subdomain: "Dictionary",
	});
	const response = await dictionaryService.getDictionaries(questionnaireId);
	return response.dictionaries;
};

export const getMatrices = async (fields: Field[] | undefined): Promise<Matrix[]> => {
	const matrixService: MatrixService = new DefaultMatrixService({
		subdomain: "Matrix",
	});

	const result = await matrixService.getMatrices(fields);

	return result.matrixList;
};

export const updateComments = (
	fieldId: number,
	comments: string,
): SimpleThunkAction<QuestionnaireAction | CommonAction, State> => {
	return (dispatch, getState) => {
		try {
			dispatch({
				type: QuestionnaireActionTypes.UpdateComment,
				fieldId,
				comments,
			});

			const state = getState();

			const fieldUpdated = state.questionnaire.fields.find((f) => f.id === fieldId);

			if (fieldUpdated != null) {
				const validationService: ValidationService = new DefaultValidationService();
				const notApplicableComments =
					getState().questionnaire.questionnaire!.notApplicableComments;
				const validationResults = validationService.validateField(
					fieldUpdated,
					state.portal.portals[0],
					notApplicableComments,
				);

				const valMessages: string[] = [];

				validationResults.forEach((x) => {
					valMessages.push(...x.validationMessages);
				});

				dispatch({
					type: QuestionnaireActionTypes.UpdateValidationStatus,
					fieldId,
					value: valMessages,
				});
			}
		} catch (e) {
			logException(e);
			dispatch({
				type: CommonActionTypes.ErrorHappened,
				key: "error:updateCommentsFailed",
				hasError: true,
			});
		}
	};
};

export const updateNotApplicable = (
	fieldId: number,
	notApplicable: boolean,
	comments: boolean,
): SimpleThunkAction<QuestionnaireAction | CommonAction, State> => {
	return (dispatch, getState) => {
		try {
			dispatch({
				type: QuestionnaireActionTypes.UpdateNotApplicable,
				fieldId,
				notApplicable,
				comments,
			});

			const state = getState();

			const fieldUpdated = state.questionnaire.fields.find((f) => f.id === fieldId);

			if (fieldUpdated != null) {
				dispatch(evaluateBranchingConditionForField(fieldUpdated));
			}
		} catch (e) {
			logException(e);
		}
	};
};

export const createNote = (note: Note): SimpleThunkAction<QuestionnaireAction | CommonAction> => {
	return (dispatch) => {
		try {
			dispatch({
				type: QuestionnaireActionTypes.CreateNote,
				note,
			});
		} catch (e) {
			logException(e);
			dispatch({
				type: CommonActionTypes.ErrorHappened,
				key: "error:unauthorized",
				hasError: true,
			});
		}
	};
};

export const deleteNote = (note: Note): SimpleThunkAction<QuestionnaireAction | CommonAction> => {
	return (dispatch) => {
		try {
			dispatch({
				type: QuestionnaireActionTypes.DeleteNote,
				note,
			});
		} catch (e) {
			logException(e);
			dispatch({
				type: CommonActionTypes.ErrorHappened,
				key: "error:unauthorized",
				hasError: true,
			});
		}
	};
};

export const editNote = (note: Note): SimpleThunkAction<QuestionnaireAction | CommonAction> => {
	return (dispatch) => {
		try {
			dispatch({
				type: QuestionnaireActionTypes.EditNote,
				note,
			});
		} catch (e) {
			logException(e);
			dispatch({
				type: CommonActionTypes.ErrorHappened,
				key: "error:unauthorized",
				hasError: true,
			});
		}
	};
};

export const evaluateBranchingInitialState = (): SimpleThunkAction<
	QuestionnaireAction | CommonAction,
	State
> => {
	return (dispatch, getState) => {
		const es = new DefaultEvaluationService();
		const bs = new DefaultBranchingService();
		const questionnaire = getState().questionnaire;

		if (questionnaire.branchConditions.length) {
			const branchInitInfo: BranchInfo = {
				sections: questionnaire.sections,
				fields: questionnaire.fields,
				conditions: questionnaire.branchConditions,
			};

			const branchinginfo = bs.initialiseQuestionnaire(branchInitInfo, es);

			dispatch({
				type: QuestionnaireActionTypes.BranchingTriggered,
				sections: branchinginfo.sections,
				fields: branchinginfo.fields,
				conditions: branchinginfo.conditions,
			});
		}
	};
};

export const evaluateBranchingConditionForField = (
	field: Field,
	branchingService?: BranchingService,
	evaluationService?: EvaluationService,
): SimpleThunkAction<QuestionnaireAction | CommonAction, State> => {
	return (dispatch, getState) => {
		const bs = branchingService || new DefaultBranchingService();
		const es = evaluationService || new DefaultEvaluationService();
		const state = getState();

		const triggeringInfo: BranchTriggeringInfo = {
			fieldUpdated: field,
			sections: state.questionnaire.sections,
			fields: state.questionnaire.fields,
			conditions: state.questionnaire.branchConditions,
		};
		const branchinginfo: BranchInfo = bs.getBranchingChanges(triggeringInfo, es);

		dispatch({
			type: QuestionnaireActionTypes.BranchingTriggered,
			sections: branchinginfo.sections,
			fields: branchinginfo.fields,
			conditions: branchinginfo.conditions,
		});
	};
};

export const updateIndirectPicklistDefaultValues = (): SimpleThunkAction<
	QuestionnaireAction | CommonAction,
	State
> => {
	return (dispatch, getState) => {
		const questionnaire = getState().questionnaire;
		const eligibleFields = questionnaire.fields.filter(
			(f) =>
				f.type === FieldType.Indirect &&
				f.originalField.type === FieldType.Select &&
				f.originalField.selectType === SelectFieldType.SingleSelect &&
				!f.value,
		);
		const eligibleFieldsWithDictionaries: IndirectField[] = (
			eligibleFields as IndirectField[]
		).filter((f) => {
			const ssf = f.originalField as SingleSelectField;
			return !!(ssf.dictionaryKey && ssf.dictionaryKey.length);
		});
		eligibleFieldsWithDictionaries.forEach((f) => {
			const singleSelectField = f.originalField as SingleSelectField;
			const dictionary = questionnaire.dictionaries.find(
				(d) => d.key === (f.originalField as SingleSelectField).dictionaryKey,
			);
			if (dictionary && singleSelectField.defaultValue) {
				const item = dictionary.dictionaryItems.find(
					(di) => di.id === singleSelectField.defaultValue,
				);
				const value = item && item.text;
				if (value) {
					dispatch(updateField(f.id, value, true));
				}
			}
		});
		dispatch({
			type: QuestionnaireActionTypes.UpdateIndirectPicklistTriggered,
		});
	};
};

export const evaluatePicklists = (
	triggerValidation: boolean,
): SimpleThunkAction<QuestionnaireAction | CommonAction, State> => {
	return (dispatch, getState) => {
		const questionnaire = getState().questionnaire;
		const orgUnitField = questionnaire.fields.find(
			(f) => f.id === LocalFieldIds.OrgUnit,
		) as OrgUnitField;
		if (orgUnitField) {
			const elligbleFields = questionnaire.fields
				.reduce<Field[]>((acc, field) => {
					acc.push(field);

					if (field.relatedFields) {
						field.relatedFields.forEach((rf) => acc.push(rf));
					}

					return acc;
				}, [])
				.filter(
					(f) =>
						(f.type === FieldType.Select &&
							f.selectType === SelectFieldType.SingleSelect) ||
						f.type === FieldType.SelectableText,
				);

			// only select top level select lists. Any children are handled in the evaluateChildPicklists function
			const elligbleSelectFields = (
				elligbleFields as (SingleSelectField | SelectableTextField)[]
			).filter(
				(f: SingleSelectField | SelectableTextField) =>
					f.dictionaryKey && f.dictionaryKey.length && !f.parentDictionaryKey,
			);
			elligbleSelectFields.forEach((f) => {
				const dictionary = questionnaire.dictionaries.find(
					(d) => d.key === f.dictionaryKey,
				);
				if (dictionary) {
					DictionarySort(dictionary);

					let items: Map<number, string>;
					if (orgUnitField.value) {
						items = new Map<number, string>(
							dictionary.dictionaryItems
								.filter(
									(di) =>
										di.orgUnitIds.includes(orgUnitField.value || 0) ||
										di.orgUnitIds.length === 0,
								)
								.map((i: any) => [i.id, i.text]),
						);
						dispatch({
							type: QuestionnaireActionTypes.AttachDictionary,
							fieldId: f.id,
							data: items,
						});
					} else {
						items = new Map<number, string>(
							dictionary.dictionaryItems
								.filter((di) => di.orgUnitIds.length === 0)
								.map((i: any) => [i.id, i.text]),
						);
						dispatch({
							type: QuestionnaireActionTypes.AttachDictionary,
							fieldId: f.id,
							data: items,
						});
					}

					if (f.type === FieldType.Select) {
						if (f.value !== undefined) {
							let value: number | undefined = f.value;
							if (!items.has(value || -1) && !f.hidden) {
								value = undefined;
								dispatch(updateField(f.id, value, true));
							}

							if (f.childDictionaryKeys && f.childDictionaryKeys.length > 0) {
								f.childDictionaryKeys.forEach((childKey) => {
									dispatch(evaluateChildPicklists(childKey, value));
								});
							}

							if (triggerValidation) {
								const validator = new SingleSelectFieldValidator(f);
								if (!validator.isFieldValid(value)) {
									dispatch(updateValidationStatus(f.id, validator.messages));
								}
							}
						}
					}
				}
			});
		}
		dispatch({
			type: QuestionnaireActionTypes.EvaluatePicklistsTriggered,
		});
	};
};

export const evaluateChildPicklists = (
	dictionaryKey: string,
	parentValue?: number,
): SimpleThunkAction<QuestionnaireAction | CommonAction, State> => {
	return (dispatch, getState) => {
		const state = getState();
		const orgUnitField = state.questionnaire.fields.find(
			(f) => f.id === LocalFieldIds.OrgUnit,
		) as OrgUnitField;
		const dictionary = state.questionnaire.dictionaries.find((d) => d.key === dictionaryKey);
		const elligbleFields = state.questionnaire.fields.filter(
			(f) => f.type === FieldType.Select && f.selectType === SelectFieldType.SingleSelect,
		);
		const elligbleSelectFields = (elligbleFields as SingleSelectField[]).filter(
			(f: SingleSelectField) => f.dictionaryKey === dictionaryKey,
		);
		const allSelectableTextFields = state.questionnaire.fields.filter(
			(f) => f.type === FieldType.SelectableText,
		);
		const elligbleSelectableTextFields = (
			allSelectableTextFields as SelectableTextField[]
		).filter((f: SelectableTextField) => f.dictionaryKey === dictionaryKey);
		if (dictionary) {
			DictionarySort(dictionary);
			let filteredItems = new Map<number, string>();

			if (parentValue) {
				filteredItems = new Map<number, string>(
					dictionary.dictionaryItems
						.filter(
							(di) =>
								(di.orgUnitIds.includes(orgUnitField.value || 0) ||
									di.orgUnitIds.length === 0) &&
								di.parentDictionaryItemId === parentValue,
						)
						.map((i: any) => [i.id, i.text]),
				);
			}

			elligbleSelectableTextFields.forEach((field) => {
				if (!field.hidden) {
					dispatch({
						type: QuestionnaireActionTypes.AttachDictionary,
						fieldId: field.id,
						data: filteredItems,
					});
				}
			});

			elligbleSelectFields.forEach((field) => {
				let value: number | undefined = field.value;
				if (!field.hidden) {
					dispatch({
						type: QuestionnaireActionTypes.AttachDictionary,
						fieldId: field.id,
						data: filteredItems,
					});

					if (field.value !== undefined) {
						if (!filteredItems.has(value || -1)) {
							value = undefined;
							dispatch(updateField(field.id, value, true));
						}
					}
				}

				if (field.childDictionaryKeys && field.childDictionaryKeys.length > 0) {
					field.childDictionaryKeys.forEach((childKey) => {
						dispatch(evaluateChildPicklists(childKey, value));
					});
				}
			});
		}
	};
};

export const markSectionNA = (
	groupId: string,
	comment: string,
): SimpleThunkAction<QuestionnaireAction | CommonAction, State> => {
	return (dispatch, getState) => {
		const state = getState();

		const visibleFields = state.questionnaire.fields.filter(
			(f) => f.groupId === groupId && !f.isConfidential && !f.hidden,
		);

		const elligibleFields = visibleFields
			.filter((f) => f.allowNotApplicable)
			.reduce((acc: Field[], field) => {
				if (isTopNAQuestion(field, visibleFields, state.questionnaire.branchConditions)) {
					acc.push(field);
				}
				return acc;
			}, []);

		const notApplicableComments =
			state.questionnaire.questionnaire &&
			state.questionnaire.questionnaire.notApplicableComments;

		elligibleFields.forEach((f) => {
			dispatch(
				updateNotApplicable(
					f.id,
					true,
					notApplicableComments === NotApplicableComments.Required ||
						notApplicableComments === NotApplicableComments.Visible,
				),
			);
			dispatch(updateValidationStatus(f.id, []));
			if (
				notApplicableComments === NotApplicableComments.Required ||
				notApplicableComments === NotApplicableComments.Visible
			) {
				dispatch(updateComments(f.id, comment));
			}
		});
	};
};

export const makeDirty = (): SimpleThunkAction<QuestionnaireAction | CommonAction> => {
	return (dispatch) => {
		try {
			dispatch({
				type: QuestionnaireActionTypes.MakeDirty,
			});
		} catch (e) {
			logException(e);
			dispatch({
				type: CommonActionTypes.ErrorHappened,
				key: "error:createSubModuleRecordFailed",
				hasError: true,
			});
		}
	};
};

export const evaluatePPEPicklists = (): SimpleThunkAction<
	QuestionnaireAction | CommonAction,
	State
> => {
	return (dispatch, getState) => {
		const state = getState();
		const orgUnitField = state.questionnaire.fields.find(
			(f) => f.id === LocalFieldIds.OrgUnit,
		) as OrgUnitField;

		if (orgUnitField && orgUnitField.value !== undefined) {
			const ppeSubmodule = state.questionnaire.subModules.find(
				(submodule) => submodule.type === SubmoduleType.PPE,
			);
			if (ppeSubmodule) {
				const ppeRecord = state.questionnaire.subModuleRecords.find(
					(record) => record.subModuleId === ppeSubmodule.id,
				);
				if (ppeRecord && ppeRecord.values.length) {
					const ppeValue = ppeRecord.values[0].value as PPECategoryValue[];
					if (
						ppeValue &&
						ppeValue.length &&
						ppeValue.some(
							(categoryValue) =>
								categoryValue.option && categoryValue.option.orgUnitIds.length,
						)
					) {
						// rebuild the PPE value as some answers might be org uint specific
						const newValue: PPECategoryValue[] = [...ppeValue];

						newValue.forEach((value) => {
							if (
								value.option &&
								value.option.orgUnitIds.length !== 0 &&
								orgUnitField.value &&
								!value.option.orgUnitIds.includes(orgUnitField.value)
							) {
								value.option = undefined;
							}
						});

						dispatch({
							type: QuestionnaireActionTypes.EditSubModuleRecord,
							subModuleRecordId: ppeRecord.localId,
							subModuleRecord: {
								...ppeRecord,
								values: [{ ...ppeRecord.values[0], value: newValue }],
							},
						});
					}
				}
			}
		}
	};
};

export const fileSizeError = (
	error: boolean,
): SimpleThunkAction<QuestionnaireAction | CommonAction> => {
	return (dispatch) => {
		dispatch({
			type: QuestionnaireActionTypes.FileSizeError,
			error,
		});
	};
};
