import { QuestionnaireActionTypes } from "../components/questionnaire/actions/types";
import { FieldType } from "../../models/questionnaire";
import { DateTimeFieldValidator } from "../../validators/dateTimeFieldValidator";
import type { Dispatch, MiddlewareAPI } from "redux";
import type { State } from "../model";
import type { UpdateFieldAction } from "../components/questionnaire/actions/definitions";
import type { Field } from "../../models/fields/Field";
import type { DateField } from "../../models/fields/DateField";

const validateDate = (field: Field, value: any, state: State, next: Dispatch) => {
	const { id } = field;
	const validator = new DateTimeFieldValidator(field as DateField);
	if (!validator.isFieldValid(value, state.questionnaire.fields)) {
		next({
			type: QuestionnaireActionTypes.UpdateValidationStatus,
			fieldId: id,
			value: validator.messages,
		});
	} else {
		next({
			type: QuestionnaireActionTypes.UpdateValidationStatus,
			fieldId: id,
			value: [],
		});
	}
};

export const validationMiddleware =
	(api: MiddlewareAPI<any, State>) => (next: Dispatch) => (action: any) => {
		if (action.type === QuestionnaireActionTypes.UpdateField) {
			const { fieldId, value } = action as UpdateFieldAction;
			const state = api.getState();
			const field = state.questionnaire.fields.find((field) => field.id === fieldId);
			if (field) {
				// TODO move all validation from defaultValidationService here instead of launching it in every field
				switch (field.type) {
					case FieldType.Date:
						validateDate(field, value, state, next);
				}
			}
		}
		return next(action);
	};
