import { useMemo } from "react";
import { TextFieldValidator } from "../../../../validators/textFieldValidator";
import { TextInputContainer } from "./TextInput.container";
import type { IndirectField } from "../../../../models/fields/IndirectField";
import type { SelectableTextField } from "../../../../models/fields/SelectableTextField";
import type { TextField } from "../../../../models/fields/TextField";

interface Props {
	textField: TextField | IndirectField | SelectableTextField;
	updateField: (fieldId: number, value: any) => void;
	updateValidationStatus: (fieldId: number, value: string[]) => void;
	isExternalValidationRequired?: boolean;
	onValidation?: () => void;
}

export const TextInputComponent = ({
	textField,
	updateField,
	updateValidationStatus,
	isExternalValidationRequired,
	onValidation,
}: Props) => {
	const validator = useMemo(() => new TextFieldValidator(textField), [textField]);

	return (
		<TextInputContainer
			isExternalValidationRequired={isExternalValidationRequired}
			onValidation={onValidation}
			textField={textField}
			updateField={(value) => updateField(textField.id, value)}
			updateValidationStatus={(value) => updateValidationStatus(textField.id, value)}
			validator={validator}
		/>
	);
};
