import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { commonError } from "../../../../state";
import { NotificationModal } from "../../modals";
import type { CommonErrorType, State } from "../../../../state";

export interface Props {
	reason: CommonErrorType;
	handleOnce: boolean;
	message: string;
}

export const CommonErrorNotification = ({ reason, handleOnce, message }: Props) => {
	const [errorHandled, setErrorHandled] = useState(false);
	const dispatch = useDispatch();
	const error = useSelector<State, boolean>((state) => state.common.commonError === reason);

	useEffect(() => {
		dispatch(commonError(undefined));
	}, [dispatch]);

	const handleClose = () => {
		if (!handleOnce) {
			dispatch(commonError(undefined));
		} else {
			setErrorHandled(true);
		}
	};

	return (
		<NotificationModal
			content={message}
			onClose={handleClose}
			show={!!error && !errorHandled}
			withHistory={false}
		/>
	);
};
