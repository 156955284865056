import { useCallback, useContext, useEffect, useState } from "react";
import { AuthProvider, hasCodeInUrl } from "oidc-react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../state";
import {
	clearPreSignInURL,
	getAuthUser,
	getCustomerKey,
	getPortalKey,
	getPreSignInURL,
	getUserManager,
} from "../../../helpers/AuthenticationHelpers";
import { Loading } from "../Loading";
import { APIHealthContext } from "../../../services/health/implementations/healthContext/healthContext";
import { OnlineStatus } from "../../../services/health/implementations/healthMonitor";
import { ForceLoginModal } from "../modals/ForceLoginModal";
import { PortalAuthenticationMode } from "../../../models/portal";
import { useGainsightLogin } from "../../../services/gainsight";
import type { State } from "../../../state";
import type { UserManager } from "oidc-react";

type Props = {
	children?: React.ReactNode;
};

export const Authentication = ({ children }: Props) => {
	const history = useHistory();
	const [userManager, setUserManager] = useState<UserManager | undefined>();
	const [authenticationStatus, setAuthenticationStatus] = useState<
		"loading" | "private" | "public" | null
	>(null);
	const health = useContext(APIHealthContext);
	const dispatch = useDispatch();
	const portalAuthenticationMode = useSelector<State, PortalAuthenticationMode | undefined>(
		(state) => {
			return state.portal.portals[0] && state.portal.portals[0].authenticationMode;
		},
	);

	const authToken = useSelector<State, string | undefined>((s) => s.auth.token);
	const isForcingLogin = useSelector<State, boolean>((s) => s.auth.forcingLoginModal);
	const customerKey = getCustomerKey();
	const portalKey = getPortalKey();
	const customerKeyLowercase = customerKey.toLowerCase();
	const portalKeyLowercase = portalKey.toLowerCase();

	const signInCallbackRoute = useRouteMatch(
		"/:customerKey/p/:portalKey/authentication/callback*",
	);

	const signInSilentCallbackRoute = useRouteMatch(
		"/:customerKey/p/:portalKey/authentication/silent_callback*",
	);

	useEffect(() => {
		if (signInSilentCallbackRoute && hasCodeInUrl(history.location)) {
			getUserManager().then((userManager) => {
				userManager.signinSilentCallback();
			});
		}
		// run it only once on sign in callback route
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (signInCallbackRoute && hasCodeInUrl(history.location)) {
			getUserManager().then((userManager) => {
				userManager.signinCallback().then(() => {
					history.push(getPreSignInURL() || `/${customerKey}/p/${portalKey}`);
					clearPreSignInURL();
				});
			});
		}
		// run it only once on sign in callback route
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const authCheck = useCallback(() => {
		const authUser = () => {
			dispatch(login(customerKeyLowercase, portalKeyLowercase));
		};

		if (!signInCallbackRoute) {
			if (!authenticationStatus) {
				getUserManager().then((userManager) => {
					setUserManager(userManager);
					getAuthUser().then((user) => {
						if (!user && !authToken && health === OnlineStatus.Available) {
							setAuthenticationStatus("loading");
							authUser();
						} else if (user) {
							setAuthenticationStatus("private");
						} else if (health === OnlineStatus.Unavailable) {
							if (authToken) {
								setAuthenticationStatus("public");
							} else {
								authUser();
							}
						}
					});
				});
			} else if (authToken) {
				setAuthenticationStatus("public");
			}
			if (!authToken && portalAuthenticationMode === PortalAuthenticationMode.Public) {
				authUser();
			}
		}
	}, [
		health,
		authenticationStatus,
		authToken,
		customerKeyLowercase,
		dispatch,
		portalKeyLowercase,
		portalAuthenticationMode,
		signInCallbackRoute,
	]);

	useEffect(() => {
		// Trigger auth check when window re-focuses (iOS 15.4 workaround for cross-domain auth)
		authCheck();
		window.addEventListener("focus", authCheck);
		return () => {
			window.removeEventListener("focus", authCheck);
		};
	}, [authCheck]);

	useGainsightLogin(authenticationStatus);

	const onSignIn = () => {
		history.push(getPreSignInURL() || `/${customerKey}/p/${portalKey}`);
		clearPreSignInURL();
	};

	const forceLogin = async () => {
		const userManger = await getUserManager();
		userManger.signinRedirect({ prompt: "login" });
	};

	return userManager ? (
		<AuthProvider autoSignIn={false} onSignIn={onSignIn} userManager={userManager}>
			<ForceLoginModal forceLogin={forceLogin} isForcingLogin={isForcingLogin} />

			{authenticationStatus === "private" || authenticationStatus === "public" ? (
				children
			) : (
				<Loading withHistory={false} />
			)}
		</AuthProvider>
	) : (
		<Loading withHistory={false} />
	);
};
