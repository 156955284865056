import { QueryClient as TSQueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

export const queryClient = new TSQueryClient();

export const QueryClient = ({ children }: { children: React.ReactNode }) => (
	<QueryClientProvider client={queryClient}>
		<ReactQueryDevtools initialIsOpen={false} />
		{children}
	</QueryClientProvider>
);
