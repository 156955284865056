import { useTranslation } from "react-i18next";

interface Props {
	isMandatory: boolean;
	isEmpty: boolean;
	children: React.ReactNode;
}

export const EmptyPicklistMessage = ({ isMandatory, isEmpty, children }: Props) => {
	const { t } = useTranslation();

	if (isEmpty) {
		if (isMandatory) {
			return <p>{t("display:labelNoPickListValuesMandatory")}</p>;
		}
		return <p>{t("display:labelNoPickListValues")}</p>;
	}
	return <>{children}</>;
};
