import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ActionPropertyType, ActionSortOrder } from "../../../../../models/action";
import { Menu, MenuItem } from "../../../../components/Menu";
import { loadActionSort, saveActionSort } from "../../../../utilities/SortSettingStorage";
import { ReactComponent as Check } from "../../../../assets/svg/check.svg";
import type { ActionSortPropertyInfo } from "../../../../../models/action";

interface Props {
	propertyInfos?: ActionSortPropertyInfo[];
	onSelect?: (propertyInfo: ActionSortPropertyInfo) => void;
}

const ItemOption = ({ caption }: { caption: string }): JSX.Element => <span>{caption}</span>;

export const ActionListSort = ({ propertyInfos, onSelect }: Props) => {
	const { t } = useTranslation();
	const [sortList, setSortList] = useState<ActionSortPropertyInfo[] | undefined>();
	const [selected, setSelected] = useState<ActionSortPropertyInfo | undefined>();

	const getDefaultSelectedProperty = (
		propertyInfos: ActionSortPropertyInfo[],
	): ActionSortPropertyInfo => {
		const reference = propertyInfos.find(
			(p) => p.propertyName === "CompletePercent" && p.order === ActionSortOrder.Asc,
		);

		return reference || propertyInfos[0];
	};

	useEffect(() => {
		if (propertyInfos && propertyInfos.length) {
			setSortList(propertyInfos);
			setSelected(propertyInfos[0]);
			if (selected) {
				setSelected(
					propertyInfos.find(
						(p) =>
							p.propertyName ===
								((selected && selected.propertyName) || "CompletePercent") &&
							p.order === ((selected && selected.order) || ActionSortOrder.Asc),
					),
				);
			} else {
				const savedSortSettings = loadActionSort();
				const propertyInfo =
					savedSortSettings &&
					savedSortSettings.propertyInfo &&
					propertyInfos.find(
						(p) =>
							p.propertyName === savedSortSettings.propertyInfo.propertyName &&
							p.order === savedSortSettings.propertyInfo.order,
					);
				if (propertyInfo) {
					setSelected(propertyInfo);
					onSelect?.(propertyInfo);
				} else {
					const reference = getDefaultSelectedProperty(propertyInfos);

					setSelected(reference);
					if (reference) {
						saveActionSort({ propertyInfo: reference });
						onSelect?.(reference);
					}
				}
			}
		}
	}, [propertyInfos, sortList, selected, onSelect]);

	const handleSelect = (propertyInfo: ActionSortPropertyInfo) => {
		onSelect?.(propertyInfo);
		setSelected(propertyInfo);
		saveActionSort({ propertyInfo });
	};

	if (!sortList || !selected) {
		return null;
	}

	const chooseCaption = (option: ActionSortPropertyInfo) => {
		if (option.type === ActionPropertyType.Date) {
			return t(`display:labelActionSort.date${option.order}`, {
				caption: option.caption,
			});
		} else if (option.type === ActionPropertyType.Priority) {
			return t(`display:labelActionSort.priority${option.order}`, {
				caption: option.caption,
			});
		} else if (option.type === ActionPropertyType.Percent) {
			return t(`display:labelActionSort.percent${option.order}`, {
				caption: option.caption,
			});
		}
		return t(`display:labelActionSort.alphabetical${option.order}`, {
			caption: option.caption,
		});
	};

	const selectedCaption = chooseCaption(selected);

	return (
		<div className="she-actions__sort">
			<span className="she-actions__sort__label">{t("display:labelActionSort.sort")}</span>
			<Menu
				accessibleButtonLabel={selectedCaption}
				trigger={selectedCaption}
				variant="select"
			>
				{sortList.map((f) => {
					return (
						<MenuItem
							bindIconToSelected
							icon={<Check />}
							key={f.propertyName + f.order}
							onClick={() => handleSelect(f)}
							selected={selected === f}
						>
							<ItemOption caption={chooseCaption(f)} />
						</MenuItem>
					);
				})}
			</Menu>
		</div>
	);
};
