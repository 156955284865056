import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RecordPropertyType, RecordSortOrder } from "../../../../../models/record";
import { ReactComponent as Check } from "../../../../assets/svg/check.svg";
import { Menu, MenuItem } from "../../../../components/Menu";
import {
	loadRecordSortForModule,
	saveRecordSortForModule,
} from "../../../../utilities/SortSettingStorage";
import type { RecordModule, RecordSortPropertyInfo } from "../../../../../models/record";

interface Props {
	module: RecordModule;
	propertyInfos?: RecordSortPropertyInfo[];
	onSelect?: (propertyInfo: RecordSortPropertyInfo) => void;
}

export const RecordListSort = ({ module, propertyInfos, onSelect }: Props) => {
	const [sortList, setSortList] = useState<RecordSortPropertyInfo[] | undefined>();
	const [selected, setSelected] = useState<RecordSortPropertyInfo | undefined>();
	const { t } = useTranslation();

	useEffect(() => {
		if (propertyInfos && propertyInfos.length) {
			setSortList(propertyInfos);
			if (selected) {
				setSelected(
					propertyInfos.find(
						(p) =>
							p.propertyName ===
								((selected && selected.propertyName) || "EntityReference") &&
							p.order === ((selected && selected.order) || RecordSortOrder.Asc),
					),
				);
			} else {
				const savedSortSettings = loadRecordSortForModule(module);
				const propertyInfo =
					savedSortSettings &&
					savedSortSettings.propertyInfo &&
					propertyInfos.find(
						(p) =>
							p.propertyName === savedSortSettings.propertyInfo.propertyName &&
							p.order === savedSortSettings.propertyInfo.order,
					);
				if (propertyInfo) {
					setSelected(propertyInfo);
					onSelect && onSelect(propertyInfo);
				} else {
					const reference = propertyInfos.find(
						(p) =>
							p.propertyName === "EntityReference" && p.order === RecordSortOrder.Asc,
					);
					setSelected(reference);
					if (reference) {
						saveRecordSortForModule({ module, propertyInfo: reference });
						onSelect && onSelect(reference);
					}
				}
			}
		}
	}, [propertyInfos, sortList, selected, module, onSelect]);

	const handleSelect = (propertyInfo: RecordSortPropertyInfo) => {
		onSelect && onSelect(propertyInfo);
		setSelected(propertyInfo);
		saveRecordSortForModule({ module, propertyInfo });
	};

	const sortOrderCaption = (propertyInfo: RecordSortPropertyInfo) => {
		switch (propertyInfo.type) {
			case RecordPropertyType.Date:
				return t(`display:labelRecordSort.date${propertyInfo.order}`, {
					caption: propertyInfo.caption,
				});

			case RecordPropertyType.Number:
				return t(`display:labelActionSort.priority${propertyInfo.order}`, {
					caption: propertyInfo.caption,
				});
			default:
				return t(`display:labelRecordSort.alphabetical${propertyInfo.order}`, {
					caption: propertyInfo.caption,
				});
		}
	};

	if (!sortList || !selected) {
		return null;
	}

	const selectedCaption = sortOrderCaption(selected);

	return (
		<div className="she-records__sort">
			<span className="she-records__sort__label">{t("display:labelRecordSort.sort")}</span>
			<Menu
				accessibleButtonLabel={selectedCaption}
				trigger={selectedCaption}
				variant="select"
			>
				{sortList.map((f) => (
					<MenuItem
						bindIconToSelected
						icon={<Check />}
						key={f.propertyName + f.order}
						onClick={() => handleSelect(f)}
						selected={selected === f}
					>
						{sortOrderCaption(f)}
					</MenuItem>
				))}
			</Menu>
		</div>
	);
};
