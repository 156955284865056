import { addBreadcrumb, captureException } from "@sentry/react";

export const logException = (message: unknown) => {
	if (process.env.NODE_ENV === "development") {
		// eslint-disable-next-line no-console -- This is a development only console log for debugging
		console.log(message);
	}
	if (typeof message === "object" && message !== null) {
		if ("error" in message) {
			if (
				"customData" in message &&
				typeof message.customData === "object" &&
				message.customData !== null
			) {
				captureException(message.error, {
					extra: message.customData as Record<string, unknown>,
				});
				return;
			}
			captureException(message.error);
			return;
		}
	}
	captureException(message);
};

export const logBreadcrumb = (message: string, data: object) => {
	if (process.env.NODE_ENV === "development") {
		// eslint-disable-next-line no-console -- This is a development only console log for debugging
		console.log(message, data);
	}
	addBreadcrumb({ message, data });
};
