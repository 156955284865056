import { useMemo } from "react";
import Axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
	extractCurrentPortalAuthenticationMode,
	isHiddenOnPortal,
} from "../../helpers/PortalHelpers";
import { useIsAuthenticated } from "../../helpers/UseIsAuthenticated";
import { DefaultActionsService } from "../../services/actions";
import { CancelablePromise } from "../../services/utilities/CancelablePromise";
import type { Actions } from "../../models/action";
import type { PortalAuthenticationMode } from "../../models/portal";
import type { State } from "../../state";

export const useGetActionsNumber = () => {
	const { portalKey } = useParams<{ portalKey: string }>();
	const authenticationMode = useSelector<State, PortalAuthenticationMode | null>((state) =>
		extractCurrentPortalAuthenticationMode(state),
	);

	const service = useMemo(
		() =>
			new DefaultActionsService({
				subdomain: "Action",
			}),
		[],
	);

	const enabled = useIsAuthenticated() && !isHiddenOnPortal(authenticationMode);

	const { data, refetch } = useQuery(
		["getActions", portalKey],
		() => {
			const cancelTokenSource = Axios.CancelToken.source();
			const getActions = new CancelablePromise<Actions>((resolve, reject) =>
				service
					.getActions(
						portalKey,
						undefined,
						undefined,
						0,
						undefined,
						undefined,
						undefined,
						undefined,
						cancelTokenSource,
					)
					.then((r) => resolve(r))
					.catch((e) => reject(e)),
			);
			getActions.cancel = () => {
				cancelTokenSource.cancel();
			};
			return getActions;
		},
		{ enabled },
	);

	return {
		actionsCount: data?.totalDataCount ?? 0,
		refetch,
	};
};
