import moment from "moment";
import type { Info } from "../../../../models/record/index";

const scopeOfServices = "ScopeOfServices";

export const mapRecordInfo = (
	responseRecord: any,
	responseFieldInfo: [],
	dateFormat: string,
): Info[] => {
	return responseFieldInfo.reduce<Info[]>((acc, fieldInfo: any) => {
		if (
			fieldInfo.Visible &&
			fieldInfo.PropertyName !== "OrgUnit" &&
			fieldInfo.PropertyName !== "EntityReference"
		) {
			if (
				responseRecord[fieldInfo.PropertyName] &&
				!(
					Array.isArray(responseRecord[fieldInfo.PropertyName]) &&
					responseRecord[fieldInfo.PropertyName].length === 0
				)
			) {
				acc.push({
					propertyName: fieldInfo.PropertyName,
					title: fieldInfo.Caption,
					detail: convertToString(
						responseRecord[fieldInfo.PropertyName],
						fieldInfo.PropertyName,
						dateFormat,
					),
				});
			}
		}
		return acc;
	}, []);
};

const convertToString = (value: any, propertyName: string, dateFormat: string): string => {
	if (propertyName === scopeOfServices) {
		return formatScopeOfServices(value);
	}

	if (moment(value, "YYYY-MM-DDTHH:mm:ss", true).isValid()) {
		return moment(value).format(resolveDateFormat(dateFormat));
	}

	if (typeof value !== "string") {
		return JSON.stringify(value);
	}

	return value;
};

const formatScopeOfServices = (value: []): string => {
	return value.reduce((acc: string, value: ScopeOfService) => {
		// TODO: We might need to think off a pattern for array.reduce that we can ignore, as this specific case is valid for the no-param-reassign rules
		// There are ways around it, but you can encounter the 0(n^2) rule, which can heavily impact performance
		if (acc !== "") {
			acc += ", ";
		}
		acc += value.Category;
		if (value.SubCategory) {
			acc += `: ${value.SubCategory}`;
		}

		return acc;
	}, "");
};

const resolveDateFormat = (portalCulture: string): string =>
	portalCulture === "Us" ? "MM/DD/YYYY" : "DD/MM/YYYY";

interface ScopeOfService {
	Category: string;
	SubCategory: string;
}
