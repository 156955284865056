import { NoteRecordPresentation } from "./NoteRecord.presentation";
import type { Note } from "../../../../../../models/questionnaire";

interface Props {
	note: Note;
	deleteNote: () => void;
	editNote: (note: Note) => void;
}

export const NoteRecordContainer = ({ deleteNote, editNote, note }: Props) => {
	return (
		<NoteRecordPresentation
			deleteNote={deleteNote}
			editNote={() => editNote(note)}
			note={note}
		/>
	);
};
