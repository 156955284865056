import { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import type { Transition, TargetAndTransition } from "framer-motion";

interface Props {
	measure?: boolean;
	onAnimationComplete?: () => void;
	as?: "li" | "div";
	children?: React.ReactNode;
}

const variants = {
	enter: {
		height: 0,
		opacity: 0,
	},
	center: {
		height: "auto",
		opacity: 1,
	},
};

const exitTransition: TargetAndTransition = {
	height: 0,
	opacity: 0,
	transition: {
		height: {
			duration: 0.15,
		},
		opacity: {
			duration: 0.1,
		},
	},
};

const transition: Transition = {
	height: {
		duration: 0.15,
	},
	opacity: {
		duration: 0.15,
		delay: 0.15,
	},
};

export const AnimationSlideFade = ({
	as = "div",
	children,
	measure,
	onAnimationComplete,
}: Props) => {
	const controls = useAnimation();
	const [mounted, setMounted] = useState(false);

	useEffect(() => {
		if (measure) {
			if (!mounted) {
				setMounted(true);
			} else {
				controls.start("center");
			}
		}
	}, [mounted, controls, measure]);

	const Tag = motion[as];

	return (
		<Tag
			animate={measure ? controls : "center"}
			className="clearfix"
			exit={exitTransition}
			initial="enter"
			onAnimationComplete={onAnimationComplete}
			transition={transition}
			variants={variants}
		>
			{children}
		</Tag>
	);
};
