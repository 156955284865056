import { useContext } from "react";
import { AnimationSlideFade } from "../../animation/AnimationSlideFade";
import { AnimationWrapper } from "../../animation/AnimationWrapper";
import { ToastContext } from "../context/ToastContext";
import "./Toast.styles.scss";

export const ToastContainer = () => {
	const toastContext = useContext(ToastContext);

	return (
		<div className="she-portal-toast-container">
			<div className="she-toast-list__wrapper">
				<ul aria-live="assertive" className="she-toast-list">
					<AnimationWrapper>
						{toastContext.toasts.map((toast) => (
							<AnimationSlideFade as="li" key={toast.uuid}>
								<div key={toast.uuid}>{toast.content}</div>
							</AnimationSlideFade>
						))}
					</AnimationWrapper>
				</ul>
			</div>
		</div>
	);
};
