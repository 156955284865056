import { sanitize } from "dompurify";
import { QuestionnaireStatus } from "../../../../models/portal";
import { BaseService } from "../../../baseService";
import { withSignInGet } from "../../../utilities/http";
import { DefaultLocalDataService } from "../../../localData";
import { isBrowserIE } from "../../../../helpers/ieHelper";
import { mapLanguageDecimalIdToStringId } from "../../../../translations/helpers/i18nHelpers";
import { logException } from "../../../log";
import type { Portal } from "../../../../models/portal";
import type { PortalService } from "../../interface";

export class DefaultPortalService extends BaseService implements PortalService {
	async getOneByKeyAndCustomerKey(key: string, customerKey: string): Promise<Portal> {
		const serviceUrl = `${await this.getEndpointUrl()}?key=${key}`;
		const themeUrl = `${await this.getEndpointUrl()}/themeColour`;
		const response = await withSignInGet<any>(serviceUrl, await this.getAuthHeaders());
		const portalResponse = response.parsedBody;

		let theme = "";

		try {
			const themeResponse = await withSignInGet<any>(themeUrl, await this.getAuthHeaders());

			if (themeResponse && themeResponse.status === 200 && themeResponse.data) {
				theme = themeResponse.data;
			}
		} catch (error) {
			/* empty */
		}

		const portal: Portal = {
			key: key.toLowerCase(),
			customerKey: customerKey.toLowerCase(),
			title: portalResponse.Portal.Title,
			notification:
				portalResponse.Portal.NotificationTitle ||
				portalResponse.Portal.NotificationDescription
					? {
							title: portalResponse.Portal.NotificationTitle,
							description: sanitize(portalResponse.Portal.NotificationDescription),
					  }
					: undefined,
			dateFormat: portalResponse.Portal.DateFormat,
			attachmentSizeLimit: portalResponse.Portal.AttachmentSizeLimit,
			logoGuid: portalResponse.Portal.LogoGuid,
			questionnaireStubs: portalResponse.Portal.QuestionnaireStubs.map((qs: any) => ({
				key: `${qs.Id}`,
				title: qs.Title,
				description: sanitize(qs.Description),
				module: qs.Module,
				language: mapLanguageDecimalIdToStringId(qs.LanguageId),
				imageGuid: qs.ImageGuid,
				makeAvailableOffline: false,
				questionnaireStatus: QuestionnaireStatus.Pending,
				authenticationRequired: qs.AuthorisationRequired,
			})),
			defaultOrgUnitId:
				portalResponse.Portal.OrgUnitId === 0 ? undefined : portalResponse.Portal.OrgUnitId,
			maskedOrgUnitId:
				portalResponse.Portal.MaskedOrgUnitId === 0
					? undefined
					: portalResponse.Portal.MaskedOrgUnitId,
			excludedFileTypes: portalResponse.Portal.ExcludedFileTypes,
			userLanguages: portalResponse.Portal.Languages.map((language: any) => ({
				idDecimal: language.Id,
				idString: mapLanguageDecimalIdToStringId(language.Id),
				code: language.Language,
				isDefault: language.IsDefault,
			})),
			questionnaireTemplateSyncRequired: false,
			initialSetupCompleted: false,
			installModules: portalResponse.InstallModules.map((module: any) => ({
				id: module.ModuleMasterId,
				key: module.ModuleMasterModuleKey,
				caption: module.ModuleCaption,
			})),
			installModulesSectionTitle: portalResponse.InstallModulesSectionTitle.map(
				(section: any) => ({
					title: section.Title,
					language: section.Language,
				}),
			),
			authenticationMode: portalResponse.Portal.AuthenticationMode,
			defaultPortalLanguage: portalResponse.Portal.DefaultPortalLanguage
				? mapLanguageDecimalIdToStringId(portalResponse.Portal.DefaultPortalLanguage)
				: undefined,
			showLearnTraining: portalResponse.Portal.ShowLearnTraining,
		};

		if (theme) {
			portal.theme = sanitize(theme);
		}

		return !isBrowserIE() ? this.UpdateStoredPortal(portal) : portal;
	}

	private UpdateStoredPortal = async (portalResponse: Portal): Promise<Portal> => {
		const localDataService = new DefaultLocalDataService();

		await localDataService
			.getPortalByKey(portalResponse.key, portalResponse.customerKey)
			.then((storedPortal) => {
				if (storedPortal) {
					if (storedPortal.questionnaireStubs) {
						const offlineQuestionnaires = storedPortal.questionnaireStubs
							.filter((s) => s.makeAvailableOffline)
							.map((s) => s.key);
						const newQuestionnaireList = portalResponse.questionnaireStubs.map(
							(s) => s.key,
						);
						portalResponse.questionnaireStubs.forEach((s) => {
							s.makeAvailableOffline = offlineQuestionnaires.includes(s.key);
							s.questionnaireStatus = s.makeAvailableOffline
								? QuestionnaireStatus.Downloaded
								: QuestionnaireStatus.Pending;
						});

						offlineQuestionnaires.forEach((q) => {
							if (!newQuestionnaireList.includes(q)) {
								localDataService.deleteTemplate(q);
							}
						});

						portalResponse.initialSetupCompleted = storedPortal.initialSetupCompleted;
					}
				}

				localDataService.savePortal(portalResponse);
			})
			.catch((e) => {
				logException(e);
			});

		return portalResponse;
	};
}
