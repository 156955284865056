import { t } from "i18next";
import type { Field } from "../../models/fields/Field";

export class MandatoryAdditionsValidator {
	field: Field;
	messages: string[];

	constructor(field: Field) {
		this.field = field;
		this.messages = [];
	}

	hasMandatoryActions(actionCount: number): boolean {
		this.messages = [];

		if (
			this.field.isIq &&
			this.field.actionsMandatory &&
			this.field.isNotApplicable !== true &&
			actionCount === 0
		) {
			this.messages.push(t("validation:mandatoryActionsMessage"));
			return false;
		}

		return true;
	}

	hasMandatoryAttachments(attachmentCount: number): boolean {
		this.messages = [];

		if (
			this.field.isIq &&
			this.field.attachmentsMandatory &&
			this.field.isNotApplicable !== true &&
			attachmentCount === 0
		) {
			this.messages.push(t("validation:mandatoryAttachmentsMessage"));
			return false;
		}

		return true;
	}
}
