import { useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { RadioButton } from "../input/RadioButton";
import { OptionalRegisterSelectInput } from "./components/OptionalRegisterSelectInput";
import { OptionalRegisterSelectManualEntry } from "./components/OptionalRegisterSelectManualEntry";
import type { OptionalSelectField } from "../../../models/fields/OptionalSelectField";
import "./OptionalRegisterSelect.styles.scss";

interface Props {
	field: OptionalSelectField;
	updateField: (fieldId: number, value: any, additionalParams?: Record<string, any>) => void;
	updateValidationStatus: (fieldId: number, value: string[]) => void;
}

export const OptionalRegisterSelect = ({ field, updateField, updateValidationStatus }: Props) => {
	const fieldId = field.id;
	const { t } = useTranslation();
	const selectedFromRegister = useRef<boolean | null>(
		(field.originalField as OptionalSelectField).selectedFromRegister,
	);

	const handleChange = useCallback(
		(selectFromRegister: boolean) => {
			if (
				(selectFromRegister && selectedFromRegister.current === true) ||
				(!selectFromRegister && selectedFromRegister.current === false)
			) {
				return;
			}

			selectedFromRegister.current = selectFromRegister;
			updateField(fieldId, undefined, { selectedFromRegister: selectFromRegister });
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[fieldId],
	);

	const radioGroupName = `optional-register-select-${fieldId}`;

	return (
		<div className="optional-register-select__container">
			<div className="she-components-radio-group-button optional-register-select__radio-option">
				<RadioButton
					id="optional-register-select-register--radio"
					name={radioGroupName}
					onClick={() => handleChange(true)}
					selected={selectedFromRegister.current === true}
				/>
				<span className="optional-register-select__radio-option-description">
					{t("display:optionalSelect.registerSelect", {
						propertyName: field.registerModuleName,
					})}
				</span>
			</div>
			{selectedFromRegister.current === true && (
				<div className="optional-register-select-option__content">
					<OptionalRegisterSelectInput
						field={field}
						updateField={updateField}
						updateValidationStatus={updateValidationStatus}
					/>
				</div>
			)}
			<div className="optional-register-select__radio-option">
				<RadioButton
					id="optional-register-select-manual--radio"
					name={radioGroupName}
					onClick={() => handleChange(false)}
					selected={selectedFromRegister.current === false}
				/>
				<span className="optional-register-select__radio-option-description">
					{t("display:optionalSelect.manualEntry")}
				</span>
			</div>
			{selectedFromRegister.current === false && (
				<OptionalRegisterSelectManualEntry field={field} />
			)}
		</div>
	);
};
