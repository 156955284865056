import { useState } from "react";
import { useTranslation } from "react-i18next";
import "./ErrorViewer.styles.scss";

interface Props {
	error: unknown;
}

export const ErrorViewer = ({ error }: Props) => {
	const { t } = useTranslation();
	const [displayError, setDisplayError] = useState(false);

	return (
		<div className="she-error-viewer">
			{!displayError && (
				<button
					className="she-btn she-btn-tertiary"
					onClick={() => {
						setDisplayError(true);
					}}
					type="button"
				>
					{t("display:labelDisplayError")}
				</button>
			)}
			{displayError && error && (
				<p>
					{Array.isArray(error)
						? error[0]
						: typeof error === "string"
						? error
						: JSON.stringify(error)}
				</p>
			)}
		</div>
	);
};
