import { useState, useEffect } from "react";
import type { History, Location } from "history";
import type { HistoryLocationState } from "../types/History";

// TODO: Aarron - this should not accept a ref as an arg, the hook should create the ref and return it
export const useHighlight = (
	initialHighlight: boolean | undefined,
	ref: React.RefObject<HTMLElement>,
) => {
	const [highlight, setHighlight] = useState(initialHighlight);
	useEffect(() => {
		if (highlight) {
			ref.current && ref.current.focus();
			setHighlight(false);
		}
	}, [highlight, ref]);
	return highlight;
};

export const useResetHighlight = (history: History, location: Location<HistoryLocationState>) => {
	useEffect(() => {
		if (location.state && location.state.highlightRecord) {
			history.replace(location.pathname, {
				...location.state,
				highlightRecord: null,
			});
		}
	});
};
