import { useTranslation } from "react-i18next";
import "./Spinner.styles.scss";

export const Spinner = () => {
	const { t } = useTranslation();

	return (
		<div className="she-spinner">
			<svg aria-hidden="true" height="80" viewBox="0 0 200 200" width="80">
				<title>{t("global:loading")}</title>
				<circle className="she-spinner-background" cx="100" cy="100" r="75" />
				<circle className="she-spinner-circle" cx="100" cy="100" pathLength="1000" r="75" />
			</svg>
		</div>
	);
};
