import { t } from "i18next";
import { FieldType } from "../../models/questionnaire";
import type { Entity } from "../../models/entity";
import type { EntitySelectField } from "../../models/fields/EntitySelectField";

export class EntitySelectFieldValidator {
	messages: string[] = [];

	constructor(public entityField: EntitySelectField) {}

	isFieldValid = (value?: Entity | "UseDefaultValue"): boolean => {
		if (value === "UseDefaultValue") {
			return true;
		}

		let result = true;

		if (this.entityField.type === FieldType.EntitySelectWithDefault && value === undefined) {
			this.messages.push(
				t("validation:entitySelectWithDefaultValidation.pleaseSelecValue", {
					propertyName: this.entityField.name,
				}),
			);
			result = false;
		}

		if (this.entityField.isMandatory && value === undefined) {
			this.messages.push(t("validation:mandatoryFieldMessage"));
			result = false;
		}

		return result;
	};
}
