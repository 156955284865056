import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { useLocation, useHistory } from "react-router-dom";
import { FormModal } from "../../../../components/modals";
import { switchMatchingLanguageCode } from "../../../../../helpers/LanguageCodeHelper";
import { PolicyAttachmentsItem } from "./PolicyAttachmentsItem.component";
import type { Policies, Attachment } from "../../../../../models/policy";
import "./PolicyAttachmentsList.styles.scss";

interface Props {
	policies?: Policies;
}

export const PolicyAttachmentsList = ({ policies }: Props) => {
	const userLanguage = localStorage.getItem("userLanguage");
	const { t } = useTranslation();
	const [loading, setLoading] = useState(true);
	const [attachmentList, setAttachmentList] = useState<Attachment[]>([]);
	const [attachmentToShow, setAttachmentToShow] = useState<Attachment | undefined>(undefined);
	const [moduleTitle, setModuleTitle] = useState("");
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [modalContent, setModalContent] = useState<string>("");
	const location = useLocation();
	const history = useHistory();

	useEffect(() => {
		if (policies && policies.attachments) {
			const sorted = policies.attachments.sort((a: any, b: any) => {
				a = new Date(a.dateCreated);
				b = new Date(b.dateCreated);
				return a > b ? -1 : a < b ? 1 : 0;
			});
			setAttachmentList(sorted);
			setLoading(false);
		}
	}, [policies]);

	useEffect(() => {
		setModuleTitle(t("display:labelPoliciesTitle"));

		const languageCode = userLanguage && switchMatchingLanguageCode(userLanguage);

		if (policies && policies.moduleTitle && policies.moduleTitle.length) {
			const captionedLanguage =
				policies.moduleTitle.find((x: any) => x.language === languageCode) || "";

			if (captionedLanguage && captionedLanguage.title) {
				setModuleTitle(captionedLanguage.title);
			}
		}
	}, [policies, t, userLanguage]);

	useEffect(() => {
		const idMatch = /id=([\w-]+)/.exec(location.search);

		if (idMatch?.[1]) {
			const idToFind = idMatch[1];
			const matchedAttachment = attachmentList.find((item) => item.identifier === idToFind);

			if (matchedAttachment?.attachmentType === "File") {
				setModalContent(
					`${t("display:downloadDocumentFile", {
						fileName: matchedAttachment.fileName,
					})}`,
				);

				setAttachmentToShow(matchedAttachment);
			} else if (matchedAttachment?.attachmentType === "Link") {
				setModalContent(t("display:leaveAndViewLink"));
				setAttachmentToShow(matchedAttachment);
			} else {
				setModalContent(t("error:documentNotFound"));
			}

			setIsModalOpen(true);
		}
	}, [location.search, attachmentList]);

	const toggleModal = () => {
		setIsModalOpen(false);

		history.push(location.pathname);
	};

	if (loading) {
		return (
			<div>
				<p>{t("display:labelLoading")}...</p>
			</div>
		);
	}

	return (
		<>
			<h2>{moduleTitle}</h2>
			<FormModal
				cancelText={t("global:close")}
				large
				onCancel={() => {
					toggleModal();
				}}
				onOk={() => {}}
				padded={false}
				primaryCancelBtn
				show={isModalOpen}
				showOkBtn={false}
				title={moduleTitle}
				withHistory={false}
			>
				<ul className="attachment-list">
					<li className="she-policy-list-modal-item">
						<li className="she-policy-list-modal-header">{modalContent}</li>
						{attachmentToShow && (
							<PolicyAttachmentsItem attachment={attachmentToShow} />
						)}
					</li>
				</ul>
			</FormModal>
			<div className="she-policy-list-container">
				<ul className="she-policy-list">
					<TransitionGroup component={null}>
						{attachmentList.map((item, i) => (
							<CSSTransition
								appear
								classNames="fade"
								component={null}
								key={i}
								timeout={500}
							>
								<PolicyAttachmentsItem attachment={item} />
							</CSSTransition>
						))}
					</TransitionGroup>
				</ul>
			</div>
		</>
	);
};
