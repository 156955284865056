import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useParams } from "react-router-dom";
import { usePortalPath } from "../../../../../../../helpers/UsePortalPath";
import type { ActionComment } from "../../../../../../../models/action";
import "./_action-comments-card.scss";

export const ActionCommentsCard = ({
	actionStatus,
	id,
	owner,
	content,
	createdDate,
	deletedBy,
	latestDate,
	status,
}: ActionComment) => {
	const portalPath = usePortalPath();
	const history = useHistory();
	const { action, actionId } = useParams<{ action: string; actionId: string }>();
	const { t } = useTranslation();
	const url = `${portalPath}actions/${action}/${actionId}/comments/${id}`;

	return (
		<Link
			className="action-comments-card"
			data-testid="action-comments-card"
			to={{ pathname: url, state: { previousPath: history.location.pathname } }}
		>
			<div className="action-comments-card__header">
				<p className="action-comments-card__title">{owner.name}</p>

				{status.key !== "Active" ? (
					<p
						className={classNames(
							`action-comments-card__tag`,
							{ "action-comments-card__tag--deleted": status.key === "Deleted" },
							{ "action-comments-card__tag--edited": status.key === "Edited" },
						)}
					>
						{status.label} - {latestDate.formattedNoTime}
					</p>
				) : null}
			</div>

			<p className="action-comments-card__date">{createdDate.formatted}</p>

			<p className="action-comments-card__status">{actionStatus.label}</p>

			<p className="action-comments-card__description">{content}</p>

			{status.key === "Deleted" && deletedBy ? (
				<p className="action-comments-card__deleted-by">
					{t("myTasks.labelCommentDeletedBy", { name: deletedBy })}
				</p>
			) : null}
		</Link>
	);
};

ActionCommentsCard.displayName = "ActionCommentsCard";
