import { secretbox, randomBytes } from "tweetnacl";
import { decodeUTF8, encodeUTF8 } from "tweetnacl-util";

const newNonce = () => randomBytes(secretbox.nonceLength);

export const encrypt = function (object: any, keyUint8Array: Uint8Array) {
	const nonce = newNonce();
	const messageUint8 = decodeUTF8(JSON.stringify(object, replacer));
	const box = secretbox(messageUint8, nonce, keyUint8Array);

	const fullMessage = new Uint8Array(nonce.length + box.length);
	fullMessage.set(nonce);
	fullMessage.set(box, nonce.length);

	return fullMessage.buffer;
};

export const decrypt = function (arrayBuffer: ArrayBuffer, keyUint8Array: Uint8Array) {
	const nonce = new Uint8Array(arrayBuffer, 0, secretbox.nonceLength);
	const message = new Uint8Array(arrayBuffer, secretbox.nonceLength);

	const decrypted = secretbox.open(message, nonce, keyUint8Array);

	if (!decrypted) {
		throw new Error("Could not decrypt message");
	}

	const base64DecryptedMessage = encodeUTF8(decrypted);
	return JSON.parse(base64DecryptedMessage, reviver);
};

export const encryptArrayBuffer = function (arrayBuffer: ArrayBuffer, keyUint8Array: Uint8Array) {
	const nonce = newNonce();
	const messageUint8 = new Uint8Array(arrayBuffer);
	const box = secretbox(messageUint8, nonce, keyUint8Array);

	const fullMessage = new Uint8Array(nonce.length + box.length);
	fullMessage.set(nonce);
	fullMessage.set(box, nonce.length);

	return fullMessage.buffer;
};

export const decryptArrayBuffer = function (arrayBuffer: ArrayBuffer, keyUint8Array: Uint8Array) {
	const nonce = new Uint8Array(arrayBuffer, 0, secretbox.nonceLength);
	const message = new Uint8Array(arrayBuffer, secretbox.nonceLength);

	const decrypted = secretbox.open(message, nonce, keyUint8Array);

	if (!decrypted) {
		throw new Error("Could not decrypt message");
	}

	return decrypted;
};

function replacer(key: string, value: any) {
	if (value instanceof Map) {
		return {
			dataType: "Map",
			value: Array.from(value.entries()),
		};
	}
	return value;
}

function reviver(key: string, value: any) {
	if (typeof value === "object" && value !== null) {
		if (value.dataType === "Map") {
			return new Map(value.value);
		}
	}
	return value;
}
