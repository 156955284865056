import { CommentBox } from "../../../../components/input/CommentBox";
import type { Field } from "../../../../../models/fields/Field";
import type { NotApplicableComments } from "../../../../../models/questionnaire";

interface Props {
	field: Field;
	isVisible: boolean;
	isMandatory: boolean;
	isCommentsDisabled: boolean;
	notApplicableComments?: NotApplicableComments;
}

export const QuestionnaireFieldCommentsPresentation = ({
	field,
	isCommentsDisabled,
	isMandatory,
	isVisible,
	notApplicableComments,
}: Props) => {
	return (
		<>
			{!isCommentsDisabled && (
				<CommentBox
					field={field}
					isMandatory={isMandatory}
					isVisible={isVisible}
					notApplicableComments={notApplicableComments}
				/>
			)}
		</>
	);
};
