import { t } from "i18next";
import { BaseService } from "../../../baseService";
import { RecordModule } from "../../../../models/record";
import { withSignInGet } from "../../../utilities/http";
import { mapRecordInfo } from "./mapper";
import { mapSortProperties } from "./sortPropertyMapper";
import type { Records, Record, RecordSortOrder } from "../../../../models/record";
import type { RecordsService } from "../../interface";
import type { CancelTokenSource } from "axios";

const ATTACHMENTS_ONLY_MODULES = [RecordModule.Policy];
export class DefaultRecordsService extends BaseService implements RecordsService {
	async getRecords(
		module: RecordModule,
		portalKey: string,
		sortField?: string,
		sortOrder?: RecordSortOrder,
		pageSize = 20,
		startIndex = 0,
		searchText = "",
		portalCulture = "Uk",
		language = "EnGB",
		cancelToken?: CancelTokenSource,
	) {
		let query = "/list?";
		query += `SearchText=${encodeURIComponent(searchText)}`;
		query += `&SearchDateFormat=${portalCulture}`;
		query += `&UserCurrentLanguage=${language}`;
		query += `&StartIndex=${startIndex}`;
		query += `&PageSize=${pageSize}`;
		query += `&PortalKey=${portalKey}`;
		query += sortField ? `&SortField=${sortField}` : "";
		query += sortOrder ? `&SortOrder=${sortOrder}` : "";

		const serviceUrl = `${await this.getEndpointUrl()}${query}`;
		const headers = await this.getAuthHeaders();
		const response = await withSignInGet<any>(serviceUrl, {
			...headers,
			cancelToken: cancelToken && cancelToken.token,
		});

		const hasSheAttachments =
			response.data.Data &&
			(response.data.Data as any[]).some(
				(d) => d.SheAttachments && d.SheAttachments.length > 0,
			);

		if (hasSheAttachments) {
			response.data.FieldInfo.push({
				PropertyName: "SheAttachments",
				Caption: t("display:labelAttachments"),
				Visible: true,
			});
		}

		const hasAttachments =
			response.data.Data &&
			(response.data.Data as any[]).some((d) => d.Attachments && d.Attachments.length > 0);

		if (hasAttachments) {
			response.data.FieldInfo.push({
				PropertyName: "Attachments",
				Caption: t("display:labelAttachments"),
				Visible: true,
			});
		}

		return this.mapRecords(response, module, portalCulture);
	}

	mapRecords(response: any, module: RecordModule, portalCulture: string): Records {
		return {
			module,
			totalDataCount: response.data.TotalDataCount,
			list: response.data.Data.map((responseRecord: any) =>
				this.mapRecord(responseRecord, module, response.data.FieldInfo, portalCulture),
			),
			sortProperties: mapSortProperties(response.data.FieldInfo),
		};
	}

	mapRecord(
		responseRecord: any,
		module: RecordModule,
		responseFieldInfo: [],
		portalCulture: string,
	): Record {
		return {
			heading: {
				title: responseRecord.OrgUnit,
				ref: responseRecord.EntityReference,
			},
			recordId: responseRecord.ModuleId,
			info: mapRecordInfo(responseRecord, responseFieldInfo, portalCulture),
			hasAttachments: !!(
				(responseRecord.SheAttachments && responseRecord.SheAttachments.length > 0) ||
				(responseRecord.Attachments && responseRecord.Attachments.length > 0)
			),
			hasReport: !ATTACHMENTS_ONLY_MODULES.includes(module),
		};
	}
}
