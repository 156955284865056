import { t } from "i18next";
import type { EnumSelectField } from "../../models/fields/EnumSelectField";

export class EnumSelectFieldValidator {
	enumSelectField: EnumSelectField;
	messages: string[];

	constructor(enumSelectField: EnumSelectField) {
		this.enumSelectField = enumSelectField;
		this.messages = [];
	}

	isFieldValid(value?: number): boolean {
		this.messages = [];
		return this.doesMandatoryHaveValue(value);
	}

	doesMandatoryHaveValue(value?: number): boolean {
		let result = true;

		if (this.enumSelectField.isMandatory) {
			if (value == null) {
				this.messages.push(t("validation:mandatoryFieldMessage"));
				result = false;
			}
		}

		return result;
	}
}
