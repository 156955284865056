import { forwardRef, useMemo } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { AnimationSlideFade } from "../../../../components/animation/AnimationSlideFade";
import { AnimationWrapper } from "../../../../components/animation/AnimationWrapper";
import { ConditionalWrapper } from "../../../../components/ConditionalWrapper";
import { FieldOrderComparer } from "../../../../../helpers/SortingHelper";
import { MarkSectionNA } from "../../../../components/input/MarkSectionNA";
import { QuestionnaireField } from "../QuestionnaireField";
import { QuestionnaireGroupPresentation } from "./QuestionnaireGroup.presentation";
import type { State } from "../../../../../state";
import type { Group } from "../../../../../models/questionnaire";
import type { Field } from "../../../../../models/fields/Field";

interface Props {
	groupId: string;
	guidanceIsPopup: boolean;
	guidance?: string;
	mediaGuidance?: string;
}

export const QuestionnaireGroupContainer = forwardRef<HTMLHeadingElement, Props>(
	({ groupId, guidanceIsPopup, guidance, mediaGuidance }: Props, ref) => {
		const group = useSelector<State, Group | undefined>(
			(state) => state.questionnaire.groups.find((group) => group.id === groupId),
			shallowEqual,
		);

		const fields = useSelector<State, Field[]>(
			(state) =>
				state.questionnaire.fields.filter(
					(field) => field.groupId === groupId && !field.isConfidential,
				),
			shallowEqual,
		);

		const sectionNAMarkable = useMemo(
			() => fields.some((field) => field.allowNotApplicable === true),
			[fields],
		);

		const visibleSortedFields = useMemo(() => {
			return fields
				.filter((field) => !field.hidden)
				.sort((a, b) => FieldOrderComparer(a, b))
				.map((field) => (
					<ConditionalWrapper
						condition={!!(field.parentType && field.parentType !== "Section")}
						key={field.guid}
						parent={AnimationSlideFade}
						parentProps={{ measure: true }}
					>
						<QuestionnaireField fieldGuid={field.guid} />
					</ConditionalWrapper>
				));
		}, [fields]);

		if (!group) {
			return null;
		}

		return (
			<>
				<QuestionnaireGroupPresentation
					guidance={guidance || ""}
					guidanceIsPopup={guidanceIsPopup}
					mediaGuidance={mediaGuidance || ""}
					name={group.name}
					ref={ref}
				/>

				{sectionNAMarkable && <MarkSectionNA groupId={group.id} />}

				<AnimationWrapper>{visibleSortedFields}</AnimationWrapper>
			</>
		);
	},
);

QuestionnaireGroupContainer.displayName = "QuestionnaireGroupContainer";
