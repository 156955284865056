import { useMemo } from "react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { DefaultActionsService } from "../../../../services/actions";
import { CancelablePromise } from "../../../../services/utilities/CancelablePromise";
import type { ActionFull } from "../../../../models/action";

export const useGetActionQuery = (actionId: string, enabled: boolean, language?: string) => {
	const service = useMemo(
		() =>
			new DefaultActionsService({
				subdomain: "Action",
			}),
		[],
	);

	const query = useQuery(
		["Action", actionId, language],
		() => {
			const cancelTokenSource = axios.CancelToken.source();
			const getAction = new CancelablePromise<ActionFull>((resolve, reject) =>
				service
					.getAction(parseInt(actionId), language, cancelTokenSource)
					.then((r) => resolve(r))
					.catch((e) => reject(e)),
			);
			getAction.cancel = () => {
				cancelTokenSource.cancel();
			};
			return getAction;
		},
		{ enabled },
	);

	return query;
};
