import { forwardRef } from "react";
import { Guidance } from "../../../../components/generic/Guidance";

interface Props {
	name: string;
	guidanceIsPopup: boolean;
	guidance: string;
	mediaGuidance: string;
}

export const QuestionnaireGroupPresentation = forwardRef<HTMLHeadingElement, Props>(
	({ guidance, guidanceIsPopup, mediaGuidance, name }: Props, ref) => {
		return (
			<>
				<h1 className="she-components-questionnaire-group-header" ref={ref} tabIndex={-1}>
					{name}
				</h1>
				<div className="she-components-questionnaire-group-guidance">
					<Guidance
						guidance={guidance}
						guidanceIsPopup={guidanceIsPopup}
						mediaGuidance={mediaGuidance}
						showGuidance
					/>
				</div>
			</>
		);
	},
);

QuestionnaireGroupPresentation.displayName = "QuestionnaireGroupPresentation";
