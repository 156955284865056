import { BaseService } from "../../../baseService";
import { withSignInGet, withSignInPostMultiPart } from "../../../utilities/http";
import { ErrorWithData } from "../../../../models/errors/ErrorWithData";
import { fileNameToPost } from "../../../../helpers/FileHelper";
import type { AttachmentService } from "../../interface";
import type { Attachment } from "../../../../models/attachments/Attachment";
import type { ActionAttachment } from "../../../../models/action";

export class DefaultAttachmentService extends BaseService implements AttachmentService {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	async getOneByGuidAndCustomerKey(guid: string, customerKey: string): Promise<Blob> {
		const serviceUrl = `${await this.getEndpointUrl()}?guid=${guid}`;
		return withSignInGet(serviceUrl, {
			...(await this.getAuthHeaders()),
			responseType: "blob",
		}).then((response) => response.data);
	}

	async postAttachment(
		attachment: Attachment,
		questionnaireId?: number,
		fieldId?: number,
	): Promise<{ isSaved: boolean; errors: string[] }> {
		const escapedfileName = encodeURIComponent(attachment.fileName);
		const serviceUrl = `${await this.getEndpointUrl()}?AttachmentId=${escapedfileName}&QuestionnaireId=${questionnaireId}&EqsQuestionId=${
			fieldId !== undefined ? fieldId : ""
		}`;

		// eslint-disable-next-line no-useless-catch
		try {
			const formData = new FormData();
			formData.append("file", attachment.file, escapedfileName);

			return withSignInPostMultiPart(
				serviceUrl,
				{ headers: await this.getAuthHeadersWithoutContentType() },
				formData,
			)
				.then(() => ({ isSaved: true, errors: [] }))
				.catch((response) => {
					throw new ErrorWithData("Couldn't upload attachment", response);
				});
		} catch (ex) {
			throw ex;
		}
	}

	async postActionAttachment(
		attachment: ActionAttachment,
	): Promise<{ isSaved: boolean; errors: string[] }> {
		if (!attachment.File) {
			throw new Error("File is not defined");
		}

		const escapedfileName = encodeURIComponent(
			fileNameToPost(attachment.Identifier, attachment.FileName),
		);
		const formData = new FormData();
		formData.append("file", attachment.File, escapedfileName);
		const serviceUrl = `${await this.getEndpointUrl()}?attachmentIdentifier=${escapedfileName}`;

		return withSignInPostMultiPart(
			serviceUrl,
			{ headers: await this.getAuthHeadersWithoutContentType() },
			formData,
		)
			.then(() => ({ isSaved: true, errors: [] }))
			.catch((response) => {
				throw new ErrorWithData("Couldn't upload attachment", response);
			});
	}

	async postActionAttachments(attachments: ActionAttachment[]) {
		const promises = attachments.map((attachment) => this.postActionAttachment(attachment));
		return Promise.all(promises);
	}
}
