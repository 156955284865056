import { v4 as uuid } from "uuid";
import { withSignInGet } from "../../../utilities/http";
import { BaseService } from "../../../baseService";
import type { Dictionary } from "../../../../models/dictionary/Dictionary";
import type { DictionaryItem } from "../../../../models/dictionary/DictionaryItem";
import type { DictionaryService } from "../../interface";
import type { ActionDictionaries } from "../../../../models/action";

export class DefaultDictionaryService extends BaseService implements DictionaryService {
	// Get the dictionary from Assure (Maxwell)
	public async getDictionaries(questionnaireId: number): Promise<{ dictionaries: Dictionary[] }> {
		const endpoint = await this.getEndpointUrl();
		const serviceUrl = `${endpoint}?questionnaireId=${questionnaireId}`;
		const response = await withSignInGet<any>(serviceUrl, await this.getAuthHeaders());
		const body = response.parsedBody;

		const dictionaries: Dictionary[] = [];

		body.DictionaryList.forEach((dict: any) => {
			const dictionariesItems: DictionaryItem[] = [];

			const dictionary: Dictionary = {
				id: uuid(),
				key: dict.DictionaryKey,
				readOnly: dict.ReadOnly,
				isOrgUnitDependent: dict.IsOrgUnitDependent,
				type: dict.Type,
				moduleMasterIds: dict.ModuleMasterIds,
				dictionaryItems: dictionariesItems,
			};

			dict.DictionaryItems.forEach((dictItem: any) => {
				const dictionaryItem: DictionaryItem = {
					id: dictItem.Id,
					text: dictItem.Text,
					isDefault: dictItem.IsDefault,
					priority: dictItem.Priority,
					orderIndex: dictItem.OrderIndex,
					orgUnitIds: dictItem.OrgUnitIds,
					parentDictionaryItemId: dictItem.ParentDictionaryItemId,
				};

				dictionariesItems.push(dictionaryItem);
			});

			dictionaries.push(dictionary);
		});

		return {
			dictionaries,
		};
	}

	async getActionDictionaries(language = "EnGB"): Promise<ActionDictionaries> {
		const languages = language;
		let query = "/ActionDictionaries?";
		query += `currentLanguage=${languages}`;

		const serviceUrl = `${await this.getEndpointUrl()}${query}`;
		const headers = await this.getAuthHeaders();
		const response = await withSignInGet<ActionDictionariesDTO>(serviceUrl, {
			...headers,
		});

		const dictionaryList = response.parsedBody?.DictionaryList;

		if (!dictionaryList) {
			return { priorityDictionary: [], categoryDictionary: [], subCategoryDictionary: [] };
		}

		const priorityDictionary = dictionaryList
			.find((dictionary) => dictionary.DictionaryKey === "Priority")
			?.DictionaryItems.map((dictionaryItem) => ({
				key: dictionaryItem.Id,
				value: dictionaryItem.Text,
				orgUnitIds: dictionaryItem.OrgUnitIds,
			}));
		const categoryDictionary = dictionaryList
			.find((dictionary) => dictionary.DictionaryKey === "ActionCategory")
			?.DictionaryItems.map((dictionaryItem) => ({
				key: dictionaryItem.Id,
				value: dictionaryItem.Text,
				orgUnitIds: dictionaryItem.OrgUnitIds,
			}));
		const subCategoryDictionary = dictionaryList
			.find((dictionary) => dictionary.DictionaryKey === "ActionSubCategory")
			?.DictionaryItems.map((dictionaryItem) => ({
				key: dictionaryItem.Id,
				value: dictionaryItem.Text,
				orgUnitIds: dictionaryItem.OrgUnitIds,
				parentId: dictionaryItem.ParentDictionaryItemId,
			}));

		return {
			priorityDictionary: priorityDictionary ?? [],
			categoryDictionary: categoryDictionary ?? [],
			subCategoryDictionary: subCategoryDictionary ?? [],
		};
	}
}

type ActionDictionaryItemDTO = {
	Id: number;
	ParentDictionaryItemId?: number | null;
	Text: string;
	OrgUnitIds: [number];
};

type ActionDictionariesDTO = {
	DictionaryList?: [
		{
			DictionaryKey: "Priority" | "ActionCategory" | "ActionSubCategory";
			DictionaryItems: [ActionDictionaryItemDTO];
		},
	];
};
