import { CheckboxPresentation } from "./Checkbox.presentation";

interface Props {
	id: string;
	name: string;
	value: number;
	checked: boolean;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const CheckboxContainer = ({ checked, id, name, onChange, value }: Props) => {
	return (
		<CheckboxPresentation
			checked={checked}
			id={id}
			name={name}
			onChange={onChange}
			value={value}
		/>
	);
};
