import { t } from "i18next";
import type { EmailField } from "../../models/fields/EmailField";

export class EmailFieldValidator {
	emailValidationRegex = new RegExp(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.[a-zA-Z]{1,}$/);
	emailField: EmailField;
	messages: string[];

	constructor(emailField: EmailField) {
		this.emailField = emailField;
		this.messages = [];
	}

	isFieldValid = (value?: string): boolean => {
		this.messages = [];
		let result = true;

		if (typeof value === "string" && typeof this.emailField.minLength === "number") {
			if (
				value.length < this.emailField.minLength &&
				!(value.length === 0 && !this.emailField.isMandatory)
			) {
				this.messages.push(
					t("validation:minimumLengthMessage", {
						minLength: this.emailField.minLength,
					}),
				);
				result = false;
			}
		}

		if (typeof value === "string" && typeof this.emailField.maxLength === "number") {
			if (value.length > this.emailField.maxLength) {
				this.messages.push(
					t("validation:maxLengthMessage", {
						maxLength: this.emailField.maxLength,
					}),
				);
				result = false;
			}
		}

		if (
			typeof value === "string" &&
			value.length > 0 &&
			!this.emailField.additionalEmails &&
			!this.emailValidationRegex.exec(value)
		) {
			this.messages.push(t("validation:invalidEmailAddress"));
			result = false;
		}

		if (typeof value === "string" && value.length > 0 && this.emailField.additionalEmails) {
			if (
				this.emailField.additionalEmailsRegx !== undefined &&
				this.emailField.additionalEmailsRegx !== null
			) {
				this.emailValidationRegex = new RegExp(this.emailField.additionalEmailsRegx);
			}

			const emails = value.split(";");
			if (emails.some((email) => !this.emailValidationRegex.exec(email))) {
				this.messages.push(t("validation:invalid", { invalidText: this.emailField.name }));
				result = false;
			}
		}

		if ((typeof value !== "string" || value.length === 0) && this.emailField.isMandatory) {
			this.messages.push(t("validation:mandatoryFieldMessage"));
			result = false;
		}

		return result;
	};
}
