import { createRef, useEffect, useMemo, useState } from "react";
import { Marker, Map, TileLayer, ZoomControl } from "react-leaflet";
import { icon as leafletIcon } from "leaflet";
import { GeoMiniMap } from "../GeoMiniMap";
import { useMapCopyrightInfo } from "../../utilities/UseMapCopyrightInfo";
import noInternetImg from "../../assets/png/no-internet-for-map.jpg";
import markerIcon from "../../assets/png/marker-icon.png";
import markerIcon2x from "../../assets/png/marker-icon-2x.png";
import markerShadow from "../../assets/png/marker-shadow.png";

export interface Position {
	lat?: number;
	long?: number;
}
export interface Props {
	lat: number;
	long: number;
	updatePositionCallback?: (position: Position) => void;
	zoom: number;
	setZoom?: (value: number) => void;
	apiUrl: string;
	copyrightUrl: string;
	isReadonly?: boolean;
	miniMapApiUrl?: string;
	miniMapLabel?: string;
	toggleMapModeCallback?: () => void;
}

export const GeoPositionMap = ({
	lat,
	long,
	updatePositionCallback,
	zoom,
	setZoom,
	apiUrl,
	copyrightUrl,
	isReadonly,
	miniMapApiUrl,
	miniMapLabel,
	toggleMapModeCallback,
}: Props) => {
	const refMarker = createRef<Marker>();
	const refMap = createRef<Map>();

	const here = {
		url: `${apiUrl}`,
	};

	const getCopyrightInfo = useMapCopyrightInfo(copyrightUrl);
	const [copyrightInfo, setCopyrightInfo] = useState(getCopyrightInfo(refMap));

	useEffect(() => {
		setCopyrightInfo(getCopyrightInfo(refMap));
	}, [getCopyrightInfo, refMap]);

	const icon = useMemo(
		() =>
			leafletIcon({
				iconUrl: markerIcon,
				shadowUrl: markerShadow,
				iconRetinaUrl: markerIcon2x,
				iconSize: [25, 41],
				iconAnchor: [12, 41],
				shadowSize: [41, 41],
				shadowAnchor: [12, 41],
			}),
		[],
	);

	return (
		<Map
			boxZoom={!isReadonly}
			center={[lat, long]}
			doubleClickZoom={!isReadonly}
			dragging={!isReadonly}
			keyboard={!isReadonly}
			minZoom={2}
			onzoomend={() => {
				zoomCallback(refMap, setZoom);
			}}
			ref={refMap}
			scrollWheelZoom={!isReadonly}
			tap={!isReadonly}
			touchZoom={!isReadonly}
			zoom={zoom}
			zoomControl={false}
		>
			<TileLayer
				attribution={copyrightInfo}
				bounds={[
					[-90, -180],
					[90, 180],
				]}
				errorTileUrl={noInternetImg}
				noWrap
				onLoad={() => {
					setCopyrightInfo(getCopyrightInfo(refMap));
				}}
				url={here.url}
			/>
			<Marker
				autoPan={!isReadonly}
				draggable={!isReadonly}
				icon={icon}
				keyboard={!isReadonly}
				ondragend={() => updatePosition(refMarker, updatePositionCallback)}
				position={[lat, long]}
				ref={refMarker}
			/>
			{!isReadonly ? <ZoomControl position="bottomright" /> : null}
			{!isReadonly && miniMapApiUrl ? (
				<GeoMiniMap
					label={miniMapLabel}
					lat={lat}
					long={long}
					onClick={toggleMapModeCallback}
					url={miniMapApiUrl}
				/>
			) : null}
		</Map>
	);
};

const zoomCallback = (refMap: React.RefObject<Map>, setZoom?: (value: number) => void): void => {
	const map = refMap.current;
	map && setZoom && setZoom(map.leafletElement.getZoom());
};

export const updatePosition = (
	refMarker: React.RefObject<Marker>,
	updatePosition?: (position: Position) => void,
) => {
	const marker = refMarker.current;

	if (marker !== null) {
		const newPosition = marker.leafletElement.getLatLng().wrap();
		updatePosition?.({ lat: newPosition.lat, long: newPosition.lng });
	}
};
