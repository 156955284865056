import { useMemo, useRef, useState } from "react";
import classnames from "classnames";
import Media from "react-media";
import { useTranslation } from "react-i18next";
import { Breadcrumb } from "../../generic/Breadcrumb";
import { FormModal } from "../../modals";
import { List } from "../../generic/List";
import { ReactComponent as NavigateIcon } from "../../../assets/svg/navigate.svg";
import { SearchBox } from "../SearchBox";
import { TouchInputBlurrer } from "../../generic/TouchInputBlurrer";
import { Tree } from "../../generic/Tree";
import { ReactComponent as DropdownChevron } from "../../../assets/svg/arrow-down-1.svg";
import { Button } from "../Button";
import styles from "./BasicOrgUnitSelector.module.scss";
import { buildBreadcrumb } from "./buildBreadcrumb";
import type { OrgUnit } from "../../../../models/orgUnit";
import "./BasicOrgUnitSelector.styles.scss";
import { TreeRadioButton } from "../../generic/Tree/components/TreeRadioButton/TreeRadioButton";

interface Props {
	caption: string;
	isValid?: boolean;
	onChange: (id?: number | "") => void;
	orgUnits: OrgUnit[];
	value?: number;
	onBlur?: () => void;
	fieldGuid: string;
}

export const BasicOrgUnitSelector = ({
	onChange,
	orgUnits,
	value,
	isValid,
	caption,
	onBlur,
}: Props) => {
	const { t } = useTranslation();

	const [currentId, setCurrentId] = useState<number | undefined>(value);
	const [checkedId, setCheckedId] = useState<number | undefined>(value);

	const [isShown, setIsShown] = useState(false);

	const breadcrumbData = useMemo(() => {
		const data: OrgUnit[] = buildBreadcrumb(
			checkedId || -1,
			orgUnits,
			orgUnits.filter((ou) => ou.parentId === checkedId).length > 0,
		);

		if (!data.length) {
			const top = orgUnits.find((ou) => ou.parentId === undefined);
			if (top) {
				data.push(top);
			}
		}

		return data;
	}, [checkedId, orgUnits]);

	const fullBreadcrumbData = useMemo(
		() => buildBreadcrumb(currentId || -1, orgUnits, false),
		[currentId, orgUnits],
	);

	const checkedOrgUnit = useMemo(
		() => orgUnits.find((ou) => ou.id === checkedId),
		[orgUnits, checkedId],
	);

	const currentOrgUnit = useMemo(
		() => orgUnits.find((ou) => ou.id === currentId),
		[orgUnits, currentId],
	);

	const [searchTerm, setSearchTerm] = useState("");
	const [isSearchMode, setIsSearchMode] = useState(false);

	const onModalSelect = () => {
		setIsSearchMode(false);
		setSearchTerm("");
		setIsShown(false);
		setCurrentId(checkedId);
		onChange(checkedId);
		onBlur?.();
	};

	const onModalCancel = () => {
		setIsSearchMode(false);
		setSearchTerm("");
		setIsShown(false);
		setCheckedId(value);
	};

	const onClear = () => {
		setCurrentId(undefined);
		setCheckedId(undefined);
		onChange("");
		onBlur?.();
	};

	const searchResults = useMemo(() => {
		if (!isSearchMode) {
			return [];
		}

		return orgUnits.filter((ou) => ou.name.toLowerCase().includes(searchTerm.toLowerCase()));
	}, [orgUnits, searchTerm, isSearchMode]);

	const searchBoxRef = useRef<HTMLInputElement>(null);

	const boxClasses = classnames(
		"she-org-unit-select__box",
		!isValid && "she-org-unit-select__box--invalid",
	);

	return (
		<>
			<div className="she-org-unit-select">
				<div
					className={boxClasses}
					onClick={() => setIsShown(true)}
					onKeyDown={(event) => {
						if (event.key === "Enter" || event.key === " ") {
							setIsShown(true);
						}
					}}
					role="button"
					tabIndex={0}
				>
					<div className="screen-reader-only">
						{t("display:labelSelectOrgUnit", { orgCaption: caption })}
					</div>
					{currentOrgUnit && (
						<div>
							<Breadcrumb data={fullBreadcrumbData} readOnly separator=">" />
							<p>{currentOrgUnit.name}</p>
						</div>
					)}
					<div className="she-components-control-icon she-org-unit-select__box__icon">
						<DropdownChevron />
					</div>
				</div>
			</div>
			<Button onClick={onClear}>{t("global:clear")}</Button>
			<FormModal
				large
				onCancel={onModalCancel}
				onOk={onModalSelect}
				padded={false}
				show={isShown}
				title={t("display:labelSelectOrgUnit", { orgCaption: caption })}
			>
				<div className="she-org-unit-select__searchbox">
					<SearchBox
						onSearchBegan={() => setIsSearchMode(true)}
						onSearchEnd={() => setIsSearchMode(false)}
						onSearchTermChange={setSearchTerm}
						reference={searchBoxRef}
						searchTerm={searchTerm}
					/>
				</div>

				<TouchInputBlurrer refToBlur={searchBoxRef}>
					{!isSearchMode ? (
						<Media query={`(min-width: ${styles.breakpointS})`}>
							{(matches) => (
								<>
									{!matches && (
										<div className="she-org-unit-select__breadcrumbs">
											<Breadcrumb
												data={breadcrumbData}
												onSelect={setCheckedId}
											/>
										</div>
									)}
									<Tree
										data={orgUnits}
										mode={!matches ? "menu" : "tree"}
										name="orgUnitTree"
										onSelect={setCheckedId}
										selectedId={checkedOrgUnit ? checkedOrgUnit.id : undefined}
									/>
								</>
							)}
						</Media>
					) : (
						<>
							<h4 className="she-org-unit-select__search-results__guidance">
								{t("display:labelSearchResults")}
							</h4>
							<List extraClassName="she-org-unit-select__search-results">
								{searchResults.length > 0 ? (
									searchResults
										.sort((a, b) =>
											a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1,
										)
										.map((ou) => (
											<div
												className="she-org-unit-select__search-results__item"
												key={ou.id}
											>
												<TreeRadioButton
													name="orgUnitTree"
													onClick={() => setCheckedId(ou.id)}
													onKeyDown={(event) => {
														if (
															event.key === "Enter" ||
															event.key === " "
														) {
															setCheckedId(ou.id);
														}
													}}
													selected={checkedOrgUnit?.id === ou.id}
												>
													<Breadcrumb
														data={buildBreadcrumb(
															ou.id,
															orgUnits,
															false,
														)}
														readOnly
														separator=">"
													/>
													{ou.name}
												</TreeRadioButton>
												<button
													className="she-btn she-btn-tertiary she-btn-orgunit-navigate"
													onClick={() => {
														setCheckedId(ou.id);
														setIsSearchMode(false);
														setSearchTerm("");
													}}
													type="button"
												>
													<NavigateIcon />
													<span>{t("global:go")}</span>
												</button>
											</div>
										))
								) : (
									<div className="she-org-unit-select__search-results__empty">
										<h1>{t("display:labelNoSearchResults")}</h1>
									</div>
								)}
							</List>
						</>
					)}
				</TouchInputBlurrer>
			</FormModal>
		</>
	);
};
