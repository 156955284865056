import { useCallback, useMemo, useRef, useState, forwardRef } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
	combineDateString,
	getLeadingZeroValue,
	isKeyDownValid,
	isPasteValid,
	setElementsFocus,
	splitDateToObject,
} from "../../../../helpers/DateTimeInputHelper";
import type { RefObject } from "react";
import type { DateFormat } from "../../../../helpers/DateTimeInputHelper";
import "./BasicDateInput.styles.scss";

interface Props {
	name: string;
	required?: boolean;
	value?: string;
	onChange?: (value?: string) => void;
	onBlur?: () => void;
	dateFormat?: DateFormat;
	fieldId: string;
}

export const BasicDateInput = forwardRef<HTMLInputElement, Props>(
	({ name, required, value, onChange, onBlur, dateFormat = "DD/MM/YYYY", fieldId }, dayRef) => {
		const { t } = useTranslation();

		const [date, setDate] = useState<
			| {
					day: string;
					month: string;
					year: string;
			  }
			| undefined
		>();

		const stringDateObject = useMemo(
			() => splitDateToObject(String(value), dateFormat),
			[value, dateFormat],
		);

		const monthRef = useRef<HTMLInputElement>(null);
		const yearRef = useRef<HTMLInputElement>(null);

		const dayName = `${name}_day`;
		const monthName = `${name}_month`;
		const yearName = `${name}_year`;

		const onToday = useCallback(() => {
			const today = moment().format(dateFormat);
			const newDate = splitDateToObject(today.toString(), dateFormat);
			setDate(newDate);
			if (typeof onChange === "function") {
				onChange(combineDateString(newDate, dateFormat));
			}
		}, [onChange, dateFormat]);

		const setLeadingZero = useCallback(
			(valueToUse: string, updateDayValue: boolean) => {
				if (valueToUse.length === 1) {
					valueToUse = getLeadingZeroValue(valueToUse);
					const newDate = {
						...(date || stringDateObject),
						[updateDayValue ? "day" : "month"]: valueToUse,
					};
					setDate(newDate);
					if (typeof onChange === "function") {
						onChange(combineDateString(newDate, dateFormat));
					}
				}
			},
			[onChange, date, dateFormat, stringDateObject],
		);

		const dayInput = (
			<label className="date-input__text-input">
				<span className="date-input__label" id={`${fieldId}-label-day`}>
					{t("display:labelDay")}
				</span>

				<input
					aria-labelledby={`${fieldId}-label ${fieldId}-description ${fieldId}-label-day`}
					aria-required={required}
					className="she-components-text-input"
					inputMode="numeric"
					maxLength={2}
					name={dayName}
					onBlur={(e) => {
						setLeadingZero(e.target.value, true);
						if (typeof onBlur === "function") {
							onBlur();
						}
					}}
					onChange={(e) => {
						const newDate = { ...(date || stringDateObject), day: e.target.value };
						setDate(newDate);
						if (typeof onChange === "function") {
							onChange(combineDateString(newDate, dateFormat));
						}
						setElementsFocus(e, dateFormat === "MM/DD/YYYY" ? yearRef : monthRef);
					}}
					onKeyDown={isKeyDownValid}
					onPaste={isPasteValid}
					placeholder={t("display:placeHolderDay")}
					ref={dayRef}
					required={required}
					type="text"
					value={(date || stringDateObject).day}
				/>
			</label>
		);

		const monthInput = (
			<label className="date-input__text-input">
				<span className="date-input__label" id={`${fieldId}-label-month`}>
					{t("display:labelMonth")}
				</span>

				<input
					aria-labelledby={`${fieldId}-label ${fieldId}-description ${fieldId}-label-month`}
					aria-required={required}
					className="she-components-text-input"
					inputMode="numeric"
					maxLength={2}
					name={monthName}
					onBlur={(e) => {
						setLeadingZero(e.target.value, false);
						if (typeof onBlur === "function") {
							onBlur();
						}
					}}
					onChange={(e) => {
						const newDate = { ...(date || stringDateObject), month: e.target.value };
						setDate(newDate);
						if (typeof onChange === "function") {
							onChange(combineDateString(newDate, dateFormat));
						}
						setElementsFocus(
							e,
							dateFormat === "MM/DD/YYYY"
								? (dayRef as RefObject<HTMLInputElement>)
								: yearRef,
						);
					}}
					onKeyDown={isKeyDownValid}
					onPaste={isPasteValid}
					placeholder={t("display:placeHolderMonth")}
					ref={monthRef}
					required={required}
					type="text"
					value={(date || stringDateObject).month}
				/>
			</label>
		);

		const yearInput = (
			<label className="date-input__text-input date-input__text-input--long">
				<span className="date-input__label" id={`${fieldId}-label-year`}>
					{t("display:labelYear")}
				</span>

				<input
					aria-labelledby={`${fieldId}-label ${fieldId}-description ${fieldId}-label-year`}
					aria-required={required}
					className="she-components-text-input"
					inputMode="numeric"
					maxLength={4}
					name={yearName}
					onBlur={onBlur}
					onChange={(e) => {
						const newDate = { ...(date || stringDateObject), year: e.target.value };
						setDate(newDate);
						if (typeof onChange === "function") {
							onChange(combineDateString(newDate, dateFormat));
						}
					}}
					onKeyDown={isKeyDownValid}
					onPaste={isPasteValid}
					placeholder={t("display:placeHolderYear")}
					ref={yearRef}
					required={required}
					type="text"
					value={(date || stringDateObject).year}
				/>
			</label>
		);

		return (
			<div className="date-input">
				{dateFormat === "MM/DD/YYYY" ? (
					<>
						{monthInput}
						{dayInput}
						{yearInput}
					</>
				) : (
					<>
						{dayInput}
						{monthInput}
						{yearInput}
					</>
				)}

				<div className="date-input__button-wrapper">
					<button className="she-btn she-btn-tertiary" onClick={onToday} type="button">
						{t("display:buttonToday")}
					</button>
				</div>
			</div>
		);
	},
);

BasicDateInput.displayName = "BasicDateInput";
