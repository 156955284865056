import React, { useMemo } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { getDateFormat } from "../../../../../helpers/DateTimeInputHelper";
import { usePortalPath } from "../../../../../helpers/UsePortalPath";
import { ReactComponent as Calendar } from "../../../../assets/svg/calendar.svg";
import { ReactComponent as Check } from "../../../../assets/svg/check.svg";
import { ReactComponent as Spots } from "../../../../assets/svg/navigation-menu-vertical.svg";
import { ReactComponent as PencilEdit } from "../../../../assets/svg/streamline-icon-pencil-write.svg";
import { Chip } from "../../../../components/generic/Chip";
import { ProgressBar } from "../../../../components/generic/ProgressBar";
import { Menu, MenuItem } from "../../../../components/Menu";
import { useActionLabel } from "../../hooks/useActionLabel";
import type { Action } from "../../../../../models/action";
import "./ActionCard.styles.scss";

interface Props {
	action: Action;
	openActionDetail: (action: Action) => void;
}

export const ActionCard = ({ action, openActionDetail }: Props) => {
	const { t } = useTranslation();
	const formattedDate = useMemo(
		() => moment(action.DueDate).format(getDateFormat()),
		[action.DueDate],
	);
	const portalPath = usePortalPath();
	const history = useHistory();

	const labelDueDate = useActionLabel("DueDate", t, action.Info);
	const labelOrgUnitName = useActionLabel("OrgUnitName", t, action.Info);
	const labelModuleDescrip = useActionLabel("ModuleDescrip", t, action.Info);

	const onKeyPress = (key: string, action: Action) => {
		if (key === "Enter" || key === " ") {
			openActionDetail(action);
		}
	};

	const menuItemClickHandler = (type: string) => {
		history.push(`${portalPath}actions/${type}/${action.ActionId}`);
	};

	const menuItems = [
		{ icon: <Check />, label: t("display:myTasks.menuMarkComplete"), type: "complete" },
		{ icon: <PencilEdit />, label: t("display:myTasks.menuEditTask"), type: "edit" },
	];

	return (
		<li className="she-action-card">
			<div
				className="she-action-card__clickable"
				onClick={() => openActionDetail(action)}
				onKeyPress={(event) => onKeyPress(event.key, action)}
				tabIndex={0}
			>
				<div className="she-action-card">
					<header className="she-action-card__header">
						<div className="she-action-card__header-chips">
							<Chip backgroundColor={action.PriorityColourCode}>
								{action.Priority}
							</Chip>

							{action.IsOverdue && (
								<Chip backgroundColor={action.DueDateColourCode}>
									{action.DueDateStatus}
								</Chip>
							)}
						</div>

						{action.RecordReference && (
							<div className="she-action-card__header-ref">
								<p>
									<span>{t("display:labelRecordCardRef")}</span>{" "}
									{action.RecordReference}
								</p>
							</div>
						)}
					</header>

					<div className="she-action-card__body">
						<div className="she-action-card__body-description">
							<p>{action.ActionHeader}</p>
						</div>

						<div className="she-action-card__body-menu">
							<Menu accessibleButtonLabel="Open task dropdown" icon={<Spots />}>
								{menuItems.map(({ icon, label, type }) => (
									<MenuItem
										icon={icon}
										key={type}
										onClick={menuItemClickHandler.bind(null, type)}
									>
										{label}
									</MenuItem>
								))}
							</Menu>
						</div>
					</div>

					<div className="she-action-card__date">
						<div className="she-action-card__date-calendar">
							<Calendar />
						</div>

						<p>
							{labelDueDate} {formattedDate}
						</p>
					</div>

					<div className="she-action-card__footer">
						<div className="she-action-card__footer-owner">
							{action.ModuleDescrip && (
								<p>
									<span>{labelModuleDescrip}</span>

									{action.ModuleDescrip}
								</p>
							)}

							<p>
								<span>{labelOrgUnitName}</span>

								{action.OrgUnitName}
							</p>
						</div>

						<div className="she-action-card__footer-progress">
							<ProgressBar>{action.CompletePercent}</ProgressBar>
						</div>
					</div>
				</div>
			</div>
		</li>
	);
};
