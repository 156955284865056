import classnames from "classnames";
import type { RefObject } from "react";
import "./Chip.styles.scss";

const TEST_ID = "chip";

interface Props {
	ref?: RefObject<HTMLDivElement>;
	className?: string;
	backgroundColor?: string;
	variant?: string;
	children: React.ReactNode;
}

export const Chip = ({ backgroundColor, variant, children, className, ref, ...rest }: Props) => {
	const classes = classnames(
		"she-chip",
		{ "she-chip-primary": variant === "primary" },
		{ "she-chip-secondary": variant === "secondary" },
		{ "she-chip-error": variant === "error" },
		className,
	);

	return (
		<div
			className={classes}
			data-testid={TEST_ID}
			ref={ref}
			style={backgroundColor ? { backgroundColor } : {}}
			{...rest}
		>
			<span>{children}</span>
		</div>
	);
};

Chip.TEST_ID = TEST_ID;
