export const trimBase64String = (originalString: string): string => {
	const searchString = "base64,";
	if (originalString && originalString.length > searchString.length) {
		const indexOfSearchString = originalString.indexOf(searchString);
		if (indexOfSearchString >= 0) {
			return originalString.slice(indexOfSearchString + searchString.length);
		}
	}

	return "";
};
