import { useEffect, useContext, useState, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "oidc-react";
import { cancelRecordAttachments } from "../../../state/components/attachment";
import { UploadSingleton } from "../../../workers/upload/upload";
import { isBrowserIE } from "../../../helpers/ieHelper";
import { APIHealthContext } from "../../../services/health/implementations/healthContext/healthContext";
import { OnlineStatus } from "../../../services/health/implementations/healthMonitor";
import { PortalPage } from "../PortalPage";
import { Documents } from "../DocumentsPage";
import { Settings } from "../SettingsPage";
import { RecordsPage } from "../RecordsPage";
import { ActionsPage } from "../ActionsPage";
import { Loading } from "../../components/Loading";
import { InitialSetup } from "../setup/initialSetup";
import { TemplateSync } from "../setup/templateSyncPage";
import { cancelQuestionnaire } from "../../../state/components/questionnaire";
import { FormsStatsProvider } from "../PortalPage/components/FormsStatsContext/FormsStatsContext";
import { CommonErrorNotification } from "../../components/error/CommonErrorNotification";
import { useToken } from "../../utilities/UseToken";
import { Password } from "../PasswordPage";
import { loadPortal } from "../../../state";
import { PrivateRoute } from "../../../routes/PrivateRoute";
import { TrackedRoute } from "../../../routes/TrackedRoute";
import { TrainingPage } from "../TrainingPage";
import { QueuedUploadError } from "./components/QueuedUploadError";
import "./HomePage.styles.scss";
import type { Portal } from "../../../models/portal";
import type { State } from "../../../state";

export const HomePage = () => {
	const { customerKey, portalKey } = useParams<{ customerKey: string; portalKey: string }>();
	const health = useContext(APIHealthContext);
	const dispatch = useDispatch();

	const { t } = useTranslation();

	const [uploadErrors, setUploadErrors] = useState(false);
	const [displayUploadErrors, setDisplayUploadErrors] = useState(false);

	const portal = useSelector<State, Portal | undefined>((s) =>
		s.portal.portals.find(
			(p) => p.key === portalKey.toLowerCase() && p.customerKey === customerKey.toLowerCase(),
		),
	);

	const initialSetupCompleted = portal && portal.initialSetupCompleted;

	const [isLoading, setIsLoading] = useState(health === OnlineStatus.Available || !portal);

	const token = useToken();

	const { userData } = useAuth();

	const isPasswordChangeRequired = userData && userData.profile.forcePasswordChange;

	useEffect(() => {
		if (!portal && token) {
			dispatch(loadPortal(portalKey.toLowerCase()));
		}
		if (portal) {
			setIsLoading(false);
		}
	}, [portalKey, customerKey, portal, dispatch, health, token]);

	useEffect(() => {
		if (!isLoading && !isBrowserIE()) {
			const uploadInstance = UploadSingleton.getInstance();
			uploadInstance.HasFailedUploads(userData || null).then((res) => {
				setUploadErrors(res);
				uploadInstance.ShouldDisplayUploadError(userData || null).then((res) => {
					window.setTimeout(() => {
						setDisplayUploadErrors(res);
					}, 500);
				});
			});
		}
	}, [isLoading, userData]);

	useEffect(() => {
		dispatch(cancelRecordAttachments());
		dispatch(cancelQuestionnaire(true));
	}, [dispatch]);

	const eventListener = useCallback(() => {
		const uploadInstance = UploadSingleton.getInstance();
		uploadInstance.HasFailedUploads(userData || null).then((res) => {
			if (res !== uploadErrors) {
				setUploadErrors(res);
			}
		});
	}, [uploadErrors, setUploadErrors, userData]);

	useEffect(() => {
		window.addEventListener("uploadRefresh", eventListener);
		return () => window.removeEventListener("uploadRefresh", eventListener);
	}, [eventListener]);

	const offlineEnabled = useMemo(() => !isBrowserIE(), []);

	return (
		<>
			{!isLoading && portal && (
				<>
					<Switch>
						{isPasswordChangeRequired ? (
							<Switch>
								<TrackedRoute exact path="/:customerKey/p/:portalKey/settings">
									<Settings />
								</TrackedRoute>
								<TrackedRoute path="/:customerKey/p/:portalKey">
									<Password />
								</TrackedRoute>
							</Switch>
						) : (
							<>
								<PrivateRoute
									component={Password}
									path="/:customerKey/p/:portalKey/password"
								/>

								<TrackedRoute exact path="/:customerKey/p/:portalKey/settings">
									<Settings />
								</TrackedRoute>

								<TrackedRoute exact path="/:customerKey/p/:portalKey/documents">
									<Documents />
								</TrackedRoute>

								<TrackedRoute exact path="/:customerKey/p/:portalKey/records">
									<RecordsPage />
								</TrackedRoute>

								<TrackedRoute
									exact
									path="/:customerKey/p/:portalKey/actions/:action(create|edit|detail|complete)?/:actionId?"
								>
									<ActionsPage />
								</TrackedRoute>

								<TrackedRoute
									exact
									path={[
										"/:customerKey/p/:portalKey/actions/:action(create|edit|detail|complete)?/:actionId?/comments/:commentId?",
										"/:customerKey/p/:portalKey/actions/:action(create|edit|detail|complete)?/:actionId?/comments/edit/:commentId?",
									]}
								>
									<ActionsPage />
								</TrackedRoute>
								<TrackedRoute
									exact
									path={[
										"/:customerKey/p/:portalKey/records/:recordsKey/:startIndex",
										"/:customerKey/p/:portalKey/records/:recordsKey",
										"/:customerKey/p/:portalKey/documents/:recordsKey(Policy)/:startIndex",
										"/:customerKey/p/:portalKey/documents/:recordsKey(Policy)",
									]}
								>
									<RecordsPage />
								</TrackedRoute>

								<TrackedRoute
									exact
									path={[
										"/:customerKey/p/:portalKey/training",
										"/:customerKey/p/:portalKey/training/:trainingKey",
									]}
								>
									<TrainingPage />
								</TrackedRoute>

								<TrackedRoute
									exact
									path={[
										"/:customerKey/p/:portalKey",
										"/:customerKey/p/:portalKey/:formsTab(forms|drafts|queue)",
									]}
								>
									<FormsStatsProvider portalStubs={portal.questionnaireStubs}>
										<PortalPage uploadErrors={uploadErrors} />
									</FormsStatsProvider>
								</TrackedRoute>
							</>
						)}
					</Switch>

					{health === OnlineStatus.Available &&
						offlineEnabled &&
						!initialSetupCompleted && (
							<InitialSetup
								customerKey={customerKey.toLowerCase()}
								portalKey={portalKey.toLowerCase()}
							/>
						)}

					{uploadErrors && (
						<QueuedUploadError
							cancelButtonClicked={() => {
								setDisplayUploadErrors(false);
								UploadSingleton.getInstance().SetDisplayUploadError(false);
							}}
							displayErrors={displayUploadErrors}
							portal={portal}
						/>
					)}

					{health === OnlineStatus.Available &&
						offlineEnabled &&
						portal.questionnaireTemplateSyncRequired && (
							<TemplateSync
								customerKey={customerKey.toLowerCase()}
								portalKey={portalKey.toLowerCase()}
								show={portal.questionnaireTemplateSyncRequired}
								stubs={portal.questionnaireStubs}
							/>
						)}
					<CommonErrorNotification
						handleOnce
						message={t("error:quotaExceededPortal")}
						reason="otherQuotaError"
					/>
				</>
			)}
			<Loading show={isLoading} withHistory={false} />
		</>
	);
};
