import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ActionGuidanceTypeOfDisplay } from "../../../../models/action";
import type { ActionInfoFull } from "../../../../models/action";
import type { MyTasksLabel } from "../../../../types/i18next";

export const useActionLabelAndGuidance = (propertyName: string, actionInfo?: ActionInfoFull[]) => {
	const { t } = useTranslation();

	return useMemo(() => {
		if (actionInfo) {
			const info = actionInfo.find((info) => info.PropertyName === propertyName);

			if (info?.Caption) {
				return {
					label:
						info.Caption || t(`display:myTasks.label${propertyName as MyTasksLabel}`),
					showGuidance: !!info.Guidance,
					guidanceIsPopup: info.TypeOfDisplay === ActionGuidanceTypeOfDisplay.Popup,
					guidance: info.Guidance ? info.Guidance : undefined,
					visible: info.Visible,
					isMandatory: info.IsMandatory,
					defaultValue: info.DefaultValue,
				};
			}
		}

		return {
			label: t(`display:myTasks.label${propertyName as MyTasksLabel}`),
			showGuidance: false,
			guidanceIsPopup: false,
			guidance: undefined,
			visible: false,
			isMandatory: false,
			defaultValue: null,
		};
	}, [actionInfo, t, propertyName]);
};
