interface Props {
	buttons: {
		text: string;
		disabled: boolean;
		awaiting: boolean;
		primary: boolean;
		onClick?: () => void;
	}[];
}

export const ButtonBarPresentation = ({ buttons }: Props) => {
	return (
		<div className="she-components-button-bar">
			{buttons.map((b) => (
				<button
					className={`${
						b.primary ? "she-btn she-btn-primary" : "she-btn she-btn-secondary"
					} ${b.awaiting ? "she-btn-busy" : ""}`}
					disabled={b.disabled}
					key={b.text}
					onClick={() => {
						if (b.onClick) {
							b.onClick();
						}
					}}
					type="button"
				>
					{b.text}
				</button>
			))}
		</div>
	);
};
