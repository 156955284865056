import { AttachmentParentType } from "../../models/attachments/Attachment";
import type { Attachment } from "../../models/attachments/Attachment";
import type { SubModuleRecord } from "../../models/questionnaire";

export const clearOrphanedAttachments = (
	attachments: Attachment[],
	subModuleRecords: SubModuleRecord[],
) => {
	return attachments.filter(
		(a) =>
			a.parentType !== AttachmentParentType.Submodule ||
			(a.parentType === AttachmentParentType.Submodule &&
				subModuleRecords.some((s) => s.localId === a.parentGuid)),
	);
};
