import { useMemo } from "react";
import { TreeNode } from "./TreeNode.component";
import type { TreeData } from "./model";
import "./Tree.styles.scss";

interface Props {
	name: string;
	data: TreeData[];

	mode: "tree" | "menu";

	selectedId?: number;
	onSelect: (id: number) => void;
}

export const Tree = ({ name, data, mode, selectedId, onSelect }: Props) => {
	const rootId = useMemo(() => {
		const top = data.find((d) => d.parentId === undefined);
		if (!top) {
			return undefined;
		}

		if (mode === "menu") {
			const selected = data.find((d) => d.id === selectedId);
			if (selected) {
				const childrenCount = data.filter((d) => d.parentId === selected.id).length;
				if (childrenCount === 0) {
					return selected.parentId;
				}

				return selected.id;
			}
		}

		return top.id;
	}, [data, mode, selectedId]);

	if (rootId === undefined) {
		return <span>error</span>;
	}

	return (
		<div className={mode === "tree" ? "she-tree" : ""}>
			<TreeNode
				data={data}
				id={rootId}
				mode={mode}
				name={name}
				onSelect={onSelect}
				rootId={rootId}
				selectedId={selectedId}
			/>
		</div>
	);
};
