import { t } from "i18next";
import type { BooleanField } from "../../models/fields/BooleanField";

export class BooleanFieldValidator {
	boolField: BooleanField;
	messages: string[];

	constructor(boolField: BooleanField) {
		this.boolField = boolField;
		this.messages = [];
	}

	isFieldValid = (value?: boolean): boolean => {
		let result = true;
		this.messages = [];

		if (this.boolField.isMandatory) {
			if (value == null) {
				this.messages.push(t("validation:mandatoryFieldMessage"));
				result = false;
			}
		}

		return result;
	};
}
