import { BaseService } from "../../baseService";
import { withSignInGet } from "../../utilities/http";
import type { IHttpResponse } from "../../utilities/http";
import type { ResponseTrainingRecord, TrainingRecord } from "../../../models/training";
import type { TrainingService } from "../types";

export class DefaultTrainingService extends BaseService implements TrainingService {
	async getTrainingRecords() {
		const query = "/LinkedCourses";

		const trainingUrl = `${await this.getEndpointUrl()}${query}`;
		const headers = await this.getAuthHeaders();

		const response = await withSignInGet<ResponseTrainingRecord[]>(trainingUrl, {
			...headers,
		});

		return this.formatRecords(response);
	}

	async getTrainingRecordsCount() {
		const query = "/LinkedCoursesCount";

		const countUrl = `${await this.getEndpointUrl()}${query}`;
		const headers = await this.getAuthHeaders();

		const response = await withSignInGet<number>(countUrl, {
			...headers,
		});

		return response.data;
	}

	formatRecords(response: IHttpResponse<ResponseTrainingRecord[]>) {
		const records = (response.data as ResponseTrainingRecord[]).filter(
			(record) => !record.CompletedDate,
		);

		const formattedRecords: TrainingRecord[] = records.map((record: ResponseTrainingRecord) => {
			const formattedRecord: TrainingRecord = {
				dueDate: record.DueDate,
				learnId: record.LearnId,
				linkUrl: record.LinkUrl ?? undefined,
				imageUrl: record.ImageUrl ?? undefined,
				moduleId: record.SheModuleId,
				customerKey: record.SheCustomerKey,
				status: record.Status,
				title: record.Title,
				isUnlinked: record.IsUnlinked,
			};

			return formattedRecord;
		});

		return formattedRecords;
	}
}
