import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { PageMainNavGroup } from "../PageMainNav";
import { PortalAuthenticationMode, PortalViewType } from "../../../../../models/portal";
import { ReactComponent as DocumentsIcon } from "../../../../assets/svg/offline-navigation/streamline-icon-bookmarks-document.svg";
import { ReactComponent as DocumentsIconActive } from "../../../../assets/svg/offline-navigation/streamline-icon-bookmarks-document-bold.svg";
import { ReactComponent as FormsIcon } from "../../../../assets/svg/offline-navigation/forms.svg";
import { ReactComponent as FormsIconActive } from "../../../../assets/svg/offline-navigation/forms-selected.svg";
import { ReactComponent as RecordsIcon } from "../../../../assets/svg/offline-navigation/records.svg";
import { ReactComponent as RecordsIconActive } from "../../../../assets/svg/offline-navigation/records-selected.svg";
import { ReactComponent as TaskIcon } from "../../../../assets/svg/offline-navigation/task-list.svg";
import { ReactComponent as TaskIconActive } from "../../../../assets/svg/offline-navigation/task-list-bold.svg";
import { ReactComponent as TrainingIcon } from "../../../../assets/svg/offline-navigation/training.svg";
import { ReactComponent as TrainingIconActive } from "../../../../assets/svg/offline-navigation/training-bold.svg";
import { useGetPolicies } from "../../../../utilities/UseGetPolicies";
import { useIsAuthenticated } from "../../../../../helpers/UseIsAuthenticated";
import { usePortalPath } from "../../../../../helpers/UsePortalPath";
import { useGetActionsNumber } from "../../../../utilities/UseGetActionsNumber";
import { useGetTrainingCount } from "../../../../utilities/useGetTrainingCount";
import type { Portal } from "../../../../../models/portal";
import type { State } from "../../../../../state";

export const PageNavigation = () => {
	const { t } = useTranslation();
	const portalPath = usePortalPath();
	const { customerKey, formsTab, portalKey } = useParams<{
		formsTab: string;
		portalKey: string;
		customerKey: string;
	}>();
	const [lastFormsTab, setLastFormsTab] = useState("");

	const portal = useSelector<State, Portal | undefined>((s) =>
		s.portal.portals.find(
			(p) => p.key === portalKey.toLowerCase() && p.customerKey === customerKey.toLowerCase(),
		),
	);

	const isAuthenticatedPortal = portal?.authenticationMode !== PortalAuthenticationMode.Public;
	const { data: policies } = useGetPolicies(portalKey);
	const hasDocuments =
		policies &&
		((policies.attachments && policies.attachments.length > 0) || !!policies.policyCount);

	const isUserAuthenticated = useIsAuthenticated();
	const { actionsCount } = useGetActionsNumber();
	const trainingCount = useGetTrainingCount();

	const hasModuleInstalls = useSelector(
		(state: State) =>
			state.portal &&
			state.portal.portals &&
			state.portal.portals.some(
				(p) =>
					p.key.toLowerCase() === portalKey.toLowerCase() &&
					p.installModules &&
					p.installModules.filter((m) => m.key !== "Policy").length > 0,
			),
	);

	const hasPolicyModule = useSelector(
		(state: State) =>
			state.portal &&
			state.portal.portals &&
			state.portal.portals.some(
				(p) =>
					p.key.toLowerCase() === portalKey.toLowerCase() &&
					p.installModules &&
					p.installModules.some((m) => m.key === "Policy"),
			),
	);

	useEffect(() => {
		setLastFormsTab(formsTab === undefined ? "" : formsTab);
	}, [formsTab]);

	const items = useMemo(() => {
		return [
			{
				to: portalPath + lastFormsTab,
				icon: <FormsIcon />,
				iconActive: <FormsIconActive />,
				label: t("display:labelNavigationForms"),
				exact: true,
				show: true,
			},
			{
				to: portalPath + PortalViewType.Documents + (!hasDocuments ? "/Policy" : ""),
				icon: <DocumentsIcon />,
				iconActive: <DocumentsIconActive />,
				label: t("display:labelNavigationDocuments"),
				show: hasDocuments || hasPolicyModule,
			},
			{
				to: portalPath + PortalViewType.Records,
				icon: <RecordsIcon />,
				iconActive: <RecordsIconActive />,
				label: t("display:labelNavigationRecords"),
				show: hasModuleInstalls,
			},
			{
				to: portalPath + PortalViewType.Actions,
				icon: <TaskIcon />,
				iconActive: <TaskIconActive />,
				label: t("display:labelNavigationActions"),
				show: isUserAuthenticated && isAuthenticatedPortal,
				pill: actionsCount > 0 ? actionsCount.toLocaleString() : undefined,
			},
			{
				to: portalPath + PortalViewType.Training,
				icon: <TrainingIcon />,
				iconActive: <TrainingIconActive />,
				label: t("display:labelNavigationTraining"),
				show: isUserAuthenticated && isAuthenticatedPortal && portal?.showLearnTraining,
				pill: trainingCount > 0 ? trainingCount.toLocaleString() : undefined,
			},
		];
	}, [
		portalPath,
		lastFormsTab,
		t,
		hasDocuments,
		hasModuleInstalls,
		hasPolicyModule,
		isUserAuthenticated,
		actionsCount,
		isAuthenticatedPortal,
		trainingCount,
		portal?.showLearnTraining,
	]);
	return <PageMainNavGroup items={items} />;
};
