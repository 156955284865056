import { v4 as uuid } from "uuid";
import { FieldType } from "../../../../models/questionnaire";
import { BaseService } from "../../../baseService";
import { withSignInGet } from "../../../utilities/http";
import type { MatrixService } from "../../interface";
import type { Field } from "../../../../models/fields/Field";
import type { MatrixField } from "../../../../models/fields/MatrixField";
import type { Matrix } from "../../../../models/matrix/matrix";
import type { MatrixEdge } from "../../../../models/matrix/matrixEdge";
import type { MatrixFactor } from "../../../../models/matrix/matrixFactor";

export class DefaultMatrixService extends BaseService implements MatrixService {
	async getMatrices(fields: Field[] | undefined): Promise<{ matrixList: Matrix[] }> {
		const matrixIds = this.getUniqueMatrixIds(fields);
		const matrixes: Matrix[] = [];

		for (const matrixId of matrixIds) {
			const endpoint = await this.getEndpointUrl();
			const serviceUrl = `${endpoint}?Id=${matrixId}`;
			const response = await withSignInGet<any>(serviceUrl, await this.getAuthHeaders());
			const data = response.parsedBody && response.parsedBody.Matrix;

			const matrix: Matrix = {
				id: data.Id,
				guid: uuid(),
				dimensionX: data.DimensionX,
				dimensionY: data.DimensionY,
				guidance: data.Guidance,
				modalGuidance: data.ModalGuidance,
				captionX: data.CaptionX,
				captionY: data.CaptionY,
				captionFactor: data.CaptionFactor,
				captionType: data.CaptionType,
				edges: [],
				factors: [],
			};

			data.Edges.forEach((edgeData: any) => {
				const edge: MatrixEdge = {
					count: edgeData.EdgeCount,
					caption: edgeData.EdgeCaption,
					value: edgeData.EdgeValue,
					direction: edgeData.EdgeDirection,
				};

				matrix.edges.push(edge);
			});

			data.Factors &&
				data.Factors.forEach((factorData: any) => {
					const factor: MatrixFactor = {
						id: factorData.FactorId,
						caption: factorData.FactorCaption,
						count: factorData.FactorCount,
						value: factorData.FactorValue,
						colour: factorData.Colour,
					};

					matrix.factors.push(factor);
				});

			matrixes.push(matrix);
		}
		return {
			matrixList: matrixes,
		};
	}

	private getUniqueMatrixIds(fields: Field[] | undefined): number[] {
		const matrixIds: number[] = [];
		if (fields && fields.length > 0) {
			const matrixFields = fields
				.filter((f) => f.type === FieldType.Matrix)
				.map((f) => f as MatrixField);

			matrixFields.forEach((f) => {
				if (!matrixIds.includes(f.matrixId)) {
					matrixIds.push(f.matrixId);
				}
			});
		}

		return matrixIds;
	}
}
