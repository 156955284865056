import { useMemo, useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FieldType } from "../../../../models/questionnaire";
import { ProjectSortOrder, ProjectSortField } from "../../../../services/project";
import { ProjectSelectInputListItem } from "../ProjectSelectInputListItem";
import { SearchBox } from "../SearchBox";
import { Pagination } from "../../generic/Pagination";
import { TouchInputBlurrer } from "../../generic/TouchInputBlurrer";
import { FormModal } from "../../modals";
import { ReactComponent as IconSort } from "../../../assets/svg/arrange-letter.svg";
import { RadioButton } from "../RadioButton";
import { stringHelper } from "../../../../helpers/StringHelper";
import {
	ProjectsLocalStatus,
	useProjectSelectLocalState,
	ProjectsLocalActionTypes,
} from "./ProjectSelectUseLocalState";
import { useProjectService } from "./ProjectSelectUseService";
import type { OrgUnit } from "../../../../models/orgUnit";
import type { Field } from "../../../../models/fields/Field";
import type { State } from "../../../../state";
import type { ProjectSelectField } from "../../../../models/fields/ProjectSelctField";
import type { Project } from "../../../../models/project/project";
import "./ProjectSelectList.styles.scss";

interface Props {
	field: ProjectSelectField;
	dateFormat: string;
	portalCulture: string;
	onSelectProject: (project: Project) => void;
}

export const ProjectSelectInputList = ({
	field,
	dateFormat,
	portalCulture,
	onSelectProject,
}: Props) => {
	const { t } = useTranslation();

	const pageSize = 100;
	const storageSortOrder = localStorage.getItem("storageSortOrder");
	const storageSortProperty = localStorage.getItem("storageSortProperty");

	const [showModal, setShowModal] = useState(false);
	const [stateSortOrder, setStateSortOrder] = useState<ProjectSortOrder>();
	const [stateSortProperty, setStateSortProperty] = useState<ProjectSortField>();
	const [stateCanSaveSort, setStateCanSaveSort] = useState(false);
	const [stateSortCurrentLabel, setStateSortCurrentLabel] = useState("");
	const [stateInitialLoad, setStateInitialLoad] = useState(true);

	const orgUnitSelected = useSelector<State, Field | undefined>((s) =>
		s.questionnaire.fields.find((f) => f.type === FieldType.OrgUnit),
	);
	const orgUnits = useSelector<State, OrgUnit[]>((s) => s.orgUnit.orgUnits);

	const groupText = useMemo(
		() => stringHelper.alphaNumericRegExLowerCase(field.name),
		[field.name],
	);
	const searchTerm = useMemo(() => {
		if (
			orgUnitSelected &&
			orgUnitSelected.value &&
			orgUnitSelected.type === FieldType.OrgUnit &&
			!orgUnitSelected.isFixed &&
			!orgUnitSelected.hidden
		) {
			const orgUnit = orgUnits.find((o) => o.id === orgUnitSelected.value);
			if (orgUnit) {
				return `"${orgUnit.name}"`;
			}
		}
		return undefined;
	}, [orgUnitSelected, orgUnits]);

	const [localState, localStateDispatch] = useProjectSelectLocalState({
		page: 1,
		status: ProjectsLocalStatus.Loading,
		checkedProjectId: field.value && field.value.id,
		searchTerm,
		portalCulture,
		sortOrder: ProjectSortOrder.Asc,
		sortProperty: ProjectSortField.Reference,
	});
	const searchBoxRef = useRef<HTMLInputElement>(null);

	useProjectService(localState, localStateDispatch);

	const onChangeProject = (project: Project) => {
		project.captions = localState.captions;
		localStateDispatch({
			type: ProjectsLocalActionTypes.SetCheckedProjectId,
			checkedProjectId: project.id,
		});
		onSelectProject(project);
	};

	const onChangePage = (page: number) => {
		localStateDispatch({
			type: ProjectsLocalActionTypes.SetStatus,
			status: ProjectsLocalStatus.Loading,
		});
		localStateDispatch({ type: ProjectsLocalActionTypes.SetPage, page });
	};

	const onSearchTermChange = (searchTerm: string) => {
		if (searchTerm !== localState.searchTerm) {
			localStateDispatch({
				type: ProjectsLocalActionTypes.SetStatus,
				status: ProjectsLocalStatus.Loading,
			});
			localStateDispatch({
				type: ProjectsLocalActionTypes.SetSearchTerm,
				searchTerm,
			});
		}
	};

	const recordsDetails = localState.projects
		? t("display:projectSelect.labelShowingRecordsNumber", {
				min: pageSize * (localState.page - 1) + 1,
				max: Math.min(pageSize * localState.page, localState.projects.resultCount),
				total: localState.projects.resultCount,
		  })
		: "";

	// Sorting project reference

	const handleSortSetLocalState = (order: ProjectSortOrder, property: ProjectSortField) => {
		setStateSortOrder(order);
		setStateSortProperty(property);
	};

	useEffect(() => {
		if (!!storageSortOrder && !!storageSortProperty) {
			let storedSortOrder;
			let storedSortProperty;

			if (storageSortOrder === "Desc") {
				storedSortOrder = ProjectSortOrder.Desc;
			} else {
				storedSortOrder = ProjectSortOrder.Asc;
			}

			switch (storageSortProperty) {
				case "StartDate":
					storedSortProperty = ProjectSortField.StartDate;
					break;
				case "Location":
					storedSortProperty = ProjectSortField.Location;
					break;
				case "EntityReference":
					storedSortProperty = ProjectSortField.Reference;
					break;
				case "Manager":
					storedSortProperty = ProjectSortField.Manager;
					break;
				case "OrgUnitName":
					storedSortProperty = ProjectSortField.OrgUnitName;
					break;
				default:
					storedSortProperty = ProjectSortField.Reference;
					break;
			}

			handleSortSetLocalState(storedSortOrder, storedSortProperty);

			localStateDispatch({
				type: ProjectsLocalActionTypes.SetSort,
				setSort: {
					sortOrder: storedSortOrder,
					sortProperty: storedSortProperty,
				},
			});
		} else {
			handleSortSetLocalState(ProjectSortOrder.Asc, ProjectSortField.Reference);

			localStateDispatch({
				type: ProjectsLocalActionTypes.SetSort,
				setSort: {
					sortOrder: ProjectSortOrder.Asc,
					sortProperty: ProjectSortField.Reference,
				},
			});
		}
	}, [localStateDispatch, storageSortOrder, storageSortProperty]);

	const sortOptions = useMemo(
		() => [
			{
				idTitle: "reference-a-to-z",
				selected:
					stateSortOrder === ProjectSortOrder.Asc &&
					stateSortProperty === ProjectSortField.Reference,
				onClick: () =>
					handleSortSetLocalState(ProjectSortOrder.Asc, ProjectSortField.Reference),
				label: t("display:projectSelect.label", {
					title: localState.captions && localState.captions.referenceCaption,
					order: t("display:projectSelect.aToZ"),
				}),
			},
			{
				idTitle: "reference-z-to-a",
				selected:
					stateSortOrder === ProjectSortOrder.Desc &&
					stateSortProperty === ProjectSortField.Reference,
				onClick: () =>
					handleSortSetLocalState(ProjectSortOrder.Desc, ProjectSortField.Reference),
				label: t("display:projectSelect.label", {
					title: localState.captions && localState.captions.referenceCaption,
					order: t("display:projectSelect.zToA"),
				}),
			},
			{
				idTitle: "location-a-to-z",
				selected:
					stateSortOrder === ProjectSortOrder.Asc &&
					stateSortProperty === ProjectSortField.Location,
				onClick: () =>
					handleSortSetLocalState(ProjectSortOrder.Asc, ProjectSortField.Location),
				label: t("display:projectSelect.label", {
					title: localState.captions && localState.captions.locationCaption,
					order: t("display:projectSelect.aToZ"),
				}),
			},
			{
				idTitle: "location-z-to-a",
				selected:
					stateSortOrder === ProjectSortOrder.Desc &&
					stateSortProperty === ProjectSortField.Location,
				onClick: () =>
					handleSortSetLocalState(ProjectSortOrder.Desc, ProjectSortField.Location),
				label: t("display:projectSelect.label", {
					title: localState.captions && localState.captions.locationCaption,
					order: t("display:projectSelect.zToA"),
				}),
			},
			{
				idTitle: "start-date-new-to-old",
				selected:
					stateSortOrder === ProjectSortOrder.Desc &&
					stateSortProperty === ProjectSortField.StartDate,
				onClick: () =>
					handleSortSetLocalState(ProjectSortOrder.Desc, ProjectSortField.StartDate),
				label: t("display:projectSelect.label", {
					title: localState.captions && localState.captions.startDateCaption,
					order: t("display:projectSelect.newToOld"),
				}),
			},
			{
				idTitle: "start-date-old-to-new",
				selected:
					stateSortOrder === ProjectSortOrder.Asc &&
					stateSortProperty === ProjectSortField.StartDate,
				onClick: () =>
					handleSortSetLocalState(ProjectSortOrder.Asc, ProjectSortField.StartDate),
				label: t("display:projectSelect.label", {
					title: localState.captions && localState.captions.startDateCaption,
					order: t("display:projectSelect.oldToNew"),
				}),
			},
			{
				idTitle: "manager-a-to-z",
				selected:
					stateSortOrder === ProjectSortOrder.Asc &&
					stateSortProperty === ProjectSortField.Manager,
				onClick: () =>
					handleSortSetLocalState(ProjectSortOrder.Asc, ProjectSortField.Manager),
				label: t("display:projectSelect.label", {
					title: localState.captions && localState.captions.managerCaption,
					order: t("display:projectSelect.aToZ"),
				}),
			},
			{
				idTitle: "manager-z-to-a",
				selected:
					stateSortOrder === ProjectSortOrder.Desc &&
					stateSortProperty === ProjectSortField.Manager,
				onClick: () =>
					handleSortSetLocalState(ProjectSortOrder.Desc, ProjectSortField.Manager),
				label: t("display:projectSelect.label", {
					title: localState.captions && localState.captions.managerCaption,
					order: t("display:projectSelect.zToA"),
				}),
			},
			{
				idTitle: "org-unit-a-to-z",
				selected:
					stateSortOrder === ProjectSortOrder.Asc &&
					stateSortProperty === ProjectSortField.OrgUnitName,
				onClick: () =>
					handleSortSetLocalState(ProjectSortOrder.Asc, ProjectSortField.OrgUnitName),
				label: t("display:projectSelect.label", {
					title: localState.captions && localState.captions.orgUnitIdCaption,
					order: t("display:projectSelect.aToZ"),
				}),
			},
			{
				idTitle: "org-unit-z-to-a",
				selected:
					stateSortOrder === ProjectSortOrder.Desc &&
					stateSortProperty === ProjectSortField.OrgUnitName,
				onClick: () =>
					handleSortSetLocalState(ProjectSortOrder.Desc, ProjectSortField.OrgUnitName),
				label: t("display:projectSelect.label", {
					title: localState.captions && localState.captions.orgUnitIdCaption,
					order: t("display:projectSelect.zToA"),
				}),
			},
		],
		[localState.captions, stateSortOrder, stateSortProperty, t],
	);

	const getLabel = sortOptions.find((item) => item.selected === true);

	useEffect(() => {
		const hasLength = stateSortCurrentLabel.length > 0;
		const notUndefined = !stateSortCurrentLabel.includes("undefined");

		if (getLabel && stateInitialLoad) {
			setStateSortCurrentLabel(getLabel.label);
		}

		if (hasLength && notUndefined) {
			setStateInitialLoad(false);
		}
	}, [sortOptions, stateInitialLoad, stateSortCurrentLabel, getLabel]);

	useEffect(() => {
		const matchSortOrder = storageSortOrder === stateSortOrder;
		const matchSortProperty = storageSortProperty === stateSortProperty;
		const matchSortOrderAndProperty = matchSortOrder && matchSortProperty;

		if (!matchSortOrderAndProperty) {
			return setStateCanSaveSort(true);
		}

		if (matchSortOrderAndProperty) {
			return setStateCanSaveSort(false);
		}
	}, [storageSortOrder, storageSortProperty, stateSortOrder, stateSortProperty]);

	const handleSortOnOk = () => {
		localStorage.setItem("storageSortOrder", stateSortOrder || "");
		localStorage.setItem("storageSortProperty", stateSortProperty || "");

		if (stateCanSaveSort) {
			getLabel && setStateSortCurrentLabel(getLabel.label);

			localStateDispatch({
				type: ProjectsLocalActionTypes.SetSort,
				setSort: {
					sortOrder: stateSortOrder,
					sortProperty: stateSortProperty,
				},
			});
		}

		setShowModal(false);
	};

	const handleSortOnCancel = () => {
		setShowModal(false);
	};

	return (
		<div className="she-project-list">
			<div className="she-project-list__search">
				<SearchBox
					onSearchBegan={() => {}}
					onSearchEnd={() => {}}
					onSearchTermChange={onSearchTermChange}
					reference={searchBoxRef}
					searchTerm={localState.searchTerm || ""}
				/>
			</div>

			<div className="she-project-list__sort">
				<button
					className="she-btn she-btn-tertiary she-project-list__sort-button"
					onClick={() => {
						setShowModal(true);
						setStateSortOrder(localState.sortOrder);
						setStateSortProperty(localState.sortProperty);
					}}
					type="button"
				>
					<IconSort aria-hidden />
					{t("display:projectSelect.buttonLabelSort")}
				</button>
			</div>

			<div className="she-project-list__list">
				{localState.status === ProjectsLocalStatus.Success && (
					<TouchInputBlurrer refToBlur={searchBoxRef}>
						<div className="she-project-list__numbers">
							{t("display:projectSelect.labelShowingRecordsNumberSortedBy", {
								labelShowingRecordsNumber: recordsDetails,
								sortedBy: t("display:projectSelect.sortedBy", {
									label: stateSortCurrentLabel,
								}),
							})}
						</div>

						{localState.projects &&
							localState.projects.projects.map(
								(project: Project) =>
									localState.captions && (
										<ProjectSelectInputListItem
											captions={localState.captions}
											dateFormat={dateFormat}
											fieldId={field.id}
											groupText={groupText}
											key={project.id}
											onSelectProject={() => onChangeProject(project)}
											project={project}
											selected={localState.checkedProjectId === project.id}
										/>
									),
							)}

						<div className="she-project-list__numbers">{recordsDetails}</div>
						<div className="she-project-list__pagination">
							{localState.projects && (
								<Pagination
									currentPage={localState.page}
									maxPages={Math.ceil(localState.projects.resultCount / pageSize)}
									onChangePage={onChangePage}
								/>
							)}
						</div>
					</TouchInputBlurrer>
				)}

				{localState.status === ProjectsLocalStatus.Loading && (
					<div className="she-project-list__status">{t("global:loading")}</div>
				)}

				{localState.status === ProjectsLocalStatus.Error && (
					<div className="she-project-list__status">
						{t("error:projectsDownloadError")}
					</div>
				)}

				{localState.status === ProjectsLocalStatus.Empty && (
					<div className="she-project-list__status">
						{localState.searchTerm
							? t("display:labelNoSearchResults")
							: t("display:labelProjectEmptyList")}
					</div>
				)}
			</div>

			<FormModal
				onCancel={handleSortOnCancel}
				onOk={handleSortOnOk}
				show={showModal}
				title="Sort"
			>
				<div className="she-components-control-question">
					{t("display:projectSelect.sortRecordsBy")}
				</div>
				{sortOptions.map((sortOption) => (
					<RadioButton
						id={sortOption.idTitle}
						key={sortOption.idTitle}
						name="project-sort"
						onClick={sortOption.onClick}
						selected={sortOption.selected}
					>
						{sortOption.label}
					</RadioButton>
				))}
			</FormModal>
		</div>
	);
};
