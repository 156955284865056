import type { Portal } from "../../../../../models/portal";
import "./PortalHeader.styles.scss";

interface Props {
	portal: Portal;
}

export const PortalHeader = ({ portal }: Props) => {
	return (
		<>
			{(portal.notification?.title || portal.notification?.description) && (
				<header className="she-portal-header">
					{portal.notification.title && <h2>{portal.notification.title}</h2>}
					{portal.notification.description && (
						<div
							className="she-portal-description"
							dangerouslySetInnerHTML={{
								__html: (portal.notification.description || "").replace(
									/<a href=/g,
									'<a target="_blank" href=',
								),
							}}
						/>
					)}
				</header>
			)}
		</>
	);
};
