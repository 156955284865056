import { AttachmentType } from "../../../../../models/record";
import { ReactComponent as Attachment } from "../../../../assets/svg/streamline-icon-attachment.svg";
import { ReactComponent as Link } from "../../../../assets/svg/streamline-icon-hyperlink.svg";
import type { RecordAttachment } from "../../../../../models/record";

interface Props {
	propertyName: string;
	detail: string;
}
export const ItemDetail = ({ propertyName, detail }: Props) => {
	if (propertyName === "SheAttachments" || propertyName === "Attachments") {
		const parsedAttachments = JSON.parse(detail) as RecordAttachment[];

		const linkCount: number = parsedAttachments.filter(
			(a) => a.AttachmentType === AttachmentType.Link,
		).length;
		const fileCount: number = parsedAttachments.filter(
			(a) => a.AttachmentType === AttachmentType.File,
		).length;
		return (
			<div className="attachments-detail" data-testid="attachments-detail">
				<Attachment />
				{fileCount}
				<Link />
				{linkCount}
			</div>
		);
	}

	return <>{detail}</>;
};
