import "./ui/utilities/ApplicationId";
import "./services/log/initLog";
import "./ui/utilities/InjectGainsightTag";
import "bootstrap-reboot";
import { StrictMode } from "react";
import { render } from "react-dom";
import { Provider as ReduxProvider } from "react-redux";
import { store } from "./state";
import "./translations/i18n";
import { APIHealthProvider } from "./services/health/implementations/healthContext";
import { Root } from "./ui/Root";
import { ToastProvider, ToastContainer } from "./ui/components/toast";
import { WaitingServiceWorkerProvider } from "./ui/components/WaitingServiceWorkerContext";
import { QueryClient } from "./QueryClient";

render(
	<StrictMode>
		<WaitingServiceWorkerProvider>
			<ReduxProvider store={store}>
				<QueryClient>
					<APIHealthProvider>
						<ToastProvider>
							<Root />
							<ToastContainer />
						</ToastProvider>
					</APIHealthProvider>
				</QueryClient>
			</ReduxProvider>
		</WaitingServiceWorkerProvider>
	</StrictMode>,
	document.getElementById("root"),
);
