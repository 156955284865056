import React from "react";
import { AnimationSlideFade } from "../../../animation/AnimationSlideFade";
import { AnimationWrapper } from "../../../animation/AnimationWrapper";
import { Card } from "../../../Card";
import { ReactComponent as Remove } from "../../../../assets/svg/remove.svg";
import type { RegisterEntity } from "../../../../../models/registerEntity";

interface Props {
	entity?: RegisterEntity;
	buttonLabel: string;
	onClick: (event: React.MouseEvent) => void;
	onRemove: (event: React.MouseEvent) => void;
	removeLabel: string;
}

export const OptionalRegisterSelectInputPresentation = ({
	entity,
	buttonLabel,
	onClick,
	onRemove,
	removeLabel,
}: Props) => {
	return (
		<>
			<AnimationWrapper>
				{entity && (
					<AnimationSlideFade key={entity.id}>
						<Card
							buttonSlot={
								<button
									aria-label={removeLabel}
									className="she-icon she-icon-gray"
									onClick={onRemove}
									type="button"
								>
									<Remove />
								</button>
							}
							onClick={onClick}
						>
							<dl>
								{entity.info.map((info) =>
									info.value ? (
										<React.Fragment key={info.propertyName}>
											<dt>{info.title}</dt>
											<dd>{info.value}</dd>
										</React.Fragment>
									) : null,
								)}
							</dl>
						</Card>
					</AnimationSlideFade>
				)}
			</AnimationWrapper>
			<button className="she-btn she-btn-tertiary" onClick={onClick} type="button">
				{buttonLabel}
			</button>
		</>
	);
};
