import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox } from "../../../../components/Checkbox";
import {
	updateNotApplicable,
	updateValidationStatus,
} from "../../../../../state/components/questionnaire";
import { NotApplicableComments } from "../../../../../models/questionnaire";
import type { Field } from "../../../../../models/fields/Field";
import type { State } from "../../../../../state";

interface Props {
	field: Field;
}

export const QuestionnaireFieldNAComponent = ({ field }: Props) => {
	const dispatch = useDispatch();
	const notApplicableComments = useSelector<State, NotApplicableComments | undefined>(
		// eslint-disable-next-line @typescript-eslint/no-extra-non-null-assertion
		(state) => state.questionnaire.questionnaire!!.notApplicableComments,
	);

	const onNotApplicable = (event: React.ChangeEvent<HTMLInputElement>) => {
		const notApplicable = event.target.checked;

		let commentsEnabled = false;

		if (notApplicable) {
			commentsEnabled = notApplicableComments !== NotApplicableComments.Hidden;
		}

		dispatch(updateNotApplicable(field.id, notApplicable, commentsEnabled));
		dispatch(updateValidationStatus(field.id, []));
	};

	return (
		<>
			{field.allowNotApplicable && (
				<div className="she-components-control-na">
					<Checkbox
						checked={field.isNotApplicable}
						id={`${field.id}_notApplicableCheckbox`}
						name={t("display:labelNotApplicable")}
						onChange={onNotApplicable}
						value={0}
					/>
				</div>
			)}
		</>
	);
};
