import { Link, useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useActionComment } from "../hooks/useActionComment";
import { usePortalPath } from "../../../../../../helpers/UsePortalPath";
import type { ResponseActionComment } from "../../../../../../models/action";
import "./_action-last-comment.scss";
import classNames from "classnames";

export const ActionLastComment = (comment: ResponseActionComment) => {
	const portalPath = usePortalPath();
	const history = useHistory();
	const { action, actionId } = useParams<{ action: string; actionId: string }>();
	const { t } = useTranslation();

	const { actionStatus, owner, createdDate, deletedBy, status, content, id, latestDate } =
		useActionComment(comment);

	return (
		<div className="action-last-comment" data-testid="action-last-comment">
			<div className="action-last-comment__header">
				<p className="action-last-comment__title">{owner.name}</p>

				{status.key !== "Active" ? (
					<p
						className={classNames(
							`action-last-comment__tag`,
							{ "action-last-comment__tag--deleted": status.key === "Deleted" },
							{ "action-last-comment__tag--edited": status.key === "Edited" },
						)}
					>
						{status.label} - {latestDate.formattedNoTime}
					</p>
				) : null}
			</div>

			<p className="action-last-comment__date">{createdDate.formatted}</p>

			<p className="action-last-comment__status">{actionStatus.label}</p>

			<p className="action-last-comment__description">
				{status.key === "Deleted"
					? t("myTasks.labelCommentDeletedBy", { name: deletedBy })
					: content}
			</p>

			<Link
				className="she-btn she-btn-tertiary action-last-comment__view-full"
				to={{
					pathname: `${portalPath}actions/${action}/${actionId}/comments/${id}`,
					state: { previousPath: history.location.pathname },
				}}
			>
				{t("display:myTasks.labelCommentViewFull")}
			</Link>
		</div>
	);
};

ActionLastComment.displayName = "ActionLastComment";
