export const getIncrementedOrDecrementedValue = (
	value: any,
	isIncrement: boolean,
	isDecimal: boolean,
	step: number,
	min?: number,
	max?: number,
): number | undefined => {
	// Value exists so perform normal increment/decrement
	if (
		!Number.isNaN(value) &&
		value !== undefined &&
		value !== null &&
		value !== "" &&
		value !== "undefined"
	) {
		return performIncrementOrDecrement(
			convertToDecimalOrInt(value, isDecimal),
			isIncrement,
			step,
		);
	}

	if (!isIncrement) {
		return undefined; // When decrement and value is empty
	}

	if (min !== null && min !== undefined) {
		// When there is a min value, return the minimum value
		return min;
	} else if (max !== null && max !== undefined && max >= 0) {
		// When the max is anything above or equals 0 and there is no min, return 0
		return 0;
	} else if (max !== null && max !== undefined && max < 0) {
		// When the max is negative number and there is no min
		return max;
	} // When there is no max and no min, return 0

	return 0;
};

export const performIncrementOrDecrement = (
	value: any,
	isIncrement: boolean,
	step: number,
): number => {
	if (isIncrement) {
		// eslint-disable-next-line @typescript-eslint/restrict-plus-operands
		return Number((value + step).toFixed(5));
	}
	return Number((value - step).toFixed(5));
};

export const convertToDecimalOrInt = (value: any, isDecimal: boolean): number => {
	return isDecimal ? parseFloat(value) : parseInt(value);
};

export const getDisplayValue = (
	value: any,
	isDecimal: boolean,
	formatDisplay: boolean,
	numberOfFixedDecimalPlaces = 5,
): string => {
	if (
		value === undefined ||
		value === null ||
		isNaN(value) ||
		isNaN(parseInt(value)) ||
		value === "undefined"
	) {
		return "";
	}

	if (!formatDisplay) {
		return value;
	}

	const result = convertToDecimalOrInt(value, isDecimal);
	return isDecimal ? result.toFixed(numberOfFixedDecimalPlaces) : result.toString();
};

export const roundToMinMaxValue = (value: number, min?: number, max?: number): number => {
	if (max !== null && max !== undefined && value !== null && value > max) {
		return max;
	}

	if (min !== null && min !== undefined && value !== null && value < min) {
		return min;
	}

	return value;
};

export const roundToNearestStep = (
	value: number,
	step: number,
	min?: number,
	max?: number,
): number => {
	if (min !== undefined && min !== null && max !== undefined && max !== null) {
		return determineClosestStep(value, step, min, max);
	}
	return Math.round(value / step) * step;
};

export const determineClosestStep = (
	value: number,
	step: number,
	min: number,
	max: number,
): number => {
	if (value <= min) {
		return min;
	}

	if (value >= max) {
		value = max;
	}

	const lowerStep = Math.floor((value - min) / step) * step + min;
	if (lowerStep === value) {
		return value;
	}

	const upperStep = lowerStep + step;
	if (upperStep > max) {
		return lowerStep;
	}

	return Math.abs(value - lowerStep) <= Math.abs(upperStep - value) ? lowerStep : upperStep;
};

export const getHintDisplayValue = (value: number, isDecimal: boolean): string => {
	return isDecimal ? value.toFixed(5) : value.toFixed(0);
};
