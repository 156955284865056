import { useTranslation } from "react-i18next";
import "./Error.styles.scss";

interface Props {
	message?: any[];
	redirectUrl?: string;
	pageNotFound?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Error = ({ message, pageNotFound, redirectUrl }: Props) => {
	const { t } = useTranslation();

	return (
		<div className="she-error-message-container">
			<h2>{t("display:labelErrorTitle")}</h2>
			<h3>
				{(message && t(message[0], message[1])) ||
					(pageNotFound && t("error:pageNotFound")) ||
					t("error:generic")}
			</h3>
		</div>
	);
};
