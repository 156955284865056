import { Checkbox } from "../../Checkbox";
import { AnimationWrapper } from "../../animation/AnimationWrapper";
import { AnimationSlideFade } from "../../animation/AnimationSlideFade";

interface Props {
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	id: number;
	availableResponses: Map<number, string>;
	selectedValues: number[];
	isNotApplicable: boolean;
	guid: string;
}

export const MultiSelectInputPresentation = ({
	availableResponses,
	id,
	isNotApplicable,
	onChange,
	selectedValues,
	guid,
}: Props) => {
	return (
		<AnimationWrapper>
			{!isNotApplicable && (
				<AnimationSlideFade key={id}>
					<div
						aria-labelledby={`${guid}-label ${guid}-description`}
						data-testid="multi-select-input"
						role="group"
					>
						<ul className="she-components-checkbox-group">
							{Array.from(availableResponses).map((tuple) => (
								<li className="she-components-checkbox" key={tuple[0]}>
									<Checkbox
										checked={selectedValues.includes(tuple[0])}
										id={`${id}_${tuple[1].toString()}`}
										name={tuple[1]}
										onChange={onChange}
										value={tuple[0]}
									/>
								</li>
							))}
						</ul>
					</div>
				</AnimationSlideFade>
			)}
		</AnimationWrapper>
	);
};
