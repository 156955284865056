import moment from "moment";
import { withSignInGet } from "../../../utilities/http";
import { BaseService } from "../../../baseService";
import type { CancelTokenSource } from "axios";
import type { ProjectService } from "../../interface";
import type { Project } from "../../../../models/project/project";
import type { Projects } from "../../../../models/project/projects";

export enum ProjectSortField {
	Manager = "Manager",
	Id = "Id",
	StartDate = "StartDate",
	Reference = "EntityReference",
	Location = "Location",
	OrgUnitName = "OrgUnitName",
}

export enum ProjectSortOrder {
	Asc = "Asc",
	Desc = "Desc",
}

export class DefaultProjectService extends BaseService implements ProjectService {
	async getProjects(
		rootId: number,
		maskedOrgId = 0,
		pageSize = 100,
		startIndex = 0,
		sortField: ProjectSortField = ProjectSortField.Id,
		sortOrder: ProjectSortOrder = ProjectSortOrder.Asc,
		searchText?: string,
		includeCaption?: boolean,
		cancelToken?: CancelTokenSource,
		searchFields?: string[],
		portalCulure?: string,
	): Promise<Projects> {
		let searchFieldsQuery: string;
		if (searchFields && searchFields.length) {
			searchFieldsQuery = searchFields.reduce((accumulator, current, index) => {
				return `${accumulator}&searchFields[${index}]=${current}`;
			}, "");
		} else {
			searchFieldsQuery =
				"&searchFields[0]=Manager&searchFields[1]=StartDate&searchFields[2]=EntityReference&searchFields[3]=Location&searchFields[4]=OrgUnitName";
		}
		const searchQuery = searchText
			? `&searchText=${encodeURIComponent(
					searchText,
			  )}${searchFieldsQuery}&searchDateFormat=${portalCulure}`
			: "";
		const includeCaptionQuery = includeCaption ? "&IncludeCaptions=true" : "";
		const serviceUrl = `${await this.getEndpointUrl()}?rootOrgId=${rootId}&maskedOrgId=${maskedOrgId}&pageSize=${pageSize}&startIndex=${startIndex}&sortField=${sortField}&sortOrder=${sortOrder}${searchQuery}${includeCaptionQuery}`;
		const headers = await this.getAuthHeaders();
		const response = await withSignInGet<any>(serviceUrl, {
			...headers,
			cancelToken: cancelToken && cancelToken.token,
		});

		const projects: Projects = {
			resultCount: parseInt(response.parsedBody.ResultCount || "0"),
			projects: response.parsedBody.Projects.reduce(
				(accumulatedProjects: Project[], projectDto: any) => {
					if (projectDto.Id && projectDto.OrgUnitId && projectDto.EntityReference) {
						accumulatedProjects.push({
							id: projectDto.Id,
							orgUnitId: projectDto.OrgUnitId,
							reference: projectDto.EntityReference,
							manager: projectDto.Manager || undefined,
							location: projectDto.Location || undefined,
							startDate: moment(projectDto.StartDate).isValid()
								? moment(projectDto.StartDate).toDate()
								: undefined,
						} as Project);
					}
					return accumulatedProjects;
				},
				[],
			),
			captions:
				response.parsedBody.Captions &&
				response.parsedBody.Captions.map((captionDto: any) => ({
					propertyName: captionDto.PropertyName,
					displayText: captionDto.DisplayText,
					language: captionDto.Language,
				})),
		};
		return projects;
	}

	async getProjectsAll(orgId: number): Promise<Projects> {
		return this.getProjects(orgId, 999999);
	}
}
