import { useMemo } from "react";
import reactStringReplace from "react-string-replace";

export function useTextWithUrl(guidance: string | undefined) {
	return useMemo(() => {
		return (
			guidance &&
			reactStringReplace(
				guidance,
				/((?:mailto:|(?:news|(?:ht|f)tp(?:s?)):\/\/){1}\S+)/g,
				(match, i) => (
					<a href={match} key={`${match}${i}`} rel="noopener noreferrer" target="_blank">
						{match}
					</a>
				),
			)
		);
	}, [guidance]);
}
