import Dexie from "dexie";
import { QuestionnaireProgressState } from "../../../../models/questionnaire";
import { AttachmentParentType } from "../../../../models/attachments/Attachment";
import type { Portal } from "../../../../models/portal";
import type { OrgUnit } from "../../../../models/orgUnit";
import type {
	QuestionnaireTemplate,
	StoredQuestionnaireTemplate,
} from "../../../../models/questionnaire";
import type { Attachment } from "../../../../models/attachments/Attachment";

class BainDatabase extends Dexie {
	portals: Dexie.Table<Portal, string>;
	auth: Dexie.Table<{ CustomerKey: string; Token: string }, string>;
	orgUnits: Dexie.Table<{ customerKey: string; orgUnits: OrgUnit[] }, string>;
	questionnaireTemplates: Dexie.Table<QuestionnaireTemplate, string>;
	questionnaires: Dexie.Table<StoredQuestionnaireTemplate, string>;
	attachments: Dexie.Table<Attachment, string>;
	languages: Dexie.Table<{ customerKey: string; key: string; language: string }, string>;

	constructor() {
		super("BainDatabase");

		this.version(1).stores({
			portals: "[key+customerKey], title",
			auth: "CustomerKey",
			orgUnits: "customerKey",
		});

		this.version(2).stores({
			portals: "[key+customerKey], title, key, customerKey",
			auth: "CustomerKey",
			orgUnits: "customerKey",
			questionnaireTemplates: "questionnaire.templateId",
		});

		this.version(3).stores({
			portals: "[key+customerKey], title, key, customerKey",
			auth: "CustomerKey",
			orgUnits: "customerKey",
			questionnaireTemplates: "questionnaire.templateId",
			questionnaires: "questionnaire.id, questionnaire.portalKey, status.questionnaireState",
		});

		this.version(4).stores({
			portals: "[key+customerKey], title, key, customerKey",
			auth: "CustomerKey",
			orgUnits: "customerKey",
			questionnaireTemplates: "questionnaire.templateId",
			questionnaires:
				"questionnaire.id, questionnaire.portalKey, status.questionnaireState, [questionnaire.portalKey+status.questionnaireState]",
		});

		this.version(5)
			.stores({
				attachments: "id, recordId, fieldGuid",
			})
			.upgrade(async (tx) => {
				let attachments: Attachment[] = [];
				await tx
					.table("questionnaires")
					.toCollection()
					.each((record: any) => {
						const recordAttachments = record.attachments.map((a: any) => {
							return {
								...a,
								recordId: a.questionnaireId,
							};
						});
						attachments = attachments.concat(recordAttachments);
						record.attachments = null;
					});
				await tx.table("attachments").bulkPut(attachments);
			});

		this.version(6)
			.stores({})
			.upgrade(async (tx) => {
				const recordIds: string[] = [];
				await tx
					.table("questionnaires")
					.toCollection()
					.each((record: any) => {
						recordIds.push(record.questionnaire.id);
					});

				if (recordIds.length > 0) {
					await tx.table("attachments").where("recordId").noneOf(recordIds).delete();
				} else {
					await tx.table("attachments").clear();
				}
			});

		this.version(7)
			.stores({
				attachments: "id, recordId, parentGuid, parentType",
			})
			.upgrade((tx) => {
				tx.table("attachments")
					.toCollection()
					.modify((attachment) => {
						attachment.parentType = attachment.fieldGuid
							? AttachmentParentType.Field
							: AttachmentParentType.Record;
						attachment.parentGuid = attachment.fieldGuid;
						delete attachment.fieldGuid;
					});
			});

		this.version(8)
			.stores({})
			.upgrade(async (tx) => {
				const recordIds: string[] = [];
				await tx
					.table("questionnaires")
					.toCollection()
					.each((record: any) => {
						recordIds.push(record.questionnaire.id);
					});

				if (recordIds.length > 0) {
					await tx.table("attachments").where("recordId").noneOf(recordIds).delete();
				} else {
					await tx.table("attachments").clear();
				}
			});

		this.version(9)
			.stores({
				questionnaires:
					"questionnaire.id, " +
					"questionnaire.portalKey, " +
					"status.questionnaireState," +
					"userId, " +
					"[questionnaire.portalKey+status.questionnaireState+userId], " +
					"[status.questionnaireState+userId], " +
					"[status.questionnaireState+questionnaire.portalKey]",
			})
			.upgrade((tx) => {
				return tx
					.table("questionnaires")
					.toCollection()
					.modify((questionnaire: QuestionnaireTemplate) => {
						if (questionnaire.status) {
							if (!questionnaire.status.questionnaireState) {
								questionnaire.status.questionnaireState =
									QuestionnaireProgressState.InProgress;
							}
						} else {
							questionnaire.status = {
								displayValidationMessage: false,
								errors: [],
								isSubmittingInProgress: false,
								isValidated: false,
								questionnaireState: QuestionnaireProgressState.InProgress,
							};
						}
						if (!questionnaire.userId) {
							questionnaire.userId = "";
						}
					});
			});

		this.version(10)
			.stores({
				languages: "[key+customerKey]",
			})
			.upgrade((tx) => {
				return tx
					.table("portals")
					.toArray()
					.then((portals: Portal[]) => {
						return tx.table("languages").bulkPut(
							portals.map((portal) => {
								let language =
									window.localStorage.getItem("userLanguage") || "en-gb";
								if (
									!portal.userLanguages.some(
										(portalLanguage: { idString: string }) =>
											portalLanguage.idString === language,
									)
								) {
									language = "en-gb";
								}
								return {
									key: portal.key.toLowerCase(),
									customerKey: portal.customerKey.toLowerCase(),
									language,
								};
							}),
						);
					});
			});

		this.portals = this.table("portals");
		this.auth = this.table("auth");
		this.orgUnits = this.table("orgUnits");
		this.questionnaireTemplates = this.table("questionnaireTemplates");
		this.questionnaires = this.table("questionnaires");
		this.attachments = this.table("attachments");
		this.languages = this.table("languages");

		this.requestPersistentStorage();
	}

	async persist() {
		return navigator.storage && navigator.storage.persist && navigator.storage.persist();
	}

	async isStoragePersisted() {
		return navigator.storage && navigator.storage.persisted && navigator.storage.persisted();
	}

	requestPersistentStorage() {
		this.isStoragePersisted().then(async (isPersisted) => {
			if (isPersisted) {
				// Log-Message "Storage successfully persisted."
			} else if (await this.persist()) {
				// Log-Message "Storage successfully persisted."
			} else {
				// Log-Message "Failed to make storage persistant."
			}
		});
	}
}

export const localData = new BainDatabase();
