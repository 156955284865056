import { NumberFieldValidator } from "../numberFieldValidator";
import type { BehaviouralField } from "../../models/fields/BehaviouralField";
import type { BaseNumberField } from "../../models/fields/NumberField";

export class BehaviouralFieldValidator {
	behaviouralField: BehaviouralField;
	messages: string[];

	constructor(behaviouralField: BehaviouralField) {
		this.behaviouralField = behaviouralField;
		this.messages = [];
	}

	isFieldValid(safeValue: string, unsafeValue: string): boolean {
		this.messages = [];

		return (
			this.isBehaviouralSafeUnSafeValid(safeValue, this.behaviouralField.safeField) &&
			this.isBehaviouralSafeUnSafeValid(unsafeValue, this.behaviouralField.unSafeField)
		);
	}

	isBehaviouralSafeUnSafeValid(
		safeUnSafeValue: string,
		behaviouralField: BaseNumberField,
	): boolean {
		const numberValidator = new NumberFieldValidator(behaviouralField);
		const validationResult = numberValidator.isFieldValid(
			safeUnSafeValue,
			behaviouralField.pattern,
			false,
			false,
		);
		this.messages = numberValidator.messages;
		return validationResult;
	}
}
