import { get, withSignInGet, withSignInPut } from "../../../utilities/http";
import { BaseService } from "../../../baseService";
import type { AuthService } from "../../interface";

const APIKEY = "9EB983B9-3CD9-463F-80B9-2FC08C5C1160";

export class DefaultAuthService extends BaseService implements AuthService {
	public async authenticate(customerKey: string, portalKey: string): Promise<any> {
		const authUrl = `${await this.getEndpointUrl()}Auth/Token?key=${portalKey}`;

		const response = await get<any>(authUrl, {
			headers: {
				"api-key": APIKEY,
				"portal-key": portalKey,
				"customer-key": customerKey,
				"Cache-Control": "no-cache",
				Pragma: "no-cache",
				Expires: "0",
			},
		});

		return response.parsedBody;
	}

	public async getPasswordRules(customerKey: string, portalKey: string): Promise<any> {
		const passwordRulesUrl = `${await this.getEndpointUrl()}User/PasswordRules?key=${portalKey}`;

		const response = await withSignInGet<any>(passwordRulesUrl, {
			headers: {
				...(await this.getAuthHeadersInit()),
				"api-key": APIKEY,
				"customer-key": customerKey,
			},
		});

		return response.parsedBody;
	}

	public async changePassword(
		customerKey: string,
		portalKey: string,
		data: { OldPassword: string; NewPassword: string },
	): Promise<any> {
		let query = "User/ChangePassword?";
		query += `key=${portalKey}`;

		const serviceUrl = (await this.getEndpointUrl()) + query;

		const response = await withSignInPut<any>(
			serviceUrl,
			{
				headers: {
					...(await this.getAuthHeadersInit()),
					"api-key": APIKEY,
					"customer-key": customerKey,
				},
			},
			{ OldPassword: data.OldPassword, newPassword: data.NewPassword },
		);

		return response.parsedBody;
	}
}
