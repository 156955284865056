import { DefaultHealthService } from "../default";
import { OnlineStatus } from "./OnlineStatus";

export class HealthMonitorSingleton {
	private static instance: HealthMonitorSingleton;
	private status: OnlineStatus = OnlineStatus.Unknown;
	private service: DefaultHealthService;
	private interval: number;
	private listeners: ((status: OnlineStatus) => void)[] = [];

	private constructor(interval: number) {
		this.interval = interval;
		this.service = new DefaultHealthService({
			subdomain: "TestConnection",
		});
	}

	static getInstance() {
		if (!HealthMonitorSingleton.instance) {
			HealthMonitorSingleton.instance = new HealthMonitorSingleton(
				parseInt(process.env.REACT_APP_HEALTH_CHECK_INTERVAL || "500"),
			);
			HealthMonitorSingleton.instance.checkConnection();
			window.setInterval(
				HealthMonitorSingleton.getInstance().checkConnection,
				HealthMonitorSingleton.getInstance().interval,
			);
		}
		return HealthMonitorSingleton.instance;
	}

	private async checkConnection() {
		const instance = HealthMonitorSingleton.getInstance();
		const connectionTimeout =
			instance.interval - 500 > 0 ? instance.interval - 500 : instance.interval;
		if ((await instance.service.checkAPIconnection(connectionTimeout)) === true) {
			instance.status = OnlineStatus.Available;
		} else {
			instance.status = OnlineStatus.Unavailable;
		}
		instance.notifyListeners(instance.status);
	}

	async checkIfOnline(): Promise<OnlineStatus> {
		const instance = HealthMonitorSingleton.getInstance();
		await instance.checkConnection();

		return instance.status;
	}

	isAPIavailable(): OnlineStatus {
		return HealthMonitorSingleton.getInstance().status;
	}

	registerListener(observer: (status: OnlineStatus) => void) {
		HealthMonitorSingleton.getInstance().listeners.push(observer);
	}

	unregisterListener(observer: (status: OnlineStatus) => void) {
		HealthMonitorSingleton.getInstance().listeners =
			HealthMonitorSingleton.getInstance().listeners.filter((obs) => obs !== observer);
	}

	registerWindowEventListeners() {
		window.addEventListener("online", this.goOnline);
		window.addEventListener("offline", this.goOffline);
	}

	unRegisterWindowEventListeners() {
		window.removeEventListener("online", this.goOnline);
		window.removeEventListener("offline", this.goOffline);
	}

	private goOnline() {
		HealthMonitorSingleton.getInstance().checkConnection();
	}

	private goOffline() {
		const instance = HealthMonitorSingleton.getInstance();
		instance.status = OnlineStatus.Unavailable;
		instance.notifyListeners(OnlineStatus.Unavailable);
	}

	private notifyListeners(status: OnlineStatus) {
		HealthMonitorSingleton.getInstance().listeners.forEach((listener) => listener(status));
	}
}
