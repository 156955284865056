import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { PageLayout } from "../../layouts/PageLayout";
import { usePortalPath } from "../../../helpers/UsePortalPath";
import { PasswordForm } from "./components/PasswordForm/PasswordForm";
import type { State } from "../../../state";
import type { Portal } from "../../../models/portal";
import "./PasswordPage.styles.scss";

export const Password = () => {
	const { customerKey, portalKey } = useParams<{ customerKey: string; portalKey: string }>();
	const portal = useSelector<State, Portal | undefined>((s) =>
		s.portal.portals.find(
			(p) => p.key === portalKey.toLowerCase() && p.customerKey === customerKey.toLowerCase(),
		),
	);
	const portalPath = usePortalPath();

	return (
		<PageLayout
			backTo={`${portalPath}settings`}
			portal={portal}
			title={portal ? portal.title : ""}
		>
			<div className="she-password-page-column">
				<PasswordForm />
			</div>
		</PageLayout>
	);
};
