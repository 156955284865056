export interface Actions {
	list: Action[];
	totalDataCount: number;
	sortProperties: ActionSortPropertyInfo[];
}

export interface Action {
	ActionColorCode?: string;
	ActionHeader: string;
	ActionId: number;
	ActionSource?: string;
	Category?: number | null;
	CompleteDate?: string;
	CompletePercent: number;
	CustomerId: number;
	DueDate: string;
	DueDateColourCode?: string;
	DueDateStatus: string;
	Info?: ActionInfo[];
	ModuleDescrip?: string;
	ModuleId: number;
	OrgUnitId: number;
	OrgUnitName: string;
	Priority: number;
	PriorityText: string;
	PriorityColourCode?: string;
	RecordReference?: string;
	IsOverdue?: boolean;
}

export interface ActionFull extends Action {
	ActionCategory?: number;
	ActionCategoryText?: string;
	ActionSubCategory?: number;
	ActionSubCategoryText?: string;
	ActionDetail?: string;
	Attachments: ActionAttachment[];
	CurrentStatus: string;
	DateCreated: string;
	ForUser: ActionUser;
	NoteComment?: string;
	RaisingUser: ActionUser;
	Info?: ActionInfoFull[];
	IsCommentMandatory: number;
	OriginalDueDate: string;
	TimeTaken?: number;
	LatestComment: ResponseActionComment | null;
}

export interface ActionAttachment {
	AttachmentType: "Link" | "File" | "NewFile";
	DateCreated: string;
	FileDescription?: string;
	FileName?: string;
	FileSize?: number;
	Identifier: string;
	Link?: string;
	File?: File;
}

export interface ActionUser {
	Email: string;
	FullName: string;
	Id: boolean;
	OrgUnit?: string;
}

export interface ActionInfo {
	PropertyName: string;
	Caption: string;
}

export interface ActionInfoFull extends ActionInfo {
	DefaultValue: string | null;
	Guidance: string | null;
	IsCommentMandatory: number;
	IsMandatory: boolean;
	Visible: boolean;
	TypeOfDisplay: ActionGuidanceTypeOfDisplay;
}
export interface ActionSortPropertyInfo {
	propertyName: string;
	caption: string;
	type: ActionPropertyType;
	order: ActionSortOrder;
}

export enum ActionPropertyType {
	Priority,
	Percent,
	Date,
	String,
}

export enum ActionSortOrder {
	Asc = "Asc",
	Desc = "Desc",
}

export enum ActionGuidanceTypeOfDisplay {
	Popup = 1,
	Static = 2,
}

export type ActionDictionaryItem = {
	key: number;
	value: string;
	orgUnitIds: number[];
	parentId?: number | null;
};

export type ActionDictionaries = {
	priorityDictionary: ActionDictionaryItem[];
	categoryDictionary: ActionDictionaryItem[];
	subCategoryDictionary: ActionDictionaryItem[];
};

export enum ActionStateAtTimeOfComment {
	NotStarted = 1,
	InProgress = 2,
	Closed = 3,
	Completed = 4,
	Unknown = 5,
}

export enum ActionCommentStatus {
	Deleted = 0,
	Active = 1,
	Edited = 2,
}

export interface ResponseActionComment {
	ActionStateAtTimeOfComment: number;
	Content: string;
	CreatedDate: string;
	DeletedBy?: {
		FullName: string | null;
		UserId: number | null;
	};
	Id: string;
	LastEditedBy?: {
		FullName: string | null;
		UserId: number | null;
	};
	LatestDate: string;
	Owner: {
		FullName: string;
		UserId: number;
	};
	Status: number;
	UserCanDelete: boolean;
	UserCanEdit: boolean;
}

export interface ActionComment {
	actionStatus: {
		key: string;
		label: string;
		value: number;
	};
	createdDate: {
		formattedNoTime: string;
		formatted: string;
		unformatted: string;
	};
	content: string;
	deletedBy?: string;
	editedBy?: string;
	id: string;
	latestDate: {
		formattedNoTime: string;
		formatted: string;
		unformatted: string;
	};
	owner: {
		name: string;
		id: number;
	};
	status: {
		key: string;
		label: string;
		value: number;
	};
	userCanEdit: boolean;
	userCanDelete: boolean;
}

export enum ActionCommentMandatory {
	Never = 0,
	Always = 1,
	OnCompletion = 2,
}
