import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { updateInitialSetupFlag } from "../../../state";
import { Button } from "../input/Button";
import "./DownloadForOfflineUse.styles.scss";

export const DownloadForOfflineUse = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { portalKey } = useParams<{ portalKey: string }>();

	const handleClick = () => {
		dispatch(updateInitialSetupFlag(portalKey.toLowerCase(), false));
	};

	return (
		<div className="download-for-offline-use">
			<Button onClick={handleClick}>{t("display:buttonDownloadForOffline")}</Button>
		</div>
	);
};
