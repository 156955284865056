import { useMemo } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { useRouteMatch } from "react-router-dom";
import { DefaultActionsService } from "../../../../../../services/actions";
import { CancelablePromise } from "../../../../../../services/utilities/CancelablePromise";
import { switchMatchingLanguageCode } from "../../../../../../helpers/LanguageCodeHelper";
import { useFormatActionComment } from "./useFormatActionComment";
import type { ActionComment, ResponseActionComment } from "../../../../../../models/action";

export const useActionComments = (actionId: number) => {
	const { i18n } = useTranslation();
	const language = switchMatchingLanguageCode(i18n.language);
	const formatActionComment = useFormatActionComment();

	const service = useMemo(
		() =>
			new DefaultActionsService({
				subdomain: "Action",
			}),
		[],
	);

	const isViewingAction = !!useRouteMatch("/:customerKey/p/:portalKey/actions/:action/:actionId");

	const {
		data: comments,
		isLoading,
		refetch,
	} = useQuery(
		["trainingRecordsCount", actionId],
		() => {
			const cancelTokenSource = axios.CancelToken.source();

			const getComments = new CancelablePromise<ResponseActionComment[]>((resolve, reject) =>
				service
					.getActionComments(actionId, language)
					.then((r) => resolve(r))
					.catch((e) => reject(e)),
			);

			getComments.cancel = () => {
				cancelTokenSource.cancel();
			};

			return getComments;
		},
		{ enabled: isViewingAction },
	);

	if (!comments?.length) {
		return { comments: [], isLoading };
	}

	return {
		comments: comments.map((comment): ActionComment => formatActionComment(comment)),
		isLoading,
		refetch,
	};
};
