import { useState, useEffect, useRef } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { AnimationWrapper } from "../../animation/AnimationWrapper";
import { AnimationSlideFade } from "../../animation/AnimationSlideFade";
import { QRScanner } from "../../QRScanner/QRScanner";

interface Props {
	name: string;
	value: string;
	hint: string;
	maxLength: number | undefined;
	rows: number;
	guid: string;
	isMandatory: boolean;
	isInvalid: boolean;
	isNotApplicable: boolean;
	onChange: (value?: string) => void;
	onBlur: () => void;
	autoCompleteValue?: string;
	isScannable: boolean;
}

export const TextInputPresentation = ({
	guid,
	hint,
	isInvalid,
	isMandatory,
	isNotApplicable,
	isScannable,
	maxLength,
	name,
	onBlur,
	onChange,
	rows,
	value,
	autoCompleteValue,
}: Props) => {
	const inputRef = useRef<HTMLTextAreaElement | null>(null);
	const [measureHeight, setMeasureHeight] = useState(false);

	useEffect(() => {
		if (isNotApplicable && !measureHeight) {
			setMeasureHeight(true);
		} else if (!isNotApplicable && measureHeight) {
			setMeasureHeight(false);
		}
	}, [isNotApplicable, measureHeight]);

	const handleChange = (decodedResult: string) => {
		onChange(decodedResult);
		if (inputRef.current) {
			inputRef.current.focus();
		}
	};

	return (
		<AnimationWrapper>
			{!isNotApplicable && (
				<AnimationSlideFade key={guid} measure={measureHeight}>
					<div
						className="she-components-control-hint"
						data-testid="hint-text"
						id={`${guid}-hint`}
					>
						{hint}
					</div>
					<div className="text-field-scanner-container">
						<TextareaAutosize
							aria-describedby={`${guid}-hint`}
							aria-invalid={isInvalid}
							aria-labelledby={`${guid}-label ${guid}-description`}
							aria-required={isMandatory}
							autoComplete={autoCompleteValue}
							className="she-components-text-input"
							data-testid="text-input"
							disabled={isNotApplicable}
							id={guid}
							maxLength={maxLength}
							name={name}
							onBlur={onBlur}
							onChange={(e) => onChange(e.target.value)}
							ref={inputRef}
							rows={rows}
							value={value}
						/>

						{isScannable && <QRScanner onResult={handleChange} />}
					</div>
				</AnimationSlideFade>
			)}
		</AnimationWrapper>
	);
};
