import { t } from "i18next";
import { BaseService } from "../../../baseService";
import { EntityApiUrl, EntitySelectServiceEndpointMap } from "../../../../models/questionnaire";
import { withSignInGet } from "../../../utilities/http";
import type {
	Entity,
	Entities,
	EntityProperty,
	EntityPropertyInfo,
} from "../../../../models/entity";
import type { EntityService } from "../../interface";
import type { CancelTokenSource } from "axios";

const UNMASKED_ORG_UNIT_ENDPOINTS: string[] = [EntitySelectServiceEndpointMap.Submission];

export class DefaultEntityService extends BaseService implements EntityService {
	buildEntityQuery(
		maskedOrgUnitId: number,
		portalKey: string,
		userCurrentLanguage: string,
		searchText: string,
		searchDateFormat: string,
		pageSize: number,
		startIndex: number,
		sortField?: string,
		sortOrder?: string,
		includeCurrentUser?: boolean,
		propertyName: string = EntitySelectServiceEndpointMap.ForUser,
		selectedOrgUnit?: string,
	) {
		let query = `/${this.generateURLPrefix(propertyName)}?`;

		query += `${
			UNMASKED_ORG_UNIT_ENDPOINTS.includes(propertyName) ? "OrgUnit" : "MaskedOrgUnitId"
		}=${maskedOrgUnitId}`;
		query += `&SearchText=${encodeURIComponent(searchText)}`;
		query += `&SearchDateFormat=${searchDateFormat}`;
		query += `&UserCurrentLanguage=${userCurrentLanguage}`;
		query += `&StartIndex=${startIndex}`;
		query += `&PageSize=${pageSize}`;
		query += `&PortalKey=${portalKey}`;
		query += sortField ? `&SortField=${sortField}` : "";
		query += sortOrder ? `&SortOrder=${sortOrder}` : "";
		query += includeCurrentUser ? `&IncludeCurrentUser=${includeCurrentUser}` : "";
		query +=
			propertyName === EntitySelectServiceEndpointMap.Submission ? "&AccessType=Assign" : "";
		query += selectedOrgUnit ? `&SelectedOrgUnit=${selectedOrgUnit}` : "";

		return query;
	}

	generateURLPrefix(propertyName: string) {
		switch (propertyName) {
			case EntitySelectServiceEndpointMap.Submission:
				return EntityApiUrl.Assigned;
			default:
				return EntityApiUrl.Default;
		}
	}

	async getEntitySelectCollection(
		maskedOrgUnitId: number,
		portalKey: string,
		sortField?: string,
		sortOrder?: string,
		userCurrentLanguage = "EnGB",
		searchText = "",
		searchDateFormat = "Uk",
		pageSize = 20,
		startIndex = 0,
		cancelToken?: CancelTokenSource,
		includeCurrentUser?: boolean,
		urlLabel?: string,
		selectedOrgUnit?: string,
	) {
		const query = this.buildEntityQuery(
			maskedOrgUnitId,
			portalKey,
			userCurrentLanguage,
			searchText,
			searchDateFormat,
			pageSize,
			startIndex,
			sortField,
			sortOrder,
			includeCurrentUser,
			urlLabel,
			selectedOrgUnit,
		);

		const serviceUrl = `${await this.getEndpointUrl()}${query}`;
		const headers = await this.getAuthHeaders();
		const response = await withSignInGet<any>(serviceUrl, {
			...headers,
			cancelToken: cancelToken && cancelToken.token,
		});

		const hasSheAttachments =
			response.data.Data &&
			(response.data.Data as any[]).some(
				(d) => d.SheAttachments && d.SheAttachments.length > 0,
			);

		if (hasSheAttachments) {
			response.data.FieldInfo.push({
				PropertyName: "SheAttachments",
				Caption: t("display:labelAttachments"),
				Visible: true,
			});
		}

		return mapEntitySelectCollection(response.data);
	}
}

export const mapEntitySelectCollection = (data: any): Entities => {
	const properties = mapEntityProperties(data.FieldInfo);
	const entitySelectCollection = {
		totalDataCount: data.TotalDataCount,
		sortProperties: properties,
		list: mapEntities(data.Data, properties),
	} as Entities;
	return entitySelectCollection;
};

export const mapEntityProperties = (fieldInfo: any): EntityProperty[] => {
	return fieldInfo.map((fi: any) => ({
		propertyName: fi.PropertyName,
		caption: fi.Caption,
	}));
};

export const mapEntities = (data: any, properties: EntityProperty[]): Entity[] => {
	return data.map((d: any) => ({
		id: d.ModuleId || d.Id,
		info: mapEntityPropertyInfos(d, properties),
	}));
};

const EXCLUDED_PROPERTIES = ["Id"];

export const mapEntityPropertyInfos = (
	dataItem: any,
	properties: EntityProperty[],
): EntityPropertyInfo[] => {
	const propertyInfo: EntityPropertyInfo[] = [];
	Object.keys(dataItem).forEach((key) => {
		const property = properties.find((p) => p.propertyName === key);
		if (property && !EXCLUDED_PROPERTIES.includes(key)) {
			propertyInfo.push({
				propertyName: key,
				detail: dataItem[key],
				title: property.caption,
			});
		}
	});
	return propertyInfo;
};
