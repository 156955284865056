import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import type { State } from "../../state";

export const usePreventBrowserReload = (preventReload: boolean) => {
	const forceLoginModal = useSelector<State, boolean>((state) => state.auth.forcingLoginModal);

	const preventBrowserReload = useCallback((event: BeforeUnloadEvent) => {
		event.preventDefault();
		event.returnValue = "";
	}, []);

	useEffect(() => {
		if (!forceLoginModal && preventReload) {
			window.addEventListener("beforeunload", preventBrowserReload);
		} else {
			window.removeEventListener("beforeunload", preventBrowserReload);
		}
		return () => window.removeEventListener("beforeunload", preventBrowserReload);
	}, [preventReload, preventBrowserReload, forceLoginModal]);
};
