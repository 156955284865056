import { useTranslation } from "react-i18next";
import { IllustratedThemedModal } from "../modals/IllustratedThemedModal";
import { Spinner } from "../generic/Spinner";

interface Props {
	show?: boolean;
	showEntryAnimation?: boolean;
	text?: string;
	withHistory?: boolean;
}

export const Loading = ({ show = true, showEntryAnimation, text, withHistory }: Props) => {
	const { t } = useTranslation();

	return (
		<IllustratedThemedModal
			blockUi
			show={show}
			showEntryAnimation={showEntryAnimation}
			text={text || t("global:loading")}
			withHistory={withHistory}
		>
			<Spinner />
		</IllustratedThemedModal>
	);
};
