import type { FieldType } from "../questionnaire";
import type { Entity } from "../entity";
import type { BaseField } from "./BaseField";

export const AUTHENTICATED_ONLY_PROPERTIES = ["ForUser"];
export interface EntitySelectField
	extends BaseField<
		FieldType.EntitySelect | FieldType.EntitySelectWithDefault,
		Entity | "UseDefaultValue"
	> {
	propertyName: string;
}
