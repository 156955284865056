import { useTranslation } from "react-i18next";
import { ThumbnailList } from "../../../../../components/ThumbnailList";
import { FileInput } from "../../../../../components/input/FileInput";
import type { AttachmentParentType } from "../../../../../../models/attachments/Attachment";
import type { LocalSectionId } from "../../../../../../models/questionnaire";

interface Props {
	questionnaireId: string;
	parentGuid?: string;
	parentType: AttachmentParentType;
	subModuleId?: LocalSectionId;
}

export const SubmoduleAttachmentInput = ({ ...props }: Props) => {
	const { t } = useTranslation();

	return (
		<div className="she-components-control">
			<div className="she-components-control-question">{t("display:labelAttachments")}</div>
			<ThumbnailList {...props} displayNoAttachmentsMessage={false} />
			<FileInput validateAttachmentsLimit {...props} />
		</div>
	);
};
