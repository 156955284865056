export enum ErrorType {
	NotEnabled,
	CannotGetPosition,
}

export const GetPosition = (
	errorCallback: (type: ErrorType) => void,
	getPositionCallback: (lat: number, long: number) => void,
): void => {
	if (!navigator.geolocation) {
		errorCallback(ErrorType.CannotGetPosition);
	} else {
		navigator.geolocation.getCurrentPosition(
			(pos) => {
				getPositionCallback(pos.coords.latitude, pos.coords.longitude);
			},
			(e: GeolocationPositionError) => {
				e.code === e.PERMISSION_DENIED
					? errorCallback(ErrorType.NotEnabled)
					: errorCallback(ErrorType.CannotGetPosition);
			},
			{ timeout: 30000 },
		);
	}
};
