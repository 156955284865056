import { OrgUnitActionTypes } from "./actions/types";
import type { Reducer } from "redux";
import type { OrgUnitState } from "./model";
import type { OrgUnitAction } from "./actions/definitions";

const initialState: OrgUnitState = {
	orgUnits: [],
};

export const orgunitReducer: Reducer<OrgUnitState, OrgUnitAction> = (
	state = initialState,
	action,
) => {
	switch (action.type) {
		case OrgUnitActionTypes.Loaded:
			return {
				...state,
				orgUnits: [...action.orgUnits],
			};
		default: {
			return state;
		}
	}
};
