import { Fragment } from "react";
import "./Breadcrumb.styles.scss";

export type BreadcrumbDataId = string | number;

export interface BreadcrumbData<T extends BreadcrumbDataId> {
	id: T;
	name: string;
}

interface Props<T extends BreadcrumbDataId = number> {
	data: BreadcrumbData<T>[];

	readOnly?: boolean;
	separator?: string;

	onSelect?: (id: T) => void;
}

export const Breadcrumb = ({ data, onSelect, readOnly, separator }: Props) => {
	return (
		<div
			className={`she-breadcrumbs-container ${
				data.length === 0 && "she-breadcrumbs-container-empty"
			}`}
		>
			{data.map((d, index) =>
				index === data.length - 1 ? (
					<span
						className={readOnly ? "she-breadcrumbs-crumb" : "she-breadcrumbs-current"}
						key={d.id}
					>
						{d.name}
					</span>
				) : (
					<Fragment key={d.id}>
						{readOnly ? (
							<span className="she-breadcrumbs-crumb">{d.name}</span>
						) : (
							<span
								className="she-breadcrumbs__button"
								onClick={() => onSelect?.(d.id)}
								onKeyDown={(event) => {
									if (event.key === "Enter" || event.key === " ") {
										onSelect?.(d.id);
									}
								}}
								role="button"
								tabIndex={0}
							>
								{d.name}
							</span>
						)}
						<span role="separator">{` ${separator || "/"} `}</span>
					</Fragment>
				),
			)}
		</div>
	);
};
