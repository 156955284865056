import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AttachmentsDisplayPresentation } from "./AttachmentsDisplay.presentation";
import type { State } from "../../../state";
import type { AttachmentParentType } from "../../../models/attachments/Attachment";

interface Props {
	parentGuid: string;
	questionnaireId: string;
	fieldId: number;
	isMandatory: boolean;
	hasValidationErrors: boolean;
	validationErrors: string[];
	parentType: AttachmentParentType;
}

export const AttachmentsDisplayContainer = ({
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	fieldId,
	hasValidationErrors,
	isMandatory,
	parentGuid,
	parentType,
	questionnaireId,
	validationErrors,
}: Props) => {
	const { t } = useTranslation();
	const attachments = useSelector<State, number>(
		(state) =>
			state.recordAttachments.attachments.filter(
				(a) => a.recordId === questionnaireId && a.parentGuid === parentGuid,
			).length,
	);

	return (
		<AttachmentsDisplayPresentation
			attachmentsTitle={t("display:labelAttachments")}
			hasValidationErrors={hasValidationErrors}
			isMandatory={isMandatory}
			parentGuid={parentGuid}
			parentType={parentType}
			questionnaireId={questionnaireId}
			showAttachments={attachments > 0}
			validationErrors={validationErrors}
		/>
	);
};
