import {
	init,
	makeBrowserOfflineTransport,
	makeFetchTransport,
	reactRouterV5BrowserTracingIntegration,
} from "@sentry/react";
import { ConfigPropertyKey, getConfigurationOption } from "../../helpers/ConfigHelper";
import { browserHistory } from "../../ui/utilities/History";
import { getApplicationId } from "../../ui/utilities/ApplicationId";
import packageJson from "../../../package.json";

init({
	dsn: await getConfigurationOption(ConfigPropertyKey.SentryDsn),
	integrations: [reactRouterV5BrowserTracingIntegration({ history: browserHistory })],
	release: packageJson.version,
	environment: await getConfigurationOption(ConfigPropertyKey.SentryEnvironment),
	initialScope: {
		user: {
			id: getApplicationId(),
		},
	},
	transport: makeBrowserOfflineTransport(makeFetchTransport),
	beforeSend: async (event) => {
		if (
			(await getConfigurationOption(ConfigPropertyKey.SentryEnvironment)) ===
			"sentry-env-localhost"
		) {
			return null;
		}
		return event;
	},
});
