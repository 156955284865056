import { useSelector } from "react-redux";
import { NotApplicableComments } from "../../../../../models/questionnaire";
import { QuestionnaireFieldCommentsPresentation } from "./QuestionnaireFieldComments.presentation";
import type { State } from "../../../../../state";
import type { Field } from "../../../../../models/fields/Field";

interface Props {
	field: Field;
}

export const QuestionnaireFieldCommentsContainer = ({ field }: Props) => {
	const notApplicableComments = useSelector<State, NotApplicableComments | undefined>(
		// eslint-disable-next-line @typescript-eslint/no-extra-non-null-assertion
		(state) => state.questionnaire.questionnaire!!.notApplicableComments,
	);

	const isVisible = () => {
		const visibilityRules = {
			hasContent:
				field.comments.value !== undefined &&
				field.comments.value.length > 0 &&
				field.comments.isEnabled,
			isNotApplicable: field.isNotApplicable,
			displayHidden:
				field.isNotApplicable && notApplicableComments !== NotApplicableComments.Hidden,
		};
		return Object.values(visibilityRules).includes(true);
	};

	const isMandatory =
		field.isNotApplicable && notApplicableComments === NotApplicableComments.Required;

	const isCommentsDisabled =
		field.isNotApplicable && notApplicableComments === NotApplicableComments.Hidden;

	return (
		<QuestionnaireFieldCommentsPresentation
			field={field}
			isCommentsDisabled={isCommentsDisabled}
			isMandatory={isMandatory}
			isVisible={isVisible()}
			notApplicableComments={notApplicableComments}
		/>
	);
};
