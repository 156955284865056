import { t } from "i18next";
import type { SignatureField, SimplifiedSignatureField } from "../../models/fields/SignatureField";

export class SignatureFieldValidator {
	signatureField: SignatureField | SimplifiedSignatureField;
	messages: string[];

	constructor(signatureField: SignatureField | SimplifiedSignatureField) {
		this.signatureField = signatureField;
		this.messages = [];
	}

	isFieldValid = (value?: string): boolean => {
		this.messages = [];

		if (this.signatureField.isMandatory) {
			if (typeof value === "string") {
				if (!this.isSignatureValid(value)) {
					this.messages.push(t("validation:mandatoryFieldMessage"));
					return false;
				}
			} else if (value === undefined || value === null) {
				this.messages.push(t("validation:mandatoryFieldMessage"));
				return false;
			}
		} else if (typeof value === "string" && value.length > 0) {
			if (!this.isSignatureValid(value)) {
				this.messages.push(t("validation:invalidSignatureMessage"));
				return false;
			}
		}

		return true;
	};

	isSignatureValid = (value: string): boolean => {
		const requiredText = "base64,";
		return !(value.length < requiredText.length || value.indexOf(requiredText) < 1);
	};
}
