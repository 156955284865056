import { ReactComponent as DropdownChevron } from "../../../assets/svg/arrow-down-1.svg";

interface Props {
	defaultValue?: string;
	name: string;
	value?: string;
	data: any[];
	guid: string;
	isMandatory: boolean;
	isTopOptionTitle: boolean;
	topOptionTitle?: string;
	onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
	onBlur?: (event: React.FocusEvent<HTMLSelectElement>) => void;
	ariaLabelledby?: string;
}

export const DropdownPresentation = ({
	data,
	guid,
	isMandatory,
	isTopOptionTitle,
	name,
	onChange,
	defaultValue,
	onBlur,
	topOptionTitle,
	value,
	ariaLabelledby,
}: Props) => {
	return (
		<div className="she-components-dropdown">
			<select
				aria-labelledby={ariaLabelledby}
				defaultValue={defaultValue}
				id={guid}
				name={name}
				onBlur={onBlur}
				onChange={onChange}
				required={isMandatory}
				value={value}
			>
				{isTopOptionTitle ? <option value="-1">{topOptionTitle}</option> : ""}
				{data.map((x) => (
					<option key={x.key} value={x.key}>
						{x.value}
					</option>
				))}
			</select>
			<div className="she-components-control-icon">
				<DropdownChevron />
			</div>
		</div>
	);
};
