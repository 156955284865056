import { logException } from "../../services/log";
import type { ActionSortPropertyInfo } from "../../models/action";
import type { RecordSortPropertyInfo } from "../../models/record";

export interface SavedSortSettings {
	module: string;
	propertyInfo: RecordSortPropertyInfo;
}

export interface SavedSortActionSettings {
	propertyInfo: ActionSortPropertyInfo;
}

export const loadRecordSortForModule = (module: string) => {
	try {
		const savedSettings = window.localStorage.getItem("moduleRecordSort");
		if (savedSettings) {
			const sortSetting = JSON.parse(savedSettings).find(
				(s: SavedSortSettings) => s.module === module,
			);
			return (sortSetting as SavedSortSettings) || null;
		}
		return null;
	} catch (e) {
		logException(e);
		return null;
	}
};

export const saveRecordSortForModule = (sortSettings: SavedSortSettings) => {
	try {
		const savedSettings = window.localStorage.getItem("moduleRecordSort");
		let savedSortSettings = [];
		if (savedSettings) {
			savedSortSettings = JSON.parse(savedSettings).filter(
				(s: SavedSortSettings) => s.module !== sortSettings.module,
			);
		}
		savedSortSettings.push(sortSettings);
		window.localStorage.setItem("moduleRecordSort", JSON.stringify(savedSortSettings));
	} catch (e) {
		logException(e);
	}
};

export const loadActionSort = () => {
	try {
		const savedSettings = window.localStorage.getItem("actionSort");
		if (savedSettings) {
			const sortSetting = JSON.parse(savedSettings);
			return (sortSetting as SavedSortActionSettings) || null;
		}
		return null;
	} catch (e) {
		logException(e);
		return null;
	}
};

export const saveActionSort = (sortSettings: SavedSortActionSettings) => {
	try {
		window.localStorage.setItem("actionSort", JSON.stringify(sortSettings));
	} catch (e) {
		logException(e);
	}
};
