import type { ButtonHTMLAttributes } from "react";

interface Button {
	text: string;
	primary: boolean;
	isShown?: boolean;
	onClick?: () => void;
	danger?: boolean;
	formId?: string;
	testId?: string;
	type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
}

export interface Props {
	buttons: Button[];

	padded?: boolean;
}

export const ModalFooter = ({ buttons, padded }: Props) => {
	const resolveButtonClass = (button: Button): string => {
		if (button.danger) {
			return "danger";
		}
		return button.primary ? "primary" : "secondary";
	};

	return (
		<footer
			className={`she-components-modal-footer ${padded ? "padded" : ""}`}
			data-testid="modal-footer"
		>
			{buttons
				.filter((x) => x.isShown === undefined || x.isShown === true)
				.map((b) => (
					<button
						className={`she-btn she-btn-${resolveButtonClass(b)}`}
						data-testid={b.testId}
						form={b.formId ? b.formId : ""}
						key={b.text}
						onClick={b.onClick}
						// eslint-disable-next-line react/button-has-type
						type={b.type ?? "button"}
					>
						{b.text}
					</button>
				))}
		</footer>
	);
};
