import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "oidc-react";
import { PageLayout } from "../../layouts/PageLayout";
import { usePortalPath } from "../../../helpers/UsePortalPath";
import { PageLanguageSwitcherWrapper } from "./components/PageLanguageSwitcherWrapper/PageLanguageSwitcherWrapper";
import { PageUserSettingsWrapper } from "./components/PageUserSettingsWrapper/PageUserSettingsWrapper";
import type { Portal } from "../../../models/portal";
import type { State } from "../../../state";

export const Settings = () => {
	const { userData } = useAuth();
	const { customerKey, portalKey } = useParams<{ customerKey: string; portalKey: string }>();
	const portal = useSelector<State, Portal | undefined>((s) =>
		s.portal.portals.find(
			(p) => p.key === portalKey.toLowerCase() && p.customerKey === customerKey.toLowerCase(),
		),
	);
	const { t } = useTranslation();
	const portalPath = usePortalPath();

	const settingsLanguageOptions = portal ? <PageLanguageSwitcherWrapper portal={portal} /> : null;

	const settingsUserOptions =
		portal && userData && portal.authenticationMode !== 0 ? (
			<PageUserSettingsWrapper portal={portal} />
		) : null;

	return (
		<PageLayout backTo={portalPath} portal={portal} title={portal ? portal.title : ""}>
			{portal && (
				<>
					<h2>{t("display:labelNavigationSettings")}</h2>
					{settingsUserOptions}
					{settingsLanguageOptions}
				</>
			)}
		</PageLayout>
	);
};
