import { ReactComponent as Earth } from "../../../../assets/svg/earth.svg";
import { ReactComponent as User } from "../../../../assets/svg/user.svg";
import { Button } from "../../../../components/input/Button";
import "./SettingsModuleListItem.styles.scss";

type Icons = Record<string, React.FunctionComponent<React.SVGProps<SVGSVGElement>>>;

const ICONS: Icons = {
	earth: Earth,
	user: User,
};

interface SettingsModuleListItemProps {
	title?: string;
	subTitle?: string;
	options?: { label: string; action: (param: boolean) => void }[];
	image: string;
}

const SettingsModuleListItem = ({
	image,
	title,
	subTitle,
	options,
}: SettingsModuleListItemProps) => {
	const Icon = ICONS[image];
	return (
		<div className="she-module-settings-card">
			<div className="she-module-settings-card__thumbnail">
				<Icon />
			</div>
			<div className="she-module-settings-card__body">
				{title && <h1 className="she-module-settings-card__title">{title}</h1>}
				{subTitle && <h1 className="she-module-settings-card__subtitle">{subTitle}</h1>}
				<div className="she-module-settings-card__options">
					{options &&
						options.map(({ label, action }, index) => {
							return (
								<div key={index}>
									<Button onClick={() => action(true)} variant="tertiary">
										{label}
									</Button>
								</div>
							);
						})}
				</div>
			</div>
		</div>
	);
};

export { SettingsModuleListItem };
