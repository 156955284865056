import Compressor from "compressorjs";
import { logException } from "../services/log/log";
import type { Attachment } from "../models/attachments/Attachment";

export const isFileImage = (file: File) => {
	return file && file.type.split("/")[0] === "image";
};

export const isSVG = (file: File) => {
	return file.type.includes("svg");
};

export const resizeImageFile = (
	file: File | Blob,
	maxWidth = 2000,
	maxHeight = 2000,
	quality = 0.8,
) => {
	return new Promise<File | Blob>((resolve, reject) => {
		new Compressor(file, {
			quality,
			maxWidth,
			maxHeight,
			success(result) {
				resolve(result);
			},
			error(err) {
				reject(err);
			},
		});
	});
};

export const processAttachments = async (attachments: Attachment[]) => {
	for (let i = 0; i < attachments.length; i++) {
		const attachment = attachments[i];
		if (isFileImage(attachment.file)) {
			await resizeImageFile(attachment.file)
				.then((result) => {
					if (attachment.file.type.includes("jpeg")) {
						// Always convert jpeg to drop exif orientation values
						if (result.size > 1024 * 100) {
							attachment.file = result as File;
							// We keep the compressed image only if result file is bigger than 100kB. See #66180
						} else {
							logException({
								error: new Error("Compressed image is too small"),
								customData: { fileSize: result.size },
							});
						}
					} else if (attachment.file.size > 1024 * 1024 * 2 && result.size > 1024 * 100) {
						// We keep the compressed image only if original file is bigger than 2mB and result file is bigger than 100kB.
						attachment.file = result as File;
					}
					if (isSVG(attachment.file)) {
						attachment.isSVG = true;
						attachment.isImage = false;
					} else {
						attachment.isImage = true;
					}
				})
				.catch((e) => {
					logException(e);
					attachment.isImage = false;
				});
		} else {
			attachment.isImage = false;
		}
	}
	return attachments;
};

export const fileNameToPost = (identifier?: string, fileName?: string) =>
	`${identifier}-${fileName}`;

export const isAttachmentSizeCorrect = async (attachment: Attachment) => {
	if (attachment.file.size === 0) {
		logException({
			error: new Error("Attachment file size too small"),
			customData: { fileSize: attachment.file.size },
		});
		return false;
	}
	return attachment.file
		.arrayBuffer()
		.then((attachmentBuffer) => {
			if (attachmentBuffer.byteLength === 0) {
				logException({
					error: new Error("Attachment's array buffer too small"),
					customData: {
						fileSize: attachment.file.size,
						bufferSize: attachmentBuffer.byteLength,
					},
				});
				return false;
			}
			return true;
		})
		.catch(() => {
			logException({
				error: new Error("Error reading file buffer"),
				customData: { fileSize: attachment.file.size },
			});
			return false;
		});
};

export const sanitizeFilename = (filename: string) => {
	// Avoid problems with replacing characters that are modifing other characters by chaining replace calls.
	const illegalChars = /[\\/:*?"<>|]/g;
	const controlChars = /[\p{C}\p{Zl}\p{Zp}]/gu;
	const zeroWidthChars = /[\u200B-\u200F]/g;
	const bidiChars = /[\u202A-\u202E]/g;

	let sanitizedFilename = filename
		.replace(illegalChars, "")
		.replace(controlChars, "")
		.replace(zeroWidthChars, "")
		.replace(bidiChars, "");

	// Trim leading and trailing whitespace
	sanitizedFilename = sanitizedFilename.trim();

	// Replace sequences of spaces with a single space
	sanitizedFilename = sanitizedFilename.replace(/\s+/g, " ");

	return sanitizedFilename;
};
