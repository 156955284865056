// Import the action types that we created
import { CommonActionTypes } from "./types";
import type { SimpleThunkAction } from "../../../utils/thunk";
// Import the action definitions that we created
import type { CommonAction, CommonErrorAction } from "./definitions";
// Import the ThunkActionCreator type that helps define action creators for thunk-based actions
import type { CommonErrorType } from "..";

// define and export the 'setCounter' action creator
// it's of type ThunkActionCreator which we created to simplify the types involved
// in thunk-based actions
// the first (and only in this case) type parameter is our discriminated union type that we
// created in the definitions file
// - this ensures we are creating an action of the correct type and allows us to benefit
// - from the discriminated union at dispatch time (as well as reduction time)
// it's a function that takes a 'num' parameter which will be the number that the state becomes
export const error = (
	key: string,
	hasError: boolean,
	parameters?: Record<string, string>,
	redirectUrl?: string,
): SimpleThunkAction<CommonAction> => {
	// it returns a function that has the store's dispatch method as it first parameter (implicitly types thanks to ThunkActionCreator)
	// - it can also have a second parameter which contains the store's getState method (typed by a second type parameter on ThunkActionCreator)
	return (dispatch) => {
		// we generate our action and pass it to the store by invoking the dispatch method that we've been given
		// the 'type' parameter is required and allows us to benefit from the discriminated union here
		// as num is required (try commenting it out)
		dispatch({
			type: CommonActionTypes.ErrorHappened,
			key,
			parameters,
			hasError,
			redirectUrl,
		});
	};
};

export const commonError = (error: CommonErrorType): CommonErrorAction => {
	return {
		type: CommonActionTypes.CommonError,
		error,
	};
};
