import { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
	getDateFormat,
	getIQDateAfterRange,
	getIQDateBeforeRange,
	getPortalCulture,
	removeSpaces,
	splitDateToObject,
	ukDateFormat,
} from "../../../../helpers/DateTimeInputHelper";
import { FieldType } from "../../../../models/questionnaire";
import { updateFieldProperty } from "../../../../state/components/questionnaire";
import { UpdatableFieldProperty } from "../../../../state/components/questionnaire/actions/enums";
import { DateTimeFieldValidator } from "../../../../validators/dateTimeFieldValidator";
import { DateInputPresentation } from "./DateInput.presentation";
import type { DateField } from "../../../../models/fields/DateField";
import type { IDateValues } from "../../../../helpers/DateTimeInputHelper";
import type { IQDateField } from "../../../../models/fields/IQDateField";

interface Props {
	field: DateField | IQDateField;
	startDate: Date;
	updateField: (fieldId: number, value: any) => void;
	updateValidationStatus: (fieldId: number, value: string[]) => void;
}

export const DateInputContainer = ({
	field,
	startDate,
	updateField,
	updateValidationStatus,
}: Props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [hint, setHint] = useState<string>("");
	const [minDate, setMinDate] = useState<Date | undefined>(undefined);
	const [maxDate, setMaxDate] = useState<Date | undefined>(undefined);
	const [format, setFormat] = useState<string>("");
	const portalCulture = getPortalCulture();
	const [minMaxUpdated, setMinMaxUpdated] = useState<boolean>(false);
	const dateTimeFieldValidator = useMemo(() => {
		return new DateTimeFieldValidator(field);
	}, [field]);

	const dateValues = {
		day: field.value && field.value.day ? field.value.day : "",
		month: field.value && field.value.month ? field.value.month : "",
		year: field.value && field.value.year ? field.value.year : "",
	};

	const generateDateFormat = () => {
		const formatToUse = getDateFormat();
		setFormat(formatToUse);
		generateHint(formatToUse);
	};

	const generateHint = (hintFormat: string) => {
		let hintToUse = "";

		if (!minDate && field.min) {
			setMinDate(moment(field.min).subtract(1, "days").toDate());
			hintToUse = t("display:hintDateFieldMin", {
				minValue: moment(field.min).format(hintFormat),
				interpolation: { escapeValue: false },
			});
		}

		if (!maxDate && field.max) {
			setMaxDate(moment(field.max).add(1, "days").toDate());
			hintToUse = t("display:hintDateFieldMax", {
				maxValue: moment(field.max).format(hintFormat),
				interpolation: { escapeValue: false },
			});
		}

		if (field.min && field.max) {
			hintToUse = t("display:hintDateFieldMinMax", {
				minValue: moment(field.min).format(hintFormat),
				maxValue: moment(field.max).format(hintFormat),
				interpolation: { escapeValue: false },
			});
		}

		if (field.isFutureAllowed === false) {
			hintToUse = t("display:hintDateFieldNoFutureAllowed");
		}

		setHint(hintToUse);
	};

	const handleChange = (returnedValues: IDateValues) => {
		updateValidationStatus(field.id, dateTimeFieldValidator.messages);
		updateField(field.id, returnedValues);
	};

	const calculateToday = () => {
		const today = moment().format(ukDateFormat);
		const dateValues = splitDateToObject(today.toString());
		handleChange(dateValues);
	};

	if (!format) {
		generateDateFormat();
	}

	useEffect(() => {
		if (minMaxUpdated === true) {
			generateDateFormat();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [minMaxUpdated]);

	useEffect(() => {
		if (field.type === FieldType.IQDate) {
			if (!field.min && field.LimitBeforeType && field.LimitBeforeValue !== undefined) {
				const min = getIQDateBeforeRange(
					startDate,
					field.LimitBeforeValue,
					field.LimitBeforeType,
				);
				setMinDate(min.toDate());
				dispatch(
					updateFieldProperty(field.id, min.toDate(), UpdatableFieldProperty.DateMin),
				);
			}

			if (!field.max && field.LimitAfterType && field.LimitAfterValue !== undefined) {
				const max = getIQDateAfterRange(
					startDate,
					field.LimitAfterValue,
					field.LimitAfterType,
				);
				setMaxDate(max.toDate());
				dispatch(
					updateFieldProperty(field.id, max.toDate(), UpdatableFieldProperty.DateMax),
				);
			}

			setMinMaxUpdated(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<DateInputPresentation
			displayMonthBeforeDay={portalCulture === "Us"}
			guid={field.guid}
			hint={hint}
			isNotApplicable={field.isNotApplicable}
			name={removeSpaces(field.name)}
			onChange={handleChange}
			onTodayClick={calculateToday}
			required={field.isMandatory}
			values={dateValues}
		/>
	);
};
