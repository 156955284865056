import { Guidance } from "../Guidance";

interface Props {
	guid: string;
	title: string;
	guidance?: string;
	showGuidance: boolean;
	mediaGuidance?: string;
	required: boolean;
	validationErrors?: string[];
	guidanceIsPopup: boolean;
	additionalLabelStyle?: React.CSSProperties;
	hideValidationErrors?: boolean;
}

export const QuestionHeader = ({
	guid,
	title,
	guidance,
	showGuidance,
	mediaGuidance,
	required,
	validationErrors,
	guidanceIsPopup,
	additionalLabelStyle,
	hideValidationErrors,
}: Props) => {
	return (
		<>
			<div>
				<label
					className="she-components-control-question"
					htmlFor={guid}
					id={`${guid}-label`}
					style={additionalLabelStyle ? additionalLabelStyle : undefined}
				>
					{title}
					<abbr
						className="she-components-control-required-indicator"
						title={required ? "required" : "optional"}
					>
						{required ? "*" : ""}
					</abbr>
				</label>
			</div>
			<div id={`${guid}-description`}>
				{(guidance ||
					mediaGuidance ||
					(validationErrors && validationErrors.length > 0)) && (
					<>
						<Guidance
							guidance={guidance}
							guidanceIsPopup={guidanceIsPopup}
							mediaGuidance={mediaGuidance}
							showGuidance={showGuidance}
						/>
						{!hideValidationErrors &&
							validationErrors &&
							validationErrors.length > 0 &&
							validationErrors.map((e: string, i: number) => (
								<div className="she-components-validation-message" key={i}>
									{e}
								</div>
							))}
					</>
				)}
			</div>
		</>
	);
};
