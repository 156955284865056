interface Props {
	messages: string[];
	headerText: string;
}

export const ValidationHeaderPresentation = ({ headerText, messages }: Props) => {
	return (
		<ul className="she-components-validation-summary">
			<li className="she-components-validation-summary-header">{headerText}</li>
			{messages.length > 0 &&
				messages.map((msg: string, i: number) => (
					<li
						className="she-components-validation-message"
						data-testid="validationMessage"
						key={i}
					>
						{msg}
					</li>
				))}
		</ul>
	);
};
