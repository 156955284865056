import { withSignInGet } from "../../../utilities/http";
import { BaseService } from "../../../baseService";
import type { CancelTokenSource } from "axios";
import type { PolicyService } from "../../interface";
import type { Policies } from "../../../../models/policy";

export class DefaultPolicyService extends BaseService implements PolicyService {
	async getPolicies(portalDashKey: string, cancelToken?: CancelTokenSource): Promise<Policies> {
		const serviceUrl = `${await this.getEndpointUrl()}?IncludeCaptions=true&PortalDashKey=${portalDashKey}`;
		const headers = await this.getAuthHeaders();
		const response = await withSignInGet<any>(serviceUrl, {
			...headers,
			cancelToken: cancelToken && cancelToken.token,
		});

		const policiesApi = response.parsedBody;

		return {
			attachments: policiesApi.PolicyDocuments
				? policiesApi.PolicyDocuments.map((item: any) => ({
						entityReference: item.AttachmentEntityReference,
						attachmentType: item.AttachmentType,
						policyCategoryDictionaryText: item.CategoryDictionaryText,
						dateCreated: item.DateCreated,
						dateModified: item.DateModified,
						fileDescription: item.FileDescription,
						fileName: item.FileName,
						fileSize: (item.FileSize / 1000).toFixed(2),
						identifier: item.Identifier,
						link: item.Link,
						policyEntityReference: item.PolicyEntityReference,
						policyTitle: item.Title,
				  }))
				: [],

			captions: policiesApi.Captions
				? policiesApi.Captions.map((item: any) => ({
						propertyName: item.PropertyName,
						displayText: item.DisplayText,
						language: item.Language,
				  }))
				: [],

			policyCount: parseInt(policiesApi.PolicyCount || "0"),

			moduleTitle: policiesApi.ModuleTitle
				? policiesApi.ModuleTitle.map((item: any) => ({
						title: item.Title,
						language: item.Language,
				  }))
				: [],

			anySystemMessages: policiesApi.AnySystemMessages,
		};
	}
}
