import { v4 as uuid } from "uuid";
import { t } from "i18next";

interface Props {
	licenseData: { name: string; version: string; licenseType: string; url: string }[];
}

export const LicensePresentation = ({ licenseData }: Props) => {
	return (
		<>
			<label>{t("display:labelLicenseTitle")}</label>
			<ul className="she-license-ul" id={uuid()}>
				{licenseData.length > 0 &&
					licenseData.map((license, id: number) => (
						<li className="she-license-li-full" id={id.toString()} key={id}>
							<ul className="she-license-nested-ul">
								<li className="she-license-nested-li">
									<label className="she-license-li-heading">{license.name}</label>
								</li>
								<li className="she-license-nested-li">
									<label>
										{t("display:labelLicenseName", {
											licenseName: license.licenseType,
										})}
									</label>
								</li>
								<li className="she-license-nested-li">
									<label>
										{t("display:labelLicenseVersion", {
											licenseVersion: license.version,
										})}
									</label>
								</li>
								<li className="she-license-nested-li">
									<a
										className={
											license.url === t("display:labelLicenseUrlUnknown")
												? "she-license-li-url-no-click"
												: "she-license-li-url"
										}
										href={
											license.url === t("display:labelLicenseUrlUnknown")
												? ""
												: license.url
										}
										rel="noopener noreferrer"
										target="_blank"
									>
										{license.url}
									</a>
								</li>
							</ul>
						</li>
					))}
			</ul>
		</>
	);
};
