import type { Field } from "../models/fields/Field";
import type { Section } from "../models/questionnaire";

export const AreFieldArraysEqual = (
	left: Field[] | undefined,
	right: Field[] | undefined,
): boolean => {
	if (!left || !right) {
		return false;
	}

	if (right.length !== left.length) {
		return false;
	}

	const leftValErrors = left.filter((f) => f.validationMessage.length > 0);
	const rightValErrors = right.filter((f) => f.validationMessage.length > 0);

	if (leftValErrors.length !== rightValErrors.length) {
		return false;
	}

	return true;
};

export const AreFieldArraysEqualNoValidationCheck = (
	left: Field[] | undefined,
	right: Field[] | undefined,
): boolean => {
	if (!left || !right) {
		return false;
	}

	if (right.length !== left.length) {
		return false;
	}

	const leftIds = left
		.map((x) => x.guid)
		.sort()
		.join(" ");
	const rightIds = right
		.map((x) => x.guid)
		.sort()
		.join(" ");

	if (leftIds !== rightIds) {
		return false;
	}

	return true;
};

export const AreSectionArraysEqual = (
	left: Section[] | undefined,
	right: Section[] | undefined,
): boolean => {
	if (!left || !right) {
		return false;
	}

	if (right.length !== left.length) {
		return false;
	}

	return true;
};
