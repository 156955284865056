export enum DateFormat {
	Us = "MM/DD/YYYY",
	Other = "DD/MM/YYYY",
}

export const getPortalDateFormat = (portalCulture?: string, includeTime?: boolean) => {
	let baseString: string = portalCulture === "Us" ? DateFormat.Us : DateFormat.Other;

	if (includeTime) {
		baseString += portalCulture === "Us" ? " - hh:mm a" : (" - HH:mm" as string);
	}

	return baseString;
};
