import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { NotificationModal } from "../../modals";
import { useTextWithUrl } from "../../../utilities/UseTextWithUrl";

interface Props {
	guidance?: string;
	showGuidance: boolean;
	mediaGuidance?: string;
	guidanceIsPopup: boolean;
}

export const Guidance = ({ guidanceIsPopup, showGuidance, guidance, mediaGuidance }: Props) => {
	const { t } = useTranslation();
	const [isOpen, setIsOpen] = useState(false);
	const [isOpenMedia, setIsOpenMedia] = useState(false);

	const guidanceWithUrl = useTextWithUrl(guidance);

	const handleClick = (event: React.MouseEvent) => {
		event.preventDefault();

		setIsOpen(true);
	};

	const guidanceFragment = useCallback(() => {
		if (showGuidance) {
			if (!guidanceIsPopup) {
				return (
					<div
						className="she-components-control-guidance-static"
						data-testid="static-guidance"
					>
						{guidanceWithUrl}
					</div>
				);
			}
			return (
				<button
					className="she-btn she-btn-tertiary"
					data-testid="popup-guidance-button"
					onClick={handleClick}
					type="button"
				>
					{t("display:buttonViewGuidance")}
				</button>
			);
		}
	}, [showGuidance, guidanceIsPopup, guidanceWithUrl, t]);

	const mediaGuidanceFragment = useCallback(() => {
		if (showGuidance) {
			if (mediaGuidance) {
				return (
					<button
						className="she-btn she-btn-tertiary"
						data-testid="media-guidance-button"
						onClick={() => setIsOpenMedia(true)}
						type="button"
					>
						{t("display:buttonViewGuidance")}
					</button>
				);
			}
		}
	}, [mediaGuidance, showGuidance, t]);

	const mediaGuidanceTargetedContent = useMemo(
		() =>
			(mediaGuidance &&
				typeof mediaGuidance === "string" &&
				mediaGuidance.replace(/<a href=/g, '<a target="_blank" href=')) ||
			"",
		[mediaGuidance],
	);

	return (
		<>
			{(guidance || mediaGuidance) && (
				<div className="she-components-control-guidance">
					{guidance && guidanceFragment()}
					{mediaGuidance && mediaGuidanceFragment()}
				</div>
			)}
			{guidanceIsPopup && (
				<NotificationModal
					content={guidanceWithUrl || ""}
					onClose={() => setIsOpen(false)}
					preWrap
					show={isOpen}
				/>
			)}
			{mediaGuidance && (
				<NotificationModal
					content={mediaGuidanceTargetedContent}
					fullScreenMobile
					html
					onClose={() => setIsOpenMedia(false)}
					show={isOpenMedia}
				/>
			)}
		</>
	);
};
