import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { SubModuleRecordDisplay } from "../SubModuleRecord";
import { AnimationWrapper } from "../../../../../components/animation/AnimationWrapper";
import { AnimationSlideFade } from "../../../../../components/animation/AnimationSlideFade";
import type { SubModuleRecord } from "../../../../../../models/questionnaire";

interface Props {
	name: string;
	addRecord: () => void;
	records?: SubModuleRecord[];
	headers: { id: number; value: string }[];
	remove: (recordId: string) => void;
	edit: (record: SubModuleRecord) => void;
	singleRecordName?: string;
	hideHeader?: boolean;
	emptyListMessage?: string;
	isIQ?: boolean;
	isMandatory: boolean;
	hasValidationErrors: boolean;
	validationErrors: string[];
	isInSubmodule?: boolean;
	allowActions?: boolean;
	allowAttachments?: boolean;
}

export const SubmodulePresentation = forwardRef<HTMLHeadingElement, Props>(
	(
		{
			addRecord,
			edit,
			hasValidationErrors,
			headers,
			isMandatory,
			name,
			remove,
			validationErrors,
			allowActions,
			allowAttachments,
			emptyListMessage,
			hideHeader,
			isIQ,
			isInSubmodule,
			records,
			singleRecordName,
		}: Props,
		ref,
	) => {
		const { t } = useTranslation();

		return (
			<div
				className={`she-components-submodule ${
					hasValidationErrors ? "she-components-validation-border-left" : ""
				}`}
			>
				{(!isIQ || !isInSubmodule || isMandatory || (records && records.length > 0)) && (
					<h1
						className={`${
							isIQ
								? "she-components-control-subtitle"
								: isInSubmodule
								? "she-components-control-question"
								: "she-components-questionnaire-group-header"
						}`}
						ref={ref}
						tabIndex={-1}
					>
						{name}
						<abbr
							className="she-components-control-required-indicator"
							title={isMandatory ? "required" : "optional"}
						>
							{isMandatory ? "*" : ""}
						</abbr>
					</h1>
				)}
				{validationErrors &&
					validationErrors.length > 0 &&
					validationErrors.map((e: string, i: number) => (
						<div className="she-components-validation-message" key={i}>
							{e}
						</div>
					))}
				<div
					className={`${
						isIQ || isInSubmodule ? "she-components-submodule-iq" : ""
					} she-components-submodule-container`}
				>
					{!hideHeader && <label>{name}</label>}
					{emptyListMessage && !isIQ && records && !records.length && (
						<div className="she-components-submodule-list-empty">
							{emptyListMessage}
						</div>
					)}
					<AnimationWrapper>
						{records?.map((rec) => {
							return (
								<AnimationSlideFade key={rec.localId}>
									<SubModuleRecordDisplay
										allowActions={allowActions}
										allowAttachments={allowAttachments}
										editRecord={edit}
										headers={headers}
										record={rec}
										removeRecord={remove}
									/>
								</AnimationSlideFade>
							);
						})}
					</AnimationWrapper>
					<div>
						<button
							className="she-btn she-btn-tertiary"
							onClick={() => {
								addRecord();
							}}
							type="button"
						>
							{t("display:buttonAddSubmoduleName", {
								submodule: singleRecordName ? singleRecordName : name,
							})}
						</button>
					</div>
				</div>
			</div>
		);
	},
);

SubmodulePresentation.displayName = "SubmodulePresentation";
