import { useMemo } from "react";
import moment from "moment";
import { RadioButton } from "../RadioButton";
import { CollapsibleLabelList } from "../../generic/CollapsibleLabelList";
import type { ProjectCaptions, Project } from "../../../../models/project/project";
import "./ProjectSelectInputListItem.styles.scss";

interface Props {
	captions: ProjectCaptions;
	project: Project;
	fieldId: number;
	groupText: string;
	selected: boolean;
	onSelectProject: (project: Project) => void;
	dateFormat: string;
}

export const ProjectSelectInputListItem = ({
	captions,
	project,
	fieldId,
	groupText,
	selected,
	onSelectProject,
	dateFormat,
}: Props) => {
	const list = useMemo(() => {
		const items: { key: string; value: string; strong: boolean }[] = [
			{
				key: captions.referenceCaption,
				value: project.reference,
				strong: true,
			},
		];
		if (project.location) {
			items.push({
				key: captions.locationCaption,
				value: project.location,
				strong: false,
			});
		}
		if (project.startDate && moment(project.startDate).isValid()) {
			items.push({
				key: captions.startDateCaption,
				value: moment(project.startDate).format(dateFormat),
				strong: false,
			});
		}
		if (project.manager) {
			items.push({
				key: captions.managerCaption,
				value: project.manager,
				strong: false,
			});
		}
		items.push({
			key: captions.orgUnitIdCaption,
			value: project.orgUnitId.toString(),
			strong: false,
		});
		return items;
	}, [project, captions, dateFormat]);

	return (
		<div className="she-project-list-item" key={project.id}>
			<RadioButton
				id={`${groupText}-${fieldId}-${project.id}`}
				key={project.id}
				name={`${groupText}-${fieldId}`}
				onClick={() => onSelectProject(project)}
				selected={selected}
			>
				<CollapsibleLabelList listItems={list} />
			</RadioButton>
		</div>
	);
};
