import { useMemo, useState } from "react";
import { v4 as uuid } from "uuid";
import type { ActionAttachment } from "../../models/action";

export const useManageActionAttachments = (attachments: ActionAttachment[]) => {
	const [attachmentsToRemove, setAttachmentsToRemove] = useState<string[]>([]);
	const [attachmentsToAdd, setAttachmentsToAdd] = useState<ActionAttachment[]>([]);

	const attachmentsToDisplay = useMemo(() => {
		return attachments
			.filter((attachment) => !attachmentsToRemove.includes(attachment.Identifier))
			.concat(attachmentsToAdd)
			.sort(sortAttachments);
	}, [attachments, attachmentsToAdd, attachmentsToRemove]);

	const removeAttachment = (identifier: string) => {
		if (attachmentsToAdd.some((attachment) => attachment.Identifier === identifier)) {
			setAttachmentsToAdd((attachments) =>
				attachments.filter((attachment) => attachment.Identifier !== identifier),
			);
		} else if (!attachmentsToRemove.includes(identifier)) {
			setAttachmentsToRemove((attachments) => [...attachments, identifier]);
		}
	};

	const addAttachment = (file: File, identifier?: string, description?: string) => {
		setAttachmentsToAdd((attachments) => [
			...attachments,
			{
				FileName: file.name,
				AttachmentType: "NewFile",
				File: file,
				Identifier: identifier || uuid(),
				FileDescription: description,
				DateCreated: file.lastModified.toString(),
				FileSize: file.size,
			},
		]);
	};

	const resetAttachments = () => {
		setAttachmentsToRemove([]);
		setAttachmentsToAdd([]);
	};

	return {
		addAttachment,
		attachmentsToAdd,
		attachmentsToDisplay,
		attachmentsToRemove,
		removeAttachment,
		resetAttachments,
	};
};

const sortAttachments = (attachmentA: ActionAttachment, attachmentB: ActionAttachment) => {
	const nameA = attachmentA.FileName || attachmentA.Link;
	const nameB = attachmentB.FileName || attachmentB.Link;
	if (nameA && nameB) {
		return nameA.localeCompare(nameB);
	}
	return 0;
};
