import { useMemo, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getRandomId } from "../../../../helpers/RandomIdHelper";
import { ModalPresentation } from "./Modal.presentation";
import type { HistoryLocationState } from "../../../../types/History";

export interface Props {
	show: boolean;
	onClose?: () => void;
	fullHeight?: boolean;
	flexibleSize?: boolean;
	fullScreenMobile?: boolean;
	customClassName?: string;
	customOverlayClassName?: string;
	blockUi?: boolean;
	showEntryAnimation?: boolean;
	withHistory?: boolean;
	shouldReturnFocusAfterClose?: boolean;
	children?: React.ReactNode;
}

export const ModalContainer = ({
	show,
	onClose,
	fullHeight,
	flexibleSize,
	fullScreenMobile,
	customClassName,
	customOverlayClassName,
	blockUi,
	showEntryAnimation = true,
	children,
	withHistory = true,
	shouldReturnFocusAfterClose = true,
}: Props) => {
	const [routeId, setRouteId] = useState("sheModal");
	const [routeAdded, setRouteAdded] = useState(false);
	const history = useHistory<HistoryLocationState>();

	// TODO: Why are we doing a useMemo of a boolean value??
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const withHistoryNoProps = useMemo(() => withHistory, []);

	useEffect(() => {
		if (withHistoryNoProps) {
			if (show && !routeAdded) {
				// Show modal called - adding new route
				const id = `sheModal${getRandomId(8)}`;
				setRouteId(id);
				history.push(history.location.pathname, {
					...history.location.state,
					...{ [id]: true },
				} as any);
				setRouteAdded(true);
			} else if (!show && routeAdded) {
				// Hide modal called - removing route
				setRouteAdded(false);
				history.goBack();
			} else if (
				show &&
				routeAdded &&
				(!history.location.state || !history.location.state[routeId])
			) {
				// No modal route in state - remove modal
				setRouteAdded(false);
				onClose?.();
			}
		}
	}, [routeAdded, history, routeId, onClose, show, withHistoryNoProps]);

	const overlayClassName = useMemo(() => {
		let className = "she-components-modal-overlay";
		if (fullScreenMobile) {
			className = `${className} she-components-modal-overlay-full-screen`;
		}
		if (!showEntryAnimation) {
			className = `${className} she-components-modal-overlay-after-open`;
		}
		if (customOverlayClassName) {
			className = `${className} ${customOverlayClassName}`;
		}
		return className;
	}, [fullScreenMobile, customOverlayClassName, showEntryAnimation]);

	const modalClassName = useMemo(() => {
		let className = "she-components-modal";
		if (fullHeight) {
			className = `${className} she-components-modal-full-height`;
		}
		if (flexibleSize) {
			className = `${className} she-components-modal-flexible-size`;
		}
		if (fullScreenMobile) {
			className = `${className} she-components-modal-full-screen`;
		}
		if (!showEntryAnimation) {
			className = `${className} she-components-modal-after-open`;
		}
		if (customClassName) {
			className = `${className} ${customClassName}`;
		}
		return className;
	}, [fullHeight, flexibleSize, fullScreenMobile, customClassName, showEntryAnimation]);

	return (
		<ModalPresentation
			className={modalClassName}
			onClose={onClose}
			overlayClassName={overlayClassName}
			shouldCloseOnEsc={!blockUi}
			shouldCloseOnOverlayClick={!blockUi}
			shouldReturnFocusAfterClose={shouldReturnFocusAfterClose}
			show={show}
		>
			{children}
		</ModalPresentation>
	);
};
