import { createContext, useEffect, useState } from "react";
import { HealthMonitorSingleton } from "../healthMonitor";
import { OnlineStatus } from "../healthMonitor/OnlineStatus";

export const APIHealthContext = createContext(OnlineStatus.Unknown);

export const APIHealthConsumer = APIHealthContext.Consumer;

type Props = {
	children?: React.ReactNode;
};

export const APIHealthProvider = ({ children }: Props) => {
	const [state, setState] = useState(OnlineStatus.Unknown);

	useEffect(() => {
		HealthMonitorSingleton.getInstance().registerListener(setState);
		HealthMonitorSingleton.getInstance().registerWindowEventListeners();
		return () => {
			HealthMonitorSingleton.getInstance().unregisterListener(setState);
			HealthMonitorSingleton.getInstance().unRegisterWindowEventListeners();
		};
	}, []);

	return <APIHealthContext.Provider value={state}>{children}</APIHealthContext.Provider>;
};
