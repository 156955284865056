import { useEffect, useState, useMemo, useRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import Axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import { RecordCard } from "../RecordCard";
import { stringHelper } from "../../../../../helpers/StringHelper";
import { Pagination } from "../../../../components/generic/Pagination";
import { DefaultRecordsService } from "../../../../../services/records";
import { switchMatchingLanguageCode } from "../../../../../helpers/LanguageCodeHelper";
import { SearchBox } from "../../../../components/input/SearchBox";
import { CancelablePromise } from "../../../../../services/utilities/CancelablePromise";
import { APIHealthContext } from "../../../../../services/health/implementations/healthContext/healthContext";
import { OnlineStatus } from "../../../../../services/health/implementations/healthMonitor";
import { RecordListSort } from "./RecordListSort";
import type { Portal } from "../../../../../models/portal";
import type { RecordSortPropertyInfo, RecordModule, Records } from "../../../../../models/record";
import type { LabelRecordsModuleTitle } from "../../../../../types/i18next";
import "./RecordList.styles.scss";

interface RecordListProps {
	portal?: Portal | undefined;
	recordsKey: RecordModule;
}

const RecordList = ({ recordsKey, portal }: RecordListProps) => {
	const { t, i18n } = useTranslation();
	const [caption, setCaption] = useState("");
	const [totalPages, setTotalPages] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const maxItemsPerPage = 20;
	const portalKey = (portal && portal.key) || "";
	const [displayError, setDisplayError] = useState(false);
	const [searchTerm, setSearchTerm] = useState("");
	const [recordsDetails, setRecordsDetails] = useState("");
	const onlineStatus = useContext(APIHealthContext);
	const [sort, setSort] = useState<RecordSortPropertyInfo | undefined>();
	const history = useHistory();
	const location = useLocation();

	const searchBoxRef = useRef<HTMLInputElement>(null);

	const performSearch = (searchTerm: string) => {
		setSearchTerm(searchTerm);

		if (searchTerm) {
			history.push(`?search=${encodeURIComponent(searchTerm)}`);
		} else {
			const searchParams = new URLSearchParams(location.search);
			searchParams.delete("search");
			history.replace({ search: searchParams.toString() });

			setSearchTerm("");
		}
	};

	useEffect(() => {
		const recordMatch = /search=([^&]+)/.exec(location.search);
		let record;

		if (recordMatch?.[1]) {
			record = decodeURIComponent(recordMatch[1]);
			setSearchTerm(record);
		}
	}, [location.search]);

	useEffect(() => {
		setCaption(() => {
			const moduleTile = stringHelper.alphaNumericRegExLowerCase(
				recordsKey,
			) as LabelRecordsModuleTitle;

			return t(`display:labelRecordsModuleTitle.${moduleTile}`);
		});

		if (portal && portal.installModules) {
			const currentModule =
				portal &&
				portal.installModules.find(
					(x: any) =>
						stringHelper.alphaNumericRegExLowerCase(x.key) ===
						stringHelper.alphaNumericRegExLowerCase(recordsKey),
				);
			const currentModuleCaption = currentModule && currentModule.caption;

			if (currentModuleCaption) {
				setCaption(currentModuleCaption);
			}
		}
	}, [portal, recordsKey, t]);

	const service = useMemo(
		() =>
			new DefaultRecordsService({
				subdomain: recordsKey,
			}),
		[recordsKey],
	);

	const { isLoading, isError, data, error } = useQuery(
		[
			recordsKey,
			portalKey,
			currentPage - 1,
			switchMatchingLanguageCode(i18n.language),
			searchTerm,
			sort && sort.propertyName,
			sort && sort.order,
		],
		() => {
			const cancelTokenSource = Axios.CancelToken.source();
			const getRecords = new CancelablePromise<Records>((resolve, reject) =>
				service
					.getRecords(
						recordsKey,
						portalKey,
						sort && sort.propertyName,
						sort && sort.order,
						maxItemsPerPage,
						(currentPage - 1) * maxItemsPerPage,
						searchTerm,
						portal && portal.dateFormat,
						switchMatchingLanguageCode(i18n.language),
						cancelTokenSource,
					)
					.then((r) => resolve(r))
					.catch((e) => reject(e)),
			);
			getRecords.cancel = () => {
				cancelTokenSource.cancel();
			};
			return getRecords;
		},
		{
			onSuccess: (data) => {
				if (data.totalDataCount) {
					const { totalDataCount } = data;
					const totalPagesSum = totalDataCount / maxItemsPerPage;
					setTotalPages(Math.ceil(totalPagesSum));
					setRecordsDetails(
						t("display:labelViewingXYofZ", {
							min: maxItemsPerPage * (currentPage - 1) + 1,
							max: Math.min(maxItemsPerPage * currentPage, data.totalDataCount),
							total: data.totalDataCount,
						}),
					);
				}
			},
		},
	);

	const errorContent = (
		<>
			<p className="she-records__status">{t("display:labelError")}</p>
			<div className="she-records__error">
				{!displayError && (
					<button
						className="she-btn she-btn-tertiary"
						onClick={() => {
							setDisplayError(true);
						}}
						type="button"
					>
						{t("display:labelDisplayError")}
					</button>
				)}
				{displayError && error && (
					<p>
						{Array.isArray(error)
							? error[0]
							: typeof error === "string"
							? error
							: JSON.stringify(error)}
					</p>
				)}
			</div>
		</>
	);

	const listContent = (
		<>
			<div className="she-records__wrapper">
				{recordsDetails ? (
					<div className="she-records__details">{recordsDetails}</div>
				) : null}
				<ul className="she-records__list">
					{data &&
						data.list &&
						data.list.map((record, i) => (
							<li className="she-records__list-item" key={i}>
								<RecordCard moduleCaption={caption} record={record} />
							</li>
						))}
				</ul>
				{recordsDetails ? (
					<div className="she-records__details">{recordsDetails}</div>
				) : null}
			</div>

			<Pagination
				currentPage={currentPage}
				maxPages={totalPages}
				onChangePage={(e) => {
					setCurrentPage(e);
				}}
			/>
		</>
	);

	const resolveConditionalRendering = (): JSX.Element => {
		if (data && sort) {
			if (data.list && data.list.length) {
				return listContent;
			} else if (searchTerm) {
				return (
					<p className="she-records__status">
						{t("display:labelRecordsNoResultsWereFound")}
					</p>
				);
			}
			return <p className="she-records__status">{t("display:labelRecordsEmptyList")}</p>;
		} else if (isError) {
			return errorContent;
		} else if (isLoading || !sort) {
			if (onlineStatus === OnlineStatus.Available) {
				return <p className="she-records__status">{t("display:labelLoading")}</p>;
			}
			return <p className="she-records__status">{t("error:recordsDeviceOffline")}</p>;
		}
		return <p className="she-records__status">{t("display:labelRecordsEmptyList")}</p>;
	};

	return (
		<>
			<h2>{caption}</h2>
			<div className="she-records">
				<SearchBox
					onSearchBegan={() => {}}
					onSearchEnd={() => {}}
					onSearchTermChange={(newSearchTerm) => {
						performSearch(newSearchTerm);
					}}
					reference={searchBoxRef}
					searchTerm={searchTerm || ""}
				/>
				<RecordListSort
					module={recordsKey}
					onSelect={(fieldInfo) => setSort(fieldInfo)}
					propertyInfos={data ? data.sortProperties : undefined}
				/>
				{resolveConditionalRendering()}
			</div>
		</>
	);
};

export { RecordList };
