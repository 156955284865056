import { t } from "i18next";
import type { IQSignatureField } from "../../models/fields/IQSignatureField";

export class IQSignatureFieldValidator {
	signatureField: IQSignatureField;
	messages: string[];

	constructor(signatureField: IQSignatureField) {
		this.signatureField = signatureField;
		this.messages = [];
	}

	isFieldValid = (): boolean => {
		this.messages = [];

		if (this.signatureField.isMandatory) {
			if (typeof this.signatureField.signature.value === "string") {
				if (!this.isSignatureValid(this.signatureField.signature.value)) {
					this.messages.push(t("validation:mandatoryFieldMessage"));
					return false;
				}
			} else if (
				this.signatureField.signature.value === undefined ||
				this.signatureField.signature.value === null
			) {
				this.messages.push(t("validation:mandatoryFieldMessage"));
				return false;
			} else if (
				this.signatureField.fullname.value === undefined ||
				this.signatureField.fullname.value === null
			) {
				this.messages.push(t("validation:mandatoryFieldMessage"));
				return false;
			}
		} else if (
			typeof this.signatureField.signature.value === "string" &&
			this.signatureField.signature.value.length > 0
		) {
			if (!this.isSignatureValid(this.signatureField.signature.value)) {
				this.messages.push(t("validation:invalidSignatureMessage"));
				return false;
			}
		}

		return true;
	};

	isSignatureValid = (value: string): boolean => {
		const requiredText = "base64,";
		return !(value.length < requiredText.length || value.indexOf(requiredText) < 1);
	};
}
