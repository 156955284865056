import { withSignInGet } from "../../../utilities/http";
import { BaseService } from "../../../baseService";
import type {
	RegisterEntity,
	RegisterEntityProperty,
	RegisterEntityPropertyInfo,
	RegisterEntities,
} from "../../../../models/registerEntity";
import type { IHttpResponse } from "../../../utilities/http";
import type { RegisterService } from "../../interface";

type ResponseData = {
	Id: number;
} & Record<string, string>;

interface ResponseFieldInfo {
	PropertyName: string;
	Visible: boolean;
	Caption: string;
	Language: string;
}

interface RegisterListResponse {
	Data: ResponseData[];
	FieldInfo: ResponseFieldInfo[];
	UserCurrentLanguage: string;
	TotalDataCount: number;
}

export class DefaultRegisterService extends BaseService implements RegisterService {
	endpoint: string;

	constructor(endpoint: string) {
		super({ subdomain: "" });

		this.endpoint = endpoint;
	}

	async getRegisterList(
		portalKey: string,
		searchText = "",
		sortField = "EntityReference",
		searchDateFormat = "Uk",
		startIndex = 0,
		pageSize = 5,
		sortOrder = "asc",
		userCurrentLanguage = "EnGB",
	) {
		let query = `SearchText=${encodeURIComponent(searchText)}`;
		query += `&SearchDateFormat=${searchDateFormat}`;
		query += `&UserCurrentLanguage=${userCurrentLanguage}`;
		query += `&StartIndex=${startIndex}`;
		query += `&PageSize=${pageSize}`;
		query += `&PortalKey=${portalKey}`;
		query += sortField ? `&SortField=${sortField}` : "";
		query += sortOrder ? `&SortOrder=${sortOrder}` : "";

		const serviceUrl = `${await this.getEndpointUrl()}${this.endpoint}?${query}`;
		const headers = await this.getAuthHeaders();
		const response = await withSignInGet<RegisterListResponse>(serviceUrl, {
			...headers,
		});
		return mapResponse(response);
	}
}

const mapResponse = (response: IHttpResponse<RegisterListResponse>): RegisterEntities => {
	return {
		totalDataCount: response.data.TotalDataCount,
		list: response.data.Data.map((responseAction: ResponseData) =>
			mapRegisterItem(responseAction, response.data.FieldInfo),
		),
		sortProperties: mapEntityProperties(response.data.FieldInfo),
		userCurrentLanguage: response.data.UserCurrentLanguage,
	};
};

const mapRegisterItem = (
	responseEntity: ResponseData,
	responseFieldInfo: ResponseFieldInfo[],
): RegisterEntity => {
	return {
		id: responseEntity.Id,
		info: mapEntityItemProperties(responseEntity, responseFieldInfo),
	};
};

const EXCLUDED_PROPERTIES = ["Id"];

const mapEntityProperties = (fieldInfo: ResponseFieldInfo[]): RegisterEntityProperty[] => {
	return fieldInfo
		.filter((fi) => !EXCLUDED_PROPERTIES.includes(fi.PropertyName))
		.map((fi) => ({
			propertyName: fi.PropertyName,
			caption: fi.Caption,
		}));
};

const mapEntityItemProperties = (
	responseEntity: ResponseData,
	responseFieldInfo: ResponseFieldInfo[],
): RegisterEntityPropertyInfo[] => {
	const propertyInfo: RegisterEntityPropertyInfo[] = [];
	Object.keys(responseEntity).forEach((key) => {
		const property = responseFieldInfo.find((p) => p.PropertyName === key);

		if (property && !EXCLUDED_PROPERTIES.includes(key)) {
			propertyInfo.push({
				propertyName: key,
				value: responseEntity[key],
				title: property.Caption,
			});
		}
	});
	return propertyInfo;
};
