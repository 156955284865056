import { createContext, useCallback, useEffect, useState } from "react";
import { useAuth } from "oidc-react";
import { useParams } from "react-router-dom";
import { isBrowserIE } from "../../../../../helpers/ieHelper";
import { QuestionnaireProgressState } from "../../../../../models/questionnaire";
import { DefaultLocalDataService } from "../../../../../services/localData";
import type { QuestionnaireStub } from "../../../../../models/portal";
import type { QuestionnaireTemplate } from "../../../../../models/questionnaire";

export const FormsStatsContext = createContext({
	inProgress: 0,
	queued: 0,
	refreshStats: () => {},
});

interface Props {
	portalStubs: QuestionnaireStub[];
	children: React.ReactNode;
}

export const FormsStatsProvider = ({ children, portalStubs }: Props) => {
	const [inProgress, setInProgress] = useState(0);
	const [queued, setQueued] = useState(0);
	const { portalKey } = useParams<{ portalKey: string }>();
	const auth = useAuth();
	const refreshStats = useCallback(() => {
		if (!isBrowserIE()) {
			const localDataService = new DefaultLocalDataService();
			localDataService
				.getQuestionnaireByState(
					portalKey.toLowerCase(),
					QuestionnaireProgressState.InProgress,
					auth.userData || null,
				)
				.then((records) => {
					setInProgress(filterNumberOfRecordsForPortal(portalStubs, records));
				});
			localDataService
				.getQueuedQuestionnaires(portalKey.toLowerCase(), auth.userData || null)
				.then((records) => {
					setQueued(filterNumberOfRecordsForPortal(portalStubs, records));
				});
		}
	}, [portalStubs, portalKey, auth.userData]);

	useEffect(() => {
		refreshStats();
	}, [refreshStats]);

	useEffect(() => {
		window.addEventListener("uploadRefresh", refreshStats);
		return () => window.removeEventListener("uploadRefresh", refreshStats);
	}, [refreshStats]);

	return (
		<FormsStatsContext.Provider value={{ inProgress, queued, refreshStats }}>
			{children}
		</FormsStatsContext.Provider>
	);
};

const filterNumberOfRecordsForPortal = (
	portalStubs: QuestionnaireStub[],
	records: QuestionnaireTemplate[],
): number =>
	records.reduce((acc: QuestionnaireTemplate[], r) => {
		portalStubs.find((ps) => ps.key === r.questionnaire.templateId.toString()) && acc.push(r);
		return acc;
	}, []).length;

export const FormsStatsConsumer = FormsStatsContext.Consumer;
