import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Axios from "axios";
import { DefaultProjectService } from "../../../../services/project";
import { logException } from "../../../../services/log";
import { ProjectsLocalActionTypes, ProjectsLocalStatus } from "./ProjectSelectUseLocalState";
import type { State } from "../../../../state";
import type { OrgUnit } from "../../../../models/orgUnit";
import type { ProjectService } from "../../../../services/project";
import type { ProjectsLocalState, ProjectsLocalStateAction } from "./ProjectSelectUseLocalState";
import type { ProjectCaptions } from "../../../../models/project/project";

export const useProjectService = (
	localState: ProjectsLocalState,
	localStateDispatch: (action: ProjectsLocalStateAction) => void,
) => {
	const pageSize = 100;

	const { t } = useTranslation();

	const token = useSelector<State, string | undefined>((s) => s.auth.token);
	const customerKey = useSelector<State, string | undefined>((s) => s.auth.customerKey);
	const questionnaireLanguage = useSelector<State, string>(
		(s) => s.questionnaire.questionnaire!.language,
	);

	const questionnaireOrgUnitCaption = useSelector<State, string | undefined>(
		(s) => s.questionnaire.questionnaire!.orgUnitCaption,
	);
	const questionnaireOrgUnitLabel =
		questionnaireOrgUnitCaption || t("display:labelOrgUnit") || "";

	const orgUnits = useSelector<State, OrgUnit[]>((s) => s.orgUnit.orgUnits);

	const orgUnitID: number = useMemo(() => {
		const rootOrgUnit = orgUnits.find((ou) => ou.parentId === undefined);
		if (rootOrgUnit) {
			return rootOrgUnit.id;
		}
		return 0;
	}, [orgUnits]);

	useEffect(() => {
		localStateDispatch({
			type: ProjectsLocalActionTypes.SetStatus,
			status: ProjectsLocalStatus.Loading,
		});
		const service: ProjectService = new DefaultProjectService({
			subdomain: "Project",
		});
		const cancelToken = Axios.CancelToken.source();
		service
			.getProjects(
				0,
				orgUnitID,
				pageSize,
				pageSize * (localState.page - 1),
				localState.sortProperty,
				localState.sortOrder,
				localState.searchTerm,
				true,
				cancelToken,
				undefined,
				localState.portalCulture,
			)
			.then((result) => {
				result.projects.forEach((project) => {
					const orgUnit = orgUnits.find((o) => o.id === project.orgUnitId);
					if (orgUnit) {
						project.orgUnitId = orgUnit.name;
					}
				});

				if (result.captions !== undefined) {
					const findCaption = (property: string) => {
						return result.captions!.find(
							(c) =>
								c.propertyName === property && c.language === questionnaireLanguage,
						);
					};

					const locationCaption = findCaption("Location");
					const startDateCaption = findCaption("StartDate");
					const managerCaption = findCaption("Manager");
					const orgUnitCaption = findCaption("OrgUnit");
					const entityReferenceCaption = findCaption("EntityReference");

					const captions: ProjectCaptions = {
						locationCaption: locationCaption
							? locationCaption.displayText
							: t("display:labelProjectLocation"),
						startDateCaption: startDateCaption
							? startDateCaption.displayText
							: t("display:labelProjectStartDate"),
						managerCaption: managerCaption
							? managerCaption.displayText
							: t("display:labelProjectManager"),
						orgUnitIdCaption: orgUnitCaption
							? orgUnitCaption.displayText
							: questionnaireOrgUnitLabel,
						referenceCaption: entityReferenceCaption
							? entityReferenceCaption.displayText
							: t("display:labelProjectReference"),
					};

					localStateDispatch({
						type: ProjectsLocalActionTypes.SetCaptions,
						captions,
					});
				}

				if (result.projects.length === 0) {
					localStateDispatch({
						type: ProjectsLocalActionTypes.SetStatus,
						status: ProjectsLocalStatus.Empty,
					});
				} else {
					localStateDispatch({
						type: ProjectsLocalActionTypes.SetProjects,
						projects: result,
					});

					localStateDispatch({
						type: ProjectsLocalActionTypes.SetStatus,
						status: ProjectsLocalStatus.Success,
					});
				}
			})
			.catch((e) => {
				localStateDispatch({
					type: ProjectsLocalActionTypes.SetStatus,
					status: ProjectsLocalStatus.Error,
				});
				logException(e);
			});
		return () => cancelToken.cancel();
	}, [
		customerKey,
		localState.page,
		localState.portalCulture,
		localState.searchTerm,
		localState.sortOrder,
		localState.sortProperty,
		localStateDispatch,
		orgUnitID,
		orgUnits,
		questionnaireLanguage,
		questionnaireOrgUnitLabel,
		t,
		token,
	]);
};
