import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Button } from "../../../../components/input/Button";
import { ReactComponent as DownloadIcon } from "../../../../assets/svg/download--dark.svg";
import { FormModal } from "../../../../components/modals";
import { AttachmentsList } from "../AttachmentsList/AttachmentsList";
import { DownloadManagerContext } from "../../../../components/DownloadManagerContext/DownloadManagerContext";
import { DefaultReportService } from "../../../../../services/report";
import { switchMatchingLanguageCode } from "../../../../../helpers/LanguageCodeHelper";
import { Download } from "../../../../components/DownloadManagerContext/Download";
import { isIosSafari, isIosStandalone } from "../../../../../helpers/SafariHelper";
import { ItemDetail } from "./DetailItem";
import type { Record, Info } from "../../../../../models/record";
import "./RecordCard.styles.scss";

interface RecordCardProps {
	record: Record;
	moduleCaption: string;
}

const RecordCard = ({ record, moduleCaption }: RecordCardProps) => {
	const recordTitle = record.info.find((i) => i.title === "Title");
	const [showAttachmentsModal, setShowAttachmentsModal] = useState(false);
	const [selectedAttachments, setSelectedAttachments] = useState(undefined);
	const downloadContext = useContext(DownloadManagerContext);
	const { t } = useTranslation();

	const { i18n } = useTranslation();
	const { portalKey, recordsKey } = useParams<{
		portalKey: string;
		recordsKey: string;
	}>();
	const reportService = useMemo(() => {
		return new DefaultReportService(
			{ subdomain: "Modules" },
			portalKey,
			switchMatchingLanguageCode(i18n.language) || "EnGB",
			recordsKey,
		);
	}, [i18n.language, portalKey, recordsKey]);

	const downloadLabel = useMemo(
		() =>
			isIosSafari() && isIosStandalone()
				? t("display:labelOpenReport")
				: t("display:labelRecordCardDownloadButton"),
		[t],
	);

	const handleClick = () => {
		const download = new Download(
			() =>
				new Promise((resolve, reject) => {
					reportService
						.getReport(record.recordId)
						.then((r) => resolve(r))
						.catch((e) => reject(e));
				}),
			`${moduleCaption} ${record.heading.ref}`.replace(/[\W]+/g, "_"),
		);
		download.addExtension = true;
		downloadContext.addDownload(download);
	};

	const sheAttachmentRecordInfo = record.info.find(
		(i) => i.propertyName === "SheAttachments" || i.propertyName === "Attachments",
	) as any;

	return (
		<article className="she-record-card">
			<header className="she-record-card__header">
				<div className="she-record-card__header-title">{record.heading.title}</div>
				<div className="she-record-card__header-ref">
					<span>{t("display:labelRecordCardRef")}</span> {record.heading.ref}
				</div>
			</header>

			<div className="she-record-card__body">
				<dl className="she-record-card__list">
					{record.info.map((infoItem: Info, i: number) => (
						<div className="she-record-card__list-item" key={i}>
							<dt className="she-record-card__list-item-title">{infoItem.title}</dt>
							<dd className="she-record-card__list-item-detail">
								<ItemDetail
									detail={infoItem.detail}
									propertyName={infoItem.propertyName}
								/>
							</dd>
						</div>
					))}
				</dl>
				<div className="she-record-card__button-container">
					{record.hasAttachments ? (
						<Button
							data-testid="record_card-show_attachments"
							onClick={() => {
								setSelectedAttachments(JSON.parse(sheAttachmentRecordInfo.detail));
								setShowAttachmentsModal(true);
							}}
							variant="secondary"
						>
							{t("display:labelViewAttachments")}
						</Button>
					) : null}
					{record.hasReport ? (
						<Button icon={<DownloadIcon />} onClick={handleClick} variant="secondary">
							{downloadLabel}
						</Button>
					) : null}
				</div>
			</div>
			<FormModal
				cancelText={t("global:close")}
				large
				onCancel={() => {
					setShowAttachmentsModal(false);
				}}
				onOk={() => {}}
				padded={false}
				primaryCancelBtn
				show={showAttachmentsModal}
				showOkBtn={false}
				title={t("display:labelAttachments")}
			>
				<AttachmentsList
					attachments={selectedAttachments}
					recordTitle={recordTitle && recordTitle.detail}
				/>
			</FormModal>
		</article>
	);
};

export { RecordCard };
