import { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { MultiSelectFieldValidator } from "../../../../validators/multiSelectFieldValidator/multiSelectFieldValidator";
import { MultiSelectInputPresentation } from "./MultiSelectInput.presentation";
import type { MultiSelectField } from "../../../../models/fields/MultiSelectField";
import type { State } from "../../../../state";

interface Props {
	multiSelectField: MultiSelectField;
	updateField: (fieldId: number, value: any) => void;
	updateValidationStatus: (fieldId: number, value: string[]) => void;
}

export const MultiSelectInputContainer = ({
	multiSelectField,
	updateField,
	updateValidationStatus,
}: Props) => {
	const [values, setValues] = useState(multiSelectField.value || []);
	const dictionaries = useSelector((s: State) => s.questionnaire.dictionaries);

	const availableData = useMemo(() => {
		if (multiSelectField.data.size > 0) {
			return multiSelectField.data;
		}

		const dictionary = dictionaries.find((d) => d.key === multiSelectField.dictionaryKey);
		if (!dictionary) {
			return new Map<any, string>();
		}
		return new Map<any, string>(dictionary.dictionaryItems.map((di) => [di.id, di.text]));
	}, [multiSelectField, dictionaries]);

	const validator = useMemo(
		() => new MultiSelectFieldValidator(multiSelectField),
		[multiSelectField],
	);

	const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const selectedValues = values;
		const numberCast = Number(event.target.value);

		if (selectedValues.includes(numberCast)) {
			const index = selectedValues.indexOf(numberCast);
			if (index > -1) {
				selectedValues.splice(index, 1);
			}
		} else {
			selectedValues.push(numberCast);
		}

		setValues(selectedValues);
		updateField(multiSelectField.id, values);

		const validationResult = validator.isFieldValid(values);
		if (validationResult !== (multiSelectField.validationMessage.length === 0)) {
			updateValidationStatus(multiSelectField.id, validator.messages);
		}
	};

	return (
		<MultiSelectInputPresentation
			availableResponses={availableData}
			guid={multiSelectField.guid}
			id={multiSelectField.id}
			isNotApplicable={multiSelectField.isNotApplicable}
			onChange={onChange}
			selectedValues={multiSelectField.value ? [...multiSelectField.value, ...values] : []}
		/>
	);
};
