interface Props {
	condition: boolean;
	parent: React.ComponentType<any>;
	parentProps?: any;
	children?: React.ReactNode;
}

export const ConditionalWrapper = ({ children, condition, parent: Parent, parentProps }: Props) => {
	return <>{condition ? <Parent {...parentProps}>{children}</Parent> : children}</>;
};
