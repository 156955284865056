import { useRef } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useClicksWithinTime } from "../../utilities/UseClicksWithinTime";
import packageJson from "../../../../package.json";
import "./Version.styles.scss";

export const VersionComponent = () => {
	const { t } = useTranslation();

	const ref = useRef<HTMLDivElement>(null);
	const match = useRouteMatch<{ customerKey: string; portalKey: string }>(
		"/:customerKey/p/:portalKey/settings",
	);
	const history = useHistory();
	const gotoAuditIndexedDbPage = () => {
		if (match) {
			const { customerKey, portalKey } = match.params;
			history.push(`/${customerKey}/p/${portalKey}/auditindexeddb`);
		}
	};
	useClicksWithinTime(ref, gotoAuditIndexedDbPage);

	return (
		<div className="she-portal-footer-version" ref={ref}>
			<label className="she-version-label">
				{t("display:labelVersion", { versionNumber: packageJson.version })}
			</label>
		</div>
	);
};
