import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "../Modal";
import { ModalFooter } from "../ModalFooter";

interface Props {
	show: boolean;
	content: string | React.ReactNode;
	onClose: () => void;
	html?: boolean;
	preWrap?: boolean;
	paddedFooter?: boolean;
	fullScreenMobile?: boolean;
	showEntryAnimation?: boolean;
	closeText?: string;
	withHistory?: boolean;
}

export const NotificationModal = ({
	content,
	onClose,
	closeText,
	fullScreenMobile,
	html,
	paddedFooter,
	preWrap,
	withHistory,
	show,
	showEntryAnimation,
}: Props) => {
	const { t } = useTranslation();

	const bodyClassName = useMemo(() => {
		let className = "she-components-modal-body-padded she-components-modal-body-notification";
		if (preWrap) {
			className = `${className} she-components-modal-body-prewrap`;
		}
		return className;
	}, [preWrap]);

	const closeTextMemo = useMemo(
		() => (closeText ? closeText : t("global:close")),
		[closeText, t],
	);

	return (
		<Modal
			fullScreenMobile={fullScreenMobile}
			onClose={onClose}
			show={show}
			showEntryAnimation={showEntryAnimation}
			withHistory={withHistory}
		>
			{html && typeof content === "string" ? (
				<div
					className={bodyClassName}
					dangerouslySetInnerHTML={{ __html: content }}
					data-testid="conditionalDiv"
					tabIndex={0}
				/>
			) : (
				<p className={bodyClassName} data-testid="conditionalPTag" tabIndex={0}>
					{content}
				</p>
			)}
			<ModalFooter
				buttons={[{ text: closeTextMemo, primary: true, onClick: onClose }]}
				padded={paddedFooter}
			/>
		</Modal>
	);
};
