import { SkipToMain } from "../components/SkipToMain/SkipToMain.component";
import "./FormLayout.styles.scss";

interface Props {
	children: React.ReactNode;
	header?: React.ReactNode;
	nav?: React.ReactNode;
}

const MAIN_CONTENT_ID = "main-content";

const FormLayout = ({ children, header, nav }: Props) => {
	return (
		<>
			<SkipToMain mainContentId={MAIN_CONTENT_ID} />
			<div className="she-layout-form">
				{header}
				<div className="she-row">
					{nav}
					<main className="she-layout-form__content" id={MAIN_CONTENT_ID}>
						<div className="she-layout-form__content-column">{children}</div>
					</main>
				</div>
			</div>
		</>
	);
};

export { FormLayout };
