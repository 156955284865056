import { useMemo } from "react";
import { useAuth } from "oidc-react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getBlobUrl } from "../../../helpers/BlobHelper";
import { isBrowserIE } from "../../../helpers/ieHelper";
import { ReactComponent as IconBack } from "../../assets/svg/arrow-left.svg";
import { SkipToMain } from "../components/SkipToMain/SkipToMain.component";
import { PageHeader } from "./components/PageHeader";
import { PageFooter } from "./components/PageFooter";
import { PageOptions } from "./components/PageOptions";
import type { Portal } from "../../../models/portal";
import "./PageLayout.styles.scss";

interface PageLayoutProps {
	backTo?: string;
	belowTitleBarSlot?: React.ReactNode;
	children?: React.ReactNode;
	footer?: React.ReactNode;
	heading?: string;
	portal?: Portal;
	title?: string;
	titleBarIconSlot?: React.ReactNode;
}

const MAIN_CONTENT_ID = "main-content";

export const PageLayout = ({
	backTo,
	belowTitleBarSlot,
	children,
	heading,
	portal,
	title,
	titleBarIconSlot,
}: PageLayoutProps) => {
	const brandLogo = portal?.logo && getBlobUrl(portal.logo);
	const IE11 = useMemo(() => isBrowserIE(), []);
	const { userData } = useAuth();
	const isPasswordChangeRequired = userData?.profile.forcePasswordChange;
	const history = useHistory();
	const { t } = useTranslation();

	return (
		<>
			<SkipToMain mainContentId={MAIN_CONTENT_ID} />
			<div className="she-layout-page" id="she-layout-page">
				<div className="she-row">
					{isPasswordChangeRequired ? null : <PageHeader brandLogo={brandLogo} />}

					<div className="she-layout-page__content">
						<header className="she-layout-page__content-topbar">
							{backTo ? (
								<div className="she-layout-page__content-topbar-icon-slot">
									<button
										aria-label={t("global:back")}
										className="she-icon she-questionnaire-header-icon she-layout-page__back-icon"
										onClick={() => history.push(backTo)}
										type="button"
									>
										<IconBack />
									</button>
								</div>
							) : null}
							{titleBarIconSlot ? (
								<div className="she-layout-page__content-topbar-icon-slot">
									{titleBarIconSlot}
								</div>
							) : null}

							{title ? (
								<div className="she-layout-page__content-topbar-title">{title}</div>
							) : null}
							<PageOptions />
						</header>
						<main id={MAIN_CONTENT_ID}>
							{belowTitleBarSlot}
							<div
								className="she-portal-page__container"
								style={IE11 ? { marginTop: 0 } : {}}
							>
								<div className="she-layout-page__content-column">
									{heading ? <h2>{heading}</h2> : null}

									{children}
								</div>
							</div>
						</main>

						<footer className="she-layout-page__footer">
							<PageFooter portal={portal} />
						</footer>
					</div>
				</div>
			</div>
		</>
	);
};
