import { TemplateSyncStatusContainer } from "./templateStatus";
import { TemplateError } from "./templateError";
import type { QuestionnaireStub } from "../../../../../models/portal";

interface Props {
	stub: QuestionnaireStub;
	showError: boolean;
}

export const TemplateListItem = ({ stub, showError }: Props) => {
	return (
		<li key={stub.key}>
			<div className="she-components-sync-info-content-title">
				<div data-testid="item-title">{stub.title}</div>
				<div>
					<TemplateSyncStatusContainer status={stub.questionnaireStatus} />
				</div>
			</div>
			{showError && stub.syncError && <TemplateError errorMsg={stub.syncError} />}
		</li>
	);
};
