import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ToastContext } from "../../../../components/toast";
import { Toast } from "../../../../components/toast/component/Toast";
import type { State } from "../../../../../state";

export interface PropBranchConditionArray {
	branchConditions: [BranchConditionMessage];
}

export interface BranchConditionMessage {
	istriggered: boolean;
	alertMessage: string;
}

export const AlertMessages = () => {
	const branchConditions = useSelector<State, BranchConditionMessage[] | undefined>(
		(state) => state.questionnaire.branchConditions,
	);
	const toastContext = useContext(ToastContext);
	// TODO: Look into this, why are we setting local state, but never using it???
	const [, setLocalBranchConditions] = useState<BranchConditionMessage[]>([]);

	useEffect(() => {
		branchConditions &&
			setLocalBranchConditions((prev: BranchConditionMessage[]) => {
				const activeChangesArray =
					prev.length > 0 &&
					branchConditions
						.map((el: BranchConditionMessage) => {
							return {
								istriggered: el.istriggered,
								alertMessage: el.alertMessage,
							};
						})
						.filter(
							(el, index) =>
								el.istriggered === true && prev[index].istriggered === false,
						);

				if (activeChangesArray && activeChangesArray.length > 0) {
					activeChangesArray.forEach((message: BranchConditionMessage) => {
						if (message.alertMessage) {
							let uuid = "";
							const onCloseToast = () => toastContext.removeToast(uuid);
							uuid = toastContext.putToast({
								content: (
									<Toast
										data-testid="message-display"
										onClick={() => onCloseToast()}
										variant="success"
									>
										{message.alertMessage}
									</Toast>
								),
								timeout: 5000,
							});
						}
					});
				}
				return [...branchConditions].map((el: BranchConditionMessage) => {
					return {
						istriggered: el.istriggered,
						alertMessage: el.alertMessage,
					};
				});
			});
	}, [branchConditions, toastContext]);

	return null;
};
