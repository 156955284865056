import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { ModuleListItem } from "../../../../components/ModuleListItem";
import { stringHelper } from "../../../../../helpers/StringHelper";
import type { Portal } from "../../../../../models/portal";
import type { LabelRecordsModuleTitle } from "../../../../../types/i18next";

interface RecordsViewIProps {
	caption?: string;
	portal?: Portal;
}

const ModuleList = ({ portal }: RecordsViewIProps) => {
	const installModules = useMemo(
		() =>
			portal &&
			portal.installModules &&
			portal.installModules.filter((m) => m.key !== "Policy"),
		[portal],
	);
	const { t } = useTranslation();

	if (!installModules) {
		return null;
	}

	return (
		<>
			<h2>{t("display:labelRecordSelectHeader")}</h2>
			<ul className="she-questionnaire-list">
				<TransitionGroup component={null}>
					{installModules.map((module: any) => (
						<CSSTransition
							appear
							classNames="fade"
							component={null}
							key={module.key}
							timeout={500}
						>
							<ModuleListItem
								moduleKey={module.key}
								title={
									module.caption ||
									t(
										`display:labelRecordsModuleTitle.${
											stringHelper.alphaNumericRegExLowerCase(
												module.key,
											) as LabelRecordsModuleTitle
										}`,
									)
								}
							/>
						</CSSTransition>
					))}
				</TransitionGroup>
			</ul>
		</>
	);
};

export { ModuleList };
