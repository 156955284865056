import { useParams } from "react-router-dom";

/**
 * Hook that returns current portal pathname e.g. "/customerKey/p/portalKey/".
 * @returns portal path
 */

export const usePortalPath = () => {
	const { customerKey, portalKey } = useParams<{ customerKey: string; portalKey: string }>();
	return `/${customerKey}/p/${portalKey}/`;
};
