import type { Field } from "../models/fields/Field";
import type { Dictionary } from "../models/dictionary/Dictionary";

export const FieldOrderComparer = (left: Field | undefined, right: Field | undefined): number => {
	if (!left || !right) {
		return 0;
	}

	if (left.parsedOrderIndex !== undefined && right.parsedOrderIndex !== undefined) {
		// Iterate and compare through order indexes on every level
		for (
			let i = 0;
			i <
			(left.parsedOrderIndex.length > right.parsedOrderIndex.length
				? left.parsedOrderIndex.length
				: right.parsedOrderIndex.length);
			i++
		) {
			if (left.parsedOrderIndex[i] === undefined) {
				return -1;
			} else if (right.parsedOrderIndex[i] === undefined) {
				return 1;
			} else if (left.parsedOrderIndex[i] !== right.parsedOrderIndex[i]) {
				return left.parsedOrderIndex[i] - right.parsedOrderIndex[i];
			}
		}
		return 0;
	}
	// Use simple order Index
	return left.orderIndex - right.orderIndex;
};

export const DictionarySort = (toSort: Dictionary) => {
	if (toSort.dictionaryItems.every((item) => item.orderIndex === 0)) {
		toSort.dictionaryItems.sort((a, b) => (a.text > b.text ? 1 : -1));
	} else {
		toSort.dictionaryItems.sort((item) => item.orderIndex);
	}
};
