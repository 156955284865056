import { useState, useCallback } from "react";
import { Html5Qrcode } from "html5-qrcode";
import { useTranslation } from "react-i18next";
import { Modal } from "../modals/Modal";
import { NotificationModal } from "../modals";
import { ReactComponent as QRIcon } from "../../assets/svg/scanner.svg";
import { ModalBody } from "./ScannerModalBody";

const RejectedPermissionsText = () => {
	const { t } = useTranslation();
	return (
		<div className="translation-text">
			<p>{t("display:rejectedPermissions.invalidPermissions")}</p>
			<p>{t("display:rejectedPermissions.noDeviceFound")}</p>
		</div>
	);
};
interface Props {
	onResult: (decodedResult: string) => void;
}

export const QRScanner = ({ onResult }: Props) => {
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [rejectedPermissions, setRejectedPermissions] = useState(false);

	const permissions = useCallback(() => {
		Html5Qrcode.getCameras()
			.then(() => {
				setModalIsOpen(true);
			})
			.catch(() => {
				setRejectedPermissions(true);
			});
	}, []);

	const closeModal = useCallback(() => {
		setModalIsOpen(false);
	}, []);

	const onScanComplete = useCallback(
		(decodedResult: string) => {
			setModalIsOpen(false);
			onResult(decodedResult);
		},
		[onResult],
	);

	return (
		<div className="modal-scanner-container">
			<button
				className="scanner-btn she-components-text-input"
				onClick={() => permissions()}
				type="button"
			>
				<QRIcon className="scanner-btn-icon" />
			</button>
			<NotificationModal
				content={<RejectedPermissionsText />}
				onClose={() => setRejectedPermissions(false)}
				show={rejectedPermissions}
				withHistory
			/>
			<Modal
				fullScreenMobile
				onClose={closeModal}
				shouldReturnFocusAfterClose={false}
				show={modalIsOpen}
			>
				<ModalBody closeAction={closeModal} onScanComplete={onScanComplete} />
			</Modal>
		</div>
	);
};
