import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import { DefaultMatrixFieldService as MatrixFieldService } from "../../../services/matrixField";
import { MatrixDialogPresentation } from "./MatrixDialog.presentation";
import type { State } from "../../../state";
import type { Matrix } from "../../../models/matrix/matrix";
import type { matrixEdgesList } from "../../../models/matrix/matrixEdgesList";

interface Props {
	saveRecord: (factorId: number | undefined) => void;
	cancel: () => void;
	isOpen: boolean;
	title: string;
	matrixId: number;
	currentValue: number | undefined;
}

export const MatrixDialogContainer = ({
	cancel,
	currentValue,
	isOpen,
	matrixId,
	saveRecord,
	title,
}: Props) => {
	const matrix: Matrix | undefined = useSelector<State, Matrix | undefined>((state) =>
		state.questionnaire.matrixes.find((m) => m.id === matrixId),
	);
	const service = new MatrixFieldService();
	const { t } = useTranslation();
	const dimensionXGuid = uuid();
	const dimensionYGuid = uuid();
	const currentDimensionsValues = service.getMatrixEdgesValues(currentValue, matrix);
	const [dimensionXValue, setDimensionXValue] = useState(
		currentDimensionsValues.edgeXCount.toString(),
	);
	const [dimensionYValue, setDimensionYValue] = useState(
		currentDimensionsValues.edgeYCount.toString(),
	);
	const [errorY, setErrorY] = useState("");
	const [errorX, setErrorX] = useState("");

	useEffect(() => {
		if (currentDimensionsValues && isOpen) {
			setDimensionXValue(currentDimensionsValues.edgeXCount.toString());
			setDimensionYValue(currentDimensionsValues.edgeYCount.toString());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);

	const getMatrixDropdownLists = (): matrixEdgesList => {
		return {
			dimensionXValues: service.getMatrixEdgeValuesList(matrix, 0),
			dimensionYValues: service.getMatrixEdgeValuesList(matrix, 1),
		};
	};

	const saveButtonClicked = () => {
		const valueX = parseInt(dimensionXValue);
		const valueY = parseInt(dimensionYValue);
		let valid = true;

		if (valueX === -1) {
			valid = false;
			setErrorX(t("validation:mandatoryFieldMessage"));
		} else {
			setErrorX("");
		}

		if (valueY === -1) {
			valid = false;
			setErrorY(t("validation:mandatoryFieldMessage"));
		} else {
			setErrorY("");
		}

		if (valid) {
			const newFactorId: number | undefined = service.getMatrixFactorId(
				valueX,
				valueY,
				matrix,
			);
			saveRecord(newFactorId);
		}
	};

	const cancelButtonClicked = () => {
		setDimensionXValue("");
		setDimensionYValue("");
		cancel();
	};

	const onDropdownChange = (value: string, guid: string): void => {
		switch (guid) {
			case dimensionXGuid:
				setDimensionXValue(value);
				break;
			case dimensionYGuid:
				setDimensionYValue(value);
				break;
		}
	};

	const edgesList: matrixEdgesList = getMatrixDropdownLists();

	return (
		<MatrixDialogPresentation
			cancel={cancelButtonClicked}
			captionDimensionX={matrix ? matrix.captionX : ""}
			captionDimensionY={matrix ? matrix.captionY : ""}
			dataDimensionX={edgesList.dimensionXValues}
			dataDimensionY={edgesList.dimensionYValues}
			errorDimensionX={errorX && errorX.length > 0 ? errorX : undefined}
			errorDimensionY={errorY && errorY.length > 0 ? errorY : undefined}
			guidDimensionX={dimensionXGuid}
			guidDimensionY={dimensionYGuid}
			isOpen={isOpen}
			onDropdownChange={onDropdownChange}
			saveChanges={saveButtonClicked}
			title={title}
			valueDimensionX={dimensionXValue}
			valueDimensionY={dimensionYValue}
		/>
	);
};
