// Import the Reducer type from the core Redux library
import { CommonActionTypes } from "./actions/types";
import type { Reducer } from "redux";
// Import the shape of the state for this state component
import type { CommonState } from "./model";
// Import the action definitions for this state component
import type { CommonAction } from "./actions/definitions";
// Import the action types for this state component

// define an initial state that adheres to the shape of the state required
const initialState: CommonState = {
	key: undefined,
	parameters: undefined,
	redirectUrl: "/",
	hasError: false,
};

// define and export a reducer that will potentially derive a new state from the current state and incoming actions
// to ensure type safety and expected behaviour, the Reducer type is generically typed with two type parameters
// - the shape of the state
// - and the potential actions that could affect this state
// the reducer receives two parameters
// - the current state (defaulted if undefined)
// - the action being evaluated
// its return type is implicitly set to the interface that defines the shape of the state
export const commonReducer: Reducer<CommonState, CommonAction> = (state = initialState, action) => {
	// use a switch to benefit from the discriminated union (action definitions)
	// this (or a set of ifs) is a typescript compiler feature
	switch (action.type) {
		case CommonActionTypes.ErrorHappened:
			// if the type has been found to match the given value (enum lookup for safety)
			// then within this local scope, the action will be typed correctly
			// state is derived by using the old state (... is object destructuring)
			// and combining it with the changes that reflect the current action
			if (state.key === "error:ipRestricted" && state.hasError) {
				// Ignore every other error if we are showing the restricted IP error.
				return {
					...state,
				};
			}
			return {
				...state,
				key: action.key,
				parameters: action.parameters,
				redirectUrl: action.redirectUrl,
				hasError: action.hasError,
				alternateRoutes: action.alternateRoutes || [],
				buttonText: action.buttonText,
			};
		case CommonActionTypes.CommonError:
			return {
				...state,
				commonError: action.error,
			};
	}

	// if no action is matched, then simply return the current state
	return state;
};
