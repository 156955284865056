import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Thumbnail } from "../Thumbnail";
import type { Attachment, AttachmentParentType } from "../../../models/attachments/Attachment";
import type { State } from "../../../state";
import type { LocalSectionId } from "../../../models/questionnaire";
import "./ThumbnailList.styles.scss";

interface Props {
	parentGuid?: string;
	questionnaireId: string;
	displayNoAttachmentsMessage: boolean;
	parentType: AttachmentParentType;
	subModuleId?: LocalSectionId;
}

export const ThumbnailList = ({
	displayNoAttachmentsMessage,
	parentType,
	questionnaireId,
	parentGuid,
	subModuleId,
}: Props) => {
	const { t } = useTranslation();
	const attachments = useSelector<State, Attachment[]>((state) =>
		state.recordAttachments.attachments.filter(
			(a) =>
				a.recordId === questionnaireId &&
				a.parentGuid === parentGuid &&
				a.parentType === parentType,
		),
	);
	const uploadGuidance = useSelector<State, string | undefined>(
		(state) => state.questionnaire.questionnaire?.uploadGuidance,
	);

	return (
		<>
			{uploadGuidance ? (
				<div className="she-thumbnail-list__guidance">{uploadGuidance}</div>
			) : null}
			{attachments.length ? (
				<ul className="she-thumbnail-list__wrapper">
					{attachments.map((attachment) => {
						return (
							<li className="she-thumbnail-list__image-wrapper" key={attachment.id}>
								<Thumbnail attachment={attachment} subModuleId={subModuleId} />
							</li>
						);
					})}
				</ul>
			) : null}
			{!attachments.length && displayNoAttachmentsMessage ? (
				<div className="she-thumbnail-list__empty-state">
					{t("display:labelNoAttachmentsMessage")}
				</div>
			) : null}
		</>
	);
};
