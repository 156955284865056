import { useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PortalViewType } from "../../../models/portal";
import { isBrowserIE } from "../../../helpers/ieHelper";
import { usePortalPath } from "../../../helpers/UsePortalPath";
import { NavTabGroup } from "../../components/NavTabs";
import { PageLayout } from "../../layouts/PageLayout";
import { PortalHeader } from "../DocumentsPage/components/PortalHeader";
import { PortalHomeView } from "./components/HomeView";
import { PortalInProgressView } from "./components/InProgressView";
import { PortalQueuedView } from "./components/QueuedView";
import { FormsStatsContext } from "./components/FormsStatsContext/FormsStatsContext";
import type { TabProps } from "../../components/NavTabs/NavTab";
import type { State } from "../../../state";
import type { Portal } from "../../../models/portal";
import "./PortalPage.styles.scss";

interface Props {
	uploadErrors: boolean;
}

export const PortalPage = ({ uploadErrors }: Props) => {
	const { t } = useTranslation();
	const { customerKey, portalKey, formsTab } = useParams<{
		customerKey: string;
		portalKey: string;
		formsTab: string;
	}>();
	const offlineEnabled = !isBrowserIE();
	const portal = useSelector<State, Portal | undefined>((s) =>
		s.portal.portals.find(
			(p) => p.key === portalKey.toLowerCase() && p.customerKey === customerKey.toLowerCase(),
		),
	);
	const portalPath = usePortalPath();

	const { inProgress, queued } = useContext(FormsStatsContext);

	const tabs = useMemo<TabProps[]>(
		() =>
			[
				{
					to: portalPath,
					label: t("display:labelNavigationNew"),
					exact: true,
				},
				{
					to: portalPath + PortalViewType.InProgress,
					label: t("display:labelNavigationInProgress"),
					pill: inProgress
						? inProgress <= 99
							? inProgress.toString()
							: t("display:moreThan99")
						: undefined,
				},
				{
					to: portalPath + PortalViewType.Queued,
					label: t("display:labelNavigationQueued"),
					pill: queued
						? queued <= 99
							? queued.toString()
							: t("display:moreThan99")
						: undefined,
					alert: uploadErrors,
				},
			] as TabProps[],
		[portalPath, uploadErrors, t, inProgress, queued],
	);

	return (
		<PageLayout
			belowTitleBarSlot={
				portal && offlineEnabled ? (
					<NavTabGroup label={t("display:labelNavigationForms")} tabs={tabs} />
				) : null
			}
			portal={portal}
			title={portal ? portal.title : ""}
		>
			{portal && (
				<>
					{(!formsTab || formsTab === PortalViewType.Forms) && (
						<>
							<PortalHeader portal={portal} /> <PortalHomeView portal={portal} />
						</>
					)}
					{formsTab === PortalViewType.InProgress && (
						<PortalInProgressView portal={portal} />
					)}
					{formsTab === PortalViewType.Queued && <PortalQueuedView portal={portal} />}
				</>
			)}
		</PageLayout>
	);
};
