import { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
	getLeadingZeroValue,
	setElementsFocus,
	isKeyDownValid,
	isPasteValid,
} from "../../../../helpers/DateTimeInputHelper";
import { AnimationWrapper } from "../../animation/AnimationWrapper";
import { AnimationSlideFade } from "../../animation/AnimationSlideFade";
import type { IDateValues } from "../../../../helpers/DateTimeInputHelper";
import "./styles.scss";

interface Props {
	name: string;
	required: boolean;
	values: {
		day: string;
		month: string;
		year: string;
	};
	hint: string;
	guid: string;
	displayMonthBeforeDay: boolean;
	isNotApplicable: boolean;
	onChange: (values: IDateValues) => void;
	onTodayClick: () => void;
}

export const DateInputPresentation = ({
	displayMonthBeforeDay,
	guid,
	hint,
	isNotApplicable,
	name,
	onChange,
	onTodayClick,
	required,
	values,
}: Props) => {
	const { t } = useTranslation();

	const dayRef = useRef<HTMLInputElement>(null);
	const monthRef = useRef<HTMLInputElement>(null);
	const yearRef = useRef<HTMLInputElement>(null);

	const dayName = `${name}_day`;
	const monthName = `${name}_month`;
	const yearName = `${name}_year`;
	const [measureHeight, setMeasureHeight] = useState(false);

	useEffect(() => {
		if (isNotApplicable && !measureHeight) {
			setMeasureHeight(true);
		} else if (!isNotApplicable && measureHeight) {
			setMeasureHeight(false);
		}
	}, [isNotApplicable, measureHeight]);

	const setLeadingZero = (valueToUse: any, updateDayValue: boolean) => {
		if (valueToUse.length === 1) {
			valueToUse = getLeadingZeroValue(valueToUse);
			updateDayValue ? (values.day = valueToUse) : (values.month = valueToUse);
			onChange(values);
		}
	};

	const dayInput = (
		<label className="she-components-text-input__date-inline">
			<span className="she-components-text-input__date-label" id={`${guid}-label-day`}>
				{t("display:labelDay")}
			</span>

			<input
				aria-labelledby={`${guid}-label ${guid}-label-day ${guid}-description ${guid}-date-hint`}
				aria-required={required}
				className="she-components-text-input"
				id={`${guid}_day`}
				inputMode="numeric"
				maxLength={2}
				name={dayName}
				onBlur={(e) => {
					setLeadingZero(e.target.value, true);
				}}
				onChange={(e) => {
					values.day = e.target.value;
					onChange(values);
					setElementsFocus(e, displayMonthBeforeDay ? yearRef : monthRef);
				}}
				onKeyDown={isKeyDownValid}
				onPaste={isPasteValid}
				placeholder={t("display:placeHolderDay")}
				ref={dayRef}
				required={required}
				type="text"
				value={values.day}
			/>
		</label>
	);

	const monthInput = (
		<label className="she-components-text-input__date-inline">
			<span className="she-components-text-input__date-label" id={`${guid}-label-month`}>
				{t("display:labelMonth")}
			</span>

			<input
				aria-labelledby={`${guid}-label ${guid}-label-month ${guid}-description ${guid}-date-hint`}
				aria-required={required}
				className="she-components-text-input"
				id={`${guid}_month`}
				inputMode="numeric"
				maxLength={2}
				name={monthName}
				onBlur={(e) => {
					setLeadingZero(e.target.value, false);
				}}
				onChange={(e) => {
					values.month = e.target.value;
					onChange(values);
					setElementsFocus(e, displayMonthBeforeDay ? dayRef : yearRef);
				}}
				onKeyDown={isKeyDownValid}
				onPaste={isPasteValid}
				placeholder={t("display:placeHolderMonth")}
				ref={monthRef}
				required={required}
				type="text"
				value={values.month}
			/>
		</label>
	);

	const yearInput = (
		<label className="she-components-text-input__date-inline she-components-text-input__date-inline--long">
			<span className="she-components-text-input__date-label" id={`${guid}-label-year`}>
				{t("display:labelYear")}
			</span>

			<input
				aria-labelledby={`${guid}-label ${guid}-label-year ${guid}-description ${guid}-date-hint`}
				aria-required={required}
				className="she-components-text-input"
				id={`${guid}_year`}
				inputMode="numeric"
				maxLength={4}
				name={yearName}
				onChange={(e) => {
					values.year = e.target.value;
					onChange(values);
				}}
				onKeyDown={isKeyDownValid}
				onPaste={isPasteValid}
				placeholder={t("display:placeHolderYear")}
				ref={yearRef}
				required={required}
				type="text"
				value={values.year}
			/>
		</label>
	);

	return (
		<AnimationWrapper>
			{!isNotApplicable && (
				<AnimationSlideFade key={guid} measure={measureHeight}>
					<div className="she-control-hint" id={`${guid}-date-hint`}>
						{hint}
					</div>

					<div className="she-date-wrapper">
						{displayMonthBeforeDay ? (
							<>
								{monthInput}
								{dayInput}
								{yearInput}
							</>
						) : (
							<>
								{dayInput}
								{monthInput}
								{yearInput}
							</>
						)}

						<div className="she-date-button-wrapper">
							<button
								className="she-btn she-btn-tertiary"
								id={`${guid}_today`}
								onClick={onTodayClick}
								type="button"
							>
								{t("display:buttonToday")}
							</button>
						</div>
					</div>
				</AnimationSlideFade>
			)}
		</AnimationWrapper>
	);
};
