import { useEffect } from "react";
import { useRouteMatch } from "react-router-dom";

interface Props {
	observe?: any;
	scroll?: boolean;
	excludeRoute?: string | string[];
	children?: React.ReactNode;
}

export const ScrollRootToTop = ({ observe, scroll = true, excludeRoute, children }: Props) => {
	const excludedRoute = useRouteMatch(excludeRoute || []);
	useEffect(() => {
		if (scroll && !excludedRoute) {
			window.scrollTo(0, -50);
			setTimeout(() => {
				window.scrollTo(0, 0);
			}, 1);
		}
		// Rerender only if observed value changes
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [observe]);

	return <>{children}</>;
};
