import { useMemo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextFieldValidator } from "../../../../validators/textFieldValidator";
import { SignatureFieldValidator } from "../../../../validators/signatureFieldValidator";
import { UpdatableFieldProperty } from "../../../../state/components/questionnaire/actions/enums";
import { ConfirmationModal } from "../../modals";
import { IQSignatureInputPresentation } from "./IQSignatureInput.presentation";
import type { IQSignatureField } from "../../../../models/fields/IQSignatureField";

interface Props {
	iQSignatureField: IQSignatureField;
	updateFieldProperty: (
		fieldId: number,
		value: any,
		propertyName: UpdatableFieldProperty,
	) => void;
	updateValidationStatus: (fieldId: number, value: string[]) => void;
}

export const IQSignatureInput = ({
	iQSignatureField,
	updateFieldProperty,
	updateValidationStatus,
}: Props) => {
	const { t } = useTranslation();
	const [showModal, setShowModal] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [addButtonVisible, setAddButtonVisible] = useState(iQSignatureField.isNotApplicable);
	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
	const fullNameValidator = useMemo(
		() => new TextFieldValidator(iQSignatureField.fullname),
		[iQSignatureField.fullname],
	);
	const descriptionValidator = useMemo(
		() => new TextFieldValidator(iQSignatureField.description),
		[iQSignatureField.description],
	);
	const signatureValidator = useMemo(
		() => new SignatureFieldValidator(iQSignatureField.signature),
		[iQSignatureField.signature],
	);
	const [statesFullName, setStatesFullName] = useState(iQSignatureField.fullname.value);
	const [statesDescription, setStatesDescription] = useState(iQSignatureField.description.value);
	const [statesSignature, setStatesSignature] = useState(iQSignatureField.signature.value);
	const [fullNameValMessages, setFullNameValMessages] = useState<string[]>([]);
	const [descriptionValMessages, setDescriptionValMessages] = useState<string[]>([]);
	const [signatureValMessages, setSignatureValMessages] = useState<string[]>([]);
	const deleteMessage = useMemo(
		() => t("display:labelDeleteSubModuleAreYouSure", { record: iQSignatureField.name }),
		[],
	);

	const updateFullName = (value: any) => {
		setStatesFullName(value);
	};

	const updateFullNameValidation = (value: any) => {
		setFullNameValMessages(value);
	};

	const updateDescription = (value: any) => {
		setStatesDescription(value);
	};

	const updateDescriptionValidation = (value: any) => {
		setDescriptionValMessages(value);
	};

	const updateSignature = (value: any) => {
		setStatesSignature(value);
	};

	const updateSignatureValidation = (value: any) => {
		setSignatureValMessages(value);
	};

	const saveSignature = () => {
		const isSignatureValid = signatureValidator.isFieldValid(statesSignature);
		setSignatureValMessages(signatureValidator.messages);
		const isFullNameValid = fullNameValidator.isFieldValid(statesFullName);
		setFullNameValMessages(fullNameValidator.messages);
		const isDescriptionValid = descriptionValidator.isFieldValid(statesDescription);
		setDescriptionValMessages(descriptionValidator.messages);

		if (isSignatureValid && isFullNameValid && isDescriptionValid) {
			updateFieldProperty(
				iQSignatureField.id,
				statesFullName,
				UpdatableFieldProperty.IQSignatureName,
			);

			updateFieldProperty(
				iQSignatureField.id,
				statesDescription,
				UpdatableFieldProperty.IQSignatureDescription,
			);

			updateFieldProperty(
				iQSignatureField.id,
				statesSignature,
				UpdatableFieldProperty.IQSignatureSignature,
			);

			updateValidationStatus(iQSignatureField.id, []);
			setShowModal(false);
		}
	};

	const cancelSignature = () => {
		setShowModal(false);
		setSignatureValMessages([]);
		setFullNameValMessages([]);
		setDescriptionValMessages([]);
	};

	const editSignature = () => {
		if (!iQSignatureField.isNotApplicable) {
			setStatesFullName(iQSignatureField.fullname.value);
			setStatesDescription(iQSignatureField.description.value);
			setIsEdit(true);
			setShowModal(true);
		}
	};

	const handleAddSignatureButton = () => {
		setStatesFullName(iQSignatureField.fullname.value);
		setStatesDescription(iQSignatureField.description.value);
		setStatesSignature(iQSignatureField.signature.value);
		setShowModal(true);
	};

	const removeSignature = () => {
		setIsEdit(false);
		setStatesFullName(undefined);
		setStatesDescription(undefined);
		setStatesSignature(undefined);
		updateFieldProperty(iQSignatureField.id, undefined, UpdatableFieldProperty.IQSignatureName);

		updateFieldProperty(
			iQSignatureField.id,
			undefined,
			UpdatableFieldProperty.IQSignatureDescription,
		);

		updateFieldProperty(
			iQSignatureField.id,
			undefined,
			UpdatableFieldProperty.IQSignatureSignature,
		);
	};

	const deleteSignature = () => {
		setDeleteConfirmOpen(true);
	};

	const onKeyPress = (key: string) => {
		if (key === "Enter" || key === " ") {
			editSignature();
		}
	};

	useEffect(() => {
		setAddButtonVisible(!iQSignatureField.isNotApplicable);
	}, [iQSignatureField.isNotApplicable, setAddButtonVisible]);

	return (
		<>
			<IQSignatureInputPresentation
				addButtonVisible={addButtonVisible}
				cancelSignature={cancelSignature}
				descriptionValMessages={descriptionValMessages}
				descriptionValidator={descriptionValidator}
				editSignature={editSignature}
				fullNameValMessages={fullNameValMessages}
				fullNameValidator={fullNameValidator}
				handleAddSignatureButton={handleAddSignatureButton}
				iQSignatureField={iQSignatureField}
				isEdit={isEdit}
				onKeyPress={onKeyPress}
				removeSignature={deleteSignature}
				saveSignature={saveSignature}
				showModal={showModal}
				signatureValMessages={signatureValMessages}
				signatureValidator={signatureValidator}
				updateDescription={updateDescription}
				updateDescriptionValidationStatus={updateDescriptionValidation}
				updateFullName={updateFullName}
				updateFullNameValidationStatus={updateFullNameValidation}
				updateSignature={updateSignature}
				updateSignatureValidationStatus={updateSignatureValidation}
			/>
			<ConfirmationModal
				onCancel={() => {
					setDeleteConfirmOpen(false);
				}}
				onConfirm={() => {
					setDeleteConfirmOpen(false);
					removeSignature();
				}}
				show={deleteConfirmOpen}
				text={deleteMessage}
			/>
		</>
	);
};
