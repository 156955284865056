import * as AreaIcons from "../../../../assets/svg/area-icons";

const iconMap: Record<string, JSX.Element> = {
	GhsSDS: <AreaIcons.Risk />,
	COSHH: <AreaIcons.Risk />,
	SupplierContractor: <AreaIcons.Contractor />,
	GeneralRisk: <AreaIcons.Risk />,
	ManualHandling: <AreaIcons.Risk />,
	COSHHActivity: <AreaIcons.Risk />,
	ActivityRisk: <AreaIcons.Risk />,
	Policy: <AreaIcons.Policy />,
	MSDS: <AreaIcons.Risk />,
	Noise: <AreaIcons.Risk />,
	JobHazardAnalysis: <AreaIcons.Risk />,
	FireRisk: <AreaIcons.Risk />,
	ProjectRisk: <AreaIcons.Risk />,
	DSE: <AreaIcons.Risk />,
};

interface ModuleListItemThumbnailProps {
	imageUrl?: string;
	moduleKey: string;
}

const ModuleListItemThumbnail = ({ imageUrl, moduleKey }: ModuleListItemThumbnailProps) => {
	return (
		<div className={`she-module-card__thumbnail she-module-card__thumbnail--${moduleKey}`}>
			{!imageUrl ? (
				iconMap[moduleKey]
			) : (
				<div
					className="she-module-card__thumbnail-image"
					style={{ backgroundImage: `url(${imageUrl})` }}
				/>
			)}
		</div>
	);
};

export { ModuleListItemThumbnail };
