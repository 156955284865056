import { ActionPropertyType, ActionSortOrder } from "../../../../models/action";
import type { ActionSortPropertyInfo } from "../../../../models/action";

const dateProperties = ["DueDate"];
const priorityProperties = ["Priority"];
const percentProperties = ["CompletePercent"];

const allowedProperties = [
	"CompletePercent",
	"OrgUnitName",
	"Priority",
	"ActionHeader",
	"DueDate",
	"ModuleDescrip",
	"RecordReference",
];

export const mapSortProperties = (sortProperties: any): ActionSortPropertyInfo[] => {
	return sortProperties
		.sort((a: any, b: any) => {
			return a.Caption < b.Caption ? -1 : 1;
		})
		.reduce((acc: ActionSortPropertyInfo[], s: any) => {
			if (s.Visible && allowedProperties.includes(s.PropertyName)) {
				return [...acc, ...mapSortProperty(s)];
			}
			return acc;
		}, []);
};

const mapSortProperty = (sortProperty: any): ActionSortPropertyInfo[] => {
	return [
		{
			propertyName: sortProperty.PropertyName,
			caption: sortProperty.Caption,
			type: mapPropertyToType(sortProperty.PropertyName),
			order: ActionSortOrder.Asc,
		},
		{
			propertyName: sortProperty.PropertyName,
			caption: sortProperty.Caption,
			type: mapPropertyToType(sortProperty.PropertyName),
			order: ActionSortOrder.Desc,
		},
	];
};

const mapPropertyToType = (property: string) => {
	if (dateProperties.includes(property)) {
		return ActionPropertyType.Date;
	} else if (priorityProperties.includes(property)) {
		return ActionPropertyType.Priority;
	} else if (percentProperties.includes(property)) {
		return ActionPropertyType.Percent;
	}
	return ActionPropertyType.String;
};
