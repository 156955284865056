import { useTranslation } from "react-i18next";
import TextareaAutosize from "react-textarea-autosize";
import { v4 as uuid } from "uuid";
import { AnimationWrapper } from "../../animation/AnimationWrapper";
import { AnimationSlideFade } from "../../animation/AnimationSlideFade";

interface Props {
	onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
	onAddComment: () => void;
	onBlur: () => void;
	fieldGuid: string;
	comments: string;
	isVisible: boolean;
	isInvalid: boolean;
	isIq: boolean;
	isEnabled: boolean;
	isMandatory: boolean;
	autoFocus: boolean;
}

export const CommentBoxPresentation = ({
	autoFocus,
	comments,
	fieldGuid,
	isEnabled,
	isInvalid,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	isIq,
	isMandatory,
	isVisible,
	onAddComment,
	onBlur,
	onChange,
}: Props) => {
	const { t } = useTranslation();
	const id = uuid();

	return (
		<AnimationWrapper>
			{!isVisible && isEnabled && (
				<AnimationSlideFade key={1}>
					<button
						className="she-btn she-btn-tertiary"
						onClick={onAddComment}
						type="button"
					>
						{t("display:buttonAddComment")}
					</button>
				</AnimationSlideFade>
			)}
			{isVisible && (
				<AnimationSlideFade key={2} measure>
					<label className="she-components-control-subtitle" htmlFor={id}>
						{t("display:labelComment")}
						<abbr
							className="she-components-control-required-indicator"
							title={isMandatory ? "required" : "optional"}
						>
							{isMandatory ? " * " : ""}
						</abbr>
					</label>
					<TextareaAutosize
						aria-describedby={`${fieldGuid}-comment`}
						aria-invalid={isInvalid}
						aria-required={isMandatory}
						// eslint-disable-next-line jsx-a11y/no-autofocus
						autoFocus={autoFocus}
						className="she-components-text-input"
						id={id}
						onBlur={onBlur}
						onChange={onChange}
						value={comments}
					/>
				</AnimationSlideFade>
			)}
		</AnimationWrapper>
	);
};
