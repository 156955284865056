import { useMemo } from "react";
import { CollapsibleLabelList } from "../../../generic/CollapsibleLabelList";
import { RadioButton } from "../../../input/RadioButton";
import type { CollapsibleLabelListData } from "../../../generic/CollapsibleLabelList";
import type { RegisterEntity } from "../../../../../models/registerEntity";
import "./OptionalRegisterSelectInputListItem.styles.scss";

interface Props {
	entity: RegisterEntity;
	groupText: string;
	selected: boolean;
	onSelectEntity: (entity: RegisterEntity) => void;
}

const STRONG_PROPERTIES = ["EntityReference"];

export const OptionalRegisterSelectInputListItem = ({
	entity,
	groupText,
	selected,
	onSelectEntity,
}: Props) => {
	const list = useMemo(
		() =>
			entity.info.reduce<CollapsibleLabelListData[]>((accumulator, info) => {
				accumulator.push({
					key: info.title,
					value: info.value,
					strong: STRONG_PROPERTIES.includes(info.propertyName),
				});

				return accumulator;
			}, []),
		[entity],
	);

	return (
		<div className="she-entity-list-item" key={entity.id}>
			<RadioButton
				id={`${groupText}-${entity.id}`}
				key={entity.id}
				name={`${groupText}`}
				onClick={() => onSelectEntity(entity)}
				selected={selected}
			>
				<CollapsibleLabelList listItems={list} />
			</RadioButton>
		</div>
	);
};
