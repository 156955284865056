import * as AreaIcons from "../../../../assets/svg/area-icons";
import "./PortalQuestionnaireThumbnail.styles.scss";

const iconMap: Record<string, JSX.Element> = {
	assets: <AreaIcons.Assets />,
	audit: <AreaIcons.Audit />,
	behaviour: <AreaIcons.Behaviour />,
	contractor: <AreaIcons.Contractor />,
	environment: <AreaIcons.Environment />,
	incident: <AreaIcons.Incident />,
	people: <AreaIcons.People />,
	risk: <AreaIcons.Risk />,
	insights: <AreaIcons.Insights />,
	policy: <AreaIcons.Policy />,
};

interface Props {
	imageUrl?: string;
	module: string;
}

export const PortalQuestionnaireThumbnail = ({ module, imageUrl }: Props) => {
	return (
		<div className={`she-portal-questionnaire-thumbnail ${module.toLowerCase()}`}>
			{!imageUrl ? (
				iconMap[module.toLowerCase()]
			) : (
				<div
					className="she-portal-questionnaire-thumbnail-image"
					style={{ backgroundImage: `url(${imageUrl})` }}
				/>
			)}
		</div>
	);
};
