import { useTranslation } from "react-i18next";
import { Dropdown } from "../input/DropDown";
import { FormModal } from "../modals";
import styles from "./styles.module.scss";

interface Props {
	saveChanges: () => void;
	cancel: () => void;
	onDropdownChange: (value: string, guid: string) => void;
	isOpen: boolean;
	title: string;
	dataDimensionX: any[];
	dataDimensionY: any[];
	guidDimensionX: string;
	guidDimensionY: string;
	valueDimensionX: string;
	valueDimensionY: string;
	captionDimensionX: string;
	captionDimensionY: string;
	errorDimensionX: string | undefined;
	errorDimensionY: string | undefined;
}

export const MatrixDialogPresentation = ({
	saveChanges,
	cancel,
	onDropdownChange,
	isOpen,
	title,
	dataDimensionX,
	dataDimensionY,
	guidDimensionX,
	guidDimensionY,
	valueDimensionX,
	valueDimensionY,
	captionDimensionX,
	captionDimensionY,
	errorDimensionX,
	errorDimensionY,
}: Props) => {
	const { t } = useTranslation();

	return (
		<FormModal onCancel={cancel} onOk={saveChanges} show={isOpen} title={title}>
			<div>
				<div className={`${errorDimensionX && styles.sheControlError} ${styles.field}`}>
					<label htmlFor={guidDimensionX} id={`${guidDimensionX}-label`}>
						{captionDimensionX}{" "}
						<abbr className={styles.sheRequiredIndicator} title={t("global:required")}>
							*
						</abbr>
					</label>
					<div className={styles.riskMatrix}>
						{errorDimensionX && (
							<div className={styles.sheControlValidation}>{errorDimensionX}</div>
						)}
						<Dropdown
							data={dataDimensionX}
							guid={guidDimensionX}
							isMandatory
							isTopOptionTitle
							name={captionDimensionX}
							onChange={onDropdownChange}
							topOptionTitle={`${t("global:select")} ${captionDimensionX}`}
							value={valueDimensionX}
						/>
					</div>
				</div>
				<div className={`${errorDimensionY && styles.sheControlError} ${styles.field}`}>
					<label htmlFor={guidDimensionY} id={`${guidDimensionY}-label`}>
						{captionDimensionY}{" "}
						<abbr className={styles.sheRequiredIndicator} title={t("global:required")}>
							*
						</abbr>
					</label>
					<div className={styles.riskMatrix}>
						{errorDimensionY && (
							<div className={styles.sheControlValidation}>{errorDimensionY}</div>
						)}
						<Dropdown
							data={dataDimensionY}
							guid={guidDimensionY}
							isMandatory
							isTopOptionTitle
							name={captionDimensionY}
							onChange={onDropdownChange}
							topOptionTitle={`${t("global:select")} ${captionDimensionY}`}
							value={valueDimensionY}
						/>
					</div>
				</div>
			</div>
		</FormModal>
	);
};
