import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { DictionarySort } from "../../helpers/SortingHelper";
import { FieldType, SelectFieldType, LocalFieldIds } from "../../models/questionnaire";
import type { Dictionary } from "../../models/dictionary/Dictionary";
import type { DictionaryItem } from "../../models/dictionary/DictionaryItem";
import type { OrgUnitField } from "../../models/fields/OrgUnitField";
import type { SelectableTextField } from "../../models/fields/SelectableTextField";
import type { SingleSelectField } from "../../models/fields/SingleSelectField";
import type { State } from "../../state";

export const useFullDictionary = (
	field: SelectableTextField,
	searchTerm?: string,
): [number, string, string][][] | undefined => {
	const parentField = useSelector<State, SingleSelectField | undefined>((state) => {
		return state.questionnaire.fields.find(
			(f) =>
				f.type === FieldType.Select &&
				f.selectType === SelectFieldType.SingleSelect &&
				(f as SingleSelectField).dictionaryKey &&
				(f as SingleSelectField).dictionaryKey === field.parentDictionaryKey,
		) as SingleSelectField;
	});
	const parentDictionary = useSelector<State, Dictionary | undefined>((state) =>
		state.questionnaire.dictionaries.find((d) => d.key === field.parentDictionaryKey),
	);
	const dictionary = useSelector<State, Dictionary | undefined>((state) =>
		state.questionnaire.dictionaries.find((d) => d.key === field.dictionaryKey),
	);
	const orgUnitField = useSelector<State, OrgUnitField | undefined>(
		(state) =>
			state.questionnaire.fields.find((f) => f.id === LocalFieldIds.OrgUnit) as OrgUnitField,
	);
	const searchTermLowerCase = useMemo(
		() => searchTerm && searchTerm.trim().toLocaleLowerCase(),
		[searchTerm],
	);

	useEffect(() => {
		if (parentDictionary) {
			DictionarySort(parentDictionary);
		}
		if (dictionary) {
			DictionarySort(dictionary);
		}
	}, [parentDictionary, dictionary]);

	return useMemo(() => {
		if (
			(!field.data || field.data.size === 0) &&
			(!parentField || !parentField.value) &&
			parentDictionary &&
			parentDictionary.dictionaryItems.length &&
			dictionary &&
			dictionary.dictionaryItems.length &&
			orgUnitField
		) {
			let elligibleDictionaryItems: DictionaryItem[];
			if (orgUnitField.value) {
				elligibleDictionaryItems = dictionary.dictionaryItems.filter(
					(di) =>
						di.orgUnitIds.includes(orgUnitField.value || 0) ||
						di.orgUnitIds.length === 0,
				);
			} else {
				elligibleDictionaryItems = dictionary.dictionaryItems;
			}

			const unparentedItems: [number, string, string][][] = [
				elligibleDictionaryItems
					.filter((item) => !item.parentDictionaryItemId)
					.filter(
						(item) =>
							!searchTermLowerCase ||
							(searchTermLowerCase &&
								item.text.toLocaleLowerCase().includes(searchTermLowerCase)),
					)
					.map((item) => [item.id, item.text, ""]),
			];

			const parentedItems: [number, string, string][][] =
				parentDictionary.dictionaryItems.map((parentItem) => {
					return elligibleDictionaryItems
						.filter((item) => item.parentDictionaryItemId === parentItem.id)
						.filter(
							(item) =>
								!searchTermLowerCase ||
								(searchTermLowerCase &&
									item.text.toLocaleLowerCase().includes(searchTermLowerCase)) ||
								(searchTermLowerCase &&
									parentItem.text
										.toLocaleLowerCase()
										.includes(searchTermLowerCase)),
						)
						.map((item) => [item.id, item.text, parentItem.text]);
				});
			return unparentedItems.concat(parentedItems);
		}
	}, [dictionary, field.data, orgUnitField, parentDictionary, parentField, searchTermLowerCase]);
};
