import { QuestionnaireStatus } from "../../../../../../models/portal";
import loading from "../../../../../assets/gif/loading.gif";

interface Props {
	status: QuestionnaireStatus;
}

export const TemplateSyncStatusContainer = ({ status }: Props) => {
	return (
		<div data-testid="template-sync-container">
			{(() => {
				switch (status) {
					case QuestionnaireStatus.Pending:
						return null;
					case QuestionnaireStatus.Downloading:
						return (
							<img
								alt="loading"
								className="she-components-sync-info-spinner"
								data-testid="template-sync-image"
								src={loading}
							/>
						);
					case QuestionnaireStatus.Downloaded:
						return (
							<span
								className="she-components-sync-info-status"
								data-testid="template-sync-message"
							/>
						);
					case QuestionnaireStatus.Error:
						return (
							<span
								className="she-components-sync-info-status she-components-sync-info-error"
								data-testid="template-sync-message"
							/>
						);
					default:
						return null;
				}
			})()}
		</div>
	);
};
