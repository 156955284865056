import type { Middleware } from "redux";
import type { State } from "../..";

export const forceLoginGuard: Middleware<unknown, State> = (store) => (next) => (action) => {
	if (store.getState().auth.forcingLoginModal) {
		// prevent any state update if forcing relogin
		return;
	}
	return next(action);
};
