import { useTranslation } from "react-i18next";
import { PageMainNavItem } from "./PageMainNavItem";
import type { PageNavItemProps } from "./PageMainNavItem";
import type { ReactElement } from "react";
import "./PageMainNav.styles.scss";

interface Props {
	items: PageNavItemProps[];
}

export const PageMainNavGroup = ({ items }: Props) => {
	const { t } = useTranslation();

	return (
		<nav aria-label={t("display:labelNavigationMain")} className="she-main-nav">
			<ul className="she-main-nav__group">
				{items.reduce<ReactElement[]>((acc, item) => {
					item.show && acc.push(<PageMainNavItem {...item} key={item.to} />);
					return acc;
				}, [])}
			</ul>
		</nav>
	);
};
