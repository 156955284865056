import { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FormModal, ConfirmationModal } from "../../modals";
import { EntitySelectFieldValidator } from "../../../../validators/entitySelectFieldValidator";
import { EntitySelectInputList } from "../EntitySelectInputList";
import { EntitySelectInputPresentation } from "./EntitySelectInput.presentation";
import type { State } from "../../../../state";
import type { Portal } from "../../../../models/portal";
import type { EntitySelectField } from "../../../../models/fields/EntitySelectField";
import type { Entity } from "../../../../models/entity";

interface Props {
	field: EntitySelectField;
	updateField: (fieldId: number, value: any) => void;
	updateValidationStatus: (fieldId: number, value: string[]) => void;
}

export const EntitySelectInput = ({ field, updateField, updateValidationStatus }: Props) => {
	const { t } = useTranslation();
	const [showSelectModal, setShowSelectModal] = useState(false);
	const [showRemoveModal, setShowRemoveModal] = useState(false);
	const realValue = useMemo(() => {
		if (field.value === "UseDefaultValue") {
			return undefined;
		}
		return field.value;
	}, [field.value]);
	const [checkedEntity, setCheckedEntity] = useState<Entity | undefined>(realValue);
	const [tempEntity, setTempEntity] = useState<Entity | undefined>(realValue);
	const fieldValidator = new EntitySelectFieldValidator(field);

	const portal = useSelector<State, Portal | undefined>((state) =>
		state.portal.portals.find(
			(p) =>
				state.questionnaire.questionnaire &&
				p.key === state.questionnaire.questionnaire.portalKey,
		),
	);

	const caption = useMemo(
		() =>
			t(checkedEntity ? "display:labelChangeEntity" : "display:labelSelectEntity", {
				entityCaption: field.name,
			}),
		[t, field.name, checkedEntity],
	);

	const onDisplayModal = () => {
		setShowSelectModal(true);
		setTempEntity(checkedEntity);
	};

	const onCancel = () => {
		setShowSelectModal(false);
	};

	const onOk = () => {
		fieldValidator.isFieldValid(tempEntity);
		setCheckedEntity(tempEntity);
		updateField(field.id, tempEntity);
		updateValidationStatus(field.id, fieldValidator.messages);
		setShowSelectModal(false);
	};

	const onRemove = () => {
		setCheckedEntity(undefined);
		fieldValidator.isFieldValid(undefined);
		updateField(field.id, undefined);
		updateValidationStatus(field.id, fieldValidator.messages);
		setShowRemoveModal(false);
	};

	return (
		<>
			<EntitySelectInputPresentation
				buttonLabel={caption}
				entity={realValue}
				onClick={onDisplayModal}
				onRemove={() => setShowRemoveModal(true)}
				removeLabel={t("display:buttonDelete")}
			/>
			<FormModal
				large
				onCancel={onCancel}
				onOk={onOk}
				padded={false}
				show={showSelectModal}
				title={t("display:labelSelectEntity", {
					entityCaption: field.name,
				})}
			>
				<EntitySelectInputList
					field={field}
					onSelectEntity={setTempEntity}
					portalCulture={portal ? portal.dateFormat : "Uk"}
					selectedEntity={tempEntity}
				/>
			</FormModal>
			<ConfirmationModal
				onCancel={() => {
					setShowRemoveModal(false);
				}}
				onConfirm={onRemove}
				show={showRemoveModal}
				text={t("display:labelClearEntitySelectField")}
			/>
		</>
	);
};
