import { useMemo } from "react";
import { AnimationWrapper } from "../../animation/AnimationWrapper";
import { AnimationSlideFade } from "../../animation/AnimationSlideFade";
import { stringHelper } from "../../../../helpers/StringHelper";
import { ReactComponent as DropdownChevron } from "../../../assets/svg/arrow-down-1.svg";

interface Props {
	onChange: (
		event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>,
	) => void;
	click: (value: number) => void;
	availableResponse: Map<number, string>;
	selectedValue?: number;
	group: string;
	isNotApplicable: boolean;
	id: number;
	guid: string;
}

export const SingleSelectInputPresentation = ({
	availableResponse,
	click,
	group,
	id,
	isNotApplicable,
	onChange,
	guid,
	selectedValue,
}: Props) => {
	const groupText = useMemo(() => stringHelper.alphaNumericRegExLowerCase(group), [group]);

	return (
		<AnimationWrapper>
			{!isNotApplicable && (
				<AnimationSlideFade key={id}>
					{availableResponse.size <= 5 && (
						<div
							aria-labelledby={`${guid}-label ${guid}-description`}
							data-testid="single-select-input"
							role="radiogroup"
						>
							<ul className="she-components-radio-group">
								{Array.from(availableResponse).map((tuple) => (
									<li
										className="she-components-radio-group-button"
										key={tuple[0]}
									>
										<input
											checked={selectedValue === tuple[0]}
											disabled={isNotApplicable}
											id={`${groupText}-${tuple[0]}`}
											name={`${groupText}-${id}`}
											onChange={onChange}
											onClick={() => {
												click(tuple[0]);
											}}
											type="radio"
											value={tuple[0]}
										/>
										<label htmlFor={`${groupText}-${tuple[0]}`}>
											{tuple[1]}
										</label>
									</li>
								))}
							</ul>
						</div>
					)}
					{availableResponse.size > 5 && (
						<div className="she-components-dropdown" data-testid="single-select-input">
							<select
								aria-labelledby={`${guid}-label ${guid}-description`}
								data-testid="single-select-input-more-than-five"
								defaultValue={selectedValue ? selectedValue.toString() : ""}
								disabled={isNotApplicable}
								id={guid}
								onChange={onChange}
							>
								<option value="-1" />
								{Array.from(availableResponse).map((tuple) => (
									<option key={tuple[0]} value={tuple[0]}>
										{tuple[1]}
									</option>
								))}
							</select>
							<div className="she-components-control-icon">
								<DropdownChevron />
							</div>
						</div>
					)}
				</AnimationSlideFade>
			)}
		</AnimationWrapper>
	);
};
