import { Menu, MenuItem } from "../Menu";
import { ReactComponent as Spots } from "../../assets/svg/navigation-menu-vertical.svg";
import type { ReactElement } from "react";
import "./AttachmentCard.styles.scss";

interface Props {
	name: string;
	description?: string;
	menuItems?: {
		label: string;
		onClick: () => void;
		icon?: ReactElement;
	}[];
}

export const AttachmentCard = ({ name, description, menuItems }: Props) => {
	return (
		<li className="action-attachment-item">
			<div className="action-attachment-item__name">{name}</div>
			{description && (
				<div className="action-attachment-item__description">{description}</div>
			)}

			{menuItems && menuItems.length && (
				<div className="action-attachment-item__menu">
					<Menu accessibleButtonLabel="Open task dropdown" icon={<Spots />}>
						{menuItems.map(({ label, onClick, icon }) => (
							<MenuItem icon={icon} key={label} onClick={onClick}>
								{label}
							</MenuItem>
						))}
					</Menu>
				</div>
			)}
		</li>
	);
};
