import { useTranslation } from "react-i18next";
import { ReactComponent as Bin } from "../../../../../assets/svg/bin-1.svg";
import type { Note } from "../../../../../../models/questionnaire";

interface Props {
	note: Note;
	deleteNote: () => void;
	editNote: () => void;
}

export const NoteRecordPresentation = ({ deleteNote, editNote, note }: Props) => {
	const { t } = useTranslation();
	return (
		<div className="she-components-note-tile" onClick={editNote}>
			<div>
				<div className="she-components-note-text">{note.value}</div>
			</div>
			<div className="she-components-note-remove">
				<button
					aria-label={t("display:buttonDeleteNote", { note: note.value })}
					className="she-icon"
					onClick={(e) => {
						e.stopPropagation();
						deleteNote();
					}}
					type="button"
				>
					<Bin />
				</button>
			</div>
		</div>
	);
};
