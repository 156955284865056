import { useMemo } from "react";
import { RadioButton } from "../RadioButton";
import { CollapsibleLabelList } from "../../generic/CollapsibleLabelList";
import type { Entity } from "../../../../models/entity";
import "./EntitySelectInputListItem.styles.scss";

interface Props {
	entity: Entity;
	fieldId: number;
	groupText: string;
	selected: boolean;
	onSelectEntity: (entity: Entity) => void;
}

const STRONG_PROPERTIES = ["EntityReference", "FullName"];

export const EntitySelectInputListItem = ({
	entity,
	fieldId,
	groupText,
	selected,
	onSelectEntity,
}: Props) => {
	const list = useMemo(
		() =>
			entity.info.reduce<{ key: string; value: string; strong: boolean }[]>((acc, info) => {
				if (info.detail) {
					acc.push({
						key: info.title,
						value: info.detail,
						strong: STRONG_PROPERTIES.includes(info.propertyName),
					});
				}
				return acc;
			}, []),
		[entity],
	);

	return (
		<div className="she-entity-list-item" key={entity.id}>
			<RadioButton
				id={`${groupText}-${fieldId}-${entity.id}`}
				key={entity.id}
				name={`${groupText}-${fieldId}`}
				onClick={() => onSelectEntity(entity)}
				selected={selected}
			>
				<CollapsibleLabelList listItems={list} />
			</RadioButton>
		</div>
	);
};
