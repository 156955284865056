export class ErrorWithData extends Error {
	data: any;
	status?: number;

	constructor(message: string, data: any, status?: number) {
		super(message);
		this.data = data;
		this.status = status;
	}
}
