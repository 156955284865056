import TextareaAutosize from "react-textarea-autosize";

interface Props {
	name: string;
	value: string;
	hint: string;
	maxLength: number;
	rows: number;
	guid: string;
	isMandatory: boolean;
	isInvalid: boolean;
	isNotApplicable: boolean;
	onChange: (value?: string) => void;
	onBlur: () => void;
}

export const EmailInputPresentation = ({
	guid,
	hint,
	isInvalid,
	isMandatory,
	isNotApplicable,
	maxLength,
	name,
	onBlur,
	onChange,
	rows,
	value,
}: Props) => {
	return (
		<>
			<div className="she-components-control-hint">{hint}</div>

			<TextareaAutosize
				aria-describedby={`${guid}-description`}
				aria-invalid={isInvalid}
				aria-required={isMandatory}
				autoComplete="email"
				className="she-components-text-input"
				defaultValue={value}
				disabled={isNotApplicable}
				hidden={isNotApplicable}
				id={guid}
				maxLength={maxLength}
				name={name}
				onBlur={onBlur}
				onChange={(e) => onChange(e.target.value)}
				rows={rows}
			/>
		</>
	);
};
