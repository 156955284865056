import { AttachmentActionTypes } from "./actions/types";
import type { Reducer } from "redux";
import type { AttachmentState } from "./model";
import type { AttachmentAction } from "./actions/definitions";

const initialState: AttachmentState = {
	attachments: [],
	listOfEditedAttachments: [],
};

export const attachmentsReducer: Reducer<AttachmentState, AttachmentAction> = (
	state = initialState,
	action,
) => {
	switch (action.type) {
		case AttachmentActionTypes.Add:
			return {
				listOfEditedAttachments: action.shouldHideDescription
					? []
					: action.attachments.map((a) => a.id),
				attachments: [...state.attachments, ...action.attachments],
			};
		case AttachmentActionTypes.Remove:
			return {
				...state,
				attachments: state.attachments.filter((a) => a.id !== action.attachmentId),
			};
		case AttachmentActionTypes.RecordCancel:
			return {
				...state,
				attachments: [],
			};
		case AttachmentActionTypes.RecordLoad:
			return {
				...state,
				attachments: [...action.attachments],
			};
		case AttachmentActionTypes.Edit:
			return {
				attachments: state.attachments.map((a) => {
					const updatedAttachment = action.attachments.find(
						(actionAttachment) => actionAttachment.id === a.id,
					);
					return updatedAttachment ? updatedAttachment : a;
				}),
				listOfEditedAttachments: [],
			};
		case AttachmentActionTypes.ListOfEdited:
			return {
				...state,
				listOfEditedAttachments: action.attachmentIds,
			};
		default: {
			return state;
		}
	}
};
