import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { EmailFieldValidator } from "../../../../validators/emailFieldValidator";
import { EmailInputPresentation } from "./EmailInput.presentation";
import type { EmailField } from "../../../../models/fields/EmailField";

interface Props {
	emailField: EmailField;
	updateField: (fieldId: number, value: any) => void;
	updateValidationStatus: (fieldId: number, value: string[]) => void;
}

export const EmailInputContainer = ({ emailField, updateField, updateValidationStatus }: Props) => {
	const { t } = useTranslation();
	const [isInvalid, setIsInvalid] = useState(false);
	const [email, setEmail] = useState(emailField.value);
	let hint = "";

	const emailFieldValidator = useMemo(() => new EmailFieldValidator(emailField), [emailField]);

	const onChange = (output?: string) => {
		setEmail(removeWhitespaces(output));
		updateField(emailField.id, removeWhitespaces(output));
	};

	const onBlur = () => {
		const validationResult = emailFieldValidator.isFieldValid(email);
		updateField(emailField.id, email);
		updateValidationStatus(emailField.id, emailFieldValidator.messages);
		setIsInvalid(!validationResult);
	};

	if (emailField.maxLength || emailField.minLength) {
		hint = t("display:hintTextFieldLength", {
			minLength: emailField.minLength || 0,
			maxLength: emailField.maxLength || t("display:labelUnlimited"),
		});
	}

	const removeWhitespaces = (value?: string) => {
		return value ? value.trim() : value;
	};

	return (
		<EmailInputPresentation
			guid={emailField.guid}
			hint={hint}
			isInvalid={isInvalid}
			isMandatory={emailField.isMandatory}
			isNotApplicable={emailField.isNotApplicable}
			maxLength={emailField.maxLength || 250}
			name={emailField.name}
			onBlur={onBlur}
			onChange={(e) => onChange(e)}
			rows={emailField.rows || 1}
			value={email || ""}
		/>
	);
};
