export const isIosSafari = (): boolean => {
	return (
		["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(
			navigator.platform,
		) ||
		(navigator.userAgent.includes("Mac") && "ontouchend" in document)
	);
};

export const isIosStandalone = () =>
	"standalone" in window.navigator && window.navigator.standalone;

export const isIos13orMore = () => "download" in document.createElement("a");
