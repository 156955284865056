import { t } from "i18next";
import type { BaseNumberField } from "../../models/fields/NumberField";

export class NumberFieldValidator {
	numberField: BaseNumberField;
	messages: string[];

	constructor(numberField: BaseNumberField) {
		this.numberField = numberField;
		this.messages = [];
	}

	isFieldValid(
		value: string,
		pattern: string,
		skipMaxRangeValidation: boolean,
		skipMinRangeValidation: boolean,
	): boolean {
		this.messages = [];
		if (value !== null && value !== undefined && value !== "") {
			return (
				this.matchesPattern(value, pattern) &&
				this.isANumber(value) &&
				(skipMaxRangeValidation ? true : this.isValidForMaxRange(value)) &&
				(skipMinRangeValidation ? true : this.isValidForMinRange(value))
			);
		}

		return this.doesMandatoryHaveValue(value);
	}

	getValidationMessages(): string[] {
		return this.messages;
	}

	matchesPattern(value: string, pattern: string): boolean {
		if (!value.match(pattern)) {
			return false;
		}

		return true;
	}

	isValidForMaxRange(value: number): boolean {
		if (
			this.numberField.max === null ||
			this.numberField.max === undefined ||
			value <= this.numberField.max
		) {
			return true;
		}

		return false;
	}

	isValidForMinRange(value: number): boolean {
		if (
			this.numberField.min === null ||
			this.numberField.min === undefined ||
			value >= this.numberField.min
		) {
			return true;
		}

		return false;
	}

	doesMandatoryHaveValue(value?: string): boolean {
		if (this.numberField.isMandatory) {
			if (
				value === null ||
				value === undefined ||
				isNaN(Number(value)) ||
				isNaN(parseInt(value))
			) {
				this.messages.push(t("validation:mandatoryFieldMessage"));
				return false;
			}
		}

		return true;
	}

	isANumber(value: any): value is number {
		if (value === null || value === undefined || isNaN(value)) {
			this.messages.push(t("validation:notANumberMessage"));
			return false;
		}

		return true;
	}
}
