import { FieldType, SelectFieldType } from "../../models/questionnaire";
import { TextFieldValidator } from "../textFieldValidator";
import { NumberFieldValidator } from "../numberFieldValidator";
import { BooleanFieldValidator } from "../booleanFieldValidator";
import { EnumSelectFieldValidator } from "../enumSelectFieldValidator";
import { SingleSelectFieldValidator } from "../singleSelectFieldValidator";
import { MultiSelectFieldValidator } from "../multiSelectFieldValidator/multiSelectFieldValidator";
import { DateTimeFieldValidator } from "../dateTimeFieldValidator";
import { MatrixFieldValidator } from "../matrixFieldValidator";
import { SignatureFieldValidator } from "../signatureFieldValidator";
import { PPEFieldValidator } from "../PPEFieldValidator.ts/PPEFieldValidator";
import { EmailFieldValidator } from "../emailFieldValidator";
import { EntitySelectFieldValidator } from "../entitySelectFieldValidator";
import type { TextField } from "../../models/fields/TextField";
import type { EnumSelectField } from "../../models/fields/EnumSelectField";
import type { SingleSelectField } from "../../models/fields/SingleSelectField";
import type { MultiSelectField } from "../../models/fields/MultiSelectField";
import type { Field } from "../../models/fields/Field";

// Used in the sub module dialog when validating all fields.
// Cannot use the same setup as the main questionnaire because the sub module record isn't
// in redux state when we need to validate it. Sub module records are only added to state after saving.
export class SubModuleFieldsValidator {
	messages: string[];
	portalCulture: string | undefined;

	constructor(culture: string | undefined) {
		this.messages = [];
		this.portalCulture = culture;
	}

	isFieldValid = (field: Field, value: any): string[] => {
		this.messages = [];

		switch (field.type) {
			case FieldType.Text:
			case FieldType.SelectableText: {
				const textFieldValidator = new TextFieldValidator(field as TextField);
				textFieldValidator.isFieldValid(value);
				this.messages = textFieldValidator.messages;
				break;
			}
			case FieldType.Number: {
				const numValidator = new NumberFieldValidator(field);
				numValidator.isFieldValid(
					value !== null && value !== undefined ? value.toString() : "",
					field.pattern,
					false,
					false,
				);
				this.messages = numValidator.getValidationMessages();
				break;
			}
			case FieldType.Boolean: {
				const boolValidator = new BooleanFieldValidator(field);
				boolValidator.isFieldValid(value);
				this.messages = boolValidator.messages;
				break;
			}
			case FieldType.Select:
				if (field.selectType === SelectFieldType.EnumSingleSelect) {
					const enumSelectValidator = new EnumSelectFieldValidator(
						field as EnumSelectField,
					);
					enumSelectValidator.isFieldValid(value);
					this.messages = enumSelectValidator.messages;
				} else if (field.selectType === SelectFieldType.SingleSelect) {
					const singleSelectValidator = new SingleSelectFieldValidator(
						field as SingleSelectField,
					);
					singleSelectValidator.isFieldValid(value);
					this.messages = singleSelectValidator.messages;
				} else if (field.selectType === SelectFieldType.MultiSelect) {
					const multiSelectValidator = new MultiSelectFieldValidator(
						field as MultiSelectField,
					);
					multiSelectValidator.isFieldValid(value);
					this.messages = multiSelectValidator.messages;
				}
				break;
			case FieldType.Date: {
				const dateValidator = new DateTimeFieldValidator(field);
				dateValidator.isFieldValid(value);
				this.messages = dateValidator.messages;
				break;
			}
			case FieldType.Time: {
				const timeValidator = new DateTimeFieldValidator(field);
				timeValidator.isFieldValid(value);
				this.messages = timeValidator.messages;
				break;
			}
			case FieldType.DateTime: {
				const dateTimeValidator = new DateTimeFieldValidator(field);
				dateTimeValidator.isFieldValid(value);
				this.messages = dateTimeValidator.messages;
				break;
			}
			case FieldType.Matrix: {
				const matrixFieldValidator = new MatrixFieldValidator(field);
				matrixFieldValidator.isFieldValid(value);
				this.messages = matrixFieldValidator.messages;
				break;
			}
			case FieldType.Signature: {
				const signatureValidator = new SignatureFieldValidator(field);
				signatureValidator.isFieldValid(value);
				this.messages = signatureValidator.messages;
				break;
			}
			case FieldType.PPE: {
				const PPEValidator = new PPEFieldValidator();
				PPEValidator.isFieldValid(value);
				this.messages = PPEValidator.messages;
				break;
			}
			case FieldType.Email: {
				const emailValidator = new EmailFieldValidator(field);
				emailValidator.isFieldValid(value);
				this.messages = emailValidator.messages;
				break;
			}
			case FieldType.EntitySelectWithDefault: {
				const entitySelectWithDefaultValidator = new EntitySelectFieldValidator(field);
				entitySelectWithDefaultValidator.isFieldValid(value);
				this.messages = entitySelectWithDefaultValidator.messages;
				break;
			}
		}

		return this.messages;
	};
}
