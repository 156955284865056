import { useTranslation } from "react-i18next";
import { Button } from "../../../../../components/input/Button";
import { PPESubmoduleCardItem } from "./PPESubmoduleCardItem.component";
import type { PPECategoryValue } from "../../../../../../models/fields/PPEField";
import type { SubModule, SubModuleRecord } from "../../../../../../models/questionnaire";

export interface Props {
	submodule: SubModule;
	record?: SubModuleRecord;
	remove: (recordId: string) => void;
	edit: (record: SubModuleRecord) => void;
}

export const PPESubmoduleCard = ({ submodule, record, remove, edit }: Props) => {
	const { t } = useTranslation();

	if (!record) {
		return null;
	}

	return (
		(record && (
			<>
				<div
					className="she-components-submodule-tile she-ppe-tile"
					onClick={() => {
						edit(record);
					}}
				>
					{record.values.map((v) =>
						v.value.map((v: PPECategoryValue) => (
							<div className="she-ppe-submodule-item" key={v.id}>
								<PPESubmoduleCardItem value={v} />
							</div>
						)),
					)}
				</div>
				<Button
					className="she-btn she-btn-tertiary"
					onClick={() => {
						edit(record);
					}}
				>
					{t("display:buttonEditSubmoduleName", {
						submodule: submodule.name,
					})}
				</Button>
				<Button
					className="she-btn she-btn-tertiary"
					onClick={() => {
						remove(record.localId);
					}}
				>
					{t("global:clear", {
						submodule: submodule.name,
					})}
				</Button>
			</>
		)) ||
		null
	);
};
