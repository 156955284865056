import { useState, useContext, useRef, useEffect } from "react";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { APIHealthContext } from "../../../../../services/health/implementations/healthContext/healthContext";
import { isBrowserIE } from "../../../../../helpers/ieHelper";
import { OnlineStatus } from "../../../../../services/health/implementations/healthMonitor";
import { PortalQuestionnaireThumbnail } from "../PortalQuestionnaireThumbnail";
import { QuestionnaireStatus, PortalViewType } from "../../../../../models/portal";
import { ReactComponent as Spots } from "../../../../assets/svg/navigation-menu-vertical.svg";
import { usePortalPath } from "../../../../../helpers/UsePortalPath";
import { useHighlight } from "../../../../../helpers/UseHighlight";
import "./PortalQuestionnaire.styles.scss";
import { getBlobUrl } from "../../../../../helpers/BlobHelper";
import type { QuestionnaireStub } from "../../../../../models/portal";
import type { PortalTranslationLanguage } from "../../../../../types/i18next";

interface Props {
	questionnaireStub: QuestionnaireStub;
	displayLanguage: boolean;
	onQuestionnaireSelect: (key: string) => void;
	onQuestionnaireSelectKeyboard: (questionnaireKey: string, keyPressed: string) => void;
	onRemoveQuestionnaire: (
		questionnaireKey: string,
		contextMenu: HTMLButtonElement | null,
	) => void;
	onDownloadQuestionnaire: (questionnaireKey: string) => void;
	highlight: boolean;
}

export const PortalQuestionnaire = ({
	displayLanguage,
	highlight,
	onDownloadQuestionnaire,
	onQuestionnaireSelect,
	onQuestionnaireSelectKeyboard,
	onRemoveQuestionnaire,
	questionnaireStub,
}: Props) => {
	const { t } = useTranslation();
	const health = useContext(APIHealthContext);
	const menuBoxRef = useRef<HTMLDivElement>(null);
	const menuButtonRef = useRef<HTMLButtonElement>(null);
	const [displayOptions, setDisplayOptions] = useState<boolean>(false);
	const offlineEnabled = !isBrowserIE();
	const portalPath = usePortalPath();

	const itemRef = useRef<HTMLAnchorElement>(null);
	const highlightValue = useHighlight(highlight, itemRef);
	const classes = classnames(highlightValue && "she-portal-questionnaire--highlight");

	const handleClickOutside = (e: any) => {
		if (displayOptions) {
			if (!menuBoxRef.current!.contains(e.target)) {
				setDisplayOptions(false);
			}
		}
	};

	const handleKeyDownOutside = () => {
		if (displayOptions) {
			setTimeout(() => {
				if (!menuBoxRef.current!.contains(document.activeElement)) {
					setDisplayOptions(false);
				}
			}, 1);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside, false);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside, false);
		};
	});

	const removeButtonClicked = () => {
		onRemoveQuestionnaire(questionnaireStub.key, menuButtonRef.current);
	};

	return (
		<li className="she-portal-questionnaire">
			<NavLink
				className={classes}
				onClick={(e) => {
					e.preventDefault();
					onQuestionnaireSelect(questionnaireStub.key);
				}}
				onKeyPress={(e) => {
					if (!displayOptions) {
						onQuestionnaireSelectKeyboard(questionnaireStub.key, e.key);
					}
				}}
				ref={itemRef}
				tabIndex={0}
				to={`${portalPath}${PortalViewType.Forms}/${questionnaireStub.key}`}
			>
				<PortalQuestionnaireThumbnail
					imageUrl={questionnaireStub.image && getBlobUrl(questionnaireStub.image)}
					module={questionnaireStub.module}
				/>
				<div className="she-portal-questionnaire-body">
					<p className="she-portal-questionnaire-body-title">{questionnaireStub.title}</p>
					<p
						className="she-portal-questionnaire-body-description"
						dangerouslySetInnerHTML={{
							__html: questionnaireStub.description,
						}}
					/>
					<p className="she-portal-questionnaire-body-data">
						{displayLanguage &&
							t(
								`display:language${
									questionnaireStub.language.replace(
										"-",
										"",
									) as PortalTranslationLanguage
								}`,
							)}
						{offlineEnabled && (
							<span className="she-portal-questionnaire-availibility-indicator">
								<span
									className={`${
										(questionnaireStub.makeAvailableOffline &&
											questionnaireStub.questionnaireStatus ===
												QuestionnaireStatus.Downloaded) ||
										health === OnlineStatus.Available
											? "she-portal-questionnaire-availibility-indicator-offline"
											: ""
									}`}
								/>
								{questionnaireStub.makeAvailableOffline &&
								questionnaireStub.questionnaireStatus ===
									QuestionnaireStatus.Downloaded
									? t("display:labelOfflineAvailable")
									: t("display:labelOnlineOnly")}
							</span>
						)}
					</p>
				</div>
			</NavLink>

			{offlineEnabled && (
				<div className="she-portal-questionnaire-options-menu">
					<button
						aria-label={t("display:buttonOptions")}
						className="she-icon"
						onClick={(e) => {
							setDisplayOptions(true);
							e.stopPropagation();
						}}
						onKeyPress={(e) => {
							setDisplayOptions(true);
							e.stopPropagation();
						}}
						ref={menuButtonRef}
						type="button"
					>
						<Spots />
					</button>
				</div>
			)}
			<div
				className={`she-portal-questionnaire-options-wrapper ${
					displayOptions ? "she-portal-questionnaire-thumbnail-showOptions" : ""
				} `}
				onBlur={handleKeyDownOutside}
				onClick={(e) => {
					e.preventDefault();
				}}
				ref={menuBoxRef}
			>
				<div className="she-portal-questionnaire-options">
					<div className="she-portal-questionnaire-option">
						<button
							onClick={() => {
								onQuestionnaireSelect(questionnaireStub.key);
								setDisplayOptions(false);
								menuButtonRef.current!.focus();
							}}
							type="button"
						>
							<div>
								<span>{t("display:buttonStartForm")}</span>
							</div>
						</button>
					</div>
					{(questionnaireStub.makeAvailableOffline === undefined ||
						!questionnaireStub.makeAvailableOffline ||
						(questionnaireStub.makeAvailableOffline &&
							questionnaireStub.questionnaireStatus !==
								QuestionnaireStatus.Downloaded)) &&
						health === OnlineStatus.Available && (
							<div className="she-portal-questionnaire-option">
								<button
									// eslint-disable-next-line jsx-a11y/no-autofocus
									autoFocus
									onClick={(e) => {
										e.stopPropagation();
										onDownloadQuestionnaire(questionnaireStub.key);
										setDisplayOptions(false);
										menuButtonRef.current!.focus();
									}}
									tabIndex={0}
									type="button"
								>
									<div>
										<span>{t("display:buttonDownloadForOffline")}</span>
									</div>
								</button>
							</div>
						)}
					{questionnaireStub.makeAvailableOffline &&
						questionnaireStub.questionnaireStatus ===
							QuestionnaireStatus.Downloaded && (
							<div className="she-portal-questionnaire-option">
								<button
									// eslint-disable-next-line jsx-a11y/no-autofocus
									autoFocus
									onClick={(e) => {
										e.stopPropagation();
										removeButtonClicked();
										setDisplayOptions(false);
									}}
									tabIndex={0}
									type="button"
								>
									<div>
										<span>{t("display:buttonRemoveFromDevice")}</span>
									</div>
								</button>
							</div>
						)}
				</div>
			</div>
		</li>
	);
};
