import { useState, useEffect } from "react";
import { t } from "i18next";
import {
	getIncrementedOrDecrementedValue,
	convertToDecimalOrInt,
	roundToNearestStep,
	roundToMinMaxValue,
	getDisplayValue,
} from "../../../../helpers/NumberInputHelper";
import { NumberInputPresentation } from "./NumberInput.presentation";
import type { NumberFieldValidator } from "../../../../validators/numberFieldValidator";
import type { BaseNumberField } from "../../../../models/fields/NumberField";

interface Props {
	numberField: BaseNumberField;
	validator: NumberFieldValidator;
	updateField: (value: any) => void;
	updateValidationStatus: (value: string[]) => void;
	hint?: string;
}

export const NumberInputContainer = ({
	numberField,
	updateField,
	updateValidationStatus,
	validator,
	hint,
}: Props) => {
	const [statesValue, setStatesValue] = useState(numberField.value);
	const step = numberField.step != null ? numberField.step : 1;

	useEffect(() => {
		setStatesValue(numberField.value);
	}, [numberField.value]);
	// onIncrement will always be used for decimal and have 5 decimal points
	const onIncrement = () => {
		const value = getIncrementedOrDecrementedValue(
			statesValue,
			true,
			numberField.isDecimal,
			step,
			numberField.min,
			numberField.max,
		);
		if (value !== undefined && validator.isValidForMaxRange(value)) {
			updateValue(value.toString());
		}
	};

	// onDecrement will always be used for decimal and have 5 decimal points
	const onDecrement = () => {
		const value = getIncrementedOrDecrementedValue(
			statesValue,
			false,
			numberField.isDecimal,
			step,
			numberField.min,
			numberField.max,
		);
		if (value !== undefined && validator.isValidForMinRange(value)) {
			updateValue(value.toString());
		}
	};

	const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		updateValue(value);
	};

	const onBlur = (incomingValue: string) => {
		if (incomingValue !== "") {
			let value = convertToDecimalOrInt(incomingValue, numberField.isDecimal);
			if (numberField.showStep && numberField.step) {
				// We check the original property step as we do not want to do rounding for main module fields
				value = roundToNearestStep(value, step, numberField.min, numberField.max);
			} else {
				value = roundToMinMaxValue(value, numberField.min, numberField.max);
			}

			incomingValue = value.toString();
		}
		validator.doesMandatoryHaveValue(incomingValue);
		updateValue(incomingValue.toString());
	};

	const updateValue = (incomingValue: string) => {
		let value: number | undefined;
		if (incomingValue === "") {
			value = undefined;
		} else if (
			!validator.isFieldValid(
				incomingValue,
				numberField.pattern,
				incomingValue.startsWith("-"),
				true,
			)
		) {
			// We can only check Max if not a negative number but we cant ever check min else it restricts the user: eg if min is 5, user cant enter 10
			return;
		} else {
			value = convertToDecimalOrInt(incomingValue, numberField.isDecimal);
		}

		setStatesValue(value);
		updateField(value);
		updateValidationStatus(validator.messages);
	};

	const generateHint = (min?: number, max?: number): string | undefined => {
		const numberOfDecimals = numberField.numberOfdecimalsToShow;

		if (min != null && max != null) {
			if (numberField.showStep && numberField.step) {
				return t("display:hintNumericMinMax", {
					minValue: numberOfDecimals ? min.toFixed(numberOfDecimals) : min,
					maxValue: numberOfDecimals ? max.toFixed(numberOfDecimals) : max,
					step: numberOfDecimals ? step.toFixed(numberOfDecimals) : step,
				});
			}
			return t("display:hintNumericMinMaxNoStep", {
				minValue: numberOfDecimals ? min.toFixed(numberOfDecimals) : min,
				maxValue: numberOfDecimals ? max.toFixed(numberOfDecimals) : max,
			});
		} else if (min != null) {
			return t("display:hintNumericMin", {
				minValue: numberOfDecimals ? min.toFixed(numberOfDecimals) : min,
			});
		} else if (max != null) {
			return t("display:hintNumericMax", {
				maxValue: numberOfDecimals ? max.toFixed(numberOfDecimals) : max,
			});
		}

		return undefined;
	};

	const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>, input: string) => {
		if (
			event.key === "e" ||
			event.key === "E" ||
			event.key === "+" ||
			(event.key === "-" && !validator.matchesPattern(event.key, numberField.pattern)) ||
			(event.key === "." &&
				!numberField.isDecimal &&
				!validator.matchesPattern(event.key, numberField.pattern)) ||
			(event.key === "." &&
				(event.currentTarget.value === "0.0" ||
					event.currentTarget.value === "0.00" ||
					event.currentTarget.value === "0.000" ||
					event.currentTarget.value === "0.0000" ||
					event.currentTarget.value === "0.00000")) ||
			(input.toString() === "" && event.key === ".") ||
			(input.toString().includes(".") && event.key === ".")
		) {
			event.preventDefault();
		}
	};

	const onPaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
		if (
			event.clipboardData.getData("Text").includes("e") ||
			event.clipboardData.getData("Text").includes("+") ||
			event.clipboardData.getData("Text").includes("..") ||
			event.clipboardData.getData("Text").includes("--") ||
			!validator.matchesPattern(event.clipboardData.getData("Text"), numberField.pattern)
		) {
			event.preventDefault();
		}
	};

	return (
		<NumberInputPresentation
			guid={numberField.guid}
			hint={hint ? hint : generateHint(numberField.min, numberField.max)}
			isMandatory={numberField.isMandatory}
			isNotApplicable={numberField.isNotApplicable}
			max={numberField.max}
			min={numberField.min}
			onBlur={onBlur}
			onChange={onChange}
			onDecrement={onDecrement}
			onIncrement={onIncrement}
			onKeyPress={onKeyPress}
			onPaste={onPaste}
			pattern={numberField.pattern}
			showStep={numberField.showStep}
			step={step}
			value={getDisplayValue(
				statesValue,
				numberField.isDecimal,
				!!numberField.numberOfdecimalsToShow,
				numberField.numberOfdecimalsToShow || 5,
			)}
		/>
	);
};
