import { useState, useEffect, useCallback } from "react";

export const useCachedUpdate = <T>(value: T, onChange: (value: T) => void, timeoutDelay = 300) => {
	const [localValue, setLocalValue] = useState(value);
	const [timeoutId, setTimeoutId] = useState(0);

	useEffect(() => {
		setLocalValue(value);
	}, [value]);

	const debouncedChange = useCallback(
		(newValue: T) => {
			window.clearTimeout(timeoutId);
			setLocalValue(newValue);
			setTimeoutId(
				window.setTimeout(() => {
					onChange(newValue);
				}, timeoutDelay),
			);
		},
		[timeoutId, setLocalValue, setTimeoutId, onChange, timeoutDelay],
	);

	const immediateChange = useCallback(
		(newValue: T) => {
			window.clearTimeout(timeoutId);
			if (newValue !== value) {
				onChange(newValue);
			}
		},
		[timeoutId, value, onChange],
	);

	return {
		value: localValue,
		debouncedChange,
		immediateChange,
	};
};
