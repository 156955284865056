import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { Tree } from "../../generic/Tree";
import { Breadcrumb } from "../../generic/Breadcrumb";
import { FormModal } from "../../modals";
import { SearchBox } from "../SearchBox";
import { List } from "../../generic/List";
import { TouchInputBlurrer } from "../../generic/TouchInputBlurrer";
import { ReactComponent as NavigateIcon } from "../../../assets/svg/navigate.svg";
import { ReactComponent as DropdownChevron } from "../../../assets/svg/arrow-down-1.svg";
import { buildBreadcrumb } from "./buildBreadcrumb";
import type { OrgUnit } from "../../../../models/orgUnit";
import "./OrgUnitSelector.styles.scss";
import { TreeRadioButton } from "../../generic/Tree/components/TreeRadioButton/TreeRadioButton";

interface Props {
	orgUnits: OrgUnit[];
	breadcrumbData: OrgUnit[];
	fullBreadcrumbData: OrgUnit[];
	buttonText?: string;
	fieldId: string;

	currentOrgUnit?: OrgUnit;
	checkedOrgUnit?: OrgUnit;
	onChange: (id?: number) => void;

	show: boolean;
	onOpen: () => void;
	onCancel: () => void;
	onSelect: () => void;

	searchTerm: string;
	setSearchTerm: (value: string) => void;
	isSearchMode: boolean;
	setIsSearchMode: (value: boolean) => void;

	isValid: boolean;
	isFixed: boolean;
	orgUnitCaption: string;
}

export const OrgUnitSelectorPresentation = ({
	breadcrumbData,
	fullBreadcrumbData,
	isFixed,
	isSearchMode,
	isValid,
	onCancel,
	onChange,
	onOpen,
	onSelect,
	orgUnits,
	searchTerm,
	setIsSearchMode,
	setSearchTerm,
	show,
	buttonText,
	checkedOrgUnit,
	currentOrgUnit,
	fieldId,
	orgUnitCaption,
}: Props) => {
	const { t } = useTranslation();

	const searchResults = useMemo(() => {
		if (!isSearchMode) {
			return [];
		}

		return orgUnits.filter((ou) => ou.name.toLowerCase().includes(searchTerm.toLowerCase()));
	}, [orgUnits, searchTerm, isSearchMode]);

	const searchBoxRef = useRef<HTMLInputElement>(null);
	const boxClasses = classnames(
		"she-org-unit-select__box",
		!isValid && "she-org-unit-select__box--invalid",
		isFixed && "she-org-unit-select__box--fixed",
	);

	return (
		<>
			<div className="she-org-unit-select">
				<div
					aria-describedby={`${fieldId}-description`}
					aria-disabled={isFixed}
					className={boxClasses}
					onClick={() => buttonText && onOpen()}
					onKeyDown={(event) => {
						if (buttonText && (event.key === "Enter" || event.key === " ")) {
							onOpen();
						}
					}}
					role="button"
					tabIndex={0}
				>
					<div className="screen-reader-only">
						{t("display:labelSelectOrgUnit", { orgCaption: orgUnitCaption })}
					</div>
					{currentOrgUnit && (
						<div>
							<Breadcrumb data={fullBreadcrumbData} readOnly separator=">" />
							<p>{currentOrgUnit.name}</p>
						</div>
					)}
					{!isFixed && (
						<div className="she-components-control-icon she-org-unit-select__box__icon">
							<DropdownChevron />
						</div>
					)}
				</div>
			</div>
			<FormModal
				large
				onCancel={onCancel}
				onOk={onSelect}
				padded={false}
				show={show}
				title={buttonText || ""}
			>
				<div className="she-org-unit-select__searchbox">
					<SearchBox
						onSearchBegan={() => setIsSearchMode(true)}
						onSearchEnd={() => setIsSearchMode(false)}
						onSearchTermChange={setSearchTerm}
						reference={searchBoxRef}
						searchTerm={searchTerm}
					/>
				</div>

				<TouchInputBlurrer refToBlur={searchBoxRef}>
					{!isSearchMode ? (
						<>
							<div className="she-org-unit-select__breadcrumbs">
								<Breadcrumb data={breadcrumbData} onSelect={onChange} />
							</div>

							<Tree
								data={orgUnits}
								mode="menu"
								name="orgUnitTree"
								onSelect={onChange}
								selectedId={checkedOrgUnit ? checkedOrgUnit.id : undefined}
							/>
						</>
					) : (
						<>
							<h4 className="she-org-unit-select__search-results__guidance">
								{t("display:labelSearchResults")}
							</h4>
							<List extraClassName="she-org-unit-select__search-results">
								{searchResults.length > 0 ? (
									searchResults
										.sort((a, b) =>
											a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1,
										)
										.map((ou) => (
											<div
												className="she-org-unit-select__search-results__item"
												key={ou.id}
											>
												<TreeRadioButton
													name="orgUnitTree"
													onClick={() => onChange(ou.id)}
													onKeyDown={(event) => {
														if (
															event.key === "Enter" ||
															event.key === " "
														) {
															onChange(ou.id);
														}
													}}
													selected={checkedOrgUnit?.id === ou.id}
												>
													<Breadcrumb
														data={buildBreadcrumb(
															ou.id,
															orgUnits,
															false,
														)}
														readOnly
														separator=">"
													/>
													{ou.name}
												</TreeRadioButton>
												<button
													className="she-btn she-btn-tertiary she-btn-orgunit-navigate"
													onClick={() => {
														onChange(ou.id);
														setIsSearchMode(false);
														setSearchTerm("");
													}}
													type="button"
												>
													<NavigateIcon />
													<span>{t("global:go")}</span>
												</button>
											</div>
										))
								) : (
									<div className="she-org-unit-select__search-results__empty">
										<h1>{t("display:labelNoSearchResults")}</h1>
									</div>
								)}
							</List>
						</>
					)}
				</TouchInputBlurrer>
			</FormModal>
		</>
	);
};
