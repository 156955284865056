import moment from "moment";
import { FieldType, DateRestrictionType } from "../models/questionnaire";
import { store } from "../state";
import type { SubModuleRecordValue } from "../models/questionnaire";
import type { RefObject } from "react";
import type { Moment } from "moment";

export const ukDateFormat = "DD/MM/YYYY";
const usDateFormat = "MM/DD/YYYY";
const defaultTimeFormat = "HH:mm";
const defaultTimeUsFormat = "hh:mm a";

export type DateFormat = typeof ukDateFormat | typeof usDateFormat;

export interface IDateValues {
	day: string;
	month: string;
	year: string;
}

export const getDateFormat = (): DateFormat => {
	const culture = getPortalCulture();
	if (culture === "Uk") {
		return ukDateFormat;
	}

	if (culture === "Us") {
		return usDateFormat;
	}

	return ukDateFormat;
};

export const getDateTimeFormat = (): string => {
	return `DD/MM/YYYY ${getTimeFormat()}`; // Date is always used across the system in UK format
};

export const getTimeFormat = (): string => {
	return getPortalCulture() === "Us" ? defaultTimeUsFormat : defaultTimeFormat;
};

export const getPortalCulture = (): string => {
	return store.getState().portal.portals[0].dateFormat || "Uk";
};

export const GetReadOnlyDate = (subModuleRecordValue: SubModuleRecordValue): string | undefined => {
	const dateFormat = getDateFormat();
	const timeFormat = getTimeFormat();
	const dateTimeFormat = getDateTimeFormat();

	switch (subModuleRecordValue.type) {
		case FieldType.Date: {
			const dateToUse = combineDateString(
				subModuleRecordValue.value && true ? subModuleRecordValue.value : undefined,
			);
			return dateToUse && true
				? moment(dateToUse, "DD/MM/YYYY").format(dateFormat)
				: undefined;
		}
		case FieldType.Time: {
			const timeFormatForMoment = getTimeFormat();
			const timeToUse = combineTimeString(
				subModuleRecordValue.value && true ? subModuleRecordValue.value : undefined,
			);
			return timeToUse && true
				? moment(timeToUse, timeFormatForMoment).format(timeFormat)
				: undefined;
		}
		case FieldType.DateTime: {
			const dateTimeForMoment = getDateTimeFormat();
			const dateTimeToUse = combineDateTimeString(
				subModuleRecordValue.value && true ? subModuleRecordValue.value : undefined,
			);
			return dateTimeToUse && true
				? moment(dateTimeToUse, dateTimeForMoment).format(dateTimeFormat)
				: undefined;
		}
	}
};

export const splitDateToObject = (
	dateToSplit: string,
	format: DateFormat = ukDateFormat,
): IDateValues => {
	let dayToUse = "";
	let monthToUse = "";
	let yearToUse = "";

	if (dateToSplit && moment(dateToSplit, format).isValid()) {
		const dateToUse = moment(dateToSplit, format).toDate();
		dayToUse = getLeadingZeroValue(dateToUse.getDate().toString());
		monthToUse = getLeadingZeroValue((dateToUse.getMonth() + 1).toString());
		yearToUse = dateToUse.getFullYear().toString();
	}

	return {
		day: dayToUse,
		month: monthToUse,
		year: yearToUse,
	};
};

export const getMomentDateFromDateValues = (dateObject: IDateValues) => {
	const { month } = dateObject;
	// Months are stored from Zero f.e. January is 0
	const correctMonthNumberString = Number(month) - 1;
	return moment({
		...dateObject,
		month: correctMonthNumberString,
	} as any);
};

export const combineDateString = (
	dateObject: any,
	format: DateFormat = ukDateFormat,
): string | undefined => {
	if (!dateObject) {
		return undefined;
	}

	if (format === ukDateFormat) {
		return `${dateObject.day}/${dateObject.month}/${dateObject.year}`;
	}

	return `${dateObject.month}/${dateObject.day}/${dateObject.year}`;
};

export const getLeadingZeroValue = (valueToUse: string): string => {
	return valueToUse && true ? `0${valueToUse.toString()}`.slice(-2) : "";
};

export const splitTimeToObject = (timeToSplit: string, isIncomingValue = false): object => {
	let hourToUse = "";
	let minuteToUse = "";
	let amPmToUse = "";
	const timeFormatToUse: string = isIncomingValue ? defaultTimeFormat : getTimeFormat();

	if (timeToSplit && moment(timeToSplit, timeFormatToUse).isValid()) {
		const timeToUse = moment(timeToSplit, timeFormatToUse);
		if (getPortalCulture() === "Us") {
			hourToUse = getLeadingZeroValue(timeToUse.format("hh"));
			minuteToUse = getLeadingZeroValue(timeToUse.format("mm"));
			amPmToUse = timeToUse.format("a");
		} else {
			hourToUse = getLeadingZeroValue(timeToUse.format("HH"));
			minuteToUse = getLeadingZeroValue(timeToUse.format("mm"));
			amPmToUse = "";
		}
	}

	return {
		hour: hourToUse,
		minute: minuteToUse,
		amPm: amPmToUse,
	};
};

export const combineTimeString = (timeObject: any): string | undefined => {
	if (getPortalCulture() === "Us") {
		return timeObject && true
			? `${timeObject.hour}:${timeObject.minute} ${timeObject.amPm}`
			: undefined;
	}
	return timeObject && true ? `${timeObject.hour}:${timeObject.minute}` : undefined;
};

export const setElementsFocus = (
	element: any,
	nextFocusElement: RefObject<HTMLInputElement> | null,
) => {
	if (
		nextFocusElement &&
		nextFocusElement.current &&
		element &&
		element.target.value.length === 2
	) {
		nextFocusElement.current.focus();
		nextFocusElement.current.select();
	}
};

export const isKeyDownValid = (event: React.KeyboardEvent<HTMLInputElement>) => {
	if (
		event.key === "1" ||
		event.key === "2" ||
		event.key === "3" ||
		event.key === "4" ||
		event.key === "5" ||
		event.key === "6" ||
		event.key === "7" ||
		event.key === "8" ||
		event.key === "9" ||
		event.key === "0" ||
		event.key === "Delete" ||
		event.key === "Backspace" ||
		event.key === "ArrowLeft" ||
		event.key === "ArrowRight" ||
		event.key === "Tab" ||
		event.key === "Home" ||
		event.key === "End" ||
		((event.ctrlKey || event.metaKey) && event.key === "v")
	) {
		return;
	}

	event.preventDefault();
};

export const isPasteValid = (event: React.ClipboardEvent<HTMLInputElement>) => {
	if (/^(0|[1-9][0-9]*)$/.exec(event.clipboardData.getData("Text"))) {
		return;
	}

	event.preventDefault();
};

export const removeSpaces = (string: string) => {
	return string.split(" ").join("");
};

export const combineDateTimeString = (dateTimeObject: any): string | undefined => {
	if (getPortalCulture() === "Us") {
		return dateTimeObject && true
			? `${dateTimeObject.day}/${dateTimeObject.month}/${dateTimeObject.year} ${dateTimeObject.hour}:${dateTimeObject.minute} ${dateTimeObject.amPm}`
			: undefined;
	}
	return dateTimeObject && true
		? `${dateTimeObject.day}/${dateTimeObject.month}/${dateTimeObject.year} ${dateTimeObject.hour}:${dateTimeObject.minute}`
		: undefined;
};

export const manipulateMaxDate = (dateToChange: Date): Moment | undefined => {
	// This is used for DateTime only as Assure does not support max time configuration so we need to change the time to 23:59 instead of 00:00
	return dateToChange && true
		? moment(
				`${getLeadingZeroValue(dateToChange.getDate().toString())}/${getLeadingZeroValue(
					(dateToChange.getMonth() + 1).toString(),
				)}/${dateToChange.getFullYear().toString()} 23:59:59`,
				"DD/MM/YYYY HH:mm:ss",
		  )
		: undefined;
};

export const getIQDateBeforeRange = (
	formStartDate: Date,
	rangeValue: number,
	rangeType: DateRestrictionType,
): Moment => {
	const beforeDate = moment(formStartDate);

	switch (rangeType) {
		case DateRestrictionType.Day:
			beforeDate.subtract(rangeValue, "days");
			break;
		case DateRestrictionType.Week:
			beforeDate.subtract(rangeValue, "weeks");
			break;
	}
	beforeDate.hours(0);
	beforeDate.minutes(0);
	return beforeDate;
};

export const getIQDateAfterRange = (
	formStartDate: Date,
	rangeValue: number,
	rangeType: DateRestrictionType,
) => {
	const afterDate = moment(formStartDate);

	switch (rangeType) {
		case DateRestrictionType.Day:
			afterDate.add(rangeValue, "days");
			break;
		case DateRestrictionType.Week:
			afterDate.add(rangeValue, "weeks");
			break;
	}

	afterDate.hours(23);
	afterDate.minutes(59);
	return afterDate;
};

export const formatOutboundDateTime = (moment: Moment, format: string) => {
	const momentClone = moment.clone().locale("en");
	return momentClone.format(format);
};
