import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "../Modal";
import { ModalFooter } from "../ModalFooter";
import "./IllustratedThemedModal.styles.scss";

interface Props {
	show: boolean;

	text?: string;
	bottomText?: string;

	confirmText?: string;
	cancelText?: string;

	onConfirm?: () => void;
	onCancel?: () => void;

	blockUi?: boolean;
	showEntryAnimation?: boolean;
	withHistory?: boolean;
	children?: React.ReactNode;
}

export const IllustratedThemedModal = ({
	show,
	blockUi,
	bottomText,
	cancelText,
	confirmText,
	onCancel,
	onConfirm,
	showEntryAnimation,
	text,
	withHistory,
	children,
}: Props) => {
	const { t } = useTranslation();

	const confirmTextMemo = useMemo(() => confirmText ?? t("global:yes"), [confirmText, t]);
	const cancelTextMemo = useMemo(() => cancelText ?? t("global:no"), [cancelText, t]);
	const buttons = useMemo(() => {
		const tempButtons = [
			{ text: cancelTextMemo, primary: true, danger: false, onClick: onCancel },
		];

		if (onConfirm !== undefined) {
			tempButtons.push({
				text: confirmTextMemo,
				primary: true,
				danger: true,
				onClick: onConfirm,
			});
		}

		return tempButtons;
	}, [confirmTextMemo, cancelTextMemo, onCancel, onConfirm]);

	return (
		<Modal
			blockUi={blockUi}
			customClassName="she-components-modal-illustrated"
			fullScreenMobile
			onClose={onCancel}
			show={show}
			showEntryAnimation={showEntryAnimation}
			withHistory={withHistory}
		>
			<div className="she-components-modal-body-illustrated">
				<div className="she-components-modal-body-illustrated-content">
					{text ? <h3 className="she-layout-text-center">{text}</h3> : ""}

					<div className="she-components-modal-illustration">{children}</div>

					{bottomText && (
						<h3 className="txt-margin-top she-layout-text-center">{bottomText}</h3>
					)}
				</div>
			</div>

			{!blockUi && <ModalFooter buttons={buttons} padded />}
		</Modal>
	);
};
