import { useState } from "react";
import { useSelector } from "react-redux";
import { TextInputSelectablePresentation } from "./TextInputSelectable.presentation";
import type { Dictionary } from "../../../../models/dictionary/Dictionary";
import type { SelectableTextField } from "../../../../models/fields/SelectableTextField";
import type { State } from "../../../../state";

interface Props {
	field: SelectableTextField;
	updateField: (fieldId: number, value: any) => void;
	updateValidationStatus: (fieldId: number, value: string[]) => void;
}

export const TextInputSelectableContainer = ({
	field,
	updateField,
	updateValidationStatus,
}: Props) => {
	const initialValues: number[] = [];
	const [values, setValues] = useState(initialValues);
	const [isOpen, setIsOpen] = useState(false);
	const [isTextValidationRequired, setIsTextValidationRequired] = useState(false);
	const dictionary = useSelector<State, Dictionary | undefined>((state) =>
		state.questionnaire.dictionaries.find((d) => d.key === field.dictionaryKey),
	);

	const openSelector = () => {
		setIsOpen(true);
	};

	const cancelSelector = () => {
		closeSelector();
	};

	const closeSelector = () => {
		setIsOpen(false);
		setValues(initialValues);
	};

	const addValuesFromSelector = () => {
		let currentValue = field.value || "";
		if (values && values.length > 0 && dictionary && dictionary.dictionaryItems) {
			const items = dictionary.dictionaryItems
				.filter((item) => values.includes(item.id))
				.map((item) => item.text);

			const itemsReducer = (a: string, b: string) => `${a}\n${b}`;
			currentValue =
				currentValue.length > 0
					? items.reduce(itemsReducer, currentValue)
					: items.reduce(itemsReducer);
			updateField(field.id, currentValue);
			setIsTextValidationRequired(true);
		}

		closeSelector();
	};

	const onSelectorChange = (value: number) => {
		const selectedValues = [...values];

		if (selectedValues.includes(value)) {
			const index = selectedValues.indexOf(value);
			if (index > -1) {
				selectedValues.splice(index, 1);
			}
		} else {
			selectedValues.push(value);
		}

		setValues(selectedValues);
	};

	const onTextValidation = () => {
		setIsTextValidationRequired(false);
	};

	return (
		<TextInputSelectablePresentation
			field={field}
			isModalOpen={isOpen}
			isTextValidationRequired={isTextValidationRequired}
			onModalCancel={cancelSelector}
			onModalOk={addValuesFromSelector}
			onModalOpen={openSelector}
			onSelectorChange={onSelectorChange}
			onTextValidation={onTextValidation}
			selectedValues={values}
			updateField={updateField}
			updateValidationStatus={updateValidationStatus}
		/>
	);
};
