import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FormModal } from "../modals";
import { DownloadListItem } from "./DownloadListItem";
import { DownloadManagerContext } from "./DownloadManagerContext";
import { DownloadState } from "./Download";

interface Props {
	show: boolean;
	onCancel: () => void;
	type: DownloadState;
}

export const DownloadModal = ({ show, onCancel, type }: Props) => {
	const downloadContext = useContext(DownloadManagerContext);
	const { t } = useTranslation();
	const filteredDownloads = useMemo(
		() => downloadContext.downloads.filter((d) => d.status === type),
		[downloadContext.downloads, type],
	);

	return (
		<FormModal
			cancelText={t("global:close")}
			large
			onCancel={onCancel}
			onOk={() => {}}
			padded={false}
			primaryCancelBtn
			show={show}
			showOkBtn={false}
			title={
				type === DownloadState.InProgress
					? t("display:headerDownloadsInProgress")
					: t("display:headerDownloadErrors")
			}
		>
			<ul className="she-download-list">
				{!filteredDownloads.length && (
					<div className="she-download-list__empty">
						{t("display:labelDownloadListEmpty")}
					</div>
				)}
				{filteredDownloads.map((d) => (
					<DownloadListItem download={d} key={d.uuid} />
				))}
			</ul>
		</FormModal>
	);
};
