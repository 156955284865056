/* eslint-disable eslint-comments/no-unlimited-disable */
/* eslint-disable */
// @ts-nocheck
import { getCustomerKey } from "../../helpers/AuthenticationHelpers";
import { ConfigPropertyKey, getConfigurationOption } from "../../helpers/ConfigHelper";
import { isGainsightAllowed } from "../../services/gainsight";

export const InjectGainsightTag = async () => {
	if (!(await isGainsightAllowed(getCustomerKey().toLowerCase()))) {
		return;
	}
	(function (n, t, a, e, co) {
		var i = "aptrinsic";
		(n[i] =
			n[i] ||
			function () {
				(n[i].q = n[i].q || []).push(arguments);
			}),
			(n[i].p = e);
		n[i].c = co;
		var r = t.createElement("script");
		(r.async = !0), (r.src = `${a}?a=${e}`);
		var c = t.getElementsByTagName("script")[0];
		c.parentNode.insertBefore(r, c);
	})(
		window,
		document,
		"https://web-sdk-eu.aptrinsic.com/api/aptrinsic.js",
		await getConfigurationOption(ConfigPropertyKey.GainsightTagKey),
	);
};
/* eslint-enable */

InjectGainsightTag();
