import { useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { removeAttachment, editAttachment } from "../../../state/components/attachment";
import { dataURItoBlob } from "../../../helpers/BlobHelper";
import { isBrowserIE } from "../../../helpers/ieHelper";
import { isIos13orMore, isIosSafari } from "../../../helpers/SafariHelper";
import { ThumbnailPresentation } from "./Thumbnail.presentation";
import type { Attachment } from "../../../models/attachments/Attachment";
import type { LocalSectionId } from "../../../models/questionnaire";

interface Props {
	attachment: Attachment;
	subModuleId?: LocalSectionId;
}

export const ThumbnailContainer = ({ attachment, subModuleId }: Props) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [areYouSure, setAreYouSure] = useState(false);
	const [displayOptions, setDisplayOptions] = useState<boolean>(false);
	const [isEditing, setIsEditing] = useState(false);
	const [isEditedFromMenu, setIsEditedFromMenu] = useState(false);
	const displayEditButton = useMemo(
		// We do not support image annotations in iOS 12 and lower or IE
		() => attachment.isImage && (isIosSafari() ? isIos13orMore() : !isBrowserIE()),
		[attachment],
	);

	useEffect(() => {
		if (!attachment.isImage) {
			const icon = document.getElementById(`expander-${attachment.id}`);

			if (icon) {
				icon.style.height = `${
					document.getElementById(`filename-${attachment.id}`)!.offsetHeight
				}px`;
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onClick = () => {
		if (!displayOptions) {
			setIsOpen(true);
			setIsEditedFromMenu(false);
		}
	};

	const onKeyPress = (key: string) => {
		if ((key === "Enter" || key === " ") && attachment.isImage && !displayOptions) {
			setIsOpen(true);
			setIsEditedFromMenu(false);
		}
	};

	const onCancel = () => {
		setIsEditing(false);
		setIsOpen(false);
	};

	const onDoNotDelete = () => {
		setAreYouSure(false);
	};

	const onDelete = () => {
		setAreYouSure(true);
		setDisplayOptions(false);
	};

	const onDeleteAttachment = (attachmentId: string) => {
		if (isOpen) {
			history.go(-2);
		} else {
			history.goBack();
		}

		dispatch(removeAttachment(attachmentId));
	};

	const onDisplayMenu = (showMenu: boolean) => {
		setDisplayOptions(showMenu);
	};

	const onOpenMenu = () => {
		setIsOpen(false);
		setDisplayOptions(true);
	};

	const onEdit = () => {
		if (!isOpen) {
			setIsEditedFromMenu(true);
			setIsOpen(true);
		} else {
			setIsEditedFromMenu(false);
		}
		setIsEditing(true);
	};

	const onEditSave = (dataURI: string) => {
		setIsEditing(false);
		if (isEditedFromMenu) {
			setIsOpen(false);
		}
		const blob = dataURItoBlob(dataURI);
		dispatch(
			editAttachment([
				{
					...attachment,
					file: new File([blob], attachment.fileName, {
						type: blob.type,
					}),
				},
			]),
		);
	};

	const onEditClose = () => {
		setIsEditing(false);
		if (isEditedFromMenu) {
			setIsOpen(false);
		}
	};

	return (
		<ThumbnailPresentation
			areYouSure={areYouSure}
			attachment={attachment}
			displayEditButton={displayEditButton}
			displayOptions={displayOptions}
			hideControls={isEditedFromMenu}
			isEditing={isEditing}
			isOpen={isOpen}
			onCancel={onCancel}
			onClick={onClick}
			onDelete={onDelete}
			onDeleteAttachment={onDeleteAttachment}
			onDisplayMenu={onDisplayMenu}
			onDoNotDelete={onDoNotDelete}
			onEdit={onEdit}
			onEditClose={onEditClose}
			onEditSave={onEditSave}
			onKeyPress={onKeyPress}
			onOpenMenu={onOpenMenu}
			subModuleId={subModuleId}
		/>
	);
};
