import { useEffect } from "react";
import type { ActionDictionaryItem } from "../../../../models/action";

export const useResetFilteredOptions = (
	filteredOptions: ActionDictionaryItem[],
	selectedOptionId: number | undefined,
	fieldName: string,
	setValue: (field: string, value: any, options: { shouldValidate: boolean }) => void,
	isLoaded: boolean,
) => {
	useEffect(() => {
		if (!isLoaded) {
			return;
		}
		if (
			selectedOptionId &&
			selectedOptionId !== -1 &&
			!filteredOptions.some((option) => option.key === selectedOptionId)
		) {
			setValue(fieldName, "-1", { shouldValidate: true });
		}
	}, [fieldName, filteredOptions, isLoaded, selectedOptionId, setValue]);
};
