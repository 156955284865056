import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { List } from "../List";
import { ReactComponent as SVGArrow } from "../../../assets/svg/arrow-down-1.svg";
import { TreeRadioButton } from "./components/TreeRadioButton/TreeRadioButton";
import type { TreeData } from "./model";

interface Props {
	name: string;

	data: TreeData[];
	id: number;
	rootId: number;

	mode: "tree" | "menu";

	isMenuChild?: boolean;

	selectedId?: number;
	onSelect: (id: number) => void;
}

const shouldBeCollapsed = (
	data: TreeData[],
	thisId: number,
	rootId: number,
	selectedId?: number,
) => {
	if (thisId === rootId) {
		return false;
	}

	if (selectedId === thisId) {
		return true;
	}

	const selected = data.find((d) => d.id === selectedId);
	if (!selected) {
		return true;
	}

	let next: TreeData | undefined = selected;

	while (next) {
		if (next.id === thisId) {
			return false;
		}

		next = data.find((d) => d.id === next!.parentId);
	}

	return true;
};

export const TreeNode = ({
	data,
	id,
	mode,
	name,
	onSelect,
	rootId,
	isMenuChild,
	selectedId,
}: Props) => {
	const current = data.find((d) => d.id === id);
	const children = data.filter((d) => d.parentId === id);

	const [collapsed, setCollapsed] = useState(
		mode === "tree" && shouldBeCollapsed(data, id, rootId, selectedId),
	);

	const { t } = useTranslation();

	const arrowLabel = useMemo(() => {
		if (mode === "tree") {
			return collapsed ? t("display:buttonExpand") : t("display:buttonCollapse");
		}
		return t("display:buttonEnter");
	}, [mode, collapsed, t]);

	if (current === undefined) {
		return <span>error</span>;
	}

	return (
		<div className="she-tree-indent">
			<div
				className={`she-tree-row ${
					mode === "menu" && (isMenuChild ? "she-tree-child" : "she-tree-root")
				}`}
			>
				<TreeRadioButton
					onClick={() => onSelect(id)}
					onKeyDown={(event) => {
						if (event.key === "Enter" || event.key === " ") {
							onSelect(id);
						}
					}}
					selected={selectedId === id}
				>
					{current.name}
				</TreeRadioButton>
				{children.length > 0 && (mode === "menu" ? isMenuChild : true) && (
					<button
						aria-label={arrowLabel}
						className="she-icon"
						onClick={() => {
							if (mode === "tree") {
								setCollapsed(!collapsed);
							}

							if (mode === "menu") {
								onSelect(current.id);
							}
						}}
						type="button"
					>
						{mode === "tree" ? (
							<SVGArrow
								className={`she-tree-arrow ${
									collapsed && "she-tree-arrow-collapsed"
								}`}
							/>
						) : (
							<SVGArrow
								className={`she-tree-arrow ${
									isMenuChild && "she-tree-arrow-collapsed"
								}`}
							/>
						)}
					</button>
				)}
			</div>
			{children.length > 0 && !collapsed && isMenuChild !== true && (
				<List>
					{children.map((c) => (
						<TreeNode
							data={data}
							id={c.id}
							isMenuChild={mode === "menu"}
							key={c.id}
							mode={mode}
							name={name}
							onSelect={onSelect}
							rootId={rootId}
							selectedId={selectedId}
						/>
					))}
				</List>
			)}
		</div>
	);
};
