import { v4 as uuid } from "uuid";

export enum DownloadState {
	InProgress,
	Error,
}

export class Download {
	public fileName: string;
	public status: DownloadState = DownloadState.InProgress;
	public uuid: string = uuid();
	public downloadPromise: () => Promise<Blob>;
	public description?: string[];
	public addExtension?: boolean;

	constructor(downloadPromise: () => Promise<Blob>, fileName: string) {
		this.downloadPromise = downloadPromise;
		this.fileName = fileName;
	}
}
