import { PolicyAttachmentsItem } from "../../../PortalPage/components/PolicyAttachments/PolicyAttachmentsItem.component";
import type { RecordAttachment } from "../../../../../models/record";
import type { Attachment } from "../../../../../models/policy";
import "./AttachmentsList.styles.scss";

interface Props {
	attachments?: RecordAttachment[];
	recordTitle?: string;
}

export const AttachmentsList = ({ attachments, recordTitle }: Props) => {
	return (
		<ul className="attachment-list" data-testid="attachment-list">
			{attachments &&
				attachments.map((a, i) => {
					const attachment = {
						attachmentType: a.AttachmentType,
						dateCreated: a.DateCreated,
						fileDescription: a.FileDescription,
						fileName: a.FileName,
						fileSize: a.FileSize && (a.FileSize / 1000).toFixed(2),
						identifier: a.Identifier,
						link: a.Link,
						policyTitle: recordTitle,
					} as Attachment;

					return <PolicyAttachmentsItem attachment={attachment} key={i} modalStyles />;
				})}
		</ul>
	);
};
