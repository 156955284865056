import { useMemo } from "react";
import type { TFunction } from "i18next";
import type { ActionInfo } from "../../../../models/action";

export const useActionLabel = (propertyName: string, t: TFunction, actionInfo?: ActionInfo[]) => {
	return useMemo(() => {
		if (actionInfo) {
			const info = actionInfo.find((info) => info.PropertyName === propertyName);
			if (info && info.Caption) {
				return info.Caption;
			}
		}
		return t(`display:myTasks.label${propertyName}`);
	}, [actionInfo, t, propertyName]);
};
