import { NumberFieldValidator } from "../numberFieldValidator";
import type { AuditField } from "../../models/fields/AuditField";

export class AuditFieldValidator {
	auditField: AuditField;
	messages: string[];

	constructor(auditField: AuditField) {
		this.auditField = auditField;
		this.messages = [];
	}

	isFieldValid(scoreValue: string): boolean {
		this.messages = [];
		return this.isAuditScoreValid(scoreValue);
	}

	isAuditScoreValid(scoreValue: string): boolean {
		const numberValidator = new NumberFieldValidator(this.auditField.scoreField);
		const validationResult = numberValidator.isFieldValid(
			scoreValue,
			this.auditField.scoreField.pattern,
			false,
			false,
		);
		this.messages = numberValidator.messages;
		return validationResult;
	}
}
