import { useMemo } from "react";
import moment from "moment";
import { DateTimeFieldValidator } from "../../../../validators/dateTimeFieldValidator";
import {
	splitTimeToObject,
	removeSpaces,
	getPortalCulture,
	getTimeFormat,
} from "../../../../helpers/DateTimeInputHelper";
import { TimeInputPresentation } from "./TimeInput.presentation";
import type { TimeField } from "../../../../models/fields/TimeField";

interface Props {
	field: TimeField;
	updateField: (fieldId: number, value: any) => void;
	updateValidationStatus: (fieldId: number, value: string[]) => void;
}

export const TimeInputContainer = ({ field, updateField, updateValidationStatus }: Props) => {
	const dateTimeFieldValidator = useMemo(() => new DateTimeFieldValidator(field), [field]);
	const portalCulture = getPortalCulture();
	const timeValues = {
		hour: field.value && field.value.hour ? field.value.hour : "",
		minute: field.value && field.value.minute ? field.value.minute : "",
		amPm:
			field.value && field.value.amPm ? field.value.amPm : portalCulture === "Us" ? "am" : "",
	};

	const handleChange = (returnedValues: any) => {
		dateTimeFieldValidator.isFieldValid(returnedValues);
		updateValidationStatus(field.id, dateTimeFieldValidator.messages);
		updateField(field.id, returnedValues);
	};

	const calculateTimeNow = () => {
		const timeFormatToUse = getTimeFormat();
		const timeNow = moment().format(timeFormatToUse);
		const timeValues = splitTimeToObject(timeNow.toString());
		handleChange(timeValues);
	};

	return (
		<TimeInputPresentation
			guid={field.guid}
			isNotApplicable={field.isNotApplicable}
			name={removeSpaces(field.name)}
			onChange={handleChange}
			onNowClick={calculateTimeNow}
			required={field.isMandatory}
			showAmPm={portalCulture === "Us"}
			values={timeValues}
		/>
	);
};
