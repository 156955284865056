import type { Field } from "../fields/Field";
import type { Dictionary } from "../dictionary/Dictionary";
import type { Matrix } from "../matrix/matrix";
import type { SubModuleRecord } from "./SubModuleRecord";
import type { Note } from "./Note";
import type {
	Questionnaire,
	Status,
	Section,
	Group,
	SubModule,
	BranchCondition,
	QuestionnaireEncryptionVersion,
} from ".";

export interface QuestionnaireTemplate {
	questionnaire: Questionnaire;
	status: Status;
	sections: Section[];
	groups: Group[];
	fields: Field[];
	subModules: SubModule[];
	subModuleRecords: SubModuleRecord[];
	dictionaries: Dictionary[];
	matrixes: Matrix[];
	branchConditions: BranchCondition[];
	notes: Note[];
	userId?: string;
}

export interface EncryptedQuestionnaireTemplate {
	questionnaire: Questionnaire;
	status: Status;
	userId: string;
	encryptedQuestionnaire: ArrayBuffer;
	encryptionVersion?: QuestionnaireEncryptionVersion;
}

export type StoredQuestionnaireTemplate = QuestionnaireTemplate | EncryptedQuestionnaireTemplate;

export function isEncryptedQuestionnaireTemplate(
	questionnaire: StoredQuestionnaireTemplate,
): questionnaire is EncryptedQuestionnaireTemplate {
	if ((questionnaire as EncryptedQuestionnaireTemplate).userId) {
		return true;
	}
	return false;
}
