import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { PageLanguageSwitcherPresentation } from "./PageLanguageSwitcher.presentation";
import type { UserLanguage } from "../../../../../models/portal";
import type { PortalTranslationLanguage } from "../../../../../types/i18next";

interface Props {
	languages: UserLanguage[];
	setSelectedLanguage: (key: string) => void;
	selectedLanguage: string;
}

export const PageLanguageSwitcherContainer = ({
	languages,
	selectedLanguage,
	setSelectedLanguage,
}: Props) => {
	const { t } = useTranslation();

	const languagesMemo: Record<string, string> = useMemo(() => {
		const sortedLanguages = languages.sort((a, b) => {
			return t(
				`display:language${a.idString.replace("-", "") as PortalTranslationLanguage}`,
			).localeCompare(
				t(`display:language${b.idString.replace("-", "") as PortalTranslationLanguage}`),
			);
		});
		return sortedLanguages.reduce((acc, current) => {
			return {
				...acc,
				[current.idString]: t(
					`display:language${
						current.idString.replace("-", "") as PortalTranslationLanguage
					}`,
				),
			};
		}, {});
	}, [languages, t]);

	const onChange = function (key: string) {
		setSelectedLanguage(key);
	};

	return (
		<PageLanguageSwitcherPresentation
			languageLabel={t("display:labelLanguageRegion")}
			languages={languagesMemo}
			onChange={(key) => onChange(key)}
			selectedLanguage={selectedLanguage}
		/>
	);
};
