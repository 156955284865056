import { RecordPropertyType, RecordSortOrder } from "../../../../models/record";
import type { RecordSortPropertyInfo } from "../../../../models/record";

const dateProperties = ["AssessmentDate", "ApprovalDate", "DateCreated", "DateModified"];
const numericProperties = ["NumberExposed"];

const allowedProperties = [
	"EntityReference",
	"OrgUnit",
	"Location",
	"Locality",
	"AssessmentDate",
	"RiskCategory",
	"Description",
	"ActivityDescription",
	"ApprovalDate",
	"SupplierName",
	"ProductName",
	"TemplateName",
	"SubstanceDetails",
	"ActivityOrProcess",
	"TypeOfTask",
	"Title",
	"Category",
	"Details",
	"DateCreated",
	"DateModified",
	"CompanyIdentification",
	"AssessorName",
	"Assessor",
	"AssessorFullName",
	"UserFullName",
	"ResponsiblePerson",
	"NumberExposed",
];

export const mapSortProperties = (sortProperties: any): RecordSortPropertyInfo[] => {
	return sortProperties
		.sort((a: any, b: any) => {
			return a.Caption < b.Caption ? -1 : 1;
		})
		.reduce((acc: RecordSortPropertyInfo[], s: any) => {
			if (s.Visible && allowedProperties.includes(s.PropertyName)) {
				return [...acc, ...mapSortProperty(s)];
			}
			return acc;
		}, []);
};

const mapSortProperty = (sortProperty: any): RecordSortPropertyInfo[] => {
	return [
		{
			propertyName: sortProperty.PropertyName,
			caption: sortProperty.Caption,
			type: mapPropertyToType(sortProperty.PropertyName),
			order: RecordSortOrder.Asc,
		},
		{
			propertyName: sortProperty.PropertyName,
			caption: sortProperty.Caption,
			type: mapPropertyToType(sortProperty.PropertyName),
			order: RecordSortOrder.Desc,
		},
	];
};

const mapPropertyToType = (property: string) => {
	if (dateProperties.includes(property)) {
		return RecordPropertyType.Date;
	} else if (numericProperties.includes(property)) {
		return RecordPropertyType.Number;
	}

	return RecordPropertyType.String;
};
