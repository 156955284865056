import { useTranslation } from "react-i18next";

interface Props {
	errorMsg: string;
}

export const TemplateErrorComponent = ({ errorMsg }: Props) => {
	const { t } = useTranslation();

	return (
		<div className="she-components-sync-info-content-error" data-testid="item-error">
			{(() => {
				switch (errorMsg) {
					case "QuotaExceededError":
						return t("error:downloadTemplateSpaceError");
					case "networkError":
						return t("error:downloadTemplateNetworkError");
					default:
						return t("error:downloadTemplateUnexpectedError");
				}
			})()}
		</div>
	);
};
