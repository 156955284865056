import type { Language } from "../../types/i18next";
import type { QuestionnaireProgressState } from "../questionnaire";

export interface Portal {
	key: string;
	customerKey: string;

	title: string;
	notification?: {
		title: string;
		description: string;
	};

	dateFormat: string;
	attachmentSizeLimit: number;

	logoGuid?: string;
	logo?: Blob;
	storedLogo?: ArrayBuffer;

	questionnaireStubs: QuestionnaireStub[];

	defaultOrgUnitId?: number;
	maskedOrgUnitId?: number;
	excludedFileTypes: string;

	userLanguages: UserLanguage[];
	defaultPortalLanguage?: string;
	questionnaireTemplateSyncRequired: boolean;
	initialSetupCompleted: boolean;
	installModules?: InstallModule[];
	installModulesSectionTitle?: InstallModulesSectionTitle[];

	authenticationMode?: PortalAuthenticationMode;

	showLearnTraining?: boolean;
	theme?: string;
}

export interface QuestionnaireStub {
	key: string;
	title: string;
	description: string;
	module: string;
	language: string;

	imageGuid?: string;
	image?: Blob;
	storedImage?: ArrayBuffer;
	makeAvailableOffline: boolean;
	questionnaireStatus: QuestionnaireStatus;
	syncError?: string;
	authenticationRequired?: boolean;
}

export interface UserLanguage {
	idDecimal: number;
	idString: Language;
	isDefault: boolean;
}

export interface RecordStub {
	id: string;
	name: string;
	dateCreated: Date;
	templateStub: QuestionnaireStub;
	dateCompleted: Date;
	userId?: string;
	state?: QuestionnaireProgressState;
	highlight?: boolean;
	errors?: any;
}

export interface InstallModule {
	id: number;
	key?: string;
	caption?: string;
}

export interface InstallModulesSectionTitle {
	title?: string;
	language?: string;
}

export enum QuestionnaireStatus {
	Pending = "PENDING",
	Downloading = "DOWNLOADING",
	Downloaded = "DOWNLOADED",
	Error = "ERROR",
}

export enum PortalViewType {
	Forms = "forms",
	InProgress = "drafts",
	Queued = "queue",
	Records = "records",
	Documents = "documents",
	Actions = "actions",
	Home = "",
	Training = "training",
}

export type PortalTypes = "Public" | "Private" | "Hybrid";

export enum PortalAuthenticationMode {
	Public = 0,
	Private = 1,
	Hybrid = 2,
}
