import { logException, logBreadcrumb } from "../../../services/log";

export const FeaturesPage = () => {
	return (
		<div className="she-features-wrapper">
			<div className="she-components-control">
				<span>Send raygun message:</span>
				<div className="she-components-control">
					<button
						className="she-btn she-btn-primary she-features-button"
						id="logException1"
						onClick={() => {
							logException({
								error: new Error("internal test error"),
							});
						}}
						type="button"
					>
						Send Error message
					</button>
				</div>
				<div className="she-components-control">
					<button
						className="she-btn she-btn-primary she-features-button"
						id="logException2"
						onClick={() => {
							logException(new Error("alternate internal test error"));
						}}
						type="button"
					>
						Send Alternate message
					</button>
				</div>
				<div className="she-components-control">
					<button
						className="she-btn she-btn-primary she-features-button"
						id="logException3"
						onClick={() => {
							logException({
								error: new Error("internal test error with custom data"),
								customData: { custom: "data" },
							});
						}}
						type="button"
					>
						Send Error with Custom Data
					</button>
				</div>
				<div className="she-components-control">
					<button
						className="she-btn she-btn-primary she-features-button"
						id="logBreadcrumb"
						onClick={() => {
							logBreadcrumb("test breadcrumb", { custom: "data" });
						}}
						type="button"
					>
						Send Breadcrumb
					</button>
				</div>
			</div>
		</div>
	);
};
