import { useTranslation } from "react-i18next";
import { IllustratedThemedModal } from "../../modals/IllustratedThemedModal";
import { NotificationModal } from "../../modals";
import { Loading } from "../../Loading";
import { ReactComponent as FilesTooBig } from "../../../assets/svg/illustrations/fileTooBig.svg";
import "./FileInput.styles.scss";

interface Props {
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	validationErrors: string[];
	addButtonText: string;
	parentGuid?: string;
	filesTooBig: boolean;
	filesTooBigMessage: string;
	okButtonText: string;
	largeFiles: { fileName: string; fileSize: string }[];
	onFileToLargeOk: () => void;
	showEmptyFiles: boolean;
	emptyFiles: string[];
	onEmptyFilesOk: () => void;
	attachmentLimit: number;
	attachmentLimitMet: boolean;
	attachmentLimitMessage: string;
	displayMaxLimitModal: boolean;
	setDisplayMaxLimitModal: (e: boolean) => void;
	processing: boolean;
}

export const FileInputPresentation = ({
	addButtonText,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	attachmentLimit,
	attachmentLimitMessage,
	attachmentLimitMet,
	displayMaxLimitModal,
	emptyFiles,
	filesTooBig,
	filesTooBigMessage,
	largeFiles,
	okButtonText,
	onChange,
	onEmptyFilesOk,
	onFileToLargeOk,
	processing,
	setDisplayMaxLimitModal,
	showEmptyFiles,
	validationErrors,
	parentGuid,
}: Props) => {
	const { t } = useTranslation();

	return (
		<>
			<div className="she-file-input" data-testid="she-file-input">
				{validationErrors &&
					validationErrors.length > 0 &&
					validationErrors.map((e: string, i: number) => (
						<div className="she-components-validation-message" key={i}>
							{e}
						</div>
					))}
				<div>
					<input
						id={`${parentGuid !== undefined ? parentGuid : ""}photo-input`}
						multiple
						onChange={onChange}
						type="file"
					/>
					<label
						className={`she-btn she-btn-tertiary ${
							attachmentLimitMet && "she-btn-limited"
						}`}
						htmlFor={`${parentGuid !== undefined ? parentGuid : ""}photo-input`}
						onClick={(e) => {
							if (attachmentLimitMet) {
								e.preventDefault();
								setDisplayMaxLimitModal(true);
							}
						}}
					>
						{addButtonText}
					</label>
				</div>
			</div>

			<NotificationModal
				closeText={okButtonText}
				content={attachmentLimitMessage}
				onClose={() => setDisplayMaxLimitModal(false)}
				show={displayMaxLimitModal}
			/>

			<IllustratedThemedModal
				cancelText={okButtonText}
				onCancel={onFileToLargeOk}
				show={filesTooBig && !processing}
				text={filesTooBigMessage}
			>
				<FilesTooBig />
				<div className="she-large-file-wrapper">
					{largeFiles.map((f: { fileName: string; fileSize: string }, i: number) => (
						<div className="she-large-file" key={i}>
							<div>{f.fileName}</div>
							<div>{f.fileSize}</div>
						</div>
					))}
				</div>
			</IllustratedThemedModal>

			<IllustratedThemedModal
				cancelText={okButtonText}
				onCancel={onEmptyFilesOk}
				show={showEmptyFiles && !processing}
				text={t("validation:attachmentSizeIncorrect")}
			>
				<FilesTooBig />
				<div className="she-large-file-wrapper">
					{emptyFiles.map((name) => (
						<div className="she-large-file" key={name}>
							<div>{name}</div>
						</div>
					))}
				</div>
			</IllustratedThemedModal>

			{processing && (
				<Loading
					show={processing}
					text={t("display:labelProcessing")}
					withHistory={false}
				/>
			)}
		</>
	);
};
