import classnames from "classnames";
import { NavLink } from "react-router-dom";
import "./NavTabGroup.styles.scss";

export interface TabProps {
	to: string;
	label: string;
	alert?: boolean;
	exact?: boolean;
	pill?: string;
}

export const NavTab = ({ label, to, alert, exact, pill }: TabProps) => {
	const alertClassName = classnames({
		"she-nav-tab__alert": alert,
	});

	return (
		<li className="she-nav-tab__tab">
			<NavLink activeClassName="she-nav-tab__tab__link--selected" exact={exact} to={to}>
				<span className="she-nav-tab__label">{label}</span>
				<span className={alertClassName}>
					{pill ? <span className="she-nav-tab__pill">{pill}</span> : null}
				</span>
			</NavLink>
		</li>
	);
};
