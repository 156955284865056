import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ActionAttachmentFileItem } from "../ActionAttachmentFileItem";
import { ActionAttachmentLinkItem } from "../ActionAttachmentLinkItem";
import { ConfirmationModal } from "../../../../components/modals";
import type { ActionAttachment } from "../../../../../models/action";

interface Props {
	attachments: ActionAttachment[];
	readonly: boolean;
	removeAttachment?: (identifier: string) => void;
}

export const ActionAttachmentsList = ({ attachments, readonly, removeAttachment }: Props) => {
	const { t } = useTranslation();
	const [displayDeleteConfirmation, setDisplayDeleteConfirmation] = useState(false);
	const [attachmentToDelete, setAttachmentToDelete] = useState("");

	const handleOnDelete = (identifier: string) => {
		setDisplayDeleteConfirmation(true);
		setAttachmentToDelete(identifier);
	};

	const handleOnDeleteCancel = () => setDisplayDeleteConfirmation(false);

	const handleOnDeleteConfirm = () => {
		if (removeAttachment) {
			removeAttachment(attachmentToDelete);
		}
		setDisplayDeleteConfirmation(false);
	};

	return (
		<>
			<div className="she-components-control-question">{t("display:labelAttachments")}</div>
			<div>
				{attachments.map((attachment) => {
					if (
						attachment.AttachmentType === "File" ||
						attachment.AttachmentType === "NewFile"
					) {
						return (
							<ActionAttachmentFileItem
								file={attachment.File}
								fileDescription={attachment.FileDescription}
								fileName={attachment.FileName}
								identifier={attachment.Identifier}
								key={attachment.Identifier}
								onDelete={() => handleOnDelete(attachment.Identifier)}
								readonly={readonly}
							/>
						);
					} else if (attachment.AttachmentType === "Link") {
						return (
							<ActionAttachmentLinkItem
								fileDescription={attachment.FileDescription}
								identifier={attachment.Identifier}
								key={attachment.Identifier}
								link={attachment.Link}
								onDelete={() => handleOnDelete(attachment.Identifier)}
								readonly={readonly}
							/>
						);
					}
					return null;
				})}
			</div>
			<ConfirmationModal
				cancelText={t("global:no")}
				confirmText={t("global:yes")}
				onCancel={handleOnDeleteCancel}
				onConfirm={handleOnDeleteConfirm}
				show={displayDeleteConfirmation}
				text={t("display:labelDeleteActionAttachmentMessage")}
			/>
		</>
	);
};
