import { useCallback } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ActionDetail } from "../ActionDetail/ActionDetail.component";
import { ErrorViewer } from "../../../../components/ErrorViewer/ErrorViewer.component";
import { FormModal } from "../../../../components/modals";
import { switchMatchingLanguageCode } from "../../../../../helpers/LanguageCodeHelper";
import { usePortalPath } from "../../../../../helpers/UsePortalPath";
import { useGetActionQuery } from "../../hooks/useGetActionQuery";

interface Match {
	params: { action: string };
}

export const ActionModal = () => {
	const { actionId } = useParams<{ actionId: string }>();
	const { t, i18n } = useTranslation();
	const language = switchMatchingLanguageCode(i18n.language);
	const portalPath = usePortalPath();
	const history = useHistory();
	const match: Match | null = useRouteMatch(
		"/:customerKey/p/:portalKey/actions/:action(edit|detail|complete)/:actionId",
	);

	const {
		isLoading,
		isError,
		data: action,
		error,
	} = useGetActionQuery(actionId, !!match, language);

	const onCancel = () => {
		history.push(`${portalPath}actions`);
	};

	const openEditAction = useCallback(() => {
		history.push({
			pathname: `${portalPath}actions/edit/${actionId}`,
			state: {
				previousPath: history.location.pathname,
			},
		});
	}, [history, portalPath, actionId]);

	return (
		<FormModal
			cancelText={t("global:close")}
			large
			okText={t("display:myTasks.labelEditTask")}
			onCancel={onCancel}
			onOk={openEditAction}
			show={match !== null && match.params.action === "detail"}
			title={t("display:myTasks.labelViewTask")}
			withHistory={false}
		>
			{isLoading && <div>{t("display:labelLoading")}</div>}

			{isError && (
				<>
					<div>{t("display:labelError")}</div>
					<ErrorViewer error={error} />
				</>
			)}

			{action && !isLoading && !isError ? <ActionDetail action={action} /> : null}
		</FormModal>
	);
};
