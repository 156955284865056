import { AnimationSlideFade } from "../../animation/AnimationSlideFade";
import { AnimationWrapper } from "../../animation/AnimationWrapper";
import { RadioButton } from "../RadioButton";

interface Props {
	defaultValueLabel: string;
	selectValueLabel: string;
	guid: string;
	useDefaultValue?: boolean;
	onUseDefaultValueChange: (useDefault: boolean) => void;
	children?: React.ReactNode;
	isMandatory: boolean;
}

export const WithDefaultValue = ({
	defaultValueLabel,
	selectValueLabel,
	guid,
	useDefaultValue = true,
	onUseDefaultValueChange,
	children,
	isMandatory,
}: Props) => {
	return (
		<>
			<div
				aria-labelledby={`${guid}-label ${guid}-description`}
				aria-required={isMandatory}
				role="radiogroup"
			>
				<RadioButton
					id={`${guid}-default`}
					name={guid.toString()}
					onClick={() => onUseDefaultValueChange(true)}
					selected={useDefaultValue}
				>
					{defaultValueLabel}
				</RadioButton>
				<RadioButton
					id={`${guid}-select`}
					name={guid.toString()}
					onClick={() => onUseDefaultValueChange(false)}
					selected={!useDefaultValue}
				>
					{selectValueLabel}
				</RadioButton>
			</div>
			<AnimationWrapper>
				{!useDefaultValue && <AnimationSlideFade>{children}</AnimationSlideFade>}
			</AnimationWrapper>
		</>
	);
};
