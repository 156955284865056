import { useMemo } from "react";
import { FieldOrderComparer } from "../../../../helpers/SortingHelper";
import { QuestionnaireField } from "../../../pages/QuestionnairePage/Components/QuestionnaireField";
import { AnimationSlideFade } from "../../animation/AnimationSlideFade";
import { AnimationWrapper } from "../../animation/AnimationWrapper";
import type { Field } from "../../../../models/fields/Field";

interface Props {
	field: Field;
}

export const OptionalRegisterSelectManualEntry = ({ field }: Props) => {
	const visibleSortedFields = useMemo(() => {
		if (!field.relatedFields) {
			return;
		}
		return field.relatedFields
			.filter((f) => !f.hidden)
			.sort((a, b) => FieldOrderComparer(a, b))
			.map((f) => {
				if (f.parentType && f.parentType !== "Section") {
					return (
						<AnimationSlideFade key={f.guid} measure>
							<QuestionnaireField fieldGuid={f.guid} />
						</AnimationSlideFade>
					);
				}
				return <QuestionnaireField fieldGuid={f.guid} key={f.guid} />;
			});
	}, [field]);

	return (
		<div className="optional-register-select-option__content">
			<AnimationWrapper>{visibleSortedFields}</AnimationWrapper>
		</div>
	);
};
