import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { NoteRecordDisplay } from "../NoteRecord";
import { AnimationWrapper } from "../../../../../components/animation/AnimationWrapper";
import { AnimationSlideFade } from "../../../../../components/animation/AnimationSlideFade";
import styles from "./styles.module.scss";
import type { Note } from "../../../../../../models/questionnaire";

interface Props {
	notes?: Note[];
	addNote: () => void;
	remove: (note: Note) => void;
	edit: (note: Note) => void;
	showNotesHeader: () => boolean;
}

export const NoteListPresentation = ({ addNote, edit, remove, showNotesHeader, notes }: Props) => {
	const { t } = useTranslation();
	const ref = useRef<HTMLButtonElement>(null);

	return (
		<>
			<div className={`${styles.sheNote}`}>
				{showNotesHeader() && (
					<label className="she-components-control-subtitle">
						{t("display:labelNotes")}
					</label>
				)}

				<AnimationWrapper>
					{notes?.map((x) => {
						return (
							<AnimationSlideFade key={x.id}>
								<NoteRecordDisplay
									deleteNote={() => {
										remove(x);
										ref.current?.focus();
									}}
									editNote={edit}
									note={x}
								/>
							</AnimationSlideFade>
						);
					})}
				</AnimationWrapper>
			</div>
			<button className="she-btn she-btn-tertiary" onClick={addNote} ref={ref} type="button">
				{t("display:labelAddNote")}
			</button>
		</>
	);
};
