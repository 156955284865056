import { useMemo } from "react";
import { SignatureFieldValidator } from "../../../../validators/signatureFieldValidator";
import { SignatureInputContainer } from "./SignatureInput.container";
import type { SignatureField } from "../../../../models/fields/SignatureField";

interface Props {
	signatureField: SignatureField;
	updateField: (fieldId: number, value: any) => void;
	updateValidationStatus: (fieldId: number, value: string[]) => void;
	validateOnEnd?: boolean;
}

export const SignatureInputComponent = ({
	signatureField,
	updateField,
	updateValidationStatus,
	validateOnEnd,
}: Props) => {
	const validator = useMemo(() => new SignatureFieldValidator(signatureField), [signatureField]);

	return (
		<SignatureInputContainer
			signatureField={signatureField}
			updateField={(value) => updateField(signatureField.id, value)}
			updateValidationStatus={(value) => updateValidationStatus(signatureField.id, value)}
			validateOnEnd={validateOnEnd}
			validator={validator}
		/>
	);
};
