import type { BranchCondition } from "../models/questionnaire";
import type { Field } from "../models/fields/Field";

/**
 * Check if the field is the first NA enabled field from the root.
 */

export const isTopNAQuestion = (
	field: Field,
	fields: Field[],
	conditions: BranchCondition[],
): boolean => {
	if (field.parentType && field.parentType === "Section") {
		return true;
	} else if (field.parentType && field.parentType === "Condition") {
		const condition = conditions.find((c) => field.parentId === c.id);
		if (condition) {
			const parentField = fields.find((f) => f.id === condition.questionTemplateId);
			if (parentField && parentField.allowNotApplicable) {
				return false;
			} else if (parentField) {
				return isTopNAQuestion(parentField, fields, conditions);
			}
		}
	}
	return true;
};
