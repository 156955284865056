import { useTranslation } from "react-i18next";
import "./Pagination.styles.scss";

interface Props {
	currentPage: number;
	maxPages: number;
	onChangePage: (page: number) => void;
}

export const Pagination = ({ currentPage, maxPages, onChangePage }: Props) => {
	const { t } = useTranslation();

	const onPrevPage = () => {
		if (currentPage > 1) {
			// TODO: Check this, as it's mutation the original prop coming in. TUT TUT
			onChangePage(--currentPage);
		}
	};

	const onNextPage = () => {
		if (currentPage < maxPages) {
			// TODO: Check this, as it's mutation the original prop coming in. TUT TUT
			onChangePage(++currentPage);
		}
	};

	return (
		<div className="she-pagination">
			<div className="she-pagination__container">
				{currentPage > 1 && (
					<button
						className="she-btn she-btn-tertiary"
						data-testid="pagination-previous"
						onClick={onPrevPage}
						type="button"
					>
						{t("display:labelPagePrevious")}
					</button>
				)}
			</div>
			<div className="she-pagination__container" data-testid="pagination-label">
				{t("display:labelPageNumber", {
					page: currentPage,
					maxPages,
				})}
			</div>
			<div className="she-pagination__container">
				{currentPage < maxPages && (
					<button
						className="she-btn she-btn-tertiary"
						data-testid="pagination-next"
						onClick={onNextPage}
						type="button"
					>
						{t("display:labelPageNext")}
					</button>
				)}
			</div>
		</div>
	);
};
