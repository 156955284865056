import { ThumbnailList } from "../ThumbnailList";
import { FileInput } from "../input/FileInput";
import type { AttachmentParentType } from "../../../models/attachments/Attachment";

interface Props {
	questionnaireId: string;
	parentGuid?: string;
	attachmentsTitle: string;
	showAttachments: boolean;
	isMandatory: boolean;
	hasValidationErrors: boolean;
	validationErrors: string[];
	parentType: AttachmentParentType;
}

export const AttachmentsDisplayPresentation = ({
	questionnaireId,
	parentGuid,
	attachmentsTitle,
	showAttachments,
	isMandatory,
	hasValidationErrors,
	validationErrors,
	parentType,
}: Props) => {
	return (
		<div
			className={`she-components-submodule  ${
				hasValidationErrors ? "she-components-validation-border-left" : ""
			}`}
		>
			{(showAttachments || isMandatory) && (
				<h1 className="she-components-control-subtitle">
					{attachmentsTitle}
					<abbr
						className="she-components-control-required-indicator"
						title={isMandatory ? "required" : "optional"}
					>
						{isMandatory ? "*" : ""}
					</abbr>
				</h1>
			)}
			{validationErrors &&
				validationErrors.length > 0 &&
				validationErrors.map((e: string, i: number) => (
					<div className="she-components-validation-message" key={i}>
						{e}
					</div>
				))}
			<ThumbnailList
				displayNoAttachmentsMessage={false}
				parentGuid={parentGuid}
				parentType={parentType}
				questionnaireId={questionnaireId}
			/>
			<FileInput
				parentGuid={parentGuid}
				parentType={parentType}
				questionnaireId={questionnaireId}
				validateAttachmentsLimit
			/>
		</div>
	);
};
