import { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
	getLeadingZeroValue,
	setElementsFocus,
	isKeyDownValid,
	isPasteValid,
} from "../../../../helpers/DateTimeInputHelper";
import { DropdownContainer } from "../DropDown/Dropdown.container";
import "./styles.scss";
import { AnimationWrapper } from "../../animation/AnimationWrapper";
import { AnimationSlideFade } from "../../animation/AnimationSlideFade";

interface Props {
	name: string;
	required: boolean;
	values: {
		hour: string;
		minute: string;
		amPm?: string;
	};
	guid: string;
	showAmPm: boolean;
	isNotApplicable: boolean;
	onChange: (values: object) => void;
	onNowClick: () => void;
}

export const TimeInputPresentation = ({
	guid,
	isNotApplicable,
	name,
	onChange,
	onNowClick,
	required,
	showAmPm,
	values,
}: Props) => {
	const { t } = useTranslation();

	const hourRef = useRef<HTMLInputElement>(null);
	const minuteRef = useRef<HTMLInputElement>(null);

	const hourName = `${name}_hour`;
	const minuteName = `${name}_minute`;
	const amPmName = `${name}_amPm`;
	const [measureHeight, setMeasureHeight] = useState(false);

	useEffect(() => {
		if (isNotApplicable && !measureHeight) {
			setMeasureHeight(true);
		} else if (!isNotApplicable && measureHeight) {
			setMeasureHeight(false);
		}
	}, [isNotApplicable, measureHeight]);

	const setLeadingZero = (valueToUse: any, updateHourValue: boolean) => {
		if (valueToUse.length === 1) {
			valueToUse = getLeadingZeroValue(valueToUse);
			updateHourValue ? (values.hour = valueToUse) : (values.minute = valueToUse);
			onChange(values);
		}
	};

	const hourInput = (
		<label className="she-components-text-input__time-inline">
			<span className="she-components-text-input__time-label" id={`${guid}-label-hour`}>
				{t("display:labelHour")}
			</span>

			<input
				aria-labelledby={`${guid}-label ${guid}-label-hour ${guid}-description`}
				aria-required={required}
				className="she-components-text-input"
				id={`${guid}_hour`}
				inputMode="numeric"
				maxLength={2}
				name={hourName}
				onBlur={(e) => {
					setLeadingZero(e.target.value, true);
				}}
				onChange={(e) => {
					values.hour = e.target.value;
					onChange(values);
					setElementsFocus(e, minuteRef);
				}}
				onKeyDown={isKeyDownValid}
				onPaste={isPasteValid}
				placeholder={t("display:placeHolderHour")}
				ref={hourRef}
				required={required}
				type="text"
				value={values.hour}
			/>
		</label>
	);

	const minuteInput = (
		<label className="she-components-text-input__time-inline">
			<span className="she-components-text-input__time-label" id={`${guid}-label-minute`}>
				{t("display:labelMinute")}
			</span>

			<input
				aria-labelledby={`${guid}-label ${guid}-label-minute ${guid}-description`}
				aria-required={required}
				className="she-components-text-input"
				id={`${guid}_minute`}
				inputMode="numeric"
				maxLength={2}
				name={minuteName}
				onBlur={(e) => {
					setLeadingZero(e.target.value, false);
				}}
				onChange={(e) => {
					values.minute = e.target.value;
					onChange(values);
				}}
				onKeyDown={isKeyDownValid}
				onPaste={isPasteValid}
				placeholder={t("display:placeHolderMinute")}
				ref={minuteRef}
				required={required}
				type="text"
				value={values.minute}
			/>
		</label>
	);

	const amPmInput = (
		<label className="she-components-text-input__time-inline she-components-text-input__time-inline--very-long">
			<span className="she-components-text-input__time-label" id={`${guid}-label-ampm`}>
				{t("display:labelAmPm")}
			</span>

			<DropdownContainer
				ariaLabelledby={`${guid}-label ${guid}-label-ampm ${guid}-description`}
				data={[
					{ key: "am", value: t("display:labelAm") },
					{ key: "pm", value: t("display:labelPm") },
				]}
				guid={`${guid}_amPm`}
				isMandatory={required}
				isTopOptionTitle={false}
				name={amPmName}
				onChange={(e) => {
					values.amPm = e;
					onChange(values);
				}}
				value={values.amPm}
			/>
		</label>
	);

	return (
		<AnimationWrapper>
			{!isNotApplicable && (
				<AnimationSlideFade key={guid} measure={measureHeight}>
					<div className="she-time-wrapper">
						{hourInput}
						{minuteInput}
						{showAmPm ? amPmInput : null}
						<div className="she-time-button-wrapper">
							<button
								className="she-btn she-btn-tertiary"
								id={`${guid}_now`}
								onClick={() => {
									onNowClick();
								}}
								type="button"
							>
								{t("display:buttonNow")}
							</button>
						</div>
					</div>
				</AnimationSlideFade>
			)}
		</AnimationWrapper>
	);
};
