import { useState, useEffect, useMemo } from "react";
import { NotesValidator } from "../../../../../../validators/notesValidator";
import { NoteDialogPresentation } from "./NoteDialog.presentation";
import type { Note } from "../../../../../../models/questionnaire";

interface Props {
	saveNote: (note: string) => void;
	cancelNote: () => void;
	noteRecord?: Note;
	noteLength?: number;
	isOpen: boolean;
}

export const NoteDialogContainer = ({
	cancelNote,
	isOpen,
	saveNote,
	noteLength,
	noteRecord,
}: Props) => {
	const [note, setNote] = useState(noteRecord ? noteRecord.value : "");
	const [isEditMode, setIsEditMode] = useState<boolean>(false);
	const [validationErrors, setValidationErrors] = useState<string[]>([]);
	const noteValidator = useMemo(() => new NotesValidator(noteLength), [noteLength]);

	useEffect(() => {
		if (noteRecord) {
			setNote(noteRecord.value);
			setValidationErrors([]);
			setIsEditMode(noteRecord ? noteRecord.value.length > 0 : false);
		}
	}, [noteRecord]);

	if (!noteRecord) {
		return null;
	}

	const onChange = (note: string) => {
		noteValidator.isFieldValid(note);
		setValidationErrors(noteValidator.messages);
		setNote(note);
	};

	const save = () => {
		noteValidator.isFieldValid(note);
		setValidationErrors(noteValidator.messages);
		if (noteValidator.messages.length === 0) {
			saveNote(note);
		}
	};

	return (
		<NoteDialogPresentation
			cancelNote={cancelNote}
			fieldGuid={noteRecord.fieldGuid}
			isEditMode={isEditMode}
			isOpen={isOpen}
			note={note}
			noteLength={noteLength}
			onChange={onChange}
			saveNote={save}
			validationErrors={validationErrors}
		/>
	);
};
