import { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { PageLayout } from "../../layouts/PageLayout";
import { PortalViewType } from "../../../models/portal";
import { RecordModule } from "../../../models/record";
import { ReactComponent as IconBack } from "../../assets/svg/arrow-left.svg";
import { switchMatchingLanguageCode } from "../../../helpers/LanguageCodeHelper";
import { usePortalPath } from "../../../helpers/UsePortalPath";
import { useGetPolicies } from "../../utilities/UseGetPolicies";
import { RecordList } from "./components/RecordList";
import { ModuleList } from "./components/ModuleList";
import type { State } from "../../../state";
import type { Portal } from "../../../models/portal";

const RecordsPage = () => {
	const userLanguage = localStorage.getItem("userLanguage");
	const { customerKey, portalKey, recordsKey } = useParams<{
		customerKey: string;
		portalKey: string;
		recordsKey: string;
	}>();
	const { t } = useTranslation();
	const portal = useSelector<State, Portal | undefined>((s) =>
		s.portal.portals.find(
			(p) => p.key === portalKey.toLowerCase() && p.customerKey === customerKey.toLowerCase(),
		),
	);
	const portalPath = usePortalPath();
	const history = useHistory();

	const { data } = useGetPolicies(portalKey);

	const [caption, setCaption] = useState("");

	useEffect(() => {
		setCaption(t("display:labelRecordsPageTitle"));

		const languageCode = userLanguage && switchMatchingLanguageCode(userLanguage);

		if (portal) {
			const { installModulesSectionTitle }: any = portal;

			if (installModulesSectionTitle && installModulesSectionTitle.length) {
				const captionedLanguage =
					installModulesSectionTitle.find((x: any) => x.language === languageCode) || "";

				if (captionedLanguage.title && captionedLanguage.title.length) {
					setCaption(captionedLanguage.title);
				}
			}
		}
	}, [portal, t, userLanguage]);

	const isRecordList = useMemo(
		() => Object.values(RecordModule).includes(recordsKey as RecordModule),
		[recordsKey],
	);

	const backTo = useMemo(() => {
		if (recordsKey === "Policy") {
			if (data && data.attachments && data.attachments.length) {
				return portalPath + PortalViewType.Documents;
			}
		} else if (isRecordList) {
			return portalPath + PortalViewType.Records;
		}
		return null;
	}, [isRecordList, data, portalPath, recordsKey]);

	const backButton = useMemo(() => {
		return backTo ? (
			<button
				aria-label={t("global:cancel")}
				className="she-icon she-questionnaire-header-icon she-layout-page__back-icon"
				onClick={() => history.push(backTo)}
				type="button"
			>
				<IconBack />
			</button>
		) : null;
	}, [t, history, backTo]);

	return (
		<PageLayout
			portal={portal}
			title={portal ? portal.title : ""}
			titleBarIconSlot={backButton}
		>
			{Object.values(RecordModule).includes(recordsKey as RecordModule) ? (
				<RecordList portal={portal} recordsKey={recordsKey as RecordModule} />
			) : (
				<ModuleList caption={caption} portal={portal} />
			)}
		</PageLayout>
	);
};

export { RecordsPage };
