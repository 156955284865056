import { useMemo, useState } from "react";
import { NumberFieldValidator } from "../../../../validators/numberFieldValidator";
import { UpdatableFieldProperty } from "../../../../state/components/questionnaire/actions/enums";
import { AuditInputPresentation } from "./AuditInput.presentation";
import type { AuditField } from "../../../../models/fields/AuditField";

interface Props {
	auditField: AuditField;
	updateFieldProperty: (
		fieldId: number,
		value: any,
		propertyName: UpdatableFieldProperty,
	) => void;
	updateValidationStatus: (fieldId: number, value: string[]) => void;
}

export const AuditInput = ({ auditField, updateFieldProperty, updateValidationStatus }: Props) => {
	const [scoreComment, setScoreComment] = useState(auditField.scoreComment);
	const numberValidator = useMemo(
		() => new NumberFieldValidator(auditField.scoreField),
		[auditField.scoreField],
	);

	const updateScore = (value: any) => {
		updateFieldProperty(auditField.id, value, UpdatableFieldProperty.AuditScoreValue);
	};

	const updateScoreValidationStatus = (messages: string[]) => {
		updateValidationStatus(auditField.id, messages);
	};

	const updateScoreComment = (value: string) => {
		setScoreComment(value);
	};

	const saveScoreComment = () => {
		updateFieldProperty(auditField.id, scoreComment, UpdatableFieldProperty.AuditScoreComment);
	};

	return (
		<AuditInputPresentation
			guid={auditField.guid}
			isMandatory={auditField.isMandatory}
			isNotApplicable={auditField.isNotApplicable}
			numberValidator={numberValidator}
			onCommentChange={updateScoreComment}
			saveScoreComment={saveScoreComment}
			scoreComment={scoreComment}
			scoreField={auditField.scoreField}
			updateScore={updateScore}
			updateScoreValidationStatus={updateScoreValidationStatus}
		/>
	);
};
