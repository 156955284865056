import classnames from "classnames";
import "./Toast.styles.scss";

interface Props {
	variant?: "normal" | "error" | "success";
	timeout?: number;
	onClick?: () => void;
	children?: React.ReactNode;
}

export const Toast = ({ variant = "normal", onClick, children }: Props) => {
	const className = classnames("she-toast", `she-toast--${variant}`);
	return (
		<div className={className} onClick={onClick}>
			{children}
		</div>
	);
};
