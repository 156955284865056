import { useMemo } from "react";
import type { ActionDictionaryItem } from "../../../../models/action";

export const useFilteredOptions = (
	options: ActionDictionaryItem[],
	orgUnitId?: number,
	parentId?: number,
) => {
	return useMemo(() => {
		return options
			.filter(
				(option) =>
					option.orgUnitIds.length === 0 || option.orgUnitIds.includes(orgUnitId ?? -1),
			)
			.filter((option) => !("parentId" in option) || option.parentId === parentId);
	}, [options, parentId, orgUnitId]);
};
