import { QuestionHeader } from "../../../../components/generic/QuestionHeader";

interface Props {
	guid: string;
	title: string;
	guidance?: string;
	showGuidance: boolean;
	mediaGuidance?: string;
	required: boolean;
	validationErrors?: string[];
	guidanceIsPopup: boolean;
	hideValidationErrors?: boolean;
}

export const QuestionnaireFieldPresentation = ({
	guid,
	guidanceIsPopup,
	required,
	showGuidance,
	title,
	guidance,
	hideValidationErrors,
	mediaGuidance,
	validationErrors,
}: Props) => {
	return (
		<QuestionHeader
			guid={guid}
			guidance={guidance}
			guidanceIsPopup={guidanceIsPopup}
			hideValidationErrors={hideValidationErrors}
			mediaGuidance={mediaGuidance}
			required={required}
			showGuidance={showGuidance}
			title={title}
			validationErrors={validationErrors}
		/>
	);
};
