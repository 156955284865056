import { useEffect, useState, useCallback, createContext } from "react";
import * as serviceWorker from "../../../serviceWorker";

export const WaitingServiceWorkerContext = createContext(false);

type Props = {
	children: React.ReactNode;
};

export const WaitingServiceWorkerProvider = ({ children }: Props) => {
	const [waitingWorkerActivated, setWaitingWorkerActivated] = useState(false);

	const onServiceWorkerActivated = useCallback(() => {
		setWaitingWorkerActivated(true);
	}, []);

	useEffect(() => {
		serviceWorker.register({
			onUpdateActivated: onServiceWorkerActivated,
		});
	}, [onServiceWorkerActivated]);

	return (
		<WaitingServiceWorkerContext.Provider value={waitingWorkerActivated}>
			{children}
		</WaitingServiceWorkerContext.Provider>
	);
};

export const WaitingServiceWorkerConsumer = WaitingServiceWorkerContext.Consumer;
