import type { OrgUnit } from "../../../../models/orgUnit";

export const buildBreadcrumb = (
	orgUnitId: number,
	orgUnits: OrgUnit[],
	includeCurrent: boolean,
) => {
	const crumbs: OrgUnit[] = [];

	const current = orgUnits.find((ou) => ou.id === orgUnitId);
	if (current) {
		if (includeCurrent) {
			crumbs.unshift(current);
		}

		const parent = orgUnits.find((ou) => ou.id === current.parentId);
		if (parent) {
			crumbs.unshift(...buildBreadcrumb(parent.id, orgUnits, true));
		}
	}

	return crumbs;
};
