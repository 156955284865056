import { useMemo } from "react";
import { saveAs } from "file-saver";
import { useTranslation } from "react-i18next";
import { AttachmentCard } from "../../../../components/AttachmentCard";
import { isIosSafari, isIosStandalone } from "../../../../../helpers/SafariHelper";
import { ReactComponent as BinIcon } from "../../../../assets/svg/bin-1.svg";
import { ReactComponent as DownloadIcon } from "../../../../assets/svg/download.svg";
import { useDownloadFile } from "../../../../utilities/UseDownloadFile";

interface Props {
	fileDescription?: string;
	fileName?: string;
	file?: File;
	identifier: string;
	onDelete: () => void;
	readonly: boolean;
}

export const ActionAttachmentFileItem = ({
	fileDescription,
	fileName,
	file,
	identifier,
	onDelete,
	readonly,
}: Props) => {
	const { t } = useTranslation();

	const downloadLabel = useMemo(
		() =>
			isIosSafari() && isIosStandalone()
				? t("display:labelOpenFile")
				: t("display:labelDownloadFile"),
		[t],
	);

	const downloadFile = useDownloadFile(
		fileName || "downloadedAttachment",
		identifier,
		fileDescription ? [fileDescription] : undefined,
	);

	const menuItems = useMemo(() => {
		const saveFile = () => {
			if (file) {
				saveAs(file, file.name);
			}
		};

		const menuItems = [
			{
				label: downloadLabel,
				onClick: file ? saveFile : downloadFile,
				icon: <DownloadIcon />,
			},
		] as { label: string; onClick: () => void; icon: JSX.Element }[];
		if (!readonly) {
			menuItems.push({
				label: t("display:buttonDelete"),
				onClick: onDelete,
				icon: <BinIcon />,
			});
		}
		return menuItems;
	}, [downloadFile, downloadLabel, file, onDelete, readonly, t]);

	return (
		<AttachmentCard description={fileDescription} menuItems={menuItems} name={fileName ?? ""} />
	);
};
