const urls = new WeakMap<Blob, string>();

export const getBlobUrl = (blob: Blob, isSVG = false) => {
	if (urls.has(blob)) {
		return urls.get(blob) as string;
	}

	let url;
	if (isSVG) {
		url = URL.createObjectURL(
			new Blob([blob], {
				type: "image/svg+xml",
			}),
		);
	} else {
		url = URL.createObjectURL(blob);
	}
	urls.set(blob, url);
	return url;
};

export const dataURItoBlob = function (dataUri: string) {
	const mime = dataUri.split(",")[0].split(":")[1].split(";")[0];
	const binary = atob(dataUri.split(",")[1]);
	const array = [];
	for (let i = 0; i < binary.length; i++) {
		array.push(binary.charCodeAt(i));
	}
	return new Blob([new Uint8Array(array)], { type: mime });
};
