import classnames from "classnames";
import "./Card.styles.scss";

interface Props {
	children: React.ReactNode;
	onClick?: (event: React.MouseEvent) => void;
	buttonSlot?: React.ReactNode;
}

const Card = ({ children, buttonSlot, onClick }: Props) => {
	const classProps = classnames(
		"she-components-card",
		onClick && "she-components-card--clickable",
	);

	return (
		<div className={classProps}>
			<div className="she-components-card__content" onClick={onClick}>
				{children}
			</div>

			{buttonSlot && <div className="she-components-card__button-slot">{buttonSlot}</div>}
		</div>
	);
};

export { Card };
