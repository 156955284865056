import type { FieldType } from "../questionnaire";
import type { BaseField } from "./BaseField";

export enum PPEIcon {
	Apron = "An Apron Must Be Worn",
	EarProtection = "Ear protection must be worn",
	EyeProtection = "Eye protection must be worn",
	FaceShield = "Face shields must be worn",
	HandProtection = "Hand protection must be worn",
	Harness = "Harness must be worn",
	Mask = "Masks must be worn",
	Overall = "Overall/Body Covering must be worn",
	ProtectiveFootwear = "Protective footwear must be worn",
	Respirator = "Respirators must be worn",
	SafetyHelmet = "Safety helmets must be worn",
	WeldingMask = "Wear welding mask",
}

export interface PPEField extends BaseField<FieldType.PPE, number> {
	categories: PPECategory[];
}

export interface PPECategory {
	iconId: PPEIcon;
	name: string;
	id: number;
	options: PPEOption[];
}

export interface PPEOption {
	id: number;
	name: string;
	order: number;
	orgUnitIds: number[];
}

export interface PPECategoryValue {
	id: number;
	selected: boolean;
	iconId: PPEIcon;
	name: string;
	option?: PPEOption;
}
