import { useReducer } from "react";
import type { Projects } from "../../../../models/project/projects";
import type { ProjectCaptions } from "../../../../models/project/project";
import type { ProjectSortField, ProjectSortOrder } from "../../../../services/project";

export enum ProjectsLocalStatus {
	Loading,
	Error,
	Success,
	Empty,
}

export enum ProjectsLocalActionTypes {
	SetStatus,
	SetPage,
	SetProjects,
	ClearProjects,
	SetCheckedProjectId,
	SetCaptions,
	SetSearchTerm,
	SetSort,
}

export interface ProjectsLocalState {
	status: ProjectsLocalStatus;
	page: number;
	projects?: Projects;
	captions?: ProjectCaptions;
	checkedProjectId?: number;
	searchTerm?: string;
	portalCulture?: string;
	sortProperty: ProjectSortField;
	sortOrder: ProjectSortOrder;
}

export type ProjectsLocalStateAction =
	| { type: ProjectsLocalActionTypes.SetStatus; status: ProjectsLocalStatus }
	| { type: ProjectsLocalActionTypes.SetPage; page: number }
	| {
			type: ProjectsLocalActionTypes.SetSort;
			setSort: { sortOrder: any; sortProperty: any };
	  }
	| { type: ProjectsLocalActionTypes.SetProjects; projects: Projects }
	| { type: ProjectsLocalActionTypes.ClearProjects }
	| {
			type: ProjectsLocalActionTypes.SetCheckedProjectId;
			checkedProjectId: number | undefined;
	  }
	| {
			type: ProjectsLocalActionTypes.SetCaptions;
			captions: ProjectCaptions;
	  }
	| {
			type: ProjectsLocalActionTypes.SetSearchTerm;
			searchTerm: string | undefined;
	  };

export const useProjectSelectLocalState = (initialState: ProjectsLocalState) => {
	return useReducer(localStateReducer, initialState);
};

const localStateReducer = (state: ProjectsLocalState, action: ProjectsLocalStateAction) => {
	switch (action.type) {
		case ProjectsLocalActionTypes.SetPage:
			return {
				...state,
				projects: undefined,
				page: action.page,
			};
		case ProjectsLocalActionTypes.SetSort:
			return {
				...state,
				projects: undefined,
				page: 1,
				sortOrder: action.setSort.sortOrder,
				sortProperty: action.setSort.sortProperty,
			};
		case ProjectsLocalActionTypes.SetProjects:
			return {
				...state,
				projects: action.projects,
			};
		case ProjectsLocalActionTypes.SetCaptions:
			return {
				...state,
				captions: action.captions,
			};
		case ProjectsLocalActionTypes.SetCheckedProjectId:
			return {
				...state,
				checkedProjectId: action.checkedProjectId,
			};
		case ProjectsLocalActionTypes.SetStatus:
			return {
				...state,
				status: action.status,
			};
		case ProjectsLocalActionTypes.ClearProjects:
			return {
				...state,
				project: undefined,
			};
		case ProjectsLocalActionTypes.SetSearchTerm:
			return {
				...state,
				projects: undefined,
				page: 1,
				searchTerm: action.searchTerm,
			};
		default:
			return state;
	}
};
