import { useEffect, useMemo, useState } from "react";
import moment from "moment";
import TextareaAutosize from "react-textarea-autosize";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ActionAttachmentsList } from "../ActionAttachmentsList";
import { ActionField } from "../ActionField";
import { ActionReadOnlyField } from "../ActionReadOnlyField/ActionReadOnlyField.component";
import { BasicDateInput } from "../../../../components/input/BasicDateInput";
import { BasicEntitySelectInput } from "../../../../components/input/BasicEntitySelectInput";
import { BasicFileInput } from "../../../../components/input/BasicFileInput/BasicFileInput.component";
import { BasicOrgUnitSelector } from "../../../../components/input/BasicOrgUnitSelector";
import { Dropdown } from "../../../../components/input/DropDown";
import { EmptyPicklistMessage } from "../EmptyPicklistMessage/EmptyPicklistMessage";
import { getCustomerKeyLowercase } from "../../../../../helpers/AuthenticationHelpers";
import { getDateFormat } from "../../../../../helpers/DateTimeInputHelper";
import { loadOrgUnits } from "../../../../../state";
import { ReactComponent as Calendar } from "../../../../assets/svg/calendar.svg";
import { ReactComponent as UserCircle } from "../../../../assets/svg/userCircle.svg";
import { useActionLabelAndGuidance } from "../../hooks/useActionLabelAndGuidance";
import { usePreventBrowserReload } from "../../../../utilities/UsePreventBrowserReload";
import { useGetActionDictionaryQuery } from "../../hooks/useGetActionDictionaryQuery";
import { useFilteredOptions } from "../../hooks/useFilteredOptions";
import { useResetFilteredOptions } from "../../hooks/useResetFilteredOptions";
import { ActionLastComment } from "../ActionComments/ActionLastComment";
import { useActionComments } from "../ActionComments/hooks/useActionComments";
import { ActionCommentsHistory } from "../ActionComments/ActionCommentsHistory";
import {
	ActionCommentMandatory,
	type ActionAttachment,
	type ActionFull,
} from "../../../../../models/action";
import type { OrgUnit } from "../../../../../models/orgUnit";
import type { State } from "../../../../../state";
import type { Entity } from "../../../../../models/entity";
import "./EditAction.styles.scss";

interface Props {
	action: ActionFull;
	onChange: (values: Record<string, any>) => any;
	onSubmit: any;
	isCreator: boolean;
	addAttachment: (file: File, identifier?: string, description?: string) => void;
	attachmentsToDisplay: ActionAttachment[];
	removeAttachment: (identifier: string) => void;
	resetAttachments: () => void;
}

export const EditAction = ({
	action,
	onChange,
	onSubmit,
	isCreator,
	addAttachment,
	attachmentsToDisplay,
	removeAttachment,
	resetAttachments,
}: Props) => {
	const { actionId } = useParams<{ actionId: string }>();

	useEffect(() => {
		resetAttachments();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const { t } = useTranslation();
	const formattedDateCreated = useMemo(
		() => moment(action.DateCreated).format(getDateFormat()),
		[action.DateCreated],
	);
	const formattedDueDate = useMemo(
		() => moment(action.DueDate).format(getDateFormat()),
		[action.DueDate],
	);
	const dispatch = useDispatch();
	const customerKey = getCustomerKeyLowercase();
	const orgUnits = useSelector<State, OrgUnit[]>(({ orgUnit }) => orgUnit.orgUnits);

	useEffect(() => {
		dispatch(loadOrgUnits(0, customerKey));
	}, [customerKey, dispatch]);

	const noteCommentField = useActionLabelAndGuidance("NoteComment", action.Info);
	const actionTitleField = useActionLabelAndGuidance("ActionHeader", action.Info);
	const actionDetailField = useActionLabelAndGuidance("ActionDetail", action.Info);
	const dueDateField = useActionLabelAndGuidance("DueDate", action.Info);
	const priorityField = useActionLabelAndGuidance("Priority", action.Info);
	const forUserFieldInfo = useActionLabelAndGuidance("ForUser", action.Info);
	const orgUnitField = useActionLabelAndGuidance("OrgUnitName", action.Info);
	const completePercentField = useActionLabelAndGuidance("CompletePercent", action.Info);
	const categoryField = useActionLabelAndGuidance("ActionCategory", action.Info);
	const subCategoryField = useActionLabelAndGuidance("ActionSubCategory", action.Info);
	const [isNoteCommentRequired, setIsNoteCommentRequired] = useState(false);
	const isCommentMandatory = action.IsCommentMandatory;

	const { control, formState, handleSubmit, register, reset, watch, setValue } = useForm({
		mode: "onBlur",
		criteriaMode: "all",
	});

	const { actionDictionariesQuery, categoryOptions, priorityOptions, subCategoryOptions } =
		useGetActionDictionaryQuery();

	const selectedOrgUnit = watch("OrgUnitId") || action.OrgUnitId;

	const selectedCategory = parseInt(watch("ActionCategory")) || action.ActionCategory;
	const filteredCategoryOptions = useFilteredOptions(categoryOptions ?? [], selectedOrgUnit);
	useResetFilteredOptions(
		filteredCategoryOptions,
		selectedCategory,
		"ActionCategory",
		setValue,
		actionDictionariesQuery.isSuccess,
	);

	const selectedSubCategory = parseInt(watch("ActionSubCategory"));
	const filteredSubCategoryOptions = useFilteredOptions(
		subCategoryOptions ?? [],
		selectedOrgUnit,
		selectedCategory,
	);
	const { comments } = useActionComments(Number(actionId));

	useResetFilteredOptions(
		filteredSubCategoryOptions,
		selectedSubCategory,
		"ActionSubCategory",
		setValue,
		actionDictionariesQuery.isSuccess,
	);

	usePreventBrowserReload(formState.isDirty);

	const watchCompletePercent = watch("CompletePercent");

	if (
		watchCompletePercent &&
		parseInt(watchCompletePercent) !== parseFloat(watchCompletePercent)
	) {
		setValue("CompletePercent", Math.round(parseInt(watchCompletePercent)));
	}

	const handleChange = () => {
		if (onChange) {
			onChange(control.getValues());
		}
	};

	useEffect(() => {
		reset();
	}, [action, reset]);

	const handleOnAddAttachments = (files: File[]) => {
		files.forEach((file) => addAttachment(file));
	};

	const formattedForUser = useMemo(
		() => ({
			id: action.ForUser.Id,
			info: [
				{ detail: action.ForUser.FullName, title: "Full Name", propertyName: "FullName" },
				{ detail: action.ForUser.Email, title: "Email", propertyName: "Email" },
				{ detail: action.ForUser.OrgUnit, title: "Company/Dept", propertyName: "orgUnit" },
			],
		}),
		[action.ForUser],
	);

	if (actionDictionariesQuery.isLoading) {
		return <div>{t("display:labelLoading")}</div>;
	}

	return (
		<div className="she-action-detail">
			<form
				id="edit-action-form"
				noValidate
				onChange={handleChange}
				onSubmit={handleSubmit(onSubmit)}
			>
				{isCreator && !action.ModuleId ? (
					<ActionField
						fieldId="OrgUnitId"
						guidance={orgUnitField.guidance}
						guidanceIsPopup={orgUnitField.guidanceIsPopup}
						required={orgUnitField.isMandatory}
						showGuidance={orgUnitField.showGuidance}
						title={orgUnitField.label}
						validationErrors={
							formState.errors.OrgUnitId &&
							Object.values(formState.errors.OrgUnitId.types)
						}
					>
						<Controller
							control={control}
							defaultValue={action.OrgUnitId}
							name="OrgUnitId"
							render={({ ...props }) => {
								return (
									<BasicOrgUnitSelector
										caption={orgUnitField.label}
										fieldGuid="OrgUnitId"
										isValid
										orgUnits={orgUnits}
										{...{ ...props, ref: null }}
									/>
								);
							}}
							rules={{
								required:
									orgUnitField.isMandatory &&
									`${t("validation:passwordValidation.required")}`,
							}}
						/>
					</ActionField>
				) : (
					<ActionReadOnlyField info={action.Info} propertyName="OrgUnitName">
						{action.OrgUnitName}
					</ActionReadOnlyField>
				)}

				{action.ModuleId ? (
					<ActionReadOnlyField info={action.Info} propertyName="ModuleDescrip">
						{action.ModuleDescrip}
					</ActionReadOnlyField>
				) : (
					""
				)}
				<ActionReadOnlyField info={action.Info} propertyName="RecordReference">
					{action.RecordReference}
				</ActionReadOnlyField>
				{isCreator ? (
					<ActionField
						fieldId="ActionHeader"
						guidance={actionTitleField.guidance}
						guidanceIsPopup={actionTitleField.guidanceIsPopup}
						required={actionTitleField.isMandatory}
						showGuidance={actionTitleField.showGuidance}
						title={actionTitleField.label}
						validationErrors={
							formState.errors.ActionHeader &&
							Object.values(formState.errors.ActionHeader.types)
						}
					>
						<input
							className="she-components-text-input"
							defaultValue={action.ActionHeader}
							name="ActionHeader"
							ref={register({
								maxLength: 255,
								validate: (value: any) => {
									if (!value) {
										return (
											actionTitleField.isMandatory &&
											`${t("validation:mandatoryFieldMessage")}`
										);
									}
								},
							})}
						/>
					</ActionField>
				) : (
					<ActionReadOnlyField info={action.Info} propertyName="ActionHeader">
						{action.ActionHeader}
					</ActionReadOnlyField>
				)}
				{isCreator ? (
					<ActionField
						fieldId="ActionDetail"
						guidance={actionDetailField.guidance}
						guidanceIsPopup={actionDetailField.guidanceIsPopup}
						required={actionDetailField.isMandatory}
						showGuidance={actionDetailField.showGuidance}
						title={actionDetailField.label}
						validationErrors={
							formState.errors.CompleteDate &&
							Object.values(formState.errors.CompleteDate.types)
						}
					>
						<Controller
							control={control}
							defaultValue={action.ActionDetail}
							maxLength={100000}
							name="ActionDetail"
							render={({ ref, value, ...props }) => (
								<TextareaAutosize
									className="she-components-text-input"
									ref={ref}
									required={actionDetailField.isMandatory}
									value={value || ""}
									{...props}
								/>
							)}
							required={
								actionDetailField.isMandatory &&
								`${t("validation:mandatoryFieldMessage")}`
							}
						/>
					</ActionField>
				) : (
					<ActionReadOnlyField info={action.Info} propertyName="ActionDetail">
						{action.ActionDetail}
					</ActionReadOnlyField>
				)}

				{isCreator ? (
					<ActionField
						fieldId="ActionCategory"
						guidance={categoryField.guidance}
						guidanceIsPopup={categoryField.guidanceIsPopup}
						required={categoryField.isMandatory}
						showGuidance={categoryField.showGuidance}
						title={categoryField.label}
						validationErrors={
							formState.errors.ActionCategory &&
							Object.values(formState.errors.ActionCategory.types)
						}
					>
						<Controller
							control={control}
							defaultValue={action.ActionCategory}
							name="ActionCategory"
							render={({ ...props }) => (
								<EmptyPicklistMessage
									isEmpty={!filteredCategoryOptions.length}
									isMandatory={categoryField.isMandatory}
								>
									<Dropdown
										data={filteredCategoryOptions}
										guid="category"
										isMandatory={false}
										isTopOptionTitle
										{...{ ...props, ref: null }}
									/>
								</EmptyPicklistMessage>
							)}
							rules={{
								validate: (value: string) => {
									if (categoryField.isMandatory && (!value || value === "-1")) {
										return `${t("validation:mandatoryFieldMessage")}`;
									}
									return true;
								},
							}}
						/>
					</ActionField>
				) : (
					<ActionReadOnlyField info={action.Info} propertyName="ActionCategory">
						{action.ActionCategoryText}
					</ActionReadOnlyField>
				)}
				{subCategoryField.visible && isCreator ? (
					<ActionField
						fieldId="ActionSubCategory"
						guidance={subCategoryField.guidance}
						guidanceIsPopup={subCategoryField.guidanceIsPopup}
						required={subCategoryField.isMandatory}
						showGuidance={subCategoryField.showGuidance}
						title={subCategoryField.label}
						validationErrors={
							formState.errors.ActionSubCategory &&
							Object.values(formState.errors.ActionSubCategory.types)
						}
					>
						<Controller
							control={control}
							defaultValue={action.ActionSubCategory}
							name="ActionSubCategory"
							render={({ ...props }) => (
								<EmptyPicklistMessage
									isEmpty={!filteredSubCategoryOptions.length}
									isMandatory={subCategoryField.isMandatory}
								>
									<Dropdown
										data={filteredSubCategoryOptions}
										guid="subCategory"
										isMandatory={false}
										isTopOptionTitle
										{...{ ...props, ref: null }}
									/>
								</EmptyPicklistMessage>
							)}
							rules={{
								validate: (value: string) => {
									if (
										subCategoryField.isMandatory &&
										(!value || value === "-1")
									) {
										return `${t("validation:mandatoryFieldMessage")}`;
									}
									return true;
								},
							}}
						/>
					</ActionField>
				) : null}
				{subCategoryField.visible && !isCreator ? (
					<ActionReadOnlyField info={action.Info} propertyName="ActionSubCategory">
						{action.ActionCategoryText}
					</ActionReadOnlyField>
				) : null}
				<ActionReadOnlyField info={action.Info} propertyName="RaisingUser">
					<div className="she-icon she-action-detail__icon">
						<UserCircle />
					</div>
					{action.RaisingUser.FullName}
				</ActionReadOnlyField>

				{isCreator ? (
					<ActionField
						fieldId="ForUserIds"
						guidanceIsPopup={forUserFieldInfo.guidanceIsPopup}
						required
						showGuidance={forUserFieldInfo.showGuidance}
						title={forUserFieldInfo.label}
						validationErrors={
							formState.errors.ForUserIds &&
							Object.values(formState.errors.ForUserIds.types)
						}
					>
						<Controller
							control={control}
							defaultValue={formattedForUser ? formattedForUser : null}
							name="ForUserIds"
							render={({ value, ...props }) => (
								<BasicEntitySelectInput
									caption={forUserFieldInfo.label}
									initialSearch={
										action.ForUser.OrgUnit && `"${action.ForUser.OrgUnit}"`
									}
									propertyName="ForUser"
									value={value}
									{...{ ...props, ref: null }}
								/>
							)}
							rules={{
								validate: (value?: Entity) => {
									if (!value) {
										return `${t("validation:mandatoryFieldMessage")}`;
									}
									return true;
								},
							}}
						/>
					</ActionField>
				) : (
					<ActionReadOnlyField info={action.Info} propertyName="ForUser">
						<div className="she-icon she-action-detail__icon">
							<UserCircle />
						</div>

						{action.ForUser.FullName}
					</ActionReadOnlyField>
				)}

				<ActionReadOnlyField info={action.Info} propertyName="DateCreated">
					<div className="she-icon she-action-detail__icon">
						<Calendar />
					</div>
					{formattedDateCreated}
				</ActionReadOnlyField>
				{isCreator ? (
					<ActionField
						fieldId="DueDate"
						guidance={dueDateField.guidance}
						guidanceIsPopup={dueDateField.guidanceIsPopup}
						required={dueDateField.isMandatory}
						showGuidance={dueDateField.showGuidance}
						title={dueDateField.label}
						validationErrors={
							formState.errors.DueDate &&
							Object.values(formState.errors.DueDate.types)
						}
					>
						<Controller
							control={control}
							defaultValue={formattedDueDate}
							name="DueDate"
							render={(props) => (
								<BasicDateInput
									fieldId="DueDate"
									{...props}
									dateFormat={getDateFormat()}
									ref={null}
								/>
							)}
							rules={{
								validate: (value: string) => {
									if (!dueDateField.isMandatory && value === "//") {
										return true;
									} else if (value === "//") {
										return `${t("validation:mandatoryFieldMessage")}`;
									}

									const selectedDate = moment(value, getDateFormat(), true);
									const dateIsValid = selectedDate.isValid();

									if (
										dateIsValid &&
										!(
											selectedDate.isSameOrBefore("2200-12-31T00:00:00") &&
											selectedDate.isSameOrAfter("1900-01-01T00:00:00")
										)
									) {
										return `${t("validation:invalidDateRange")}`;
									}

									return dateIsValid || `${t("validation:invalidDateMessage")}`;
								},
							}}
						/>
					</ActionField>
				) : (
					<ActionReadOnlyField info={action.Info} propertyName="DueDate">
						<div className="she-icon she-action-detail__icon">
							<Calendar />
						</div>
						{formattedDueDate}
					</ActionReadOnlyField>
				)}
				{isCreator ? (
					<ActionField
						fieldId="Priority"
						guidance={priorityField.guidance}
						guidanceIsPopup={priorityField.guidanceIsPopup}
						required={priorityField.isMandatory}
						showGuidance={priorityField.showGuidance}
						title={priorityField.label}
						validationErrors={
							formState.errors.Priority &&
							Object.values(formState.errors.Priority.types)
						}
					>
						<Controller
							control={control}
							defaultValue={action.Priority}
							name="Priority"
							render={({ ...props }) => (
								<Dropdown
									data={priorityOptions ?? []}
									guid="Priority"
									isMandatory
									isTopOptionTitle={false}
									{...{ ...props, ref: null }}
								/>
							)}
						/>
					</ActionField>
				) : (
					<ActionReadOnlyField info={action.Info} propertyName="Priority">
						{action.PriorityText}
					</ActionReadOnlyField>
				)}
				<ActionReadOnlyField info={action.Info} propertyName="CurrentStatus">
					{action.CurrentStatus}
				</ActionReadOnlyField>
				<ActionField
					fieldId="CompletePercent"
					guidance={completePercentField.guidance}
					guidanceIsPopup={completePercentField.guidanceIsPopup}
					required={completePercentField.isMandatory}
					showGuidance={completePercentField.showGuidance}
					title={completePercentField.label}
					validationErrors={
						formState.errors.CompletePercent &&
						Object.values(formState.errors.CompletePercent.types)
					}
				>
					<input
						className="she-components-text-input"
						defaultValue={action.CompletePercent.toString()}
						max="100"
						min="0"
						name="CompletePercent"
						ref={register({
							required:
								completePercentField.isMandatory &&
								`${t("validation:mandatoryFieldMessage")}`,
							validate: (value: any) => {
								setIsNoteCommentRequired(
									value > 99 &&
										isCommentMandatory === ActionCommentMandatory.OnCompletion,
								);
								if (value < 0 || value > 100) {
									return `${t("validation:percentageMessage")}`;
								}
							},
						})}
						type="number"
					/>
				</ActionField>

				{comments.length ? (
					<ActionReadOnlyField
						direction="column"
						info={action.Info}
						propertyName="LastComment"
					>
						{action.LatestComment ? (
							<ActionLastComment {...action.LatestComment} />
						) : null}

						<ActionCommentsHistory comments={comments} />
					</ActionReadOnlyField>
				) : null}

				<ActionField
					fieldId="NoteComment"
					guidance={noteCommentField.guidance}
					guidanceIsPopup={noteCommentField.guidanceIsPopup}
					hint={`${t("hintTextFieldLength", { minLength: 0, maxLength: 5000 })}`}
					required={
						isNoteCommentRequired ||
						isCommentMandatory === ActionCommentMandatory.Always
					}
					showGuidance={noteCommentField.showGuidance}
					title={noteCommentField.label}
					validationErrors={
						formState.errors.NoteComment &&
						Object.values(formState.errors.NoteComment.types)
					}
				>
					<Controller
						control={control}
						defaultValue={action.NoteComment}
						maxLength={5000}
						name="NoteComment"
						render={({ ref, value, ...props }) => (
							<TextareaAutosize
								className="she-components-text-input"
								maxLength={5000}
								ref={ref}
								required={
									isNoteCommentRequired ||
									isCommentMandatory === ActionCommentMandatory.Always
								}
								value={value || ""}
								{...props}
							/>
						)}
						required={
							isNoteCommentRequired ||
							isCommentMandatory === ActionCommentMandatory.Always
								? `${t("validation:mandatoryFieldMessage")}`
								: null
						}
						rules={{
							validate: (value: string) => {
								if (
									isNoteCommentRequired ||
									isCommentMandatory === ActionCommentMandatory.Always
								) {
									if (!value || value.trim().length === 0) {
										return `${t("validation:mandatoryFieldMessage")}`;
									}
								}
							},
						}}
					/>
				</ActionField>
			</form>
			<ActionAttachmentsList
				attachments={attachmentsToDisplay}
				readonly={false}
				removeAttachment={removeAttachment}
			/>
			<BasicFileInput
				name={`action-${action.ActionId}`}
				onAddAttachments={handleOnAddAttachments}
			/>
		</div>
	);
};
