import TextareaAutosize from "react-textarea-autosize";
import { useTranslation } from "react-i18next";
import { FormModal } from "../../../../../components/modals";
import "./NoteDialog.styles.scss";

interface Props {
	saveNote: () => void;
	cancelNote: () => void;
	onChange: (note: string) => void;
	note: string;
	fieldGuid: string;
	isOpen: boolean;
	isEditMode: boolean;
	validationErrors?: string[];
	noteLength?: number;
}

export const NoteDialogPresentation = ({
	cancelNote,
	fieldGuid,
	isEditMode,
	isOpen,
	note,
	onChange,
	saveNote,
	noteLength,
	validationErrors,
}: Props) => {
	const { t } = useTranslation();

	return (
		<FormModal
			onCancel={cancelNote}
			onOk={saveNote}
			show={isOpen}
			title={isEditMode ? t("display:labelEditNote") : t("display:labelAddNote")}
		>
			<div
				className={`she-components-control ${
					validationErrors && validationErrors.length > 0
						? "she-components-validation-border-left"
						: ""
				}`}
			>
				<label id={`${fieldGuid}-modal-label`}>
					{t("display:labelNote")}
					<abbr className="she-components-control-required-indicator" title="required">
						*
					</abbr>
				</label>
				{validationErrors &&
					validationErrors.length > 0 &&
					validationErrors.map((e: string, i: number) => (
						<div className="she-components-validation-message" key={i}>
							{e}
						</div>
					))}
				<TextareaAutosize
					aria-invalid={validationErrors && validationErrors.length > 0}
					aria-labelledby={`${fieldGuid}-modal-label`}
					aria-required
					className="she-components-text-input"
					maxLength={noteLength}
					onChange={(e) => {
						onChange(e.target.value);
					}}
					value={note ? note : ""}
				/>
			</div>
		</FormModal>
	);
};
