import { DropdownPresentation } from "./Dropdown.presentation";

interface Props {
	defaultValue?: string;
	name: string;
	value?: string;
	data: any[];
	guid: string;
	isMandatory: boolean;
	isTopOptionTitle: boolean;
	topOptionTitle?: string;
	onChange: (value: string, guid: string) => void;
	onBlur?: (event: React.FocusEvent<HTMLSelectElement>) => void;
	ariaLabelledby?: string;
}

export const DropdownContainer = ({
	data,
	guid,
	isMandatory,
	isTopOptionTitle,
	name,
	onChange,
	defaultValue,
	onBlur,
	topOptionTitle,
	value,
	ariaLabelledby,
}: Props) => {
	return (
		<DropdownPresentation
			ariaLabelledby={ariaLabelledby}
			data={data}
			defaultValue={defaultValue}
			guid={guid}
			isMandatory={isMandatory}
			isTopOptionTitle={isTopOptionTitle}
			name={name}
			onBlur={onBlur}
			onChange={(event) => onChange(event.target.value, guid)}
			topOptionTitle={topOptionTitle}
			value={value || ""}
		/>
	);
};
