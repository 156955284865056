import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Hide } from "../../../assets/svg/subtract.svg";
import { ReactComponent as Show } from "../../../assets/svg/add.svg";
import "./CollapsibleLabelList.styles.scss";

export interface CollapsibleLabelListData {
	key: string;
	value: string;
	strong: boolean;
}

interface Props {
	listItems: CollapsibleLabelListData[];
}

export const CollapsibleLabelList = ({ listItems }: Props) => {
	const [showLabels, setShowLabels] = useState(false);

	const { t } = useTranslation();

	const toggleLabels = (e: React.MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();
		setShowLabels(!showLabels);
	};

	return (
		<>
			<ul className="she-callapsible-list">
				{listItems.map((listItem) => (
					<li key={listItem.key}>
						{showLabels && (
							<div className="she-callapsible-list__key">{listItem.key}</div>
						)}
						<div className="she-callapsible-list__value">
							{listItem.strong ? (
								<strong className="she-callapsible-list__accent">
									{listItem.value}
								</strong>
							) : (
								listItem.value
							)}
						</div>
					</li>
				))}
			</ul>
			<button
				aria-label={t("display:labelExpandCollapse")}
				className="she-icon she-icon-gray she-callapsible-list__toggle"
				onClick={toggleLabels}
				type="button"
			>
				{showLabels ? <Hide /> : <Show />}
			</button>
		</>
	);
};
