import TextareaAutosize from "react-textarea-autosize";
import { t } from "i18next";
import { NumberInputContainer } from "../NumberInput/NumberInput.container";
import { AnimationWrapper } from "../../animation/AnimationWrapper";
import { AnimationSlideFade } from "../../animation/AnimationSlideFade";
import type { BaseNumberField } from "../../../../models/fields/NumberField";
import type { NumberFieldValidator } from "../../../../validators/numberFieldValidator";

interface Props {
	guid: string;
	safeField: BaseNumberField;
	safeComment?: string;
	safeCommentRows?: number;
	unSafeField: BaseNumberField;
	unSafeComment?: string;
	unSafeCommentRows?: number;
	safeNumberValidator: NumberFieldValidator;
	unSafeNumberValidator: NumberFieldValidator;
	isNotApplicable: boolean;
	isMandatory: boolean;
	updateSafe: (value: any) => void;
	updateSafeComment: (value: string) => void;
	updateUnSafe: (value: any) => void;
	updateUnSafeComment: (value: string) => void;
	updateSafeUnSafeValidationStatus: (value: any) => void;
	saveSafeComment: () => void;
	saveUnSafeComment: () => void;
}

export const BehaviouralInputPresentation = ({
	guid,
	safeField,
	safeComment,
	safeCommentRows,
	unSafeField,
	unSafeComment,
	unSafeCommentRows,
	safeNumberValidator,
	unSafeNumberValidator,
	isNotApplicable,
	isMandatory,
	updateSafe,
	updateSafeComment,
	updateUnSafe,
	updateUnSafeComment,
	updateSafeUnSafeValidationStatus,
	saveSafeComment,
	saveUnSafeComment,
}: Props) => {
	return (
		<AnimationWrapper>
			{!isNotApplicable && (
				<AnimationSlideFade key={guid} measure>
					<div id={`${guid}-group`} role="group">
						<label
							className="she-components-control-subtitle"
							htmlFor={safeField.guid}
							id={`${safeField.guid}-label`}
						>
							{t("display:labelSafe")}
							<abbr
								className="she-components-control-required-indicator"
								title={
									isMandatory ? t("global:required") : t("display:labelOptional")
								}
							>
								{isMandatory ? "*" : ""}
							</abbr>
						</label>
						<NumberInputContainer
							numberField={safeField}
							updateField={updateSafe}
							updateValidationStatus={updateSafeUnSafeValidationStatus}
							validator={safeNumberValidator}
						/>

						<label
							className="she-components-control-subtitle"
							htmlFor={`${guid}-safe-textarea`}
							id={`${guid}-safe-label`}
						>
							{t("display:labelSafeComment")}
						</label>
						<TextareaAutosize
							aria-describedby={`${guid}-safe-label`}
							className="she-components-text-input"
							disabled={isNotApplicable}
							id={`${guid}-safe-textarea`}
							onBlur={saveSafeComment}
							onChange={(e) => updateSafeComment(e.target.value)}
							rows={safeCommentRows}
							value={safeComment}
						/>

						<label
							className="she-components-control-subtitle"
							htmlFor={unSafeField.guid}
							id={`${unSafeField.guid}-label`}
						>
							{t("display:labelUnSafe")}
							<abbr
								className="she-components-control-required-indicator"
								title={
									isMandatory ? t("global:required") : t("display:labelOptional")
								}
							>
								{isMandatory ? "*" : ""}
							</abbr>
						</label>
						<NumberInputContainer
							numberField={unSafeField}
							updateField={updateUnSafe}
							updateValidationStatus={updateSafeUnSafeValidationStatus}
							validator={unSafeNumberValidator}
						/>

						<label
							className="she-components-control-subtitle"
							htmlFor={`${guid}-unSafe-textarea`}
							id={`${guid}-unSafe-label`}
						>
							{t("display:labelUnSafeComment")}
						</label>
						<TextareaAutosize
							aria-describedby={`${guid}-unSafe-label`}
							className="she-components-text-input"
							disabled={isNotApplicable}
							id={`${guid}-unSafe-textarea`}
							onBlur={saveUnSafeComment}
							onChange={(e) => updateUnSafeComment(e.target.value)}
							rows={unSafeCommentRows}
							value={unSafeComment}
						/>
					</div>
				</AnimationSlideFade>
			)}
		</AnimationWrapper>
	);
};
