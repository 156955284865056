export enum PortalActionTypes {
	Loaded = "PORTAL_Loaded",
	QuestionnaireStubsSorted = "PORTAL_QuestionnaireStubsSorted",
	SaveInitialSetup = "PORTAL_SaveInitialSetup",
	UpdateInitialSetupFlag = "PORTAL_UpdateInitialSetupFlag",
	RemoveQuestionnaire = "PORTAL_RemoveQuestionnaire",
	DownloadQuestionnaireToDevice = "PORTAL_DownloadQuestionnaire",
	UpdateQuestionnaireTemplateStatus = "PORTAL_UpdateQuestionnaireTemplateStatus",
	UpdateTemplateSyncFlag = "PORTAL_UpdateTemplateSyncFlag",
	SwitchView = "PORTAL_SwitchView",
}
