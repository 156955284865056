import { t } from "i18next";
import type { MatrixField } from "../../models/fields/MatrixField";

export class MatrixFieldValidator {
	matrixField: MatrixField;
	messages: string[];

	constructor(matrixField: MatrixField) {
		this.matrixField = matrixField;
		this.messages = [];
	}

	isFieldValid = (value?: number): boolean => {
		let result = true;
		this.messages = [];

		if (this.matrixField.isMandatory) {
			if (value == null) {
				this.messages.push(t("validation:mandatoryFieldMessage"));
				result = false;
			}
		}

		return result;
	};
}
