import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "../../../../components/modals/Modal";
import { ModalFooter } from "../../../../components/modals/ModalFooter";
import { PortalQuestionnaireThumbnail } from "../PortalQuestionnaireThumbnail";
import { getBlobUrl } from "../../../../../helpers/BlobHelper";
import type { QuestionnaireStub } from "../../../../../models/portal";
import type { PortalTranslationLanguage } from "../../../../../types/i18next";
import "./StartQuestionnaireModal.styles.scss";

interface Props {
	show: boolean;

	okText?: string;
	cancelText?: string;

	onOk: () => void;
	onCancel: () => void;

	questionnaireStub: QuestionnaireStub;
	displayLanguage: boolean;
}

export const StartQuestionnaireModal = ({
	displayLanguage,
	onCancel,
	onOk,
	questionnaireStub,
	show,
	cancelText,
	okText,
}: Props) => {
	const { t } = useTranslation();

	const startText = useMemo(() => okText ?? t("display:buttonStartForm"), [okText, t]);
	const cancelTextMemo = useMemo(() => cancelText ?? t("global:cancel"), [cancelText, t]);

	return (
		<Modal fullHeight={false} onClose={onCancel} show={show}>
			<div className="she-components-modal-body-padded she-components-modal-body-form she-start-questionnaire-modal">
				<PortalQuestionnaireThumbnail
					imageUrl={questionnaireStub.image && getBlobUrl(questionnaireStub.image)}
					module={questionnaireStub.module}
				/>
				<div className="she-start-questionnaire-modal-body">
					<p className="she-start-questionnaire-modal-body-title">
						{questionnaireStub.title}
					</p>
					{questionnaireStub.description !== "" && (
						<p
							className="she-start-questionnaire-modal-body-description"
							dangerouslySetInnerHTML={{
								__html: questionnaireStub.description,
							}}
						/>
					)}
					{displayLanguage && (
						<p className="she-start-questionnaire-modal-body-data">
							{t(
								`display:language${
									questionnaireStub.language.replace(
										"-",
										"",
									) as PortalTranslationLanguage
								}`,
							)}
						</p>
					)}
				</div>
			</div>
			<ModalFooter
				buttons={[
					{ text: cancelTextMemo, primary: false, onClick: onCancel },
					{ text: startText, primary: true, onClick: onOk },
				]}
			/>
		</Modal>
	);
};
