import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { PageLayout } from "../../layouts/PageLayout";
import { useGetPolicies } from "../../utilities/UseGetPolicies";
import { PolicyAttachmentsList } from "./components/PolicyAttachments";
import { PoliciesModuleListItem } from "./components/PoliciesModuleListItem/PoliciesModuleListItem";
import type { State } from "../../../state";
import type { Portal } from "../../../models/portal";

export const Documents = () => {
	const { customerKey, portalKey } = useParams<{ customerKey: string; portalKey: string }>();
	const portal = useSelector<State, Portal | undefined>((s) =>
		s.portal.portals.find(
			(p) => p.key === portalKey.toLowerCase() && p.customerKey === customerKey.toLowerCase(),
		),
	);

	const { data } = useGetPolicies(portalKey);

	return (
		<PageLayout portal={portal} title={portal ? portal.title : ""}>
			{portal && (
				<>
					<PoliciesModuleListItem portal={portal} />
					<PolicyAttachmentsList policies={data} />
				</>
			)}
		</PageLayout>
	);
};
