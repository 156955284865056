import { QuestionHeader } from "../../../../components/generic/QuestionHeader";

interface Props {
	title: string;
	showGuidance: boolean;
	required: boolean;
	validationErrors?: string[];
	guidance?: string;
	mediaGuidance?: string;
	guidanceIsPopup: boolean;
	hint?: string;
	children?: React.ReactNode;
	fieldId: string;
}

export const ActionField = ({ validationErrors, hint, children, fieldId, ...rest }: Props) => {
	return (
		<div
			className={`she-components-control ${
				validationErrors && validationErrors.length > 0
					? "she-components-validation-border-left"
					: ""
			}`}
		>
			<QuestionHeader guid={fieldId} validationErrors={validationErrors} {...rest} />
			{hint && <div className="she-control-hint">{hint}</div>}
			{children}
		</div>
	);
};
