import { FormLayout } from "../../../../layouts/FormLayout";
import { QuestionnaireHeader } from "../Header";
import "./LoadingMock.styles.scss";

export const LoadingMock = () => {
	return (
		<FormLayout header={<QuestionnaireHeader backButtonPressed={() => {}} title="" />}>
			<div className="she-mock-sidebar" />
		</FormLayout>
	);
};
