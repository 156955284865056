import { useTranslation } from "react-i18next";
import "./SkipToMain.styles.scss";

interface Props {
	mainContentId: string;
}

export const SkipToMain = ({ mainContentId }: Props) => {
	const { t } = useTranslation();
	return (
		<div className="skip-to-main">
			<a href={`#${mainContentId}`}>{t("display:labelNavigateToMainContent")}</a>
		</div>
	);
};
