import classnames from "classnames";
import { useTranslation } from "react-i18next";
import type { RefObject } from "react";
import "./ProgressBar.styles.scss";

export const PROGRESS_BAR_TEST_ID = "progressBar";
export const PROGRESS_BAR_PERCENTAGE_TEST_ID = "progressBarPercentage";

interface Props {
	ref?: RefObject<HTMLDivElement>;
	className?: string;
	children: number;
}

export const ProgressBar = ({ children, className, ref, ...rest }: Props) => {
	const { t } = useTranslation();

	return (
		<div
			className={classnames("she-progressBar", className)}
			data-testid={PROGRESS_BAR_TEST_ID}
			ref={ref}
			{...rest}
		>
			<div
				className="she-progressBar__completed"
				data-testid={PROGRESS_BAR_PERCENTAGE_TEST_ID}
				style={{ transform: `translateX(${-100 + +children}%)` }}
			/>
			<span
				className={`she-progressBar__completed-label${
					children && Number(children) > 60
						? " she-progressBar__completed-label--invert-color"
						: ""
				}`}
			>
				{children ? `${children}%` : t("display:myTasks.labelStatusNotStarted")}
			</span>
		</div>
	);
};
