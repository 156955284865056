import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { CachedTextBox, IconifiedTextBox, TextBox } from "../TextBox";
import { ReactComponent as CloseIcon } from "../../../assets/svg/remove.svg";
import { QRScanner } from "../../QRScanner/QRScanner";
import { ReactComponent as SearchIcon } from "../../../assets/svg/search.svg";
import "./SearchBox.styles.scss";

interface Props {
	reference: React.RefObject<HTMLInputElement>;
	searchTerm: string;
	onSearchTermChange: (searchTerm: string) => void;
	onSearchBegan: () => void;
	onSearchEnd: () => void;
	qrScannable?: boolean;
	instant?: boolean;
}

export const SearchBox = ({
	onSearchBegan,
	onSearchEnd,
	onSearchTermChange,
	reference,
	searchTerm,
	instant,
	qrScannable,
}: Props) => {
	const { t } = useTranslation();
	const searchChanged = useCallback(
		(value: string) => {
			if (searchTerm.length === 0 && value.length > 0) {
				onSearchBegan();
			}

			if (searchTerm.length > 0 && value.length === 0) {
				onSearchEnd();
			}
			onSearchTermChange(value);
		},
		[onSearchBegan, onSearchEnd, onSearchTermChange, searchTerm],
	);

	return (
		<div className="searchbox_container">
			<IconifiedTextBox
				component={instant ? TextBox : CachedTextBox}
				icon={searchTerm.length > 0 ? CloseIcon : SearchIcon}
				iconDisabled={searchTerm.length === 0}
				iconLabel={searchTerm.length > 0 ? t("global:clear") : t("display:buttonSearch")}
				label={t("display:buttonSearch")}
				onChange={searchChanged}
				onIconClick={() => searchChanged("")}
				reference={reference}
				value={searchTerm}
			/>
			{qrScannable && <QRScanner onResult={searchChanged} />}
		</div>
	);
};
