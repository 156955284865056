import { t } from "i18next";
import { v4 as uuid } from "uuid";
import {
	EntitySelectProperty,
	EntitySelectPropertyWithDefault,
	FieldType,
	LocalFieldIds,
	LocalGroupIds,
	LocalSectionId,
	NotApplicableComments,
	SelectFieldType,
} from "../../models/questionnaire";
import { PPEIcon } from "../../models/fields/PPEField";
import { splitDateToObject, splitTimeToObject } from "../../helpers/DateTimeInputHelper";
import { AUTHENTICATED_ONLY_PROPERTIES } from "../../models/fields/EntitySelectField";
import type { PPECategory, PPEField, PPEOption } from "../../models/fields/PPEField";
import type { CommonFieldProperties } from "../../models/fields/CommonFieldProps";
import type { DateField } from "../../models/fields/DateField";
import type { Field } from "../../models/fields/Field";
import type { IndirectField } from "../../models/fields/IndirectField";

/**
 * Returns Fields that can be used in application. If field is not direct input, replacement InDirectField
 * is returned.
 */

export const mapMainOrIndirectField = async (
	field: any,
	questionnaireId: string,
	groupId: string,
	sectionId: number,
): Promise<Field> => {
	const mainField = mapMainField(field, questionnaireId, groupId, sectionId);
	if (mainField.isDirectInput === false) {
		const commonProperties = CreateCommonProperties(
			field,
			questionnaireId,
			groupId,
			sectionId,
			false,
			false,
		);
		const inDirectField: IndirectField = {
			...commonProperties,
			type: FieldType.Indirect,
			maxLength: 500,
			originalField: mainField,
		};

		switch (field.FieldType) {
			case 1: // Date
			case 2: // Single Text
			case 3: // Multi Text
			case 4: // Number
			case 10: // Time
			case 11: // Decimal
			case 15: // Geo
				inDirectField.value = field.DefaultValue;
				break;
			case 5: // Boolean
				if (mainField.value === true) {
					inDirectField.value = t("global:yes");
					break;
				}
				if (mainField.value === false) {
					inDirectField.value = t("global:no");
				}
				break;
			case 9: // Enum
				inDirectField.value =
					field.DefaultValue &&
					field.EnumItems.find((i: any) => i.EnumString === field.DefaultValue) &&
					field.EnumItems.find((i: any) => i.EnumString === field.DefaultValue).EnumText;
				break;
			case 18: // Optional Select
				inDirectField.relatedFields = mainField.relatedFields;
				break;
		}
		return inDirectField;
	}
	return mainField;
};

export const mapMainField = (
	field: any,
	questionnaireId: string,
	groupId: string,
	sectionId: number,
): Field => {
	const commonProperties = CreateCommonProperties(
		field,
		questionnaireId,
		groupId,
		sectionId,
		false,
		false,
	);

	switch (field.FieldType) {
		case 1: // Date
			return {
				...commonProperties,
				type: FieldType.Date,
				defaultValue: field.DefaultValue && splitDateToObject(field.DefaultValue),
				value: field.DefaultValue && splitDateToObject(field.DefaultValue),
				isFutureAllowed:
					field.Attributes &&
					field.Attributes.FutureDate &&
					field.Attributes.FutureDate.IsAllowed,
			};
		case 2: // Single Text
			return {
				...commonProperties,
				type: FieldType.Text,
				defaultValue: field.DefaultValue,
				value: field.DefaultValue,
				maxLength: field.MaxLength,
				rows: 1,
			};
		case 3: // Multi Text
			if (field.DictionaryKey !== null && field.DictionaryKey !== undefined) {
				return {
					...commonProperties,
					type: FieldType.SelectableText,
					defaultValue: field.DefaultValue,
					value: field.DefaultValue,
					maxLength: field.MaxLength,
					rows: 5,
					dictionaryKey: field.DictionaryKey,
					parentDictionaryKey: field.ParentDictionaryKey,
					childDictionaryKeys: field.ChildDictionaryKeys,
				};
			}
			return {
				...commonProperties,
				type: FieldType.Text,
				defaultValue: field.DefaultValue,
				value: field.DefaultValue,
				maxLength: field.MaxLength,
				rows: 5,
			};

		case 4: // Number
			return {
				...commonProperties,
				type: FieldType.Number,
				defaultValue: field.DefaultValue ? field.DefaultValue : undefined,
				value: field.DefaultValue ? field.DefaultValue : undefined,
				showStep: false,
				isDecimal: false,
				step: field.NumericRangeStep,
				max:
					field.Attributes &&
					field.Attributes.NumericRange &&
					field.Attributes.NumericRange.Max
						? field.Attributes.NumericRange.Max
						: undefined,
				min:
					field.Attributes &&
					field.Attributes.NumericRange &&
					field.Attributes.NumericRange.Min
						? field.Attributes.NumericRange.Min
						: undefined,
				pattern:
					field.Attributes &&
					field.Attributes.RegularExpression &&
					field.Attributes.RegularExpression.Pattern
						? field.Attributes.RegularExpression.Pattern
						: undefined,
			};
		case 5: // Boolean
			return {
				...commonProperties,
				type: FieldType.Boolean,
				defaultValue: field.DefaultValue && field.DefaultValue === "true",
				value: field.DefaultValue && field.DefaultValue === "true",
			};
		case 7: // Single Select
			return {
				...commonProperties,
				type: FieldType.Select,
				selectType: SelectFieldType.SingleSelect,
				data: new Map<number, string>(),
				dictionaryKey: field.DictionaryKey,
				defaultValue: field.DefaultValue && parseFloat(field.DefaultValue),
				value: field.DefaultValue && parseFloat(field.DefaultValue),
				parentDictionaryKey: field.ParentDictionaryKey,
				childDictionaryKeys: field.ChildDictionaryKeys,
			};
		case 8: // Project Select
			if (field.PropertyName === EntitySelectProperty.Project) {
				return {
					...commonProperties,
					type: FieldType.ProjectSelect,
				};
			} else if (Object.keys(EntitySelectProperty).includes(field.PropertyName)) {
				return {
					...commonProperties,
					type: FieldType.EntitySelect,
					propertyName: field.PropertyName,
				};
			}
			return {
				...commonProperties,
				type: FieldType.Text,
			};

		case 9: // Enum
			return {
				...commonProperties,
				type: FieldType.Select,
				selectType: SelectFieldType.EnumSingleSelect,
				data: new Map<number, string>(
					field.EnumItems.map((e: any) => [e.EnumValue, e.EnumText]),
				),
				defaultValue:
					field.DefaultValue &&
					field.EnumItems.find((i: any) => i.EnumString === field.DefaultValue) &&
					field.EnumItems.find((i: any) => i.EnumString === field.DefaultValue).EnumValue,
				value:
					field.DefaultValue &&
					field.EnumItems.find((i: any) => i.EnumString === field.DefaultValue) &&
					field.EnumItems.find((i: any) => i.EnumString === field.DefaultValue).EnumValue,
				enumData: field.EnumItems.map((e: any) => ({
					value: e.EnumValue,
					enumString: e.EnumString,
					enumText: e.EnumText,
				})),
			};
		case 10: // Time
			return {
				...commonProperties,
				type: FieldType.Time,
				defaultValue: field.DefaultValue && splitTimeToObject(field.DefaultValue, true),
				value: field.DefaultValue && splitTimeToObject(field.DefaultValue, true),
			};
		case 11: // Decimal
			return {
				...commonProperties,
				type: FieldType.Number,
				defaultValue: field.DefaultValue ? field.DefaultValue : undefined,
				value: field.DefaultValue ? field.DefaultValue : undefined,
				showStep: false,
				isDecimal: true,
				step: field.NumericRangeStep,
				max:
					field.Attributes &&
					field.Attributes.NumericRange &&
					field.Attributes.NumericRange.Max
						? field.Attributes.NumericRange.Max
						: undefined,
				min:
					field.Attributes &&
					field.Attributes.NumericRange &&
					field.Attributes.NumericRange.Min
						? field.Attributes.NumericRange.Min
						: undefined,
				pattern:
					field.Attributes &&
					field.Attributes.RegularExpression &&
					field.Attributes.RegularExpression.Pattern
						? field.Attributes.RegularExpression.Pattern
						: undefined,
			};
		case 12: // Matrix
			return {
				...commonProperties,
				type: FieldType.Matrix,
				matrixId: field.MatrixId,
				defaultValue: field.DefaultValue && parseFloat(field.DefaultValue),
				value: field.DefaultValue && parseFloat(field.DefaultValue),
			};
		case 13: // Multi Select
			return {
				...commonProperties,
				type: FieldType.Select,
				selectType: SelectFieldType.MultiSelect,
				data: new Map<number, string>(),
				dictionaryKey: field.DictionaryKey,
				defaultValue:
					field.DefaultValue && field.DefaultValue.length
						? field.DefaultValue
						: [field.DefaultValue],
				value:
					field.DefaultValue && field.DefaultValue.length
						? field.DefaultValue
						: [field.DefaultValue],
				parentDictionaryKey: field.ParentDictionaryKey,
				childDictionaryKeys: field.ChildDictionaryKeys,
			};
		case 15: // Geo position
			return {
				...commonProperties,
				hideValidationErrors: true,
				type: FieldType.GeoPosition,
				validation:
					field.Attributes &&
					field.Attributes.Geo &&
					field.Attributes.Geo.LocationMandatoryOptions,
				long: {
					pattern: "",
					guid: uuid(),
					min: -180,
					max: 180,
					isDecimal: true,
					showStep: false,
					isNotApplicable: commonProperties.isNotApplicable,
					isMandatory: commonProperties.isMandatory,
				},
				lat: {
					pattern: "",
					guid: uuid(),
					min: -90,
					max: 90,
					isDecimal: true,
					showStep: false,
					isNotApplicable: commonProperties.isNotApplicable,
					isMandatory: commonProperties.isMandatory,
				},
				description: {
					name: "Description",
					guid: commonProperties.guid,
					minLength: 0,
					maxLength: 500,
					rows: 3,
					isNotApplicable: false,
					isMandatory: !!(
						field.Attributes &&
						field.Attributes.Geo &&
						field.Attributes.Geo.LocationMandatoryOptions === "LocationTextField"
					),
					value: field.DefaultValue,
					isScannable: false,
				},
			};
		case 17: // Email
			return {
				...commonProperties,
				type: FieldType.Email,
				defaultValue: field.DefaultValue,
				value: field.DefaultValue,
				maxLength: field.MaxLength,
				rows: 1,
			};
		case 18: // Optional select
			return {
				...commonProperties,
				propertyName: field.PropertyName,
				type: FieldType.OptionalSelect,
				selectedFromRegister: null,
				relatedFields: field.RelatedFields.map((relatedField: Field) =>
					mapMainField(relatedField, questionnaireId, groupId, sectionId),
				),
				dataUrl: field.DataUrl,
			};
		default:
			return {
				...commonProperties,
				type: FieldType.Text,
			};
	}
};

export const mapAdditionalField = (
	field: any,
	questionnaireId: string,
	groupId: string,
	sectionId: number,
): Field => {
	const { Attributes, PropertyName } = field;
	const commonProperties: CommonFieldProperties = {
		questionnaireId,
		groupId,
		sectionId,
		guid: uuid(),
		id: field.Id,
		name: field.QuestionText,
		guidance: field.GuidanceText ? field.GuidanceText : undefined,
		isMandatory: !!field.IsMandatory,
		comments: {
			isEnabled: false,
			isNotApplicableCommentsEnabled: false,
		},
		allowNotApplicable: field.AllowNotApplicable,
		isNotApplicable: false,
		notesEnabled: false,
		hidden: false,
		orderIndex: parseFloat(field.OrderIndex),
		isIq: true,
		isConfidential: field.IsResponseConfidential,
		validationMessage: [],
		actionValidationMessage: [],
		attachmentValidationMessage: [],
		guidanceIsPopup: field.GuidanceIsPopup,
		mediaGuidance: field.MediaGuidance ? field.MediaGuidance : undefined,
		isUpdated: false,
		showGuidance: field.ShowGuidance,
		attachmentsEnabled: false,
		actionsEnabled: false,
		attributes: Attributes as Record<string, any>,
		propertyName: PropertyName,
		isScannable: false,
	};

	switch (field.ResponseType) {
		case 1: // DateTime
			return {
				...commonProperties,
				type: FieldType.DateTime,
				min: field.MinDateValue,
				max: field.MaxDateValue,
			};
		case 2: // Numeric
			return {
				...commonProperties,
				type: FieldType.Number,
				id: field.Id,
				showStep: true,
				isDecimal: true,
				step: undefined, // Additional fields do not have a step
				min: field.MinNumericValue ? field.MinNumericValue : undefined,
				max: field.MaxNumericValue ? field.MaxNumericValue : undefined,
				pattern: field.DefaultNumericRegExPattern
					? field.DefaultNumericRegExPattern
					: undefined,
				numberOfdecimalsToShow: 2,
			};
		case 3:
			return {
				...commonProperties,
				type: FieldType.Text,
				minLength: field.MinTextLength,
				maxLength: field.MaxTextLength,
				rows: 1,
			};
		case 4:
			return {
				...commonProperties,
				type: FieldType.Date,
				min: field.MinDateValue,
				max: field.MaxDateValue,
			};
		default:
			throw new Error(
				`mapAdditionalField() :: Unsupported additional field type '${field.FieldType}' (this shouldn't happen, the entire enum is mapped)`,
			);
	}
};

export const mapIQField = (
	field: any,
	questionnaireId: string,
	groupId: string,
	iQTemplate: any,
	sectionId: number,
): Field => {
	const { Attributes, PropertyName } = field;
	const commonProperties: CommonFieldProperties = {
		questionnaireId,
		groupId,
		sectionId,
		guid: uuid(),
		id: field.Id,
		name: field.QuestionText,
		guidance: field.GuidanceText ? field.GuidanceText : undefined,
		allowNotApplicable: field.AllowNotApplicable,
		isNotApplicable: false,
		isMandatory: !!field.IsMandatory,
		hidden: false,
		orderIndex: parseFloat(field.OrderIndex),
		originalOrderIndex: field.CompoundOrderIndex,
		isIq: true,
		parentId: field.ParentId,
		parentType: field.ParentType,
		isConfidential: field.IsResponseConfidential,
		validationMessage: [],
		actionValidationMessage: [],
		attachmentValidationMessage: [],
		comments: {
			isEnabled: field.AllowComments,
			isNotApplicableCommentsEnabled:
				iQTemplate.NotApplicableCommentsOption !== NotApplicableComments.Hidden,
		},
		notesEnabled: field.AllowNotes,
		notesLength: field.NotesLength,
		guidanceIsPopup: field.GuidanceIsPopup,
		mediaGuidance: field.MediaGuidance ? field.MediaGuidance : undefined,
		isUpdated: false,
		showGuidance: field.ShowGuidance,
		attachmentsEnabled: field.AllowAttachments > 0,
		actionsEnabled: field.AllowActions > 0,
		actionsMandatory: field.AllowActions === 2,
		attachmentsMandatory: field.AllowAttachments === 2,
		attributes: Attributes as Record<string, any>,
		propertyName: PropertyName,
		isScannable: false,
	};

	commonProperties.parsedOrderIndex = commonProperties.originalOrderIndex
		? commonProperties.originalOrderIndex.split(".").map((i) => parseFloat(i))
		: undefined;

	switch (field.ResponseType) {
		case 1:
			return {
				...commonProperties,
				type: FieldType.Number,
				value: field.DefaultValue ? field.DefaultValue : undefined,
				min: !Number.isNaN(field.NumericRangeMin) ? field.NumericRangeMin : undefined,
				max: !Number.isNaN(field.NumericRangeMax) ? field.NumericRangeMax : undefined,
				showStep: true,
				isDecimal: true,
				step: field.NumericRangeStep,
				pattern: field.DefaultNumericRegExPattern
					? field.DefaultNumericRegExPattern
					: undefined,
			};
		case 2:
			return {
				...commonProperties,
				type: FieldType.Text,
				defaultValue: field.DefaultValue ? field.DefaultValue : undefined,
				value: field.DefaultValue ? field.DefaultValue : undefined,
				minLength: field.MinTextLength,
				maxLength: field.MaxTextLength,
				rows: 1,
			};
		case 3: // Single Select
			return {
				...commonProperties,
				type: FieldType.Select,
				dictionaryKey: "",
				selectType: SelectFieldType.SingleSelect,
				data: new Map<number, string>(
					field.AvailableResponses &&
						field.AvailableResponses.map((ar: any) => [
							ar,
							iQTemplate.ResponsePoolItems.find((rpi: any) => ar === rpi.Id).Value,
						]),
				),
				defaultValue:
					field.DefaultResponses && field.DefaultResponses.length
						? field.DefaultResponses[0]
						: undefined,
				value:
					field.DefaultResponses && field.DefaultResponses.length
						? field.DefaultResponses[0]
						: undefined,
			};
		case 4: // Multi Select
			return {
				...commonProperties,
				type: FieldType.Select,
				dictionaryKey: "",
				selectType: SelectFieldType.MultiSelect,
				data: new Map<number, string>(
					field.AvailableResponses &&
						field.AvailableResponses.map((ar: any) => [
							ar,
							iQTemplate.ResponsePoolItems.find((rpi: any) => ar === rpi.Id).Value,
						]),
				),
				defaultValue:
					field.DefaultResponses && field.DefaultResponses.length
						? field.DefaultResponses
						: [],
				value:
					field.DefaultResponses && field.DefaultResponses.length
						? field.DefaultResponses
						: [],
			};
		case 5: // Behavioural
			return {
				...commonProperties,
				type: FieldType.Behavioural,
				safeField: {
					guid: uuid(),
					isNotApplicable: commonProperties.isNotApplicable,
					isMandatory: commonProperties.isMandatory,
					value: undefined,
					min: !Number.isNaN(field.Min) ? field.Min : undefined,
					max: !Number.isNaN(field.Max) ? field.Max : undefined,
					showStep: true,
					isDecimal: false,
					step: undefined,
					pattern: field.DefaultNumericRegExPattern
						? field.DefaultNumericRegExPattern
						: undefined,
				},
				unSafeField: {
					guid: uuid(),
					isNotApplicable: commonProperties.isNotApplicable,
					isMandatory: commonProperties.isMandatory,
					value: undefined,
					min: !Number.isNaN(field.Min) ? field.Min : undefined,
					max: !Number.isNaN(field.Max) ? field.Max : undefined,
					showStep: true,
					isDecimal: false,
					step: undefined,
					pattern: field.DefaultNumericRegExPattern
						? field.DefaultNumericRegExPattern
						: undefined,
				},
			};
		case 6: // Audit
			return {
				...commonProperties,
				type: FieldType.Audit,
				scoreField: {
					guid: uuid(),
					isNotApplicable: commonProperties.isNotApplicable,
					isMandatory: commonProperties.isMandatory,
					value: undefined,
					min: !Number.isNaN(field.Min) ? field.Min : undefined,
					max: !Number.isNaN(field.Max) ? field.Max : undefined,
					showStep: true,
					isDecimal: false,
					step: undefined,
					pattern: field.DefaultNumericRegExPattern
						? field.DefaultNumericRegExPattern
						: undefined,
				},
			};
		case 7: // Checklists
			return {
				...commonProperties,
				type: FieldType.Boolean,
				defaultValue:
					field.DefaultValue && field.DefaultValue === "-1"
						? undefined
						: field.DefaultValue && field.DefaultValue === "1",
				value:
					field.DefaultValue && field.DefaultValue === "-1"
						? undefined
						: field.DefaultValue && field.DefaultValue === "1",
			};
		case 8: // Signature
			return {
				...commonProperties,
				type: FieldType.IQSignature,
				signature: {
					name: "Signature",
					guid: uuid(),
					isNotApplicable: false,
					isMandatory: commonProperties.isMandatory,
					value: undefined,
					validationMessage: [],
				},
				fullname: {
					name: "Full Name",
					guid: uuid(),
					minLength: 0,
					maxLength: 256,
					rows: 1,
					isNotApplicable: false,
					isMandatory: commonProperties.isMandatory,
					value: undefined,
					isScannable: false,
				},
				description: {
					name: "Description",
					guid: uuid(),
					minLength: 0,
					maxLength: 512,
					rows: 3,
					isNotApplicable: false,
					isMandatory: false,
					value: undefined,
					isScannable: false,
				},
			};
		case 9:
			return {
				...commonProperties,
				type: FieldType.IQDateTime,
				LimitBeforeType: field.LimitBeforeType,
				LimitBeforeValue: field.LimitBeforeValue,
				LimitAfterType: field.LimitAfterType,
				LimitAfterValue: field.LimitAfterValue,
				isFutureAllowed: field.LimitAfterValue && field.LimitAfterValue > 0,
				isPastAllowed: field.LimitBeforeValue && field.LimitBeforeValue > 0,
			};
		case 10:
			return {
				...commonProperties,
				type: FieldType.IQDate,
				LimitBeforeType: field.LimitBeforeType,
				LimitBeforeValue: field.LimitBeforeValue,
				LimitAfterType: field.LimitAfterType,
				LimitAfterValue: field.LimitAfterValue,
				isFutureAllowed: field.LimitAfterValue && field.LimitAfterValue > 0,
				isPastAllowed: field.LimitBeforeValue && field.LimitBeforeValue > 0,
			};
		case 11:
			return {
				...commonProperties,
				type: FieldType.Time,
			};
		default:
			return {
				...commonProperties,
				type: FieldType.Text,
			};
	}
};

// why is this a promise?
export const mapSubModuleField = async (
	field: any,
	questionnaireId: string,
	subModuleId: number,
): Promise<Field> => {
	const { Attributes, PropertyName } = field;
	const commonProperties: CommonFieldProperties = {
		questionnaireId,
		subModuleId,
		guid: uuid(),
		id: field.Id,
		name: field.DisplayText,
		guidance: field.Guidance ? field.Guidance : undefined,
		isMandatory: !!field.IsMandatory,
		hidden: field.IsHiddenByDefault,
		orderIndex: parseFloat(field.OrderIndex),
		isIq: true,
		isConfidential: false,
		validationMessage: [],
		actionValidationMessage: [],
		attachmentValidationMessage: [],
		comments: {
			isEnabled: false,
			isNotApplicableCommentsEnabled: false,
		},
		allowNotApplicable: field.AllowNotApplicable,
		isNotApplicable: false,
		notesEnabled: false,
		guidanceIsPopup: field.TypeOfDisplay === 1,
		mediaGuidance: field.MediaGuidance ? field.MediaGuidance : undefined,
		isUpdated: false,
		showGuidance: Boolean(field.Guidance),
		attachmentsEnabled: false,
		actionsEnabled: false,
		attributes: Attributes as Record<string, any>,
		propertyName: PropertyName,
		isScannable: false,
	};

	let mappedField: Field;

	switch (field.FieldType) {
		case 1: // Date
			mappedField = {
				...commonProperties,
				type: FieldType.Date,
				defaultValue: field.DefaultValue && splitDateToObject(field.DefaultValue),
				value: field.DefaultValue && splitDateToObject(field.DefaultValue),
				isIq: false,
				isFutureAllowed:
					field.Attributes &&
					field.Attributes.FutureDate &&
					field.Attributes.FutureDate.IsAllowed,
			};
			break;
		case 2: // Single Text
			mappedField = {
				...commonProperties,
				type: FieldType.Text,
				defaultValue: field.DefaultValue,
				value: field.DefaultValue,
				maxLength: field.MaxLength,
				rows: 1,
			};
			break;
		case 3: // Multi Text
			if (field.DictionaryKey !== null && field.DictionaryKey !== undefined) {
				mappedField = {
					...commonProperties,
					type: FieldType.SelectableText,
					defaultValue: field.DefaultValue,
					value: field.DefaultValue,
					maxLength: field.MaxLength,
					rows: 5,
					isIq: false,
					dictionaryKey: field.DictionaryKey,
					parentDictionaryKey: field.ParentDictionaryKey,
					childDictionaryKeys: field.ChildDictionaryKeys,
				};
			} else {
				mappedField = {
					...commonProperties,
					type: FieldType.Text,
					defaultValue: field.DefaultValue,
					value: field.DefaultValue,
					maxLength: field.MaxLength,
					rows: 5,
					isIq: false,
				};
			}

			break;
		case 4: // Number
			mappedField = {
				...commonProperties,
				type: FieldType.Number,
				defaultValue: field.DefaultValue ? field.DefaultValue : undefined,
				value: field.DefaultValue ? field.DefaultValue : undefined,
				showStep: true,
				isDecimal: false,
				step: field.NumericRangeStep,
				max:
					field.Attributes &&
					field.Attributes.NumericRange &&
					field.Attributes.NumericRange.Max
						? field.Attributes.NumericRange.Max
						: undefined,
				min:
					field.Attributes &&
					field.Attributes.NumericRange &&
					field.Attributes.NumericRange.Min
						? field.Attributes.NumericRange.Min
						: undefined,
				pattern:
					field.Attributes &&
					field.Attributes.RegularExpression &&
					field.Attributes.RegularExpression.Pattern
						? field.Attributes.RegularExpression.Pattern
						: undefined,
			};
			break;
		case 5: // Boolean
			mappedField = {
				...commonProperties,
				type: FieldType.Boolean,
				defaultValue: field.DefaultValue && field.DefaultValue === "true",
				value: field.DefaultValue && field.DefaultValue === "true",
			};
			break;
		case 7: // Single Select
			mappedField = {
				...commonProperties,
				type: FieldType.Select,
				selectType: SelectFieldType.SingleSelect,
				dictionaryKey: field.DictionaryKey,
				data: new Map<number, string>(),
				defaultValue: field.DefaultValue && parseFloat(field.DefaultValue),
				value: field.DefaultValue && parseFloat(field.DefaultValue),
				parentDictionaryKey: field.ParentDictionaryKey,
				childDictionaryKeys: field.ChildDictionaryKeys,
			};
			break;
		case 8: // Entity Select
			if (field.PropertyName === EntitySelectProperty.Project) {
				mappedField = {
					...commonProperties,
					type: FieldType.ProjectSelect,
				};
			} else if (Object.keys(EntitySelectProperty).includes(field.PropertyName)) {
				mappedField = {
					...commonProperties,
					type: FieldType.EntitySelect,
					propertyName: field.PropertyName,
				};
			} else if (Object.keys(EntitySelectPropertyWithDefault).includes(field.PropertyName)) {
				mappedField = {
					...commonProperties,
					type: FieldType.EntitySelectWithDefault,
					propertyName: field.PropertyName,
					value: "UseDefaultValue",
					defaultValue: "UseDefaultValue",
					authenticatedOnly: AUTHENTICATED_ONLY_PROPERTIES.includes(field.PropertyName),
				};
			} else {
				mappedField = {
					...commonProperties,
					type: FieldType.Text,
				};
			}

			break;
		case 9: // Enum
			mappedField = {
				...commonProperties,
				type: FieldType.Select,
				selectType: SelectFieldType.EnumSingleSelect,
				data: new Map<number, string>(
					field.EnumItems.map((e: any) => [e.EnumValue, e.EnumText]),
				),
				defaultValue:
					field.DefaultValue &&
					field.EnumItems.find((i: any) => i.EnumString === field.DefaultValue) &&
					field.EnumItems.find((i: any) => i.EnumString === field.DefaultValue).EnumValue,
				value:
					field.DefaultValue &&
					field.EnumItems.find((i: any) => i.EnumString === field.DefaultValue) &&
					field.EnumItems.find((i: any) => i.EnumString === field.DefaultValue).EnumValue,
				enumData: field.EnumItems.map((e: any) => ({
					value: e.EnumValue,
					enumString: e.EnumString,
					enumText: e.EnumText,
				})),
			};
			break;
		case 10: // Time
			mappedField = {
				...commonProperties,
				type: FieldType.Time,
				defaultValue: field.DefaultValue && splitTimeToObject(field.DefaultValue, true),
				value: field.DefaultValue && splitTimeToObject(field.DefaultValue, true),
			};
			break;
		case 11: // Decimal
			mappedField = {
				...commonProperties,
				type: FieldType.Number,
				defaultValue: field.DefaultValue ? field.DefaultValue : undefined,
				value: field.DefaultValue ? field.DefaultValue : undefined,
				showStep: false,
				isDecimal: true,
				step: field.NumericRangeStep,
				min: field.MinNumericValue ? field.MinNumericValue : undefined,
				max: field.MaxNumericValue ? field.MaxNumericValue : undefined,
				pattern:
					field.Attributes &&
					field.Attributes.RegularExpression &&
					field.Attributes.RegularExpression.Pattern
						? field.Attributes.RegularExpression.Pattern
						: undefined,
			};
			break;
		case 12: // Matrix
			mappedField = {
				...commonProperties,
				type: FieldType.Matrix,
				matrixId: field.MatrixId,
				defaultValue: field.DefaultValue && parseFloat(field.DefaultValue),
				value: field.DefaultValue && parseFloat(field.DefaultValue),
			};
			break;
		case 13: // Multi Select
			mappedField = {
				...commonProperties,
				type: FieldType.Select,
				selectType: SelectFieldType.MultiSelect,
				data: new Map<number, string>(), // todo get data
				dictionaryKey: field.DictionaryKey,
				defaultValue:
					field.DefaultValue && field.DefaultValue.length
						? field.DefaultValue
						: [field.DefaultValue],
				value:
					field.DefaultValue && field.DefaultValue.length
						? field.DefaultValue
						: [field.DefaultValue],
				parentDictionaryKey: field.ParentDictionaryKey,
			};
			break;
		case 14:
			mappedField = {
				...commonProperties,
				type: FieldType.Signature,
				defaultValue: field.DefaultValue,
				value: field.DefaultValue,
				isIq: false,
			};
			break;
		case 16: // PPE
			mappedField = mapPPEField(field, commonProperties);
			break;
		case 17:
			mappedField = {
				...commonProperties,
				type: FieldType.Email,
				rows: 1,
				isIq: false,
			};
			break;
		default:
			mappedField = {
				...commonProperties,
				type: FieldType.Text,
			};
	}

	if (subModuleId === LocalSectionId.Actions) {
		remapActionField(field, mappedField);
	}

	if (subModuleId === LocalSectionId.Submission) {
		remapSubmissionField(field, mappedField);
	}

	return mappedField;
};

/**
 * Modifies field to be used in Actions submodule. Maps PropertyName to LocalFieldId enum. This is later used to create outbound DTO for Action.
 */
function remapActionField(field: any, actionField: Field) {
	switch (field.PropertyName) {
		case "ActionHeader":
			actionField.id = LocalFieldIds.ActionTitle;
			break;
		case "DueDate":
			actionField.id = LocalFieldIds.ActionDueDate;
			(actionField as DateField).isPastAllowed = false;
			break;
		case "ActionDetail":
			actionField.id = LocalFieldIds.ActionDetail;
			break;
		case "Priority":
			actionField.id = LocalFieldIds.ActionPriority;
			break;
		case "ActionCategory":
			actionField.id = LocalFieldIds.ActionCategory;
			break;
		case "ActionSubCategory":
			actionField.id = LocalFieldIds.ActionSubCategory;
			break;
		case "ForUser":
			actionField.id = LocalFieldIds.ActionForUser;
	}
}

function remapSubmissionField(field: any, submissionField: Field) {
	switch (field.PropertyName) {
		case "Assigned":
			submissionField.id = LocalFieldIds.SubmissionAssigned;
			submissionField.groupId = LocalGroupIds.Submission;
			submissionField.sectionId = LocalSectionId.Submission;
			delete submissionField.subModuleId;
			break;
		case "DueDate":
			submissionField.id = LocalFieldIds.SubmissionDueDate;
			submissionField.groupId = LocalGroupIds.Submission;
			submissionField.sectionId = LocalSectionId.Submission;
			delete submissionField.subModuleId;
			break;
		case "ApprovalComment":
			submissionField.id = LocalFieldIds.SubmissionComments;
			submissionField.groupId = LocalGroupIds.Submission;
			submissionField.sectionId = LocalSectionId.Submission;
			delete submissionField.subModuleId;
			break;
	}
}

function CreateCommonProperties(
	field: any,
	questionnaireId: string,
	groupId: string,
	sectionId: number,
	isIQ: boolean,
	isConfidential: boolean,
): CommonFieldProperties {
	const { Attributes, PropertyName } = field;
	return {
		questionnaireId,
		groupId,
		sectionId,
		guid: uuid(),
		id: field.Id,
		name: field.DisplayText,
		guidance: field.Guidance ? field.Guidance : undefined,
		isMandatory: !!field.IsMandatory,
		hidden: field.IsHiddenByDefault,
		orderIndex: parseFloat(field.OrderIndex),
		isIq: isIQ,
		isConfidential,
		validationMessage: [],
		actionValidationMessage: [],
		attachmentValidationMessage: [],
		comments: {
			isEnabled: false,
			isNotApplicableCommentsEnabled: false,
		},
		allowNotApplicable: field.AllowNotApplicable,
		isNotApplicable: false,
		notesEnabled: false,
		guidanceIsPopup: field.TypeOfDisplay === 1,
		isUpdated: false,
		showGuidance: Boolean(field.Guidance),
		attachmentsEnabled: false,
		isDirectInput: field.IsDirectInput,
		actionsEnabled: false,
		attributes: Attributes as Record<string, any>,
		propertyName: PropertyName,
		isScannable: !!field.IsScannable,
		registerModuleName: field.RegisterModuleName,
	};
}

function mapPPEField(field: any, commonProperties: CommonFieldProperties): PPEField {
	const mappedField: PPEField = {
		...commonProperties,
		type: FieldType.PPE,
		categories: mapPPECategories(field.Attributes.PpeImages),
	};
	return mappedField;
}

function mapPPECategories(categories: any[]): PPECategory[] {
	return categories
		.map((c) => ({
			iconId: c.Text,
			name: c.TextInGivenLanguage,
			id: c.Id,
			options: mapPPEOptions(c.Categories),
		}))
		.filter((c) => Object.values(PPEIcon).includes(c.iconId));
}

function mapPPEOptions(options: any[]): PPEOption[] {
	return options
		.map((o) => ({
			id: o.Id,
			name: o.Text,
			order: o.OrderIndex,
			orgUnitIds: o.OrgUnitIds,
		}))
		.sort((a, b) => b.order - a.order);
}
