import { t } from "i18next";
import type { TextField, SimplifiedTextField } from "../../models/fields/TextField";
import type { IndirectField } from "../../models/fields/IndirectField";
import type { SelectableTextField } from "../../models/fields/SelectableTextField";

export class TextFieldValidator {
	textValidationRegex = new RegExp(/.*\S.*/);

	textField: TextField | IndirectField | SelectableTextField | SimplifiedTextField;
	messages: string[];

	constructor(textField: TextField | IndirectField | SelectableTextField | SimplifiedTextField) {
		this.textField = textField;
		this.messages = [];
	}

	isFieldValid = (value?: string): boolean => {
		this.messages = [];
		let result = true;

		if (typeof value === "string" && typeof this.textField.minLength === "number") {
			if (
				value.length < this.textField.minLength &&
				!(value.length === 0 && !this.textField.isMandatory)
			) {
				this.messages.push(
					t("validation:minimumLengthMessage", {
						minLength: this.textField.minLength,
					}),
				);
				result = false;
			}
		}

		if (typeof value === "string" && typeof this.textField.maxLength === "number") {
			if (value.length > this.textField.maxLength) {
				this.messages.push(
					t("validation:maxLengthMessage", {
						maxLength: this.textField.maxLength,
					}),
				);
				result = false;
			}
		}

		if ((typeof value !== "string" || value.length === 0) && this.textField.isMandatory) {
			this.messages.push(t("validation:mandatoryFieldMessage"));
			result = false;
		}

		if (
			typeof value === "string" &&
			value.length > 0 &&
			this.textField.isMandatory &&
			!this.textValidationRegex.exec(value)
		) {
			this.messages.push(t("validation:invalidTextMessage"));
			result = false;
		}

		return result;
	};
}
