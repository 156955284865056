import { useMemo, useState } from "react";
import { Button } from "../../../../components/input/Button";
import { FormModal } from "../../../../components/modals";

export const DisplayObjectModal = ({ objectContent }: { objectContent: object }) => {
	const [showModal, setShowModal] = useState(false);

	const stringifiedObjectContent = useMemo(
		() => JSON.stringify(objectContent, null, 2),
		[objectContent],
	);

	return (
		<>
			<Button onClick={() => setShowModal(true)}>View</Button>
			<FormModal
				onCancel={() => {
					setShowModal(false);
				}}
				onOk={() => {
					setShowModal(false);
				}}
				show={showModal}
				showCancelBtn={false}
			>
				<pre>{stringifiedObjectContent}</pre>
			</FormModal>
		</>
	);
};
