import { useEffect, useMemo, useRef, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Axios from "axios";
import { RegisterEntitySortOrder } from "../../../../../models/registerEntity";
import { OptionalRegisterSelectInputListItem } from "../OptionalRegisterSelectInputListItem";
import { CancelablePromise } from "../../../../../services/utilities/CancelablePromise";
import { DefaultRegisterService } from "../../../../../services/register";
import { RegisterSelectDefaultSort } from "../../../../../models/questionnaire";
import { FormModal } from "../../../modals";
import { ReactComponent as IconSort } from "../../../../assets/svg/arrange-letter.svg";
import { Pagination } from "../../../generic/Pagination";
import { RadioButton } from "../../../input/RadioButton";
import { SearchBox } from "../../../input/SearchBox";
import { TouchInputBlurrer } from "../../../generic/TouchInputBlurrer";
import { switchMatchingLanguageCode } from "../../../../../helpers/LanguageCodeHelper";
import type { State } from "../../../../../state";
import type { OrgUnit } from "../../../../../models/orgUnit";
import type { RegisterEntity, RegisterEntities } from "../../../../../models/registerEntity";
import type { TranslationSortOrderKey } from "../../../../../types/i18next";
import "./OptionalRegisterSelectInputList.styles.scss";

interface Props {
	propertyName: string;
	portalCulture: string;
	dataUrl: string;
	onSelectEntity: (entity: RegisterEntity) => void;
	onEntitySearch: (entity: RegisterEntity) => void;
	selectedEntity?: RegisterEntity;
	initialSearch?: string;
	qrScannable?: boolean;
}

interface RegisterSelectSortOption {
	idTitle: string;
	selected: boolean;
	onClick: () => void;
	label: string;
}

interface SortInfo {
	order: RegisterEntitySortOrder;
	property: string;
	caption: string;
}

export const OptionalRegisterSelectInputList = ({
	dataUrl,
	propertyName,
	selectedEntity,
	portalCulture,
	onSelectEntity,
	onEntitySearch,
	initialSearch,
	qrScannable,
}: Props) => {
	const { t, i18n } = useTranslation();
	const pageSize = 100;
	const [page, setPage] = useState(0);
	const { portalKey } = useParams<{ portalKey: string }>();
	const searchBoxRef = useRef<HTMLInputElement>(null);
	const language = useMemo(() => switchMatchingLanguageCode(i18n.language), [i18n.language]);
	const [sortOptions, setSortOptions] = useState<RegisterSelectSortOption[]>([]);

	const orgUnits = useSelector<State, OrgUnit[]>((s) => s.orgUnit.orgUnits);
	const rootOrgUnitId: number = useMemo(() => {
		const rootOrgUnit = orgUnits.find((ou) => ou.parentId === undefined);
		if (rootOrgUnit) {
			return rootOrgUnit.id;
		}
		return 0;
	}, [orgUnits]);

	const [showSortModal, setShowSortModal] = useState(false);
	const [sort, setSort] = useState<SortInfo>(() => {
		const sortFromStorage = localStorage.getItem(`registerSelectSort${propertyName}`);

		if (sortFromStorage) {
			const parsedSortFromStorage = JSON.parse(sortFromStorage);

			if (
				parsedSortFromStorage.property &&
				Object.keys(RegisterEntitySortOrder).includes(parsedSortFromStorage.order)
			) {
				return parsedSortFromStorage;
			}
		}

		if (Object.keys(RegisterSelectDefaultSort).includes(propertyName)) {
			return {
				order: RegisterEntitySortOrder.Asc,
				property: (RegisterSelectDefaultSort as any)[propertyName],
			};
		}

		return { order: RegisterEntitySortOrder.Asc, property: "EntityReference" };
	});

	const [tempSort, setTempSort] = useState<SortInfo | undefined>(sort);

	const [searchTerm, setSearchTerm] = useState(() => initialSearch);

	const registerService = useMemo(() => new DefaultRegisterService(dataUrl), [dataUrl]);

	const { data, isLoading, isError } = useQuery(
		[
			propertyName,
			rootOrgUnitId,
			portalKey,
			sort.property,
			sort.order,
			language,
			searchTerm,
			portalCulture,
			page,
		],
		() => {
			const cancelTokenSource = Axios.CancelToken.source();
			const getRecords = new CancelablePromise<RegisterEntities>((resolve, reject) =>
				registerService
					.getRegisterList(
						portalKey,
						searchTerm,
						sort.property,
						portalCulture,
						page * pageSize,
						pageSize,
						sort.order,
						language,
					)
					.then((r) => resolve(r))
					.catch((e) => reject(e)),
			);
			getRecords.cancel = () => {
				cancelTokenSource.cancel();
			};
			return getRecords;
		},
	);

	const updateSortCaption = useCallback(() => {
		if (data && data.sortProperties) {
			const sortProperty = data.sortProperties.find(
				({ propertyName }) => propertyName === sort.property,
			);

			if (sortProperty) {
				setSort({ ...sort, caption: sortProperty.caption });
			}
		}
	}, [data, sort, setSort]);

	useEffect(updateSortCaption, [data]);

	const recordsDetails = data
		? t("display:entitySelect.labelShowingRecordsNumber", {
				min: pageSize * page + 1,
				max: Math.min(pageSize * (page + 1), data.totalDataCount),
				total: data.totalDataCount,
		  })
		: "";

	const onChangePage = (page: number) => {
		setPage(page - 1);
	};

	const onSearchTermChange = (searchTerm: string) => {
		setPage(0);
		setSearchTerm(searchTerm);
	};

	const handleSetSort = () => {
		if (tempSort) {
			setSort(tempSort);
			localStorage.setItem(`registerSelectSort${propertyName}`, JSON.stringify(tempSort));
		}
		setShowSortModal(false);
	};

	useEffect(() => {
		if (data && data && data.sortProperties.length > 0) {
			const sortedProperties = data.sortProperties.sort((a, b) => {
				const aPropertyToCompare =
					a.caption !== null && a.caption !== undefined ? a.caption : a.propertyName;
				const bPropertyToCompare =
					b.caption !== null && b.caption !== undefined ? b.caption : b.propertyName;

				return aPropertyToCompare.localeCompare(bPropertyToCompare);
			});

			const sortCategories = [
				{ titleSuffix: "a-to-z", sortOrder: RegisterEntitySortOrder.Asc, order: "aToZ" },
				{ titleSuffix: "z-to-a", sortOrder: RegisterEntitySortOrder.Desc, order: "zToA" },
			];

			const newSortProperties = sortedProperties.reduce(
				(acc: RegisterSelectSortOption[], sp) => {
					sortCategories.forEach((category) => {
						acc.push({
							idTitle: `${sp.propertyName}-${category.titleSuffix}`,
							selected:
								!!tempSort &&
								tempSort.order === category.sortOrder &&
								tempSort.property === sp.propertyName,
							onClick: () =>
								setTempSort({
									order: category.sortOrder,
									property: sp.propertyName,
									caption: sp.caption,
								}),
							label: t("display:entitySelect.label", {
								title:
									sp.caption !== null && sp.caption !== undefined
										? sp.caption
										: sp.propertyName,
								order: t(
									`display:entitySelect.${
										category.order as TranslationSortOrderKey
									}`,
								),
							}),
						});
					});

					return acc;
				},
				[],
			);

			setSortOptions(newSortProperties);
		}
	}, [data, t, tempSort, propertyName]);

	useEffect(() => {
		if (data && data.totalDataCount === 1 && data.list.length === 1) {
			// Check that one of the properties values matches the search term

			if (data.list[0].info.some((info) => info.value === searchTerm)) {
				onEntitySearch(data.list[0]);
			}
		}
	}, [data, searchTerm, onEntitySearch]);

	return (
		<div className="she-entity-list">
			<div className="she-entity-list__search">
				<SearchBox
					onSearchBegan={() => {}}
					onSearchEnd={() => {}}
					onSearchTermChange={onSearchTermChange}
					qrScannable={qrScannable}
					reference={searchBoxRef}
					searchTerm={searchTerm || ""}
				/>
			</div>

			{sortOptions && sortOptions.length > 0 && (
				<div className="she-entity-list__sort">
					<button
						className="she-btn she-btn-tertiary she-entity-list__sort-button"
						onClick={() => {
							setShowSortModal(true);
							setTempSort(sort);
						}}
						type="button"
					>
						<IconSort aria-hidden />
						{t("display:entitySelect.buttonLabelSort")}
					</button>
				</div>
			)}
			<div className="she-entity-list__list">
				{data && data.list && data.list.length > 0 && (
					<TouchInputBlurrer refToBlur={searchBoxRef}>
						<div className="she-entity-list__numbers">
							{t("display:entitySelect.labelShowingRecordsNumberSortedBy", {
								labelShowingRecordsNumber: recordsDetails,
								sortedBy: t("display:entitySelect.sortedBy", {
									label: t("display:entitySelect.label", {
										title: sort.caption,
										order:
											sort.order === RegisterEntitySortOrder.Asc
												? t("display:entitySelect.aToZ")
												: t("display:entitySelect.zToA"),
									}),
								}),
							})}
						</div>

						{data.list &&
							data.list.map((entity: RegisterEntity) => (
								<OptionalRegisterSelectInputListItem
									entity={entity}
									groupText={propertyName}
									key={entity.id}
									onSelectEntity={() => onSelectEntity(entity)}
									selected={!!selectedEntity && selectedEntity.id === entity.id}
								/>
							))}

						<div className="she-entity-list__numbers">{recordsDetails}</div>
						<div className="she-entity-list__pagination">
							{data && (
								<Pagination
									currentPage={page + 1}
									maxPages={Math.ceil(data.totalDataCount / pageSize)}
									onChangePage={onChangePage}
								/>
							)}
						</div>
					</TouchInputBlurrer>
				)}

				{isLoading && <div className="she-entity-list__status">{t("global:loading")}</div>}

				{isError && (
					<div className="she-entity-list__status">{t("error:dataRetrievalFailed")}</div>
				)}

				{data && data.list && data.list.length === 0 && (
					<div className="she-entity-list__status">
						{searchTerm
							? t("display:labelNoSearchResults")
							: t("display:labelEntityEmptyList")}
					</div>
				)}
			</div>

			<FormModal
				onCancel={() => setShowSortModal(false)}
				onOk={() => handleSetSort()}
				show={showSortModal}
				title="Sort"
			>
				<div style={{ padding: "1rem" }}>
					<div className="she-components-control-question">
						{t("display:entitySelect.sortRecordsBy")}
					</div>
					{sortOptions.map((sortOption) => (
						<RadioButton
							id={sortOption.idTitle}
							key={sortOption.idTitle}
							name="entity-sort"
							onClick={sortOption.onClick}
							selected={sortOption.selected}
						>
							{sortOption.label}
						</RadioButton>
					))}
				</div>
			</FormModal>
		</div>
	);
};
