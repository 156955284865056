import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { changeLanguage } from "../../../../../translations/i18n";
import { FormModal } from "../../../../components/modals";
import { PageLanguageSwitcher } from "../PageLanguageSwitcher";
import { SettingsModuleListItem } from "../SettingsModuleListItem";
import { storeLanguage } from "../../../../../translations/helpers/i18nHelpers";
import type { PortalTranslationLanguage } from "../../../../../types/i18next";
import type { SettingsModuleListItemOptions } from "../../../../../models/settings";
import type { Portal } from "../../../../../models/portal";
import "./PageLanguageSwitcherWrapper.styles.scss";

interface Props {
	portal: Portal;
}

export const PageLanguageSwitcherWrapper = ({ portal }: Props) => {
	const { t, i18n } = useTranslation();

	const [isOpen, setIsOpen] = useState(false);
	const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
	const [selectedLanguageToShow, setSelectedLanguageToShow] = useState<string>("en-gb");

	useEffect(() => {
		if (
			portal.userLanguages.some((userLanguage) => userLanguage.idString === selectedLanguage)
		) {
			setSelectedLanguageToShow(selectedLanguage);
		} else {
			setSelectedLanguageToShow("en-gb");
		}
	}, [portal.userLanguages, selectedLanguage, selectedLanguageToShow]);

	const languages: Record<string, string> = useMemo(() => {
		const sortedLanguages = portal.userLanguages.sort((a, b) => {
			return t(
				`display:language${a.idString.replace("-", "") as PortalTranslationLanguage}`,
			).localeCompare(
				t(`display:language${b.idString.replace("-", "") as PortalTranslationLanguage}`),
			);
		});
		return sortedLanguages.reduce((acc, current) => {
			return {
				...acc,
				[current.idString]: t(
					`display:language${
						current.idString.replace("-", "") as PortalTranslationLanguage
					}`,
				),
			};
		}, {});
	}, [portal.userLanguages, t]);

	const languageName = languages[Object.keys(languages).find((k) => k === i18n.language) || 0];

	const changeOpen = (param: boolean) => {
		setIsOpen(param);
	};

	const options: SettingsModuleListItemOptions = [
		{
			label: t("display:labelChangeLanguage"),
			action: changeOpen,
		},
	];

	return (
		<>
			<FormModal
				large
				onCancel={() => {
					setSelectedLanguage(i18n.language);
					setIsOpen(false);
				}}
				onOk={() => {
					changeLanguage(selectedLanguage);
					storeLanguage(selectedLanguage, portal.customerKey, portal.key);
					setIsOpen(false);
				}}
				padded={false}
				show={isOpen}
				title={t("display:labelLanguageRegion")}
			>
				<PageLanguageSwitcher
					languages={portal.userLanguages}
					selectedLanguage={selectedLanguageToShow}
					setSelectedLanguage={setSelectedLanguage}
				/>
			</FormModal>
			<div>
				<TransitionGroup component={null}>
					<CSSTransition appear classNames="fade" component={null} timeout={500}>
						<SettingsModuleListItem
							image="earth"
							options={options}
							subTitle={languageName || "English"}
							title={t("display:labelLanguageRegion")}
						/>
					</CSSTransition>
				</TransitionGroup>
			</div>
		</>
	);
};
