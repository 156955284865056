import { useCallback, useEffect, useRef, useState } from "react";
import classnames from "classnames";
import { ReactComponent as DropdownChevron } from "../../assets/svg/arrow-down-1.svg";
import "./Menu.styles.scss";

interface Props {
	children: React.ReactNode;
	trigger?: React.ReactNode;
	icon?: React.ReactNode;
	accessibleButtonLabel: string;
	variant?: "ghost" | "select";
}

export const Menu = ({
	children,
	trigger,
	accessibleButtonLabel,
	variant = "ghost",
	icon,
}: Props) => {
	const [open, setOpen] = useState(() => false);
	const triggerRef = useRef<HTMLButtonElement>(null);
	const listRef = useRef<HTMLUListElement>(null);
	const [timeouts, setTimeouts] = useState<number[]>([]);

	const buttonClasses = classnames([
		"she-menu__trigger",
		{ "she-menu__trigger--select": variant === "select" },
	]);

	const handleClickOutside = useCallback((e: MouseEvent) => {
		if (
			triggerRef.current &&
			!(e.target instanceof Node && triggerRef.current.contains(e.target))
		) {
			const timeoutId = window.setTimeout(() => {
				setOpen(false);
			}, 0);
			setTimeouts((timeouts) => [...timeouts, timeoutId]);
		}
	}, []);

	const handleKeyDownOutside = () => {
		if (open) {
			const timeoutId = window.setTimeout(() => {
				if (listRef.current && !listRef.current.contains(document.activeElement)) {
					setOpen(false);
				}
			}, 0);
			setTimeouts((timeouts) => [...timeouts, timeoutId]);
		}
	};

	const handleClickToggle = (event: React.MouseEvent) => {
		event.stopPropagation();
		setOpen(!open);
	};

	useEffect(() => {
		document.addEventListener("mouseup", handleClickOutside);
		return () => {
			document.removeEventListener("mouseup", handleClickOutside);
			timeouts.forEach((timeoutId) => window.clearTimeout(timeoutId));
		};
	}, [handleClickOutside, timeouts]);

	useEffect(() => {
		const main = document.querySelector("main");
		const list = listRef.current;
		if (main && list) {
			if (
				open &&
				list.getBoundingClientRect().height + list.getBoundingClientRect().top >
					main.getBoundingClientRect().height
			) {
				main.style.minHeight = `${
					list.getBoundingClientRect().height + list.getBoundingClientRect().top
				}px`;
			} else if (!open) {
				main.style.removeProperty("min-height");
			}
		}
		return () => {
			main && main.style.removeProperty("min-height");
		};
	}, [open]);

	return (
		<div className="she-menu">
			<button
				aria-label={accessibleButtonLabel}
				className={buttonClasses}
				onClick={(event) => handleClickToggle(event)}
				ref={triggerRef}
				type="button"
			>
				{trigger && <span className="she-menu__trigger__label">{trigger}</span>}
				<span className="she-menu__trigger__chevron">
					{icon ? icon : <DropdownChevron />}
				</span>
			</button>
			{open ? (
				<ul
					className="she-menu__list"
					onBlur={handleKeyDownOutside}
					ref={listRef}
					role="menu"
				>
					{children}
				</ul>
			) : null}
		</div>
	);
};
