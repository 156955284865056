import { useEffect, useRef } from "react";

export const useClicksWithinTime = (
	ref: React.RefObject<HTMLElement>,
	callback: () => void,
	numberOfClicks = 5,
	timeWindowInSeconds = 10,
): void => {
	const clickCount = useRef(0);
	const startTime = useRef<number | null>(null);
	const timeWindowInMilliseconds = timeWindowInSeconds * 1000;

	useEffect(() => {
		const element = ref.current;
		if (!element) return;

		const handleClick = () => {
			const currentTime = Date.now();

			if (
				clickCount.current === 0 ||
				(startTime.current !== null &&
					currentTime - startTime.current > timeWindowInMilliseconds)
			) {
				startTime.current = currentTime;
				clickCount.current = 1;
			} else {
				clickCount.current += 1;

				if (clickCount.current === numberOfClicks) {
					callback();
					clickCount.current = 0;
					startTime.current = null;
				}
			}
		};

		element.addEventListener("click", handleClick);

		return () => {
			element.removeEventListener("click", handleClick);
		};
	}, [ref, callback, numberOfClicks, timeWindowInMilliseconds]);
};
