import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { LoginButton } from "../../../../components/LoginButton";
import { SettingsButton } from "../SettingsButton";
import type { Portal } from "../../../../../models/portal";
import type { State } from "../../../../../state";

export const PageOptions = () => {
	const { customerKey, portalKey } = useParams<{
		customerKey: string;
		portalKey: string;
		formsTab: string;
	}>();

	const portal = useSelector<State, Portal | undefined>((s) =>
		s.portal.portals.find(
			(p) => p.key === portalKey.toLowerCase() && p.customerKey === customerKey.toLowerCase(),
		),
	);

	const loginButton = <LoginButton />;
	const userSettings = portal ? <SettingsButton /> : null;

	return (
		<div className="she-portal-page__title-bar-slot">
			{loginButton}
			{userSettings}
		</div>
	);
};
