import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { downloadSelectedQuestionnaires, updateTemplateSyncFlag } from "../../../../state";
import { Modal } from "../../../components/modals";
import { QuestionnaireStatus } from "../../../../models/portal";
import { TemplateListItem } from "./templatelist";
import type { QuestionnaireStub } from "../../../../models/portal";

interface Props {
	customerKey: string;
	portalKey: string;
	show: boolean;
	stubs: QuestionnaireStub[];
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const TemplateSyncContainer = ({ customerKey, portalKey, show, stubs }: Props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const toSync = stubs.filter((x) => x.makeAvailableOffline === true);
	const showDone =
		toSync.filter(
			(x) =>
				x.questionnaireStatus !== QuestionnaireStatus.Downloaded &&
				x.questionnaireStatus !== QuestionnaireStatus.Error,
		).length === 0;
	const showError =
		toSync.filter((x) => x.questionnaireStatus === QuestionnaireStatus.Error).length > 0 &&
		toSync.filter((x) => x.questionnaireStatus === QuestionnaireStatus.Downloading).length ===
			0;

	useEffect(() => {
		dispatch(downloadSelectedQuestionnaires(portalKey));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const doneClick = () => {
		dispatch(updateTemplateSyncFlag(portalKey, false));
	};

	const retryDownload = () => {
		dispatch(downloadSelectedQuestionnaires(portalKey));
	};

	return (
		<Modal fullScreenMobile show={show}>
			<div className="she-components-sync-info">
				<h2>
					{!showDone && !showError && t("display:labelSavingSelectedTemplates")}
					{showDone && !showError && t("display:labelSavedSelectedTemplates")}
					{showDone && showError && t("display:labelSavingTemplatesFailed")}
				</h2>
				<div className="she-components-sync-info-content">
					<ul>
						{!showError &&
							toSync.map((stub) => (
								<TemplateListItem
									key={stub.key}
									showError={showError}
									stub={stub}
								/>
							))}
						{showDone &&
							showError &&
							toSync
								.filter((s) => s.questionnaireStatus === QuestionnaireStatus.Error)
								.map((stub) => (
									<TemplateListItem
										key={stub.key}
										showError={showError}
										stub={stub}
									/>
								))}
					</ul>
					{showDone && showError && <p>{t("display:labelSaveLater")}</p>}
				</div>
				<div className="she-components-sync-info-footer">
					{showError && (
						<button
							className="she-btn she-btn-secondary"
							onClick={retryDownload}
							type="button"
						>
							{t("display:buttonRetry")}
						</button>
					)}
					{showDone && (
						<button
							className="she-btn she-btn-primary"
							onClick={doneClick}
							type="button"
						>
							{t("display:buttonDone")}
						</button>
					)}
				</div>
			</div>
		</Modal>
	);
};
