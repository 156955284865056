import { useRef } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useTranslation } from "react-i18next";
import { ReactComponent as DropdownChevron } from "../../../../assets/svg/arrow-down-1.svg";
import { PortalQuestionnaire } from "../PortalQuestionnaire";
import { SearchBox } from "../../../../components/input/SearchBox";
import type { QuestionnaireStub } from "../../../../../models/portal";
import "./PortalQuestionnaireList.styles.scss";

interface Props {
	questionnaireStubs: QuestionnaireStub[];
	displayLanguage: boolean;
	onQuestionnaireSelect: (key: string) => void;
	sortValue: string;
	onSortChange: (key: string) => void;
	onQuestionnaireSelectKeyboard: (questionnairKey: string, keyPressed: string) => void;
	onRemoveQuestionnaire: (
		questionnaireKey: string,
		contextMenu: HTMLButtonElement | null,
	) => void;
	onDownloadQuestionnaire: (questionnaireKey: string) => void;
	highlightRecord: string;
	onSearchTermChange: (searchTerm: string) => void;
	searchTerm: string;
	emptyListMessage?: string | null;
}

export const PortalQuestionnaireListPresentation = ({
	displayLanguage,
	highlightRecord,
	onDownloadQuestionnaire,
	onQuestionnaireSelect,
	onQuestionnaireSelectKeyboard,
	onRemoveQuestionnaire,
	onSearchTermChange,
	onSortChange,
	questionnaireStubs,
	searchTerm,
	sortValue,
	emptyListMessage,
}: Props) => {
	const { t } = useTranslation();
	const searchBoxRef = useRef<HTMLInputElement>(null);

	return (
		<div>
			<div className="she-questionnaire-list__search">
				<SearchBox
					onSearchBegan={() => {}}
					onSearchEnd={() => {}}
					onSearchTermChange={onSearchTermChange}
					reference={searchBoxRef}
					searchTerm={searchTerm}
				/>
			</div>
			<div className="she-questionnaire-list__sort-wrapper">
				<div className="she-questionnaire-list__sort-wrapper__header">
					{t("display:labelNewFormHeader")}
				</div>
				<div className="she-components-dropdown-inline she-questionnaire-list-sort-container">
					<label htmlFor="selectSort">{t("display:labelSortBy")}</label>
					<div className="she-components-dropdown she-questionnaire-list-sort-dropdown">
						<select
							id="selectSort"
							onChange={(e) => onSortChange(e.target.value)}
							value={sortValue}
						>
							<option value="default">{t("display:optionSortDefault")}</option>
							<option value="az">{t("display:optionSortAZ")}</option>
							<option value="za">{t("display:optionSortZA")}</option>
						</select>
						<div className="she-components-control-icon">
							<DropdownChevron />
						</div>
					</div>
				</div>
			</div>
			{emptyListMessage && (
				<p className="she-questionnaire-list__empty">{emptyListMessage}</p>
			)}
			<section>
				<ul className="she-questionnaire-list">
					<TransitionGroup component={null}>
						{questionnaireStubs.map((questionnaireStub) => {
							return (
								<CSSTransition
									appear
									classNames="fade"
									component={null}
									exit={false}
									key={questionnaireStub.key}
									timeout={500}
								>
									<PortalQuestionnaire
										displayLanguage={displayLanguage}
										highlight={highlightRecord === questionnaireStub.key}
										onDownloadQuestionnaire={onDownloadQuestionnaire}
										onQuestionnaireSelect={onQuestionnaireSelect}
										onQuestionnaireSelectKeyboard={
											onQuestionnaireSelectKeyboard
										}
										onRemoveQuestionnaire={onRemoveQuestionnaire}
										questionnaireStub={questionnaireStub}
									/>
								</CSSTransition>
							);
						})}
					</TransitionGroup>
				</ul>
			</section>
		</div>
	);
};
