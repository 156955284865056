import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export function useDetailedSubmitError() {
	const { t } = useTranslation();

	return useCallback(
		(error: any): string => {
			const detailedError = (error: any): string => {
				if (Array.isArray(error)) {
					return detailedError(error[0]);
				}
				// Handle xss error
				if (error && error.toString().includes("xss-error")) {
					return t("display:labelSubmitXssErrorMessage");
				}
				// Handle error by code
				if (error && error.data && error.data.data && Array.isArray(error.data.data)) {
					const data = error.data.data[0];
					if (data.Code && data.Details && Array.isArray(data.Details)) {
						// Single attachment size limit reached
						if (data.Code === "PortalAttachmentLimit") {
							const file = data.Details.find((d: any) => d.Key === "AttachmentId");
							const maxSize = data.Details.find(
								(d: any) => d.Key === "AttachmentLimit",
							);
							return t("error:submitPortalAttachmentSizeLimit", {
								size:
									maxSize &&
									maxSize.Value &&
									`${
										maxSize.Value < 1024
											? `${maxSize.Value} Kb`
											: `${(maxSize.Value / 1024).toFixed(2)} Mb`
									}`,
								file: file && file.Value,
							});
						}
					}
				}
				return t("display:labelSubmitErrorMessage");
			};

			return detailedError(error);
		},
		[t],
	);
}
