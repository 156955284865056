import { useMemo, useState, useEffect } from "react";
import { NumberFieldValidator } from "../../../../validators/numberFieldValidator";
import { UpdatableFieldProperty } from "../../../../state/components/questionnaire/actions/enums";
import { BehaviouralFieldValidator } from "../../../../validators/behaviouralFieldValidator/behaviouralFieldValidator";
import { BehaviouralInputPresentation } from "./BehaviouralInput.presentation";
import type { BehaviouralField } from "../../../../models/fields/BehaviouralField";

interface Props {
	behaviouralField: BehaviouralField;
	updateFieldProperty: (
		fieldId: number,
		value: any,
		propertyName: UpdatableFieldProperty,
	) => void;
	updateValidationStatus: (fieldId: number, value: string[]) => void;
}

export const BehaviouralInput = ({
	behaviouralField,
	updateFieldProperty,
	updateValidationStatus,
}: Props) => {
	const safeNumberValidator = useMemo(
		() => new NumberFieldValidator(behaviouralField.safeField),
		[behaviouralField.safeField],
	);
	const unSafeNumberValidator = useMemo(
		() => new NumberFieldValidator(behaviouralField.unSafeField),
		[behaviouralField.unSafeField],
	);
	const [statesSafeValue, setStatesSafeValue] = useState(behaviouralField.safeField.value);
	const [statesUnSafeValue, setStatesUnSafeValue] = useState(behaviouralField.unSafeField.value);
	const [statesSafeUnSafeMsgs, setStatesSafeUnSafeMsgs] = useState(
		behaviouralField.validationMessage,
	);
	const [statesSafeComment, setStatesSafeComment] = useState(behaviouralField.safeComment);
	const [statesUnsafeComment, setStatesUnsafeComment] = useState(behaviouralField.unSafeComment);

	const updateSafe = (value: any) => {
		setStatesSafeValue(value);
		updateFieldProperty(
			behaviouralField.id,
			value,
			UpdatableFieldProperty.BehaviouralSafeValue,
		);
	};

	const updateSafeComment = (value: string) => {
		setStatesSafeComment(value);
	};

	const updateUnSafe = (value: any) => {
		setStatesUnSafeValue(value);
		updateFieldProperty(
			behaviouralField.id,
			value,
			UpdatableFieldProperty.BehaviouralUnSafeValue,
		);
	};

	const updateUnSafeComment = (value: string) => {
		setStatesUnsafeComment(value);
	};

	const updateSafeUnSafeValidationStatus = (messages: string[]) => {
		setStatesSafeUnSafeMsgs(messages);
	};

	const saveSafeComment = () => {
		updateFieldProperty(
			behaviouralField.id,
			statesSafeComment,
			UpdatableFieldProperty.BehaviouralSafeComment,
		);
	};

	const saveUnSafeComment = () => {
		updateFieldProperty(
			behaviouralField.id,
			statesUnsafeComment,
			UpdatableFieldProperty.BehaviouralUnSafeComment,
		);
	};

	useEffect(() => {
		if (
			statesSafeUnSafeMsgs !== null &&
			statesSafeUnSafeMsgs !== undefined &&
			statesSafeUnSafeMsgs.length === 0
		) {
			const behaviouralValidator = new BehaviouralFieldValidator(behaviouralField);
			if (
				!behaviouralValidator.isFieldValid(
					statesSafeValue !== null && statesSafeValue !== undefined
						? statesSafeValue.toString()
						: "",
					statesUnSafeValue !== null && statesUnSafeValue !== undefined
						? statesUnSafeValue.toString()
						: "",
				)
			) {
				return;
			}
		}

		updateValidationStatus(behaviouralField.id, statesSafeUnSafeMsgs);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [statesSafeValue, statesUnSafeValue, statesSafeUnSafeMsgs]);

	return (
		<BehaviouralInputPresentation
			guid={behaviouralField.guid}
			isMandatory={behaviouralField.isMandatory}
			isNotApplicable={behaviouralField.isNotApplicable}
			safeComment={statesSafeComment}
			safeField={behaviouralField.safeField}
			safeNumberValidator={safeNumberValidator}
			saveSafeComment={saveSafeComment}
			saveUnSafeComment={saveUnSafeComment}
			unSafeComment={statesUnsafeComment}
			unSafeField={behaviouralField.unSafeField}
			unSafeNumberValidator={unSafeNumberValidator}
			updateSafe={updateSafe}
			updateSafeComment={updateSafeComment}
			updateSafeUnSafeValidationStatus={updateSafeUnSafeValidationStatus}
			updateUnSafe={updateUnSafe}
			updateUnSafeComment={updateUnSafeComment}
		/>
	);
};
