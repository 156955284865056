import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "../Modal";
import { ModalFooter } from "../ModalFooter";

interface Props {
	show: boolean;
	text?: string;
	textExtraClass?: string;
	centredBody?: boolean;
	paddedFooter?: boolean;
	confirmText?: string;
	cancelText?: string;
	confirmBtnIsPrimary?: boolean;
	confirmBtnIsDanger?: boolean;
	cancelBtnIsPrimary?: boolean;
	onConfirm: () => void;
	onCancel: () => void;
	children?: React.ReactNode;
}

export const ConfirmationModal = ({
	onCancel,
	onConfirm,
	show,
	cancelBtnIsPrimary,
	cancelText,
	centredBody,
	children,
	confirmBtnIsDanger,
	confirmBtnIsPrimary,
	confirmText,
	paddedFooter,
	text,
	textExtraClass,
}: Props) => {
	const { t } = useTranslation();

	const bodyClassName = useMemo(() => {
		let bodyClassName = "she-components-modal-body-padded";
		if (centredBody) {
			bodyClassName = `${bodyClassName} she-components-modal-body-centred`;
		}
		return bodyClassName;
	}, [centredBody]);

	const confirmTextMemo = useMemo(() => confirmText ?? t("global:yes"), [confirmText, t]);
	const cancelTextMemo = useMemo(() => cancelText ?? t("global:no"), [cancelText, t]);

	return (
		<Modal onClose={onCancel} show={show}>
			{text && (
				<p
					className={
						textExtraClass ? `${bodyClassName} ${textExtraClass}` : bodyClassName
					}
				>
					{text}
				</p>
			)}
			{children && <p className={bodyClassName}>{children}</p>}
			<ModalFooter
				buttons={[
					{
						text: cancelTextMemo,
						primary: cancelBtnIsPrimary !== undefined ? cancelBtnIsPrimary : true,
						onClick: onCancel,
					},
					{
						text: confirmTextMemo,
						primary: confirmBtnIsPrimary !== undefined ? confirmBtnIsPrimary : false,
						danger: confirmBtnIsDanger !== undefined ? confirmBtnIsDanger : true,
						onClick: onConfirm,
					},
				]}
				padded={paddedFooter}
			/>
		</Modal>
	);
};
