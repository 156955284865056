import { useTranslation } from "react-i18next";
import moment from "moment";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ActionCommentStatus, ActionStateAtTimeOfComment } from "../../../../../../models/action";
import { getPortalDateFormat } from "../../../../../../helpers/DateFormatHelper";
import type { State } from "../../../../../../state";
import type { Portal } from "../../../../../../models/portal";
import type { ActionComment, ResponseActionComment } from "../../../../../../models/action";

export const useFormatActionComment = () => {
	const { t } = useTranslation();

	const { portalKey, customerKey } = useParams<{ portalKey: string; customerKey: string }>();

	const portal = useSelector<State, Portal | undefined>((s) =>
		s.portal.portals.find(
			(p) => p.key === portalKey.toLowerCase() && p.customerKey === customerKey.toLowerCase(),
		),
	);

	const formatDate = (date: string, includeTime = false) =>
		moment(date).format(`${getPortalDateFormat(portal?.dateFormat, includeTime)}`);

	return (comment: ResponseActionComment): ActionComment => {
		const actionStatus: Record<string, string> = {
			NotStarted: t("display:myTasks.labelStatusNotStarted"),
			InProgress: t("display:myTasks.labelStatusInProgress"),
			Completed: t("display:myTasks.labelStatusCompleted"),
		};

		const commentStatus: Record<string, string> = {
			Active: t("display:myTasks.labelCommentStatusActive"),
			Deleted: t("display:myTasks.labelCommentStatusDeleted"),
			Edited: t("display:myTasks.labelCommentStatusEdited"),
		};

		const formattedComment: ActionComment = {
			actionStatus: {
				key: ActionStateAtTimeOfComment[comment.ActionStateAtTimeOfComment],
				label: actionStatus[ActionStateAtTimeOfComment[comment.ActionStateAtTimeOfComment]],
				value: comment.ActionStateAtTimeOfComment,
			},
			id: comment.Id,
			content: comment.Content,
			createdDate: {
				formattedNoTime: formatDate(comment.CreatedDate),
				formatted: formatDate(comment.CreatedDate, true),
				unformatted: comment.CreatedDate,
			},
			latestDate: {
				formattedNoTime: formatDate(comment.LatestDate),
				formatted: formatDate(comment.LatestDate, true),
				unformatted: comment.LatestDate,
			},
			owner: {
				name: comment.Owner.FullName,
				id: comment.Owner.UserId,
			},
			status: {
				key: ActionCommentStatus[comment.Status],
				label: commentStatus[ActionCommentStatus[comment.Status]],
				value: comment.Status,
			},
			userCanDelete: comment.UserCanDelete,
			userCanEdit: comment.UserCanEdit,
		};

		if (comment.LastEditedBy?.FullName) {
			formattedComment.editedBy = comment.LastEditedBy.FullName;
		}

		if (comment.DeletedBy?.FullName) {
			formattedComment.deletedBy = comment.DeletedBy.FullName;
		}

		return formattedComment;
	};
};
