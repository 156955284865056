import { useTranslation } from "react-i18next";
import { FormModal } from "../../modals";
import { QuestionHeader } from "../../generic/QuestionHeader";
import { TextInputContainer } from "../TextInput/TextInput.container";
import { SignatureInputContainer } from "../SignatureInput/SignatureInput.container";
import { Base64Image } from "../../generic/Base64Image";
import { ReactComponent as Bin } from "../../../assets/svg/bin-1.svg";
import { AnimationWrapper } from "../../animation/AnimationWrapper";
import { AnimationSlideFade } from "../../animation/AnimationSlideFade";
import type { SignatureFieldValidator } from "../../../../validators/signatureFieldValidator";
import type { TextFieldValidator } from "../../../../validators/textFieldValidator";
import type { IQSignatureField } from "../../../../models/fields/IQSignatureField";

interface Props {
	showModal: boolean;
	handleAddSignatureButton: () => void;
	iQSignatureField: IQSignatureField;
	updateFullName: (value: any) => void;
	updateFullNameValidationStatus: (value: string[]) => void;
	fullNameValidator: TextFieldValidator;
	updateDescription: (value: any) => void;
	updateDescriptionValidationStatus: (value: string[]) => void;
	descriptionValidator: TextFieldValidator;
	updateSignature: (value: any) => void;
	updateSignatureValidationStatus: (value: string[]) => void;
	signatureValidator: SignatureFieldValidator;
	saveSignature: () => void;
	cancelSignature: () => void;
	removeSignature: () => void;
	fullNameValMessages: string[];
	descriptionValMessages: string[];
	signatureValMessages: string[];
	addButtonVisible: boolean;
	editSignature: () => void;
	onKeyPress: (key: string) => void;
	isEdit: boolean;
}

export const IQSignatureInputPresentation = ({
	addButtonVisible,
	cancelSignature,
	descriptionValMessages,
	descriptionValidator,
	editSignature,
	fullNameValMessages,
	fullNameValidator,
	handleAddSignatureButton,
	iQSignatureField,
	isEdit,
	onKeyPress,
	removeSignature,
	saveSignature,
	showModal,
	signatureValMessages,
	signatureValidator,
	updateDescription,
	updateDescriptionValidationStatus,
	updateFullName,
	updateFullNameValidationStatus,
	updateSignature,
	updateSignatureValidationStatus,
}: Props) => {
	const { t } = useTranslation();

	return (
		<>
			<FormModal
				onCancel={() => {
					cancelSignature();
				}}
				onOk={() => {
					saveSignature();
				}}
				show={showModal}
				title="Signature"
			>
				<div
					className={`she-components-control ${
						fullNameValMessages && fullNameValMessages.length > 0
							? "she-components-validation-border-left"
							: ""
					}`}
				>
					<QuestionHeader
						guid={iQSignatureField.fullname.guid}
						guidance={undefined}
						guidanceIsPopup={false}
						mediaGuidance={undefined}
						required={iQSignatureField.fullname.isMandatory}
						showGuidance={false}
						title={t("display:labelFullName")}
						validationErrors={fullNameValMessages}
					/>
					<TextInputContainer
						textField={iQSignatureField.fullname}
						updateField={updateFullName}
						updateValidationStatus={updateFullNameValidationStatus}
						validator={fullNameValidator}
					/>
				</div>

				<div
					className={`she-components-control ${
						descriptionValMessages && descriptionValMessages.length > 0
							? "she-components-validation-border-left"
							: ""
					}`}
				>
					<QuestionHeader
						guid={iQSignatureField.description.guid}
						guidance={undefined}
						guidanceIsPopup={false}
						mediaGuidance={undefined}
						required={false}
						showGuidance={false}
						title={t("display:labelDescription")}
						validationErrors={descriptionValMessages}
					/>
					<TextInputContainer
						textField={iQSignatureField.description}
						updateField={updateDescription}
						updateValidationStatus={updateDescriptionValidationStatus}
						validator={descriptionValidator}
					/>
				</div>

				<div
					className={`she-components-control ${
						signatureValMessages && signatureValMessages.length > 0
							? "she-components-validation-border-left"
							: ""
					}`}
				>
					<QuestionHeader
						guid={iQSignatureField.signature.guid}
						guidance={undefined}
						guidanceIsPopup={false}
						mediaGuidance={undefined}
						required={iQSignatureField.signature.isMandatory}
						showGuidance={false}
						title={t("display:labelSignature")}
						validationErrors={signatureValMessages}
					/>

					{isEdit && iQSignatureField.signature.value ? (
						<div>
							<Base64Image
								altString={t("display:labelSignature")}
								className="she-components-signature-display"
								value={
									iQSignatureField.signature
										? iQSignatureField.signature.value
										: ""
								}
							/>
						</div>
					) : (
						<SignatureInputContainer
							signatureField={iQSignatureField.signature}
							updateField={updateSignature}
							updateValidationStatus={updateSignatureValidationStatus}
							validateOnEnd
							validator={signatureValidator}
						/>
					)}
				</div>
			</FormModal>

			{(iQSignatureField.fullname.value ||
				iQSignatureField.signature.value ||
				iQSignatureField.description.value) && (
				<div
					className="she-components-submodule-tile"
					onKeyPress={(event) => {
						onKeyPress(event.key);
					}}
					tabIndex={0}
				>
					<dl onClick={editSignature}>
						<dt>{t("display:labelFullName")}</dt>
						<dd>{iQSignatureField.fullname.value}</dd>
						<dt>{t("display:labelDescription")}</dt>
						<dd>{iQSignatureField.description.value}</dd>
						<dt>{t("display:labelSignature")}</dt>
						<dd>
							<Base64Image
								altString={t("display:labelSignature")}
								className="she-components-signature-display"
								value={iQSignatureField.signature.value}
							/>
						</dd>
					</dl>
					<div className="she-components-submodule-remove">
						{addButtonVisible && (
							<button
								aria-label={t("display:ariaLabelRemoveSubmoduleRecord")}
								className="she-icon"
								onClick={() => {
									removeSignature();
								}}
								onKeyDown={(e) => {
									e.stopPropagation();
									if (e.key === "Enter" || e.key === " ") {
										removeSignature();
									}
								}}
								type="button"
							>
								<Bin />
							</button>
						)}
					</div>
				</div>
			)}

			<AnimationWrapper>
				{!iQSignatureField.fullname.value &&
					!iQSignatureField.signature.value &&
					addButtonVisible === true && (
						<AnimationSlideFade key={1}>
							<button
								className="she-btn she-btn-tertiary"
								onClick={() => {
									handleAddSignatureButton();
								}}
								type="button"
							>
								{t("buttonAddSignature")}
							</button>
						</AnimationSlideFade>
					)}
			</AnimationWrapper>
		</>
	);
};
