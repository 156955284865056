import TextareaAutosize from "react-textarea-autosize";

export interface TextBoxProps {
	reference?: React.RefObject<HTMLInputElement>;

	value: string;
	onChange: (value: string) => void;
	onFocus?: (value: string) => void;
	onBlur?: (value: string) => void;

	large?: boolean;

	id?: string;
	extraClassName?: string;
	placeholder?: string;
	maxLength?: number;
	disabled?: boolean;
	invalid?: boolean;
	label?: string;
}

export const TextBox = ({
	onChange,
	value,
	disabled,
	extraClassName,
	id,
	invalid,
	label,
	large,
	maxLength,
	onBlur,
	onFocus,
	placeholder,
	reference,
}: TextBoxProps) => {
	if (large) {
		return (
			<TextareaAutosize
				aria-invalid={invalid}
				aria-label={label}
				className={`she-components-text-input ${extraClassName}`}
				disabled={disabled}
				id={id}
				maxLength={maxLength}
				onBlur={(e) => onBlur?.(e.target.value)}
				onChange={(e) => onChange(e.target.value)}
				onFocus={(e) => onFocus?.(e.target.value)}
				placeholder={placeholder}
				value={value}
			/>
		);
	}

	return (
		<input
			aria-invalid={invalid}
			aria-label={label}
			className={`she-components-text-input ${extraClassName}`}
			disabled={disabled}
			id={id}
			maxLength={maxLength}
			onBlur={(e) => onBlur?.(e.target.value)}
			onChange={(e) => onChange(e.target.value)}
			onFocus={(e) => onFocus?.(e.target.value)}
			placeholder={placeholder}
			ref={reference}
			type="text"
			value={value}
		/>
	);
};
