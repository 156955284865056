import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { switchMatchingLanguageCode } from "../../../../helpers/LanguageCodeHelper";
import { DefaultDictionaryService } from "../../../../services/dictionary";
import { CancelablePromise } from "../../../../services/utilities/CancelablePromise";
import type { ActionDictionaries } from "../../../../models/action";

export const useGetActionDictionaryQuery = () => {
	const service = useMemo(
		() =>
			new DefaultDictionaryService({
				subdomain: "Dictionary",
			}),
		[],
	);
	const { i18n } = useTranslation();
	const language = switchMatchingLanguageCode(i18n.language);

	const actionDictionariesQuery = useQuery(
		["ActionsDictionary", language],
		() => {
			const cancelTokenSource = axios.CancelToken.source();
			const getActionDictionary = new CancelablePromise<ActionDictionaries>(
				(resolve, reject) =>
					service
						.getActionDictionaries(language)
						.then((r) => resolve(r))
						.catch((e) => reject(e)),
			);
			getActionDictionary.cancel = () => {
				cancelTokenSource.cancel();
			};
			return getActionDictionary;
		},
		{},
	);

	return {
		actionDictionariesQuery,
		priorityOptions: actionDictionariesQuery.data?.priorityDictionary,
		categoryOptions: actionDictionariesQuery.data?.categoryDictionary,
		subCategoryOptions: actionDictionariesQuery.data?.subCategoryDictionary,
	};
};
