import { PPECategoryComponent } from "./PPECategory.component";
import type { PPECategoryValue, PPEField } from "../../../../models/fields/PPEField";

interface Props {
	field: PPEField;
	updateField: (fieldId: number, value: any) => void;
	updateValidationStatus: (fieldId: number, value: string[]) => void;
}

// TODO: why are we taking in updateValidationStatus if were not using it??
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const PPEInput = ({ field, updateField, updateValidationStatus }: Props) => {
	const updateCategoryValue = (value: PPECategoryValue) => {
		let newSelectedValues: PPECategoryValue[] = [];
		if (Array.isArray(field.value)) {
			newSelectedValues = field.value.filter((v) => v.id !== value.id);
		}
		if (value.selected) {
			newSelectedValues.push(value);
		}
		updateField(field.id, newSelectedValues);
	};

	return (
		<>
			{field.categories.map((c) => (
				<PPECategoryComponent
					category={c}
					key={c.id}
					selectedValue={
						(field.value &&
							Array.isArray(field.value) &&
							field.value.find((v) => v.id === c.id)) ||
						undefined
					}
					updateCategoryValue={updateCategoryValue}
				/>
			))}
		</>
	);
};
