import { BaseService } from "../../../baseService";
import { get } from "../../../utilities/http";
import type { IHealthService } from "../../interface";

export class DefaultHealthService extends BaseService implements IHealthService {
	public async checkAPIconnection(timeout: number) {
		return await get(await this.getEndpointUrl(), {
			timeout,
			headers: this.getTrackerIdHeaders(),
		})
			.then(() => {
				return true;
			})
			.catch(() => {
				return false;
			});
	}
}
