export interface RegisterEntities {
	list: RegisterEntity[];
	sortProperties: RegisterEntityProperty[];
	userCurrentLanguage: string;
	totalDataCount: number;
}

export enum RegisterEntitySortOrder {
	Asc = "Asc",
	Desc = "Desc",
}

export interface RegisterEntity {
	id: number;
	info: RegisterEntityPropertyInfo[];
}

export interface RegisterEntityPropertyInfo {
	title: string;
	value: string;
	propertyName: string;
}

export interface RegisterEntityProperty {
	propertyName: string;
	caption: string;
}
