import { useCallback, useMemo } from "react";
import Media from "react-media";
import { useSelector } from "react-redux";
import { AnimationSlideFade } from "../../animation/AnimationSlideFade";
import { AnimationWrapper } from "../../animation/AnimationWrapper";
import { Checkbox } from "../../Checkbox";
import { PPEIconContainer } from "../../PPEIcon/PPEIconContainer.component";
import { ReactComponent as DropdownChevron } from "../../../assets/svg/arrow-down-1.svg";
import { LocalFieldIds } from "../../../../models/questionnaire";
import styles from "./PPE.module.scss";
import type { State } from "../../../../state";
import type { PPECategory, PPECategoryValue, PPEOption } from "../../../../models/fields/PPEField";
import type { OrgUnitField } from "../../../../models/fields/OrgUnitField";
import "./PPE.styles.scss";

interface Props {
	category: PPECategory;
	updateCategoryValue: (value: PPECategoryValue) => void;
	selectedValue?: PPECategoryValue;
}

export const PPECategoryComponent = ({ category, updateCategoryValue, selectedValue }: Props) => {
	const checked = useMemo(
		() => (selectedValue && selectedValue.selected) || false,
		[selectedValue],
	);

	const orgUnitFieldValue = useSelector<State, number | undefined>((state) => {
		const orgUnitField = state.questionnaire.fields.find(
			(f) => f.id === LocalFieldIds.OrgUnit,
		) as OrgUnitField;
		if (orgUnitField) {
			return orgUnitField.value;
		}
	});

	const orgUnitFilteredOptions: PPEOption[] = useMemo(() => {
		return category.options.filter(
			(option) =>
				option.orgUnitIds.length === 0 ||
				(orgUnitFieldValue && option.orgUnitIds.includes(orgUnitFieldValue)),
		);
	}, [orgUnitFieldValue, category.options]);

	const handleCheckboxOnChange = () => {
		updateCategoryValue({
			id: category.id,
			selected: !checked,
			iconId: category.iconId,
			name: category.name,
		});
	};

	const handleOnChange = useCallback(
		(event: React.ChangeEvent<HTMLSelectElement>) => {
			const selectedValue =
				event.target.value === "-1" ? undefined : Number(event.target.value);
			updateCategoryValue({
				id: category.id,
				selected: checked,
				option: category.options.find((o) => o.id === selectedValue),
				iconId: category.iconId,
				name: category.name,
			});
		},
		[
			category.iconId,
			category.id,
			category.name,
			category.options,
			checked,
			updateCategoryValue,
		],
	);

	const OptionSelect = useMemo(() => {
		const Component = () => {
			return (
				<AnimationWrapper>
					{checked && orgUnitFilteredOptions.length > 0 && (
						<AnimationSlideFade key={category.id}>
							<div className="she-components-dropdown">
								<select
									aria-label={category.name}
									onChange={handleOnChange}
									value={
										(selectedValue &&
											selectedValue.option &&
											selectedValue.option.id) ||
										-1
									}
								>
									<option value="-1" />
									{orgUnitFilteredOptions.map((o) => (
										<option key={o.id} value={o.id}>
											{o.name}
										</option>
									))}
								</select>
								<div className="she-components-control-icon">
									<DropdownChevron />
								</div>
							</div>
						</AnimationSlideFade>
					)}
				</AnimationWrapper>
			);
		};
		return Component;
	}, [
		category.id,
		category.name,
		orgUnitFilteredOptions,
		checked,
		handleOnChange,
		selectedValue,
	]);

	return (
		<div className="she-ppe-input">
			<div className="she-ppe-input__controls">
				<div className="she-ppe-input_container">
					<Checkbox
						checked={checked}
						id={`${category.id}_notApplicableCheckbox`}
						name={category.name}
						onChange={handleCheckboxOnChange}
						value={0}
					/>
					<Media query={`(min-width: ${styles.breakpointS})`}>
						{(matches) => matches && <OptionSelect />}
					</Media>
				</div>
				<div className="she-ppe-input__icon">
					<PPEIconContainer
						iconId={category.iconId}
						onClick={handleCheckboxOnChange}
						selected={!!selectedValue && selectedValue.selected}
					/>
				</div>
			</div>
			<Media query={`(max-width: ${styles.breakpointS1})`}>
				{(matches) => matches && <OptionSelect />}
			</Media>
		</div>
	);
};
