import { useTranslation } from "react-i18next";
import { AnimationWrapper } from "../../../../../components/animation/AnimationWrapper";
import { AnimationSlideFade } from "../../../../../components/animation/AnimationSlideFade";
import { PPESubmoduleCard } from "./PPESubmoduleCard.component";
import type { SubModule, SubModuleRecord } from "../../../../../../models/questionnaire";
import "./PPE.styles.scss";

interface Props {
	submodule: SubModule;
	records?: SubModuleRecord[];
	addRecord: () => void;
	remove: (recordId: string) => void;
	edit: (record: SubModuleRecord) => void;
	emptyListMessage?: string;
	hasValidationErrors: boolean;
	validationErrors: string[];
}

export const PPESubmodule = ({
	addRecord,
	edit,
	hasValidationErrors,
	remove,
	submodule,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	validationErrors,
	emptyListMessage,
	records,
}: Props) => {
	const { t } = useTranslation();

	return (
		<div
			className={`she-components-submodule ${
				hasValidationErrors ? "she-components-validation-border-left" : ""
			}`}
		>
			<h1 className="she-components-questionnaire-group-header">{submodule.name}</h1>

			<div className="she-components-submodule-container">
				<label>{submodule.name}</label>
				{emptyListMessage && records && !records.length && (
					<div className="she-components-submodule-list-empty">{emptyListMessage}</div>
				)}
				<AnimationWrapper>
					{records?.map((rec) => {
						return (
							<AnimationSlideFade key={rec.localId}>
								<PPESubmoduleCard
									edit={edit}
									record={rec}
									remove={remove}
									submodule={submodule}
								/>
							</AnimationSlideFade>
						);
					})}
				</AnimationWrapper>
				<div>
					{(!records || records.length === 0) && (
						<button
							className="she-btn she-btn-tertiary"
							onClick={() => {
								addRecord();
							}}
							type="button"
						>
							{t("display:buttonAddSubmoduleName", {
								submodule: submodule.singleRecordName || submodule.name,
							})}
						</button>
					)}
				</div>
			</div>
		</div>
	);
};
