import type { MatrixFieldService } from "../../interface";
import type { Matrix } from "../../../../models/matrix/matrix";
import type { MatrixFactor } from "../../../../models/matrix/matrixFactor";

export class DefaultMatrixFieldService implements MatrixFieldService {
	getMatrixEdgeValuesList(
		matrix: Matrix | undefined,
		direction: number,
	): { key: string; value: string }[] {
		const values: { key: string; value: string }[] = [];
		if (matrix) {
			const edges = matrix.edges.filter((e) => e.direction === direction);

			edges.forEach((e) => {
				values.push({ key: e.count.toString(), value: e.caption });
			});
		}

		return values;
	}

	getMatrixFactorId(
		edgeXCount: number,
		edgeYCount: number,
		matrix: Matrix | undefined,
	): number | undefined {
		if (edgeXCount < 1 || edgeYCount < 1) {
			return undefined;
		}

		if (matrix) {
			const factorCount = (edgeYCount - 1) * matrix.dimensionX + edgeXCount;
			const factor = matrix.factors.find((f) => f.count === factorCount);
			if (factor) {
				return factor.id;
			}
		}

		return undefined;
	}

	getMatrixEdgesValues(
		value: number | undefined,
		matrix: Matrix | undefined,
	): { edgeXCount: number; edgeYCount: number } {
		// // X = edge direction 0, Y = edge direction 1
		if (matrix) {
			const factor: MatrixFactor | undefined = this.getFactor(value, matrix);
			if (factor) {
				let valueX = factor.count % matrix.dimensionX;
				if (valueX === 0) {
					valueX = matrix.dimensionX;
				}
				const updatedCount = factor.count - valueX;
				const valueY = updatedCount / matrix.dimensionY + 1;
				return { edgeXCount: valueX, edgeYCount: valueY };
			}
		}
		return { edgeXCount: -1, edgeYCount: -1 };
	}

	getFactor(value: number | undefined, matrix: Matrix | undefined): MatrixFactor | undefined {
		return value && matrix ? matrix.factors.find((f) => f.id === value) : undefined;
	}
}
