import { useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { usePortalPath } from "../../../../../../helpers/UsePortalPath";
import { DefaultActionsService } from "../../../../../../services/actions";
import { Spinner } from "../../../../../components/generic/Spinner";
import { FormModal } from "../../../../../components/modals";
import { IllustratedThemedModal } from "../../../../../components/modals/IllustratedThemedModal";
import { useActionComments } from "../hooks/useActionComments";
import { ReactComponent as ErrorIllustration } from "../../../../../assets/svg/illustrations/falling.svg";
import { switchMatchingLanguageCode } from "../../../../../../helpers/LanguageCodeHelper";
import { ActionCommentsCard } from "./ActionCommentsCard";
import { ActionCommentsViewEdit } from "./ActionCommentsViewEdit";

export const ActionCommentsModal = () => {
	const portalPath = usePortalPath();
	const { t } = useTranslation();
	const history = useHistory();
	const { action, actionId, commentId } = useParams<{
		action: string;
		actionId: string;
		commentId: string;
	}>();
	const [isSubmitError, setIsSubmitError] = useState(false);

	const isEditCommentRoute = !!useRouteMatch(
		"/:customerKey/p/:portalKey/actions/:action/:actionId/comments/edit/:commentId?",
	);

	const isRouteMatch =
		!!useRouteMatch(
			"/:customerKey/p/:portalKey/actions/:action/:actionId/comments/:commentId?",
		) || isEditCommentRoute;

	const { comments, isLoading, refetch } = useActionComments(Number(actionId));

	const basePreviousUrl = `${portalPath}actions/${action}/${actionId}`;
	const routerState: { previousPath?: string } | null = history.location.state || null;

	const { i18n } = useTranslation();

	const onCancel = () => {
		if (isEditCommentRoute) {
			history.push(`${basePreviousUrl}/comments/${commentId}`);
			return;
		}

		history.push(routerState?.previousPath ?? basePreviousUrl + (commentId ? "/comments" : ""));
	};

	const viewableComment = commentId
		? comments.find((comment) => JSON.stringify(comment.id) === commentId)
		: null;

	const { control, handleSubmit, formState, ...methods } = useForm({
		mode: "onBlur",
		criteriaMode: "all",
		defaultValues: {
			NoteComment: viewableComment?.content,
		},
	});

	const onOk = () => {
		if (commentId) {
			if (isEditCommentRoute) {
				if (viewableComment) {
					const updateDTO = {
						NoteComment: `${control.getValues().NoteComment as string}`,
						Id: viewableComment.id,
					};
					const language = switchMatchingLanguageCode(i18n.language);

					actionsService
						.updateActionComment(updateDTO, language)
						.then(() => {
							refetch?.();
							setIsSubmitError(false);
							history.goBack();
						})
						.catch(() => {
							setIsSubmitError(true);
						});
				}
			} else {
				history.push({
					pathname: `${portalPath}actions/${action}/${actionId}/comments/edit/${commentId}`,
					state: routerState,
				});
			}
		}
	};

	const contentToRender = useMemo(() => {
		if (!comments.length) {
			return null;
		}

		if (commentId) {
			if (!viewableComment) {
				return null;
			}

			return <ActionCommentsViewEdit comment={viewableComment} />;
		}

		return (
			<>
				{comments.map((comment) => (
					<ActionCommentsCard key={comment.id} {...comment} />
				))}
			</>
		);
	}, [comments, commentId]);

	const getModalTitle = () => {
		if (isEditCommentRoute) {
			return t("display:myTasks.labelCommentEdit");
		}

		if (commentId) {
			return t("display:myTasks.labelCommentView");
		}

		return t("display:myTasks.labelCommentHistory", { count: comments.length });
	};

	const actionsService = useMemo(
		() =>
			new DefaultActionsService({
				subdomain: "Action",
			}),
		[],
	);

	return (
		<FormModal
			cancelText={t(isEditCommentRoute ? "global:cancel" : "global:close")}
			large
			okText={isEditCommentRoute ? t(`display:buttonSave`) : t(`display:buttonEdit`)}
			onCancel={onCancel}
			onOk={onOk}
			padded={!!commentId}
			primaryCancelBtn={
				!commentId ||
				viewableComment?.status.key === "Deleted" ||
				!viewableComment?.userCanEdit
			}
			show={isRouteMatch}
			showOkBtn={
				!!commentId &&
				viewableComment?.status.key !== "Deleted" &&
				viewableComment?.userCanEdit
			}
			title={getModalTitle()}
			withHistory={false}
		>
			<FormProvider
				control={control}
				formState={formState}
				handleSubmit={handleSubmit}
				{...methods}
			>
				<div className="action-comments-modal">
					{isLoading ? <Spinner /> : contentToRender}
				</div>
			</FormProvider>

			{isSubmitError && (
				<IllustratedThemedModal
					cancelText={t("global:ok")}
					onCancel={() => setIsSubmitError(false)}
					show={isSubmitError}
					text={t("error:failedToEditTheComment")}
					withHistory={false}
				>
					<ErrorIllustration />
				</IllustratedThemedModal>
			)}
		</FormModal>
	);
};

ActionCommentsModal.displayName = "ActionCommentsModal";
