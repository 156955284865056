export interface Entity {
	id: number;
	info: EntityPropertyInfo[];
}

export interface EntityPropertyInfo {
	propertyName: string;
	title: string;
	detail: string;
}

export interface EntityProperty {
	propertyName: string;
	caption: string;
}

export interface Entities {
	list: Entity[];
	totalDataCount: number;
	sortProperties: EntityProperty[];
}

export enum EntitySortOrder {
	Asc = "Asc",
	Desc = "Desc",
}
