import { useContext } from "react";
import { Download } from "../components/DownloadManagerContext/Download";
import { DefaultAttachmentService } from "../../services/attachment/implementations/default";
import { DownloadManagerContext } from "../components/DownloadManagerContext/DownloadManagerContext";
import { getCustomerKey } from "../../helpers/AuthenticationHelpers";

export const useDownloadFile = (
	fileName: string,
	identifier: string,
	fileDescription?: string[],
) => {
	const downloadContext = useContext(DownloadManagerContext);
	const attachmentService = new DefaultAttachmentService({
		subdomain: "Attachment",
	});
	const customerKey = getCustomerKey();

	const downloadFile = () => {
		const download = new Download(
			() => attachmentService.getOneByGuidAndCustomerKey(identifier, customerKey),
			fileName || "downloadedAttachment",
		);
		if (fileDescription) {
			download.description = fileDescription;
		}
		downloadContext.addDownload(download);
	};
	return downloadFile;
};
