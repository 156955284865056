import validationThTH from "./validation/validation.th-TH.json";
import displayThTH from "./display/display.th-TH.json";
import errorThTH from "./error/error.th-TH.json";
import globalThTH from "./global/global.th-TH.json";

import validationRuRU from "./validation/validation.ru-RU.json";
import displayRuRU from "./display/display.ru-RU.json";
import errorRuRU from "./error/error.ru-RU.json";
import globalRuRU from "./global/global.ru-RU.json";

import validationViVN from "./validation/validation.vi-VN.json";
import displayViVN from "./display/display.vi-VN.json";
import errorViVN from "./error/error.vi-VN.json";
import globalViVN from "./global/global.vi-VN.json";

import validationTa from "./validation/validation.ta.json";
import displayTa from "./display/display.ta.json";
import errorTa from "./error/error.ta.json";
import globalTa from "./global/global.ta.json";

import validationKoKR from "./validation/validation.ko-KR.json";
import displayKoKR from "./display/display.ko-KR.json";
import errorKoKR from "./error/error.ko-KR.json";
import globalKoKR from "./global/global.ko-KR.json";

import validationMsMY from "./validation/validation.ms-MY.json";
import displayMsMY from "./display/display.ms-MY.json";
import errorMsMY from "./error/error.ms-MY.json";
import globalMsMY from "./global/global.ms-MY.json";

import validationJaJP from "./validation/validation.ja-JP.json";
import displayJaJP from "./display/display.ja-JP.json";
import errorJaJP from "./error/error.ja-JP.json";
import globalJaJP from "./global/global.ja-JP.json";

import validationArAE from "./validation/validation.ar-AE.json";
import displayArAE from "./display/display.ar-AE.json";
import errorArAE from "./error/error.ar-AE.json";
import globalArAE from "./global/global.ar-AE.json";

import validationHiIN from "./validation/validation.hi-IN.json";
import displayHiIN from "./display/display.hi-IN.json";
import errorHiIN from "./error/error.hi-IN.json";
import globalHiIN from "./global/global.hi-IN.json";
import validationFiFI from "./validation/validation.fi-FI.json";
import displayFiFI from "./display/display.fi-FI.json";
import errorFiFI from "./error/error.fi-FI.json";
import globalFiFI from "./global/global.fi-FI.json";
import validationFrCA from "./validation/validation.fr-CA.json";
import displayFrCA from "./display/display.fr-CA.json";
import errorFrCA from "./error/error.fr-CA.json";
import globalFrCA from "./global/global.fr-CA.json";
import validationNbNO from "./validation/validation.nb-NO.json";
import displayNbNO from "./display/display.nb-NO.json";
import errorNbNO from "./error/error.nb-NO.json";
import globalNbNO from "./global/global.nb-NO.json";
import displayCsCZ from "./display/display.cs-CZ.json";
import errorCsCZ from "./error/error.cs-CZ.json";
import globalCsCZ from "./global/global.cs-CZ.json";
import validationCsCZ from "./validation/validation.cs-CZ.json";
import displayDeDE from "./display/display.de-DE.json";
import errorDeDE from "./error/error.de-DE.json";
import globalDeDE from "./global/global.de-DE.json";
import validationDeDE from "./validation/validation.de-DE.json";
import displayEnGB from "./display/display.en-GB.json";
import errorEnGB from "./error/error.en-GB.json";
import globalEnGB from "./global/global.en-GB.json";
import validationEnGB from "./validation/validation.en-GB.json";
import displayEsES from "./display/display.es-ES.json";
import errorEsES from "./error/error.es-ES.json";
import globalEsES from "./global/global.es-ES.json";
import validationEsES from "./validation/validation.es-ES.json";
import displayEsUS from "./display/display.es-US.json";
import errorEsUS from "./error/error.es-US.json";
import globalEsUS from "./global/global.es-US.json";
import validationEsUS from "./validation/validation.es-US.json";
import displayFrFR from "./display/display.fr-FR.json";
import errorFrFR from "./error/error.fr-FR.json";
import globalFrFR from "./global/global.fr-FR.json";
import validationFrFR from "./validation/validation.fr-FR.json";
import displayHrHR from "./display/display.hr-HR.json";
import errorHrHR from "./error/error.hr-HR.json";
import globalHrHR from "./global/global.hr-HR.json";
import validationHrHR from "./validation/validation.hr-HR.json";
import displayItIT from "./display/display.it-IT.json";
import errorItIT from "./error/error.it-IT.json";
import globalItIT from "./global/global.it-IT.json";
import validationItIT from "./validation/validation.it-IT.json";
import displayLtLT from "./display/display.lt-LT.json";
import errorLtLT from "./error/error.lt-LT.json";
import globalLtLT from "./global/global.lt-LT.json";
import validationLtLT from "./validation/validation.lt-LT.json";
import displayNlNL from "./display/display.nl-NL.json";
import errorNlNL from "./error/error.nl-NL.json";
import globalNlNL from "./global/global.nl-NL.json";
import validationNlNL from "./validation/validation.nl-NL.json";
import displayPaIN from "./display/display.pa-IN.json";
import errorPaIN from "./error/error.pa-IN.json";
import globalPaIN from "./global/global.pa-IN.json";
import validationPaIN from "./validation/validation.pa-IN.json";
import displayPePE from "./display/display.pe-PE.json";
import errorPePE from "./error/error.pe-PE.json";
import globalPePE from "./global/global.pe-PE.json";
import validationPePE from "./validation/validation.pe-PE.json";
import displayPlPL from "./display/display.pl-PL.json";
import errorPlPL from "./error/error.pl-PL.json";
import globalPlPL from "./global/global.pl-PL.json";
import validationPlPL from "./validation/validation.pl-PL.json";
import displayPtPT from "./display/display.pt-PT.json";
import errorPtPT from "./error/error.pt-PT.json";
import globalPtPT from "./global/global.pt-PT.json";
import validationPtPT from "./validation/validation.pt-PT.json";
import displayPtBR from "./display/display.pt-BR.json";
import errorPtBR from "./error/error.pt-BR.json";
import globalPtBR from "./global/global.pt-BR.json";
import validationPtBR from "./validation/validation.pt-BR.json";
import displayRoRO from "./display/display.ro-RO.json";
import errorRoRO from "./error/error.ro-RO.json";
import globalRoRO from "./global/global.ro-RO.json";
import validationRoRO from "./validation/validation.ro-RO.json";
import displaySlSI from "./display/display.sl-SI.json";
import errorSlSI from "./error/error.sl-SI.json";
import globalSlSI from "./global/global.sl-SI.json";
import validationSlSI from "./validation/validation.sl-SI.json";
import displayHuHU from "./display/display.hu-HU.json";
import errorHuHU from "./error/error.hu-HU.json";
import globalHuHU from "./global/global.hu-HU.json";
import validationHuHU from "./validation/validation.hu-HU.json";
import displaySkSK from "./display/display.sk-SK.json";
import errorSkSK from "./error/error.sk-SK.json";
import globalSkSK from "./global/global.sk-SK.json";
import validationSkSK from "./validation/validation.sk-SK.json";
import displayZhHANS from "./display/display.zh-HANS.json";
import errorZhHANS from "./error/error.zh-HANS.json";
import globalZhHANS from "./global/global.zh-HANS.json";
import validationZhHANS from "./validation/validation.zh-HANS.json";
import displayDaDK from "./display/display.da-DK.json";
import errorDaDK from "./error/error.da-DK.json";
import globalDaDK from "./global/global.da-DK.json";
import validationDaDK from "./validation/validation.da-DK.json";
import displaySvSE from "./display/display.sv-SE.json";
import errorSvSE from "./error/error.sv-SE.json";
import globalSvSE from "./global/global.sv-SE.json";
import validationSvSE from "./validation/validation.sv-SE.json";
import displayEnUs from "./display/display.en-US.json";

// eslint-disable-next-line import/no-default-export
export default {
	"th-th": {
		validation: validationThTH,
		display: displayThTH,
		error: errorThTH,
		global: globalThTH,
	},
	"ru-ru": {
		validation: validationRuRU,
		display: displayRuRU,
		error: errorRuRU,
		global: globalRuRU,
	},
	"vi-vn": {
		validation: validationViVN,
		display: displayViVN,
		error: errorViVN,
		global: globalViVN,
	},
	ta: {
		validation: validationTa,
		display: displayTa,
		error: errorTa,
		global: globalTa,
	},
	"ko-kr": {
		validation: validationKoKR,
		display: displayKoKR,
		error: errorKoKR,
		global: globalKoKR,
	},
	"ms-my": {
		validation: validationMsMY,
		display: displayMsMY,
		error: errorMsMY,
		global: globalMsMY,
	},
	"ja-jp": {
		validation: validationJaJP,
		display: displayJaJP,
		error: errorJaJP,
		global: globalJaJP,
	},
	"ar-ae": {
		validation: validationArAE,
		display: displayArAE,
		error: errorArAE,
		global: globalArAE,
	},
	"hi-in": {
		validation: validationHiIN,
		display: displayHiIN,
		error: errorHiIN,
		global: globalHiIN,
	},
	"fi-fi": {
		validation: validationFiFI,
		display: displayFiFI,
		error: errorFiFI,
		global: globalFiFI,
	},
	"fr-ca": {
		validation: validationFrCA,
		display: displayFrCA,
		error: errorFrCA,
		global: globalFrCA,
	},
	"nb-no": {
		validation: validationNbNO,
		display: displayNbNO,
		error: errorNbNO,
		global: globalNbNO,
	},
	"cs-cz": {
		display: displayCsCZ,
		validation: validationCsCZ,
		global: globalCsCZ,
		error: errorCsCZ,
	},
	"de-de": {
		display: displayDeDE,
		validation: validationDeDE,
		global: globalDeDE,
		error: errorDeDE,
	},
	"en-gb": {
		display: displayEnGB,
		validation: validationEnGB,
		global: globalEnGB,
		error: errorEnGB,
	},
	"en-us": {
		display: displayEnUs,
	},
	"es-es": {
		display: displayEsES,
		validation: validationEsES,
		global: globalEsES,
		error: errorEsES,
	},
	"es-us": {
		display: displayEsUS,
		validation: validationEsUS,
		global: globalEsUS,
		error: errorEsUS,
	},
	"fr-fr": {
		display: displayFrFR,
		validation: validationFrFR,
		global: globalFrFR,
		error: errorFrFR,
	},
	"hr-hr": {
		display: displayHrHR,
		validation: validationHrHR,
		global: globalHrHR,
		error: errorHrHR,
	},
	"it-it": {
		display: displayItIT,
		validation: validationItIT,
		global: globalItIT,
		error: errorItIT,
	},
	"lt-lt": {
		display: displayLtLT,
		validation: validationLtLT,
		global: globalLtLT,
		error: errorLtLT,
	},
	"nl-nl": {
		display: displayNlNL,
		validation: validationNlNL,
		global: globalNlNL,
		error: errorNlNL,
	},
	"pt-pt": {
		display: displayPtPT,
		validation: validationPtPT,
		global: globalPtPT,
		error: errorPtPT,
	},
	"pa-in": {
		display: displayPaIN,
		validation: validationPaIN,
		global: globalPaIN,
		error: errorPaIN,
	},
	"pe-pe": {
		display: displayPePE,
		validation: validationPePE,
		global: globalPePE,
		error: errorPePE,
	},
	"pt-br": {
		display: displayPtBR,
		validation: validationPtBR,
		global: globalPtBR,
		error: errorPtBR,
	},
	"pl-pl": {
		display: displayPlPL,
		validation: validationPlPL,
		global: globalPlPL,
		error: errorPlPL,
	},
	"ro-ro": {
		display: displayRoRO,
		validation: validationRoRO,
		global: globalRoRO,
		error: errorRoRO,
	},
	"sl-si": {
		display: displaySlSI,
		validation: validationSlSI,
		global: globalSlSI,
		error: errorSlSI,
	},
	"hu-hu": {
		display: displayHuHU,
		validation: validationHuHU,
		global: globalHuHU,
		error: errorHuHU,
	},
	"sk-sk": {
		display: displaySkSK,
		validation: validationSkSK,
		global: globalSkSK,
		error: errorSkSK,
	},
	"zh-hans": {
		display: displayZhHANS,
		validation: validationZhHANS,
		global: globalZhHANS,
		error: errorZhHANS,
	},
	"da-dk": {
		display: displayDaDK,
		validation: validationDaDK,
		global: globalDaDK,
		error: errorDaDK,
	},
	"sv-se": {
		display: displaySvSE,
		validation: validationSvSE,
		global: globalSvSE,
		error: errorSvSE,
	},
};
