interface Props {
	value?: string;
	className?: string;
	altString: string;
}

export const Base64Image = ({ altString, className, value }: Props) => {
	return (
		<>
			{value !== null && value !== undefined && value.length > 0 && (
				<img alt={altString} className={className} draggable={false} src={value} />
			)}
		</>
	);
};
