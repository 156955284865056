import { t } from "i18next";
import type { PPECategoryValue } from "../../models/fields/PPEField";

export class PPEFieldValidator {
	messages: string[] = [];

	isFieldValid = (value?: PPECategoryValue[]): boolean => {
		const result = !!value && value.length > 0;
		if (!result) {
			this.messages.push(t("validation:mandatoryFieldMessage"));
		}
		return result;
	};
}
