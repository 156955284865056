import { t } from "i18next";

export class NotesValidator {
	messages: string[];
	noteLength?: number;

	constructor(noteLength?: number) {
		this.messages = [];
		this.noteLength = noteLength;
	}

	isFieldValid = (note: string): boolean => {
		this.messages = [];

		return this.hasValue(note) && this.isBelowMaxLength(note);
	};

	hasValue = (note?: string): boolean => {
		let result = true;

		if (note == null || note.trim() === "") {
			this.messages.push(t("validation:mandatoryFieldMessage"));
			result = false;
		}

		return result;
	};

	isBelowMaxLength = (note: string): boolean => {
		let result = true;

		if (this.noteLength && note.length > this.noteLength) {
			this.messages.push(t("validation:maxLengthMessage", { maxLength: this.noteLength }));
			result = false;
		}

		return result;
	};
}
