import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { stringHelper } from "../../../../../helpers/StringHelper";
import { ModuleListItem } from "../../../../components/ModuleListItem";
import type { Portal } from "../../../../../models/portal";
import type { LabelRecordsModuleTitle } from "../../../../../types/i18next";
import "./PoliciesModuleListItem.styles.scss";

interface Props {
	portal: Portal;
}

export const PoliciesModuleListItem = ({ portal }: Props) => {
	const { t } = useTranslation();

	const module = useMemo(() => {
		return portal.installModules && portal.installModules.find((m) => m.key === "Policy");
	}, [portal.installModules]);

	const caption =
		module &&
		(module.caption ||
			(module.key &&
				t(
					`display:labelRecordsModuleTitle.${
						stringHelper.alphaNumericRegExLowerCase(
							module.key,
						) as LabelRecordsModuleTitle
					}`,
				)));

	if (!module || !module.key) {
		return null;
	}

	return (
		<>
			<h2>{t("display:labelDocumentLibraryHeader", { title: caption })}</h2>
			<ul className="she-questionnaire-list she-policies-module-list-item">
				<TransitionGroup component={null}>
					<CSSTransition
						appear
						classNames="fade"
						component={null}
						key={module.key}
						timeout={500}
					>
						<ModuleListItem moduleKey={module.key} title={caption} />
					</CSSTransition>
				</TransitionGroup>
			</ul>
		</>
	);
};
