import { FieldType } from "../../../../models/questionnaire";
import type { EvaluationService } from "../../interface";
import type { Field } from "../../../../models/fields/Field";
import type { SingleSelectField } from "../../../../models/fields/SingleSelectField";
import type { MultiSelectField } from "../../../../models/fields/MultiSelectField";
import type { BranchCondition } from "../../../../models/questionnaire/BranchCondition";

export class DefaultEvaluationService implements EvaluationService {
	public evaluateCondition(field: Field, condition: BranchCondition): boolean {
		let istriggered = false;

		if (condition != null) {
			switch (field.type) {
				case FieldType.Number:
					istriggered = this.evaluateNumber(
						field.value,
						condition.numericRangeMin,
						condition.numericRangeMax,
					);
					break;
				case FieldType.Text:
					istriggered = this.evaluateText(field.value, condition.textTriggerValues);
					break;
				case FieldType.Select:
					if (field.selectType) {
						const multiSelectField = field as MultiSelectField;
						istriggered = this.evaluateNumbersList(
							multiSelectField.value,
							condition.selectiveTriggerIds,
						);
					} else {
						const selectField = field as SingleSelectField;
						istriggered = this.evaluateNumberList(
							selectField.value,
							condition.selectiveTriggerIds,
						);
					}

					break;
			}
		}

		return istriggered;
	}

	private evaluateText(value?: string, textValues?: string[]): boolean {
		const result = false;

		if (textValues && textValues.length && value && value.length) {
			const compareValue = value.toLowerCase();
			if (
				textValues
					.map((t) => t.toLowerCase())
					.filter((t) => t === compareValue || compareValue.includes(t)).length
			) {
				return true;
			}
		}

		return result;
	}

	private evaluateNumber(value?: any, minValue?: number, maxValue?: number): boolean {
		const result = false;

		if (typeof value === "number" && minValue != null && maxValue != null) {
			return value >= minValue && value <= maxValue;
		}

		return result;
	}

	private evaluateNumberList(value?: number, numberValues?: number[]): boolean {
		const result = false;

		if (value && numberValues) {
			return numberValues.includes(value);
		}

		return result;
	}

	private evaluateNumbersList(values?: number[], numberValues?: number[]): boolean {
		const result = false;

		if (values && numberValues) {
			return values.filter((v) => numberValues.includes(v)).length > 0;
		}

		return result;
	}
}
