import { useAuth } from "oidc-react";
import { useSelector } from "react-redux";
import type { State } from "../../state";

export const useToken = () => {
	const { userData } = useAuth();
	const publicToken = useSelector<State, string | undefined>((s) => s.auth.token);
	if (userData) {
		return userData.access_token;
	}
	return publicToken;
};
