import { useCallback, useMemo, useRef, useState } from "react";
import ReactModal from "react-modal";
import transitionUtils from "./Modal.module.scss";

interface Props {
	show: boolean;
	onClose?: () => void;
	shouldCloseOnOverlayClick: boolean;
	shouldCloseOnEsc: boolean;
	shouldReturnFocusAfterClose: boolean;

	routeId?: string;

	className: string;
	overlayClassName: string;
	foregroundColour?: string;
	backgroundColour?: string;
	children: React.ReactNode;
}

if (process.env.NODE_ENV !== "test") {
	ReactModal.setAppElement("#root");
}

export const ModalPresentation = ({
	children,
	className,
	overlayClassName,
	shouldCloseOnEsc,
	shouldCloseOnOverlayClick,
	shouldReturnFocusAfterClose,
	show,
	backgroundColour,
	foregroundColour,
	onClose,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	routeId,
}: Props) => {
	const divStyles = useMemo<React.CSSProperties | undefined>(
		() => ({
			color: foregroundColour,
			background: backgroundColour,
		}),
		[foregroundColour, backgroundColour],
	);

	const [isOnScreen, setIsOnScreen] = useState(false);
	if (show && !isOnScreen) {
		setIsOnScreen(true);
	}

	// fix for displaying modal after modal not hiding body scroll
	const ref = useRef(null);
	const addBodyClass = useCallback(() => {
		if (ref.current) {
			document.body.classList.add("she-components-modal-body");
		}
	}, []);
	const afterOpen = () => {
		setTimeout(addBodyClass, parseInt(transitionUtils.transitionModalDuration));
	};

	if (!isOnScreen) {
		return null;
	}

	return (
		<ReactModal
			bodyOpenClassName="she-components-modal-body"
			className={{
				base: className,
				afterOpen: "she-components-modal-after-open",
				beforeClose: "she-components-modal-before-close",
			}}
			closeTimeoutMS={parseInt(transitionUtils.transitionModalDuration)}
			isOpen={show}
			onAfterClose={() => setIsOnScreen(false)}
			onAfterOpen={afterOpen}
			onRequestClose={onClose}
			overlayClassName={{
				base: overlayClassName,
				afterOpen: "she-components-modal-overlay-after-open",
				beforeClose: "she-components-modal-overlay-before-close",
			}}
			ref={ref}
			shouldCloseOnEsc={shouldCloseOnEsc}
			shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
			shouldReturnFocusAfterClose={shouldReturnFocusAfterClose}
			testId="modal"
		>
			<div className="she-components-modal-internal" style={divStyles}>
				{children}
			</div>
		</ReactModal>
	);
};
