import { useTranslation } from "react-i18next";
import { Toast } from "../toast/component/Toast";
import { ReactComponent as Open } from "../../assets/svg/arrow-right.svg";
import { Spinner } from "../generic/Spinner";
import "./DownloadManager.styles.scss";

interface Props {
	amount?: number;
	onClick?: () => void;
}

export const DownloadInProgressToast = ({ onClick, amount }: Props) => {
	const { t } = useTranslation();
	return (
		<Toast onClick={onClick}>
			<div className="she-download-toast__body">
				<div className="she-download-toast__spinner">
					<Spinner />
				</div>
				<div className="she-download-toast__text">
					{t("display:labelDownloadsInProgress", { amount })}
				</div>
				<div className="she-icon">
					<Open />
				</div>
			</div>
		</Toast>
	);
};

export const DownloadErrorToast = ({ onClick, amount }: Props) => {
	const { t } = useTranslation();
	return (
		<Toast onClick={onClick} variant="error">
			<div className="she-download-toast__body">
				<div className="she-download-toast__text">
					{t("error:downloadError", { amount })}
				</div>
				<div className="she-icon">
					<Open />
				</div>
			</div>
		</Toast>
	);
};

export const DownloadCompleteToast = ({ onClick }: Props) => {
	const { t } = useTranslation();
	return (
		<Toast onClick={onClick} variant="success">
			<div className="she-download-toast__body">
				<div>{t("display:labelDownloadsComplete")}</div>
			</div>
		</Toast>
	);
};
