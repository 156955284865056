import { t } from "i18next";
import { AnimationSlideFade } from "../../animation/AnimationSlideFade";
import { AnimationWrapper } from "../../animation/AnimationWrapper";
import "./NumberInput.styles.scss";

interface Props {
	onIncrement: () => void;
	onDecrement: () => void;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onBlur: (input: string) => void;
	onKeyPress: (event: React.KeyboardEvent<HTMLInputElement>, input: string) => void;
	onPaste: (event: React.ClipboardEvent<HTMLInputElement>) => void;
	value: string;
	min?: number;
	max?: number;
	showStep: boolean;
	step: number;
	hint?: string;
	guid: string;
	isMandatory: boolean;
	isNotApplicable: boolean;
	pattern: string;
}

export const NumberInputPresentation = ({
	guid,
	isMandatory,
	isNotApplicable,
	onBlur,
	onChange,
	onDecrement,
	onIncrement,
	onKeyPress,
	onPaste,
	pattern,
	showStep,
	step,
	value,
	hint,
	max,
	min,
}: Props) => {
	return (
		<div>
			<AnimationWrapper>
				{!isNotApplicable && (
					<AnimationSlideFade>
						{hint && <div className="she-components-control-hint">{hint}</div>}
						<div className="she-number-input" data-testid="number-input">
							{showStep && (
								<button
									aria-label={t("display:ariaLabelDecrement", {
										step,
									})}
									disabled={isNotApplicable}
									onClick={onDecrement}
									type="button"
								>
									–
								</button>
							)}
							<input
								aria-required={isMandatory}
								className={showStep ? "" : "she-number-input-no-step"}
								disabled={isNotApplicable}
								id={guid}
								max={max}
								min={min}
								name="number"
								onBlur={() => onBlur(value)}
								onChange={onChange}
								onKeyPress={(event) => onKeyPress(event, value)}
								onPaste={(event) => onPaste(event)}
								pattern={pattern}
								type="number"
								value={value}
							/>
							{showStep && (
								<button
									aria-label={t("display:ariaLabelIncrement", {
										step,
									})}
									disabled={isNotApplicable}
									onClick={onIncrement}
									type="button"
								>
									+
								</button>
							)}
						</div>
					</AnimationSlideFade>
				)}
			</AnimationWrapper>
		</div>
	);
};
