import { useMemo } from "react";
import { ReactComponent as Apron } from "../../assets/svg/ppe/Apron.svg";
import { ReactComponent as EarProtection } from "../../assets/svg/ppe/EarProtection.svg";
import { ReactComponent as EyeProtection } from "../../assets/svg/ppe/EyeProtection.svg";
import { ReactComponent as FaceShield } from "../../assets/svg/ppe/FaceShield.svg";
import { ReactComponent as HandProtection } from "../../assets/svg/ppe/HandProtection.svg";
import { ReactComponent as Harness } from "../../assets/svg/ppe/Harness.svg";
import { ReactComponent as Mask } from "../../assets/svg/ppe/Mask.svg";
import { ReactComponent as Overall } from "../../assets/svg/ppe/Overall.svg";
import { ReactComponent as ProtectiveFootwear } from "../../assets/svg/ppe/ProtectiveFootwear.svg";
import { ReactComponent as Respirator } from "../../assets/svg/ppe/Respirator.svg";
import { ReactComponent as SafetyHelmet } from "../../assets/svg/ppe/SafetyHelmet.svg";
import { ReactComponent as WeldingMask } from "../../assets/svg/ppe/WeldingMask.svg";
import { PPEIcon } from "../../../models/fields/PPEField";

export interface Props {
	iconId: PPEIcon;
}

export const PPEIconSvg = ({ iconId }: Props) => {
	const icon = useMemo(() => {
		switch (iconId) {
			case PPEIcon.Apron:
				return <Apron />;
			case PPEIcon.EarProtection:
				return <EarProtection />;
			case PPEIcon.EyeProtection:
				return <EyeProtection />;
			case PPEIcon.FaceShield:
				return <FaceShield />;
			case PPEIcon.HandProtection:
				return <HandProtection />;
			case PPEIcon.Harness:
				return <Harness />;
			case PPEIcon.Mask:
				return <Mask />;
			case PPEIcon.Overall:
				return <Overall />;
			case PPEIcon.ProtectiveFootwear:
				return <ProtectiveFootwear />;
			case PPEIcon.Respirator:
				return <Respirator />;
			case PPEIcon.SafetyHelmet:
				return <SafetyHelmet />;
			case PPEIcon.WeldingMask:
				return <WeldingMask />;
			default:
				return null;
		}
	}, [iconId]);

	return icon;
};
