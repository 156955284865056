import { Guidance } from "../../../../components/generic/Guidance";
import { useActionLabelAndGuidance } from "../../hooks/useActionLabelAndGuidance";
import type { ActionInfoFull } from "../../../../../models/action";
import "./ActionReadOnlyField.styles.scss";
import classNames from "classnames";

interface Props {
	propertyName: string;
	info?: ActionInfoFull[];
	enableGuidance?: boolean;
	children?: React.ReactNode;
	direction?: "row" | "column";
}

export const ActionReadOnlyField = ({
	propertyName,
	info,
	children,
	enableGuidance,
	direction = "row",
}: Props) => {
	const { label, showGuidance, guidanceIsPopup, guidance, visible } = useActionLabelAndGuidance(
		propertyName,
		info,
	);

	if (!visible) {
		return null;
	}

	return (
		<>
			<dt className="she-action-field__label">
				<div>{label}</div>

				{enableGuidance && showGuidance && (
					<Guidance
						guidance={guidance}
						guidanceIsPopup={guidanceIsPopup}
						showGuidance={showGuidance}
					/>
				)}
			</dt>

			<dd
				className={classNames("she-action-field__value", {
					"she-action-field__value--column": direction === "column",
				})}
			>
				{children}
			</dd>
		</>
	);
};
