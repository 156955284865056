import { MatrixDialog } from "../../MatrixDialog";
import "./MatrixInput.styles.scss";

interface Props {
	fieldName: string;
	text: string;
	colour: string;
	isSelectorOpen: boolean;
	matrixId: number;
	currentValue: number | undefined;
	onClick: () => void;
	onKeyPress: (key: string) => void;
	onSelectorCancel: () => void;
	onSelectorSave: (factorId: number | undefined) => void;
	onClear: () => void;
	clearLabel: string;
}

export const MatrixInputPresentation = ({
	clearLabel,
	colour,
	currentValue,
	fieldName,
	isSelectorOpen,
	matrixId,
	onClear,
	onClick,
	onKeyPress,
	onSelectorCancel,
	onSelectorSave,
	text,
}: Props) => {
	const selectedColour = {
		backgroundColor: colour,
	};

	return (
		<>
			<div
				className="she-components-submodule-tile"
				data-testid="matrix-input"
				onClick={onClick}
				onKeyPress={(event) => onKeyPress(event.key)}
				tabIndex={0}
			>
				<div className="she-matrix-input-color" style={selectedColour} />
				<div className="she-matrix-input-text">{text}</div>
			</div>
			<button className="she-btn she-btn-tertiary" onClick={onClear} type="button">
				{clearLabel}
			</button>
			<MatrixDialog
				cancel={onSelectorCancel}
				currentValue={currentValue}
				isOpen={isSelectorOpen}
				matrixId={matrixId}
				saveRecord={onSelectorSave}
				title={fieldName}
			/>
		</>
	);
};
