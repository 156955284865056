import { useTranslation } from "react-i18next";
import { OnlineStatus } from "../../../../services/health/implementations/healthMonitor";
import { GeoPositionMap } from "../../GeoPosition/GeoPositionMap.component";
import { MapValidationType } from "./GeoPositionInput.container";
import type { Position } from "../../GeoPosition/GeoPositionMap.component";
import type { MapValidationMessage } from "./GeoPositionInput.container";

interface Props {
	setShowMapDialog: (value: boolean) => void;
	lat: number;
	long: number;
	updatePositionCallback: (position: Position) => void;
	apiUrl: string;
	copyrightUrl: string;
	health: OnlineStatus;
	setMapValidationMessage: (value?: MapValidationMessage) => void;
}

export const GeoPositionReadOnlyMap = ({
	lat,
	long,
	updatePositionCallback,
	apiUrl,
	copyrightUrl,
	health,
	setShowMapDialog,
	setMapValidationMessage,
}: Props) => {
	const { t } = useTranslation();
	return (
		<>
			<div className="readOnlyMapContainer">
				<p>{t("display:labelLocationCaptured")}</p>
				<GeoPositionMap
					apiUrl={apiUrl}
					copyrightUrl={copyrightUrl}
					isReadonly
					lat={lat}
					long={long}
					zoom={16}
				/>
				<div className="positionWrapper">
					<p>{`${t("display:labelLatitude")}: ${lat} | ${t(
						"display:labelLongitude",
					)}: ${long}`}</p>
				</div>
			</div>
			<div className="geo-position__read-only-map__controls">
				<button
					className="she-btn she-btn-tertiary block"
					id="editLocation"
					onClick={() => {
						health === OnlineStatus.Available
							? setShowMapDialog(true)
							: setMapValidationMessage({
									type: MapValidationType.NoInternet,
									message: t("error:geoInternetRequiredForMap"),
							  });
					}}
					type="button"
				>
					{t("display:buttonViewCoords")}
				</button>
				<button
					className="she-btn she-btn-tertiary block"
					onClick={() => {
						updatePositionCallback({ lat: undefined, long: undefined });
					}}
					type="button"
				>
					{t("display:buttonClearCoords")}
				</button>
			</div>
		</>
	);
};
