import { useSelector } from "react-redux";
import { AreFieldArraysEqual } from "../../../../../../helpers/EqualityHelper";
import { SectionNavPresentation } from "./SectionNav.presentation";
import type { Section, Status } from "../../../../../../models/questionnaire";
import type { State } from "../../../../../../state";
import type { Field } from "../../../../../../models/fields/Field";

interface Props {
	sectionIndex: number;
	changeSection: (index: number) => void;
}

export const SectionNavContainer = ({ changeSection, sectionIndex }: Props) => {
	const status = useSelector<State, Status | undefined>((state) => state.questionnaire.status);

	const visibleSections = useSelector<State, Section[]>(
		(state) =>
			state.questionnaire.sections && state.questionnaire.sections.filter((s) => s.isVisible),
	);
	const fields = useSelector<State, Field[] | undefined>(
		(state) => state.questionnaire.fields,
		AreFieldArraysEqual,
	);

	const getSectionsWithValidationErrors = (): number[] => {
		const sectionIds: number[] = [];
		if (status && status.isValidated) {
			visibleSections.forEach((s) => {
				const fieldsWithValidationErrors = fields?.filter(
					(f) =>
						f.groupId &&
						f.id &&
						f.sectionId === s.id &&
						(f.validationMessage.length > 0 ||
							(f.actionValidationMessage && f.actionValidationMessage.length > 0) ||
							(f.attachmentValidationMessage &&
								f.attachmentValidationMessage.length > 0)),
				);
				if (fieldsWithValidationErrors && fieldsWithValidationErrors.length > 0) {
					sectionIds.push(s.id);
				}
			});
		}
		return sectionIds;
	};

	const sectionsWithValidationErrors = getSectionsWithValidationErrors();

	return (
		<SectionNavPresentation
			changeSection={changeSection}
			sectionIndex={sectionIndex}
			sections={visibleSections}
			sectionsWithValidationErr={sectionsWithValidationErrors}
		/>
	);
};
