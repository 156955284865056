const ALPHABET = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";

/**
 * Generates random alphanumerical id of provided length. Generated id is not guaranteed to be unique.
 */
export const getRandomId = (length: number): string => {
	let id = "";
	for (let i = 0; i < length; i++) {
		id += ALPHABET.charAt(Math.floor(Math.random() * ALPHABET.length));
	}
	return id;
};
