import SignatureCanvas from "react-signature-canvas";
import { useTranslation } from "react-i18next";

interface Props {
	value: string;
	guid: string;
	onEnd: (signaturePad: any) => void;
	onClear: (signaturePad: any) => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SignatureInputPresentation = ({ guid, onClear, onEnd, value }: Props) => {
	const { t } = useTranslation();
	// TODO: Should be a useRef() call here
	let signaturePad: any = null;

	return (
		<div id={guid}>
			<SignatureCanvas
				canvasProps={{
					width: 300,
					height: 150,
					className: "she-components-signature-input",
				}}
				onEnd={() => onEnd(signaturePad)}
				penColor="black"
				ref={(ref) => {
					signaturePad = ref;
				}}
			/>
			<div>
				<button
					className="she-btn she-btn-tertiary"
					id={`${guid}-clear`}
					onClick={() => onClear(signaturePad)}
					type="button"
				>
					{t("global:clear")}
				</button>
			</div>
		</div>
	);
};
