import { DefaultOrgUnitService } from "../../../../services/orgUnit";
import { CommonActionTypes } from "../../common/actions/types";
import {
	GetCurrentUnitTimeDifference,
	GetUnixTimeFromStorage,
	SetCurrentUnixTimeToStorage,
} from "../../../../helpers/UnixTimeHelper";
import { DefaultLocalDataService } from "../../../../services/localData";
import {
	HealthMonitorSingleton,
	OnlineStatus,
} from "../../../../services/health/implementations/healthMonitor";
import { logException } from "../../../../services/log";
import { OrgUnitActionTypes } from "./types";
import type { State } from "../../../model";
import type { OrgUnitService } from "../../../../services/orgUnit";
import type { OrgUnitAction } from "./definitions";
import type { CommonAction } from "../../common/actions/definitions";
import type { SimpleThunkAction } from "../../../utils/thunk";

export const loadOrgUnits = (
	rootId: number,
	customerKey: string,
): SimpleThunkAction<OrgUnitAction | CommonAction, State> => {
	return async (dispatch, getState) => {
		try {
			const healthApi = HealthMonitorSingleton.getInstance();
			const localDataService = new DefaultLocalDataService();

			if (healthApi.isAPIavailable() === OnlineStatus.Available) {
				const orgState = getState().orgUnit;
				const orgUnitService: OrgUnitService = new DefaultOrgUnitService({
					subdomain: "OrgUnit",
				});

				const refreshAfterSeconds = 300;
				const timeSinceLastOrgUnitRefresh: number | undefined =
					GetCurrentUnitTimeDifference(GetUnixTimeFromStorage("orgUnitRefreshTime"));
				const isOrgUnitsStored: boolean = await orgUnitService.hasStoredOrgUnits(
					customerKey,
				);
				if (
					orgState.orgUnits.length > 0 &&
					isOrgUnitsStored &&
					timeSinceLastOrgUnitRefresh &&
					timeSinceLastOrgUnitRefresh < refreshAfterSeconds
				) {
					return;
				}

				const orgUnits = await orgUnitService.getManyByRootIdAndCustomerKey(
					rootId,
					customerKey,
				);
				SetCurrentUnixTimeToStorage("orgUnitRefreshTime");

				dispatch({
					type: OrgUnitActionTypes.Loaded,
					orgUnits,
				});
			} else {
				const orgUnits = await localDataService.getOrgUnitsByCustomerKey(customerKey);

				dispatch({
					type: OrgUnitActionTypes.Loaded,
					orgUnits,
				});
			}
		} catch (e) {
			logException(e);
			dispatch({
				type: CommonActionTypes.ErrorHappened,
				key: "error:dataRetrievalFailed",
				hasError: true,
			});
		}
	};
};
