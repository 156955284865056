import { useState } from "react";
import { useTranslation } from "react-i18next";
import { EntitySelectInput } from "../EntitySelectInput";
import { WithDefaultValue } from "../WithDefaultValue/WithDefaultValue";
import type { EntitySelectField } from "../../../../models/fields/EntitySelectField";

interface Props {
	field: EntitySelectField;
	updateField: (fieldId: number, value: any) => void;
	updateValidationStatus: (fieldId: number, value: string[]) => void;
}

export const WithDefaultValueEntitySelect = ({
	field,
	updateField,
	updateValidationStatus,
}: Props) => {
	const [useDefaultValue, setUseDefaultValue] = useState(field.value === "UseDefaultValue");
	const [value, setValue] = useState(field.value);
	const { t } = useTranslation();

	const onUseDefaultValueChange = (useDefault: boolean) => {
		setUseDefaultValue(useDefault);
		if (useDefault) {
			updateField(field.id, "UseDefaultValue");
		} else {
			updateField(field.id, value === "UseDefaultValue" ? undefined : value);
		}
	};

	const onUpdateField = (fieldId: number, value: any) => {
		setValue(value);
		if (!useDefaultValue) {
			updateField(fieldId, value);
		}
	};

	return (
		<WithDefaultValue
			defaultValueLabel={t("display:entitySelectWithDefault.useDefaultValue", {
				propertyName: field.name,
			})}
			guid={field.guid}
			isMandatory={field.isMandatory}
			onUseDefaultValueChange={onUseDefaultValueChange}
			selectValueLabel={t("display:entitySelectWithDefault.selectValue", {
				propertyName: field.name,
			})}
			useDefaultValue={useDefaultValue}
		>
			<EntitySelectInput
				field={field}
				updateField={onUpdateField}
				updateValidationStatus={updateValidationStatus}
			/>
		</WithDefaultValue>
	);
};
