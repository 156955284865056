import { useState, useMemo, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { UpdatableFieldProperty } from "../../../../state/components/questionnaire/actions/enums";
import { TextFieldValidator } from "../../../../validators/textFieldValidator";
import { APIHealthContext } from "../../../../services/health/implementations/healthContext/healthContext";
import { GeoPositionFieldValidator } from "../../../../validators/geoPositionValidator/geoPositionFieldValidator";
import { GeoPositionInputPresentation } from "./GeoPositionInput.presentation";
import type { Position } from "../../GeoPosition/GeoPositionMap.component";
import type { GeoPositionField } from "../../../../models/fields/GeoPositionField";

export interface Props {
	geoPositionField: GeoPositionField;
	updateFieldProperty: (
		fieldId: number,
		value: any,
		propertyName: UpdatableFieldProperty,
	) => void;
	updateValidationStatus: (fieldId: number, value: string[]) => void;
}

export const GeoPositionInput = ({
	geoPositionField,
	updateFieldProperty,
	updateValidationStatus,
}: Props) => {
	const health = useContext(APIHealthContext);

	const [descriptionValidationMessages, setDescriptionValidationMessages] = useState<string[]>(
		[],
	);
	const [mapValidationMessage, setMapValidationMessage] = useState<
		MapValidationMessage | undefined
	>(undefined);
	const [useSatelliteView, setUseSatelliteView] = useState<boolean>(false);
	const [zoom, setZoom] = useState<number>(16);
	const [apiKey, setApiKey] = useState<string | undefined>(undefined);
	const [apiUrl, setApiUrl] = useState<string>("");
	const [copyrightUrl, setCopyrightUrl] = useState<string>("");
	const [miniMapApiUrl, setMiniMapApiUrl] = useState<string>("");
	const [miniMapLabel, setMiniMapLabel] = useState<string>("");
	const [mapApiUrl, setMapApiUrl] = useState<string>("");
	const [satelliteApiUrl, setSatelliteApiUrl] = useState<string>("");
	const [mapCopyrightUrl, setMapCopyrightUrl] = useState<string>("");
	const [satelliteCopyrightUrl, setSatelliteCopyrightUrl] = useState<string>("");
	const [showMapDialog, setShowMapDialog] = useState(false);
	const [revalidate, setRevalidate] = useState(false);
	const { t } = useTranslation();

	const staticMapUrl = useMemo(() => {
		return `${localStorage.getItem("mapCopyrightsUrl")}${localStorage.getItem("mapApiKey")}`;
	}, []);

	useEffect(() => {
		const key = localStorage.getItem("mapApiKey");
		if (key) {
			setApiKey(key);
		}

		const mapUrl = localStorage.getItem("mapTileUrl");
		if (mapUrl) {
			setMapApiUrl(mapUrl);
		}

		const satelliteUrl = localStorage.getItem("satelliteTileUrl");
		if (satelliteUrl) {
			setSatelliteApiUrl(satelliteUrl);
		}

		setMapCopyrightUrl(localStorage.getItem("mapCopyrightsUrl") || "");
		setSatelliteCopyrightUrl(localStorage.getItem("satelliteCopyrightsUrl") || "");
	}, []);

	useEffect(() => {
		if (useSatelliteView) {
			setApiUrl(`${satelliteApiUrl}${apiKey}`);
			setCopyrightUrl(`${satelliteCopyrightUrl}${apiKey}`);
			setMiniMapApiUrl(`${mapApiUrl}${apiKey}`);
			setMiniMapLabel(t("display:labelMap"));
		} else {
			setApiUrl(`${mapApiUrl}${apiKey}`);
			setCopyrightUrl(`${mapCopyrightUrl}${apiKey}`);
			setMiniMapApiUrl(`${satelliteApiUrl}${apiKey}`);
			setMiniMapLabel(t("display:labelSatellite"));
		}
	}, [
		apiKey,
		mapApiUrl,
		satelliteApiUrl,
		t,
		useSatelliteView,
		mapCopyrightUrl,
		satelliteCopyrightUrl,
	]);

	const updatePositionCallback = (position: Position): void => {
		updateFieldProperty(
			geoPositionField.id,
			formatInput(position.long),
			UpdatableFieldProperty.GeoPositionLong,
		);
		updateFieldProperty(
			geoPositionField.id,
			formatInput(position.lat),
			UpdatableFieldProperty.GeoPositionLat,
		);
		setRevalidate(true);
	};

	const updateDescriptionCallback = (value: string): void => {
		updateFieldProperty(
			geoPositionField.id,
			value,
			UpdatableFieldProperty.GeoPositionDescription,
		);
		setRevalidate(true);
	};

	useEffect(() => {
		if (revalidate) {
			const geoValidator = new GeoPositionFieldValidator(geoPositionField);
			geoValidator.isFieldValid();
			updateValidationStatus(geoPositionField.id, geoValidator.messages);
			setRevalidate(false);
		}
	}, [revalidate, geoPositionField, updateValidationStatus]);

	const toggleMapModeCallback = (): void => {
		setUseSatelliteView(!useSatelliteView);
	};

	const descriptionValidator = useMemo(
		() => new TextFieldValidator(geoPositionField.description),
		[geoPositionField.description],
	);

	return (
		<GeoPositionInputPresentation
			apiUrl={apiUrl}
			copyrightUrl={copyrightUrl}
			description={geoPositionField.description}
			descriptionValidationMessages={descriptionValidationMessages}
			descriptionValidator={descriptionValidator}
			geoPositionValidationMessages={geoPositionField.validationMessage}
			health={health}
			lat={geoPositionField.lat.value}
			long={geoPositionField.long.value}
			mapValidationMessage={mapValidationMessage}
			miniMapApiUrl={miniMapApiUrl}
			miniMapLabel={miniMapLabel}
			setMapValidationMessage={setMapValidationMessage}
			setShowMapDialog={setShowMapDialog}
			setZoom={setZoom}
			showMapDialog={showMapDialog}
			staticCopyrightUrl={staticMapUrl}
			streetViewApiUrl={`${mapApiUrl}${apiKey}`}
			toggleMapModeCallback={toggleMapModeCallback}
			updateDescription={updateDescriptionCallback}
			updateDescriptionValidationStatus={setDescriptionValidationMessages}
			updatePositionCallback={updatePositionCallback}
			zoom={zoom}
		/>
	);
};

export const formatInput = (value: any): number => (value ? parseFloat(value).toFixed(6) : value);

export enum MapValidationType {
	Error,
	NoInternet,
}

export interface MapValidationMessage {
	message: string;
	bottomMessage?: string;
	type: MapValidationType;
}
