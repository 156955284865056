import axios from "axios";
import { QuestionnaireActionTypes } from "../../state/components/questionnaire/actions/types";
import type { Dispatch } from "react";
import type { SetDictionariesLoading } from "../../state/components/questionnaire/actions/definitions";

// eslint-disable-next-line import/no-default-export
export default (dispatch: Dispatch<any>) => {
	axios.interceptors.request.use(
		(config) => {
			if (config.url && config.url.includes("/Dictionary")) {
				dispatch({
					type: QuestionnaireActionTypes.SetDictionaryLoadingState,
					isLoadingDictionaries: true,
				} as SetDictionariesLoading);
			}

			return config;
		},
		(error) => {
			return Promise.reject(error);
		},
	);

	axios.interceptors.response.use(
		(response) => {
			if (response.config.url && response.config.url.includes("/Dictionary")) {
				dispatch({
					type: QuestionnaireActionTypes.SetDictionaryLoadingState,
					isLoadingDictionaries: false,
				} as SetDictionariesLoading);
			}

			return response;
		},
		(error) => {
			return Promise.reject(error);
		},
	);
};
