import moment from "moment";
import { Card } from "../../Card";
import { AnimationWrapper } from "../../animation/AnimationWrapper";
import { AnimationSlideFade } from "../../animation/AnimationSlideFade";
import { ReactComponent as Remove } from "../../../assets/svg/remove.svg";
import type { Project } from "../../../../models/project/project";

interface Props {
	project?: Project;
	buttonLabel: string;
	dateFormat: string;
	onClick: () => void;
	onRemove: () => void;
	removeLabel: string;
}

export const ProjectSelectInputPresentation = ({
	project,
	buttonLabel,
	dateFormat,
	onClick,
	onRemove,
	removeLabel,
}: Props) => {
	return (
		<>
			<AnimationWrapper>
				{project && project.captions && (
					<AnimationSlideFade key={project.id}>
						<Card
							buttonSlot={
								<button
									aria-label={removeLabel}
									className="she-icon she-icon-gray"
									onClick={onRemove}
									type="button"
								>
									<Remove />
								</button>
							}
							onClick={onClick}
						>
							<dl>
								<dt>{project.captions.referenceCaption}</dt>
								<dd>{project.reference}</dd>
								{project.location && (
									<>
										<dt>{project.captions.locationCaption}</dt>
										<dd>{project.location}</dd>
									</>
								)}
								{project.startDate && moment(project.startDate).isValid() && (
									<>
										<dt>{project.captions.startDateCaption}</dt>
										<dd>{moment(project.startDate).format(dateFormat)}</dd>
									</>
								)}
								{project.manager && (
									<>
										<dt>{project.captions.managerCaption}</dt>
										<dd>{project.manager}</dd>
									</>
								)}
								<dt>{project.captions.orgUnitIdCaption}</dt>
								<dd>{project.orgUnitId}</dd>
							</dl>
						</Card>
					</AnimationSlideFade>
				)}
			</AnimationWrapper>
			<button className="she-btn she-btn-tertiary" onClick={onClick} type="button">
				{buttonLabel}
			</button>
		</>
	);
};
