import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { APIHealthContext } from "../../../../services/health/implementations/healthContext/healthContext";
import { OnlineStatus } from "../../../../services/health/implementations/healthMonitor";
import { getMatrices, reportQuestionnaireError } from "../../../../state/components/questionnaire";
import type { Field } from "../../../../models/fields/Field";
import type { MatrixField } from "../../../../models/fields/MatrixField";
import type { Matrix } from "../../../../models/matrix/matrix";
import type { State } from "../../../../state";
import { DefaultLocalDataService } from "../../../../services/localData";
import { QuestionnaireActionTypes } from "../../../../state/components/questionnaire/actions/types";
import { CommonAction } from "../../../../state/components/common/actions/definitions";
import { QuestionnaireAction } from "../../../../state/components/questionnaire/actions/definitions";
import { SimpleThunkAction } from "../../../../state/utils/thunk";

const updateQuestionnaireMatrix = (
	templateId: number,
	fields: Field[],
): SimpleThunkAction<QuestionnaireAction | CommonAction, State> => {
	return async (dispatch) => {
		try {
			const matrices = await getMatrices(fields);
			const localDataService = new DefaultLocalDataService();
			const template = await localDataService.getTemplateById(templateId);

			if (template) {
				template.matrixes = matrices;
				localDataService.saveTemplate(template);
			}

			dispatch({
				type: QuestionnaireActionTypes.AddMatrix,
				matrixes: matrices || [],
			});
		} catch (e) {
			const error = e as any;
			dispatch(reportQuestionnaireError("error:updateFieldFailed", error));
		}
	};
};

export const useMatrixFix = (field: MatrixField) => {
	const dispatch = useDispatch();
	const health = useContext(APIHealthContext);
	const matrix: Matrix | undefined = useSelector<State, Matrix | undefined>((state) =>
		state.questionnaire.matrixes.find((m) => m.id === field.matrixId),
	);
	const templateId = useSelector<State, number | undefined>(
		(state) =>
			state.questionnaire.questionnaire && state.questionnaire.questionnaire.templateId,
	);
	const fields = useSelector<State, Field[]>((state) => state.questionnaire.fields);

	useEffect(() => {
		if (matrix || !templateId || health !== OnlineStatus.Available || !fields) {
			return;
		}

		dispatch(updateQuestionnaireMatrix(templateId, fields));
		// run only on first render
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};
