import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { isBrowserIE } from "../../../helpers/ieHelper";
import { NotificationModal } from "../modals";

export const UnsupportedBrowser = () => {
	const [showModal, setShowModal] = useState(true);
	const { t } = useTranslation();

	if (!isBrowserIE()) {
		return null;
	}

	const unsupportedBrowserMessage = (
		<Trans i18nKey="display:unsupportedBrowserMessage">
			stringPart1
			<a
				href="https://knowledgebase.sheassure.net/hc/en-gb"
				rel="noopener noreferrer"
				target="_blank"
			>
				stringPart2
			</a>
			stringPart3
		</Trans>
	);

	return (
		<NotificationModal
			closeText={t("global:ok")}
			content={unsupportedBrowserMessage}
			onClose={() => setShowModal(false)}
			show={showModal}
			withHistory={false}
		/>
	);
};
