import { useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { PageLayout } from "../../layouts/PageLayout";
import { PortalAuthenticationMode } from "../../../models/portal";
import { DefaultTrainingService } from "../../../services/training";
import { CancelablePromise } from "../../../services/utilities/CancelablePromise";
import { Spinner } from "../../components/generic/Spinner";
import { useIsAuthenticated } from "../../../helpers/UseIsAuthenticated";
import trainingEmptyImageMobile from "../../assets/png/training-empty-mobile.png";
import trainingEmptyImageDesktop from "../../assets/png/training-empty-desktop.png";
import { APIHealthContext } from "../../../services/health/implementations/healthContext";
import { OnlineStatus } from "../../../services/health/implementations/healthMonitor";
import { TrainingCard } from "./components/TrainingCard";
import type { Portal } from "../../../models/portal";
import type { State } from "../../../state";
import type { TrainingRecord } from "../../../models/training";
import "./training-page.scss";

export const TrainingPage = () => {
	const { t } = useTranslation();
	const { customerKey, portalKey } = useParams<{ customerKey: string; portalKey: string }>();
	const portal = useSelector<State, Portal | undefined>((s) =>
		s.portal.portals.find(
			(p) => p.key === portalKey.toLowerCase() && p.customerKey === customerKey.toLowerCase(),
		),
	);
	const isUserAuthenticated = useIsAuthenticated();
	const history = useHistory();
	const health = useContext(APIHealthContext);

	const isPublicPortal = useSelector<State, boolean>(
		(state) =>
			state.portal &&
			state.portal.portals &&
			state.portal.portals[0] &&
			!!state.portal.portals[0].authenticationMode &&
			(state.portal.portals[0].authenticationMode as number) ===
				PortalAuthenticationMode.Public,
	);

	const service = useMemo(
		() =>
			new DefaultTrainingService({
				subdomain: "learn",
			}),
		[],
	);

	const isOffline = health !== OnlineStatus.Available;

	const { isLoading, data: trainingRecords } = useQuery(["trainingRecords"], () => {
		const cancelTokenSource = axios.CancelToken.source();

		const getTrainingRecords = new CancelablePromise<TrainingRecord[]>((resolve, reject) =>
			service
				.getTrainingRecords()
				.then((r) => resolve(r))
				.catch((e) => reject(e)),
		);

		getTrainingRecords.cancel = () => {
			cancelTokenSource.cancel();
		};

		return getTrainingRecords;
	});

	if (isPublicPortal || !isUserAuthenticated || !portal?.showLearnTraining) {
		history.push(`/${customerKey}/p/${portalKey}`);
		return null;
	}

	if (isOffline) {
		return (
			<PageLayout portal={portal} title={portal ? portal.title : ""}>
				<div className="training-page">
					<h2>{t("display:trainingPageHeading")}</h2>

					<div className="training-page__offline">
						<p className="training-page__offline-title">
							{t("display:trainingOfflineHeading")}
						</p>

						<p className="training-page__offline-description">
							{t("display:trainingOfflineDescription")}
						</p>
					</div>
				</div>
			</PageLayout>
		);
	}

	if (isLoading) {
		return (
			<PageLayout portal={portal} title={portal ? portal.title : ""}>
				<div className="training-page">
					<h2>{t("display:trainingPageHeading")}</h2>

					<div className="training-page__loading">
						<Spinner />
					</div>
				</div>
			</PageLayout>
		);
	}

	return (
		<PageLayout portal={portal} title={portal ? portal.title : ""}>
			<div className="training-page">
				<h2>{t("display:trainingPageHeading")}</h2>

				{trainingRecords?.length ? (
					<div className="training-page__cards" data-testid="training-page-cards">
						{trainingRecords.map((record) => (
							<TrainingCard key={record.title} {...record} />
						))}
					</div>
				) : (
					<div className="training-page__empty" data-testid="training-page-empty">
						<div className="training-page__empty-inner">
							<img
								alt="No training illustration"
								className="training-page__empty-illustration training-page__empty-illustration--mobile"
								src={trainingEmptyImageMobile}
							/>

							<img
								alt="No training illustration"
								className="training-page__empty-illustration training-page__empty-illustration--desktop"
								src={trainingEmptyImageDesktop}
							/>

							<p className="training-page__empty-title">
								{t("display:labelTrainingRecordsUpToDate")}
							</p>

							<p className="training-page__empty-description">
								{t("display:labelTrainingRecordsUpToDateDescription")}
							</p>
						</div>
					</div>
				)}
			</div>
		</PageLayout>
	);
};

TrainingPage.displayName = "TrainingPage";
