import { t } from "i18next";
import TextareaAutosize from "react-textarea-autosize";
import { getBlobUrl } from "../../../helpers/BlobHelper";
import type { Attachment } from "../../../models/attachments/Attachment";

interface Props {
	attachment: Attachment;
	updateAttachmentDescriptionCallback: (id: string, description: string) => void;
}
export const AttachmentDescriptionModalItem = ({
	attachment,
	updateAttachmentDescriptionCallback: updateAttachmentCallback,
}: Props) => {
	return (
		<div className="she-attachment-description-modal-item" data-testid="description-modal-item">
			{attachment.isImage && (
				<img
					alt={attachment.file.name}
					draggable={false}
					src={getBlobUrl(attachment.file)}
				/>
			)}

			<div
				className="she-components-control-subtitle she-attachment-description__filename"
				id={`filename-${attachment.id}`}
			>
				{attachment.originalFileName || attachment.file.name || attachment.fileName}
			</div>

			<label
				className="she-components-control-subtitle she-attachment-description__label"
				htmlFor={attachment.id}
				id={`${attachment.id}-label`}
			>
				{t("display:labelDescription")}
			</label>
			<div className="she-components-control-hint">
				{t("display:hintTextFieldLength", { minLength: 0, maxLength: 255 })}
			</div>
			<TextareaAutosize
				aria-required={false}
				className="she-components-text-input mb"
				data-testid="text-input"
				defaultValue={
					(attachment.description as string) && (attachment.description as string)
				}
				id={attachment.id}
				maxLength={255}
				name={`${attachment.fileName}-description`}
				onBlur={() => {}}
				onChange={(e) => {
					updateAttachmentCallback(attachment.id, e.target.value);
				}}
			/>
		</div>
	);
};
