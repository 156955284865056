import classnames from "classnames";
import { PPEIconSvg } from "./PPEIconSvg.component";
import type { PPEIcon } from "../../../models/fields/PPEField";
import "./PPE.styles.scss";

export interface Props {
	iconId: PPEIcon;
	selected?: boolean;
	onClick?: () => void;
}

export const PPEIconContainer = ({ iconId, selected, onClick }: Props) => {
	const classes = classnames(["she-question-icon", { "she-question-icon--selected": selected }]);

	return (
		<div className={classes} onClick={onClick}>
			<PPEIconSvg iconId={iconId} />
		</div>
	);
};
