import { useTranslation } from "react-i18next";
import { PortalQuestionnaireList } from "../PortalQuestionnaireList";
import type { Portal } from "../../../../../models/portal";

interface Props {
	portal: Portal;
}

export const PortalHomeView = ({ portal }: Props) => {
	const { t } = useTranslation();

	return (
		<>
			<header>
				<h2>{t("display:labelNavigationForms")}</h2>
			</header>
			<PortalQuestionnaireList portal={portal} />
		</>
	);
};
