import { BaseService } from "../../../baseService";
import { get, withSignInGet, withSignInPost, withSignInPut } from "../../../utilities/http";
import { mapSortProperties } from "./sortPropertyMapper";
import type {
	Action,
	ActionFull,
	Actions,
	ActionSortOrder,
	ResponseActionComment,
} from "../../../../models/action";
import type { ActionsService } from "../../interface";
import type { CancelTokenSource } from "axios";

export class DefaultActionsService extends BaseService implements ActionsService {
	async getActions(
		portalKey: string,
		sortField?: string,
		sortOrder?: ActionSortOrder,
		pageSize = 20,
		startIndex = 0,
		searchText = "",
		portalCulture = "Uk",
		language = "EnGB",
		cancelToken?: CancelTokenSource,
	) {
		let query = "/MyActions?";
		query += `SearchText=${encodeURIComponent(searchText)}`;
		query += `&SearchDateFormat=${portalCulture}`;
		query += `&UserCurrentLanguage=${language}`;
		query += `&StartIndex=${startIndex}`;
		query += `&PageSize=${pageSize}`;
		query += `&PortalKey=${portalKey}`;
		query += sortField ? `&SortField=${sortField}` : "";
		query += sortOrder ? `&SortOrder=${sortOrder}` : "";

		const serviceUrl = `${await this.getEndpointUrl()}${query}`;
		const headers = await this.getAuthHeaders();
		const response = await withSignInGet<any>(serviceUrl, {
			...headers,
			cancelToken: cancelToken && cancelToken.token,
		});

		return this.mapActions(response, portalCulture);
	}

	mapActions(response: any, portalCulture: string): Actions {
		return {
			totalDataCount: response.data.TotalDataCount,
			list: response.data.Data.map((responseAction: any) =>
				this.mapAction(responseAction, response.data.FieldInfo, portalCulture),
			),
			sortProperties: mapSortProperties(response.data.FieldInfo),
		};
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	mapAction(responseAction: any, responseFieldInfo: [], portalCulture: string): Action {
		return {
			...responseAction,
			ActionId: responseAction.ActionId,
			Info: responseFieldInfo,
		};
	}

	async getAction(
		actionId: number | null,
		language = "EnGB",
		cancelToken?: CancelTokenSource,
	): Promise<ActionFull> {
		const languages = language;
		let query = "?";

		query += `Id=${actionId}`;
		query += `&UserLanguage=${languages}`;

		const serviceUrl = `${await this.getEndpointUrl()}${query}`;
		const headers = await this.getAuthHeaders();
		const response = await withSignInGet<any>(serviceUrl, {
			...headers,
			cancelToken: cancelToken && cancelToken.token,
		});

		return { ...response.parsedBody.Data, Info: response.parsedBody.FieldInfo };
	}

	async createAction(
		customerKey: string,
		portalKey: string,
		action: any,
		language = "EnGB",
	): Promise<any> {
		let query = "/Create?";
		query += `key=${portalKey}`;
		query += `&userLanguage=${language}`;

		const serviceUrl = (await this.getEndpointUrl()) + query;

		const response = await withSignInPost<any>(
			serviceUrl,
			{
				headers: {
					...(await this.getAuthHeadersInit()),
					"customer-key": customerKey,
				},
			},
			action,
		);

		return response.parsedBody;
	}

	async putAction(
		actionId: string | null,
		customerKey: string,
		portalKey: string,
		action: any,
		language = "EnGB",
	): Promise<any> {
		let query = "/Edit?";
		query += `Id=${actionId}`;
		query += `&key=${portalKey}`;
		query += `&userLanguage=${language}`;

		const serviceUrl = (await this.getEndpointUrl()) + query;

		const response = await withSignInPut<any>(
			serviceUrl,
			{
				headers: {
					...(await this.getAuthHeadersInit()),
					"customer-key": customerKey,
				},
			},
			action,
		);

		return response.parsedBody;
	}

	async getMetadata(language: string): Promise<ActionFull[]> {
		const serviceUrl = await this.getEndpointUrl();
		const response = await get<any>(`${serviceUrl}/Metadata?userLanguage=${language}`, {
			headers: {
				...(await this.getAuthHeadersInit()),
			},
		});

		return response.parsedBody.Metadata as ActionFull[];
	}

	async getActionComments(actionId: number, language = "EnGB"): Promise<ResponseActionComment[]> {
		let query = "/Comments";
		query += `?Id=${actionId}`;
		query += `&UserLanguage=${language}`;

		const commentsUrl = `${await this.getEndpointUrl()}${query}`;
		const headers = await this.getAuthHeaders();

		const response = await withSignInGet<any>(commentsUrl, {
			...headers,
		});

		return response.data;
	}

	async updateActionComment(
		values: Record<string, unknown>,
		language = "EnGB",
	): Promise<ResponseActionComment> {
		let query = "/EditComment";
		query += `?userLanguage=${language}`;
		const commentsUrl = `${await this.getEndpointUrl()}${query}`;

		const response = await withSignInPut<any>(
			commentsUrl,
			{
				headers: {
					...(await this.getAuthHeadersInit()),
				},
			},
			values,
		);

		return response.data;
	}
}
