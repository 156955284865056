import { t } from "i18next";
import type { SingleSelectField } from "../../models/fields/SingleSelectField";

export class SingleSelectFieldValidator {
	singleSelectField: SingleSelectField;
	messages: string[];

	constructor(singleSelectField: SingleSelectField) {
		this.singleSelectField = singleSelectField;
		this.messages = [];
	}

	isFieldValid(value?: number): boolean {
		this.messages = [];
		return this.doesMandatoryHaveValue(value);
	}

	doesMandatoryHaveValue(value?: number): boolean {
		let result = true;

		if (this.singleSelectField.isMandatory) {
			if (value == null) {
				this.messages.push(t("validation:mandatoryFieldMessage"));
				result = false;
			}
		}

		return result;
	}
}
