import { useState, useMemo, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { LocalSectionId } from "../../../../models/questionnaire";
import { FileValidator } from "../../../../validators/fileValidator";
import {
	isFileImage,
	isSVG,
	processAttachments,
	sanitizeFilename,
} from "../../../../helpers/FileHelper";
import { addAttachments } from "../../../../state/components/attachment";
import { makeDirty, updateFieldProperty } from "../../../../state/components/questionnaire";
import { UpdatableFieldProperty } from "../../../../state/components/questionnaire/actions/enums";
import { FileInputPresentation } from "./FileInput.presentation";
import type { Field } from "../../../../models/fields/Field";
import type { State } from "../../../../state";
import type { Questionnaire } from "../../../../models/questionnaire";
import type { Portal } from "../../../../models/portal";
import type { Attachment, AttachmentParentType } from "../../../../models/attachments/Attachment";

interface Props {
	questionnaireId: string;
	parentGuid?: string;
	validateAttachmentsLimit: boolean;
	parentType: AttachmentParentType;
	subModuleId?: LocalSectionId;
}

export const FileInputContainer = ({
	parentType,
	questionnaireId,
	parentGuid,
	subModuleId,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	validateAttachmentsLimit,
}: Props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const questionnaire = useSelector<State, Questionnaire>(
		// TODO: Look into this - we should not have to assert its existence
		(state) => state.questionnaire.questionnaire!,
	);
	const portal = useSelector<State, Portal | undefined>((state) =>
		state.portal.portals.find(
			(p) => p.key.toLowerCase() === questionnaire.portalKey.toLowerCase(),
		),
	);
	const attachments = useSelector<State, Attachment[] | undefined>(
		(state) => state.recordAttachments.attachments,
	);
	const field = useSelector<State, Field | undefined>((state) =>
		state.questionnaire.fields.find((x) => x.guid === parentGuid),
	);

	const [messages, setMessages] = useState<string[]>([]);
	const [filesTooBig, setFilesTooBig] = useState(false);
	const [largeFiles, setLargeFiles] = useState<{ fileName: string; fileSize: string }[]>([]);
	const [emptyFiles, setEmptyFiles] = useState<string[]>([]);
	const [displayMaxLimitModal, setDisplayMaxLimitModal] = useState(false);

	const [processing, setProcessing] = useState(false);

	const validator = useMemo(
		() =>
			new FileValidator(
				questionnaire,
				// TODO: Look into this - we should not have to asset is existence
				portal!.attachmentSizeLimit,
				portal!.excludedFileTypes.split(".").map((x) => x.toLowerCase()),
			),
		[questionnaire, portal],
	);

	useEffect(() => {
		if (
			attachments &&
			attachments.length > 0 &&
			validator.isBelowAttachmentLimit(attachments.length)
		) {
			setMessages([]);
		}
	}, [attachments, validator]);

	const onChange = useCallback(
		async (event: React.ChangeEvent<HTMLInputElement>) => {
			if (event.target.files) {
				const files = Array.from(event.target.files).map((file) => {
					const fileName = sanitizeFilename(file.name);
					return new File([file], fileName, {
						type: file.type,
						lastModified: file.lastModified,
					});
				});
				validator.messages = [];
				validator.fileTooBig = false;
				validator.fileEmpty = false;
				setFilesTooBig(false);
				setLargeFiles([]);
				setEmptyFiles([]);
				event.target.value = "";

				// Stop if too many attachments
				if (
					attachments &&
					!validator.isBelowAttachmentLimit(attachments.length + files.length)
				) {
					return setMessages(validator.messages);
				}

				// Validate file type
				for (const f of files) {
					if (!validator.isAcceptableFileType(f.name)) {
						return setMessages(validator.messages);
					}
				}

				// Build array of attachments for processing
				let attachmentsForProcessing: Attachment[];
				attachmentsForProcessing = files.reduce((accAttachments: Attachment[], file) => {
					if (file !== null) {
						const id = uuid();
						accAttachments.push({
							id,
							originalFileName: file.name,
							fileName: `${id}-${file.name}`,
							recordId: questionnaireId,
							file,
							parentGuid,
							isImage: isFileImage(file),
							isSVG: isSVG(file),
							parentType,
						});
					}
					return accAttachments;
				}, []);

				setProcessing(true);
				// Resize image attachments
				if (attachmentsForProcessing.some((a) => a.isImage)) {
					attachmentsForProcessing = await processAttachments(attachmentsForProcessing);
				}

				// Validate sizes
				const validatedAttachments: Attachment[] = [];

				for (const attachment of attachmentsForProcessing) {
					const validationResult = await validator.isAttachmentValid(attachment);
					if (validationResult) {
						validatedAttachments.push(attachment);
					} else {
						if (validator.fileTooBig) {
							const largeFile = {
								fileName: attachment.file.name,
								fileSize: `${
									attachment.file.size / 1024 < 1024
										? `${(attachment.file.size / 1024).toFixed(2)} Kb`
										: `${(attachment.file.size / 1024 / 1024).toFixed(2)} Mb`
								}`,
							};
							setFilesTooBig(true);
							setLargeFiles((largeFiles) => [...largeFiles, largeFile]);
						}
						if (validator.fileEmpty) {
							setEmptyFiles((emptyFiles) => [...emptyFiles, attachment.file.name]);
						}
					}
				}

				// Store valid attachments
				if (validatedAttachments.length) {
					dispatch(makeDirty());
					dispatch(
						addAttachments(
							validatedAttachments,
							subModuleId === LocalSectionId.Actions,
						),
					);

					if (field && field.isIq) {
						dispatch(
							updateFieldProperty(
								field.id,
								[],
								UpdatableFieldProperty.attachmentValidationMessage,
							),
						);
					}
				}

				setMessages(validator.messages);
				setProcessing(false);
			}
		},
		[
			attachments,
			dispatch,
			field,
			parentGuid,
			parentType,
			questionnaireId,
			subModuleId,
			validator,
		],
	);

	if (!portal) {
		return null;
	}

	const attachmentsLimitMet = () => {
		const attachmentNumber = attachments && attachments.length;
		const setAttachmentsNumber = questionnaire && questionnaire.maxAttachments;

		return attachmentNumber! >= setAttachmentsNumber!;
	};

	const onCloseFilesTooBigDialog = () => {
		setFilesTooBig(false);
		setLargeFiles([]);
	};

	const onCloseEmptyFilesDialog = () => {
		setEmptyFiles([]);
	};

	return (
		<FileInputPresentation
			addButtonText={t("display:buttonAddAttachment")}
			attachmentLimit={questionnaire.maxAttachments!}
			attachmentLimitMessage={t("validation:attachmentLimitReached", {
				maxAttachmentSize: questionnaire.maxAttachments!,
			})}
			attachmentLimitMet={attachmentsLimitMet()}
			displayMaxLimitModal={displayMaxLimitModal}
			emptyFiles={emptyFiles}
			filesTooBig={filesTooBig}
			filesTooBigMessage={t("validation:filesTooBigMessage", {
				maxFilesSize: `${
					portal.attachmentSizeLimit < 1024
						? `${portal.attachmentSizeLimit} Kb`
						: `${(portal.attachmentSizeLimit / 1024).toFixed(2)} Mb`
				}`,
			})}
			largeFiles={largeFiles}
			okButtonText={t("global:ok")}
			onChange={onChange}
			onEmptyFilesOk={onCloseEmptyFilesDialog}
			onFileToLargeOk={onCloseFilesTooBigDialog}
			parentGuid={parentGuid}
			processing={processing}
			setDisplayMaxLimitModal={setDisplayMaxLimitModal}
			showEmptyFiles={!!emptyFiles.length}
			validationErrors={messages}
		/>
	);
};
