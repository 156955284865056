import { useTranslation } from "react-i18next";
import { ButtonBarPresentation } from "./ButtonBar.presentation";

interface Props {
	backButtonPressed: () => void;
	nextButtonPressed: () => void;
	cancelButtonPressed: () => void;
	submitButtonPressed: () => void;
	allSectionsIndices: number[];
	currentSectionIndex: number;
	isSubmitDisabled: boolean;
}

export const ButtonBarContainer = ({
	allSectionsIndices,
	backButtonPressed,
	cancelButtonPressed,
	currentSectionIndex,
	isSubmitDisabled,
	nextButtonPressed,
	submitButtonPressed,
}: Props) => {
	let buttons: {
		text: string;
		disabled: boolean;
		awaiting: boolean;
		primary: boolean;
		onClick?: () => void;
	}[] = [];
	const { t } = useTranslation();
	const sectionCount = allSectionsIndices.length;
	const lastIndexValue = sectionCount > 0 ? allSectionsIndices[sectionCount - 1] : -1;

	if (sectionCount === 0) {
		buttons = [
			{
				text: t("global:close"),
				disabled: false,
				awaiting: false,
				primary: false,
				onClick: () => {
					cancelButtonPressed();
				},
			},
		];
	}
	if (sectionCount === 1) {
		buttons = [
			{
				text: t("global:close"),
				disabled: false,
				awaiting: false,
				primary: false,
				onClick: () => {
					cancelButtonPressed();
				},
			},
			{
				text: t("global:submit"),
				disabled: isSubmitDisabled,
				awaiting: isSubmitDisabled,
				primary: true,
				onClick: () => {
					submitButtonPressed();
				},
			},
		];
	} else if (currentSectionIndex === 0) {
		buttons = [
			{
				text: t("global:close"),
				disabled: false,
				awaiting: false,
				primary: false,
				onClick: () => {
					cancelButtonPressed();
				},
			},
			{
				text: t("global:next"),
				disabled: false,
				awaiting: false,
				primary: true,
				onClick: () => {
					nextButtonPressed();
				},
			},
		];
	} else if (currentSectionIndex === lastIndexValue) {
		buttons = [
			{
				text: t("global:back"),
				disabled: false,
				awaiting: false,
				primary: false,
				onClick: () => {
					backButtonPressed();
				},
			},
			{
				text: t("global:submit"),
				disabled: isSubmitDisabled,
				awaiting: isSubmitDisabled,
				primary: true,
				onClick: () => {
					submitButtonPressed();
				},
			},
		];
	} else {
		buttons = [
			{
				text: t("global:back"),
				disabled: false,
				awaiting: false,
				primary: false,
				onClick: () => {
					backButtonPressed();
				},
			},
			{
				text: t("global:next"),
				disabled: false,
				awaiting: false,
				primary: true,
				onClick: () => {
					nextButtonPressed();
				},
			},
		];
	}

	return <ButtonBarPresentation buttons={buttons} />;
};
