import { useEffect, useMemo, useRef, useState } from "react";
import Axios from "axios";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import {
	EntitySelectDefaultSort,
	EntitySelectServiceEndpointMap,
	FieldType,
} from "../../../../models/questionnaire";
import { SearchBox } from "../SearchBox";
import { Pagination } from "../../generic/Pagination";
import { TouchInputBlurrer } from "../../generic/TouchInputBlurrer";
import { FormModal } from "../../modals";
import { ReactComponent as IconSort } from "../../../assets/svg/arrange-letter.svg";
import { RadioButton } from "../RadioButton";
import { stringHelper } from "../../../../helpers/StringHelper";
import { EntitySortOrder } from "../../../../models/entity";
import { DefaultEntityService } from "../../../../services/entity";
import { CancelablePromise } from "../../../../services/utilities/CancelablePromise";
import { switchMatchingLanguageCode } from "../../../../helpers/LanguageCodeHelper";
import { EntitySelectInputListItem } from "../EntitySelectInputListItem";
import { ENTITY_SELECT_PROPERTY_USING_SELECTED_ORG_UNIT } from "../../../../models/questionnaire/Consts";
import type { EntitySelectField } from "../../../../models/fields/EntitySelectField";
import type { OrgUnit } from "../../../../models/orgUnit";
import type { EntitySelectProperty } from "../../../../models/questionnaire";
import type { Field } from "../../../../models/fields/Field";
import type { State } from "../../../../state";
import type { Entities, Entity } from "../../../../models/entity";
import "./EntitySelectList.styles.scss";
import type { TranslationSortOrderKey } from "../../../../types/i18next";

interface Props {
	field: EntitySelectField;
	portalCulture: string;
	onSelectEntity: (entity: Entity) => void;
	selectedEntity?: Entity;
}

interface EntitySortOption {
	idTitle: string;
	selected: boolean;
	onClick: () => void;
	label: string;
}

interface SortInfo {
	order: EntitySortOrder;
	property: string;
	caption: string;
}

const EXCLUDED_SORT_PROPERTIES = [
	{ endpoint: "ForUser", propertyName: "Id" },
	{ endpoint: "User", propertyName: "Id" },
	{ endpoint: "Assigned", propertyName: "Id" },
];

const INCLUDE_CURRENT_USER_PROPERTIES = ["ForUser"];

export const EntitySelectInputList = ({
	field,
	selectedEntity,
	portalCulture,
	onSelectEntity,
}: Props) => {
	const { t, i18n } = useTranslation();
	const pageSize = 100;
	const [page, setPage] = useState(0);
	const { portalKey } = useParams<{ portalKey: string }>();
	const searchBoxRef = useRef<HTMLInputElement>(null);
	const language = useMemo(() => switchMatchingLanguageCode(i18n.language), [i18n.language]);
	const [sortOptions, setSortOptions] = useState<EntitySortOption[]>([]);

	const orgUnits = useSelector<State, OrgUnit[]>((s) => s.orgUnit.orgUnits);
	const orgUnitSelected = useSelector<State, Field | undefined>((s) =>
		s.questionnaire.fields.find((f) => f.type === FieldType.OrgUnit),
	);
	const rootOrgUnitId: number = useMemo(() => {
		const rootOrgUnit = orgUnits.find((ou) => ou.parentId === undefined);
		if (rootOrgUnit) {
			return rootOrgUnit.id;
		}
		return 0;
	}, [orgUnits]);

	const [showSortModal, setShowSortModal] = useState(false);
	const [sort, setSort] = useState<SortInfo>(() => {
		const sortFromStorage = localStorage.getItem(`entitySort${field.propertyName}`);

		if (sortFromStorage) {
			const parsedSortFromStorage = JSON.parse(sortFromStorage);

			if (
				parsedSortFromStorage.property &&
				Object.keys(EntitySortOrder).includes(parsedSortFromStorage.order)
			) {
				return parsedSortFromStorage;
			}
		}

		if (Object.keys(EntitySelectDefaultSort).includes(field.propertyName)) {
			return {
				order: EntitySortOrder.Asc,
				property: (EntitySelectDefaultSort as any)[field.propertyName],
			};
		}

		return { order: EntitySortOrder.Asc, property: "EntityReference" };
	});

	const [tempSort, setTempSort] = useState<SortInfo | undefined>(sort);

	const groupText = useMemo(
		() => stringHelper.alphaNumericRegExLowerCase(field.name),
		[field.name],
	);

	const [searchTerm, setSearchTerm] = useState(() => {
		if (
			orgUnitSelected &&
			orgUnitSelected.value &&
			orgUnitSelected.type === FieldType.OrgUnit &&
			!orgUnitSelected.isFixed &&
			!orgUnitSelected.hidden
		) {
			const orgUnit = orgUnits.find((o) => o.id === orgUnitSelected.value);

			if (orgUnit) {
				return `"${orgUnit.name}"`;
			}
		}
		return undefined;
	});

	const entityService = useMemo(
		() =>
			new DefaultEntityService({
				subdomain: Object.keys(EntitySelectServiceEndpointMap).includes(field.propertyName)
					? (EntitySelectServiceEndpointMap as any)[field.propertyName]
					: field.propertyName,
			}),
		[field.propertyName],
	);

	const selectedOrgUnitId = useMemo(() => {
		if (
			(ENTITY_SELECT_PROPERTY_USING_SELECTED_ORG_UNIT as string[]).includes(
				field.propertyName as EntitySelectProperty,
			)
		) {
			if (orgUnitSelected && orgUnitSelected.value) {
				return orgUnitSelected.value.toString();
			}
			return rootOrgUnitId;
		}
		return undefined;
	}, [field.propertyName, orgUnitSelected, rootOrgUnitId]);

	const { data, isLoading, isError } = useQuery(
		[
			field.propertyName,
			rootOrgUnitId,
			portalKey,
			sort.property,
			sort.order,
			language,
			searchTerm,
			portalCulture,
			page,
		],
		() => {
			const cancelTokenSource = Axios.CancelToken.source();
			const getRecords = new CancelablePromise<Entities>((resolve, reject) =>
				entityService
					.getEntitySelectCollection(
						rootOrgUnitId,
						portalKey,
						sort.property,
						sort.order,
						language,
						searchTerm,
						portalCulture,
						pageSize,
						page * pageSize,
						cancelTokenSource,
						INCLUDE_CURRENT_USER_PROPERTIES.includes(field.propertyName),
						field.propertyName,
						selectedOrgUnitId,
					)
					.then((r) => resolve(r))
					.catch((e) => reject(e)),
			);
			getRecords.cancel = () => {
				cancelTokenSource.cancel();
			};

			return getRecords;
		},
		{
			onSuccess: (data) => {
				const sortProperty = data.sortProperties.find(
					({ propertyName }) => propertyName === sort.property,
				);

				if (sortProperty) {
					setSort({ ...sort, caption: sortProperty.caption });
				}
			},
		},
	);

	const recordsDetails = data
		? t("display:entitySelect.labelShowingRecordsNumber", {
				min: pageSize * page + 1,
				max: Math.min(pageSize * (page + 1), data.totalDataCount),
				total: data.totalDataCount,
		  })
		: "";

	const onChangePage = (page: number) => {
		setPage(page - 1);
	};

	const onSearchTermChange = (searchTerm: string) => {
		setPage(0);
		setSearchTerm(searchTerm);
	};

	const handleSetSort = () => {
		if (tempSort) {
			setSort(tempSort);
			localStorage.setItem(`entitySort${field.propertyName}`, JSON.stringify(tempSort));
		}
		setShowSortModal(false);
	};

	useEffect(() => {
		if (data && data && data.sortProperties.length > 0) {
			const sortedProperties = data.sortProperties
				.filter(
					(property) =>
						!EXCLUDED_SORT_PROPERTIES.some(
							(excluded) =>
								excluded.propertyName === property.propertyName &&
								excluded.endpoint === field.propertyName,
						),
				)
				.sort((a, b) => a.caption.localeCompare(b.caption));

			const sortCategories = [
				{ titleSuffix: "a-to-z", sortOrder: EntitySortOrder.Asc, order: "aToZ" },
				{ titleSuffix: "z-to-a", sortOrder: EntitySortOrder.Desc, order: "zToA" },
			];

			const newSortProperties = sortedProperties.reduce((acc: EntitySortOption[], sp) => {
				sortCategories.forEach((category) => {
					acc.push({
						idTitle: `${sp.propertyName}-${category.titleSuffix}`,
						selected:
							!!tempSort &&
							tempSort.order === category.sortOrder &&
							tempSort.property === sp.propertyName,
						onClick: () =>
							setTempSort({
								order: category.sortOrder,
								property: sp.propertyName,
								caption: sp.caption,
							}),
						label: t("display:entitySelect.label", {
							title: sp.caption,
							order: t(
								`display:entitySelect.${category.order as TranslationSortOrderKey}`,
							),
						}),
					});
				});

				return acc;
			}, []);

			setSortOptions(newSortProperties);
		}
	}, [data, t, tempSort, field.propertyName]);

	return (
		<div className="she-entity-list">
			<div className="she-entity-list__search">
				<SearchBox
					onSearchBegan={() => {}}
					onSearchEnd={() => {}}
					onSearchTermChange={onSearchTermChange}
					reference={searchBoxRef}
					searchTerm={searchTerm || ""}
				/>
			</div>

			{sortOptions && sortOptions.length > 0 && (
				<div className="she-entity-list__sort">
					<button
						className="she-btn she-btn-tertiary she-entity-list__sort-button"
						onClick={() => {
							setShowSortModal(true);
							setTempSort(sort);
						}}
						type="button"
					>
						<IconSort aria-hidden />
						{t("display:entitySelect.buttonLabelSort")}
					</button>
				</div>
			)}
			<div className="she-entity-list__list">
				{data && data.list && data.list.length > 0 && (
					<TouchInputBlurrer refToBlur={searchBoxRef}>
						<div className="she-entity-list__numbers">
							{t("display:entitySelect.labelShowingRecordsNumberSortedBy", {
								labelShowingRecordsNumber: recordsDetails,
								sortedBy: t("display:entitySelect.sortedBy", {
									label: t("display:entitySelect.label", {
										title: sort.caption,
										order:
											sort.order === EntitySortOrder.Asc
												? t("display:entitySelect.aToZ")
												: t("display:entitySelect.zToA"),
									}),
								}),
							})}
						</div>

						{data.list &&
							data.list.map((entity: Entity) => (
								<EntitySelectInputListItem
									entity={entity}
									fieldId={field.id}
									groupText={groupText}
									key={entity.id}
									onSelectEntity={() => onSelectEntity(entity)}
									selected={!!selectedEntity && selectedEntity.id === entity.id}
								/>
							))}

						<div className="she-entity-list__numbers">{recordsDetails}</div>
						<div className="she-entity-list__pagination">
							{data && (
								<Pagination
									currentPage={page + 1}
									maxPages={Math.ceil(data.totalDataCount / pageSize)}
									onChangePage={onChangePage}
								/>
							)}
						</div>
					</TouchInputBlurrer>
				)}

				{isLoading && <div className="she-entity-list__status">{t("global:loading")}</div>}

				{isError && (
					<div className="she-entity-list__status">{t("error:dataRetrievalFailed")}</div>
				)}

				{data && data.list && data.list.length === 0 && (
					<div className="she-entity-list__status">
						{searchTerm
							? t("display:labelNoSearchResults")
							: t("display:labelEntityEmptyList")}
					</div>
				)}
			</div>

			<FormModal
				onCancel={() => setShowSortModal(false)}
				onOk={() => handleSetSort()}
				show={showSortModal}
				title="Sort"
			>
				<div className="she-components-control-question">
					{t("display:entitySelect.sortRecordsBy")}
				</div>
				{sortOptions.map((sortOption) => (
					<RadioButton
						id={sortOption.idTitle}
						key={sortOption.idTitle}
						name="entity-sort"
						onClick={sortOption.onClick}
						selected={sortOption.selected}
					>
						{sortOption.label}
					</RadioButton>
				))}
			</FormModal>
		</div>
	);
};
