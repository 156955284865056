import { useHistory } from "react-router-dom";
import { ReactComponent as IconCog } from "../../../../assets/svg/cog.svg";
import { usePortalPath } from "../../../../../helpers/UsePortalPath";
import "./SettingsButton.styles.scss";

const SettingsButton = () => {
	const portalPath = usePortalPath();
	const history = useHistory();

	const changePage = () => {
		history.push(`${portalPath}settings`);
	};
	return (
		<button
			aria-label="open settings"
			className="she-main-nav__button"
			onClick={changePage}
			type="button"
		>
			<IconCog className="she-main-nav__icon-settings" />
		</button>
	);
};

export { SettingsButton };
