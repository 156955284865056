import { t } from "i18next";
import moment from "moment";
import { FieldType } from "../../models/questionnaire";
import {
	combineDateString,
	combineTimeString,
	combineDateTimeString,
	manipulateMaxDate,
	getTimeFormat,
	getDateTimeFormat,
	getMomentDateFromDateValues,
} from "../../helpers/DateTimeInputHelper";
import type { DateField } from "../../models/fields/DateField";
import type { TimeField } from "../../models/fields/TimeField";
import type { DateTimeField } from "../../models/fields/DateTimeField";
import type { IQDateField } from "../../models/fields/IQDateField";
import type { IQDateTimeField } from "../../models/fields/IQDateTimeField";
import type { Field } from "../../models/fields/Field";

export class DateTimeFieldValidator {
	field: DateField | TimeField | DateTimeField | IQDateField | IQDateTimeField;
	messages: string[];

	constructor(field: DateField | TimeField | DateTimeField | IQDateField | IQDateTimeField) {
		this.field = field;
		this.messages = [];
	}

	isFieldValid = (valueObject?: any, fields?: Field[]): boolean => {
		this.messages = [];
		let valueIsValid = true;
		let valuesAreFilledIn = false;

		if (
			valueObject &&
			((valueObject.day !== undefined &&
				valueObject.day !== null &&
				valueObject.day !== "") ||
				(valueObject.month !== undefined &&
					valueObject.month !== null &&
					valueObject.month !== "") ||
				(valueObject.year !== undefined &&
					valueObject.year !== null &&
					valueObject.year !== "") ||
				(valueObject.hour !== undefined &&
					valueObject.hour !== null &&
					valueObject.hour !== "") ||
				(valueObject.minute !== undefined &&
					valueObject.minute !== null &&
					valueObject.minute !== ""))
		) {
			valuesAreFilledIn = true;
		}

		// When all values are empty and not mandatory, no need to show validation
		if (valuesAreFilledIn) {
			if (
				(this.field.type === FieldType.Date || this.field.type === FieldType.IQDate) &&
				moment(combineDateString(valueObject), "DD/MM/YYYY", true).isValid() === false
			) {
				valueIsValid = false;
				this.messages.push(t("validation:invalidDateMessage"));
			} else if (
				this.field.type === FieldType.Time &&
				(!moment(combineTimeString(valueObject), getTimeFormat(), true).isValid() ||
					valueObject.hour >= 24 ||
					(valueObject.amPm &&
						valueObject.amPm !== "" &&
						(valueObject.hour > 12 || valueObject.hour === "00")))
			) {
				valueIsValid = false;
				this.messages.push(t("validation:invalidTimeMessage"));
			} else if (
				this.field.type === FieldType.DateTime ||
				this.field.type === FieldType.IQDateTime
			) {
				if (
					moment(combineDateString(valueObject), "DD/MM/YYYY", true).isValid() === false
				) {
					valueIsValid = false;
					this.messages.push(t("validation:invalidDateMessage"));
				}

				if (
					!moment(combineTimeString(valueObject), getTimeFormat(), true).isValid() ||
					valueObject.hour >= 24 ||
					(valueObject.amPm &&
						valueObject.amPm !== "" &&
						(valueObject.hour > 12 || valueObject.hour === "00"))
				) {
					valueIsValid = false;
					this.messages.push(t("validation:invalidTimeMessage"));
				}
			}

			if (!valueIsValid) {
				if (this.field.isMandatory) {
					this.messages.push(t("validation:mandatoryFieldMessage"));
				}
			} else if (
				valueIsValid &&
				(this.field.type === FieldType.Date ||
					this.field.type === FieldType.DateTime ||
					this.field.type === FieldType.IQDate ||
					this.field.type === FieldType.IQDateTime)
			) {
				// Only do min/max checks date/time values are valid
				let minDate;
				let maxDate;
				let formatToUse;
				let valueToCheck;

				if (this.field.type === FieldType.Date || this.field.type === FieldType.IQDate) {
					formatToUse = "DD/MM/YYYY";
					valueToCheck = moment(combineDateString(valueObject), formatToUse, true);
				} else {
					formatToUse = getDateTimeFormat();
					valueToCheck = moment(combineDateTimeString(valueObject), formatToUse, true);
				}

				if (this.field.type === FieldType.Date || this.field.type === FieldType.IQDate) {
					if (
						this.field.isPastAllowed !== undefined &&
						this.field.isPastAllowed === false
					) {
						minDate = moment().startOf("day").subtract(1);
					}
					if (
						this.field.isFutureAllowed !== undefined &&
						this.field.isFutureAllowed === false
					) {
						maxDate = moment().endOf("day").add(1);
					}
				}

				if (
					this.field.min &&
					minDate === undefined &&
					(this.field.type === FieldType.Date || this.field.type === FieldType.IQDate)
				) {
					minDate = moment(this.field.min).subtract(1, "days"); // For Date we need to subtract a day as the min day is still valid
				} else if (
					this.field.min &&
					minDate === undefined &&
					(this.field.type === FieldType.DateTime ||
						this.field.type === FieldType.IQDateTime)
				) {
					minDate = moment(this.field.min).subtract(1, "minute"); // For DateTime we minus 1 minute.
				} else if (minDate === undefined) {
					minDate = moment("31/12/1899", "DD/MM/YYYY");
				}

				if (
					this.field.max &&
					maxDate === undefined &&
					(this.field.type === FieldType.Date || this.field.type === FieldType.IQDate)
				) {
					maxDate = moment(this.field.max).add(1, "days"); // For Date we need to add a extra day as the max day is still valid
				} else if (
					this.field.max &&
					maxDate === undefined &&
					(this.field.type === FieldType.DateTime ||
						this.field.type === FieldType.IQDateTime)
				) {
					maxDate = manipulateMaxDate(moment(this.field.max).toDate());
				} else if (maxDate === undefined) {
					maxDate = moment("01/01/2201", "DD/MM/YYYY");
				}

				if (minDate) {
					// Just check the date is after the min value
					if (!valueToCheck.isAfter(minDate)) {
						this.messages.push(t("validation:invalidDateRange"));
					}
				}

				if (maxDate) {
					// Just check the date is before the max value
					if (!valueToCheck.isBefore(maxDate)) {
						if (
							(this.field.type === FieldType.Date ||
								this.field.type === FieldType.IQDate) &&
							this.field.isFutureAllowed === false
						) {
							this.messages.push(t("validation:invalidDateRangeFuture"));
						} else {
							this.messages.push(t("validation:invalidDateRange"));
						}
					}
				}

				if (fields) {
					const { attributes } = this.field;
					if (
						attributes &&
						attributes.DateNotBefore &&
						attributes.DateNotBefore.CompareTo
					) {
						const parentField = fields.find(
							(field) => field.propertyName === attributes.DateNotBefore.CompareTo,
						);

						if (parentField && parentField.value) {
							const date = getMomentDateFromDateValues(valueObject);
							const parentDate = getMomentDateFromDateValues(parentField.value);

							if (date < parentDate) {
								this.messages.push(
									t("validation:dateNotBeforeError", {
										parentFieldName: parentField.name,
									}),
								);
							}
						}
					}
				}
			}
		} else if (!valuesAreFilledIn && this.field.isMandatory) {
			this.messages.push(t("validation:mandatoryFieldMessage"));
		}

		return this.messages.length <= 0;
	};
}
