import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getApplicationId } from "../../../utilities/ApplicationId";
import "./AppId.styles.scss";

export const AppId = () => {
	const { t } = useTranslation();
	const [appId, setAppId] = useState<string | null>();

	useEffect(() => {
		setAppId(getApplicationId());
	}, []);

	return (
		<>
			{appId && (
				<div className="she-portal-footer-app-id">
					<label className="she-app-id-label">
						{t("display:applicationId", { id: appId })}
					</label>
				</div>
			)}
		</>
	);
};
