import { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ProjectSelectFieldValidator } from "../../../../validators/projectSelectFieldValidator";
import { FormModal, ConfirmationModal } from "../../modals";
import { ProjectSelectInputList } from "../ProjectSelectInputList";
import { getPortalDateFormat } from "../../../../helpers/DateFormatHelper";
import { ProjectSelectInputPresentation } from "./ProjectSelectInput.presentation";
import type { State } from "../../../../state";
import type { Portal } from "../../../../models/portal";
import type { ProjectSelectField } from "../../../../models/fields/ProjectSelctField";
import type { Project } from "../../../../models/project/project";

interface Props {
	field: ProjectSelectField;
	updateField: (fieldId: number, value: any) => void;
	updateValidationStatus: (fieldId: number, value: string[]) => void;
}

export const ProjectSelectInput = ({ field, updateField, updateValidationStatus }: Props) => {
	const { t } = useTranslation();

	const [showProjectModal, setShowProjectModal] = useState(false);
	const [showRemoveModal, setShowRemoveModal] = useState(false);
	const [checkedProject, setCheckedProject] = useState<Project | undefined>(field.value);

	const fieldValidator = new ProjectSelectFieldValidator(field);

	const portal = useSelector<State, Portal | undefined>((state) =>
		state.portal.portals.find(
			(p) =>
				state.questionnaire.questionnaire &&
				p.key === state.questionnaire.questionnaire.portalKey,
		),
	);
	const dateFormat = useMemo(() => {
		return getPortalDateFormat(portal && portal.dateFormat);
	}, [portal]);

	const caption = useMemo(
		() =>
			t(checkedProject ? "display:labelChangeProject" : "display:labelSelectProject", {
				projectCaption: field.name,
			}),
		[t, field.name, checkedProject],
	);

	const onDisplayModal = () => {
		setShowProjectModal(true);
	};

	const onCancel = () => {
		setShowProjectModal(false);
		setCheckedProject(field.value);
	};

	const onOk = () => {
		fieldValidator.isFieldValid(checkedProject);
		updateField(field.id, checkedProject);
		updateValidationStatus(field.id, fieldValidator.messages);
		setShowProjectModal(false);
	};

	const onRemove = () => {
		setCheckedProject(undefined);
		fieldValidator.isFieldValid(undefined);
		updateField(field.id, undefined);
		updateValidationStatus(field.id, fieldValidator.messages);
		setShowRemoveModal(false);
	};

	return (
		<>
			<ProjectSelectInputPresentation
				buttonLabel={caption}
				dateFormat={dateFormat}
				onClick={onDisplayModal}
				onRemove={() => setShowRemoveModal(true)}
				project={field.value}
				removeLabel={t("display:buttonDelete")}
			/>
			<FormModal
				large
				onCancel={onCancel}
				onOk={onOk}
				padded={false}
				show={showProjectModal}
				title={t("display:labelSelectProject", {
					projectCaption: field.name,
				})}
			>
				<ProjectSelectInputList
					dateFormat={dateFormat}
					field={field}
					onSelectProject={setCheckedProject}
					portalCulture={portal ? portal.dateFormat : "Uk"}
				/>
			</FormModal>
			<ConfirmationModal
				onCancel={() => {
					setShowRemoveModal(false);
				}}
				onConfirm={onRemove}
				show={showRemoveModal}
				text={t("display:labelClearProjectSelectField")}
			/>
		</>
	);
};
