import TextareaAutosize from "react-textarea-autosize";
import { t } from "i18next";
import { NumberInputContainer } from "../NumberInput/NumberInput.container";
import { AnimationWrapper } from "../../animation/AnimationWrapper";
import { AnimationSlideFade } from "../../animation/AnimationSlideFade";
import type { BaseNumberField } from "../../../../models/fields/NumberField";
import type { NumberFieldValidator } from "../../../../validators/numberFieldValidator";

interface Props {
	guid: string;
	scoreField: BaseNumberField;
	numberValidator: NumberFieldValidator;
	isNotApplicable: boolean;
	isMandatory: boolean;
	scoreComment?: string;
	scoreCommentRows?: number;
	updateScore: (value: any) => void;
	updateScoreValidationStatus: (value: any) => void;
	onCommentChange: (value: string) => void;
	saveScoreComment: () => void;
}

export const AuditInputPresentation = ({
	guid,
	scoreField,
	numberValidator,
	isNotApplicable,
	isMandatory,
	scoreComment,
	scoreCommentRows,
	updateScore,
	updateScoreValidationStatus,
	onCommentChange,
	saveScoreComment,
}: Props) => {
	return (
		<AnimationWrapper>
			{!isNotApplicable && (
				<AnimationSlideFade key={guid} measure>
					<div id={`${guid}-group`} role="group">
						<label
							className="she-components-control-subtitle"
							htmlFor={scoreField.guid}
							id={`${scoreField.guid}-label`}
						>
							{t("display:labelScore")}
							<abbr
								className="she-components-control-required-indicator"
								title={
									isMandatory ? t("global:required") : t("display:labelOptional")
								}
							>
								{isMandatory ? "*" : ""}
							</abbr>
						</label>
						<NumberInputContainer
							numberField={scoreField}
							updateField={updateScore}
							updateValidationStatus={updateScoreValidationStatus}
							validator={numberValidator}
						/>

						<label
							className="she-components-control-subtitle"
							htmlFor={guid}
							id={`${guid}-label`}
						>
							{t("display:labelScoreComment")}
						</label>
						<TextareaAutosize
							aria-describedby={`${guid}-label`}
							className="she-components-text-input"
							disabled={isNotApplicable}
							id={guid}
							onBlur={saveScoreComment}
							onChange={(e) => onCommentChange(e.target.value)}
							rows={scoreCommentRows}
							value={scoreComment}
						/>
					</div>
				</AnimationSlideFade>
			)}
		</AnimationWrapper>
	);
};
