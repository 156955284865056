import { useState } from "react";
import classNames from "classnames";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import placeholder from "../../../../assets/png/training-placeholder.png";
import { getPortalDateFormat } from "../../../../../helpers/DateFormatHelper";
import type { State } from "../../../../../state";
import type { Portal } from "../../../../../models/portal";
import type { TrainingRecord, TrainingStatus } from "../../../../../models/training";
import "./training-card.scss";

export const TrainingCard = ({
	dueDate,
	linkUrl,
	imageUrl,
	status,
	title,
	isUnlinked,
}: TrainingRecord) => {
	const [isImageBroken, setIsImageBroken] = useState<boolean>(false);
	const { t } = useTranslation();

	const statusInfo: Record<keyof typeof TrainingStatus, { key: string; label: string }> = {
		NotStarted: {
			key: "not-started",
			label: t("display:labelTrainingStatusNotStarted"),
		},
		InProgress: {
			key: "in-progress",
			label: t("display:labelTrainingStatusInProgress"),
		},
		Completed: {
			key: "completed",
			label: t("display:labelTrainingStatusCompleted"),
		},
	};

	const currentStatus = statusInfo[status];
	const isOverdue = moment(dueDate).diff(moment()) <= 0;

	const { portalKey, customerKey } = useParams<{ portalKey: string; customerKey: string }>();

	const portal = useSelector<State, Portal | undefined>((s) =>
		s.portal.portals.find(
			(p) => p.key === portalKey.toLowerCase() && p.customerKey === customerKey.toLowerCase(),
		),
	);

	const dateFormat = getPortalDateFormat(portal?.dateFormat);
	const formattedDate = moment(dueDate).format(dateFormat);

	return (
		<a
			className={classNames("training-card", `training-card--${currentStatus.key}`, {
				"training-card--unlinked": isUnlinked,
			})}
			data-testid="training-card"
			href={linkUrl}
			rel="noopener noreferrer"
			target="_blank"
		>
			<div className="training-card__graphic">
				<img
					alt={title}
					className="training-card__image"
					onError={() => setIsImageBroken(true)}
					src={isImageBroken || !imageUrl ? placeholder : imageUrl}
				/>
			</div>

			<div className="training-card__content">
				<div className="training-card__info">
					<div className="training-card__heading">
						<p className="training-card__title">{title}</p>
						{isUnlinked && (
							<p className="training-card__unlinked">
								{t("display:labelTrainingIsUnlinked")}
							</p>
						)}
					</div>

					<div>
						<p
							className={classNames(
								"training-card__status",
								`training-card__status--${currentStatus.key}`,
							)}
						>
							{currentStatus.label}
						</p>
					</div>
				</div>

				{status !== "Completed" && formattedDate ? (
					<div className="training-card__footer" data-testid="training-card-footer">
						<div className="training-card__due-date">
							<p className="training-card__due-date-label">
								{t("display:labelTrainingDueDate", {
									date: formattedDate,
									interpolation: { escapeValue: false },
								})}
							</p>

							{isOverdue ? (
								<p
									className="training-card__overdue"
									data-testid="training-card-overdue"
								>
									{t("display:labelOverDue")}
								</p>
							) : null}
						</div>
					</div>
				) : null}
			</div>
		</a>
	);
};

TrainingCard.displayName = "TrainingCard";
