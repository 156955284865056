import { useCallback, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import type { LatLngBounds, Map } from "react-leaflet";
import type { LatLngBoundsExpression } from "leaflet";

export interface CopyrightInfo {
	minLevel: number;
	maxLevel: number;
	label: string;
	bounds: LatLngBounds[] | undefined;
}

export function useMapCopyrightInfo(copyrightUrl: string) {
	const { data } = useQuery([copyrightUrl], () => getCopyrightBounds(copyrightUrl), {
		cacheTime: Infinity,
		staleTime: Infinity,
		refetchOnMount: false,
		refetchOnWindowFocus: false,
	});

	const year = useMemo(() => {
		return new Date().getFullYear();
	}, []);

	const getCopyrightInfo = useCallback(
		(refMap: React.RefObject<Map>) => {
			const map = refMap.current && refMap.current.leafletElement;
			let copyrights = `&copy; ${year} HERE`;
			if (data && map) {
				data.forEach((ci) => {
					if (map.getZoom() >= ci.minLevel && map.getZoom() <= ci.maxLevel) {
						if (ci.bounds) {
							if (
								ci.bounds.some((b) =>
									map.getBounds().intersects(b as LatLngBoundsExpression),
								)
							) {
								if (!copyrights.includes(ci.label)) {
									copyrights += `, ${ci.label}`;
								}
							}
						} else if (!copyrights.includes(ci.label)) {
							// No bounds, always show
							copyrights += `, ${ci.label}`;
						}
					}
				});
			}
			return copyrights;
		},
		[data, year],
	);
	return getCopyrightInfo;
}

export async function getCopyrightBounds(url: string): Promise<CopyrightInfo[]> {
	const result = await axios.get(url);
	if (result.data) {
		let copyInfo: CopyrightInfo[] = [];
		if (result.data.satellite) {
			copyInfo = copyInfo.concat(mapBounds(result.data.satellite));
		}
		if (result.data.normal) {
			copyInfo = copyInfo.concat(mapBounds(result.data.normal));
		}
		if (!copyInfo.length) {
			throw new Error("Cannot get copyright bounds");
		}
		return copyInfo;
	}
	throw new Error("Cannot get copyright bounds");
}

export function mapBounds(copyInfo: []) {
	return copyInfo.map((dtoCopy: any): CopyrightInfo => {
		return {
			minLevel: dtoCopy.minLevel,
			maxLevel: dtoCopy.maxLevel,
			label: dtoCopy.label,
			bounds: dtoCopy.boxes?.map((b: any) => {
				return [
					[b[0], b[1]],
					[b[2], b[3]],
				];
			}),
		};
	});
}
