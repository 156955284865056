import type { QuestionnaireEncryptionVersion } from "../questionnaire";

export interface Attachment {
	id: string;
	originalFileName: string;
	fileName: string;
	recordId: string;
	file: File;
	parentGuid?: string;
	isImage: boolean;
	isSVG?: boolean;
	description?: string;
	parentType: AttachmentParentType;
	userId?: string;
}

export interface StoredAttachment extends Attachment {
	file: any;
	encryptionVersion?: QuestionnaireEncryptionVersion;
}

export enum AttachmentParentType {
	Record,
	Field,
	Submodule,
}

export function isEncryptedAttachment(attachment: Attachment) {
	return !!attachment.userId;
}
