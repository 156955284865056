// define and export the actions that this state component can handle
// - text is used instead of regular numeric enumeration so that inspection
// - of state mutations (from the Redux devtools for example) is easily readable
export enum QuestionnaireActionTypes {
	Create = "QUESTIONNAIRE_Create",
	UpdateField = "QUESTIONNAIRE_UpdateField",
	UpdateFieldProperty = "QUESTIONNAIRE_UpdateFieldProperty",
	UpdateValidationStatus = "QUESTIONNAIRE_UpdateValidationStatus",
	Cancel = "QUESTIONNAIRE_cancel",
	CreateSubModuleRecord = "QUESTIONNAIRE_createSubModuleRecord",
	DeleteSubModuleRecord = "QUESTIONNAIRE_deleteSubModuleRecord",
	EditSubModuleRecord = "QUESTIONNAIRE_editSubModuleRecord",
	GetDictionaries = "QUESTIONNAIRE_GetDictionaries",
	SetDictionaryLoadingState = "QUESTIONNAIRE_SetDictionaryLoadingState",
	AttachDictionary = "QUESTIONNAIRE_AttachDictionary",
	Submit = "QUESTIONNAIRE_Submit",
	UpdateStatus = "QUESTIONNAIRE_UpdateStatus",
	AddMatrix = "QUESTIONNAIRE_AddMatrix",
	UpdateBranchCondition = "QUESTIONNAIRE_UpdateBranchCondition",
	UpdateComment = "QUESTIONNAIRE_UpdateComment",
	UpdateNotApplicable = "QUESTIONNAIRE_UpdateNotApplicable",
	CreateNote = "QUESTIONNAIRE_CreateNote",
	EditNote = "QUESTIONNAIRE_EditNote",
	DeleteNote = "QUESTIONNAIRE_DeleteNote",
	BranchingInitialised = "QUESTIONNAIRE_BranchingInitialised",
	BranchingTriggered = "QUESTIONNAIRE_BranchingTriggered",
	EvaluatePicklistsTriggered = "QUESTIONNAIRE_EvaluatePicklistsTriggered",
	UpdateIndirectPicklistTriggered = "QUESTIONNAIRE_updateIndirectPicklistTriggered",
	CreateActionRecord = "QUESTIONNAIRE_createActionRecord",
	DeleteActionRecord = "QUESTIONNAIRE_deleteActionRecord",
	EditActionRecord = "QUESTIONNAIRE_editActionRecord",
	UpdateProgress = "QUESTIONNAIRE_updateProgress",
	Load = "QUESTIONNAIRE_load",
	MakeDirty = "QUESTIONNAIRE_makeDirty",
	FileSizeError = "QUESTIONNAIRE_FileSizeError",
}
