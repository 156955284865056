import { createRef } from "react";
import { ReactComponent as IconBack } from "../../assets/svg/arrow-left.svg";
import { ReactComponent as Bin } from "../../assets/svg/bin-1.svg";
import { ReactComponent as Edit } from "../../assets/svg/image-file-edit.svg";
import { AnnotateImage } from "../AnnotateImage";

interface Props {
	src: string;
	alt: string;
	onCancel: () => void;
	onDelete: () => void;
	onEdit?: () => void;
	onEditSave?: (dataURI: string) => void;
	onEditClose?: () => void;
	deleteButtonText: string;
	backButtonText: string;
	editButtonText: string;
	type: string;
	isEditing?: boolean;
	hideControls?: boolean;
	displayEditButton?: boolean;
}

export const LargeImage = ({
	src,
	alt,
	onCancel,
	onDelete,
	onEdit,
	onEditSave,
	onEditClose,
	deleteButtonText,
	backButtonText,
	editButtonText,
	type,
	isEditing,
	hideControls,
	displayEditButton,
}: Props) => {
	const imgRef = createRef<HTMLImageElement>();

	return (
		<>
			<img alt={alt} className="she-components-modal-lightbox-image" ref={imgRef} src={src} />
			{!isEditing && !hideControls && (
				<div className="she-components-modal-lightbox-overlay">
					<button
						aria-label={backButtonText}
						className="she-icon she-components-modal-lightbox-icon she-components-modal-lightbox-icon__back"
						onClick={onCancel}
						type="button"
					>
						<IconBack />
					</button>
					<div>
						{displayEditButton && (
							<button
								aria-label={editButtonText}
								className="she-icon she-components-modal-lightbox-icon"
								onClick={onEdit}
								type="button"
							>
								<Edit />
							</button>
						)}
						<button
							aria-label={deleteButtonText}
							className="she-icon she-components-modal-lightbox-icon"
							onClick={onDelete}
							type="button"
						>
							<Bin />
						</button>
					</div>
				</div>
			)}
			{isEditing && onEditClose && onEditSave && (
				<AnnotateImage
					delayEntry={hideControls}
					imgRef={imgRef}
					onClose={onEditClose}
					onSave={onEditSave}
					type={type}
				/>
			)}
		</>
	);
};
