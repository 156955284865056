import { useAuth } from "oidc-react";
import { useTranslation } from "react-i18next";
import { ConfirmationModal } from "..";

interface Props {
	show: boolean;
	onCancel: () => void;
}

export const SignOutModal = ({ show, onCancel }: Props) => {
	const { signOutRedirect } = useAuth();
	const { t } = useTranslation();
	return (
		<ConfirmationModal
			cancelBtnIsPrimary={false}
			cancelText={t("global:cancel")}
			centredBody
			confirmBtnIsDanger={false}
			confirmBtnIsPrimary
			confirmText={t("display:authentication.logout")}
			onCancel={onCancel}
			onConfirm={() => {
				signOutRedirect();
			}}
			show={show}
			text={t("display:authentication.logout")}
			textExtraClass="she-components-modal-body-title"
		>
			{t("display:authentication.confirmLogout")}
		</ConfirmationModal>
	);
};
