export interface Record {
	heading: {
		title: string;
		ref: string;
	};
	recordId: string;
	info: Info[];
	hasAttachments: boolean;
	hasReport: boolean;
}

export interface Info {
	propertyName: string;
	title: string;
	detail: string;
}

export interface RecordAttachment {
	Identifier: string | null;
	FileName: string | null;
	FileDescription: string | null;
	FileSize: number | null;
	AttachmentType: AttachmentType | null;
	Link: string | null;
	DateCreated: Date | null | string;
}

export enum AttachmentType {
	Link = "Link",
	File = "File",
}

export interface RecordSortPropertyInfo {
	propertyName: string;
	caption: string;
	type: RecordPropertyType;
	order: RecordSortOrder;
}

export interface Records {
	list: Record[];
	module: RecordModule;
	totalDataCount: number;
	sortProperties: RecordSortPropertyInfo[];
}

export enum RecordModule {
	ActivityRisk = "ActivityRisk",
	SupplierContractor = "SupplierContractor",
	GhsSDS = "GhsSDS",
	COSHH = "COSHH",
	ManualHandling = "ManualHandling",
	GeneralRisk = "GeneralRisk",
	COSHHActivity = "COSHHActivity",
	Policy = "Policy",
	MSDS = "MSDS",
	Noise = "Noise",
	JobHazardAnalysis = "JobHazardAnalysis",
	FireRisk = "FireRisk",
	ProjectRisk = "ProjectRisk",
	DSE = "DSE",
}

export enum RecordSortOrder {
	Asc = "Asc",
	Desc = "Desc",
}

export enum RecordPropertyType {
	Date,
	String,
	Number,
}
