import { SignatureInputPresentation } from "./SignatureInput.presentation";
import type {
	SignatureField,
	SimplifiedSignatureField,
} from "../../../../models/fields/SignatureField";
import type { SignatureFieldValidator } from "../../../../validators/signatureFieldValidator";

interface Props {
	signatureField: SignatureField | SimplifiedSignatureField;
	updateField: (value: any) => void;
	updateValidationStatus: (value: string[]) => void;
	validator: SignatureFieldValidator;
	validateOnEnd?: boolean;
}

export const SignatureInputContainer = ({
	signatureField,
	updateField,
	updateValidationStatus,
	validator,
	validateOnEnd,
}: Props) => {
	const onClear = (signaturePad: any) => {
		if (signaturePad !== undefined && signaturePad !== null) {
			signaturePad.clear();
			updateField("");
			validate("");
		}
	};

	const onEnd = (signaturePad: any) => {
		if (signaturePad !== undefined && signaturePad !== null) {
			const base64Data = signaturePad.toDataURL("image/png");
			updateField(base64Data);
			validateOnEnd && validate(base64Data);
		}
	};

	const validate = (value?: string) => {
		const validationResult = validator.isFieldValid(value);
		if (validationResult !== (signatureField.validationMessage.length === 0)) {
			updateValidationStatus(validator.messages);
		}
	};

	return (
		<SignatureInputPresentation
			guid={signatureField.guid}
			onClear={onClear}
			onEnd={onEnd}
			value={signatureField.value || ""}
		/>
	);
};
