import { useState, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { AreFieldArraysEqual } from "../../../../../../helpers/EqualityHelper";
import { SectionMobileNavPresentation } from "./SectionMobileNav.presentation";
import type { Section, Status } from "../../../../../../models/questionnaire";
import type { State } from "../../../../../../state";
import type { Field } from "../../../../../../models/fields/Field";

interface Props {
	sectionIndex: number;
	changeSection: (index: number) => void;
}

export const SectionMobileNavContainer = ({ changeSection, sectionIndex }: Props) => {
	const [isSelectorOpened, setIsSelectorOpened] = useState(false);
	const status = useSelector<State, Status | undefined>((state) => state.questionnaire.status);
	const visibleSections = useSelector<State, Section[]>(
		(state) =>
			state.questionnaire.sections && state.questionnaire.sections.filter((s) => s.isVisible),
	);
	const fields = useSelector<State, Field[] | undefined>(
		(state) => state.questionnaire.fields,
		AreFieldArraysEqual,
	);
	const selectedSection: Section | undefined = visibleSections
		? visibleSections.find((s) => s.orderIndex === sectionIndex)
		: undefined;

	const closeMenu = () => setIsSelectorOpened(false);

	const toggleMenu = useCallback(() => {
		setIsSelectorOpened(!isSelectorOpened);
	}, [isSelectorOpened]);

	useEffect(() => {
		if (isSelectorOpened) {
			document
				.getElementsByTagName("body")[0]
				.classList.add("she-components-navigation-body");
		} else {
			document
				.getElementsByTagName("body")[0]
				.classList.remove("she-components-navigation-body");
		}
		return () => {
			document
				.getElementsByTagName("body")[0]
				.classList.remove("she-components-navigation-body");
		};
	}, [isSelectorOpened]);

	const changeSectionIndex = useCallback(
		(index: number) => {
			closeMenu();
			changeSection(index);
		},
		[changeSection],
	);

	const getSectionPosition = useCallback((): number => {
		const indices = visibleSections ? visibleSections.map(({ orderIndex }) => orderIndex) : [];
		return indices.indexOf(sectionIndex) + 1;
	}, [sectionIndex, visibleSections]);

	const getSectionsWithValidationErrors = (): number[] => {
		const sectionIds: number[] = [];
		if (status && status.isValidated) {
			visibleSections.forEach((s) => {
				const fieldsWithValidationErrors = fields?.filter(
					(f) =>
						f.groupId && f.id && f.validationMessage.length > 0 && f.sectionId === s.id,
				);
				if (fieldsWithValidationErrors && fieldsWithValidationErrors.length > 0) {
					sectionIds.push(s.id);
				}
			});
		}
		return sectionIds;
	};

	const sectionsWithValidationErrors = getSectionsWithValidationErrors();

	return (
		<SectionMobileNavPresentation
			changeSection={changeSectionIndex}
			closeMenu={closeMenu}
			isSelectorOpened={isSelectorOpened}
			sectionPosition={getSectionPosition()}
			sections={visibleSections}
			sectionsWithValidationErr={sectionsWithValidationErrors}
			selectedSection={selectedSection}
			toggleMenu={toggleMenu}
		/>
	);
};
