import classnames from "classnames";
import "./Menu.styles.scss";

interface Props {
	bindIconToSelected?: boolean;
	children: React.ReactNode;
	icon?: React.ReactElement;
	onClick?: () => void;
	selected?: boolean;
}

export const MenuItem = ({
	bindIconToSelected = false,
	children,
	icon: Icon,
	onClick,
	selected = true,
}: Props) => {
	const className = classnames("she-menu__item", { "she-menu__item--selected": selected });

	const handleClick = (event: React.MouseEvent) => {
		event.stopPropagation();
		if (typeof onClick === "function") {
			onClick();
		}
	};

	return (
		<li
			className={className}
			onClick={handleClick}
			onKeyDown={(e) => e.key === "Enter" && onClick && onClick()}
			role="menuitem"
			tabIndex={0}
		>
			{Icon && (
				<span
					className={classnames("she-menu__item__icon", {
						"she-menu__item__icon--conditional": bindIconToSelected,
					})}
				>
					{Icon}
				</span>
			)}

			{children}
		</li>
	);
};
