import { useTranslation } from "react-i18next";
import { RadioButton } from "../../../../components/input/RadioButton";
import "./PageLanguageSwitcher.styles.scss";
import type { PortalTranslationLanguage } from "../../../../../types/i18next";

interface Props {
	languageLabel: string;
	languages: Record<string, string>;
	onChange: (key: string) => void;
	selectedLanguage: string;
}

export const PageLanguageSwitcherPresentation = ({
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	languageLabel,
	languages,
	onChange,
	selectedLanguage,
}: Props) => {
	const { t } = useTranslation();
	const languageItems = Object.keys(languages).map((k) => (
		<div className="she-language-container" key={k}>
			<RadioButton id={k} onClick={() => onChange(k)} selected={k === selectedLanguage}>
				<div className="she-language-container__text">
					<span className="she-language-container__title">
						{t(`display:language${k.replace("-", "") as PortalTranslationLanguage}`, {
							lng: k,
						})}
					</span>
					<span className="she-language-container__label">{languages[k]}</span>
				</div>
			</RadioButton>
		</div>
	));

	return <div className="she-page-language-switcher">{languageItems}</div>;
};
