import { FieldType, QuestionnaireProgressState } from "../../../models/questionnaire";
import { QuestionnaireActionTypes } from "./actions/types";
import type { Field } from "../../../models/fields/Field";
import type { IndirectField } from "../../../models/fields/IndirectField";
import type {
	AddMatrix,
	AttachDictionary,
	BranchingTriggered,
	CreateAction,
	CreateNote,
	CreateSubModuleRecord,
	DeleteNote,
	EditNote,
	EditSubModuleRecord,
	GetDictionaries,
	LoadQuestionnaire,
	MakeDirty,
	QuestionnaireAction,
	RemoveSubModuleRecord,
	SubmitAction,
	UpdateBranchCondition,
	UpdateComment,
	UpdateFieldAction,
	UpdateFieldPropertyAction,
	UpdateNotApplicable,
	UpdateQuestionnaireState,
	UpdateStatusAction,
	UpdateValidationStatus,
	FileSizeError,
	SetDictionariesLoading,
} from "./actions/definitions";
import type { QuestionnaireState } from "./model";
import type { OptionalSelectField } from "../../../models/fields/OptionalSelectField";
import type { Reducer } from "redux";

// define an initial state that adheres to the shape of the state required
const initialState: QuestionnaireState = {
	questionnaire: undefined,
	sections: [],
	groups: [],
	fields: [],
	subModules: [],
	subModuleRecords: [],
	dictionaries: [],
	isLoadingDictionaries: false,
	matrixes: [],
	branchConditions: [],
	notes: [],
	status: {
		isSubmittingInProgress: false,
		displayValidationMessage: false,
		errors: [],
		isValidated: false,
		questionnaireState: QuestionnaireProgressState.InProgress,
	},
};

// define and export a reducer that will potentially derive a new state from the current state and incoming actions
// to ensure type safety and expected behaviour, the Reducer type is generically typed with two type parameters
// - the shape of the state
// - and the potential actions that could affect this state
// the reducer receives two parameters
// - the current state (defaulted if undefined)
// - the action being evaluated
// its return type is implicitly set to the interface that defines the shape of the state
export const questionnaireReducer: Reducer<QuestionnaireState, QuestionnaireAction> = (
	state = initialState,
	action,
) => {
	// use a switch to benefit from the discriminated union (action definitions)
	// this (or a set of ifs) is a typescript compiler feature
	switch (action.type) {
		case QuestionnaireActionTypes.Create:
			// if the type has been found to match the given value (enum lookup for safety)
			// then within this local scope, the action will be typed correctly
			// state is derived by using the old state (... is object destructuring)
			// and combining it with the changes that reflect the current action
			return createQuestionnaire(state, action);
		case QuestionnaireActionTypes.UpdateField:
			return updateField(state, action);
		case QuestionnaireActionTypes.UpdateFieldProperty:
			return updateFieldProperty(state, action);
		case QuestionnaireActionTypes.UpdateValidationStatus:
			return updateValidationStatus(state, action);
		case QuestionnaireActionTypes.Submit:
			return submit(state, action);
		case QuestionnaireActionTypes.UpdateStatus:
			return updateStatus(state, action);
		case QuestionnaireActionTypes.Cancel:
			return initialState;
		case QuestionnaireActionTypes.CreateSubModuleRecord:
			return createSubModuleRecord(state, action);
		case QuestionnaireActionTypes.EditSubModuleRecord:
			return editSubModuleRecord(state, action);
		case QuestionnaireActionTypes.AddMatrix:
			return addMatrix(state, action);
		case QuestionnaireActionTypes.DeleteSubModuleRecord:
			return deleteSubModuleRecord(state, action);
		case QuestionnaireActionTypes.GetDictionaries:
			return getDictionaries(state, action);
		case QuestionnaireActionTypes.SetDictionaryLoadingState:
			return setDictionaryLoadingState(state, action);
		case QuestionnaireActionTypes.AttachDictionary:
			return attachDictionary(state, action);
		case QuestionnaireActionTypes.UpdateComment:
			return updateComments(state, action);
		case QuestionnaireActionTypes.UpdateBranchCondition:
			return updateBranchCondition(state, action);
		case QuestionnaireActionTypes.UpdateNotApplicable:
			return updateNotApplicable(state, action);
		case QuestionnaireActionTypes.CreateNote:
			return createNote(state, action);
		case QuestionnaireActionTypes.DeleteNote:
			return deleteNote(state, action);
		case QuestionnaireActionTypes.EditNote:
			return editNote(state, action);
		case QuestionnaireActionTypes.BranchingTriggered:
			return branchingTriggered(state, action);
		case QuestionnaireActionTypes.UpdateProgress:
			return updateProgress(state, action);
		case QuestionnaireActionTypes.Load:
			return loadFromRecord(state, action);
		case QuestionnaireActionTypes.MakeDirty:
			return makeDirty(state, action);
		case QuestionnaireActionTypes.FileSizeError:
			return fileSizeError(state, action);
		default:
			return state;
	}
};

const createQuestionnaire = (
	state: QuestionnaireState,
	action: CreateAction,
): QuestionnaireState => {
	return {
		...state,
		questionnaire: action.questionnaire,
		status: action.status,
		sections: [...state.sections, ...action.sections],
		groups: [...state.groups, ...action.groups],
		fields: [...state.fields, ...action.fields],
		subModules: [...state.subModules, ...action.subModules],
		branchConditions: [...state.branchConditions, ...action.branchConditions],
		dictionaries: [...state.dictionaries, ...action.dictionaries],
		matrixes: [...state.matrixes, ...action.matrixes],
		notes: [...state.notes, ...action.notes],
	};
};

const updateField = (state: QuestionnaireState, action: UpdateFieldAction): QuestionnaireState => {
	const dirty = state.isDirty;
	let fieldToUpdate: any;
	// TODO why is there a map here if ids are unique?
	const updatedFields = state.fields.map((field) => {
		if (
			field.type === FieldType.Indirect &&
			field.originalField.type === FieldType.OptionalSelect
		) {
			if (
				field.id !== action.fieldId &&
				field.relatedFields &&
				!field.relatedFields.some((rf) => rf.id === action.fieldId)
			) {
				return field;
			}

			if (action.additionalParams?.selectedFromRegister) {
				fieldToUpdate = field;
				return {
					...field,
					value: action.value,
					isMandatory: action.additionalParams.selectedFromRegister
						? field.originalField.isMandatory
						: false,
					originalField: {
						...field.originalField,
						...action.additionalParams,
					},
					hideValidationErrors: !action.additionalParams.selectedFromRegister,
					relatedFields: !field.relatedFields
						? field.relatedFields
						: field.relatedFields.map((relatedField) => {
								return {
									...relatedField,
									value: null,
									isUpdated: false,
									validationMessage: [],
								};
						  }),
					validationMessage: [],
				} as IndirectField;
			}

			return {
				...field,
				value: undefined,
				isUpdated: true,
				originalField: {
					...field.originalField,
					selectedFromRegister: false,
				},
				relatedFields: !field.relatedFields
					? field.relatedFields
					: field.relatedFields.map((relatedField) => {
							if (relatedField.id === action.fieldId) {
								fieldToUpdate = relatedField;
								return {
									...relatedField,
									value: action.value,
									isUpdated: true,
									validationMessage: [],
								};
							}

							return relatedField;
					  }),
			} as IndirectField;
		}
		if (field.id !== action.fieldId) {
			return field;
		}

		fieldToUpdate = field;
		return {
			...field,
			value: action.value,
			isUpdated: true,
		};
	});

	if (action.cleanUpdate !== false && fieldToUpdate.value === action.value) {
		action.cleanUpdate = true;
	}

	return {
		...state,
		fields: updatedFields,
		isDirty: action.cleanUpdate ? dirty : true,
	};
};

const updateFieldProperty = (
	state: QuestionnaireState,
	action: UpdateFieldPropertyAction,
): QuestionnaireState => {
	const dirty = state.isDirty;
	const nameSplit: string[] = action.propertyName.toString().split(".");

	if (nameSplit.length === 1) {
		return {
			...state,
			fields: state.fields.map((f) =>
				f.id !== action.fieldId
					? f
					: {
							...f,
							[action.propertyName]: action.value,
							isUpdated: true,
					  },
			),
			isDirty: action.cleanUpdate ? dirty : true,
		};
	} else if (nameSplit.length === 2) {
		return {
			...state,
			fields: state.fields.map((f) =>
				f.id !== action.fieldId
					? f
					: {
							...f,
							[nameSplit[0]]: {
								...(f as any)[nameSplit[0]],
								[nameSplit[1]]: action.value,
							},
							isUpdated: true,
					  },
			),
			isDirty: action.cleanUpdate ? dirty : true,
		};
	}
	return {
		...state,
	};
};

const hasRelatedField = (field: Field, fieldId: number) =>
	field.relatedFields?.some((rf) => rf.id === fieldId) ?? false;

const attachDictionary = (
	state: QuestionnaireState,
	action: AttachDictionary,
): QuestionnaireState => {
	const fields = state.fields.map((field) => {
		if (field.id !== action.fieldId && !hasRelatedField(field, action.fieldId)) {
			return field;
		}

		if (hasRelatedField(field, action.fieldId)) {
			return {
				...field,
				relatedFields: field.relatedFields?.map((relatedField) => {
					if (relatedField.id !== action.fieldId) {
						return relatedField;
					}

					return {
						...relatedField,
						data: action.data,
					};
				}),
			};
		}

		return {
			...field,
			data: action.data,
		};
	});

	return {
		...state,
		fields,
	};
};

const mapValidationMessageToRelatedField = (
	field: Field,
	action: UpdateValidationStatus,
): Field => {
	if (
		field.relatedFields &&
		field.relatedFields.length > 0 &&
		field.relatedFields.some((relatedField) => relatedField.id === action.fieldId)
	) {
		return {
			...field,
			relatedFields: field.relatedFields.map((relatedField) => {
				if (relatedField.id === action.fieldId) {
					return {
						...relatedField,
						validationMessage: action.value,
					};
				}
				return relatedField;
			}),
		};
	}
	return field;
};

const mapValidationMessageToField = (field: Field, action: UpdateValidationStatus) => {
	return {
		...field,
		validationMessage: action.value,
	} as Field;
};

const shouldMapToMainField = (field: Field) => {
	if (
		!field.relatedFields ||
		(field &&
			(field as IndirectField).originalField &&
			(((field as IndirectField).originalField as OptionalSelectField).selectedFromRegister ==
				null ||
				((field as IndirectField).originalField as OptionalSelectField)
					.selectedFromRegister))
	) {
		return true;
	}

	return false;
};

const updateValidationStatus = (
	state: QuestionnaireState,
	action: UpdateValidationStatus,
): QuestionnaireState => {
	return {
		...state,
		fields: state.fields.map((field) => {
			return field.id === action.fieldId && shouldMapToMainField(field)
				? mapValidationMessageToField(field, action)
				: mapValidationMessageToRelatedField(field, action);
		}),
	};
};

const submit = (state: QuestionnaireState, action: SubmitAction): QuestionnaireState => {
	return {
		...state,
		status: {
			isValidated: action.isValidated,
			isSubmitSuccess: action.isSubmitSuccess,
			isSubmittingInProgress: action.isSubmittingInProgress,
			errors: action.errors,
			displayValidationMessage: action.displayValidationMessage,
			firstSectionWithErrors: action.firstSectionIdWithErrors,
			questionnaireState: state.status.questionnaireState,
		},
	};
};

const updateStatus = (
	state: QuestionnaireState,
	action: UpdateStatusAction,
): QuestionnaireState => {
	return {
		...state,
		status: {
			isValidated: action.isValidated,
			isSubmitSuccess: action.isSubmitSuccess,
			isSubmittingInProgress: action.isSubmittingInProgress,
			errors: action.errors,
			displayValidationMessage: action.displayValidationMessage,
			dateCompleted: action.dateCompleted,
			questionnaireState: state.status && state.status.questionnaireState,
		},
	};
};

const createSubModuleRecord = (
	state: QuestionnaireState,
	action: CreateSubModuleRecord,
): QuestionnaireState => {
	return {
		...state,
		subModuleRecords: [...state.subModuleRecords, action.subModuleRecord],
		isDirty: true,
	};
};

const editSubModuleRecord = (
	state: QuestionnaireState,
	action: EditSubModuleRecord,
): QuestionnaireState => {
	const records = state.subModuleRecords.map((r) =>
		r.localId !== action.subModuleRecordId
			? r
			: {
					...r,
					values: action.subModuleRecord.values,
			  },
	);

	return {
		...state,
		subModuleRecords: records,
		isDirty: true,
	};
};

const addMatrix = (state: QuestionnaireState, action: AddMatrix): QuestionnaireState => {
	return {
		...state,
		matrixes: action.matrixes,
	};
};

const deleteSubModuleRecord = (
	state: QuestionnaireState,
	action: RemoveSubModuleRecord,
): QuestionnaireState => {
	return {
		...state,
		subModuleRecords: state.subModuleRecords.filter((x) => x.localId !== action.recordId),
		isDirty: true,
	};
};

const getDictionaries = (
	state: QuestionnaireState,
	action: GetDictionaries,
): QuestionnaireState => {
	return {
		...state,
		dictionaries: action.dictionaries,
	};
};

const setDictionaryLoadingState = (
	state: QuestionnaireState,
	action: SetDictionariesLoading,
): QuestionnaireState => ({ ...state, isLoadingDictionaries: action.isLoadingDictionaries });

const updateComments = (state: QuestionnaireState, action: UpdateComment): QuestionnaireState => {
	let originalCommentsValue;
	return {
		...state,
		fields: state.fields.map((f) => {
			if (f.id === action.fieldId) {
				originalCommentsValue = f.comments.value;
				return {
					...f,
					comments: {
						...f.comments,
						value: action.comments,
					},
				};
			}
			return f;
		}),
		isDirty: !(
			(originalCommentsValue === undefined && action.comments === "") ||
			originalCommentsValue === action.comments
		),
	};
};

const updateBranchCondition = (
	state: QuestionnaireState,
	action: UpdateBranchCondition,
): QuestionnaireState => {
	return {
		...state,
		branchConditions: state.branchConditions.map((bc) =>
			bc.id !== action.branchConditionId
				? bc
				: {
						...bc,
						istriggered: action.isTriggered,
				  },
		),
	};
};

const updateNotApplicable = (
	state: QuestionnaireState,
	action: UpdateNotApplicable,
): QuestionnaireState => {
	return {
		...state,
		fields: state.fields.map((f) =>
			f.id !== action.fieldId
				? f
				: {
						...f,
						isNotApplicable: action.notApplicable,
						comments: {
							...f.comments,
							isNotApplicableCommentsEnabled: action.comments,
						},
						actionValidationMessage: [],
						attachmentValidationMessage: [],
				  },
		),
		isDirty: true,
	};
};

const createNote = (state: QuestionnaireState, action: CreateNote): QuestionnaireState => {
	return {
		...state,
		notes: [...state.notes, action.note],
		isDirty: true,
	};
};

const deleteNote = (state: QuestionnaireState, action: DeleteNote): QuestionnaireState => {
	return {
		...state,
		notes: state.notes.filter((a) => a.id !== action.note.id),
	};
};

const editNote = (state: QuestionnaireState, action: EditNote): QuestionnaireState => {
	return {
		...state,
		notes: state.notes.map((f) =>
			f.id !== action.note.id
				? f
				: {
						...f,
						value: action.note.value,
				  },
		),
	};
};

const branchingTriggered = (
	state: QuestionnaireState,
	action: BranchingTriggered,
): QuestionnaireState => {
	return {
		...state,
		sections: [...action.sections],
		fields: [...action.fields],
		branchConditions: [...action.conditions],
	};
};

const updateProgress = (
	state: QuestionnaireState,
	action: UpdateQuestionnaireState,
): QuestionnaireState => {
	return {
		...state,
		status: { ...state.status, questionnaireState: action.questionnaireState },
	};
};

const loadFromRecord = (
	state: QuestionnaireState,
	action: LoadQuestionnaire,
): QuestionnaireState => {
	return {
		...state,
		questionnaire: action.questionnaireRecord.questionnaire,
		sections: [...action.questionnaireRecord.sections],
		groups: [...action.questionnaireRecord.groups],
		fields: [...action.questionnaireRecord.fields],
		subModules: [...action.questionnaireRecord.subModules],
		subModuleRecords: action.questionnaireRecord.subModuleRecords
			? [...action.questionnaireRecord.subModuleRecords]
			: [],
		dictionaries: action.questionnaireRecord.dictionaries
			? [...action.questionnaireRecord.dictionaries]
			: [],
		matrixes: action.questionnaireRecord.matrixes
			? [...action.questionnaireRecord.matrixes]
			: [],
		branchConditions: action.questionnaireRecord.branchConditions
			? [...action.questionnaireRecord.branchConditions]
			: [],
		notes: action.questionnaireRecord.notes ? [...action.questionnaireRecord.notes] : [],
		status: action.questionnaireRecord.status,
		isDirty: false,
	};
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const makeDirty = (state: QuestionnaireState, action: MakeDirty): QuestionnaireState => {
	return {
		...state,
		isDirty: true,
	};
};

const fileSizeError = (state: QuestionnaireState, action: FileSizeError): QuestionnaireState => {
	return {
		...state,
		status: {
			...state.status,
			fileSizeError: action.error,
		},
	};
};
