import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { SingleSelectFieldValidator } from "../../../../validators/singleSelectFieldValidator";
import { SelectFieldType } from "../../../../models/questionnaire";
import { EnumSelectFieldValidator } from "../../../../validators/enumSelectFieldValidator";
import { SingleSelectInputPresentation } from "./SingleSelectInput.presentation";
import type { EnumSelectField } from "../../../../models/fields/EnumSelectField";
import type { SingleSelectField } from "../../../../models/fields/SingleSelectField";

interface Props {
	field: SingleSelectField | EnumSelectField;
	updateField: (fieldId: number, value: any) => void;
	updateValidationStatus: (fieldId: number, value: string[]) => void;
}

export const SingleSelectInputContainer = ({
	field,
	updateField,
	updateValidationStatus,
}: Props) => {
	const { t } = useTranslation();
	const isLoadingDictionaries = useSelector((s: any) => s.questionnaire.isLoadingDictionaries);

	const validator = useMemo(
		() =>
			field.selectType === SelectFieldType.SingleSelect
				? new SingleSelectFieldValidator(field as SingleSelectField)
				: new EnumSelectFieldValidator(field as EnumSelectField),
		[field],
	);

	const handleClick = (value: number) => {
		if (value === field.value) {
			updateField(field.id, null);
			validate(undefined);
		}
	};

	const onChange = (
		event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>,
	) => {
		const selectedValue = event.target.value === "-1" ? undefined : Number(event.target.value);
		updateField(field.id, selectedValue);
		validate(selectedValue);
	};

	const validate = (value?: number) => {
		const validationResult = validator.isFieldValid(value);
		if (validationResult !== (field.validationMessage.length === 0)) {
			updateValidationStatus(field.id, validator.messages);
		}
	};

	if (field.isMandatory && field.data.size === 0) {
		return (
			<p>
				{isLoadingDictionaries
					? t("display:labelPickListLoading")
					: t("display:labelNoPickListValuesMandatory")}
			</p>
		);
	}

	if (!field.isMandatory && field.data.size === 0) {
		return (
			<p>
				{isLoadingDictionaries
					? t("display:labelPickListLoading")
					: t("display:labelNoPickListValues")}
			</p>
		);
	}

	return (
		<SingleSelectInputPresentation
			availableResponse={field.data}
			click={handleClick}
			group={field.name}
			guid={field.guid}
			id={field.id}
			isNotApplicable={field.isNotApplicable}
			onChange={onChange}
			selectedValue={field.value || undefined}
		/>
	);
};
