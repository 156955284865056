interface Props {
	input: {
		label: string;
		value: string;
		ref?: any;
	};
	errors?: {
		types?: object;
	};
}

export const PasswordInput = ({ input, errors }: Props) => {
	return (
		<div
			className={`she-components-control 
      ${errors && "she-components-validation-border-left"}
            `}
		>
			<label className="she-components-control-question" htmlFor={input.value}>
				{input.label}
			</label>

			{errors?.types &&
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				Object.entries(errors.types).map(([type, message], index) => (
					<div className="she-components-validation-message" key={index}>
						{message}
					</div>
				))}
			<input
				className="she-components-text-input"
				id={input.value}
				name={input.value}
				ref={input.ref}
				type="password"
			/>
		</div>
	);
};
