import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { ThumbnailList } from "../../../../../components/ThumbnailList";
import { FileInput } from "../../../../../components/input/FileInput";
import { ScrollRootToTop } from "../../../../../components/ScrollRootToTop";
import { AttachmentParentType } from "../../../../../../models/attachments/Attachment";

interface Props {
	questionnaireId: string;
	fieldGuid?: string;
}

export const AttachmentSectionContainer = forwardRef<HTMLHeadingElement, Props>(
	({ questionnaireId, fieldGuid }: Props, ref) => {
		const { t } = useTranslation();

		return (
			<>
				<ScrollRootToTop />
				<h1 className="she-questionnaire-section-header" ref={ref} tabIndex={-1}>
					{t("display:labelAttachments")}
				</h1>
				<ThumbnailList
					displayNoAttachmentsMessage
					parentGuid={fieldGuid}
					parentType={AttachmentParentType.Record}
					questionnaireId={questionnaireId}
				/>
				<FileInput
					parentType={AttachmentParentType.Record}
					questionnaireId={questionnaireId}
					validateAttachmentsLimit
				/>
			</>
		);
	},
);

AttachmentSectionContainer.displayName = "AttachmentSectionContainer";
