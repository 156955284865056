import { useMemo } from "react";
import { NumberFieldValidator } from "../../../../validators/numberFieldValidator";
import { NumberInputContainer } from "./NumberInput.container";
import type { NumberField } from "../../../../models/fields/NumberField";

interface Props {
	numberField: NumberField;
	updateField: (fieldId: number, value: any) => void;
	updateValidationStatus: (fieldId: number, value: string[]) => void;
}

export const NumberInputComponent = ({
	numberField,
	updateField,
	updateValidationStatus,
}: Props) => {
	const validator = useMemo(() => new NumberFieldValidator(numberField), [numberField]);

	return (
		<NumberInputContainer
			numberField={numberField}
			updateField={(value) => updateField(numberField.id, value)}
			updateValidationStatus={(value) => updateValidationStatus(numberField.id, value)}
			validator={validator}
		/>
	);
};
